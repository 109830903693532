export const setupStatuses = {
  verified: {
    label: 'Verified',
    image: '/images/checkmark.svg',
    description: '',
  },
  setup: {
    label: 'Setup',
    image: '/images/checkmark.svg',
    description: '',
  },
  connected: {
    label: 'Connected',
    image: '/images/checkmark.svg',
    description: '',
  },
  matched: {
    label: 'Matched, please save',
    image: '/images/warning.svg',
    description: 'Your banking account has been matched to the corresponding billing entity. Click Save in the Actions column to verify.',
  },
  legacy: {
    label: 'Legacy',
    image: '/images/warning.svg',
    description: '',
  },
  pending: {
    label: 'Pending Verification',
    image: '/images/warning.svg',
    description: '',
  },
  failed: {
    label: 'Failed',
    image: '/images/error-image.svg',
    description: '',
  },
  disconnected: {
    label: 'Disconnected',
    image: '/images/error-image.svg',
    description: '',
  },
  error: {
    label: 'Error',
    image: '/images/error-image.svg',
    description: '',
  },
  unsetup: {
    label: 'Not setup',
    image: '/images/warning.svg',
    description: '',
  }
};
