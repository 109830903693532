import { Box, Paper, Stack, Typography } from "@mui/material";
import AutoFixHighTwoToneIcon from "@mui/icons-material/AutoFixHighTwoTone";

interface ComingSoonPageProps {}

export function ComingSoonPage({}: ComingSoonPageProps) {
  return (
    <Box sx={{ height: "500px", width: "100%", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Paper
        elevation={0}
        sx={{
          border: "1px solid #EBEFF7",
          boxShadow: '0 0 20px rgba(0, 0, 0, 0.05)',
          p: 8,
        }}
      >
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={4}
          sx={{ textAlign: "center" }}
        >
          <AutoFixHighTwoToneIcon
            sx={{
              color: (theme) => theme.palette.primary.main,
              height: 100,
              width: "auto",
            }}
          />
          <Typography variant="h4">Upgrade in progress</Typography>
          <Typography>
            SafeLease Reports are currently being upgraded. Please check back
            later.
          </Typography>
        </Stack>
      </Paper>
    </Box>
  );
}
