import SortingTableVirtualized from "./sorting-table-virtualized";
import SortingTableLegacy from "./sorting-table-legacy";

function SortingTable( {
  virtualized=false,
  ...props
}) {
  return virtualized ? <SortingTableVirtualized {...props} /> : <SortingTableLegacy {...props} />
}


export enum TableTitles {
  Relationships = "Relationships",
  DocumentsTable = "DocumentsTable",
  LlcsSub = "LLcsSub",
  LocationsByLlcSub = "LocationsByLlcSub",
  LocationsByRelationshipSub = "LocationsByRelationshipSub",
  RelationshipsByClaims = "RelationshipsByClaims",
}

export default SortingTable;
