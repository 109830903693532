import { Box, Button, Collapse, Stack, Theme, Typography } from "@mui/material";
import { PrivatePolicy } from "../../../utilities/generated/gql-types";
import { useState } from "react";
import { PrivatePolicyDetailsSection } from "./PrivatePolicyDetailsSection";
import { ExpandMore } from "@mui/icons-material";

interface PrivatePolicyMetadataProps {
  privatePolicy: PrivatePolicy;
}

export function PrivatePolicyMetadata({
  privatePolicy,
}: PrivatePolicyMetadataProps) {
  const [isDetailsExpanded, setDetailsExpanded] = useState<boolean>(false);
  return (
    <Stack
      direction="column"
      sx={{
        flex: 2,
      }}
    >
      <Typography
        variant="body1"
        sx={{ color: (theme: Theme) => theme.palette.grey[600] }}
      >
        All Private Policy Metadata
      </Typography>
      <Stack
        direction="column"
        sx={{
          px: 2,
          pt: 2,
          border: "1px solid",
          borderColor: (theme: Theme) => theme.palette.divider,
          boxShadow: "0 0 20px rgba(0,0,0,0.05)",
          borderRadius: 2,
        }}
        spacing={1}
      >
        <Collapse
          sx={{ position: "relative" }}
          in={isDetailsExpanded}
          collapsedSize={400}
        >
          <Box sx={{ paddingBottom: "32px" }}>
            <Stack spacing={1.5}>
              <PrivatePolicyDetailsSection
                title="Submission ID"
                value={privatePolicy.id}
              />
              <PrivatePolicyDetailsSection
                title="Associated Location ID"
                value={privatePolicy.locationId}
              />
              <PrivatePolicyDetailsSection
                title="Associated Location Address"
                value={privatePolicy.locationAddress}
              />
              <PrivatePolicyDetailsSection
                title="PrivatePolicy.com Input: Tenant Name"
                value={privatePolicy.tenantName}
              />
              <PrivatePolicyDetailsSection
                title="PrivatePolicy.com Input: Move In Date"
                value={privatePolicy.moveInDate}
              />
              <PrivatePolicyDetailsSection
                title="PrivatePolicy.com Input: Policy Number"
                value={privatePolicy.policyNumber}
              />
              <PrivatePolicyDetailsSection
                title="PrivatePolicy.com Input: Policy Expiration Date"
                value={privatePolicy.expiration}
              />
              <PrivatePolicyDetailsSection
                title="AI-Extracted Company Name"
                value={privatePolicy.extractedCompanyName}
              />
              <PrivatePolicyDetailsSection
                title="AI-Extracted Policy Number"
                value={privatePolicy.extractedPolicyNumber}
              />
              <PrivatePolicyDetailsSection
                title="AI-Extracted Expiration Date"
                value={privatePolicy.extractedExpiration}
              />
              <PrivatePolicyDetailsSection
                title="Accepted Tenant Name Source"
                value={privatePolicy.tenantNameSource}
              />
              <PrivatePolicyDetailsSection
                title="Accepted Policy Number Source"
                value={privatePolicy.policyNumberSource}
              />
              <PrivatePolicyDetailsSection
                title="Accepted Expiration Date Source"
                value={privatePolicy.expirationSource}
              />
              <PrivatePolicyDetailsSection
                title="Attachment ID"
                value={privatePolicy.attachmentId}
              />
              <PrivatePolicyDetailsSection
                title="Policy Uploaded At"
                value={privatePolicy.createdAt}
              />
              <PrivatePolicyDetailsSection
                title="Unit Name"
                value={privatePolicy.unitName}
              />
              <PrivatePolicyDetailsSection
                title="Ran AI Extraction At"
                value={privatePolicy.extractedExpiration}
              />
              <PrivatePolicyDetailsSection
                title="AI Extraction Failed At"
                value={privatePolicy.extractedExpiration}
              />
              <PrivatePolicyDetailsSection
                title="Agent Input: Tenant Name"
                value={privatePolicy.overrideTenantName}
              />
              <PrivatePolicyDetailsSection
                title="Agent Input: Policy Number"
                value={privatePolicy.overridePolicyNumber}
              />
              <PrivatePolicyDetailsSection
                title="Agent Input: Expiration"
                value={privatePolicy.overrideExpiration}
              />
              <PrivatePolicyDetailsSection
                title="Tenant Email"
                value={privatePolicy.email}
              />
              <PrivatePolicyDetailsSection
                title="Is Valid Policy"
                value={privatePolicy.valid}
              />
              <PrivatePolicyDetailsSection
                title="Validation Status"
                value={privatePolicy.validationStatus}
              />
              <PrivatePolicyDetailsSection
                title="Validation Reason"
                value={privatePolicy.validationReason}
              />
              <PrivatePolicyDetailsSection
                title="Policy Includes Storage Units"
                value={String(privatePolicy.policyIncludesStorageUnits)}
              />
              <PrivatePolicyDetailsSection
                title="Policy Excludes Storage Units"
                value={String(privatePolicy.policyExcludesStorageUnits)}
              />
              <PrivatePolicyDetailsSection
                title="AI Doubts Policy Compliance"
                value={String(privatePolicy.doubtPolicyCompliance)}
              />
              <PrivatePolicyDetailsSection
                title="Error Message from AI"
                value={privatePolicy.errorMessage}
              />
              <PrivatePolicyDetailsSection
                title="GPT Model"
                value={privatePolicy.gptModel}
              />
              <PrivatePolicyDetailsSection
                title="Rejection Reason"
                value={privatePolicy.rejectionReason}
              />
            </Stack>
          </Box>
          <Box
            sx={{
              position: "absolute",
              bottom: 0,
              left: 0,
              right: 0,
              height: 50,
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-end",
              background:
                "linear-gradient(rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 80%)",
            }}
          >
            <Button
              size="small"
              onClick={() => setDetailsExpanded((prev) => !prev)}
              sx={{
                textTransform: "none",
              }}
              endIcon={
                <ExpandMore
                  sx={{
                    transitionDuration: "1.5s ease-in-out",
                    transform: isDetailsExpanded
                      ? "rotate(180deg)"
                      : "rotate(0deg)",
                  }}
                />
              }
            >
              {isDetailsExpanded ? "Hide" : "See all"}
            </Button>
          </Box>
        </Collapse>
      </Stack>
    </Stack>
  );
}
