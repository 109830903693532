import { useState, useEffect } from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { useReportQueryParams } from '../hooks';
import { ghostButtonNavy } from '../../styles/mui-overrides';
import { Button, TextField, Checkbox, ListItemText, SelectChangeEvent } from '@mui/material';
import DatePicker from '../../components/lib/SafeLeaseDatePicker';
import { ReportDef, DatePickerType } from './report-defs';
import SafeleaseWrapper from '../../shared/safelease-wrapper';
import RouterHelper from '../../utilities/router-helper';
import FormControl from '@mui/material/FormControl';
import { MenuItem, Select } from '@mui/material';
import { muiSelect } from '../../styles/mui-overrides';
import { useQuery, QueryResult } from '@apollo/client';
import { Location } from '../../utilities/generated/gql-types';
import { getLocations, getRelationshipsSelector, getReport, me } from '../../queries';
import Totals from '../components/totals';
import Loader from '../../shared/Loader';
import Error from '../../shared/Error';
import { useAuth } from '../../auth';
import VerticalTable from '../components/VerticalTable';
import ReportTable from '../components/ReportTable';
import TotalsDropdown from '../components/TotalsDropdown';
import FileSaver from 'file-saver';
import { convertArrayToCSV } from 'convert-array-to-csv';
import UserDataService from '../../services/user.service';
import { mixpanelEventHandler } from '../../utilities/reactMixpanelHandler';

function Report(props: {
  relationshipId: string;
  reportDef: ReportDef;
  routerHelper: RouterHelper;
  reportSelector: JSX.Element;
  isIngested: (location: Location) => boolean;
}) {
  const { reportDef, routerHelper, relationshipId, reportSelector, isIngested } = props;
  const history = useHistory();
  const auth = useAuth();
  const { url } = useRouteMatch();
  const queryParams = useReportQueryParams();
  const [forceVertical, setForceVertical] = useState(false);
  const [selectedTotals, setSelectedTotals] = useState(reportDef.totalDefs);
  
  const meQuery = useQuery(me, {
    onCompleted: data => {
      if (data.me.reportKeyStats?.[reportDef.reportTypeOption.queryParam]) {
        setSelectedTotals(data.me.reportKeyStats[reportDef.reportTypeOption.queryParam]);
      }
    },
  });

  const firstOfTheMonth = (() => {
    const date = new Date();
    date.setDate(1);
    date.setHours(0, 0, 0, 0);
    if (new Date().getDate() === 1) date.setDate(0); // if it's the first of the month, go back to the last day of last month 
    return date;
  })();

  const endOfTheMonth = new Date(firstOfTheMonth.getFullYear(), firstOfTheMonth.getMonth() + 1, 0);

  const updateFilters = () => {
    history.push(`${url}?${queryParams.toString()}`);
  };

  const locations: QueryResult<{ getLocations: Location[] }> = useQuery(getLocations, {
    variables: { relationshipId },
    onCompleted: data => {
      if (!queryParams.get('l')) { 
        queryParams.set('l', String(data.getLocations.filter(l => isIngested(l)).map(l => l.id)));
        updateFilters();
      }
    },
    fetchPolicy: auth.user.isAdmin ? 'no-cache' : 'cache-first', // admins only need no cache to change relationships properly
  });
  const relationships: QueryResult = useQuery(getRelationshipsSelector);

  const stateFromQueryParams = () => {
    const lParam = queryParams.get('l') || null;
    return {
      queryLocationIds:
        lParam
          ?.split(',')
          .map(id => parseInt(id)) || [],
      queryStartDate: (() => {
        const date = new Date(queryParams.get('startDate') || reportDef.defaultQueryDate.startDate);
        date.addMinutes(date.getTimezoneOffset());
        return date;
      })(),
      queryEndDate: (() => {
        const date = new Date(queryParams.get('endDate') || reportDef.defaultQueryDate.endDate || endOfTheMonth);
        date.addMinutes(date.getTimezoneOffset());
        return date;
      })(),
    };
  };

  const reportQuery = useQuery(getReport, {
    variables: {
      reportType: reportDef.reportTypeOption.value,
      relationshipId,
      locationIds: stateFromQueryParams().queryLocationIds,
      startDate: stateFromQueryParams().queryStartDate,
      endDate: stateFromQueryParams().queryEndDate,
    },
    skip: !stateFromQueryParams().queryLocationIds.length,
    onCompleted: data => {
      if(!data.getReport) return;
      if (
        reportDef.reportTypeOption.queryParam === 'dailySummary' &&
        data.getReport.rows.length <= 4
      ) {
        setForceVertical(true);
      } else if (reportDef.reportTypeOption.queryParam !== 'dailySummary') {
        setForceVertical(false);
      }
    },
  });

  const onUpdateLocationIds = (ids: string[]) => {
    mixpanelEventHandler('Insights - Location Filter');
    if (!ids.length) {
      queryParams.delete('l');
    } else {
      queryParams.set('l', String(ids.map(id => parseInt(id))));
    }
    updateFilters();
  };

  const onUpdateQueryDate = (date, paramName: string) => {
    mixpanelEventHandler('Insights - Date Filter');
    const x = new Date(date);
    if (x.toString() !== 'Invalid Date') {
      queryParams.set(paramName, x.toISOString().split('T')[0]);
      updateFilters();
    }
  };

  const getDatePickerView = (reportDef: ReportDef) => {
    if (reportDef.datePickerType === DatePickerType.Day) {
      return undefined;
    }
    return ['month', 'year'];
  };

  const getTotals = (): { number: string; label: string; tooltipText: string }[] => {
    return reportDef.columns
      .filter(col => selectedTotals.includes(col.key))
      .sort((a, b) => selectedTotals.indexOf(a.key) - selectedTotals.indexOf(b.key)) // sort them in whatever order the user selected
      .map(column => ({
        label: column.title,
        tooltipText: column.tooltip,
        number: column.renderer
          ? column.renderer(
              reportQuery?.data?.getReport?.totals[column.key],
              null,
              column.renderOptions
            )
          : reportQuery?.data?.getReport?.totals[column.key],
      }));
  };

  const onChangeRelationship = (e: SelectChangeEvent) => {
    queryParams.delete('l'); // must clear out all location params
    routerHelper.navigateToRoute('insights', e.target.value, history);
  };

  const exportCSV = () => {
    mixpanelEventHandler('Insights - CSV Export');
    const csvArray = reportQuery.data.getReport.rows.map(row => {
      return reportDef.columns.reduce((acc, column) => {
        let value = row;
        for (const part of column.key.split('.')) {
          value = value?.[part];
        }
        return { ...acc, [column.title]: value };
      }, {});
    });
    const csvString = convertArrayToCSV(csvArray);
    const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
    FileSaver.saveAs(blob, `Insights Export (${new Date().toISOString().split('T')[0]})`);
  };

  const onUpdateSelectedTotals = async (value: string[]) => {
    await UserDataService.updateReportSettings({ reportKey: reportDef.reportTypeOption.queryParam, value });
    setSelectedTotals(value);
    meQuery.refetch();
  };

  useEffect(() => { // either user the user-saved key stats, or default to the ones for that report
    if (!meQuery.loading) {
      setSelectedTotals(meQuery.data?.me?.reportKeyStats?.[reportDef.reportTypeOption.queryParam] || reportDef.totalDefs);
    }
  }, [reportDef.totalDefs, meQuery.loading]);
  if (locations.loading || relationships.loading || reportQuery.loading) return <Loader />;
  if (locations.error || relationships.error || reportQuery.error) return <Error />;

  const locationIds = stateFromQueryParams().queryLocationIds;
  if (!locationIds.length) return <p>No locations selected.</p>;
  
  return (
    <SafeleaseWrapper>
      <div className="tw-flex tw-flex-auto">
        {reportSelector}
        {locations.data.getLocations.length > 1 && (
          <FormControl sx={{ width: 'auto', maxWidth: '13.5rem' }}>
            <Select
              sx={muiSelect}
              displayEmpty={true}
              id="locations-selector"
              multiple
              value={stateFromQueryParams().queryLocationIds.map(id => String(id))}
              onChange={e => onUpdateLocationIds(e.target.value as string[])}
              renderValue={value => <>{value.length} location(s) selected</>}
            >
              {stateFromQueryParams().queryLocationIds.length / locations.data.getLocations.length >
              0.5 ? (
                <MenuItem
                  onClick={() => {
                    onUpdateLocationIds([]);
                  }}
                >
                  Clear Selection
                </MenuItem>
              ) : (
                <MenuItem
                  onClick={() => {
                    onUpdateLocationIds(locations.data.getLocations.map(l => String(l.id)));
                  }}
                >
                  Select All
                </MenuItem>
              )}

              {locations.data.getLocations.filter(l => isIngested(l)).map(location => (
                <MenuItem sx={{ height: '2.5rem' }} key={location.id} value={location.id}>
                  <Checkbox
                    checked={stateFromQueryParams().queryLocationIds.includes(
                      parseInt(location.id)
                    )}
                  />
                  <ListItemText primary={location.address} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        {relationships.data.getRelationshipsSelector.length > 1 && (
          <FormControl sx={{ width: 'auto', maxWidth: '12rem' }}>
            <Select
              value={relationshipId}
              displayEmpty
              onChange={onChangeRelationship}
              sx={muiSelect}
            >
              {relationships.data.getRelationshipsSelector.map(relationship => {
                return (
                  <MenuItem key={relationship.id} value={relationship.id}>
                    {relationship.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        )}

        <div className="tw-flex tw-flex-auto tw-justify-end">
          <DatePicker
            onChange={value => onUpdateQueryDate(value, 'startDate')}
            views={getDatePickerView(reportDef)}
            className="report-details--date-picker"
            value={stateFromQueryParams().queryStartDate || firstOfTheMonth}
            slotProps={{
              textField: {
                size: "small",
                sx: { width: 'auto', height: '2.5rem', maxWidth: '12rem', backgroundColor: 'white' }
              }
            }}
          />

          {reportDef.reportTypeOption.queryParam === 'insurancePenetration' && (
            <>
              <div className="report-details--options--left--to">
                <div>To</div>
              </div>

              <DatePicker
                onChange={value => onUpdateQueryDate(value, 'endDate')}
                views={getDatePickerView(reportDef)}
                value={stateFromQueryParams().queryEndDate || endOfTheMonth}
                slotProps={{
                  textField: {
                    size: "small",
                    sx: { width: 'auto', height: '2.5rem', maxWidth: '12rem', backgroundColor: 'white'
                  }
                }}}
              />
            </>
          )}

          <Button sx={{ ...ghostButtonNavy, marginRight: '0.25rem', height: '2.5rem' }} onClick={exportCSV}>
            Export CSV
          </Button>

          <Button sx={{ ...ghostButtonNavy, height: '2.5rem' }} onClick={() => setForceVertical(!forceVertical)}>
            Flip Table
          </Button>
        </div>
      </div>

      <div className="tw-max-w-screen-xl">
        <div className="tw-mt-2">
          <Totals
            totals={getTotals()}
            dropdown={
              <TotalsDropdown
                selectedTotals={selectedTotals}
                setSelectedTotals={onUpdateSelectedTotals}
                totals={reportQuery?.data?.getReport?.totals}
                columns={reportDef.columns}
                restoreDefaults={() => onUpdateSelectedTotals(reportDef.totalDefs)}
              />
            }
          />
        </div>

        {reportQuery?.data?.getReport?.rows && (
          <>
            {forceVertical ? (
              <VerticalTable
                columns={reportDef.columns}
                dataRows={reportQuery.data.getReport.rows}
              />
            ) : (
              <ReportTable
                columns={reportDef.columns}
                rows={reportQuery.data.getReport.rows}
                totals={reportQuery.data.getReport.totals}
              />
            )}
          </>
        )}
      </div>
    </SafeleaseWrapper>
  );
}

export default Report;
