import {
  ContentCopy,
  DoNotDisturbOnOutlined,
  FiberManualRecord,
  KeyboardArrowDown,
  RadioButtonUnchecked,
  SendOutlined,
} from "@mui/icons-material";
import { Menu, MenuItem, Stack, Typography } from "@mui/material";
import { SafeLeaseButton, SafeLeaseChip } from "@safelease/components";
import { QRCodeStatus } from "@safelease/service-utilities";
import { useState } from "react";
import { useReputationStore } from "../../../../useReputation";
import { UserQRCode } from "@safelease/service-utilities";
import { enqueueSnackbar } from "notistack";
import { useAuth } from "../../../../../auth";
import { usePerformanceBoardStore } from "../usePerformanceBoard";
import { SafeLeaseAPI as SafeLeaseAPIDefaultEnvironment } from "../../../../SafeLeaseAPIInstance";
import { reactQueryClient } from "../../../../../utilities/reactQueryClent";

/**
 * Display of a QR code that tracks an employee x a specific facility when scanned
 */
export function EmployeeQRCode({ userQrCode }: { userQrCode: UserQRCode }) {
  const { qrCode, facility } = userQrCode;

  const auth = useAuth();
  const { portfolio } = usePerformanceBoardStore();
  const { selectedRelationshipId } = useReputationStore();

  const relationshipId = selectedRelationshipId ?? auth.user.relationshipId;

  const SafeLeaseAPI = SafeLeaseAPIDefaultEnvironment({
    environment: process.env.REACT_APP_SAFELEASE_ENVIRONMENT,
  });

  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);

  const menuOpen = Boolean(anchorEl);
  const active = qrCode.status === QRCodeStatus.ACTIVE;

  const handleCopyQRCodeLink = () => {
    navigator.clipboard.writeText(qrCode.destination);
    enqueueSnackbar("Link copied to clipboard", { variant: "success" });
    setAnchorEl(null);
  };

  const handleResendQRCode = async () => {
    setAnchorEl(null);
    await SafeLeaseAPI.reputation.resendQRCodeHandler({ qrCodeId: qrCode.id });
    enqueueSnackbar("QR code sent", { variant: "success" });
  };

  const handleDeactivateQRCode = async () => {
    await SafeLeaseAPI.reputation.deactivateQRCodeHandler({ qrCodeId: qrCode.id, portfolioId: portfolio.id });
    await reactQueryClient.invalidateQueries({
      queryKey: ["getQRCodesForUser", userQrCode.userId],
    });
    enqueueSnackbar("QR code deactivated", { variant: "success" });
    setAnchorEl(null);
  };

  const handleActivateQRCode = async () => {
    await SafeLeaseAPI.reputation.activateQRCodeHandler({ qrCodeId: qrCode.id, portfolioId: portfolio.id });
    await reactQueryClient.invalidateQueries({
      queryKey: ["getQRCodesForUser", userQrCode.userId],
    });

    enqueueSnackbar("QR code activated", { variant: "success" });
    setAnchorEl(null);
  };

  return (
    <Stack sx={{ p: 2, border: "1px solid", borderColor: "#EBEFF7", borderRadius: 1 }} spacing={1} direction="column" alignItems="center">
      <Typography variant="body1" sx={{ textAlign: "center" }}>
        {facility.companyName}
      </Typography>
      <Stack spacing={2} direction="column" alignItems="center">
        <img src={qrCode.attachment.url} alt="QR Code" style={{ width: "100%", height: "auto" }} />
        {active && (
          <SafeLeaseChip color="blue" label="Active" icon={<FiberManualRecord sx={{ color: "blue.main", height: 10, width: 10 }} />} />
        )}
        {!active && (
          <SafeLeaseChip color="red" label="Inactive" icon={<RadioButtonUnchecked sx={{ color: "red.main", height: 10, width: 10 }} />} />
        )}
        {!active && (
          <SafeLeaseButton variant="contained" color="navy" sx={{ mt: 2 }} onClick={handleActivateQRCode}>
            Activate
          </SafeLeaseButton>
        )}
        {active && (
          <>
            <SafeLeaseButton variant="outlined" color="navy" endIcon={<KeyboardArrowDown />} onClick={(e) => setAnchorEl(e.currentTarget)}>
              Actions
            </SafeLeaseButton>
            <Menu
              sx={{ boxShadow: "-4px 0px 20px 0px rgba(0, 0, 0, 0.10)", border: "1px solid", borderColor: "#EBEFF7" }}
              title="Actions"
              open={menuOpen}
              onClose={() => setAnchorEl(null)}
              anchorEl={anchorEl}
            >
              <MenuItem onClick={handleCopyQRCodeLink}>
                <ContentCopy sx={{ mr: 1 }} />
                Copy Review Link
              </MenuItem>
              {/* <MenuItem onClick={handleDownloadQRCode}>
                <GetApp sx={{ mr: 1 }} />
                Download QR code
              </MenuItem> */}
              <MenuItem onClick={handleResendQRCode}>
                <SendOutlined sx={{ mr: 1 }} />
                Email code
              </MenuItem>
              <MenuItem onClick={handleDeactivateQRCode}>
                <DoNotDisturbOnOutlined sx={{ mr: 1 }} />
                Deactivate QR code
              </MenuItem>
            </Menu>
          </>
        )}
      </Stack>
    </Stack>
  );
}
