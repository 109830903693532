import { Claim } from '../utilities/generated/gql-types'
import { NotificationDot } from '../common'
import { StatusTriage } from '../utilities/field-enums'
import { NotificationsContext, NotificationsContextType } 
    from '../utilities/notifications-context';

function ClaimStatusPill(props: {
    status?: string,
    shortStatus?: string,
    canHaveNotification?: boolean,
    tableInfo?: {row: Claim}}) {
  const { status, shortStatus, tableInfo, canHaveNotification } = props;

    return (
      <NotificationsContext.Consumer>
        {(notificationsContext: NotificationsContextType) =>
          <div className='tw-w-[100px] tw-h-[16px] tw-flex tw-items-center tw-justify-start'>
            {(shortStatus === StatusTriage.Denied || 
                doClaimStatusTriage(status) === StatusTriage.Denied) &&
              <div className="status-pill status-pill__denied">
                Denied
              </div>
            }
            {(shortStatus === StatusTriage.InProgress ||
                doClaimStatusTriage(status) === StatusTriage.InProgress) &&
              <div className="status-pill status-pill__in-progress">
                In Progress
              </div>
            }
            {(shortStatus === StatusTriage.Paid || 
                doClaimStatusTriage(status) === StatusTriage.Paid) &&
              <div className="status-pill status-pill__paid">
                Paid
              </div>
            }
            {(shortStatus === StatusTriage.Abandoned || 
                doClaimStatusTriage(status) === StatusTriage.Abandoned) &&
              <div className="status-pill status-pill__abandoned">
                Abandoned
              </div>
            }
            {canHaveNotification &&
                notificationsContext.NotificationsHelper.claimHasNotification(
                    tableInfo?.row,
                    notificationsContext.allNotifications) &&
              <NumberedNotificationDot totalNotifications={
                  notificationsContext.allNotifications.filter(notification => {
                    return notification.claimId == tableInfo?.row.id
                  }).length
              } />
            }
          </div>
        }
      </NotificationsContext.Consumer>
    )
  }

  export function claimStatusPillRenderer(shortStatus: string, tableInfo: {row: Claim}) {
    return <ClaimStatusPill shortStatus={shortStatus} tableInfo={tableInfo} canHaveNotification />
  }
    
  export function doClaimStatusTriage(inDepthStatus?: string): string | null {
    // TODO ask adam how to account for "Unknown" and "Uncategorized" statuses
    if (!inDepthStatus) return null;
    if (inDepthStatus === 'Settled - Paid') {
      return StatusTriage.Paid;
    } else if (inDepthStatus.includes('Settled - Denied')) {
      return StatusTriage.Denied
    } else if (inDepthStatus.includes('Closed - Incomplete')) {
      return StatusTriage.Abandoned
    }
    return StatusTriage.InProgress
  }
  
  export const NumberedNotificationDot = (props: {totalNotifications: number}) => {
    
    return (
      <NotificationDot totalNotifs={props.totalNotifications}
          extraStyles={`tw-w-[20px] tw-h-[20px] tw-float-right tw-float-top tw-relative tw-ml-4`} />
    )
  }

  export default ClaimStatusPill
