export const invoiceStatuses = {
  unsent: {
    label: 'Unsent',
    image: '/images/warning.svg',
    severity: 'info',
  },
  queued: {
    label: 'Queued for Processing',
    image: '/images/warning.svg',
    severity: 'info',
  },
  pending: {
    label: 'Pending',
    image: '/images/warning.svg',
    severity: 'info',
  },
  cancelled: {
    label: 'Cancelled',
    image: '/images/warning.svg',
    severity: 'warning',
  },
  failed: {
    label: 'Failed',
    image: '/images/error-image.svg',
    severity: 'error',
  },
  processed: {
    label: 'Processed',
    image: '/images/checkmark.svg',
    severity: 'success'
  },
} as const;
