import { useState, useEffect } from 'react';
import AdminDataService from '../services/admin.service';
import { useAuth } from '../auth';
import Modal from '@mui/material/Modal';
import Box from '@mui/system/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { ghostButtonNavy } from '../styles/mui-overrides';

export function AuditRouteWrapper({routerHelper, children}) {
  // TODO: abstract modal text so it can be reusable
  const [reason, setReason] = useState(null);
  const auth = useAuth();

  // TODO: abstract this so it can wrap multiple routes
  const sendDashboardReason = async () => {
    const reason = (document.getElementById('reason') as HTMLInputElement).value;
    const response = await AdminDataService.auditReason({
      content: `Dashboard View: ${reason}`,
      relationshipId: routerHelper.getRelationshipId(),
    });
    if (response.data.success) setReason(reason);
  };

  const handleOnKeyDown = (e) => {
    if (e.key === 'Enter') sendDashboardReason();
  };

  // TODO: abstract boolean logic and dependencies so it can wrap multiple routes
  useEffect(() => {
    if (auth.user.relationshipId == 271 && routerHelper.getRelationshipId() != 271) setReason(null);
  }, [routerHelper.getRelationshipId()]);

  if (!reason && routerHelper.getRelationshipId() != 271 && !auth.user.isAdmin && auth.user.relationshipId == 271) {
    return (
      <>
        <Modal open={true} onClose={() => null}>
          <Box
            sx={{
              position: 'absolute' as 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 400,
              bgcolor: 'white',
              border: '2px solid #000',
              boxShadow: 24,
              p: 4,
            }}
          >
            Are you sure you want to view this partner's dashboard? Please enter a reason:
            <TextField sx={{ width: '16rem' }} onKeyDown={handleOnKeyDown} id="reason" />
            <Button sx={ghostButtonNavy} onClick={sendDashboardReason}>
              Submit
            </Button>
          </Box>
        </Modal>
      </>
    );
  }

  return <>{children}</>;
}

// TODO: create a callback wrapper for auditing before API calls