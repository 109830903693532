// UI - libs
import { Alert, Button, Grid, Paper, Stack, TabProps } from "@mui/material";

// UI - internal
import SafeleaseTabs from "../shared/SafeleaseTabs";
import SafeleaseTab from "../shared/safelease-tab";
import TabPanel from "../shared/TabPanel";
import { PrivatePolicyReport } from "../compliance/PrivatePolicyReport";
// import DailySummaryReport from "./DailySummaryReport";
// import OccupancySummaryReport from "./OccupancySummaryReport";
// import InsurancePenetrationReport from "./InsurancePenetrationReport";
import RelationshipSelector from "../shared/RelationshipSelector";
import { ghostButtonNavy } from "../styles/mui-overrides";

// Data
import RouterHelper from "../utilities/router-helper";

// Hooks & State
import { useState } from "react";
import { useReportsStore } from "./useReportsStore";
import TitleHeader from "../shared/title-header";
import { ArrowForward } from "@mui/icons-material";
import { useHistory } from "react-router-dom";
import ReportsQueries from "./ReportsQueries";
import AccessControlled from "../components/AccessControlled";
import ReportsLocationSelector from "../compliance/PrivatePolicyReport/ReportsLocationSelector";
import { ComingSoonPage } from "./ComingSoonPage";
import { TenantProtectionActivityReport } from "./TenantProtectionActivityReport";

interface ReportsProps {
  routerHelper: RouterHelper;
}

enum ReportTabs {
  tenantProtectionActivity = "tenantProtectionActivity",
  tenantProtectionEnrollment = "tenantProtectionEnrollment",
  privatePolicies = "privatePolicies",
  dailySummary = "dailySummary",
  insurancePenetration = "insurancePenetration",
  occupancySummary = "occupancySummary",
}

/* Houses all of the reports in the reports tab */
/* Certain reports may be hidden for certain users depending on their feature flags */
export default function Reports({ routerHelper }: ReportsProps) {
  const history = useHistory();

  /* State */
  const relationshipId = useReportsStore((state) => state.relationshipId);
  const setRelationshipId = useReportsStore((state) => state.setRelationshipId);

  const [currentReportTab, setCurrentReportTab] = useState<ReportTabs>(
    ReportTabs.tenantProtectionActivity
  );

  const handleChangeReportTab = (
    _: React.SyntheticEvent,
    newCurrentTab: ReportTabs
  ) => {
    setCurrentReportTab(newCurrentTab);
  };

  return (
    <AccessControlled
      controlBy="feature"
      routerHelper={routerHelper}
      featureFlag="reports"
      alternateRender={<ComingSoonPage />}
    >
      <TitleHeader title="Reports" />
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        sx={{ mb: 2 }}
      >
        <RelationshipSelector
          routerHelper={routerHelper}
          nonRoute
          nonRouteCallback={setRelationshipId}
        />
        <ReportsLocationSelector />
      </Stack>
      <Paper
        elevation={0}
        sx={{
          p: 2,
          border: "1px solid #EBEFF7",
          boxShadow: "0 0 20px rgba(0, 0, 0, 0.05)",
          overflow: "hidden",
          borderRadius: "8px",
        }}
      >
        <ReportsQueries routerHelper={routerHelper} />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Alert
              severity="info"
              action={
                <Button
                  sx={{
                    ...ghostButtonNavy,
                    fontWeight: "bold",
                    border: "none",
                    backgroundColor: "transparent",
                  }}
                  endIcon={<ArrowForward />}
                  onClick={() =>
                    history.push(`/reports/legacy/${relationshipId}`)
                  }
                >
                  View Legacy Reports
                </Button>
              }
            >
              Welcome to SafeLease Reports 2.0
            </Alert>
          </Grid>
          <Grid item xs={12}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            ></Stack>
            <SafeleaseTabs
              value={currentReportTab}
              onChange={handleChangeReportTab}
              variant="scrollable"
              scrollButtons="auto"
              routerHelper={routerHelper}
            >
              <AccessControlled
                controlBy="feature"
                featureFlag="beta-reports-tenant-protection"
                value={ReportTabs.tenantProtectionActivity}
                routerHelper={routerHelper}
                propsOverrides={{
                  value: ReportTabs.tenantProtectionActivity,
                }}
              >
                <SafeleaseTab
                  value={ReportTabs.tenantProtectionActivity}
                  label="Pricing Report"
                />
              </AccessControlled>
              {/* <AccessControlled
                controlBy="feature"
                featureFlag="beta-reports-tenant-protection"
                routerHelper={routerHelper}
                value={ReportTabs.tenantProtectionEnrollment}
                propsOverrides={{
                  value: ReportTabs.tenantProtectionEnrollment,
                }}
              >
                <SafeleaseTab
                  value={ReportTabs.tenantProtectionEnrollment}
                  label="Tenant Protection Enrollment"
                />
              </AccessControlled> */}
              <AccessControlled
                controlBy="feature"
                featureFlag="beta-reports"
                value={ReportTabs.dailySummary}
                routerHelper={routerHelper}
                propsOverrides={{
                  value: ReportTabs.dailySummary,
                }}
              >
                <SafeleaseTab
                  value={ReportTabs.dailySummary}
                  label="Daily Summary"
                />
              </AccessControlled>
              <AccessControlled
                controlBy="feature"
                featureFlag="beta-reports"
                value={ReportTabs.insurancePenetration}
                routerHelper={routerHelper}
                propsOverrides={{
                  value: ReportTabs.insurancePenetration,
                }}
              >
                <SafeleaseTab
                  value={ReportTabs.insurancePenetration}
                  label="Insurance Penetration"
                />
              </AccessControlled>
              <AccessControlled
                controlBy="feature"
                featureFlag="beta-reports"
                value={ReportTabs.occupancySummary}
                routerHelper={routerHelper}
                propsOverrides={{
                  value: ReportTabs.occupancySummary,
                }}
              >
                <SafeleaseTab
                  value={ReportTabs.occupancySummary}
                  label="Occupancy Summary"
                />
              </AccessControlled>
            </SafeleaseTabs>
          </Grid>
          <Grid item xs={12}>
            <AccessControlled
              controlBy="feature"
              featureFlag="beta-reports-tenant-protection"
              routerHelper={routerHelper}
            >
              <TabPanel
                currentTab={currentReportTab}
                index={ReportTabs.tenantProtectionActivity}
              >
                <TenantProtectionActivityReport routerHelper={routerHelper} />
              </TabPanel>
            </AccessControlled>
            <AccessControlled
              controlBy="feature"
              featureFlag="beta-reports"
              routerHelper={routerHelper}
            >
              <TabPanel
                currentTab={currentReportTab}
                index={ReportTabs.dailySummary}
              >
                {/* <DailySummaryReport routerHelper={routerHelper} /> */}
              </TabPanel>
            </AccessControlled>
            <AccessControlled
              controlBy="feature"
              featureFlag="beta-reports"
              routerHelper={routerHelper}
            >
              <TabPanel
                currentTab={currentReportTab}
                index={ReportTabs.insurancePenetration}
              >
                {/* <InsurancePenetrationReport /> */}
              </TabPanel>
            </AccessControlled>
            <AccessControlled
              controlBy="feature"
              featureFlag="beta-reports"
              routerHelper={routerHelper}
            >
              <TabPanel
                currentTab={currentReportTab}
                index={ReportTabs.occupancySummary}
              >
                {/* <OccupancySummaryReport /> */}
              </TabPanel>
            </AccessControlled>
          </Grid>
        </Grid>
      </Paper>
    </AccessControlled>
  );
}
