import axios from "axios";

export class BaseAPI {
  private baseUrls: { dev: string; staging: string; prod: string } = {
    dev: "http://localhost:8080",
    staging: "https://staging.safelease.com",
    prod: "https://app.safelease.com",
  };
  private baseUrl: string;
  public axiosInstance;

  constructor() {
    this.baseUrl = this.getBaseUrl();
    this.axiosInstance = axios.create({
      baseURL: this.baseUrl,
    });

    this.axiosInstance.interceptors.request.use((config) => {
      const user = JSON.parse(localStorage.getItem("user"));

      if (user.accessToken) {
        config.headers.Authorization = "Bearer " + user.accessToken;
      }

      return config;
    });
  }

  private getBaseUrl() {
    const env = process.env.REACT_APP_SAFELEASE_ENVIRONMENT;

    if (env === "production") {
      return this.baseUrls.prod;
    } else if (env === "staging") {
      return this.baseUrls.staging;
    } else {
      return this.baseUrls.dev;
    }
  }
}

export const baseApi = new BaseAPI();
