import { create } from 'zustand';
import { EditMode } from '../../../shared/drawer-footer';
import { User } from '../../../utilities/generated/gql-types';


interface UserManagementSettingsState {

  // Drawer management
  editDrawerOpenMode: EditMode | null;
  setEditDrawerOpenMode: (mode: EditMode | null) => void;

  // User management
  currentlyEditedUser: User | null;
  setCurrentlyEditedUser: (user: User) => void;

  // Table management
  searchValue: string;
  setSearchValue: (value: string) => void;

  loading: boolean,
  setLoading: (loading: boolean) => void;

  closeDrawer: () => void;
}

export const useUserManagementSettings = create<UserManagementSettingsState>()((set, get) => ({

  // Drawer
  editDrawerOpenMode: null,
  setEditDrawerOpenMode: (mode: EditMode | null) => set({ editDrawerOpenMode: mode }),

  // User
  currentlyEditedUser: null,
  setCurrentlyEditedUser: (user: User) => set({ currentlyEditedUser: user }),

  // Table
  searchValue: "",
  setSearchValue: (value: string) => set({ searchValue: value }),

  // For indictators & disabling
  loading: false,
  setLoading: (loading: boolean) => set({ loading: loading }),
  
  closeDrawer: () => {
    if (!get().loading) set({ editDrawerOpenMode: null, currentlyEditedUser: null }) 
  },
}))