import { StateCreator } from "zustand";
import { Location } from "../../../utilities/generated/gql-types";

export interface PlanUpgradeSettingsLocationSliceState {
  selectedLocations: Location[];
}

export interface PlanUpgradeSettingsLocationsSlice extends PlanUpgradeSettingsLocationSliceState {
  setSelectedLocations: (locations: Location[]) => void;
}

const initialState: PlanUpgradeSettingsLocationSliceState = {
  selectedLocations: [],
};

/**
 * Control which locations are selected in the plan upgrade form
 */
export const createPlanUpgradeSettingsLocationsSlice: StateCreator<PlanUpgradeSettingsLocationsSlice> = (set) => {
  return {
    ...initialState,
    setSelectedLocations: (locations: Location[]) => {
      set({
        selectedLocations: locations,
      });
    },
  };
}