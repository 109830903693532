import { PrivatePolicyValidationStatus } from "../reports/useReportsStore/privatePolicyReportSlice";
import http from "./http-common";

class PrivatePolicyDataService {
  save(params) {
    return http.post("/private-policy/save", params);
  }

  updateStatus(params: {
    generalUnitId: number | string;
    privatePolicyId: number | string;
    validationStatus: PrivatePolicyValidationStatus;
    overrideTenantName?: string;
    overridePolicyNumber?: string;
    overrideExpiration?: string;
    tenantNameSource?: string;
    policyNumberSource?: string;
    expirationSource?: string;
    rejectionReason?: string;
    sendEmail?: string;
  }) {
    return http.post("/private-policy/update-status", params);
  }
}

export default new PrivatePolicyDataService();
