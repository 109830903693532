// UI - libs
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import { green, red } from "@mui/material/colors";
import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";

// Data
import {
  GeneralUnit,
  GeneralUnitHistoryItem,
} from "../../../utilities/generated/gql-types";

// Libs
import _ from "lodash";
import dayjs from "dayjs";
import { Fragment } from "react";

const ignoreFields = [
  "id",
  "__typename",
  "generalUnitId",
  "snapshotDate",
  "updatedAt",
];

interface GeneralUnitHistoryTimelineItemProps {
  latestVersion?: boolean;
  currItem: GeneralUnitHistoryItem | GeneralUnit;
  prevItem: GeneralUnitHistoryItem;
}

export default function GeneralUnitHistoryTimelineItem({
  currItem,
  prevItem,
  latestVersion,
}: GeneralUnitHistoryTimelineItemProps) {
  // If no prev item, it's the last record
  const alteredFields = prevItem
    ? _.reduce(
        currItem,
        (acc, value, key) =>
          !ignoreFields.includes(key) && value !== prevItem[key]
            ? acc.concat(key)
            : acc,
        []
      )
    : [];

  return (
    <Accordion
      elevation={0}
      sx={{
        borderLeft: latestVersion ? "4px solid #67bd42" : "4px solid #152744",
        borderTop: "1px solid #EBEFF7",
        borderBottom: "1px solid #EBEFF7",
        borderRight: "1px solid #EBEFF7",
        my: 1,
      }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ display: "flex", justifyContent: "space-between", pr: 2 }}>
            <Typography>{latestVersion ? "Live version" : "Update"}</Typography>
            <Typography variant="caption" sx={{ ml: "auto" }}>
              {dayjs(currItem?.snapshotDate || currItem?.updatedAt).format(
                "MMM DD, YYYY hh:mm"
              )}
            </Typography>
          </Box>
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <Typography variant="caption" sx={{ color: "gray" }}>
                Field
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="caption" sx={{ color: "gray" }}>
                New value
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="caption" sx={{ color: "gray" }}>
                Old value
              </Typography>
            </Grid>
            {alteredFields.map((field) => (
              <Fragment key={field}>
                <Grid item xs={4}>
                  <Typography variant="caption">{field}</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    variant="caption"
                    sx={{ bgcolor: green[100], p: 1, borderRadius: 1 }}
                  >
                    {currItem[field]}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    variant="caption"
                    sx={{ bgcolor: red[100], p: 1, borderRadius: 1 }}
                  >
                    {prevItem[field]}
                  </Typography>
                </Grid>
              </Fragment>
            ))}
          </Grid>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Box>
          <Typography>Full before / after</Typography>
          <Divider />
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <Typography variant="caption" sx={{ color: "gray" }}>
                Field
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="caption" sx={{ color: "gray" }}>
                New value
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="caption" sx={{ color: "gray" }}>
                Old value
              </Typography>
            </Grid>
            {Object.keys(currItem).map((field) => {
              if (ignoreFields.includes(field)) return null; // skip unnecessary fields / fields that aren't share between GeneralUnit and GeneralUnitHistoryItem
              const fieldWasUpdated = alteredFields.includes(field);

              return (
                <Fragment key={field}>
                  <Grid item xs={4}>
                    <Typography variant="caption">{field}</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography
                      variant="caption"
                      sx={{
                        bgcolor: fieldWasUpdated ? green[100] : "transparent",
                        p: 1,
                        borderRadius: 1,
                      }}
                    >
                      {currItem[field] ? String(currItem[field]) : "-"}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography
                      variant="caption"
                      sx={{
                        bgcolor: fieldWasUpdated ? red[100] : "transparent",
                        p: 1,
                        borderRadius: 1,
                      }}
                    >
                      {prevItem ? String(prevItem[field]) : "-"}
                    </Typography>
                  </Grid>
                </Fragment>
              );
            })}
          </Grid>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}
