import { FormControl, styled, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";

/** Any styled component which lives in this file is used across two or more files in the MyAccount directory **/

export const FlexRowBox = styled(Box)(({ theme }) => ({
  display: "flex",
}));

export const FlexColumnBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
}));

export const StyledTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    height: "40px", // Set the height of the TextField
  },
  "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-input": {
    color: "#031E30", // Set the color of the text inside the disabled TextField
  },
  "& .MuiOutlinedInput-root.Mui-disabled .MuiInputBase-input": {
    WebkitTextFillColor: "#031E30", // Ensure the text color for Webkit browsers
  },
}));

export const StyledFormControl = styled(FormControl)(({ theme }) => ({
  marginTop: "10px",
  marginBottom: "20px",
  minWidth: "120px",
}));

export const RowTitleText = styled(Typography)(({ theme }) => ({
  fontWeight: "500",
  fontSize: "14px",
  lineHeight: "19px",
  fontColor: "#152744",
}));

export const RowSubTitleText = styled(Typography)(({ theme }) => ({
  fontSize: "12px",
  fontWeight: "500",
  lineHeight: "16px",
  fontColor: "#929292",
  opacity: "0.7",
}));

export const TextFieldLabel = styled(Typography)(({ theme }) => ({
  fontSize: "12px",
  fontWeight: "500",
  fontColor: "#031E30",
  lineHeight: "16px",
}));
