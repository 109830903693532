import { Stack } from "@mui/system";
import { FacilityBilling } from "./Tools/FacilityBilling";
import { TwilioVerificationCard } from "./Tools/TwilioVerificationCard";
import { OffboardFacilityCard } from "./Tools/OffboardFacilityCard";

export const AdminToolsFacilityContainer = () => {
  return (
    <Stack spacing={2}>
      <FacilityBilling />
      <TwilioVerificationCard />
      <OffboardFacilityCard />
    </Stack>
  );
};
