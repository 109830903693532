import { useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';

export interface DropDownItem {
  label: string;
  callback: Function;
  style?: object;
}

export function TableActionsDropdown(props: { menuItems: DropDownItem[] }) {
  const { menuItems } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const handleClose = (e: React.MouseEvent) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  const handleMenuItemClick = (e: React.MouseEvent, callback: Function) => {
    e.stopPropagation();
    callback();
  };
  return (
    <>
      <MoreVertIcon
        sx={{ '&:hover': { backgroundColor: 'lightgray', borderRadius: '3px' } }}
        onClick={handleClick}
      />
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {menuItems.map((menuItem) => (
          <MenuItem
            onClick={(e) => handleMenuItemClick(e, menuItem.callback)}
            key={menuItem.label}
            sx={{
              '&.Mui-focusVisible': {
                backgroundColor: 'white',
              },
              '&:hover': {
                backgroundColor: '#2879FB',
                color: 'white',
              },
              ...menuItem.style,
            }}
          >
            {menuItem.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
