import { Close, OpenInNew, Star } from "@mui/icons-material";
import { Box, IconButton, Stack, ThemeProvider, Typography } from "@mui/material";
import { SafeLeaseButton, SafeLeaseCard, SafeLeaseChip, theme } from "@safelease/components";
import { Image } from "../../image";
import { CalendlyButton } from "../../shared/CalendlyButton";
import { useAuth } from "../../auth";
import { useCalendlyEventListener } from "react-calendly";
import { mixpanelEventHandler } from "../../utilities/reactMixpanelHandler";
import { QueryResult, useQuery } from "@apollo/client";
import { Relationship } from "../../utilities/generated/gql-types";
import { getRelationship } from "../../queries";
import Cookies from "universal-cookie";
import { useEffect, useState } from "react";

export function ReputationBanner() {
  const auth = useAuth();
  const cookies = new Cookies();
  const [hidden, setHidden] = useState<boolean>(cookies.get("reputation-banner-visibility") === "hidden");

  // For whatever reason the introduction of the cookie doesn't force rerender, so this change lister forces
  // the page to rerender after the cookie has been set
  useEffect(() => {
    cookies.addChangeListener(() => {
      if (cookies.get("reputation-banner-visibility") === "hidden") {
        setHidden(true);
      } else {
        setHidden(false);
      }
    });
  }, []);

  const userRelationshipId = auth.user?.relationshipId;

  // Fetch or use the cache'd relationship details to derive the CSM and AM IDs
  const relationshipQuery: QueryResult<{ getRelationship: Relationship }> = useQuery(getRelationship, {
    variables: {
      id: userRelationshipId,
    },
    skip: !userRelationshipId,
  });

  /* Mixpanel / Calendly events */
  /* Capture each phase of the calendly conversion */
  useCalendlyEventListener({
    onDateAndTimeSelected: () =>
      mixpanelEventHandler("Reputation - Calendly Date Selected - SafeLease Home Page", {
        csmId: relationshipQuery?.data?.getRelationship?.csm?.id,
        amId: relationshipQuery?.data?.getRelationship?.am?.id,
        userId: auth.user.id,
        userRelationshipId,
      }),
    onEventTypeViewed: () =>
      mixpanelEventHandler("Reputation - Calendly Event Type Viewed - SafeLease Home Page", {
        csmId: relationshipQuery?.data?.getRelationship?.csm?.id,
        amId: relationshipQuery?.data?.getRelationship?.am?.id,
        userId: auth.user.id,
        userRelationshipId,
      }),
    onEventScheduled: (e) =>
      mixpanelEventHandler("Reputation - Calendly Event Scheduled - SafeLease Home Page", {
        csmId: relationshipQuery?.data?.getRelationship?.csm?.id,
        amId: relationshipQuery?.data?.getRelationship?.am?.id,
        userId: auth.user.id,
        userRelationshipId,
      }),
  });

  if (hidden) return null;

  return (
    <ThemeProvider theme={theme}>
      <SafeLeaseCard sx={{ p: 3, color: "white", position: "relative", bgcolor: "rgb(17, 48, 99)", mb: 2 }}>
        <Image src="/images/wavy_backdrop.png" style={{ position: "absolute", zIndex: 0, top: 0, left: 0, width: "100%", opacity: 0.2 }} />
        <Image
          src="/images/reputation_screenshot.png"
          style={{ position: "absolute", zIndex: 1000, bottom: 0, right: 16, height: "65%" }}
        />
        <Stack direction="column" spacing={1} sx={{ zIndex: 999, position: "relative" }}>
          <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
            <Stack direction="row" spacing={2} alignItems="center">
              <Star sx={{ color: "yellow.main" }} />
              <Typography variant="body1" color="white">
                Reputation Management
              </Typography>
              <SafeLeaseChip label="New" sx={{ bgcolor: "blue.main", color: "white" }} size="small" />
            </Stack>
            <IconButton
              sx={{ color: "white" }}
              onClick={() => {
                cookies.set("reputation-banner-visibility", "hidden");
              }}
            >
              <Close />
            </IconButton>
          </Stack>
          <Typography color="white" sx={{ fontWeight: 700, fontSize: "20px" }}>
            Automatically get reviews from your tenants at every facility
          </Typography>
          <Typography color="white" sx={{ color: "rgb(203,223,246)", maxWidth: "40%", fontSize: "12px" }}>
            Waiting for feedback won't build the reputation you deserve. Take control by reaching out directly to the tenants who know you
            best so they can share their positive experiences with your future tenants.
          </Typography>
          <Box>
            <CalendlyButton
              relationshipId={auth.user?.relationshipId}
              label="Schedule a Demo"
              linkName="reputation"
              sx={{ minWidth: 0, color: "white", borderColor: "white", mt: 2 }}
            />
            <SafeLeaseButton
              variant="text"
              sx={{ minWidth: 0, color: "white", borderColor: "white", mt: 2, ml: 2, height: 39 }}
              endIcon={<OpenInNew />}
              onClick={() => {
                mixpanelEventHandler("Reputation - Learn More Button Clicked - SafeLease Home Page");
                window.open("https://www.safelease.com/reputation-management", "_blank");
              }}
            >
              Learn More
            </SafeLeaseButton>
          </Box>
        </Stack>
      </SafeLeaseCard>
    </ThemeProvider>
  );
}
