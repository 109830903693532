import { useState } from "react";

// UI - libs
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
} from "@mui/material";

// Data
import BillingDataService from "../../../../../services/billing.service";
import { useQuery } from "@apollo/client";
import { getLlcs } from "../../../../../queries";
import { stateAbbr } from "../../../../../utilities/field-enums";
import Error from "../../../../../shared/Error";
import { useOrganizationSettings } from "../../useOrganizationSettingsStore";
import { enqueueSnackbar } from "notistack";
import { reactQueryClient } from "../../../../../utilities/reactQueryClent";
import { useAuth } from "../../../../../auth";

export type LlcErrors = {
  name?: { msg?: string };
  businessType?: { msg?: string };
  address?: { msg?: string };
  city?: { msg?: string };
  state?: { msg?: string };
  postalCode?: { msg?: string };
  ein?: { msg?: string };
};

// TODO: Pull in from service utilities
export enum TwilioBusinessType {
  PARTNERSHIP = "Partnership",
  CORPORATION = "Corporation",
  LIMITED_LIABILITY = "Limited Liability Corporation",
  SOLE_PROPRIETORSHIP = "Sole Proprietorship",
}

// NOTE: This component and the underlying DB Model/Table are named "LLC"
// but it can be other types of legal entities as well, as specified by the businessType field
export const LlcSettings = () => {
  const { selectedLlc: llc, fetchOrgSettingsByRelationshipId } = useOrganizationSettings();
  const auth = useAuth();
  const isAdmin = auth.user.isAdmin;
  const [llcData, setLlcData] = useState<any>(llc);
  const [llcErrors, setLlcErrors] = useState<LlcErrors>({});

  const llcsQuery = useQuery(getLlcs, {
    variables: { relationshipId: llc?.relationshipId },
    onCompleted: (data) => {
      setLlcData(data.getLlcs?.find((item) => item.id == llc?.id) || {});
      return data;
    },
  });

  // Set Legal Entity field
  const setLlcField = function (key, value) {
    const newLlc = { ...llcData };
    newLlc[key] = value;
    setLlcData(newLlc);
  };

  // Save Legal Entity
  const saveLlc = async function () {
    if (!llcDataIsValid()) return;

    setLlcErrors({});
    const newLlc = { ...llcData };
    newLlc.relationshipId = llc.relationshipId;

    // grab the first location that belongs to the llc, needed for api call to legacy endpoint
    const location = llc.locations.find((location) => (location.llcId = llc.id));
    if (!location) {
      enqueueSnackbar("Error saving Legal Entity, no Location found to attach Legal entity", { variant: "error" });
      return;
    }
    // creates or updates an LLC (badly named function)
    const response = await BillingDataService.addLlc(newLlc, location.id);

    if (response.data.errors) {
      setLlcErrors(response.data.errors);
      return;
    }

    enqueueSnackbar("Legal Entity saved successfully", { variant: "success" });
    llcsQuery.refetch();
    if (isAdmin) {
      fetchOrgSettingsByRelationshipId(llc.relationshipId);
    } else {
      reactQueryClient.refetchQueries({ queryKey: ["fetch-org-settings"] });
    }
  };

  // Validate Legal Entity data
  const llcDataIsValid = function () {
    setLlcErrors({});
    const updatedErrors: LlcErrors = {};

    // Validate Legal Entity Name
    if (!llcData.name) updatedErrors.name = { msg: "Legal Entity name is required" };
    // Validate Business Type
    if (!llcData.businessType) updatedErrors.businessType = { msg: "Business Type is required" };
    // Validate that EIN is exactly 9 digits long and contains only numbers
    if (!llcData.ein || !/^\d{9}$/.test(llcData.ein)) updatedErrors.ein = { msg: "EIN must be 9 digits, no dash" };
    // Validate Address exists
    if (!llcData.address) updatedErrors.address = { msg: "Address is required" };
    // Validate City exists
    if (!llcData.city) updatedErrors.city = { msg: "City is required" };
    // Validate State exists
    if (!llcData.state) updatedErrors.state = { msg: "State is required" };
    // Validate Postal Code exists
    if (!llcData.postalCode) updatedErrors.postalCode = { msg: "Postal Code is required" };

    setLlcErrors(updatedErrors);

    return Object.keys(updatedErrors).length === 0;
  };

  if (llcsQuery.loading) return <CircularProgress />;
  if (llcsQuery.error) return <Error />;

  return (
    <>
      <Paper
        sx={{
          maxHeight: 400,
          overflow: "auto",
          backgroundColor: "inherit",
          boxShadow: "none",
          padding: 1,
        }}
      >
        <div className="tw-flex tw-flex-col tw-items-start">
          <>
            <TextField
              name="llc"
              label={"Legal Entity"}
              value={llcData?.name || ""}
              type="llc"
              onChange={(e) => setLlcField("name", e.target.value)}
              helperText={llcErrors.name?.msg}
              sx={{ width: "100%", marginBottom: 1 }}
              FormHelperTextProps={{
                style: { color: "red" },
              }}
            />
            <FormControl fullWidth>
              <InputLabel id="business-type-selector-label">Business Type</InputLabel>
              <Select
                autoWidth
                id="business-type-selector"
                labelId="business-type-selector-label"
                label="Business Type"
                name="businessType"
                value={llcData?.businessType || ""}
                onChange={(e) => setLlcField("businessType", e.target.value)}
                sx={{ width: "100%" }}
              >
                {Object.values(TwilioBusinessType).map((type) => (
                  <MenuItem value={type} key={type}>
                    {type}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText sx={{ color: "red", marginBottom: "5px" }}>{llcErrors.businessType?.msg}</FormHelperText>
            </FormControl>
            <TextField
              name="ein"
              label={"EIN"}
              value={llcData?.ein || ""}
              type="ein"
              onChange={(e) => setLlcField("ein", e.target.value)}
              helperText={llcErrors.ein?.msg}
              sx={{ width: "100%", marginBottom: 1 }}
              FormHelperTextProps={{
                style: { color: "red" },
              }}
            />
            <TextField
              name="address"
              label={"Address"}
              value={llcData?.address || ""}
              type="text"
              onChange={(e) => setLlcField("address", e.target.value)}
              helperText={llcErrors.address?.msg}
              sx={{ width: "100%", marginBottom: 1 }}
              FormHelperTextProps={{
                style: { color: "red" },
              }}
            />
            <Grid className="split-input-fields" container spacing={2}>
              <Grid item xs={4} className="input-grid">
                <TextField
                  name="city"
                  label={"City"}
                  value={llcData?.city || ""}
                  type="text"
                  onChange={(e) => setLlcField("city", e.target.value)}
                  helperText={llcErrors.city?.msg}
                  sx={{ width: "100%", marginBottom: 1 }}
                  FormHelperTextProps={{
                    style: { color: "red" },
                  }}
                />
              </Grid>
              <Grid item xs={4} className="input-grid">
                <FormControl fullWidth>
                  <InputLabel id="state-selector-label">State</InputLabel>
                  <Select
                    autoWidth
                    id="state-selector"
                    labelId="state-selector-label"
                    label="State"
                    name="state"
                    value={llcData?.state || ""}
                    onChange={(e) => setLlcField("state", e.target.value)}
                    sx={{ width: "100%", marginBottom: 1 }}
                  >
                    {stateAbbr.map((state) => (
                      <MenuItem value={state} key={state}>
                        {state}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText sx={{ color: "red" }}>{llcErrors.state?.msg}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={4} className="input-grid">
                <TextField
                  name="postalCode"
                  label={"Postal Code"}
                  value={llcData?.postalCode || ""}
                  type="text"
                  onChange={(e) => setLlcField("postalCode", e.target.value)}
                  helperText={llcErrors.postalCode?.msg}
                  sx={{ width: "100%", marginBottom: 1 }}
                  FormHelperTextProps={{
                    style: { color: "red" },
                  }}
                />
              </Grid>
            </Grid>
            <FormControlLabel
              sx={{ marginRight: "-5px" }}
              control={
                <Checkbox checked={llcData?.billPerLocation || false} onChange={(e) => setLlcField("billPerLocation", e.target.checked)} />
              }
              label="Bill per Location"
            />
            {/*
              // omitting this until use case is better understood
              {llcData?.id && (
                <Button className="add-location-textfield" onClick={() => setLlcData({})}>
                  Disconnect LLC
                </Button>
              )} */}
          </>
        </div>
      </Paper>
      <Button className="modal-login-button" onClick={saveLlc}>
        Save Legal Entity
      </Button>
    </>
  );
};
