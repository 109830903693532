import React from 'react';
import { SxProps, Tab, TabProps } from '@mui/material';


interface SafeleaseTabProps extends TabProps {
  sx?: SxProps
}

// Extension of MUI's tab component with our own styles
export default function SafeleaseTab({
  sx={},
  ...props
}) {
  return (
    <Tab
      {...props}
      sx={{
        fontSize: 16,
        textTransform: 'none',
        '&:selected': {
          color: '#2879FB'
        },
        fontFamily: '"Open Sans", sans-serif',
        ...sx
      }}
    />
  )
}
