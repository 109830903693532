export type SelectOptions<T> = {
  value: T;
  label: string;
  queryParam?: string;
};

export enum NoteType {
  ClaimNote = 'claimNote'
}

export enum NotificationType {
  ClaimNote = 'claimNote',
  NeedAchAgreement = 'needAchAgreement',
  Invoice = 'invoice'
}

export enum SafeleaseStatus {
  Archived = 'archived',
  Test = 'test',
  InProgress = 'inProgress',
  Active = 'active',
  Canceled = 'canceled',
  ComingSoon = 'comingSoon',
}

export enum BillingStatus {
  Special = 'special',
  Verified = 'verified',
  Unsetup = 'unsetup',
  PartiallyVerified = 'partiallyVerified',
}

export enum FileGroups {
  Management = 'Management',
  Forms = 'Forms',
  TenantCommunication = 'TenantCommunication',
  Billing = 'Billing',
  Marketing = 'Marketing'
}

export enum FileTypes {
  PartnerAgreement = 'PartnerAgreement',
  AchInformation = 'AchInformation',
  InsurancePolicies = 'InsurancePolicies',
  AutopayTAndCSignature = 'AutopayTAndCSignature',
  AdditionalAttestations = 'AdditionalAttestations',
  APLetter = 'APLetter',
  ROLetter = 'ROLetter',
  CancellationNotice  = 'CancellationNotice',
  Invoices = 'Invoices',
  NewProductCampaigns = 'NewProductCampaigns',
  UpsellCampaigns = 'UpsellCampaigns',
  OnboardingMaterials = 'OnboardingMaterials',
  LeaseAddendum = 'LeaseAddendum',
  Brochures = 'Brochures',
  TermsOfService = 'TermsOfService',
}

export enum DocumentLevel {
  Relationship = 'Relationship',
  Llc = 'Llc',
  Location = 'Location',
  AllRelationships = 'AllRelationships'
}

enum PartnerAgreementSubCategories {
  Standard = 'Standard',
  StandardPlusRevenueShare = 'StandardPlusRevenueShare',
  Supplemental = 'Supplemental'
}

export enum Teams {
  Success = 'success',
  Support = 'support',
  Product = 'product',
}

export enum Roles {
  SuperAdmin = 'superAdmin',
  Admin = 'admin',
  Manager = 'manager',
  Employee = 'employee',
}

export enum BillingEntityStatuses {
  Unsetup = 'unsetup',
  Verified = 'verified',
}

export enum ClaimCause {
  BuildingCollapseOrFailure = 'Building Collapse/Failure',
  Burglary = 'Burglary',
  Fire = 'Fire',
  Mold = 'Mold',
  Other = 'Other',
  Rodent = 'Rodent',
  Vandalism = 'Vandalism',
  VehicleDamage = 'Vehicle Damage',
  WaterDamage = 'Water Damage',
  Windstorm = 'Windstorm'
}

export const ClaimCauseOptions: Readonly<SelectOptions<ClaimCause>[]> = 
    Object.keys(ClaimCause).map((key) => ({
      value: ClaimCause[key],
      label: ClaimCause[key],
    }));

export enum StatusTriage {
  Paid = 'Paid',
  InProgress = 'InProgress',
  Denied = 'Denied',
  Abandoned = 'Abandoned'
}

export const ClaimStatusOptions: Readonly<SelectOptions<StatusTriage>[]> =
  Object.keys(StatusTriage).map((key) => ({
    value: StatusTriage[key],
    label: StatusTriage[key],
  }));

export function undoClaimStatusTriage(status: string): string[] {
  if (status === StatusTriage.Paid) {
    return ['Settled - Paid'];
  } else if (status === StatusTriage.Denied) {
    return ['Settled - Denied'];
  } else if (status === StatusTriage.Abandoned) {
    //"Closed - Duplicate Claim" is intetionally missing from this list
    return ['Closed - Incomplete Form', 'Closed - Unknown'];
  }
  return [
    '15 Day Closing',
    'Tenant Move',
    'Follow Up',
    'Initiate Contact',
    'Follow Up - Diary',
    'Settlement Pending',
  ]
}

export function getClaimStatusFiltersFromTriageStatuses(triageStatuses: string[]): string[] {
  return triageStatuses.reduce((acc, status) => {
    return [...acc, ...undoClaimStatusTriage(status)];
  }, []);
}

export enum ProspectFms {
  All = 'All',
  None = 'None',
  ESS = 'ESS',
  WSS = 'WSS',
  SiteLink = 'SiteLink',
  StorEdge = 'StorEdge',
  Storable = 'Storable',
  SSM = 'SSM',
  Cubby = 'Cubby',
}

export enum ReportType {
  InsurancePenetration = 'InsurancePenetration',
  DailySummary = 'DailySummary',
  OccupancySummary = 'OccupancySummary',
}

export const ReportTypeOptions: Readonly<SelectOptions<ReportType>[]> = [
  { 
    value: ReportType.InsurancePenetration, 
    label: 'Insurance Penetration',
    queryParam: 'insurancePenetration' 
  },
  { 
    value: ReportType.DailySummary,
    label: 'Daily Summary',
    queryParam: 'dailySummary'
  },
  { 
    value: ReportType.OccupancySummary,
    label: 'Occupancy Summary',
    queryParam: 'occupancySummary'
  },
] as const

export const RolesHierarchy: {[key in Roles]: Roles[]} = {
  [Roles.SuperAdmin]: [Roles.SuperAdmin, Roles.Admin, Roles.Manager, Roles.Employee],
  [Roles.Admin]: [Roles.Admin, Roles.Manager, Roles.Employee],
  [Roles.Manager]: [Roles.Manager, Roles.Employee],
  [Roles.Employee]: [Roles.Employee],
}


// TODO this object should get constructed once globally, instead of everytime getFileTypeInfo is called
export const getFileTypeInfo = () => {
  let fileTypeInfo: {
    [key in FileTypes]?: {
      group: FileGroups,
      level: DocumentLevel,
      displayName: string,
      needsDate?: boolean,
      subCategories?: Readonly<SelectOptions<string>[]>,
      description?: string,
      clearance?: Roles, // No clearance defaults to admin
      otpRequiredToUpload?: boolean,
      otpRequiredToDelete?: boolean,
      link?: string,
    }
  } = {}
  fileTypeInfo[FileTypes.PartnerAgreement] = {
    group: FileGroups.Management,
    level: DocumentLevel.Relationship,
    description: 'The signed agreement between your self-storage business and SafeLease.',
    displayName: 'Partner Agreement',
    //TODO these values can be made lower camel case if we want to be consistent with the rest of the codebase, but 
    // we would have to change the values in the database
    subCategories: [
      {value: PartnerAgreementSubCategories.Standard, label: 'Standard'},
      {value: PartnerAgreementSubCategories.StandardPlusRevenueShare, label: 'Standard Plus Revenue Share'},
      {value: PartnerAgreementSubCategories.Supplemental, label: 'Supplemental'},
    ],
    clearance: Roles.Manager,
  }
  fileTypeInfo[FileTypes.AchInformation] = {
    group: FileGroups.Management,
    level: DocumentLevel.Llc,
    displayName: 'ACH Information',
    description: 'The most recent ACH information on file for payments to SafeLease.',
    otpRequiredToUpload: true,
    otpRequiredToDelete: true,
  }
  fileTypeInfo[FileTypes.InsurancePolicies] = {
    group: FileGroups.Management,
    level: DocumentLevel.Relationship,
    displayName: 'Insurance Policies',
    clearance: Roles.Manager,
    description: 'A complete listing of the coverage limits offered by your store and the associated tenant fees.',
  }
  fileTypeInfo[FileTypes.AutopayTAndCSignature] = {
    group: FileGroups.Forms,
    level: DocumentLevel.Llc,
    displayName: 'Autopay Terms and Conditions Signature',
  }
  fileTypeInfo[FileTypes.AdditionalAttestations] = {
    group: FileGroups.Forms,
    level: DocumentLevel.Location,
    displayName: 'Additional Attestations',
  }
  fileTypeInfo[FileTypes.APLetter] = {
    group: FileGroups.TenantCommunication ,
    level: DocumentLevel.Relationship,
    displayName: 'Auto-Protect Letter'
  }
  fileTypeInfo[FileTypes.ROLetter] = {
    group: FileGroups.TenantCommunication,
    level: DocumentLevel.Relationship,
    displayName: 'Rollover Letter'
  }
  fileTypeInfo[FileTypes.CancellationNotice]  = {
    group: FileGroups.TenantCommunication,
    level: DocumentLevel.Relationship,
    displayName: 'Cancellation Notice'
  }
  fileTypeInfo[FileTypes.Invoices] = {
    group: FileGroups.Billing,
    level: DocumentLevel.Llc,
    description: 'Monthly financial statements outlining protection program fees and payments due.',
    needsDate: true,
    clearance: Roles.Employee,
    displayName: 'Invoices'
  }
  fileTypeInfo[FileTypes.NewProductCampaigns] = {
    group: FileGroups.Marketing,
    level: DocumentLevel.Location,
    displayName: 'New Product Campaigns'
  }
  fileTypeInfo[FileTypes.UpsellCampaigns] = {
    group: FileGroups.Marketing,
    level: DocumentLevel.Location,
    displayName: 'Upsell Campaigns'
  }
  fileTypeInfo[FileTypes.OnboardingMaterials] = {
    group: FileGroups.Marketing,
    level: DocumentLevel.Relationship,
    displayName: 'Onboarding Materials'
  }
  fileTypeInfo[FileTypes.LeaseAddendum] = {
    group: FileGroups.Management,
    level: DocumentLevel.AllRelationships,
    displayName: 'Lease Addendum',
    clearance: Roles.Manager,
    description: 'The portion of your lease that includes tenant protection program details and requirements.',
  }
  fileTypeInfo[FileTypes.Brochures] = {
    group: FileGroups.Management,
    level: DocumentLevel.AllRelationships,
    displayName: 'Tenant Brochures',
    clearance: Roles.Employee,
    description: 'An electronic version of the SafeLease Tenant Brochure. This file may be shared with tenants via email or can be printed and distributed at your store.',
  },
  fileTypeInfo[FileTypes.TermsOfService] = {
    group: FileGroups.Management,
    level: DocumentLevel.AllRelationships,
    displayName: 'Terms of Service',
    clearance: Roles.Employee,
    description: 'The Terms of Service document(s) outline the contractual relationship between SafeLease and its customer, along with the legal obligations of both parties.',
    link: 'https://info.safelease.com/terms-of-service',
  }
  return fileTypeInfo
}

export const filterFileTypesByLevel = (documentLevel: DocumentLevel) => {
  const fileTypeInfo = getFileTypeInfo()
  let fileTypes = Object.keys(fileTypeInfo)
  fileTypes = fileTypes.filter(fileType => fileTypeInfo[fileType as FileTypes].level === documentLevel)
  return fileTypes
}

export const filterFileTypesByClearance = (userRole: Roles) => {
  const fileTypeInfo = getFileTypeInfo()
  let fileTypes = Object.keys(fileTypeInfo)
  fileTypes = fileTypes.filter(fileType => {
    //default to admin if no clearance level is specified
    const clearance = fileTypeInfo[fileType as FileTypes].clearance || Roles.Admin
    return RolesHierarchy[userRole].includes(clearance)
  })
  return fileTypes
}



export const days = () => {
  const retVal: number[] = []
  for (let i = 1; i <= 31; i++) {
    retVal.push(i)
  }
  return retVal.map(day => ({ value: day.toString() as string, label: day.toString() }))

}


export type editableAttachmentMetadata = 'subCategory' | 'serviceDate'

export const stateAbbr = ['AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'FL', 'GA', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY'];

export const occupiedUnitStatuses = ['Occupied', 'Late', 'Auction', 'Lien', 'Locked Out', 'Moving Out', 'Pre-Lien', 'Delinquent', 'Rented'];
export const reservedUnitStatuses = ['Pending', 'Reserved', 'Reserved (Marketplace)'];
export const vacantUnitStatuses = ['Available', 'Vacant'];
export const otherUnitStatuses = ['Needs Cleaning', 'Damaged'];
