import { QueryResult } from "@apollo/client";
import RouterHelper from "../../../../utilities/router-helper";
import PrivatePolicyComplianceModalMenu from "./PrivatePolicyComplianceModalMenu";
import { PrivatePolicyComplianceDrawerMenu } from "./PrivatePolicyComplianceDrawerMenu";
import { useEffect } from "react";
import { usePrivatePolicySettingsStore } from "../usePrivatePolicySettingsStore/usePrivatePolicySettingsStore";
import dayjs from "dayjs";
import { useSnackbar } from "notistack";
import privatePolicyComplianceConfigService from "../../../../services/private-policy-compliance-config.service";


type ConfigMenyProps = {
  routerHelper: RouterHelper;
  privatePolicyConfigurationsQuery: QueryResult;
};

function PrivatePolicyConfigMenu({
  privatePolicyConfigurationsQuery,
  routerHelper
}: ConfigMenyProps) {

  const configMenuOpen = usePrivatePolicySettingsStore((state) => state.configMenuOpen);
  const setInternalEnforcementEnabled = usePrivatePolicySettingsStore((state) => state.setInternalEnforcementEnabled);
  const setFmsEnforcementEnabled = usePrivatePolicySettingsStore((state) => state.setFmsEnforcementEnabled);
  const setSelectedFmsEnforcementDate = usePrivatePolicySettingsStore((state) => state.setSelectedFmsEnforcementDate);
  const setSelectedIntEnforcementDate = usePrivatePolicySettingsStore((state) => state.setSelectedIntEnforcementDate);
  const getComplianceUpdateObject = usePrivatePolicySettingsStore((state) => state.getComplianceUpdateObject);
  const setConfigMenuOpen = usePrivatePolicySettingsStore((state) => state.setConfigMenuOpen);
  // selectedLocationId is the locationId of the location selected in the location selector when in advanced mode for certain users
  // it will change what the list of location Ids to update is based on the location selected
  const selectedLocationId = usePrivatePolicySettingsStore((state) => state.selectedLocationId);


  const { enqueueSnackbar } = useSnackbar();

  const featureFlags = routerHelper.getFeatureFlags();

  const privatePolicyConfigs = privatePolicyConfigurationsQuery.data?.getPrivatePolicyConfigurations || [];

  useEffect(() => {
    const initialConfig = selectedLocationId ? privatePolicyConfigs.find((config) => (config.locationId) === selectedLocationId.toString()) : privatePolicyConfigs[0];
    const fmsEnforcementDate = initialConfig?.fmsEnforcementDate;
    const intEnforcementDate = initialConfig?.intEnforcementDate;
    setFmsEnforcementEnabled(!!fmsEnforcementDate);
    setSelectedFmsEnforcementDate(fmsEnforcementDate ? dayjs(fmsEnforcementDate) : dayjs());
    setInternalEnforcementEnabled(!!intEnforcementDate);
    setSelectedIntEnforcementDate(intEnforcementDate ? dayjs(intEnforcementDate) : dayjs());
  }, [configMenuOpen, selectedLocationId, setSelectedFmsEnforcementDate, setSelectedIntEnforcementDate]);

  const handleApply = async () => {
    const locationIds = selectedLocationId ? [selectedLocationId] : privatePolicyConfigs.map((config: any) => config.locationId);// all locations get chosen if not in advanced mode

    const updateObject = getComplianceUpdateObject(featureFlags.includes('private-policy-compliance-config-superAdmin'));
    let success = false;
    try {
      await privatePolicyComplianceConfigService.update({
        locationIds,
        ...updateObject
      });
      enqueueSnackbar("Private Policy Compliance Configuration Updated", { variant: "success", autoHideDuration: 1000 });
      success = true;
    } catch (e) {
      console.error(e);
      enqueueSnackbar("Error updating Private Policy Compliance Configuration", { variant: "error", autoHideDuration: 1000 });
    }
    privatePolicyConfigurationsQuery.refetch();
    handleClose();
  };

  const handleClose = () => {
    setConfigMenuOpen(false);
  };

  if (featureFlags.includes('private-policy-compliance-config-superAdmin')) {
    return <PrivatePolicyComplianceDrawerMenu
      handleApply={handleApply}
      handleClose={handleClose}
      relationshipId={routerHelper?.getRelationshipId()}
      privatePolicyConfigs={privatePolicyConfigs}
    />;
  }
  return <PrivatePolicyComplianceModalMenu
    handleApply={handleApply}
    handleClose={handleClose}
    privatePolicyConfigs={privatePolicyConfigs}
  />;
}

export { PrivatePolicyConfigMenu };
