import { ApolloClient, HttpLink, ApolloLink, InMemoryCache } from '@apollo/client';
import { onError } from "@apollo/client/link/error";
import Cookies from 'universal-cookie';

const reload = function() {
  console.log("Build mismatch, trying to refresh");

  // Only do a build-driven reload once per hour.  This prevents the nightmare scenario of a reload loop that makes the app unusable.
  const cookies = new Cookies();
  if(cookies.get('refreshed')) return;

  var expires = new Date();
  expires.setHours(expires.getHours() + 1);
  cookies.set('refreshed', true, {expires: expires});
  
  window.location.reload();
};

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if(networkError?.response?.status === 401) {
    window.location = "/signin";
    localStorage.removeItem('user');
  }

  if(graphQLErrors) {
    for(var error of graphQLErrors) {
      if(error.build != (process.env.REACT_APP_BUILD || '')) reload(); // If there is a mismatched build in an error message, reload.
    }
  }
});

const httpLink = new HttpLink({ uri: '/graphql' });

const authMiddleware = new ApolloLink((operation, forward) => {
  var user = localStorage.getItem('user');
  if(user) {
    user = JSON.parse(user);

    // add the authorization to the headers
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        authorization: "Bearer " + user.accessToken,
      }
    }));
  }

  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      'X-SafeLease-Build': process.env.REACT_APP_BUILD || '', // Supply the frontend build number as a header with every request.
    }
  }));

  return forward(operation);
})

const buildHandler = new ApolloLink((operation, forward) => {
  return forward(operation).map((data) => {
    const build = data.extensions.build;
    if(build != (process.env.REACT_APP_BUILD || '')) {
      // If there is a mismatched build in a non-error message, reload.
      if (!window.location.pathname.includes('admin/relationships')) {
        reload();
      }
    }
    return data;
  });
});

const merge = function merge(existing, incoming, { args }) {
  const merged = existing?.edges ? existing.edges.slice(0) : [];
  if (args) {
    const { offset = 0 } = args;
    for (let i = 0; i < incoming.edges.length; ++i) {
      merged[offset + i] = incoming.edges[i];
    }
  } else {
    merged.push.apply(merged, incoming.edges);
  }
  return {pageInfo: incoming.pageInfo, edges: merged};
};



const client = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          getUsers: {
            keyArgs: ['relationshipId'],
            merge: merge,
          },
          getAccounts: {
            keyArgs: ['relationshipId'],
          },
          getLocations: {
            keyArgs: ['relationshipId'],
          },
        }
      }
    }
  }),
  link: ApolloLink.from([
    errorLink,
    authMiddleware,
    buildHandler,
    httpLink,
  ]),
});

export default client
