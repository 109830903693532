import { useQuery } from "@apollo/client";
import { create } from "zustand";
import { getPrivatePolicies } from "../../queries";
import _ from "lodash";
import { PrivatePolicy } from "../../utilities/generated/gql-types";

export enum SectionType {
  TENANT_NAME = "tenantName",
  POLICY_NUMBER = "policyNumber",
  EXPIRATION_DATE = "expirationDate",
}

export enum FieldValueSource {
  TENANT_INPUT = "tenantInput",
  AI_EXTRACTED = "aiExtracted",
  MANUAL_OVERRIDE = "manualOverride",
}

export type ReviewFormState = {
  tenantName: {
    valueSource: string | null;
    value: string | null;
  };
  policyNumber: {
    valueSource: string | null;
    value: string | null;
  };
  expirationDate: {
    valueSource: string | null;
    value: string | null;
  };
};
interface UseComplianceCenterStore {
  // Private policies pagination
  // Returning too many private policies isn't viable due to Lambda response limits
  limit: number;
  setLimit: (limit: number) => void;
  offset: number;
  setOffset: (offset: number) => void;

  privatePolicies: PrivatePolicy[];
  setPrivatePolicies: (privatePolicies: PrivatePolicy[]) => void;

  // Update one private policy locally
  updatePrivatePolicy: (privatePolicy: PrivatePolicy) => void;

  loadingComplete: boolean;
  setLoadingComplete: (loadingComplete: boolean) => void;
}

export const useComplianceCenterStore = create<UseComplianceCenterStore>(
  (set) => ({
    // Private policies pagination
    // Returning too many private policies isn't viable due to Lambda response limits
    limit: 4000,
    setLimit: (limit) => set({ limit }),
    offset: 0,
    setOffset: (offset) => set({ offset }),

    privatePolicies: [],

    // Update one private policy locally
    updatePrivatePolicy: (privatePolicy: PrivatePolicy) =>
      set((state) => ({
        privatePolicies: state.privatePolicies.map((p) =>
          p.id === privatePolicy.id ? privatePolicy : p,
        ),
      })),

    // Append new private policies to the end of the list because we fetch them piecewise
    setPrivatePolicies: (privatePolicies) => {
      return set((state) => {
        const combined = [...state.privatePolicies, ...privatePolicies ]
        return {
          privatePolicies: combined,
        };
      });
    },
    loadingComplete: false,
    setLoadingComplete: (loadingComplete) => set({ loadingComplete }),
  }),
);

// Hook available to use in components
export const useComplianceCenter = () => {
  const {
    limit,
    offset,
    setLimit,
    setOffset,
    privatePolicies,
    setPrivatePolicies,
    setLoadingComplete,
  } = useComplianceCenterStore();

  const privatePoliciesQuery = useQuery(getPrivatePolicies, {
    variables: {
      limit,
      offset,
    },
    onCompleted: (data) => {
      setPrivatePolicies(data.getPrivatePolicies);
      if (data.getPrivatePolicies?.length === 4000) {
        setOffset(offset + 4000);
      } else {
        setLoadingComplete(true);
      }
    },
  });

  return {
    loading: privatePoliciesQuery.loading,

    // For some unknown reason as of 2/12/2024 there are sometimes duplicates sent back from the server
    // Any duplicates in the returned list cause render errors in the MUI data grid
    // which leans heavily on unique IDs for each row
    privatePolicies: privatePolicies,

    limit,
    offset,
    setLimit,
    setOffset,
  };
};
