import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { TableStyles } from './TableStyles';
import { ColumnDef } from '../../utilities/column-defs';

export default function ReportTable(props) {
  const { rows, columns, totals } = props;

  return (
    <div style={TableStyles.wrapper}>
      <TableContainer>
        <Table size="small" sx={{ minWidth: 650 }} aria-label="simple table">
          <ReportTableHeader columns={columns} />
          <TableBody>
            {rows.map(row => (
              <ReportTableRow key={row.id} row={row} columns={columns} />
            ))}
            {totals && <ReportTableTotals totals={totals} columns={columns} />}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

function ReportTableRow(props) {
  const { columns, row } = props;

  return (
    <TableRow>
      {columns.map((column: ColumnDef) => {
        let value = row;
        for (const part of column.key.split('.')) {
          value = value?.[part];
        }

        return (
          <TableCell
            sx={{
              ...TableStyles.cell,
              ...column.styles,
            }}
            key={`${column.key}:${row.id}:${value}`}
          >
            {column.renderer ? column.renderer(value, row, column.renderOptions) : value}
          </TableCell>
        );
      })}
    </TableRow>
  );
}

function ReportTableTotals(props) {
  const { totals, columns } = props;

  return (
    <TableRow>
      <TableCell sx={{ minWidth: '18rem', position: 'sticky', left: 0, backgroundColor: 'white' }}>
        Totals
      </TableCell>
      {columns.map((column, i) => {
        if (i === 0) return null;
        return (
          <TableCell sx={{ ...TableStyles.cell, fontWeight: 'bold' }} key={`total:${column.key}`}>
            {totals[column.key] || totals[column.key] === 0 // support a number type 0 as a total
              ? column.renderer
                ? column.renderer(totals[column.key], null, column.renderOptions || {})
                : totals[column.key]
              : ''}
          </TableCell>
        );
      })}
    </TableRow>
  );
}

function ReportTableHeader(props) {
  const { columns } = props;

  return (
    <TableHead>
      <TableRow>
        {columns.map((column: ColumnDef) => {
          return (
            <TableCell
              sx={{
                ...TableStyles.header,
                ...column.styles,
              }}
              key={`head:${column.title}`}
            >
              {column.title}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}
