import React, { useState } from "react";
import { MenuItem, Select, Typography } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Box } from "@mui/system";
import ThemedButton from "../shared/ThemedButton";
import GoBackButton from "./GoBackButton";

interface LocationSelectProps {
  value: string;
  onChange: (event) => void;
  label: string;
  menuItems: string[];
}

const LocationSelect: React.FC<LocationSelectProps> = ({ value, onChange, menuItems, label }) => {
  return (
    <>
      <Typography
        mb={0.5}
        sx={{
          fontSize: "12px",
          fontWeight: 500,
          color: "#152744",
        }}
      >
        {label}
      </Typography>
      <Select
        variant="outlined"
        value={value}
        onChange={onChange}
        IconComponent={KeyboardArrowDownIcon}
        sx={{
          width: "100%",
          height: "40px",
        }}
      >
        {menuItems.map((location: string) => (
          <MenuItem key={location} value={location}>
            {location}
          </MenuItem>
        ))}
      </Select>
    </>
  );
};

interface MoveExistinLocationViewProps {
  handleGoBackClick: () => void;
}

const MoveExistingLocationView: React.FC<MoveExistinLocationViewProps> = ({ handleGoBackClick }) => {
  const [existingLocation, setExistingLocation] = useState("123 Maple Street");
  const [moveTo, setMoveTo] = useState("");
  const existingLocationMenuItems = ["123 Maple Street", "123 Main Street", "123 Center Street"];

  const handleExistingLocationChange = (event) => {
    setExistingLocation(event.target.value);
  };

  const handleMoveToChange = (event) => {
    setMoveTo(event.target.value);
  };

  return (
    <>
      <GoBackButton onClick={handleGoBackClick} />

      <Box mt={2}>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: 700,
            fontColor: "#031E30",
          }}
        >
          Moving Locations
        </Typography>
        <Typography
          mt={1}
          sx={{
            fontSize: "12px",
            fontWeight: 500,
            color: "#E9645F",
            lineHeight: "16px",
          }}
        >
          All locations need to live within a legal entity. You are about to move your location to a desired legal entity. Please ensure
          this is the intended action to avoid any issues.
        </Typography>
      </Box>
      <Box mt={2}>
        <LocationSelect
          label="Existing location"
          value={existingLocation}
          onChange={handleExistingLocationChange}
          menuItems={existingLocationMenuItems}
        />
      </Box>
      <Box mt={2}>
        <LocationSelect label="Move to:" value={moveTo} onChange={handleMoveToChange} menuItems={existingLocationMenuItems} />
      </Box>
      <Typography
        mt={1}
        sx={{
          color: "#929292",
          fontWeight: 500,
          fontSize: "12px",
        }}
      >
        Choose under which legal entity you want to move your facility.
      </Typography>
      <Box
        mt={2}
        sx={{
          display: "flex",
          justifyContent: "right",
        }}
      >
        <ThemedButton onClick={() => console.log("CLICKED")} styles={{ width: "80px", marginRight: "15px" }}>
          Cancel
        </ThemedButton>
        <ThemedButton dark onClick={() => console.log("CLICKED")} styles={{ width: "133px" }}>
          Move location
        </ThemedButton>
      </Box>
    </>
  );
};

export default MoveExistingLocationView;
