import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { ColumnDef } from '../../utilities/column-defs';
import SettingsIcon from '@mui/icons-material/Settings';

export default function TotalsDropdown(props) {
  const { columns, totals, selectedTotals, setSelectedTotals, restoreDefaults } = props;

  return (
    <Select
      variant="standard"
      multiple
      value={selectedTotals}
      displayEmpty={true}
      renderValue={value => <SettingsIcon sx={{ color: 'gray', cursor: 'pointer' }} />}
      onChange={e => setSelectedTotals(e.target.value as string[])}
    >
      <MenuItem onClick={restoreDefaults}>Restore Defaults</MenuItem>
      {columns.map((column: ColumnDef) => {
        if (totals?.[column.key] || totals?.[column.key] === 0) { // also support the number type 0
          return (
            <MenuItem
              disabled={!selectedTotals.includes(column.key) && selectedTotals.length >= 5}
              sx={{ height: '2.5rem' }}
              value={column.key}
              key={column.key}
            >
              <Checkbox checked={selectedTotals.includes(column.key)} />
              {column.title}
            </MenuItem>
          );
        }
        return null;
      })}
    </Select>
  );
}
