import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import SalesforceDataServices from "../services/salesforce.service";
import RelationshipDataVisualizer from "./RelationshipDataVisualizer";
import LocationDataVisualizer from "./LocationDataVisualizer";
import LlcDataVisualizer from "./LlcDataVisualizer";
import NoChangesDetectedCard from "./NoChangesDetectedCard";
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import { useSnackbar } from "notistack";
import { checkForSyncChanges } from "./helper-functions/salesforceHelpers";
import DOMPurify from 'dompurify';
import ConfirmDialog from "./sharedComponents/ConfirmDialog";

const SyncOnOpportunityLocationId = () => {
  const params: { opportunityLocationId: string; } = useParams();
  const [syncDataForOpportunityLocation, setSyncDataForOpportunityLocation] = useState<SyncDataForOpportunityLocation | null>(null);
  const [relationshipData, setRelationshipData] = useState<RelationshipSyncMetaData | null>(null);
  const [llcData, setLlcData] = useState<LlcSyncMetaData | null>(null);
  const [locationData, setLocationData] = useState<LocationSyncMetaData | null>(null);
  const [getSyncDataLoading, setGetSyncDataLoading] = useState<boolean>(false);
  const [syncRequestLoading, setSyncRequestLoading] = useState<boolean>(false);
  const [tryAgainShown, setTryAgainShown] = useState<boolean>(false);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [confirmSync, setConfirmSync] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();

  const getDesiredSyncDataForOpportunityLocation = async () => {
    setGetSyncDataLoading(true);
    try {
      const response = await SalesforceDataServices.getDesiredSyncDataForOpportunityLocation({ opportunityLocationId: params.opportunityLocationId });
      // TODO: detect failed attempts to getDesiredSyncDataForOpportunity and show the Error screen
      setRelationshipData(checkForSyncChanges(response.data.relationship) ? response.data.relationship : null);
      setLocationData(checkForSyncChanges(response.data.location) ? response.data.location : null);
      setLlcData(checkForSyncChanges(response.data.llc) ? response.data.llc : null);
      setSyncDataForOpportunityLocation(response.data);
      setTryAgainShown(false);
    } catch (error) {
      const sanitizedHtml = DOMPurify.sanitize(error.response.data.error);
      enqueueSnackbar(<div dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />, { variant: "error" , autoHideDuration: 10000});
      setTryAgainShown(true);
    }
    setGetSyncDataLoading(false);
  };

  useEffect(() => {
    getDesiredSyncDataForOpportunityLocation();
  }, []);

  const handleConfirmDialogOpen = () => {
    if (locationData && locationData.createNew) {
      setDialogOpen(true);
    } else {
      performDataSync();
    }
  };

  const handleConfirmDialogClose = () => {
    setDialogOpen(false);
  };

  const handleConfirmSync = async () => {
    setDialogOpen(false);
    setConfirmSync(true);
  };

  useEffect(() => {
    if (confirmSync) {
      performDataSync();
      setConfirmSync(false);
    }
  }, [confirmSync]);

  const performDataSync = async () => {
    setSyncRequestLoading(true);
    try {
        const response = await SalesforceDataServices.performDataSyncForOpportunityLocation(syncDataForOpportunityLocation);
        enqueueSnackbar('Successfully synced data.', { variant: "success" });
    } catch (error) {
      enqueueSnackbar(error.response?.data?.error ? error.response.data.error : "error syncing data" , { variant: "error" });
    } finally {
        setSyncRequestLoading(false);
        getDesiredSyncDataForOpportunityLocation();
    }
  };

  if (getSyncDataLoading) return <CircularProgress />;
  if (tryAgainShown) return (
    <div>
      <h5>Failed to retrieve sync data</h5>
      <Button sx={{ marginTop: "15px" }} variant='contained' onClick={getDesiredSyncDataForOpportunityLocation}>
        Try again
      </Button>
    </div>
  );

  if (!relationshipData && !llcData && !locationData) return (
    <h5>
      No changes detected to sync
    </h5>
  );

  return (
    <div>
      <div>
        <h3>Relationship</h3>
        {
          relationshipData ?
            (
              <RelationshipDataVisualizer relationshipData={relationshipData} />
            ) :
            (
              <NoChangesDetectedCard />
            )
        }
      </div>
      <div>
        <h3>LLC</h3>
        {
          llcData ?
            (
              <LlcDataVisualizer llcData={llcData} />
            ) :
            (
              <NoChangesDetectedCard />
            )
        }
      </div>
      <div>
        <h3>Location</h3>
        {
          locationData ?
            (
              <LocationDataVisualizer locationData={locationData} />
            ) :
            (
              <NoChangesDetectedCard />
            )
        }
      </div>
      <LoadingButton sx={{ marginTop: "15px" }} color="error" loading={syncRequestLoading} variant='contained' onClick={handleConfirmDialogOpen}>
        <span>Sync data</span>
      </LoadingButton>
      
      <ConfirmDialog
        open={dialogOpen}
        title="Create New Location"
        description="We are going to create a new location based on the address in the FMS, please confirm you want to do this."
        onClose={handleConfirmDialogClose}
        onConfirm={handleConfirmSync}
      />
    </div>
  );
};

export default SyncOnOpportunityLocationId;
