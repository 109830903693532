import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';

export default function Error() {
  return (
    <Box>
      <Alert severity="error">
        Error fetching data. Please try again later. This occurrence has been automatically reported to
        SafeLease.
      </Alert>
    </Box>
  );
}
