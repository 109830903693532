// UI - libs
import { Button, TextField } from "@mui/material";

// UI - internal
import { Image } from "../../image";
import { ghostButtonNavy } from "../../styles/mui-overrides";
import DatePicker from "../../components/lib/SafeLeaseDatePicker";

// Data
import SetupDataService from "../../services/setup.service";
import { fmsData } from "../../utilities/fms-data";
import { Location } from "../../utilities/generated/gql-types";
import { LocationSettingsErrors } from "../../shared/LocationSettingsModal";
import { getLocations } from "../../queries";
import { useApolloClient } from "@apollo/client";
import { useState } from "react";

interface LocationSettingsFacilityDetailsProps {
  location: Location;
  isAdmin: boolean;
  loading: boolean;
  setLoading: (val: boolean) => void;
  setErrors: (errors: LocationSettingsErrors) => void;
  setErrorMessage: (message: string) => void;
  onClose: () => void;
}

export default function FacilitySetup({
  location,
  isAdmin,
  loading,
  setLoading,
  setErrors,
  setErrorMessage,
  onClose,
}: LocationSettingsFacilityDetailsProps) {
  const client = useApolloClient();

  const [setupDate, setSetupDate] = useState<Date>(
    location.setupDate || new Date()
  );

  const rerunSetup = async function () {
    const confirmed = window.confirm("Are you sure you want to rerun setup?");
    if (!confirmed) return;
    setLoading(true);

    const response = await SetupDataService.rerunSetup({
      locationId: location.id,
    });
    setLoading(false);
    if (response.data.errors) {
      setErrors(response.data.errors);
      setErrorMessage("A problem occurred while rerunning setup.");
      return;
    }
  };

  const verifySetup = async function () {
    setLoading(true);
    setErrors({});
    const response = await SetupDataService.addLocation({
      ...location,
      setupVerified: true,
    });
    setLoading(false);

    if (response.data.errors) {
      setErrors(response.data.errors);
      setErrorMessage("A problem occurred while verifying setup.");
      return;
    }

    onClose();
  };

  return (
    <div className="tw-flex tw-flex-col tw-items-start">
      {location?.accountId ? (
        <>
          <div className="tw-text-left">
            This facility is confirmed
            {location?.serviceId &&
              ` and matched to ${
                fmsData[location.accountType].name
              } location (Service ID: ${location?.serviceId})`}
            .
            <span className="badge">
              <Image src="/images/checkmark.svg" alt="checkmark" />
            </span>
          </div>
          {!location?.unsupported && (
            <>
              {location?.setupCompletedAt ? (
                <div className="tw-flex tw-flex-row">
                  <p>
                    {`Setup completed on ${
                      location.setupCompletedAt.split("T")[0]
                    }.`}
                    <span className="badge">
                      <Image src="/images/checkmark.svg" alt="checkmark" />
                    </span>
                  </p>
                  {isAdmin && !location.setupVerified && (
                    <Button
                      sx={ghostButtonNavy}
                      disabled={loading}
                      onClick={rerunSetup}
                    >
                      Rerun Setup
                    </Button>
                  )}
                </div>
              ) : (
                !location?.setupVerified && (
                  <div className="tw-mt-4 tw-mb-4 tw-flex tw-flex-row">
                    <DatePicker
                      label={"Setup Date"}
                      onChange={(newDate) => setSetupDate(newDate)}
                      value={setupDate}
                    />
                    <Button
                      sx={{
                        backgroundColor: "#e9645f",
                        color: "white",
                        marginLeft: 3,
                        "&:hover": {
                          backgroundColor: "#e3413b",
                        },
                      }}
                      onClick={async () => {
                        setLoading(true);
                        await SetupDataService.setupLocationOnFms({
                          locationId: location.id,
                          setupDate,
                        });
                        setLoading(false);
                        client.refetchQueries({ include: [getLocations] });
                      }}
                      disabled={loading}
                    >
                      {location.setupDate
                        ? "Reschedule Setup"
                        : "Schedule Setup"}
                    </Button>
                  </div>
                )
              )}
            </>
          )}
          <>
            {location?.setupVerified ? (
              <div className="tw-text-left">
                {location.unsupported
                  ? "This facility's reporting setup is complete."
                  : "This facility's setup has been manually verifed."}
                <span className="badge">
                  <Image src="/images/checkmark.svg" alt="checkmark" />
                </span>
              </div>
            ) : (
              <>
                {isAdmin && (
                  <Button
                    className="modal-login-button"
                    style={{ maxWidth: 400, width: "fit-content" }}
                    onClick={verifySetup}
                  >
                    {location.unsupported
                      ? "Reporting Setup Complete"
                      : "Manually Verify Setup"}
                  </Button>
                )}
              </>
            )}
          </>
        </>
      ) : (
        <p>
          This facility is not connected to an account.
          <span className="badge">
            <Image src="/images/error-image.svg" alt="error" />
          </span>
        </p>
      )}
    </div>
  );
}
