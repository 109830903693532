// UI - libs
import {
  Badge,
  Box,
  LinearProgress,
  Paper,
  Stack,
  Theme,
  Typography,
} from "@mui/material";

// UI - internal
import SafeleaseTabs from "../../shared/SafeleaseTabs";
import SafeleaseTab from "../../shared/safelease-tab";
import SafeleaseDataGrid from "../../shared/safelease-data-grid";
import { complianceCenterGridColDefs } from "./complianceCenterGridColDefs";
import { ReviewPrivatePolicyDialog } from "./ReviewPrivatePolicyDialog";
import TitleHeader from "../../shared/title-header";

// Hooks
import { useMemo, useState } from "react";
import { useComplianceCenter } from "./useComplianceCenter";
import useWindowSize from "../../utilities/useWindowSize";

// Data
import { PrivatePolicy } from "../../utilities/generated/gql-types";

// Utils
import _ from "lodash";
import { blue, red } from "@mui/material/colors";
import { Formatters } from "../../utilities/formatters";

enum ComplianceCenterTabs {
  ALL = "all",
  FLAGGED = "humanReview",
  ACCEPTED = "accepted",
  REJECTED = "rejected",
  ARCHIVED = "archived",
}

interface ComplianceCenterProps {}

export function ComplianceCenter({}: ComplianceCenterProps) {
  const size = useWindowSize();

  const [selectedPrivatePolicy, setSelectedPrivatePolicy] =
    useState<PrivatePolicy | null>(null);

  const { privatePolicies, loading } = useComplianceCenter();

  const [currentTab, setCurrentTab] = useState<ComplianceCenterTabs>(
    ComplianceCenterTabs.FLAGGED,
  );

  // Change filter status of shown private policies
  const handleChangeFilterTab = (
    _: React.SyntheticEvent,
    newCurrentTab: ComplianceCenterTabs,
  ) => {
    setCurrentTab(newCurrentTab);
  };

  const numFlaggedPolicies = useMemo(() => {
    return privatePolicies.filter(
      (privatePolicy: PrivatePolicy) =>
        privatePolicy.validationStatus === ComplianceCenterTabs.FLAGGED,
    ).length;
  }, [privatePolicies]);

  return (
    <Box>
      <TitleHeader title="Compliance Center" />
      <Paper
        elevation={0}
        sx={{
          boxShadow: "0 0 20px rgba(0,0,0,0.05)",
          borderRadius: 2,
          bgcolor: (theme) => theme.palette.background.paper,
          p: 2,
          mt: 2,
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ overflow: "visible" }}
        >
          <SafeleaseTabs value={currentTab} onChange={handleChangeFilterTab}>
            <SafeleaseTab
              value={ComplianceCenterTabs.FLAGGED}
              sx={{ overflow: "visible" }}
              label={
                <Typography>
                  Flagged{" "}
                  <Typography
                    component="span"
                    variant="body2"
                    sx={{
                      ml: 1,
                      bgcolor: blue[50],
                      color: blue[600],
                      fontWeight: 700,
                      p: '2px 6px',
                      borderRadius: 1,
                      boxShadow: '0 0 20px rgba(0,0,0,0.05)'
                    }}
                  >
                    {Formatters.formatWithCommas(numFlaggedPolicies)}
                  </Typography>
                </Typography>
              }
            />
            <SafeleaseTab
              value={ComplianceCenterTabs.ACCEPTED}
              label="Accepted"
            />
            <SafeleaseTab
              value={ComplianceCenterTabs.REJECTED}
              label="Rejected"
            />
            <SafeleaseTab
              value={ComplianceCenterTabs.ARCHIVED}
              label="Archived"
            />
            <SafeleaseTab value={ComplianceCenterTabs.ALL} label="All" />
          </SafeleaseTabs>
          <Typography variant="body2">
            Fetched {privatePolicies.length} private policies
          </Typography>
        </Stack>
        <Box sx={{ height: size.height - 340, mt: 2 }}>
          <SafeleaseDataGrid
            getRowId={(row) => row.id}
            clickable
            pagination
            hideFooter={false}
            pageSizeOptions={[50, 100, 250]}
            hideFooterRowCount={false}
            // If loading, don't render any rows
            // De-dupe any rows that are the same
            rows={privatePolicies.filter((privatePolicy: PrivatePolicy) =>
              currentTab === ComplianceCenterTabs.ALL
                ? true
                : privatePolicy.validationStatus === currentTab,
            )}
            columns={complianceCenterGridColDefs}
            autosizeOptions={{
              includeHeaders: true,
              expand: true,
            }}
            onRowClick={({ row }) =>
              setSelectedPrivatePolicy(row as PrivatePolicy)
            }
            initialState={{
              sorting: {
                sortModel: [{ field: "validationStatus", sort: "asc" }],
              },
              columns: {
                columnVisibilityModel: {
                  id: false,
                  rejectionReason: false,
                },
              },
              pagination: {
                paginationModel: {
                  pageSize: 100,
                },
              },
            }}
            loading={loading}
            slots={{
              loadingOverlay: LinearProgress,
            }}
            sx={{
              "& p": {
                // For some reason, by default our footer paragraphs have 1em bottom margin
                marginBottom: 0,
              },
            }}
          />
        </Box>
        <ReviewPrivatePolicyDialog
          open={Boolean(selectedPrivatePolicy)}
          handleClose={() => setSelectedPrivatePolicy(null)}
          privatePolicy={selectedPrivatePolicy}
        />
      </Paper>
    </Box>
  );
}
