import { StateCreator } from "zustand";

type LocationId = number;
type Premium = string;
type Coverage = string;

type LocationSKUMap = {
  [key: Premium]: {
    [key: Coverage]: Set<LocationId>;
  };
};

export interface PlanUpgradeSettingsSKUsSliceState {
  numberOfImpactedUnits: number;
  skusAtSelectedLocations: LocationSKUMap;
}

export interface PlanUpgradeSettingsSKUsSlice
  extends PlanUpgradeSettingsSKUsSliceState {
  setNumberOfImpactedUnits: (numberOfImpactedUnits: number) => void;
  setSKUsAtSelectedLocations: (skus: LocationSKUMap) => void;
}

/* Keep track of how SKUs are mapped to units at locations */
export const createPlanUpgradeSettingsSKUsSlice: StateCreator<
  PlanUpgradeSettingsSKUsSlice
> = (set) => {
  return {
    skusAtSelectedLocations: {},
    setSKUsAtSelectedLocations: (skus: LocationSKUMap) => {
      set({
        skusAtSelectedLocations: skus,
      });
    },
    numberOfImpactedUnits: 0,
    setNumberOfImpactedUnits: (numberOfImpactedUnits: number) => {
      set({
        numberOfImpactedUnits,
      });
    },
  };
};
