import React from "react";
import { OrgRowBox } from "./OrganizationStyledComponents";
import { Typography, Avatar, InputAdornment, Button, TextField, Divider, styled } from "@mui/material";
import { Box } from "@mui/system";
import { FlexColumnBox, FlexRowBox } from "./OrganizationStyledComponents";
import { useOrganizationSettings } from "./useOrganizationSettingsStore";
import PersonIcon from "@mui/icons-material/Person";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import { DialogView } from "./OrganizationDialog/DialogViews";

const NewLocationButton = styled(Button)(({ theme }) => ({
  textTransform: "none",
  fontSize: "14px",
  fontWeight: "600",
  padding: "12px",
  height: "40px",
  width: "158px",
  backgroundColor: "#031E30",
  "&:hover": {
    backgroundColor: "#031E30",
  },
  "@media (max-width: 1512px)": {
    alignSelf: "flex-end", // Moves the button to the end when stacked
  },
}));

const StyledContactSupportSpan = styled("span")(({ theme }) => ({
  fontSize: "12px",
  color: "#031E30",
  fontWeight: 700,
  textDecoration: "underline",
}));

const SearchLocationsTextField = styled(TextField)(({ theme }) => ({
  height: "40px",
  width: "250px",
  "@media (max-width: 1512px)": {
    alignSelf: "flex-end", // Moves the input to the end when stacked
  },
}));

const ResponsiveBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  flexGrow: 1,
  gap: theme.spacing(2), // Space between elements using theme spacing
  "@media (max-width: 1512px)": {
    flexDirection: "column",
    justifyContent: "flex-end",
  },
  "@media (min-width: 1513px)": {
    flexDirection: "row",
    alignItems: "center", // Ensure items are centered vertically in row layout
  },
}));

const VerticalDivider = styled(Divider)(({ theme }) => ({
  height: "100%",
  border: "0.5px solid #E9E9E9",
  marginLeft: "15px",
  marginRight: "15px",
}));

const NumOfLocsOrLlcsText = styled(Typography)(({ theme }) => ({
  color: "#031E30",
  fontSize: "14px",
  fontWeight: 700,
}));

const LocsOrLlcsText = styled(Typography)(({ theme }) => ({
  color: "#929292",
  fontSize: "12px",
  whiteSpace: "nowrap",
}));

interface OrgChartRowProps {}

const OrgChartRow: React.FC<OrgChartRowProps> = ({}) => {
  const { llcs, setDialogView, setDialogOpen } = useOrganizationSettings();
  const organizationName = llcs[0]?.name || "N/A";
  const primaryContact = llcs[0]?.billingEntities[0]?.primaryContactUser?.name || "N/A";
  const numberOfLocations = llcs[0]?.locations.length || 0;
  const numberOfLegalEntities = llcs.length;

  const handleNewLocationClick = () => {
    setDialogView(DialogView.ADD_LOCATION_DEFAULT);
    setDialogOpen(true);
  };

  return (
    <OrgRowBox>
      <FlexColumnBox>
        <Typography
          variant="body1"
          sx={{
            fontWeight: "bold",
            fontSize: "14px",
          }}
        >
          {organizationName}
        </Typography>
        <Typography
          variant="body1"
          sx={{
            color: "#929292",
            fontSize: "12px",
            marginTop: "-4px",
          }}
        >
          Organization
        </Typography>
      </FlexColumnBox>
      <VerticalDivider flexItem orientation="vertical" />
      <Avatar
        sx={{
          marginRight: "4px",
          height: "24px",
          width: "24px",
        }}
      >
        <PersonIcon />
      </Avatar>
      <FlexRowBox>
        <FlexColumnBox>
          <Typography
            variant="body1"
            sx={{
              color: "#152744",
              fontSize: "14px",
            }}
          >
            {primaryContact}
          </Typography>
          <Typography
            variant="body1"
            sx={{
              color: "#929292",
              fontSize: "12px",
              marginTop: "-4px",
            }}
          >
            Primary contact
          </Typography>
        </FlexColumnBox>
        {/* TODO: SA-5810 Make icon color match figma file */}
        <InfoIcon
          sx={{
            backgroundColor: "white",
            color: "#929292",
            marginLeft: 1,
            fontSize: "14px",
            marginTop: "6px",
          }}
        />
        <Typography
          variant="body1"
          sx={{
            fontWeight: 500,
            fontSize: "12px",
            whiteSpace: "nowrap",
            marginLeft: "10px",
          }}
        >
          To change relationship POC, <StyledContactSupportSpan>contact support</StyledContactSupportSpan>
        </Typography>
      </FlexRowBox>
      <VerticalDivider flexItem orientation="vertical" />
      <FlexColumnBox>
        <NumOfLocsOrLlcsText>{numberOfLocations}</NumOfLocsOrLlcsText>
        <LocsOrLlcsText>Locations</LocsOrLlcsText>
      </FlexColumnBox>
      <VerticalDivider flexItem orientation="vertical" />
      <FlexColumnBox>
        <NumOfLocsOrLlcsText>{numberOfLegalEntities}</NumOfLocsOrLlcsText>
        <LocsOrLlcsText>Legal entities</LocsOrLlcsText>
      </FlexColumnBox>
      <ResponsiveBox>
        <SearchLocationsTextField
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            style: {
              height: "40px", // height of the text field
            },
          }}
          variant="outlined"
          placeholder="Search facilities or legal entities"
        />
        <NewLocationButton startIcon={<AddIcon />} variant="contained" onClick={handleNewLocationClick}>
          New location
        </NewLocationButton>
      </ResponsiveBox>
    </OrgRowBox>
  );
};

export default OrgChartRow;
