import { GridColDef } from "@mui/x-data-grid-premium";
import dayjs from "dayjs";
import { UpgradeProtectionPlanConfig } from "../../../utilities/generated/gql-types";
import { Chip } from "@mui/material";
import { blue, green, grey, red } from "@mui/material/colors";
import {
  AccessTime,
  Block,
  HourglassTop,
  PriorityHigh,
} from "@mui/icons-material";
import Check from "@mui/icons-material/Check";

interface StatusChipGeneratorProps {
  config: UpgradeProtectionPlanConfig;
}


/* Generate a status chip based on the job status */
function StatusChipGenerator({ config }: StatusChipGeneratorProps) {
  const { job } = config;

  if (!job) return <Chip label="Job Not Found" color="error" />;
  if (config.status === "cancelled" || config.deletedAt)
    return (
      <Chip
        label="Cancelled"
        sx={{
          color: grey[500],
          border: `1px solid ${grey[400]}`,
          bgcolor: grey[50],
        }}
        deleteIcon={
          <Block sx={{ color: `${grey[400]} !important`, height: 18 }} />
        }
        onDelete={() => null}
      />
    );
  if (job.status === 0)
    return (
      <Chip
        label="Scheduled"
        sx={{
          color: blue[800],
          border: `1px solid ${blue[800]}`,
          bgcolor: blue[50],
        }}
        deleteIcon={
          <AccessTime sx={{ color: `${blue[800]} !important`, height: 18 }} />
        }
        onDelete={() => null}
      />
    );
  if (job.status === 1)
    return (
      <Chip
        label="Completed"
        sx={{
          color: green[800],
          border: `1px solid ${green[800]}`,
          bgcolor: green[50],
        }}
        deleteIcon={
          <Check sx={{ color: `${green[800]} !important`, height: 18 }} />
        }
        onDelete={() => null}
      />
    );
  if (job.status === 2)
    return (
      <Chip
        label="Failed"
        sx={{
          color: red[800],
          border: `1px solid ${red[800]}`,
          bgcolor: red[50],
        }}
        deleteIcon={
          <PriorityHigh sx={{ color: `${red[800]} !important`, height: 16 }} />
        }
        onDelete={() => null}
      />
    );
  if (job.status === 3)
    return (
      <Chip
        label="Running"
        sx={{
          color: blue[800],
          border: `1px solid ${blue[800]}`,
          bgcolor: blue[50],
        }}
        deleteIcon={
          <HourglassTop sx={{ color: `${blue[800]} !important`, height: 18 }} />
        }
        onDelete={() => null}
      />
    );
  return null;
}

const getStatusPriority = (status, deletedAt) => {
  if (status === 3) return 1;  // If the job is running
  if (status === 0 && !deletedAt) return 2; // If the job is queued and not cancelled
  if (status === 2) return 3;
  if (status === 1) return 4;
  if (status === 'queued') return 5;
  if (status === 'cancelled') return 6;
  return 5;  // Lowest priority for other statuses
};

// Default sorting of the rows by status
export const statusComparator = (v1, v2) => {
  const priority1 = getStatusPriority(v1.job.status, v1.deletedAt);
  const priority2 = getStatusPriority(v2.job.status, v2.deletedAt);

  if (priority1 !== priority2) {
    return priority1 - priority2; // Sort based on status priority
  }

  // If priorities are the same, sort by createdAt in descending order
  return new Date(v2.createdAt).getTime() - new Date(v1.createdAt).getTime();
};

export const planUpgradesHistoryGridColDefs: GridColDef[] = [
  {
    field: "relationshipId",
    headerName: "Relationship ID",
    valueGetter: ({ row }) => row.relationship.id,
    flex: 1,
  },
  {
    field: "relationship",
    headerName: "Relationship",
    valueGetter: ({ row }) => row.relationship.name,
    flex: 1
  },
  {
    field: "locationId",
    headerName: "Location ID",
    valueGetter: ({ row }) => row.location.id,
  },
  {
    field: "locationAddress",
    headerName: "Location Address",
    valueGetter: ({ row }) => row.location.address,
    flex: 1,
  },
  {
    field: "jobId",
    headerName: "Job ID",
    valueGetter: ({ row }) => row.job?.id,
  },
  {
    field: "createdAt",
    headerName: "Scheduled At",
    valueFormatter: ({ value }) => dayjs(value).format("MM/DD/YYYY hh:mm A"),
    flex: 1,
  },
  {
    field: "upgradeAt",
    headerName: "Scheduled For",
    valueFormatter: ({ value }) => dayjs(value).format("MM/DD/YYYY"),
    flex: 1,
  },
  {
    field: "deletedAt",
    headerName: "Cancelled At",
    valueFormatter: ({ value }) =>
      value ? dayjs(value).format("MM/DD/YYYY hh:mm A") : null,
    valueGetter: ({ row }) => (row.deletedAt ? row.deletedAt : null),
    flex: 1,
  },
  {
    field: "stepCount",
    headerName: "# of plan upgrades",
    valueGetter: ({ row }) => row.steps?.length,
    minWidth: 140
  },
  {
    field: "status",
    headerName: "Job Status",
    minWidth: 140,
    valueGetter: ({ row }) => row.status,
    renderCell: ({ row }) => (
      <StatusChipGenerator config={row as UpgradeProtectionPlanConfig} />
    ),
  },
];
