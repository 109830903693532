import { TimelineDot, TimelineDotProps } from "@mui/lab";

interface CampaignTimelineDotProps extends TimelineDotProps {}

export function CampaignTimelineDot({ children }: CampaignTimelineDotProps) {
  return (
    <TimelineDot
      sx={{ width: 48, bgcolor: "transparent", boxShadow: "none", display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      {children}
    </TimelineDot>
  );
}
