import { useEffect, useState } from "react";

// UI - libs
import {
  Box,
  Button,
  ButtonGroup,
  Grid,
  Paper,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";

// UI - internal
import SafeleaseDataGrid from "../../shared/safelease-data-grid";
import GeneralUnitsHistoryDrawer from "./GeneralUnitsHistoryDrawer";
import { generalUnitsHistoryColumns } from "./generalUnitsHistoryColumns";
import { generalUnitsColumnVisibilityModel } from "./generalUnitsColumnVisibilityModel";

// Data
import { useQuery } from "@apollo/client";
import { useGeneralUnitsHistory } from "./useGeneralUnitsHistory";
import { getGeneralUnits, } from "../../queries";
import { GeneralUnit } from "../../utilities/generated/gql-types";
import RelationshipSelector from "../../shared/RelationshipSelector";
import RouterHelper from "../../utilities/router-helper";
import LocationSelector from "../../shared/LocationSelector";

enum GeneralUnitsDisplayMode {
  Unit = "Unit",
  Tenant = "Tenant",
  Policy = "Policy",
  Financial = "Financial",
  Metadata = "Metadata",
}

interface GeneralUnitsHistoryProps {
  routerHelper: RouterHelper;
}

export default function GeneralUnitsHistory({
  routerHelper,
}: GeneralUnitsHistoryProps) {
  const relationshipId = routerHelper.getRelationshipId();
  const { setSelectedGeneralUnit, setDrawerOpen } = useGeneralUnitsHistory();

  const [displayMode, setDisplayMode] = useState<GeneralUnitsDisplayMode>(
    GeneralUnitsDisplayMode.Unit
  );

  const [selectedRelationshipId, setSelectedRelationshipId] = useState<
    string | number
  >(relationshipId);
  const [locationId, setLocationId] = useState<string | number | null>(null);

  const generalUnitsQuery = useQuery<{ getGeneralUnits: GeneralUnit[] }>(
    getGeneralUnits,
    {
      variables: {
        locationId,
        relationshipId: selectedRelationshipId,
      },
    }
  );

  // Reset selected location every time relationship ID is updated
  useEffect(() => {
    setLocationId(null);
  }, [selectedRelationshipId]);

  const generalUnits = generalUnitsQuery.data?.getGeneralUnits;

  return (
    <Paper elevation={0} sx={{ p: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h4">General Unit Records</Typography>
        </Grid>
        <Grid item xs={12}>
          <Stack direction="row" justifyContent="space-between" spacing={1}>
            <Stack direction="row" spacing={1} alignItems="center">
              <RelationshipSelector
                routerHelper={routerHelper}
                nonRoute
                nonRouteCallback={(value) => setSelectedRelationshipId(value)}
              />
              <LocationSelector
                locationId={locationId}
                setLocationId={setLocationId}
                relationshipId={selectedRelationshipId}
              />
              {!locationId && (
                <Typography variant="caption" sx={{ color: 'gray' }}>
                  If no units appear for 'all locations', try selecting a specific location.
                </Typography>
              )}
            </Stack>
            <ButtonGroup>
              {Object.keys(GeneralUnitsDisplayMode).map((mode) => (
                <Button
                  key={mode}
                  disableElevation
                  sx={{ textTransform: "none" }}
                  onClick={() =>
                    setDisplayMode(mode as GeneralUnitsDisplayMode)
                  }
                  variant={displayMode === mode ? "contained" : "outlined"}
                >
                  {mode}
                </Button>
              ))}
            </ButtonGroup>
          </Stack>
        </Grid>
        <Grid item xs={12}></Grid>
        <Grid item xs={12}>
          <Box sx={{ height: 800 }}>
            {generalUnitsQuery.loading && (
              <Skeleton variant="rectangular" height={800}/>
            )}
            {!generalUnitsQuery.loading && (
              <SafeleaseDataGrid
                sx={{ cursor: 'pointer' }}
                columns={generalUnitsHistoryColumns}
                rows={generalUnits || []}
                columnVisibilityModel={
                  generalUnitsColumnVisibilityModel[displayMode]
                }
                onRowClick={({ row }) => {
                  setSelectedGeneralUnit(row as GeneralUnit);
                  setDrawerOpen(true);
                }}
                />
            )}
          </Box>
        </Grid>
      </Grid>
      <GeneralUnitsHistoryDrawer />
    </Paper>
  );
}
