import { useEffect, useState } from "react";

// UI - libs
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";

// UI - internal
import { LocationSettingsErrors } from "../../shared/LocationSettingsModal";

// Data
import BillingDataService from "../../services/billing.service";
import SetupDataService from "../../services/setup.service";
import { Location } from "../../utilities/generated/gql-types";
import { useQuery } from "@apollo/client";
import { getLlcs, getLocations } from "../../queries";
import { stateAbbr } from "../../utilities/field-enums";
import Error from "../../shared/Error";
import client from "../../utilities/apolloClient";

enum ManualBillingType {
  ZeroDollarPlan = "zeroDollarPlan",
  ManualReport = "manualReport",
  UnsupportedCredentialedFMS = "unsupportedCredentialedFMS",
  MinimumBillingPlan = "minimumBillingPlan",
}

export type LlcErrors = {
  name?: { msg?: string };
  address?: { msg?: string };
  city?: { msg?: string };
  state?: { msg?: string };
  postalCode?: { msg?: string };
};

interface LocationSettingsBillingProps {
  loading: boolean;
  location: Location;
  setLoading: (val: boolean) => void;
  setErrors: (errors: LocationSettingsErrors) => void;
  setErrorMessage: (message: string) => void;
  onClose: () => void;
}

export default function Billing({
  loading,
  location,
  setLoading,
  setErrors,
  setErrorMessage,
  onClose,
}: LocationSettingsBillingProps) {
  const [automaticBilling, setAutomaticBilling] = useState<boolean>(
    location.automaticBilling,
  );

  const [llc, setLlc] = useState<any>({});
  const [llcErrors, setLlcErrors] = useState<LlcErrors>({});
  const [createNewLlc, setCreateNewLlc] = useState<boolean>(false);
  const [manualBillingType, setManualBillingType] = useState<ManualBillingType>(
    location.manualBillingType as ManualBillingType,
  );
  
  const llcsQuery = useQuery(getLlcs, {
    variables: { relationshipId: location.relationshipId },
    onCompleted: (data) => {
      setLlc(data.getLlcs?.find((llc) => llc.id == location.llcId) || {});
      return data;
    },
  });

  const saveBilling = async function () {
    // If we're changing the automatic billing status
    if (
      location.automaticBilling !== automaticBilling || // If the automatic billing type has changed
      location.manualBillingType !== manualBillingType // or the manual billing type has changed
    ) {
      setErrors({});
      setLoading(true);

      if (!automaticBilling && !manualBillingType) { // manual billing type is required if automatic billing is not selected
        setErrorMessage("Select a manual billing type before saving.");
        setLoading(false)
        return;
      }

      const response = await SetupDataService.addLocation({
        ...location,
        automaticBilling,
        manualBillingType: automaticBilling ? null : manualBillingType,
      });

      // Refetch locations. This doesn't actually change the state of the "selectedLocation" prop,
      // but the state will have changes from the user's UI input.
      // This does impact our ability to, if we switch to a different location then back to this one,
      // see the manual billing type in the UI.
      await client.refetchQueries({ include: [getLocations] })

      setLoading(false);

      if (response.data.errors) {
        setLlcErrors(response.data.errors);
        setErrorMessage("A problem occurred while saving the location.");
        return;
      }
    }

    const newLlc = { ...llc };
    newLlc.relationshipId = location.relationshipId;
    setErrors({});
    setLoading(true);
    const response = await BillingDataService.addLlc(newLlc, location.id);
    setLoading(false);

    if (response.data.errors) {
      setLlcErrors(response.data.errors);
      setErrorMessage("A problem occurred while saving the legal entity.");
      return;
    }

    llcsQuery.refetch();
    onClose();
  };

  const setLlcField = function (key, value) {
    var newLlc = { ...llc };
    newLlc[key] = value;
    setLlc(newLlc);
  };

  const handleChange = (event: SelectChangeEvent) => {
    setManualBillingType(event.target.value as ManualBillingType);
  };

  if (llcsQuery.loading) return <CircularProgress />;
  if (llcsQuery.error) return <Error />;

  return (
    <>
      <Paper
        sx={{
          maxHeight: 400,
          overflow: "auto",
          backgroundColor: "inherit",
          boxShadow: "none",
          padding: 1,
        }}
      >
        <div className="tw-flex tw-flex-col tw-items-start">
          <strong className="tw-mb-1">Location Level</strong>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ width: '100%' }}
          >
            <Typography>Billing Type</Typography>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography>Manual</Typography>
              <Switch
                onChange={(e) => setAutomaticBilling(e.target.checked)}
                defaultChecked={automaticBilling}
                value={automaticBilling}
              />
              <Typography>Automatic</Typography>
            </Stack>
          </Stack>
          {!Boolean(automaticBilling) && (
            <FormControl fullWidth sx={{ mt: 1 }}>
              <InputLabel>Manual Billing Type</InputLabel>
              <Select
                fullWidth
                value={manualBillingType}
                label="Manual Billing Type"
                onChange={handleChange}
              >
                <MenuItem value="zeroDollarPlan">$0 Plan</MenuItem>
                <MenuItem value="manualReport">Manual Report</MenuItem>
                <MenuItem value="unsupportedCredentialedFMS">
                  Unsupported Credentialed FMS
                </MenuItem>
                <MenuItem value="minimumBillingPlan">
                  Minimum Billing Plan
                </MenuItem>
              </Select>
            </FormControl>
          )}
          <strong className="tw-mb-3 tw-mt-4">Legal Entity Level</strong>
          {llcsQuery.data.getLlcs.length > 0 && !llc?.id && !createNewLlc ? (
            <>
              <TextField
                label="Legal Entity"
                select
                onChange={(e) => {
                  setLlc(e.target.value);
                  setErrors({});
                }}
                sx={{ width: "100%", marginBottom: 1 }}
              >
                {llcsQuery.data.getLlcs.map((llc) => {
                  return (
                    <MenuItem key={llc.id} value={llc}>
                      {llc.name}
                    </MenuItem>
                  );
                })}
              </TextField>
              <Button
                onClick={() => {
                  setLlc({});
                  setCreateNewLlc(true);
                  setErrors({});
                }}
              >
                Add New
              </Button>
            </>
          ) : (
            <>
              <TextField
                name="llc"
                label={"Legal Entity"}
                value={llc?.name || ""}
                type="llc"
                onChange={(e) => setLlcField("name", e.target.value)}
                helperText={llcErrors["llc.name"]?.msg}
                sx={{ width: "100%", marginBottom: 1 }}
              />
              <TextField
                name="address"
                label={"Address"}
                value={llc?.address || ""}
                type="address"
                onChange={(e) => setLlcField("address", e.target.value)}
                helperText={llcErrors["llc.address"]?.msg}
                sx={{ width: "100%", marginBottom: 1 }}
              />

              <Grid className="split-input-fields" container spacing={2}>
                <Grid item xs={4} className="input-grid">
                  <TextField
                    name="city"
                    label={"City"}
                    value={llc?.city || ""}
                    type="city"
                    onChange={(e) => setLlcField("city", e.target.value)}
                    helperText={llcErrors["llc.city"]?.msg}
                    sx={{ width: "100%", marginBottom: 1 }}
                  />
                </Grid>
                <Grid item xs={4} className="input-grid">
                  <FormControl fullWidth>
                    <InputLabel id="state-selector-label">State</InputLabel>
                    <Select
                      autoWidth
                      id="state-selector"
                      labelId="state-selector-label"
                      label="State"
                      name="state"
                      value={llc?.state}
                      onChange={(e) => setLlcField("state", e.target.value)}
                      sx={{ width: "100%", marginBottom: 1 }}
                    >
                      {stateAbbr.map((state) => (
                        <MenuItem value={state} key={state}>{state}</MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>{llcErrors.state?.msg}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={4} className="input-grid">
                  <TextField
                    name="postalCode"
                    label={"Postal Code"}
                    value={llc?.postalCode || ""}
                    type="postalCode"
                    onChange={(e) => setLlcField("postalCode", e.target.value)}
                    helperText={llcErrors["llc.postalCode"]?.msg}
                    sx={{ width: "100%", marginBottom: 1 }}
                  />
                </Grid>
              </Grid>
              <FormControlLabel
                sx={{ marginRight: "-5px" }}
                control={
                  <Checkbox
                    checked={llc?.billPerLocation}
                    onChange={(e) =>
                      setLlcField("billPerLocation", e.target.checked)
                    }
                  />
                }
                label="Bill per Location"
                disabled={llc?.billPerLocation}
              />

              {llc?.id && (
                <Button
                  className="add-location-textfield"
                  onClick={() => setLlc({})}
                >
                  Disconnect LLC
                </Button>
              )}
            </>
          )}
        </div>
      </Paper>
      {loading ? (
        <CircularProgress />
      ) : (
        <Button className="modal-login-button" onClick={saveBilling}>
          Save Billing
        </Button>
      )}
    </>
  );
}
