import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { ColumnDef } from '../../utilities/column-defs';
import { TableStyles } from './TableStyles';

interface VerticalTableProps {
  title?: string;
  columns: ColumnDef[];
  dataRows: any[];
}

export default function VerticalTable({ columns, dataRows }: VerticalTableProps) {
  return (
    <div style={TableStyles.wrapper}>
      <TableContainer>
        <Table size="small">
          <TableBody>
            {columns.map(column => {
              const { key, renderer, renderOptions } = column;
              return (
                <TableRow key={column.title}>
                  <TableCell
                    sx={{
                      ...TableStyles.header,
                      minWidth: '10rem',
                      position: 'sticky',
                      left: 0,
                      backgroundColor: 'white',
                    }}
                  >
                    {column.title}
                  </TableCell>
                  {dataRows.length > 0 &&
                    dataRows.map(row => {
                      let value = row;
                      for (const part of key.split('.')) {
                        value = value?.[part];
                      }

                      return (
                        <TableCell
                          sx={{ ...TableStyles.cell, minWidth: '8rem' }}
                          key={`${row.id}:${value}`}
                        >
                          {renderer ? renderer(value, { row }, renderOptions) : value || 'N/A'}
                        </TableCell>
                      );
                    })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
