// Hooks
import { useQuery } from "@tanstack/react-query";
import { useAuth } from "../auth.js";

// Data
import { SafeLeaseAPI as SafeLeaseAPIDefaultEnvironment } from "./SafeLeaseAPIInstance";
import {
  Campaign_Reputation as Campaign,
  CampaignMetrics,
  DashboardHandlerResponse,
  Facility_Reputation as Facility,
  FacilityMetrics,
  HistoricalReviewData,
  ReputationMetrics,
  Review,
  SafeLeaseAPI,
} from "@safelease/service-utilities";

// Utils
import { create } from "zustand";
import { usePerformanceBoard } from "./pages/PortfolioView/TeamPerformanceBoard/usePerformanceBoard";
import { FacilityTwilioData } from "./types/FacilityTwilioData.js";

/** Core State Management and Actions
 *  Holds essential UI states and actions for navigating and toggling key views.
 */
interface ReputationStore {
  /* Base route for reputation taking Admin role into account */
  reputationRoute: string;
  setReputationRoute: (route: string) => void;

  /* State for managing which campaign is being viewed in the campaign drawer */
  selectedCampaign: Campaign | null;
  setSelectedCampaign: (campaign: Campaign | null) => void;

  /* Manage currently viewed facility */
  selectedFacilityId: string | null;
  setSelectedFacilityId: (facilityId: string) => void;

  /* For SafeLease admins only: enable the viewing of different relationships */
  selectedRelationshipId: string | null;
  setSelectedRelationshipId: (relationshipId: string) => void;

  /* Manage the display of a loading screen when switching which relationship we're viewing */
  loadingRelationship: boolean;
  setLoadingRelationship: (loading: boolean) => void;

  /* Manage open/closed state of review replies drawer */
  reviewReplyDrawerOpen: boolean;
  setReviewReplyDrawerOpen: (open: boolean) => void;

  /* Campaign settings submission state */
  campaignConfigurationFormSubmitting?: boolean;
  setCampaignConfigurationFormSubmitting: (submitting: boolean) => void;
}

/** Query-Based Data and Fetch Status
 *  Holds data fetched from external sources and query statuses.
 */
interface ReputationState extends ReputationStore {
  portfolio: DashboardHandlerResponse;

  facilityMetrics: ReputationMetrics & {
    reviews: Array<Review>;
    campaigns: Array<
      Campaign & {
        funnelMetrics: CampaignMetrics;
        previousFunnelMetrics: CampaignMetrics;
      }
    >;
  };

  facility: Facility;
  facilityTwilioData: FacilityTwilioData;

  pendingReviews: Array<Review>;

  // Query statuses
  statuses: {
    facility: string;
    facilityMetrics: string;
    facilityTwilioData: string;
    portfolio: string;
    pendingReviews: string;
    campaignConfigurationForm: boolean;
    metrics: string;
    any: string;
  };

  metrics: {
    portfolio: ReputationMetrics & {
      historicalReviewData: Array<HistoricalReviewData>;
    };
    facilities: Array<FacilityMetrics>;
  };

  refresh: () => void;

  SafeLeaseAPI: SafeLeaseAPI;
}

/**
 * Zustand Store: `useReputationStore`
 * Manages UI states for navigation, selected items, and toggles in the reputation module.
 * Setters update specific state properties.
 */
export const useReputationStore = create<ReputationStore>((set) => ({
  /* Base route for reputation taking Admin role into account */
  reputationRoute: null,
  setReputationRoute: (repRoute) => set({ reputationRoute: repRoute }),

  /* State for managing which campaign is being viewed in the campaign drawer */
  selectedCampaign: null,
  setSelectedCampaign: (campaign) => set({ selectedCampaign: campaign }),

  /* Manage currently viewed facility */
  selectedFacilityId: null,
  setSelectedFacilityId: (facilityId: string) => set({ selectedFacilityId: facilityId }),

  /* For SafeLease admins only: enable the viewing of different relationships */
  selectedRelationshipId: null,
  setSelectedRelationshipId: (relationshipId) => set({ selectedRelationshipId: relationshipId }),

  /* Manage the display of a loading screen when switching which relationship we're viewing */
  loadingRelationship: false,
  setLoadingRelationship: (loading: boolean) => set({ loadingRelationship: loading }),

  /* Manage open/closed state of review replies drawer */
  reviewReplyDrawerOpen: false,
  setReviewReplyDrawerOpen: (open: boolean) => set({ reviewReplyDrawerOpen: open }),

  // Campaign settings submission state
  campaignConfigurationFormSubmitting: false,
  setCampaignConfigurationFormSubmitting: (submitting: boolean) => set({ campaignConfigurationFormSubmitting: submitting }),
}));

type TUseReputation = () => ReputationState;

export const useReputation: TUseReputation = () => {
  /**
   * Hooks
   */
  const auth = useAuth();
  const { setFacilities, setPortfolio } = usePerformanceBoard();

  /**
   * State
   */
  const reputationRoute = useReputationStore((state) => state.reputationRoute);
  const setReputationRoute = useReputationStore((state) => state.setReputationRoute);
  const selectedCampaign = useReputationStore((state) => state.selectedCampaign);
  const setSelectedCampaign = useReputationStore((state) => state.setSelectedCampaign);
  const selectedFacilityId = useReputationStore((state) => state.selectedFacilityId);
  const setSelectedFacilityId = useReputationStore((state) => state.setSelectedFacilityId);
  const selectedRelationshipId = useReputationStore((state) => state.selectedRelationshipId);
  const setSelectedRelationshipId = useReputationStore((state) => state.setSelectedRelationshipId);
  const loadingRelationship = useReputationStore((state) => state.loadingRelationship);
  const setLoadingRelationship = useReputationStore((state) => state.setLoadingRelationship);
  const reviewReplyDrawerOpen = useReputationStore((state) => state.reviewReplyDrawerOpen);
  const setReviewReplyDrawerOpen = useReputationStore((state) => state.setReviewReplyDrawerOpen);
  const campaignConfigurationFormSubmitting = useReputationStore((state) => state.campaignConfigurationFormSubmitting);
  const setCampaignConfigurationFormSubmitting = useReputationStore((state) => state.setCampaignConfigurationFormSubmitting);

  const relationshipId = selectedRelationshipId ?? auth.user?.relationshipId;

  /**
   * API Instance
   */
  const SafeLeaseAPI = SafeLeaseAPIDefaultEnvironment({
    environment: process.env.REACT_APP_SAFELEASE_ENVIRONMENT,
  });

  /**
   * API Queries
   */
  const handlePortfolioDashboardQuery = async () => {
    const response = await SafeLeaseAPI.reputation.getPortfolioDashboard({ relationshipId });
    setFacilities(response.data?.facilities);
    setPortfolio(response.data);
    return response;
  };

  /**
   * Retrieves high level information about the portfolio such as average rating and number of reviews
   * includes high level metric data on each facility such as rating, number of reviews,
   * number of pending reviews, and more.
   */
  const portfolioDashboardQuery = useQuery({
    queryKey: ["portfolioDashboard", relationshipId],
    queryFn: handlePortfolioDashboardQuery,
  });

  const portfolioId = portfolioDashboardQuery.data?.data?.id;

  const portfolioMetricsQuery = useQuery({
    queryKey: ["portfolioMetrics", relationshipId],
    queryFn: () =>
      SafeLeaseAPI.reputation.getPortfolioMetrics({
        relationshipId,
        periodType: "week",
        periodDuration: String(26),
      }),
    enabled: !!portfolioId,
  });

  const facilityMetricsQuery = useQuery({
    queryKey: ["facilityMetrics", relationshipId],
    queryFn: () =>
      SafeLeaseAPI.reputation.getAllFacilityMetrics({
        relationshipId: relationshipId,
        periodType: "week",
        periodDuration: String(26),
      }),
    enabled: !!portfolioId,
  });

  /* Retrieves non-numerical about one facility, including its rating, review history,
   * branding, contacts, and feedback
   */
  const facilityDetailsQuery = useQuery({
    queryKey: ["facilityDetails", selectedFacilityId],
    queryFn: () => SafeLeaseAPI.reputation.getFacilityDetails(selectedFacilityId),
    enabled: !!selectedFacilityId,
  });

  /**
   * Retrieves statistical data about a facility, tying in campaign / conversion data and review data
   */
  const facilityDashboardQuery = useQuery({
    queryKey: ["facilityDashboard", selectedFacilityId],
    queryFn: () => SafeLeaseAPI.reputation.getFacilityOverview(selectedFacilityId, "week", 26),
    enabled: !!selectedFacilityId,
  });

  /* Returns reviews that have not yet been replied to */
  const reviewsPendingReplyQuery = useQuery({
    queryKey: ["reviewsPendingReply", portfolioId],
    queryFn: () => SafeLeaseAPI.reputation.getReviewsPendingReply(portfolioId),
    enabled: !!portfolioId,
  });

  // Returns facility data including twilio entities
  const facilityTwilioDataQuery = useQuery({
    queryKey: ["facilityTwilioData", selectedFacilityId],
    queryFn: () => SafeLeaseAPI.reputation.getFacilityAndTwilioSetupDetails({ facilityId: selectedFacilityId }),
    enabled: !!selectedFacilityId,
  });

  /**
   * Effects
   */

  /**
   * Helper functions
   */
  const refresh = () => {
    portfolioDashboardQuery.refetch();
    reviewsPendingReplyQuery.refetch();

    if (selectedFacilityId) {
      facilityDetailsQuery.refetch();
      facilityDashboardQuery.refetch();
      facilityTwilioDataQuery.refetch();
      facilityMetricsQuery.refetch();
    }
  };

  const setSelectedRelationshipIdWithRefetches = (relationshipId: string) => {
    setSelectedRelationshipId(relationshipId);
    portfolioDashboardQuery.refetch();
  };

  const metricsStatus = () => {
    if (portfolioMetricsQuery.status === "error" || facilityMetricsQuery.status === "error") {
      return "error";
    }

    if (portfolioMetricsQuery.status === "loading" || facilityMetricsQuery.status === "loading") {
      return "loading";
    }

    return "success";
  };

  const combinedQueryStatus = () => {
    if (
      portfolioDashboardQuery.status === "error" ||
      facilityDetailsQuery.status === "error" ||
      facilityDashboardQuery.status === "error" ||
      reviewsPendingReplyQuery.status === "error" ||
      portfolioMetricsQuery.status === "error" ||
      facilityMetricsQuery.status === "error" ||
      facilityTwilioDataQuery.status === "error"
    ) {
      return "error";
    }

    if (
      portfolioDashboardQuery.status === "loading" ||
      facilityDetailsQuery.status === "loading" ||
      facilityDashboardQuery.status === "loading" ||
      reviewsPendingReplyQuery.status === "loading" ||
      portfolioMetricsQuery.status === "loading" ||
      facilityMetricsQuery.status === "loading" ||
      facilityTwilioDataQuery.status === "loading"
    ) {
      return "loading";
    }

    return "success";
  };

  return {
    // Reputation Base Route (admin or normal)
    reputationRoute,
    setReputationRoute,

    // Selected Campaign
    selectedCampaign,
    setSelectedCampaign,

    // Porfolio dashboard data
    portfolio: portfolioDashboardQuery.data?.data,

    /* Facility selection inside of /reputation/facility */
    selectedFacilityId,
    setSelectedFacilityId,

    /* For SafeLease admins only: enable the viewing of different relationships */
    selectedRelationshipId,
    setSelectedRelationshipId: setSelectedRelationshipIdWithRefetches,

    /* Loading state for switching relationships */
    loadingRelationship,
    setLoadingRelationship,

    /* Open/closed state of review replies drawer */
    reviewReplyDrawerOpen,
    setReviewReplyDrawerOpen,

    // Facility Details
    facility: facilityDetailsQuery.data?.data,
    facilityMetrics: facilityDashboardQuery.data?.data,
    facilityTwilioData: facilityTwilioDataQuery.data?.data,

    pendingReviews: reviewsPendingReplyQuery.data?.data,

    // Campaign step form submission state
    setCampaignConfigurationFormSubmitting,

    // Status of all queries
    statuses: {
      facility: facilityDetailsQuery.status,
      facilityMetrics: facilityDashboardQuery.status,
      facilityTwilioData: facilityTwilioDataQuery.status,
      portfolio: portfolioDashboardQuery.status,
      pendingReviews: reviewsPendingReplyQuery.status,
      campaignConfigurationForm: campaignConfigurationFormSubmitting,
      metrics: metricsStatus(),
      any: combinedQueryStatus(),
    },

    // NOTE: these are metric-only queries, not full data queries
    // This is a WIP, as you'll notice "facilityMetrics" above exists
    // which is a full quantitative + qualitative data query that incluedes joins to reviews, reviewCounts, historical data, etc
    // for a specific facility
    // This should be preferred above "facilityMetrics" going forward as that will be deprecated
    metrics: {
      portfolio: portfolioMetricsQuery.data?.data ?? [],
      facilities: facilityMetricsQuery.data?.data ?? [],
    },

    refresh,
    SafeLeaseAPI, // Configured API endpoint to point to correct environment
    portfolioDashboardQuery,
  };
};
