import dayjs, { Dayjs } from "dayjs";
import { StateCreator } from "zustand";

export interface PlanUpgradeSettingsEnforcementDateSliceState {
  enforcementDate: Dayjs;
}

export interface PlanUpgradeSettingsEnforcementDateSlice
  extends PlanUpgradeSettingsEnforcementDateSliceState {
  setEnforcementDate: (date: Dayjs) => void;
}

const initialState: PlanUpgradeSettingsEnforcementDateSliceState = {
  enforcementDate: dayjs().startOf('day').hour(4)
,
};

/**
 * Control the enforcement date for the plan upgrade
 */
export const createPlanUpgradeSettingsEnforcementDateSlice: StateCreator<
  PlanUpgradeSettingsEnforcementDateSlice
> = (set) => {
  return {
    ...initialState,
    setEnforcementDate: (date: Dayjs) => {
      set({
        enforcementDate: date,
      });
    },
  };
};
