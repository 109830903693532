import { GridColDef } from "@mui/x-data-grid-premium";
import { Formatters } from "../../utilities/formatters";

// Each of the available columns in the General Units Data Grid -- represents General Units NOT General Units History Items
export const generalUnitsHistoryColumns: GridColDef[] = [
  {
    field: "unitId",
    headerName: "Unit ID",
  },
  {
    field: "id",
    headerName: "GUR ID",
  },
  {
    field: "createdAt",
    headerName: "Created at",
    flex: 1,
  },
  {
    field: "updatedAt",
    headerName: "Updated at",
    flex: 1,
  },
  {
    field: "serviceId",
    headerName: "Service ID",
    flex: 1,
  },
  {
    field: "unitName",
    headerName: "Unit Name",
  },
  {
    field: "unitType",
    headerName: "Unit Type",
    flex: 1,
  },
  {
    field: "unitSize",
    headerName: "Unit Size",
  },
  {
    field: "fmsUnitStatus",
    headerName: "FMS Unit Status",
    width: 160,
  },
  {
    field: "safeleaseUnitStatus",
    headerName: "SafeLease Unit Status",
    width: 200,
  },
  {
    field: "insurable",
    headerName: "Insurable",
  },
  {
    field: "tenantName",
    headerName: "Tenant Name",
    flex: 1,
  },
  {
    field: "tenantEmail",
    headerName: "Tenant Email",
    flex: 1,
  },
  {
    field: "lastIngestedAt",
    headerName: "Last Ingested at",
    flex: 1,
  },
  {
    field: "leaseId",
    headerName: "Lease ID",
    flex: 1,
  },
  {
    field: "moveInDate",
    headerName: "Move In Date",
  },
  {
    field: "moveOutDate",
    headerName: "Move Out Date",
  },
  {
    field: "leasePaidThroughDate",
    headerName: "Lease Paid Through",
    width: 200,
  },
  {
    field: "currentMonthlyRent",
    headerName: "Current Monthly Rent",
    renderCell: (params) => Formatters.penniesToDollars(params.value),
    width: 200,
  },
  {
    field: "policyType",
    headerName: "Policy Type",
  },
  {
    field: "policySource",
    headerName: "Policy Source",
  },
  {
    field: "premium",
    headerName: "Premium",
    renderCell: (params) => Formatters.penniesToDollars(params.value),
  },
  {
    field: "coverage",
    headerName: "Coverage",
    renderCell: (params) => Formatters.penniesToDollars(params.value),
  },
  {
    field: "policyStartDate",
    headerName: "Policy Start Date",
  },
  {
    field: "policyCancelDate",
    headerName: "Policy Cancel Date",
  },
  {
    field: "privatePolicyNumber",
    headerName: "Private Policy Number",
    flex: 1,
  },
  {
    field: "privatePolicyExpiration",
    headerName: "Private Policy Expiration",
    width: 200,
  },
];
