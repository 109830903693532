import { Button } from "@mui/material";

interface ThemedButtonProps {
  children: any;
  dark?: boolean;
  onClick: () => void;
  styles?: object;
}

// Custom button used throughout OrganizationDialog for dark and light themes. Ex: Connect New FMS, Move Existing Location
const ThemedButton: React.FC<ThemedButtonProps> = ({
  children,
  dark,
  onClick,
  styles,
}) => {
  return (
    <Button
      disableElevation
      variant="contained"
      sx={{
        width: "100%",
        backgroundColor: dark ? "#031E30" : "white", // if dark, display dark blue background color, else white
        height: "40px",
        color: dark ? "white" : "#031E30", // if dark, display white text color, else dark blue
        border: dark ? "" : "1px solid #031E30", // if dark, apply no border styling, else apply border styling
        borderRadius: "4px",
        padding: "12px",
        fontWeight: 700,
        textTransform: "none",
        fontSize: "14px",
        "&:hover": {
          backgroundColor: dark ? "#031E30" : "white", // if dark, display dark blue background color, else white
          color: dark ? "white" : "#031E30", // if dark, display white text color, else dark blue
        },
        ...styles,
      }}
      onClick={onClick}
    >
      {children}
    </Button>
  );
};

export default ThemedButton;
