import { useState } from "react";
import { Alert, Button, DialogContent, DialogTitle, Stack, Theme } from "@mui/material";
import { SafeLeaseTextField } from "@safelease/components";
import { usePerformanceBoard } from "../usePerformanceBoard";
import { Info, KeyboardArrowLeft } from "@mui/icons-material";
import { Box } from "@mui/system";
import { useReputation } from "../../../../useReputation";
import { LoadingButton } from "@mui/lab";
import { enqueueSnackbar } from "notistack";
import { QRCodeStatus } from "@safelease/service-utilities";

export function QRCodeMessageConfig() {
  const { SafeLeaseAPI, portfolio, portfolioDashboardQuery } = useReputation();
  const { setStep, selectedUsers, facilities, setConfigDialogOpen } = usePerformanceBoard();
  const [messageText, setMessageText] = useState<string>(
     "Please find attached your review solicitation QR code. " +
      "This QR code is unique to you and the facility you work at. " +
      "It can be used to solicit reviews from our tenants. " +
      "You will receive credit for every 5-star review that comes from your link or QR code."
  );
  const [loading, setLoading] = useState<boolean>(false);

  // Create new QR codes for the selected users
  // and send them in an email with the message specified
  const createNewQRCodes = async () => {
    try {
      setLoading(true);

      const qrCodes = facilities.flatMap((facility) =>
        facility.users.filter((user) => user.qrCodes.length).flatMap((user) => user.qrCodes),
      );

      let facilityUsers: Array<{ facilityId: string; userId: string; active: boolean }> = [];
      let statusTogglePromises = [];

      // Save the message before sending QR codes
      await SafeLeaseAPI.reputation.updatePortfolioHandler({ qrCodeSendoutMessage: messageText, id: portfolio.id });

      for (const [facilityId, userIds] of Object.entries(selectedUsers)) {
        for (const [userId, selected] of Object.entries(userIds)) {
          const existingCode = qrCodes.find((qrCode) => qrCode.userId === userId && qrCode.facilityId === facilityId);
          const isExistingCodeActive = existingCode?.qrCode?.status === QRCodeStatus.ACTIVE
          
          // If the employee doesn't yet have a QR code, create a new active one for the employee
          if (!existingCode) {
            facilityUsers.push({ facilityId, userId, active: selected });
            continue;
          }

          // If the code exists, and we're moving from INACTIVE -> ACTIVE, execute request to activate the code
          if (existingCode && selected && !isExistingCodeActive) {
            statusTogglePromises.push(
              SafeLeaseAPI.reputation.activateQRCodeHandler({ qrCodeId: existingCode.qrCode.id, portfolioId: portfolio.id }),
            );
            continue;
          }

          // If the code exists, and we're moving from ACTIVE -> INACTIVE, execute request to deactivate the code
          if (existingCode && !selected && isExistingCodeActive) {
            statusTogglePromises.push(
              SafeLeaseAPI.reputation.deactivateQRCodeHandler({ qrCodeId: existingCode.qrCode.id, portfolioId: portfolio.id }),
            );
            continue;
          }
        }
      }

      await SafeLeaseAPI.reputation.bulkCreateQRCodeForUsersHandler({ facilityUsers, message: messageText });
      // Wait for all QR codes to send out
      await Promise.allSettled(statusTogglePromises);
      await portfolioDashboardQuery.refetch();
      enqueueSnackbar({ message: "QR codes sent to selected employees.", variant: "success" });
      setLoading(false);
      setConfigDialogOpen(false);
      setStep(0);
    } catch (error) {
      enqueueSnackbar("Error creating QR codes", { variant: "error" });
      setLoading(false);
    }
  };

  return (
    <>
      <DialogTitle sx={{ fontWeight: 600 }}>Distribute QR codes to your teams</DialogTitle>
      <DialogContent>
        <Stack direction="column" spacing={2}>
          <Box>
            <Button startIcon={<KeyboardArrowLeft />} sx={{ flexShrink: 1, color: "grey.A100", mr: "auto" }} onClick={() => setStep(0)}>
              Back to employee list
            </Button>
          </Box>
          <Alert
            severity="info"
            sx={{ borderLeft: (theme: Theme) => `3px solid ${theme.palette.blue.main}` }}
            icon={<Info sx={{ color: (theme: Theme) => theme.palette.blue.main }} />}
          >
            Attach a message to educate your employees on how to solicit reviews with the code, offer a reward for getting reviews, or any
            other information you want to share.
          </Alert>
          <SafeLeaseTextField
            value={messageText}
            onChange={(e) => setMessageText(e.target.value)}
            slotProps={{
              input: {
                maxLength: 2000,
              },
            }}
            multiline
            maxRows={4}
            autoFocus
            margin="dense"
            label="Add general message"
            id="name"
            type="email"
            fullWidth
            placeholder="Add a message to be sent with the QR codes."
          />
          <LoadingButton color="navy" variant="contained" onClick={createNewQRCodes} loading={loading} disabled={loading}>
            Send QR Code to Selected Employees
          </LoadingButton>
        </Stack>
      </DialogContent>
    </>
  );
}
