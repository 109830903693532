import { Box, Stack, Typography, alpha, useTheme } from "@mui/material";
import { FunnelChart, SafeLeaseTabContent, VerticalMetricWithChange } from "@safelease/components";
import { useState } from "react";
import { AdsClickOutlined, CampaignOutlined, SentimentVerySatisfiedOutlined, StarOutline } from "@mui/icons-material";
import { CampaignMetrics } from "@safelease/service-utilities";

interface CampaignsOverviewFunnelChartProps {
  funnelMetrics: CampaignMetrics;
  previousFunnelMetrics: CampaignMetrics;
}

export function CampaignsOverviewFunnelChart({ funnelMetrics, previousFunnelMetrics }: CampaignsOverviewFunnelChartProps) {
  const theme = useTheme();

  const [displayedCampaignTypes, setDisplayedCampaignTypes] = useState<"all" | "auto" | "quick">("all");

  return (
    <Stack direction="column" spacing={2}>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h6" sx={{ mb: 4 }}>
          All Campaigns Conversion Funnel
        </Typography>
      </Stack>
      <SafeLeaseTabContent value="all" currentTab={displayedCampaignTypes} sx={{ position: "relative" }}>
        <Stack direction="row" justifyContent="space-between" sx={{ position: "absolute", top: 0, left: 0, right: 0, zIndex: 999 }}>
          <Box sx={{ display: "flex", justifyContent: "center", flex: 1 }}>
            <VerticalMetricWithChange
              label="Total Messages Sent"
              icon={<CampaignOutlined />}
              value={funnelMetrics.messageSendCount}
              previousValue={previousFunnelMetrics.messageSendCount}
              changeFormat="integer"
              type="count"
              periodSize={7}
              periodType="days"
              decimalPlaces={0}
            />
          </Box>
          <Box sx={{ display: "flex", justifyContent: "center", flex: 1 }}>
            <VerticalMetricWithChange
              label="Click Through"
              icon={<AdsClickOutlined />}
              value={funnelMetrics.messageOpenCount}
              previousValue={previousFunnelMetrics.messageOpenCount}
              changeFormat="integer"
              type="count"
              periodSize={7}
              periodType="days"
              decimalPlaces={0}
            />
          </Box>
          <Box sx={{ display: "flex", justifyContent: "center", flex: 1 }}>
            <VerticalMetricWithChange
              label="Total Reviews"
              icon={<StarOutline />}
              value={funnelMetrics.reviewCount}
              previousValue={previousFunnelMetrics.reviewCount}
              changeFormat="integer"
              type="count"
              periodSize={7}
              periodType="days"
              decimalPlaces={0}
            />
          </Box>
          <Box sx={{ display: "flex", justifyContent: "center", flex: 1 }}>
            <VerticalMetricWithChange
              label="Positive Reviews"
              icon={<SentimentVerySatisfiedOutlined />}
              value={funnelMetrics.positiveReviewCount}
              previousValue={previousFunnelMetrics.positiveReviewCount}
              changeFormat="integer"
              type="count"
              periodSize={7}
              periodType="days"
              decimalPlaces={0}
            />
          </Box>
        </Stack>
        <Box sx={{ height: 450 }}>
          <FunnelChart
            spacing={5}
            transformLabelY={224}
            transparentLabels
            data={[
              {
                stepLabel: "Reached",
                value: funnelMetrics.messageSendCount,
                color: alpha(theme.palette.yellow.main, 1),
              },
              {
                stepLabel: "Clicked Through",
                value: funnelMetrics.messageOpenCount,
                color: alpha(theme.palette.yellow.main, 0.8),
              },
              {
                stepLabel: "Left Reviews",
                value: funnelMetrics.reviewCount,
                color: alpha(theme.palette.yellow.main, 0.6),
              },
              {
                stepLabel: "Positive Responses",
                value: funnelMetrics.positiveReviewCount,
                color: alpha(theme.palette.yellow.main, 0.4),
              },
            ]}
          />
        </Box>
      </SafeLeaseTabContent>
    </Stack>
  );
}
