import {
  Download,
  FirstPage,
  Fullscreen,
  FullscreenExit,
  LastPage,
  NavigateBefore,
  NavigateNext,
  Rotate90DegreesCcw,
  Rotate90DegreesCw,
  ZoomIn,
  ZoomOut,
} from "@mui/icons-material";
import { Box, IconButton, TextField, Typography } from "@mui/material";

const ControlPanel = ({
  file,
  pageNumber,
  numPages,
  setPageNumber,
  scale,
  setScale,
  showZoomControls = true,
  rotation,
  setRotation,
  expanded,
  handleClose,
  handleOpen,
}) => {
  const isFirstPage = pageNumber === 1;
  const isLastPage = pageNumber === numPages;

  const goToFirstPage = () => {
    if (!isFirstPage) setPageNumber(1);
  };
  const goToPreviousPage = () => {
    if (!isFirstPage) setPageNumber(pageNumber - 1);
  };
  const goToNextPage = () => {
    if (!isLastPage) setPageNumber(pageNumber + 1);
  };
  const goToLastPage = () => {
    if (!isLastPage) setPageNumber(numPages);
  };

  const onPageChange = (e) => {
    const { value } = e.target;

    // clamp the page number to max or 1
    const newPageNumber = Math.max(1, Math.min(Number(value), numPages));
    setPageNumber(Number(newPageNumber));
  };

  const isMinZoom = scale < 0.6;
  const isMaxZoom = scale >= 2.0;

  const zoomOut = () => {
    if (!isMinZoom) setScale(scale - 0.1);
  };

  const zoomIn = () => {
    if (!isMaxZoom) setScale(scale + 0.1);
  };

  return (
    <Box
      sx={{
        m: 2,
        borderRadius: 2,
        justifyContent: "space-between",
        display: "flex",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <IconButton onClick={goToFirstPage} disabled={pageNumber === 1}>
          <FirstPage />
        </IconButton>
        <IconButton onClick={goToPreviousPage} disabled={pageNumber === 1}>
          <NavigateBefore />
        </IconButton>
        Page{" "}
        <TextField
          size="small"
          type="number"
          sx={{ pl: 1, mx: 2, width: 60 }}
          onChange={onPageChange}
          value={pageNumber}
        />
        <Typography sx={{ minWidth: 40 }}> of {numPages ?? 1}</Typography>
        <IconButton
          onClick={goToNextPage}
          disabled={!numPages || pageNumber === numPages}
        >
          <NavigateNext />
        </IconButton>
        <IconButton
          onClick={goToLastPage}
          disabled={!numPages || pageNumber === numPages}
        >
          <LastPage />
        </IconButton>
        {showZoomControls && (
          <>
            <IconButton onClick={zoomOut} sx={{ ml: 2 }}>
              <ZoomOut />
            </IconButton>
            <span>{(scale * 100).toFixed()}%</span>
            <IconButton onClick={zoomIn}>
              <ZoomIn />
            </IconButton>
          </>
        )}
        <IconButton
          onClick={() => setRotation((prev) => prev - 90)}
          sx={{ ml: 2 }}
        >
          <Rotate90DegreesCcw />
        </IconButton>
        <IconButton
          onClick={() => setRotation((prev) => prev + 90)}
          sx={{ mr: 2 }}
        >
          <Rotate90DegreesCw />
        </IconButton>
        <IconButton href={file} sx={{ ml: 2 }} target="_blank">
          <Download />
        </IconButton>
        <IconButton onClick={expanded ? handleClose : handleOpen}>
          {handleClose ? <Fullscreen /> : <FullscreenExit />}
        </IconButton>
      </Box>
    </Box>
  );
};

export default ControlPanel;
