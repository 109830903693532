import _ from "lodash";
import { SearchOutlined } from "@mui/icons-material";
import { Checkbox, DialogContent, DialogTitle, Divider, FormControlLabel, Stack, Typography, useTheme } from "@mui/material";
import { SafeLeaseButton, SafeLeaseTextField } from "@safelease/components";
import { FacilityTeamAccordion } from "./FacilityTeamAccordion";
import { NavLink } from "react-router-dom";
import { useEffect, useState } from "react";
import { usePerformanceBoard } from "../usePerformanceBoard";
import { Facility_Reputation as Facility, QRCodeStatus } from "@safelease/service-utilities";

// Component to select which facilities & employees they would like to have QR codes
export function FacilityEmployeeSelector() {
  const theme = useTheme();
  const { setStep, setSelectedUsers, selectedUsers, facilities } = usePerformanceBoard();
  const [facilitySearchText, setFacilitySearchText] = useState<string>("");

  // At least one user at ANY facility is enabled
  const atLeastOneUserChecked = Object.values(selectedUsers)
    .flatMap((facilityUsersObject) => Object.values(facilityUsersObject).map((user) => !!user))
    .some((user) => user);

  // ALL users at ALL facilities are enabled
  const allUsersChecked = Object.values(selectedUsers)
    .flatMap((facilityUsersObject) => Object.values(facilityUsersObject).map((user) => !!user))
    .every((user) => user);

  useEffect(() => {
    if (!atLeastOneUserChecked) setAllFacilityUsersToDefaultStatus();
  }, []);

  // Sets each facility/employee to true/false based on the current existence
  // of a QR code for that user
  const setAllFacilityUsersToDefaultStatus = () => {
    // Build array of all QR codes for all facilities
    const qrCodes = facilities.flatMap((facility) => facility.users.filter((user) => user.qrCodes.length).flatMap((user) => user.qrCodes));

    let remappedFacilities = {};

    for (const facility of _.cloneDeep(facilities)) {
      remappedFacilities[facility.id] = {};

      for (const user of facility.users) {
        // Existing QR code for this facility/employee
        const foundCode = qrCodes.find((qrCode) => {
          const qrCodeBelongsToUser = qrCode.userId === user.id;
          const qrCodeBelongsToFacility = qrCode.facilityId === facility.id;
          const qrCodeIsActive = qrCode.status === QRCodeStatus.ACTIVE;
          return qrCodeBelongsToUser && qrCodeBelongsToFacility && qrCodeIsActive;
        });

        // If the user has a QR code for this facility, set to true
        remappedFacilities[facility.id][user.id] = foundCode;
      }
    }

    setSelectedUsers(remappedFacilities);
  };

  // Sets each facility/employee to true/false equally
  const setAllFacilityUsersAsSelectedStatus = (status: boolean) => {
    let remappedFacilities = {};

    for (const facility of _.cloneDeep(facilities)) {
      remappedFacilities[facility.id] = {};

      for (const user of facility.users) {
        remappedFacilities[facility.id][user.id] = status;
      }
    }

    setSelectedUsers(remappedFacilities);
  };

  const handleToggleAllFacilityUsers = () => {
    if (allUsersChecked) {
      setAllFacilityUsersAsSelectedStatus(false);
    } else {
      setAllFacilityUsersAsSelectedStatus(true);
    }
  };

  return (
    <>
      <DialogTitle sx={{ fontWeight: 600 }}>Distribute review solicitation QR codes to your teams</DialogTitle>
      <DialogContent>
        <Stack direction="column" spacing={2}>
          <Typography>
            Recipients will receive their QR codes via email and text message. If an employee is enabled at multiple facilities, they will
            have a different code/link for each facility.
          </Typography>
          <SafeLeaseTextField
            value={facilitySearchText}
            onChange={(e) => setFacilitySearchText(e.target.value)}
            autoFocus
            margin="dense"
            id="name"
            type="email"
            fullWidth
            startAdornment={<SearchOutlined sx={{ color: "grey.A100", mr: 1 }} />}
            placeholder="Search facilities"
          />
          <FormControlLabel
            onClick={handleToggleAllFacilityUsers}
            control={<Checkbox sx={{ ml: 1 }} indeterminate={atLeastOneUserChecked && !allUsersChecked} />}
            label="Select all facility teams"
            checked={allUsersChecked}
          />
          <FacilityEmployeeSelectorList facilities={facilities} facilitySearchText={facilitySearchText} />
          <SafeLeaseButton color="navy" variant="contained" onClick={() => setStep(1)}>
            Continue to message
          </SafeLeaseButton>
          <Divider />
          <Typography textAlign="center" sx={{ py: 1 }}>
            Missing employee? Go to {/* @ts-ignore  NavLink absolutely can be used as a JSX element, our react router is just old as sin */}
            <NavLink to="/settings" style={{ color: theme.palette.navy.main, fontWeight: 700 }}>
              user management
            </NavLink>
          </Typography>
        </Stack>
      </DialogContent>
    </>
  );
}

interface FacilityEmployeeSelectorListProps {
  facilities: Array<Facility>;
  facilitySearchText: string;
}

/**
 * A small helper component to pull out the list of facilities and filter them based on the search text
 */
function FacilityEmployeeSelectorList({ facilities, facilitySearchText }: FacilityEmployeeSelectorListProps) {
  return (
    <>
      {facilities
        .filter((facility) => {
          const displayName = facility.companyName ?? facility.address;
          return displayName.toLowerCase().includes(facilitySearchText.toLowerCase());
        })
        .sort((a, b) => {
          // Sort first by if the has at least 1 user, then by name alphabetically
          if (a.users.length === 0 && b.users.length > 0) return 1;
          if (a.users.length > 0 && b.users.length === 0) return -1;
          return a.companyName?.localeCompare(b.companyName ?? a.address);
        })
        .map((facility) => (
          <FacilityTeamAccordion key={facility.companyName ?? facility.address} facility={facility} />
        ))}
    </>
  );
}
