import React from 'react';
import PersonIcon from '@mui/icons-material/Person';
import { Avatar, IconButton, Menu, MenuItem } from '@mui/material';
import { muiAvatar, muiDefaultAvatarIcon, muiMenu, muiMenuItem } from '../styles/mui-overrides';

interface Props {
  dropdownOptions: {
    label: string,
    cb: () => void,
  }[]
}
function AvatarDropdown(props: Props) {
  const { dropdownOptions } = props;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Avatar sx={muiAvatar({ active: open })}>
        <IconButton
          id="avatar-button"
          aria-controls={open ? 'avatar-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}>
          <PersonIcon sx={muiDefaultAvatarIcon({ active: open })} />
        </IconButton>
      </Avatar>
      <Menu
        id="avatar-menu"
        PaperProps={{ style: muiMenu }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'avatar-button',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}>
        {
          dropdownOptions.map((option) => (
            <MenuItem
              key={option.label}
              onClick={() => {
                handleClose();
                option.cb();
              }}
              sx={muiMenuItem}
            >
              {option.label}
            </MenuItem>
          ))
        }
      </Menu>
    </>
  )
}

export default AvatarDropdown;