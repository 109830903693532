import { SummarizedMetrics, MetricsHelpers } from '../../utilities/metrics-helpers';
import { Location, Metric } from "../../utilities/generated/gql-types";
import { ChangeStatistic, ZeroState } from '../../common';
import { Formatters } from '../../utilities/formatters';
import { Line } from 'react-chartjs-2';

function AccountSummaryChart(props: {
  error: any,
  loading: boolean,
  aggregateMetrics: {getAggregateMetrics: Metric[]},
  locationId: string | number,
  locations: Location[],
  unsupportedFms: boolean,
  relationship: any
}) {
  const { locations, aggregateMetrics, locationId, unsupportedFms, error, loading } = props;
  
  if (loading) return <>Loading...</>;
  if (error) return <>Error!</>;

  const relationshipId = locations[0]?.relationshipId;

  const currentMonth = (() => {
    const date = new Date();
    date.setHours(0, 0, 0, 0);
    date.setDate(1);
    return date;
  })();
  
  const summarizedMetrics: {[index: string]: SummarizedMetrics} = {};
  ['customerRevenue', 'customerRevenueWithAutoEnroll'].forEach(key => {
    summarizedMetrics[key] = MetricsHelpers.getSummaryByKey(
      aggregateMetrics.getAggregateMetrics,
      key,
      currentMonth);
  });

  const { customerRevenue, customerRevenueWithAutoEnroll } = summarizedMetrics; 

  const prevMonthObj = (() => {
    const date = new Date(customerRevenue?.lastMetric?.date || null);
    date.addMinutes(date.getTimezoneOffset());
    date.setMonth(date.getMonth() - 1);
    return date;
  })();

  const PMRIncreasePercent = (() => {
    if (!locations.every(loc => loc.baselineMonthlyRevenue)) return null;
    const baselineRevenue = locations.reduce((total, location) => {
      return total += (location.baselineMonthlyRevenue * (1 - location.revenueSharePercent / 100));
    }, 0);
    return Math.round(
      (customerRevenue.lastMetric?.value - baselineRevenue) / baselineRevenue);
  })();
    
  const isZeroState = (
      chartData: {
        datasets: {
          data: any[]
        }[]
      },
      monthlyRevenue: SummarizedMetrics, 
      unsupportedFms?: boolean
  ) => {
    return monthlyRevenue === null || monthlyRevenue === undefined ||
        chartData.datasets.filter(dataset => dataset.data.length === 0).length > 0 ||
        unsupportedFms;
  }

  const dataset = aggregateMetrics.getAggregateMetrics
    .filter(metric => metric.key === 'customerBillable')
    .map(metric => ({ x: metric.date, y: metric.value / 100 }));

  const chartData = {
    datasets: [
      {
        label: 'SafeLease Revenue',
        borderColor: '#84D43D',
        backgroundColor: '#84D43D',
        tension: 0.2,
        data: dataset,
      },
    ],
  };
  
  const dashSegment = (ctx) => {
    if (ctx.p1DataIndex === dataset.length - 1) return true
    return false
  }

  var dynamicMetric: JSX.Element;
  if(locations.every(location => location.hasCompleteAutoEnroll)) {
    dynamicMetric = (
      <ChangeStatistic
        relationship={props.relationship}
        loading={props.loading}
        increase={null}
        prevMonth={null}
        currentValue={customerRevenue.lastMetric && Formatters.penniesToDollars(customerRevenue.lastMetric.value * 12, {digits: 0})}
        tooltip={'This is your share of the total annual protection premiums of all actively protected tenants in the selected facility.'}
        title={'Projected Annual Revenue'} />
    );
  } else {
    dynamicMetric = (
      <ChangeStatistic
        relationship={props.relationship}
        loading={props.loading}
        increase={null}
        prevMonth={null}
        currentValue={customerRevenueWithAutoEnroll.lastMetric && Formatters.penniesToDollars(customerRevenueWithAutoEnroll.lastMetric.value, {digits: 0})}
        tooltip={'The estimated revenue your location can expect to earn from the protection program each month when Auto-Protect is enabled.'}
        title={'Projected Monthly Revenue'} 
        showAutoProtect />
    );
  }

  // Month to Date Revenue dummy data, if relationship is 271 (SafeLease)
  const dummyMonthToDateRevenue = aggregateMetrics.getAggregateMetrics?.filter(metric => metric.key === 'customerRevenueThisMonth')[0]?.value;

  return (
    <div className="widget widget-account-summary widget__dashboard-widget">
      <div className="widget-title">Account Summary</div>
      {isZeroState(chartData, customerRevenue, unsupportedFms) &&
        <>
          {!unsupportedFms && 
            <div className="widget--zero-state">
              <ZeroState title="No data found"
                  body="Try adjusting your search or filter settings for better results."
                  svg="/images/no-data-zero-state.svg" />
            </div>
          }
          {unsupportedFms &&
            <div className="widget--zero-state">
              <ZeroState title="No data found"
                  body="This location currently uses a non-supported FMS."
                  svg="/images/no-data-zero-state.svg" />
            </div>
          }
        </>
      }
      {!isZeroState(chartData, customerRevenue, unsupportedFms) &&
        <div className="widget-content">
          <div className="statistics">
            <ChangeStatistic 
              relationship={props.relationship}
              loading={props.loading} 
              // increase null to not render the percent at all as it is to current day
              increase={null}
              prevMonth={null}
              currentValue={
                relationshipId === 271
                  ? Formatters.penniesToDollars(dummyMonthToDateRevenue, { digits: 0 })
                  : Formatters.penniesToDollars(
                      locations
                        .map((l) => l.customerRevenueThisMonth)
                        .reduce((a, b) => a + b, 0),
                      { digits: 0 }
                    )
              }
              tooltip={"The location's share of protection program revenue collected during the selected month."}
              title={'Month to Date Revenue'} />
            <ChangeStatistic 
              relationship={props.relationship}
              loading={props.loading} 
              increase={PMRIncreasePercent}
              prevMonth={prevMonthObj}
              currentValue={Formatters.penniesToDollars(customerRevenue.lastMetric?.value, {digits: 0})}
              tooltip={'The estimated amount of revenue your location can expect to earn from the protection program this month.'}
              title={'Projected Monthly Revenue'} />
            {dynamicMetric}

          </div>
          <Line
            options={MetricsHelpers.getChartOptions(
                chartData, 
                {
                  yFormat: Formatters.formatDollarSign,
                  // On the below line, the [6, 6] references the pixels on:off ratio. If false, line always 'on'
                  segment: { borderDash: ctx => dashSegment(ctx) ? [6, 6] : [6, 0] },
                }) as any}
            data={chartData}
            className="widget-content--line-chart"
          />
        </div>
      }
    </div>
  );
}

export default AccountSummaryChart
