import { useAuth } from '../auth';
import { Redirect, Route } from 'react-router-dom';

/**
 * This route wrapper is used to redirect routes given redirect and redirectTo props.
 * If user is an Admin or a SafeLease user, then they have access to all the routes.
 */
export function SafeleaseRoute(props) {
  const { path, exact, redirect, redirectTo, children } = props;
  const auth = useAuth();

  return (
    <Route exact={exact} path={path}>
      {(!auth.user.isAdmin && auth.user.relationshipId != 271) && redirect ? <Redirect to={redirectTo || '/settings'} /> : children}
    </Route>
  );
}
