import { Info } from "@mui/icons-material";
import { Tooltip } from "@mui/material";

export default function LearnAboutRolesTooltip() {
  return (
    <Tooltip
      title={
        <>
          <p>
            Users assigned the Manager role have the ability to add new
            Dashboard users, access data for all store locations, and schedule
            the delivery of recurring reports via email. Once granted Manager
            status, the user's permissions cannot be changed.
          </p>
          <p>
            Users assigned the Employee role receive access to data associated
            with specific store location(s). The Manager determines the
            location(s) each Employee may access. Employees may only create new users
            that share the same permissions as them.
          </p>
        </>
      }
    >
      <Info sx={{ position: 'absolute', right: 30 }} />
    </Tooltip>
  );
}
