import { Grid } from "@mui/material";
import { useState } from "react";
import { PlanUpgradesOverview } from "./PlanUpgradesOverview";
import { ScheduleUpgradeFormDialog } from "./ScheduleUpgradeForm/ScheduleUpgradeFormDialog";

interface PlanUpgradesProps {}

export function PlanUpgrades({}: PlanUpgradesProps) {

  const [dialogOpen, setDialogOpen] = useState(false);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <PlanUpgradesOverview setDialogOpen={() => setDialogOpen(true)} />
        <ScheduleUpgradeFormDialog open={dialogOpen} handleClose={() => setDialogOpen(false)} />
      </Grid>
    </Grid>
  );
}
