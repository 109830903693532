import { Box, Stack } from "@mui/material";
import { PrivatePolicy } from "../../utilities/generated/gql-types";
import { PrivatePolicyFieldSelectionSection } from "./PrivatePolicyFieldSelectionSection";
import { SectionType } from "./useComplianceCenter";

interface PrivatePolicyReviewFormProps {
  privatePolicy: PrivatePolicy;
}

/* Enables a user to review and/or manually override the AI-extracted values for a private policy */
export function PrivatePolicyReviewForm({
  privatePolicy,
}: PrivatePolicyReviewFormProps) {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
      }}
    >
      <Stack
        direction="column"
        sx={{
          flex: 2,
        }}
        spacing={3}
      >
        <PrivatePolicyFieldSelectionSection
          userInputValue={privatePolicy?.tenantName}
          aiExtractedValue={privatePolicy?.tenantName}
          categoryTitle="Tenant Name"
          sectionId={SectionType.TENANT_NAME}
        />
        <PrivatePolicyFieldSelectionSection
          userInputValue={privatePolicy?.policyNumber}
          aiExtractedValue={privatePolicy?.extractedPolicyNumber}
          categoryTitle="Private Policy Number"
          sectionId={SectionType.POLICY_NUMBER}
        />
        <PrivatePolicyFieldSelectionSection
          userInputValue={privatePolicy?.expiration}
          aiExtractedValue={privatePolicy?.extractedExpiration}
          categoryTitle="Private Policy Expiration Date"
          sectionId={SectionType.EXPIRATION_DATE}
        />
      </Stack>
    </Box>
  );
}
