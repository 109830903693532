import http from "./http-common";
import { User } from '../utilities/generated/gql-types';
import { WithRequiredProperty } from '../utilities/required-property-modifier'

class UserDataService {
  signup(user) {
    return http.post("/users/signup", user);
  }

  signin(user) {
    return http.post("/users/signin", user);
  }

  edit(user) {
    return http.post("/users/edit", user);
  }

  resetPassword(user) {
    return http.post("/users/reset_password", user);
  }
  
  requestEmailSignin(user) {
    return http.post("/users/request_email_signin", user);
  }

  signinFromEmail(user) {
    return http.post("/users/email_signin", user);
  }

  savePassword(params) {
    return http.post("/users/save_password", params);
  }

  add(user: WithRequiredProperty<User, 'relationshipId'>) {
    return http.post("/users/add", user);
  }

  acceptInvitation(params) {
    return http.post("/users/accept_invitation", params);
  }

  delete(params) {
    return http.post("/users/delete", params);
  }

  agreeToTerms() {
    return http.post("/users/agree_to_terms");
  }

  unsubscribe(token) {
    return http.post("/users/unsubscribe", {token});
  }

  getPrimaryFMS() {
    return http.get('/users/get_primary_fms');
  }

  submitUserFeedback(params) {
    return http.post("/users/submit_user_feedback", params);
  }

  resendInvitation(params) {
    return http.post('/users/resend_invitation', params);
  }
  updateInformedOfNotificationsAt(params: {lastInformedAt: Date}) {
    return http.post('/users/update_informed_of_notification_at', params);
  }

  switchPermissions(params) {
    return http.post('/users/switch_permissions', params);
  }

  updateEmailSettings(params) {
    return http.post('/users/update_email_settings', params);
  }

  updateReportSettings(params) {
    return http.post('/users/update_report_settings', params);
  }

  serviceToken(params) {
    return http.get('/users/service_token' + '?' + new URLSearchParams(params));
  }
}

export default new UserDataService();
