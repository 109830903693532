import React from "react";
import { Box, MenuItem, FormControl, Select, SelectChangeEvent } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MenuItemAvatar from "./MenuItemAvatar";

interface CustomSelectProps {
  renderValue: any;
  sx?: any;
  menuItems: any[];
  value: string;
  displayEmpty?: boolean;
  onChange: (event: SelectChangeEvent) => void;
}

export const CustomSelect: React.FC<CustomSelectProps> = ({ renderValue, sx, menuItems, value, onChange, displayEmpty }) => {
  return (
    <Box
      sx={{
        minWidth: "142px",
      }}
    >
      <FormControl fullWidth>
        <Select
          value={value}
          onChange={onChange}
          IconComponent={KeyboardArrowDownIcon}
          sx={sx}
          renderValue={renderValue}
          displayEmpty={displayEmpty}
        >
          {menuItems.map((item) => {
            return (
              <MenuItem key={item.value} value={item.value}>
                <MenuItemAvatar menuItemObject={item} />
                <span>{item.label}</span>
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );
};
