
import { muiLabelSmall } from "../../../styles/mui-overrides";
import { Checkbox, FormControlLabel, FormGroup, Tooltip, Typography } from "@mui/material";

interface UserPermissionsChecklistProps {
  disabled: boolean;
  role: string;
  permissions: {
    revenue: boolean;
    billing: boolean;
  };
  setPermissions: (object: object) => void;
}

export default function UserPermissionsChecklist({
  disabled,
  role,
  permissions,
  setPermissions,
}: UserPermissionsChecklistProps) {

  return (
    <>
      {role === 'employee' && (
        // we will move the condition lower once we have more permissions
        <div className="tw-mb-2">
          <div className="claim-details__email-settings-drawer--assign-locations-header">
            <div className="claim-key claim-key__large">Permissions</div>
          </div>
          <FormGroup>
            <FormControlLabel
              label={<Typography sx={muiLabelSmall()}>Hide Revenue</Typography>}
              control={
                <Checkbox
                  onChange={(e) => setPermissions({ ...permissions, revenue: !e.target.checked })}
                  checked={!permissions.revenue}
                  disabled={!(role === 'employee') || disabled}
                />
              }
            />
            
            <FormControlLabel
              label={<Tooltip title="Turning on this feature enables the selected user to access billing and ACH information, receive invoices via email, and receive billing reminders for all locations and billing entities."><Typography sx={muiLabelSmall()}>Billing</Typography></Tooltip>}
              control={
                <Checkbox
                  onChange={(e) => setPermissions({ ...permissions, billing: e.target.checked })}
                  checked={permissions.billing}
                  disabled={!(role === 'employee') || disabled}
                />
              }
            />
          </FormGroup>
        </div>
      )}
    </>
  );
}
