// React
import { JSXElementConstructor, ReactElement, cloneElement } from "react";

// UI - Libs
import {
  ContentPasteOff,
  EventBusy,
  LabelOff,
  WatchOff,
} from "@mui/icons-material";
import { Box, Menu, MenuItem, Stack, Theme, Typography } from "@mui/material";
import { purple } from "@mui/material/colors";

// hooks
import { useFormContext } from "react-hook-form";
import { RejectionReason } from "./ReviewPrivatePolicyDialog";

interface RejectPrivatePolicyMenuProps {
  isOpen: boolean;
  setRejectAnchorEl: (value: HTMLElement | null) => void;
  rejectAnchorEl: HTMLElement | null;
  handleReject: (rejectionReason: RejectionReason) => void;
}

/* Menu of reasons to reject a private policy, selecting any kick starts the policy rejection process */
export function RejectPrivatePolicyMenu({
  isOpen,
  setRejectAnchorEl,
  rejectAnchorEl,
  handleReject,
}: RejectPrivatePolicyMenuProps) {
  const { watch } = useFormContext();

  const handleClickMenuItem = (rejectionReason: RejectionReason) => {
    handleReject(rejectionReason);
    setRejectAnchorEl(null);
  };

  return (
    <Menu
      open={isOpen}
      onClose={() => setRejectAnchorEl(null)}
      anchorEl={rejectAnchorEl}
      sx={{
        boxShadow: "0 0 20px rgba(0,0,0,0.05)",
      }}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      MenuListProps={{
        sx: {
          borderRadius: 2,
          py: 0,
        },
      }}
    >
      <RejectPrivatePolicyMenuItem
        icon={<ContentPasteOff />}
        title="Invalid or Missing Declaration Page"
        subtitle="Tenant did not upload a valid declaration page, or the declaration page won't load."
        onClick={() => handleClickMenuItem("invalidDeclarationPage")}
      />
      <RejectPrivatePolicyMenuItem
        icon={<LabelOff />}
        title="Invalid Tenant Name"
        subtitle="Tenant name cannot be found on the declaration page, the tenant name is not the same as the name on the lease agreement, or the tenant name is not real (i.e. Mickey Mouse)."
        onClick={() => handleClickMenuItem("invalidTenantName")}
        recommended={watch("tenantName.invalid")}
      />

      <RejectPrivatePolicyMenuItem
        icon={<WatchOff />}
        title="Invalid Policy Number"
        subtitle="Policy number cannot be found on the declaration page, or the policy number is not real (i.e. 1234567890)."
        onClick={() => handleClickMenuItem("invalidPolicyNumber")}
        recommended={watch("policyNumber.invalid")}
      />

      <RejectPrivatePolicyMenuItem
        icon={<EventBusy />}
        title="Invalid Policy Expiration Date"
        subtitle="Policy expiration date cannot be found on the declaration page, policy expiration date is in the past, or policy expiration is more than two years in the future (i.e. 12/12/2099)."
        onClick={() => handleClickMenuItem("invalidExpiration")}
        recommended={watch("expirationDate.invalid")}
      />
    </Menu>
  );
}

interface RejectPrivatePolicyMenuItemProps {
  icon: ReactElement<any, string | JSXElementConstructor<any>>;
  title: string;
  subtitle: string;
  onClick: () => void;
  recommended?: boolean;
}

/* Layout of an individual item in the reject private policy menu */
function RejectPrivatePolicyMenuItem({
  icon,
  title,
  subtitle,
  onClick,
  recommended = false,
}: RejectPrivatePolicyMenuItemProps) {
  return (
    <MenuItem
      onClick={onClick}
      sx={{
        borderBottom: "1px solid",
        borderColor: (theme: Theme) => theme.palette.divider,
        py: 2,
        fontSize: "14px",
        alignItems: "flex-start",
        flexDirection: "column",
        width: 500,
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        spacing={1}
        sx={{ mb: 1, width: "100%" }}
      >
        {cloneElement(icon, {
          sx: {
            color: (theme: Theme) => theme.palette.grey[600],
            height: "auto",
            width: (theme: Theme) => theme.spacing(3),
          },
        })}
        <Typography variant="body2" sx={{ fontWeight: "600", flex: 1 }}>{title}</Typography>
        {recommended && (
          <Box
            sx={{
              ml: "auto",
              borderRadius: "50em",
              fontWeight: 600,
              bgcolor: purple[50],
              color: purple[500],
              px: 1,
              py: 0.25,
            }}
          >
            Recommended
          </Box>
        )}
      </Stack>
      <Box
        sx={{
          ml: (theme: Theme) => theme.spacing(4),
        }}
      >
        <Typography
          variant="subtitle2"
          sx={{
            color: (theme: Theme) => theme.palette.grey[600],
            flexWrap: "wrap",
            whiteSpace: "initial",
            lineHeight: "1.4em",
          }}
        >
          {subtitle}
        </Typography>
      </Box>
    </MenuItem>
  );
}
