import { useCallback, useEffect, useState } from 'react';
import { usePlaidLink, PlaidLinkOnSuccess, PlaidLinkOnExit } from 'react-plaid-link';
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Checkbox,
  IconButton,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Snackbar,
  FormControl,
  Select,
  MenuItem,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useQuery, QueryResult, useApolloClient } from '@apollo/client';
import { useAuth } from '../../auth.js';
import { FundingSource, Relationship, BillingEntity } from '../../utilities/generated/gql-types';
import {
  getBillingEntities,
  getFundingSources,
  getRelationship,
  getRelationshipsSelector,
} from '../../queries';
import BillingDataService from '../../services/billing.service';
import { setupStatuses } from '../../utilities/setup-statuses';
import { TitleValuePair, SafeleaseDropdown } from '../../common';
import { mixpanelEventHandler } from '../../utilities/reactMixpanelHandler';
import { muiSelect, muiStatisticTooltip, ghostButtonNavy } from '../../styles/mui-overrides';
import RouterHelper from '../../utilities/router-helper';
import { useHistory } from 'react-router-dom';
import { Image } from '../../image';
import Loader from '../../shared/Loader';
import Error from '../../shared/Error';

function BillingSettings(props: { routerHelper: RouterHelper }) {
  const { routerHelper } = props;

  const history = useHistory();
  const auth = useAuth();
  const isAdmin = auth.user.isAdmin;
  const isSuperAdmin = auth.user.role === 'superAdmin';
  const client = useApolloClient();

  // The link token that is returned from Plaid used when instantiating Instant Account Verification
  const [linkToken, setLinkToken] = useState<string | null>(null);
  const [alertMessage, setAlertMessage] = useState<{
    message: string;
    severity: 'error' | 'warning' | 'info' | 'success';
  } | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [billingAgreementChecked, setBillingAgreementChecked] = useState<boolean>(false);
  const [billingAgreementFirstName, setBillingAgreementFirstName] = useState<string | null>(null);
  const [billingAgreementLastName, setBillingAgreementLastName] = useState<string | null>(null);
  const [agreementError, setAgreementError] = useState<string | null>(null);

  const relationshipId = routerHelper?.getRelationshipId() || auth.user?.relationshipId;

  const relationshipsSelectorQuery: QueryResult = useQuery(getRelationshipsSelector, {
    skip: !isAdmin,
  });

  const relationshipQuery: QueryResult<{ getRelationship: Relationship }> = useQuery(
    getRelationship,
    {
      variables: { id: relationshipId },
    }
  );

  const billingEntitiesQuery: QueryResult<{ getBillingEntities: BillingEntity[] }> = useQuery(
    getBillingEntities,
    {
      variables: { relationshipId },
    }
  );

  const fundingSourcesQuery: QueryResult<{ getFundingSources: FundingSource[] }> = useQuery(
    getFundingSources,
    {
      variables: { relationshipId },
    }
  );

  // Create a Plaid Link token and persist it in our component state
  const createLinkToken = useCallback(async () => {
    const response = await BillingDataService.createLinkToken(null);
    setLinkToken(response.data.linkToken);
    console.log(`Created Link Token: ${response.data.linkToken}`);
  }, [setLinkToken]);

  // Create a Link Token if one does not already exist
  useEffect(() => {
    if (createLinkToken && !linkToken) {
      createLinkToken().catch((err) => console.error(err));
    }
  }, [createLinkToken, linkToken]);

  /**
   * Exchange Plaid's Link Token for a Process Token when Link finishes successfully
   * Plaid Docs: https://plaid.com/docs/link/web/#onsuccess
   */
  const handlePlaidLinkSuccess: PlaidLinkOnSuccess = async (publicToken, metadata) => {
    setAlertMessage({
      message: 'Exchanging Plaid token. Please wait...',
      severity: 'info',
    });
    
    // Exchange Plaid's Public Token for a Processor Token
    const response = await BillingDataService.processPublicToken(
      publicToken,
      metadata,
      relationshipId
    );

    mixpanelEventHandler('Billing Settings - Added bank account/payment method(s)');
    const message = response.data.automaticallyVerified
      ? 'Funding source connected with SafeLease'
      : 'Funding source verification in progress. You should receive an email from SafeLease in the next 1-2 business days when your funding source is ready for verification.';
    setAlertMessage({
      message,
      severity: 'success',
    });
    client.refetchQueries({ include: [getFundingSources, getBillingEntities] });
  };

  /**
   * Exchange Plaid's Link Token for a Process Token when Link finishes prematurely
   * Plaid Docs: https://plaid.com/docs/link/web/#onexit
   */
  const handlePlaidLinkExit: PlaidLinkOnExit = async (error, metadata) => {
    mixpanelEventHandler('Billing Settings - Exited from adding bank account/payment methods');
    setAlertMessage({
      message: 'Please try again and complete the process to connect your bank account.',
      severity: 'error',
    });

    // Generate new link token if invalid
    if (error != null && error.error_code === 'INVALID_LINK_TOKEN') {
      setLinkToken(null);
    }
  };

  const { open: openPlaidLink, ready: isPlaidLinkReady } = usePlaidLink({
    onSuccess: handlePlaidLinkSuccess,
    onExit: handlePlaidLinkExit,
    token: linkToken,
  });

  const deleteFundingSource = async (fundingSourceId) => {
    if (window.confirm('Are you sure you want to disconnect this bank account from SafeLease?')) {
      try {
        const reponse = await BillingDataService.deleteFundingSource(fundingSourceId);
        console.log(`Deleted funding source ${reponse.data.fundingSourceId}`);
        client.refetchQueries({ include: [getFundingSources, getBillingEntities] });
      } catch (e) {
        setAlertMessage({
          message:
            e?.response?.data?.error || 'Failed to disconnect the bank account from SafeLease.',
          severity: 'error',
        });
      }
    }
  };

  const refreshFundingSource = async (fundingSourceId: number) => {
    try {
      const reponse = await BillingDataService.refreshFundingSource(fundingSourceId);
      console.log(`Refreshed funding source ${reponse.data.fundingSourceId}`);
      client.refetchQueries({ include: [getFundingSources, getBillingEntities] });
    } catch (e) {
      setAlertMessage({
        message: e?.response?.data?.error || 'Failed to refresh the bank account.',
        severity: 'error',
      });
    }
  };

  const saveBillingSettings = async (billingEntityId: number, fundingSourceId: number) => {
    try {
      setLoading(true);
      await BillingDataService.saveBillingSettings(billingEntityId, fundingSourceId);
      setLoading(false);

      setAlertMessage({
        message: 'Billing entity successfully connected to the funding source.',
        severity: 'success',
      });
      client.refetchQueries({ include: [getFundingSources, getBillingEntities] });
      return true;
    } catch (e) {
      setLoading(false);
      setAlertMessage({
        message: e?.response?.data?.error || 'Failed to save the billing entity.',
        severity: 'error',
      });
      return false;
    }
  };

  const agreeToTerms = async () => {
    setAgreementError(null);

    if (!billingAgreementFirstName || !billingAgreementLastName) {
      setAgreementError('Both your first and last name are required in order to agree to terms.');
      return;
    }

    if (!billingAgreementChecked) {
      setAgreementError('You must agree to terms in order to proceed.');
      return;
    }

    try {
      await BillingDataService.signBillingAgreement(
        billingAgreementFirstName,
        billingAgreementLastName
      );
    } catch (e) {
      setAgreementError(e?.response?.data?.error || 'Failed to agree to terms.');
    }

    mixpanelEventHandler('Billing Settings - Accept Billing TOS');

    client.refetchQueries({ include: [getRelationship] });
  };

  // If the funding source name doesn't have the account number mask, append it to the name
  const fundingSourceDisplayName = (fundingSource, shortName = false) => {
    return fundingSource
      ? `${!shortName && fundingSource?.institution ? `${fundingSource.institution} ` : ''}${
          fundingSource.name
        }${!fundingSource.name.match(/^.*\d{4}.*$/) ? ` (${fundingSource.bankAccountMask})` : ''}`
      : '';
  };

  const updateBillingMethod = async (billingEntityId: number, billingMethod: string) => {
    try {
      setLoading(true);
      await BillingDataService.updateBillingMethod(billingEntityId, billingMethod);

      setAlertMessage({
        message: 'Billing entity successfully updated.',
        severity: 'success',
      });
      client.refetchQueries({ include: [getBillingEntities] });
    } catch (e) {
      setAlertMessage({
        message: e?.response?.data?.error || 'Failed to update the billing entity.',
        severity: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  if (relationshipQuery.loading || billingEntitiesQuery.loading || fundingSourcesQuery.loading) return <Loader />;
  if (relationshipQuery.error || billingEntitiesQuery.error || fundingSourcesQuery.error) return <Error />;

  const isBillingAgreementIncomplete =
    relationshipQuery?.data?.getRelationship &&
    (!relationshipQuery?.data?.getRelationship?.billingAgreementFirstName ||
      !relationshipQuery?.data?.getRelationship?.billingAgreementLastName);

  return (
    <div>
      {auth.user && isAdmin && relationshipsSelectorQuery.data && (
        <FormControl sx={{ marginTop: 1, marginBottom: 2, minWidth: 120 }}>
          <Select
            value={relationshipId}
            displayEmpty
            onChange={(e) => {
              routerHelper.navigateToRoute('billingSettings', e.target.value, history);
              window.location.reload();
            }}
            sx={{ ...muiSelect, marginLeft: '0px', padding: '0px' }}
          >
            {relationshipsSelectorQuery.data.getRelationshipsSelector?.map((relationship) => {
              return (
                <MenuItem key={relationship.id} value={relationship.id}>
                  {relationship.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      )}

      <TitleValuePair
        title="Add Bank Account"
        value={
          <>
            SafeLease enables streamlined bill payments via Plaid, a secure third-party software
            that connects financial information to our database. Follow the instructions below to
            connect and verify your banking details.
            <ol className="tw-mt-1 tw-mb-1">
              <li>
                Select <strong>Add Bank Account</strong>.
              </li>
              <li>
                You will be guided through setup using the Plaid interface.
                <ul>
                  <li>
                    Banks integrated with Plaid will prompt you to login to your account and no
                    further action is required.
                  </li>
                  <li>
                    If your provider is not integrated with Plaid, you will be asked to manually
                    enter your routing number and account number.
                  </li>
                  <li>
                    Plaid will verify the account by depositing a small amount of money, typically
                    $0.01, into your banking account. Information from this transaction is required
                    to complete verification.
                  </li>
                </ul>
              </li>
              <li>
                Setup is complete when the account is marked <strong>Verified</strong> in the Status
                column.
              </li>
            </ol>
            <div className="tw-italic">
              Note: You may connect multiple bank accounts within one Plaid login.  If you choose to use micro-deposits, Plaid limits micro-deposits to three per day.
            </div>
          </>
        }
      />

      {fundingSourcesQuery?.data?.getFundingSources?.length > 0 ? (
        <>
          <div className="tw-flex tw-flex-row tw-justify-end">
            <Button
              sx={{ ...ghostButtonNavy, height: '36px' }}
              type="button"
              disabled={!isPlaidLinkReady || loading}
              onClick={() => {
                mixpanelEventHandler(
                  'Billing Settings - Initiate adding bank account/payment methods'
                );
                openPlaidLink();
              }}
            >
              Add Bank Account
            </Button>
          </div>
          <TableContainer component={Paper} className="tw-mt-4">
            <Table sx={{ minWidth: 650 }} size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Bank Account</TableCell>
                  <TableCell align="left">Institution</TableCell>
                  <TableCell align="left">Account Type</TableCell>
                  <TableCell align="left">Address</TableCell>
                  <TableCell align="left">Status</TableCell>
                  <TableCell align="right">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {fundingSourcesQuery.data.getFundingSources.map((fundingSource) => {
                  return (
                    <FundingSourceRow
                      key={fundingSource.id}
                      fundingSource={fundingSource}
                      fundingSourceDisplayName={fundingSourceDisplayName}
                      deleteFundingSource={deleteFundingSource}
                      refreshFundingSource={refreshFundingSource}
                      setAlertMessage={setAlertMessage}
                      client={client}
                      isSuperAdmin={isSuperAdmin}
                    />
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      ) : (
        <div className="tw-flex tw-flex-row tw-justify-center">
          <Button
            sx={{ ...ghostButtonNavy, height: '36px' }}
            type="button"
            disabled={!isPlaidLinkReady}
            onClick={() => {
              mixpanelEventHandler(
                'Billing Settings - Initiate adding bank account/payment methods'
              );
              openPlaidLink();
            }}
          >
            Add Bank Account
          </Button>
        </div>
      )}

      <div className="settings--breaker-bar" style={{ marginTop: '24px', height: '16px' }}></div>
      <TitleValuePair
        title="Match Bank Account to the Associated Billing Entity"
        value={
          <>
            A list of billing entities has been populated by your SafeLease Customer Success
            Manager. Please match each billing entity with its associated bank account.
            <ol className="tw-mt-1 tw-mb-1">
              <li>
                Using the <strong>Bank Account</strong> dropdown, select the banking account
                associated with the billing entity.
              </li>
              <li>
                Select the <strong>Checkmark</strong> icon in the <strong>Actions</strong> column to
                verify the account.
              </li>
              <li>Repeat until all billing entities have an assigned banking account.</li>
            </ol>
          </>
        }
      />

      {billingEntitiesQuery?.data?.getBillingEntities?.length > 0 &&
        fundingSourcesQuery?.data?.getFundingSources && (
          <TableContainer component={Paper} className="tw-mt-4">
            <Table sx={{ minWidth: 650 }} size="small">
              <TableHead>
                <TableRow>
                  <TableCell>
                    Billing Entity
                    <Tooltip
                      placement="bottom"
                      title={
                        'A billing entity is the LLC or store location to be billed by SafeLease for its protection program. You may have more than one billing entity.'
                      }
                      componentsProps={{
                        tooltip: {
                          sx: { backgroundColor: '#152744' },
                        },
                      }}
                    >
                      <Button sx={muiStatisticTooltip}>
                        <span className="badge">
                          <Image src="/images/tooltip-icon.svg" alt="warning" />
                        </span>
                      </Button>
                    </Tooltip>
                  </TableCell>
                  {isAdmin && <TableCell align="left">Billing Method</TableCell>}
                  <TableCell align="left">Bank Account</TableCell>
                  <TableCell align="left">Status</TableCell>
                  <TableCell align="right">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!billingEntitiesQuery.loading &&
                  billingEntitiesQuery.data.getBillingEntities.map((billingEntity) => {
                    return (
                      <BillingEntityRow
                        key={billingEntity.id}
                        billingEntity={billingEntity}
                        fundingSources={fundingSourcesQuery?.data?.getFundingSources || []}
                        fundingSourceDisplayName={fundingSourceDisplayName}
                        isAdmin={isAdmin}
                        saveBillingSettings={saveBillingSettings}
                        updateBillingMethod={updateBillingMethod}
                      />
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        )}

      {!!alertMessage && (
        <Snackbar open anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
          <Alert
            className="tw-mt-4 tw-mb-4"
            severity={alertMessage?.severity}
            action={
              <IconButton
                color="inherit"
                onClick={() => {
                  setAlertMessage(null);
                }}
              >
                <CloseIcon />
              </IconButton>
            }
          >
            {alertMessage?.message}
          </Alert>
        </Snackbar>
      )}

      <Modal
        open={isBillingAgreementIncomplete && !isAdmin}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="popup-modal"
      >
        <Box className="modal-box modal-confirm">
          <form>
            <div className="tw-text-lg tw-font-bold">Billing Terms of Service</div>
            <div className="tw-flex tw-flex-row tw-justify-between tw-space-x-3 tw-mt-4">
              <TextField
                label="Your First Name"
                defaultValue={
                  relationshipQuery?.data?.getRelationship?.billingAgreementFirstName || ''
                }
                onChange={(event) => setBillingAgreementFirstName(event.target.value)}
              />
              <TextField
                label="Your Last Name"
                defaultValue={
                  relationshipQuery?.data?.getRelationship?.billingAgreementLastName || ''
                }
                onChange={(event) => setBillingAgreementLastName(event.target.value)}
              />
            </div>
            <div className="tw-flex tw-flex-row tw-mt-4">
              <div className="tw-flex tw-flex-col">
                <Checkbox
                  defaultChecked={billingAgreementChecked}
                  onChange={(event) => setBillingAgreementChecked(event.target.checked)}
                />
              </div>
              <div className="tw-flex tw-flex-col">
                <a>
                  By checking this box and pressing <strong>Agree & Continue</strong>, you agree to{' '}
                  <a
                    href="https://info.safelease.com/terms-of-service"
                    target="_blank"
                    rel="noreferrer"
                  >
                    SafeLease's Terms of Service
                  </a>{' '}
                  and{' '}
                  <a
                    href="https://info.safelease.com/safelease-privacy-policy"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Privacy Policy
                  </a>
                  , as well as our partner{' '}
                  <a href="https://www.dwolla.com/legal/tos" target="_blank" rel="noreferrer">
                    Dwolla's Terms of Service
                  </a>{' '}
                  and{' '}
                  <a href="https://www.dwolla.com/legal/privacy" target="_blank" rel="noreferrer">
                    Privacy Policy
                  </a>
                  .
                </a>
              </div>
            </div>
            <div className="tw-flex tw-mb-2 tw-mt-4 tw-justify-around">
              <Button className="yes-confirm-button" onClick={() => agreeToTerms()}>
                Agree & Continue
              </Button>
            </div>
            <p style={{ color: 'red' }}>{agreementError}</p>
          </form>
        </Box>
      </Modal>
    </div>
  );
}

function FundingSourceRow(props) {
  const {
    fundingSource,
    fundingSourceDisplayName,
    deleteFundingSource,
    refreshFundingSource,
    setAlertMessage,
    client,
    isSuperAdmin,
  } = props;

  // The link token that is returned from Plaid used when instantiating Instant Account Verification
  const [linkToken, setLinkToken] = useState<string | null>(null);

  // Create a Plaid Link token and persist it in our component state
  const createLinkToken = useCallback(async () => {
    console.log('Creating Plaid Link Token', fundingSource.id);
    const response = await BillingDataService.createLinkToken(fundingSource.id);
    setLinkToken(response.data.linkToken);
    console.log(`Created Link Token: ${response.data.linkToken}`);
  }, [fundingSource.id, setLinkToken]);

  // Create a Link Token if one does not already exist
  useEffect(() => {
    if ((fundingSource.setupStatus === 'verified' || fundingSource.setupStatus === 'pending') && createLinkToken && !linkToken) {
      createLinkToken().catch((err) => console.error(err));
    }
  }, [fundingSource.setupStatus, createLinkToken, linkToken]);

  /**
   * Exchange Plaid's Link Token for a Process Token when Link finishes successfully
   * Plaid Docs: https://plaid.com/docs/link/web/#onsuccess
   */
  const handlePlaidLinkSuccess: PlaidLinkOnSuccess = async (publicToken, metadata) => {
    try {
      setAlertMessage({
        message: 'Processing deposit verification. Please wait...',
        severity: 'info',
      });
      const response = await BillingDataService.verifyMicrodeposit(metadata, fundingSource.id);

      if (response.data.fundingSource.microdepositStatus === 'manually_verified') {
        mixpanelEventHandler(
          'Billing Settings - Verified bank account/payment methods micro-deposits'
        );
        setAlertMessage({
          message: 'Funding source successfully verified.',
          severity: 'success',
        });
        client.refetchQueries({ include: [getFundingSources, getBillingEntities] });
      } else if (metadata.institution) { // Check if the institution property exists in the metadata indicating the update flow completed
        mixpanelEventHandler(
          'Billing Settings - Successfully updated bank account/payment methods'
        );
        setAlertMessage({
          message: 'Bank account/payment methods updated successfully.',
          severity: 'success',
        });
        client.refetchQueries({ include: [getFundingSources, getBillingEntities] });
      } else {
        mixpanelEventHandler(
          'Billing Settings - Failed verifying bank account/payment methods micro-deposits'
        );
        setAlertMessage({
          message: 'An unexpected error occurred when verifying your bank account.',
          severity: 'error',
        });
      } 
    } catch (e) {
      mixpanelEventHandler(
        'Billing Settings - Failed verifying bank account/payment methods micro-deposits'
      );
      setAlertMessage({
        message: `An unexpected error occurred when verifying your bank account: ${e}`,
        severity: 'error',
      });
    }
  };

  /**
   * Exchange Plaid's Link Token for a Process Token when Link finishes prematurely
   * Plaid Docs: https://plaid.com/docs/link/web/#onexit
   */
  const handlePlaidLinkExit: PlaidLinkOnExit = async (error, metadata) => {
    mixpanelEventHandler(
      'Billing Settings - Exited from verifying bank account/payment methods micro-deposits'
    );

    // Generate new link token if invalid
    if (error?.error_code === 'INVALID_LINK_TOKEN') {
      setLinkToken(null);
    } else if (error?.error_code === 'MICRODEPOSITS_ALREADY_VERIFIED') {
      setAlertMessage({
        message:
          'Deposit already verified. Please refresh the page to see your verified bank account.',
        severity: 'info',
      });
    } else if (error?.error_code === 'INCORRECT_DEPOSIT_VERIFICATION') {
      setAlertMessage({
        message:
          'Incorrect deposit verification input. Please try again and complete the process to verify your bank account.',
        severity: 'error',
      });
    } else {
      setAlertMessage({
        message: 'Please try again and complete the process to verify your bank account.',
        severity: 'error',
      });
    }
  };

  const { open: openPlaidLink, ready: isPlaidLinkReady } = usePlaidLink({
    onSuccess: handlePlaidLinkSuccess,
    onExit: handlePlaidLinkExit,
    token: linkToken,
  });
  
  return (
    <TableRow key={fundingSource.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
      <TableCell component="th" scope="row">
        {fundingSourceDisplayName(fundingSource, true)}
      </TableCell>
      <TableCell align="left">{fundingSource.institution}</TableCell>
      <TableCell align="left">
        {fundingSource.bankAccountType.charAt(0).toUpperCase() +
          fundingSource.bankAccountType.slice(1)}
      </TableCell>
      <TableCell align="left">{fundingSource.fullAddress}</TableCell>
      <TableCell align="left">
        {setupStatuses[fundingSource.setupStatus].label}
        <span className="badge">
          <Image
            src={setupStatuses[fundingSource.setupStatus].image}
            alt={setupStatuses[fundingSource.setupStatus].label}
          />
        </span>
      </TableCell>
      <TableCell align="right" sx={{ minWidth: 210 }}>
        {fundingSource.setupStatus === 'pending' && (
          <Button
            sx={{ ...ghostButtonNavy, height: '36px', marginRight: '10px' }}
            type="button"
            disabled={!isPlaidLinkReady}
            onClick={() => {
              mixpanelEventHandler(
                'Billing Settings - Initiate verifying bank account/payment methods micro-deposits'
              );
              openPlaidLink();
            }}
          >
            Verify Deposit
          </Button>
        )}
        {fundingSource.setupStatus === 'verified' && (
          <Button
            sx={{ ...ghostButtonNavy, height: '36px', marginRight: '10px' }}
            type="button"
            disabled={!isPlaidLinkReady}
            onClick={() => {
              mixpanelEventHandler(
                'Billing Settings - Initiate updating bank account/payment methods'
              );
              openPlaidLink();
            }}
          >
            Update
          </Button>
        )}
        {isSuperAdmin && (
          <Tooltip title="Refresh from Plaid">
            <IconButton onClick={() => refreshFundingSource(fundingSource.id)}>
              <RefreshIcon />
            </IconButton>
          </Tooltip>
        )}
        <Tooltip title="Delete">
          <IconButton onClick={() => deleteFundingSource(fundingSource.id)}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
}

function BillingEntityRow(props) {
  const {
    billingEntity,
    fundingSources,
    fundingSourceDisplayName,
    isAdmin,
    saveBillingSettings,
    updateBillingMethod,
  } = props;

  const [activeFundingSourceId, setActiveFundingSourceId] = useState<number | null>(
    billingEntity.fundingSourceId
  );
  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState<boolean>(!billingEntity.fundingSourceId);
  const [setupStatus, setSetupStatus] = useState<string>(billingEntity.setupStatus);

  useEffect(() => {
    setSetupStatus(billingEntity.setupStatus);
  }, [billingEntity]);

  const save = async () => {
    if (!activeFundingSourceId) return;

    setLoading(true);
    const success = await saveBillingSettings(billingEntity.id, activeFundingSourceId);
    setLoading(false);

    setEdit(!success);
  };

  const matchFundingSource = () => {
    if (!!billingEntity.fundingSourceId) {
      return fundingSourceDisplayName(
        fundingSources.find((fundingSource) => fundingSource.id == billingEntity.fundingSourceId)
      );
    }

    const match = fundingSources.find((fundingSource) => {
      return fundingSource?.ownerNames && fundingSource.ownerNames.includes(billingEntity.name);
    });

    if (match && setupStatus == 'unsetup') setSetupStatus('matched');
    if (match && !activeFundingSourceId) setActiveFundingSourceId(match.id);

    return match ? fundingSourceDisplayName(match) : null;
  };

  const bankAccountOptions = fundingSources
    ?.filter(
      (fundingSource) =>
        (!fundingSource.legacy && fundingSource.setupStatus === 'verified') ||
        billingEntity?.fundingSourceId == fundingSource.id
    )
    ?.map((fundingSource) => fundingSourceDisplayName(fundingSource));

  if (
    billingEntity?.fundingSourceId &&
    billingEntity.fundingSourceId == activeFundingSourceId &&
    setupStatus !== billingEntity.setupStatus
  )
    setSetupStatus(billingEntity.setupStatus);

  return (
    <TableRow key={billingEntity.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
      <TableCell component="th" scope="row">
        {billingEntity.name}
      </TableCell>
      {isAdmin && (
        <TableCell align="left">
          <SafeleaseDropdown
            selectStyles={{ marginBottom: '0' }}
            defaultValue={billingEntity.billingMethod}
            onChange={(event) => {
              updateBillingMethod(billingEntity.id, event.target.value);
            }}
            menuOptionList={['ach', 'other']}
          />
        </TableCell>
      )}
      <TableCell align="left">
        <SafeleaseDropdown
          selectStyles={{ marginBottom: '0' }}
          defaultValue={matchFundingSource()}
          onChange={(event) => {
            setActiveFundingSourceId(
              fundingSources.find(
                (fundingSource) => fundingSourceDisplayName(fundingSource) == event.target.value
              )?.id
            );
            setSetupStatus('matched');
          }}
          menuOptionList={bankAccountOptions}
          disabled={!edit || billingEntity.billingMethod !== 'ach'}
        />
      </TableCell>
      <TableCell align="left">
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            {setupStatuses[setupStatus].label}
            <span className="badge">
              <Image
                src={setupStatuses[setupStatus].image}
                alt={setupStatuses[setupStatus].label}
              />
            </span>
            {setupStatuses[setupStatus].description && (
              <Tooltip
                placement="bottom"
                title={setupStatuses[setupStatus].description}
                componentsProps={{
                  tooltip: {
                    sx: { backgroundColor: '#152744' },
                  },
                }}
              >
                <Button sx={muiStatisticTooltip}>
                  <span className="badge">
                    <Image src="/images/tooltip-icon.svg" alt="warning" />
                  </span>
                </Button>
              </Tooltip>
            )}
          </>
        )}
      </TableCell>
      <TableCell align="right">
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            {edit ? (
              <Button sx={{ ...ghostButtonNavy, height: '36px' }} onClick={() => save()}>
                Save
              </Button>
            ) : (
              <Tooltip title="Edit">
                <IconButton
                  onClick={() => {
                    setEdit(true);
                    setSetupStatus('matched');
                  }}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
            )}
          </>
        )}
      </TableCell>
    </TableRow>
  );
}

export default BillingSettings;
