import { Box } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers-pro";
import { usePlanUpgradeStore } from "../usePlanUpgradeSettingsStore";
import dayjs, { Dayjs } from "dayjs";

interface SelectEnforcementDateStepProps {}

export function SelectEnforcementDateStep({}: SelectEnforcementDateStepProps) {
  const enforcementDate = usePlanUpgradeStore((state) => state.enforcementDate);
  const setEnforcementDate = usePlanUpgradeStore(
    (state) => state.setEnforcementDate
  );

  return (
    <Box>
      <DatePicker<Dayjs>
        value={dayjs(enforcementDate)}
        onChange={setEnforcementDate}
        disablePast
      />
    </Box>
  );
}
