/* React */
import { useState } from "react";

/* UI - Libs */
import { ArrowDropDown } from "@mui/icons-material";
import {
  Box,
  Button,
  ButtonProps,
  Checkbox,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Popover
} from "@mui/material";

/* UI - Internal */
import LocationSelectorMenuItem from "./ReportsLocationSelectorMenuItem";

/* Utils */
import _ from "lodash";
import { useReportsStore } from "../../reports/useReportsStore";

interface ReportsLocationSelectorProps extends ButtonProps {}

export default function ReportsLocationSelector({
  disabled = false,
}: ReportsLocationSelectorProps) {
  /* State */
  const locations = useReportsStore((state) => state.locations);
  const locationIds = useReportsStore((state) => state.locationIds);

  /* Manage visible locations */
  const selectedLocationsInReport = useReportsStore(
    (state) => state.selectedLocationsInReport
  );

  const setSelectedLocationsInReport = useReportsStore(
    (state) => state.setSelectedLocationsInReport
  );
  
  /* Menu visibility */
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  /* Toggle all or none */
  const handleClickMultiSelectionCheckbox = () => {
    if (locationIds.length === selectedLocationsInReport.length) {
      setSelectedLocationsInReport([]);
    } else {
      setSelectedLocationsInReport(locationIds);
    }
  };

  const allLocationsIncludedInReport =
    locationIds.length === selectedLocationsInReport.length;

  return (
    <>
      <Button
        disabled={disabled}
        aria-controls={open ? "report-positioned-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        endIcon={
          <ArrowDropDown
            sx={{
              transform: open ? "rotate(180deg)" : "initial",
              transition: "150ms",
            }}
          />
        }
        onClick={handleOpenPopover}
        sx={{
          bgcolor: "white",
          border: "1px solid #EBEFF7",
          color: "#152744",
          boxShadow: "0 0 20px rgba(0,0,0,0.05)",
          textTransform: "none",
          "&:hover": {
            border: "1px solid rgba(0,0,0,0.23)",
          },
          borderRadius: 2
        }}
      >
        {selectedLocationsInReport.length} / {locationIds.length}{" "}
        locations selected
      </Button>
      <Popover
        open={open}
        onClose={() => setAnchorEl(null)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <ListItem sx={{ borderBottom: "1px solid #EBEFF7" }}>
          <ListItemText
            primary={`${selectedLocationsInReport.length} locations selected`}
            primaryTypographyProps={{ sx: { fontWeight: "bold" } }}
          />
          <ListItemIcon>
            <Checkbox
              size="small"
              sx={{ height: 38 }}
              checked={allLocationsIncludedInReport}
              indeterminate={
                !allLocationsIncludedInReport &&
                selectedLocationsInReport.length > 0
              }
              onChange={handleClickMultiSelectionCheckbox}
            />
          </ListItemIcon>
        </ListItem>
        <Box sx={{ overflowY: "scroll", maxHeight: 400 }}>
          <List>
            {locations.map((location, i) => {
              return <LocationSelectorMenuItem location={location} key={i} />;
            })}
          </List>
        </Box>
      </Popover>
    </>
  );
}