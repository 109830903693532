/* React */
import { useState } from "react";

/* UI - libs */
import { Box, Button, Grid, Paper, Stack, Typography } from "@mui/material";
import { ArrowForward } from "@mui/icons-material";
import { PopupModal, useCalendlyEventListener } from "react-calendly";

/* UI - internal */
import MetricHighlightCard from "../../../reports/MetricComponents/MetricHighlightCard";
import StatisticCard from "../../../reports/MetricComponents/StatisticCard";
import { ghostButtonNavy, navyButton } from "../../../styles/mui-overrides";

/* Hooks */
import { useReportsStore } from "../../../reports/useReportsStore";
import { useAuth } from "../../../auth";

/* Data */
import { getLocation, getRelationship } from "../../../queries";
import { Relationship } from "../../../utilities/generated/gql-types";
import { QueryResult, useQuery } from "@apollo/client";

/* Utils */
import { Formatters } from "../../../utilities/formatters";
import _ from "lodash";
import { mixpanelEventHandler } from "../../../utilities/reactMixpanelHandler";

interface PrivatePolicyReportOverviewProps {}

/* Display important metrics about the current state of a relationship's private policies */
export function PrivatePolicyReportOverview({}: PrivatePolicyReportOverviewProps) {
  const auth = useAuth();

  /* ----------------------------------------------------------- */
  /* Report Config                                               */
  /* ----------------------------------------------------------- */
  const selectedLocationsInReport = useReportsStore(
    (state) => state.selectedLocationsInReport
  );

  /* ----------------------------------------------------------- */
  /* SafeLease metadata                                          */
  /* ----------------------------------------------------------- */
  const relationshipId = useReportsStore((state) => state.relationshipId);
  const locationIds = useReportsStore((state) => state.locationIds);

  /* ----------------------------------------------------------- */
  /* General Units state                                         */
  /* ----------------------------------------------------------- */
  const safeLeaseUnits = useReportsStore((state) => state.safeLeaseUnits);
  const nonSafeLeaseUnits = useReportsStore((state) => state.nonSafeLeaseUnits);

  const invalidPolicyNumberUnitBucket = useReportsStore(
    (state) => state.invalidPolicyNumberUnitBucket
  ).filter((generalUnit) => selectedLocationsInReport.includes(Number(generalUnit.locationId)));

  const invalidExpirationDateUnitBucket = useReportsStore(
    (state) => state.invalidExpirationDateUnitBucket
  ).filter((generalUnit) => selectedLocationsInReport.includes(Number(generalUnit.locationId)));

  const invalidDeclarationPageUnitBucket = useReportsStore(
    (state) => state.invalidDeclarationPageBucket
  ).filter((generalUnit) => selectedLocationsInReport.includes(Number(generalUnit.locationId)));

  const unknownDeclarationPageUnitBucket = useReportsStore(
    (state) => state.unknownDeclarationPageUnitBucket
  ).filter((generalUnit) => selectedLocationsInReport.includes(Number(generalUnit.locationId)));

  const humanReviewUnitBucket = useReportsStore(
    (state) => state.humanReviewBucket
  ).filter((generalUnit) => selectedLocationsInReport.includes(Number(generalUnit.locationId)));

  const acceptedUnitPrivatePolicyBucket = useReportsStore(
    (state) => state.acceptedUnitBucket
  ).filter((generalUnit) => selectedLocationsInReport.includes(Number(generalUnit.locationId)));

  /* Filter out any units that the user has deselected a location for */
  const safeLeaseUnitsFromSelectedLocations = Object.entries(
    safeLeaseUnits
  ).reduce((acc, [locationId, units]) => {
    if (selectedLocationsInReport.includes(parseInt(locationId))) {
      acc = acc.concat(units);
    }
    return acc;
  }, []);

  /* Filter out any units that the user has deselected a location for */
  const nonSafeLeaseUnitsFromSelectedLocations = Object.entries(
    nonSafeLeaseUnits
  ).reduce((acc, [locationId, units]) => {
    if (selectedLocationsInReport.includes(parseInt(locationId))) {
      acc = acc.concat(units);
    }
    return acc;
  }, []);

  /* Use the first location in the report to get the premium */
  const locationQuery = useQuery(getLocation, {
    variables: { locationId: locationIds[0] },
    skip: locationIds.length === 0,
  });

  const [csmModalOpen, setCsmModalOpen] = useState<boolean>(false);

  const relationshipQuery: QueryResult<{ getRelationship: Relationship }> =
    useQuery(getRelationship, {
      variables: {
        id: relationshipId,
      },
      skip: !relationshipId,
    });

  /* general units with negative private policy statuses */
  const generalUnitsWithNegativePrivatePolicyStatuses =
    invalidPolicyNumberUnitBucket.length +
    invalidDeclarationPageUnitBucket.length +
    invalidExpirationDateUnitBucket.length +
    unknownDeclarationPageUnitBucket.length +
    humanReviewUnitBucket.length;

  const missedRevenue =
    generalUnitsWithNegativePrivatePolicyStatuses *
    (locationQuery.data?.getLocation?.protectionLevelValues?.[0]?.premium || 0);


  const calendlyLinks = relationshipQuery?.data?.getRelationship?.am?.calendlyLink ??
    relationshipQuery.data?.getRelationship?.csm?.calendlyLink ?? {};

  const calendlylink =
    calendlyLinks["privatePolicy"] ??
    "https://calendly.com/gaalexander/safelease-i-private-policy-compliance";

  /* Mixpanel / Calendly events */
  useCalendlyEventListener({
    onDateAndTimeSelected: () =>
      mixpanelEventHandler("Private Policy - Calendly Date Selected", {
        csmId: relationshipQuery?.data?.getRelationship?.csm?.id,
        amId: relationshipQuery?.data?.getRelationship?.am?.id,
        userId: auth.user.id,
        relationshipId,
      }),
    onEventTypeViewed: () =>
      mixpanelEventHandler("Private Policy - Calendly Event Type Viewed", {
        csmId: relationshipQuery?.data?.getRelationship?.csm?.id,
        amId: relationshipQuery?.data?.getRelationship?.am?.id,
        userId: auth.user.id,
        relationshipId,
      }),
    onEventScheduled: (e) =>
      mixpanelEventHandler("Private Policy - Calendly Event Scheduled", {
        csmId: relationshipQuery?.data?.getRelationship?.csm?.id,
        amId: relationshipQuery?.data?.getRelationship?.am?.id,
        userId: auth.user.id,
        relationshipId,
      }),
  });

  return (
    <Paper
      elevation={0}
      sx={{
        p: 2,
        border: "1px solid #EBEFF7",
        boxShadow: "0 0 20px rgba(0, 0, 0, 0.05)",
        overflow: "hidden",
        borderRadius: "8px",
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <MetricHighlightCard
            label="Untapped Revenue"
            secondaryText="Missed monthly revenue from non-SafeLease policies"
            chipLabel="Revenue opportunity"
            value={missedRevenue}
            valueFormatter={penniesToDollarsMonthly}
            action={
              <Button
                sx={{
                  ...navyButton,
                  width: 240,
                  display: "flex",
                  justifyContent: "space-between",
                  px: 2,
                }}
                endIcon={<ArrowForward />}
                id="primary-cta-2"
                onClick={() => {
                  mixpanelEventHandler("Private Policy - Primary CTA 2", {
                    user: auth.user.email,
                    relationshipId,
                  });
                  setCsmModalOpen(true);
                }}
              >
                Capture opportunity
              </Button>
            }
            variant="success"
          />
        </Grid>
        <Grid item xs={6}>
          <MetricHighlightCard
            label="Unverified and Non-compliant Private Policies"
            secondaryText="Private policies that are potentially invalid"
            chipLabel={
              generalUnitsWithNegativePrivatePolicyStatuses > 0
                ? "Fraud alert"
                : undefined
            }
            value={generalUnitsWithNegativePrivatePolicyStatuses}
            action={
              generalUnitsWithNegativePrivatePolicyStatuses > 0 && (
                <Button
                  sx={{
                    ...ghostButtonNavy,
                    minWidth: 200,
                    fontWeight: "bold",
                    backgroundColor: "transparent",
                    px: 2,
                    justifyContent: "space-between",
                  }}
                  endIcon={<ArrowForward />}
                  id="primary-cta-3"
                  onClick={() => {
                    mixpanelEventHandler("Private Policy - Primary CTA 3", {
                      user: auth.user.email,
                      relationshipId,
                    });
                    setCsmModalOpen(true);
                  }}
                >
                  Contact Your Rep
                </Button>
              )
            }
            variant={
              generalUnitsWithNegativePrivatePolicyStatuses > 0
                ? "error"
                : "neutral"
            }
            valueFormatter={Formatters.formatWithCommas}
            tooltipText={
              <Box sx={{ p: 2 }}>
                <Typography variant="body1" sx={{ fontWeight: "bold", mb: 2 }}>
                  These policies may be invalid due to one or more of the
                  following reasons:
                </Typography>
                <Typography variant="body2">
                  <ul>
                    <li>Private policy number is invalid.</li>
                    <li>Expiration date is invalid.</li>
                    <li>Declaration page contains invalid information.</li>
                    <li>
                      Private policy uploaded does not cover self-storage units.
                    </li>
                  </ul>
                </Typography>
              </Box>
            }
          />
        </Grid>
        <Grid item xs={2}>
          <StatisticCard
            variant="micro"
            value={invalidPolicyNumberUnitBucket.length}
            label="Number of Invalid Policy Numbers"
            formatter={Formatters.formatWithCommas}
            tooltipText="SafeLease has determined these policies have an invalid policy number."
          />
        </Grid>
        <Grid item xs={2}>
          <StatisticCard
            variant="micro"
            value={invalidExpirationDateUnitBucket.length}
            label="Number of Invalid Expiration Dates"
            formatter={Formatters.formatWithCommas}
            tooltipText="SafeLease has determined these policies have an invalid expiration date."
          />
        </Grid>
        <Grid item xs={2}>
          <StatisticCard
            variant="micro"
            value={invalidDeclarationPageUnitBucket.length}
            label="Number of Invalid Declaration Pages"
            formatter={Formatters.formatWithCommas}
            tooltipText="SafeLease has determined these policies have an invalid declaration page."
          />
        </Grid>
        <Grid item xs={2}>
          <StatisticCard
            variant="micro"
            value={unknownDeclarationPageUnitBucket.length}
            label="Number of Declaration Pages Not Uploaded"
            formatter={Formatters.formatWithCommas}
            tooltipText="SafeLease is unable to verify these declaration pages, as they have not been uploaded by tenants to privatepolicy.com."
          />
        </Grid>
        <Grid item xs={2}>
          <StatisticCard
            variant="micro"
            value={humanReviewUnitBucket.length}
            label="Number of Private Policies Currently Under Review"
            formatter={Formatters.formatWithCommas}
            tooltipText="SafeLease is in the process of reviewing these policies."
          />
        </Grid>
        <Grid item xs={2}>
          <StatisticCard
            variant="micro"
            value={acceptedUnitPrivatePolicyBucket.length}
            label="Number of Valid Policies"
            formatter={Formatters.formatWithCommas}
            tooltipText="SafeLease has determined that these private policies are legitimate and no risk to your business."
          />
        </Grid>
      </Grid>
    </Paper>
  );
}

// Formats 1000 to "$10.00 / motnth" with varying font sizes
function penniesToDollarsMonthly(
  pennies?: number | null,
  options: { digits: number } = { digits: null }
): React.ReactElement {
  if (pennies === null || pennies === undefined) {
    return <>''</>;
  }

  var digits = 2;
  if (options.digits !== null) {
    digits = options.digits;
  }

  var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  });

  /* Separate the pennies and dollars */
  const [fmtDollars, fmtPennies] = formatter.format(pennies / 100).split(".");

  /* Render the pennies and /month in a smaller fontsize */
  return (
    <>
      <span>{fmtDollars}</span>
      <span style={{ fontSize: "20px" }}>.{fmtPennies}/month</span>
    </>
  );
}
