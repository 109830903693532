import { useQuery } from '@apollo/client';
import { getNotificationSettings } from '../../queries';
import UserDataService from '../../services/user.service';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { SafeleaseDropdown } from '../../common';
import { mixpanelEventHandler } from '../../utilities/reactMixpanelHandler';
import Loader from '../../shared/Loader';
import Error from '../../shared/Error';

export default function Notifications(props) {
  const { data, loading, error, refetch } = useQuery(getNotificationSettings);

 const updateSettingKeyNames = {
  summary: 'summary',
  summaryFrequency: 'summaryFrequency',
  claim: 'claim',
 } 

  const handleUpdateSettings = async (value: boolean | string, key: string) => {
    const getMixpanelEventName = (key) => {
      if (key === updateSettingKeyNames.summary) return 'Update Setting - Change Report Email Setting';
      if (key === updateSettingKeyNames.summaryFrequency) return 'Update Setting - Change Report Email Frequency';
      if (key === updateSettingKeyNames.claim) return 'Update Setting - Change Claim Email Setting'; 
    }
    mixpanelEventHandler(getMixpanelEventName(key)); 
    await UserDataService.updateEmailSettings({ [key]: value });
    refetch();
  };

  if (error) return <Error />;
  if (loading) return <Loader />;

  return (
    <div className="settings">
      <FormGroup>
        <FormControlLabel
          label="Report Emails"
          control={
            <Switch
              checked={data.getNotificationSettings.emailSettings.summary}
              onChange={(e) => handleUpdateSettings(e.target.checked, updateSettingKeyNames.summary)}
            />
          }
        />
        {data.getNotificationSettings.emailSettings.summary && (
          <div className="tw-ml-2 tw-mt-2">
            <SafeleaseDropdown
              label="Report Frequency"
              defaultValue={
                data.getNotificationSettings.emailSettings.summaryFrequency || 'monthly'
              }
              menuOptionList={['monthly', 'weekly']}
              onChange={(e) => handleUpdateSettings(e.target.value, updateSettingKeyNames.summaryFrequency)}
            />
          </div>
        )}
        <FormControlLabel
          label="Claim Emails"
          control={
            <Switch
              checked={data.getNotificationSettings?.emailSettings.claim}
              onChange={(e) => handleUpdateSettings(e.target.checked, updateSettingKeyNames.claim)}
            />
          }
        />
      </FormGroup>
    </div>
  );
}
