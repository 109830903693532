import _ from 'lodash';
import { enUS } from 'date-fns/locale';

// Shared functions for parsing data returned by the getMetrics endpoint
class MetricsHelpers {
  static getLastValue(key: string, data: any[]) {
    var summarized = {};
    for(var metric of data) {
      if(key != metric.key) continue;

      if (!summarized[metric.date]) summarized[metric.date] = 0;
      summarized[metric.date] += metric.value;
    }
    return Object.values(summarized).pop();
  }

  static getSummaryByKey(
      data: any[], 
      key: string, 
      currentMonth: any): SummarizedMetrics
  {
    let summarized = {};
    let lastMetricBeforeMonth = null;

    for(let metric of data || []) {
      if(metric.key !== key) continue;
  
      if (!summarized[metric.date]) summarized[metric.date] = 0;
      summarized[metric.date] += metric.value;
  
    }

    let lastMetric = null;
    for(var [key, value] of Object.entries(summarized)) {
      var metric = {value: value, date: new Date(key)};
      if(Date.parse(key) < currentMonth.getTime()) {
        lastMetricBeforeMonth = metric;
      }
      lastMetric = metric;
    }
    return { summarized, lastMetric, lastMetricBeforeMonth };
  }

  static getChartOptions(data: any, options: any = {}) {
    var dates = [];
    for(var dataset of data.datasets) {
      for(var point of dataset.data) {
        dates.push(point.x);
      }
    }
    dates = _.uniq(dates);
    dates.sort(); // We can just sort by string since these are ISO format, no need to parse.

    // Adjust padding on left and right of chart.
    var firstDay = new Date(dates[0]);
    var lastDay = new Date(dates[dates.length - 1]);
    if(dates.length == 1) {
      firstDay.setDate(firstDay.getDate() - 5); // A single point should be toward the left, but not all the way on the left.
      lastDay.setDate(lastDay.getDate() + 20);
    } else {
      firstDay.setDate(firstDay.getDate() - 5);
      lastDay.setDate(lastDay.getDate() + 5);
    }

    var retval = {
      responsive: true,
      plugins: {
        legend: {
          position: 'bottom',
          labels: {
            usePointStyle: true,
            boxWidth: 6,
          }
        },
        tooltip: {
          enabled: true,
          displayColors: false,
          callbacks: {
            title: function() {
              return null;
            },
            label: function(item) {
              var value = item.raw.y;
              if(options.yFormat) value = options.yFormat(value);
              return value;
            },
          }
        },
      },
      scales: {
        x: {
          adapters: {
            date: {
              locale: enUS,
            },
          },
          type: 'time',
          time: {
            unit: 'month',
              displayFormats: {
                month: 'MMM'
            }
          },
          ticks: {
            source: 'data',
          },
          min: firstDay.toISOString().split('T')[0],
          max: lastDay.toISOString().split('T')[0],
        },
        y: {
          min: 0,
          ticks: null
        },
      },
      segment: null
    };

    if(options.yFormat) {
      retval.scales.y.ticks = {callback: options.yFormat};
    }

    if (options.segment) {
      retval.segment = options.segment
    }

    if (options.yMax) {
      retval.scales.y['max'] = options.yMax;
    }
    
    return retval;
  }
}

export interface DataPoint {
  x: Date | string,
  y: number
}

export interface SummarizedMetrics {
  summarized: {
    [index: string]: number
  }, 
  lastMetric: {
    value: number, 
    date: string
  }, 
  lastMetricBeforeMonth: {
    value: number, 
    date: string
  }
}

export { MetricsHelpers };
