import { forwardRef } from 'react';
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import LearnAboutRolesTooltip from "../LearnAboutRolesTooltip";
import { useAuth } from '../../../../auth';
import { Roles } from '../../../../utilities/field-enums';
import { capitalize } from 'lodash';

enum Role {
  Manager = "manager",
  Employee = "employee",
}


export const RoleSelect: React.FC<any> = forwardRef(({
  currentlyEditedUser,
  disabled,
  value,
  onChange,
  ...rest
}, ref) => {
  
  const auth = useAuth();
  const isEditingAdmin = currentlyEditedUser.role === "admin" || currentlyEditedUser.role === "superAdmin";
  const hasRoleEditingPermission = auth.user?.role !== Roles.Employee;
  const menuOptionsList = isEditingAdmin ? ["admin"] : Object.values(Role);
  
  const defaultValue = () => {
    if (!currentlyEditedUser) {
      return [Role.Employee];
    } else if (currentlyEditedUser && !isEditingAdmin) {
      return [currentlyEditedUser?.role || Role.Employee];
    } else if (currentlyEditedUser && isEditingAdmin) {
      return ["admin"];
    }
  };
  
  return (
    <FormControl sx={{ width: '100%' }} disabled={disabled || isEditingAdmin}>
      <InputLabel>Role</InputLabel>
      <Select
        ref={ref}
        label="Role"
        defaultValue={defaultValue()}
        value={
          currentlyEditedUser?.role === "superAdmin" || currentlyEditedUser?.role === "admin"
            ? ["admin"]
            : value
            ? value
            : []
        }
        sx={{ width: "100%", fontSize: 14, height: 56 }} // select and textfield have different default heights, so we need to set it here
        endAdornment={<LearnAboutRolesTooltip />}
        disabled={disabled || isEditingAdmin || !hasRoleEditingPermission} // always disabled when editing admins, and always disabled when an employee is editing 
        onChange={(event) => onChange(event.target.value)}
        renderValue={(value: string) => capitalize(value)}
        {...rest}
      >
        {menuOptionsList.map((option) => (
          <MenuItem key={option} value={option}>
            {capitalize(option)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
})

export default RoleSelect;
