import { useQuery } from '@apollo/client';
import { getRelationship } from '../../queries';
import Loader from '../../shared/Loader';
import Error from '../../shared/Error';
import { useAuth } from '../../auth';

export default function Contact() {
  const auth = useAuth();

  const relationshipQuery = useQuery(getRelationship, { variables: { id: auth.user.relationshipId } });

  if (relationshipQuery.loading) return <Loader />;
  if (relationshipQuery.error) return <Error />;

  const csmName = relationshipQuery.data.getRelationship?.csm;
  let csmEmail;
  if (csmName === 'Claudia Miller') csmEmail = 'cmiller@safelease.com';
  else if (csmName === 'Shawna Douglas') csmEmail = 'sdouglas@safelease.com';

  return (
    <div>
      {csmEmail ? (
        <p>
          Customer Success ({csmName}):<br />
          {
            <a href={`mailto:${csmEmail}`} target="_blank" rel="noreferrer">
              {csmEmail}
            </a>
          }
        </p>
      ) : null}
      <p>
        <strong>Support:</strong><br />
        <a href="mailto:support@safelease.com" target="_blank" rel="noreferrer">
          support@safelease.com
        </a><br/>
        <a>
          (512) 767-1408
        </a>
      </p>
    </div>
  );
}
