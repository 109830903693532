import React, { useState, useEffect } from "react";
import { styled, alpha } from "@mui/material/styles";
import { SimpleTreeView } from "@mui/x-tree-view/SimpleTreeView";
import { TreeItem, treeItemClasses } from "@mui/x-tree-view/TreeItem";
import { useOrganizationSettings } from "./useOrganizationSettingsStore";
import LocationRow from "./LocationRow";
import LlcRow from "./LlcRow";
import Box from "@mui/material/Box";
import { UseQueryResult } from "@tanstack/react-query";

export const CustomTreeItem = styled(TreeItem)(({ theme }) => ({
  color: theme.palette.mode === "light" ? theme.palette.grey[800] : theme.palette.grey[200],

  [`& .${treeItemClasses.content}`]: {
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(0.5, 1),
    margin: theme.spacing(0.2, 0),
    [`& .${treeItemClasses.label}`]: {
      fontSize: "0.8rem",
      fontWeight: 500,
    },
  },
  [`& .${treeItemClasses.iconContainer}`]: {
    borderRadius: "50%",
    backgroundColor: theme.palette.mode === "light" ? alpha(theme.palette.primary.main, 0.25) : theme.palette.primary.dark,
    color: theme.palette.mode === "dark" && theme.palette.primary.contrastText,
    padding: theme.spacing(0, 1.2),
  },
  [`& .${treeItemClasses.groupTransition}`]: {
    marginLeft: 15,
    paddingLeft: 18,
    borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
  },
}));

interface OrganizationTreeProps {
  setLocationSettingsModalOpen: (open: boolean) => void;
  setSelectedLocationId: (locationId: number) => void;
  fetchOrgSettingsResult: UseQueryResult;
}

const OrganizationTree: React.FC<OrganizationTreeProps> = ({
  setLocationSettingsModalOpen,
  setSelectedLocationId,
  fetchOrgSettingsResult,
}) => {
  const { llcs } = useOrganizationSettings();
  const [expanded, setExpanded] = useState([]);

  useEffect(() => {
    setExpanded(buildDefaultExpandedItems(llcs));
  }, [llcs]);

  const buildDefaultExpandedItems = (llcs): string[] => {
    const defaultExpandedItems = ["grid"];
    llcs.forEach((llc, index) => {
      defaultExpandedItems.push(`${llc.name}${index}`);
      for (const location of llc.locations) {
        defaultExpandedItems.push(`${location.id}`);
      }
    });

    return defaultExpandedItems;
  };

  return (
    <Box sx={{ minHeight: 352, minWidth: 250 }}>
      <SimpleTreeView expandedItems={expanded}>
        {llcs.map((llc, index) => {
          return (
            <CustomTreeItem key={index} itemId={`${llc.name}${index}`}>
              <LlcRow llc={llc} fetchOrgSettingsResult={fetchOrgSettingsResult} />
              {llc.locations.map((location) => {
                return (
                  <CustomTreeItem key={location.id} itemId={`${location.id}`}>
                    <LocationRow
                      location={location}
                      fms={location.fms}
                      fetchOrgSettingsResult={fetchOrgSettingsResult}
                      setLocationSettingsModalOpen={setLocationSettingsModalOpen}
                      setSelectedLocationId={setSelectedLocationId}
                    />
                  </CustomTreeItem>
                );
              })}
            </CustomTreeItem>
          );
        })}
      </SimpleTreeView>
    </Box>
  );
};

export default OrganizationTree;
