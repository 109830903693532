import { useEffect, useState } from "react";

// UI - libs
import { Alert, Divider, Grid, Typography } from "@mui/material";

// UI - internal
import { SafeleaseFormTextField } from "../../../../common";
import RoleSelect from "./RoleSelect";
import DeleteUserDialog from "./DeleteUserDialog";
import UserPermissionsChecklist from "../UserPermissionsChecklist";
import DrawerFooter, {
  DrawerType,
  EditMode,
} from "../../../../shared/drawer-footer";

// Data
import { Location, User } from "../../../../utilities/generated/gql-types";
import UserDataService from "../../../../services/user.service";
import CheckboxSelection from "../../CheckboxSelection";
import { useAuth } from "../../../../auth";

// Form
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { mixpanelEventHandler } from "../../../../utilities/reactMixpanelHandler";
import { useUserManagementSettings } from "../useUserManagementSettings";
import { getPermission, getUsers } from "../../../../queries";
import client from "../../../../utilities/apolloClient";
import { WithRequiredProperty } from "../../../../utilities/required-property-modifier";
import { useSnackbar } from "notistack";
import { Roles } from "../../../../utilities/field-enums";
import { useQuery } from "@apollo/client";
import AccessControlled from "../../../../components/AccessControlled";

enum Role {
  Manager = "manager",
  Employee = "employee",
}

type UserSettingsFormValues = {
  userName: string;
  userEmail: string;
  userPhone: string;
  role: Role | string;
  pendingLocations: Location[];
  permissions: {
    revenue: boolean;
    billing: boolean;
  };
};

const validationSchema = z.object({
  userName: z.string().nonempty("Name is required."),
  userEmail: z.string().nonempty("Email is required.").email(),
  userPhone: z.union([
    z.string().length(0, "Phone number must be valid or empty."),
    z
      .string()
      .regex(
        /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
        "Phone number must be valid or empty."
      ),
  ]),
  role: z.string().nonempty("Role is required."),
  pendingLocations: z
    .array(z.unknown())
    .min(1, "At least one location must be selected."),
  permissions: z.object({
    revenue: z.boolean(),
    billing: z.boolean(),
  }),
});

const defaultValues = ({ currentlyEditedUser, locations }) => ({
  userName: currentlyEditedUser?.name || "",
  userEmail: currentlyEditedUser?.email || "",
  userPhone: currentlyEditedUser?.phone || "",
  role: currentlyEditedUser?.role || Role.Employee,
  pendingLocations:
    currentlyEditedUser?.locationIds
      ?.map((locationId: number) =>
        locations.find((location) => Number(location.id) == locationId)
      )
      .filter((location) => !!location) || [],
  permissions: {
    revenue:
      currentlyEditedUser?.permissions?.revenue !== undefined
        ? currentlyEditedUser?.permissions.revenue
        : true,
    billing:
      currentlyEditedUser?.permissions?.billing !== undefined
        ? currentlyEditedUser?.permissions.billing
        : false,
  },
});

interface UserSettingsDrawerProps {
  currentlyEditedUser: User | null;
  locations: Location[];
  relationshipId: number;
}

export default function EditUserForm({
  currentlyEditedUser,
  locations,
  relationshipId,
}: UserSettingsDrawerProps) {
  const auth = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const { editDrawerOpenMode, closeDrawer, loading, setLoading } =
    useUserManagementSettings();

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<string | null>(null);

  const isNewUser = !currentlyEditedUser;
  const isAdmin = currentlyEditedUser.isAdmin;

  const { handleSubmit, control, formState, watch, getValues, reset } =
    useForm<UserSettingsFormValues>({
      defaultValues: defaultValues({ currentlyEditedUser, locations }),
      resolver: zodResolver(validationSchema),
    });

  const getPermissionsQuery = useQuery(getPermission, {
    variables: {
      objectType: "User",
      objectId: currentlyEditedUser?.id,
      permission: "update",
    },
  });

  // Run a reset of the form state after getPermisionsQuery finishes
  // this prevents a nulled-out form state that's a side-effect
  // of RHF not hanging onto values through the render lifecycle
  useEffect(() => {
    reset(defaultValues({ currentlyEditedUser, locations }));
  }, [getPermissionsQuery.data]);

  const canEditUser = getPermissionsQuery.data?.getPermission?.allowed || false;

  const handleSave: SubmitHandler<UserSettingsFormValues> = async (data) => {
    setEmailError(null);
    setLoading(true);

    const pendingUser: Partial<User> | { previousEmail: string } = {
      id: currentlyEditedUser?.id,
      name: data.userName,
      email: data.userEmail,
      previousEmail: currentlyEditedUser?.email,
      phone: data.userPhone,
      role: data.role,
      locationIds: data.pendingLocations.map((location) => Number(location.id)),
      relationshipId,
      permissions:
        data.role !== "employee"
          ? { revenue: true, billing: true }
          : data.permissions,
    };

    const response = await UserDataService.edit(
      pendingUser as WithRequiredProperty<User, "relationshipId">
    );

    if (Object.values(response?.data?.errors || {}).length > 0) {
      enqueueSnackbar("Error updating user.", { variant: "error" });
    }

    if (response.data?.errors?.email) {
      setEmailError(response.data.errors.email.msg);
      setLoading(false);
      return;
    }
    await client.refetchQueries({ include: [getUsers] });
    enqueueSnackbar(
      <Typography>
        Successfuly updated user <b>{pendingUser.name}</b>.
      </Typography>,
      { variant: "success" }
    );

    setLoading(false);
    closeDrawer();
    mixpanelEventHandler("User Settings - Updated User");
  };

  // "Workaround" (intended behavior?) for MUI select + react-hook-form controller
  const handleSelectChange = (incomingLocation: Location) => {
    const currentState = getValues().pendingLocations;
    if (
      currentState.find(
        (location: Location) => location.id === incomingLocation.id
      )
    ) {
      return currentState.filter(
        (location: Location) => location.id !== incomingLocation.id
      );
    } else {
      return [...currentState, incomingLocation];
    }
  };

  return (
    <AccessControlled
      type="User"
      id={Number(currentlyEditedUser?.id)}
      permission="update"
      showError
    >
      <form onSubmit={handleSubmit(handleSave)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5" sx={{ mb: 2 }}>
              {editDrawerOpenMode === EditMode.Add
                ? "Add New User"
                : "Edit User"}
            </Typography>
          </Grid>
          {emailError && (
            <Grid item xs={12}>
              <Alert severity="error">{emailError}</Alert>
            </Grid>
          )}
          <Grid item xs={12} md={6}>
            <Controller
              name="userName"
              control={control}
              rules={{ required: true }}
              disabled={!canEditUser || loading}
              render={({ field }) => (
                <SafeleaseFormTextField
                  helperText={formState.errors.userName?.message}
                  error={Boolean(formState.errors.userName?.message)}
                  sx={{ width: "100%" }}
                  label="Name"
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="userEmail"
              control={control}
              rules={{ required: true }}
              disabled={!canEditUser || loading}
              render={({ field }) => (
                <SafeleaseFormTextField
                  helperText={formState.errors.userEmail?.message}
                  error={Boolean(formState.errors.userEmail?.message)}
                  sx={{ width: "100%" }}
                  label="Email"
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="userPhone"
              control={control}
              rules={{ required: false }}
              disabled={!canEditUser || loading}
              render={({ field }) => (
                <SafeleaseFormTextField
                  disabled={loading}
                  helperText={formState.errors.userPhone?.message}
                  error={Boolean(formState.errors.userPhone?.message)}
                  sx={{ width: "100%" }}
                  label="Phone"
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="role"
              control={control}
              rules={{ required: true }}
              disabled={!canEditUser || loading}
              render={({ field: { value, ...rest } }) => (
                <RoleSelect
                  currentlyEditedUser={watch() || currentlyEditedUser}
                  value={isAdmin ? ["admin"] : value}
                  {...rest}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Divider sx={{ mb: 2 }} />
          </Grid>
          <Grid item xs={12}>
            <Typography sx={{ mb: 2 }}>Assigned location(s)</Typography>
            <Controller
              name="pendingLocations"
              control={control}
              rules={{ required: true }}
              disabled={!canEditUser || loading}
              defaultValue={[]}
              render={({ field: { value, onChange, disabled }, ...rest }) => (
                <CheckboxSelection<Location>
                  sx={{ width: "100%" }}
                  options={locations || []}
                  disabled={disabled}
                  displayEmpty={true}
                  renderValue={(selected: Location[]) => {
                    if (currentlyEditedUser?.isAdmin)
                      return `${locations.length} Locations Selected`; // admins are associated with all locations
                    if (selected.length > 1)
                      return `${selected.length} Locations Selected`;
                    if (selected.length === 0) return "Select Locations";
                    if (selected.length === 1) return selected[0].address;
                  }}
                  // Disable and check all for admins
                  disableOptions={currentlyEditedUser?.isAdmin}
                  checkOptions={currentlyEditedUser?.isAdmin}
                  value={value}
                  onChange={(event, newValue) => {
                    // Generate a new set of values that are +/- the selected location
                    const newSet = handleSelectChange(
                      // Workaround since the entire new value is returned (multiple item array) and we just want the new one
                      newValue.props.value as Location
                    );

                    // Trigger the form's onChange manually with the new set of values
                    onChange({
                      target: {
                        value: newSet,
                      },
                    });
                  }}
                  renderText={(option) => option.address}
                  {...rest}
                />
              )}
            />
          </Grid>
          {watch("role") === "employee" &&
            auth.user?.role !== Roles.Employee && (
              <>
                <Grid item xs={12}>
                  <Divider sx={{ mb: 2 }} />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="permissions"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <UserPermissionsChecklist
                        disabled={loading}
                        role={watch("role")}
                        permissions={field.value}
                        setPermissions={field.onChange}
                      />
                    )}
                  />
                </Grid>
              </>
            )}
        </Grid>
        <DrawerFooter
          editMode={isNewUser ? EditMode.Add : EditMode.Edit}
          drawerType={DrawerType.UserSettings}
          setDeleteDialogOpen={setIsDeleteDialogOpen}
        />
        <DeleteUserDialog
          user={currentlyEditedUser}
          open={isDeleteDialogOpen}
          onClose={() => setIsDeleteDialogOpen(false)}
          closeDrawer={closeDrawer}
        />
      </form>
    </AccessControlled>
  );
}
