import React from "react";
import { Typography } from "@mui/material";
import ThemedButton from "../shared/ThemedButton";
import MoveExistingLocationView from "./MoveExistingLocationView";
import ConnectNewFmsView from "./ConnectNewFmsView";
import { useOrganizationSettings } from "../../useOrganizationSettingsStore";
import { DialogView } from "../DialogViews";

interface AddLocationProps {}

const AddLocation: React.FC<AddLocationProps> = () => {
  const { dialogView: view, setDialogView } = useOrganizationSettings();

  const handleViewChangeClick = (view) => {
    setDialogView(view);
  };

  return (
    <>
      {view === DialogView.ADD_LOCATION_DEFAULT && (
        <div>
          <Typography
            sx={{
              fontColor: "#031E30",
              fontWeight: 700,
              fontSize: "14px",
              lineHeight: "19px",
              fontFamily: "Open Sans",
            }}
            gutterBottom
          >
            How it works
          </Typography>
          <Typography
            sx={{
              fontColor: "#929292",
              fontSize: "12px",
              fontWeight: 500,
              lineHeight: "16px",
              fontFamily: "Open Sans",
              opacity: "0.7",
              mt: 1.5,
            }}
            gutterBottom
          >
            SafeLease enables streamlined bill payments via Plaid, a secure third-party software that connects financial information to our
            database. Follow the instructions below to connect and verify your banking details.
          </Typography>
          <ThemedButton
            dark
            onClick={() => handleViewChangeClick(DialogView.MOVE_EXISTING)}
            styles={{
              marginTop: "15px",
            }}
          >
            Move existing location here
          </ThemedButton>
          <ThemedButton
            onClick={() => console.log("CLICKED")} // TODO: Implement move existing location
            styles={{
              marginTop: "15px",
            }}
          >
            Add from existing FMS connection
          </ThemedButton>
          <ThemedButton
            onClick={() => handleViewChangeClick(DialogView.NEW_FMS)}
            styles={{
              marginTop: "15px",
            }}
          >
            Connect new FMS
          </ThemedButton>
        </div>
      )}
      {view === DialogView.MOVE_EXISTING && (
        <MoveExistingLocationView handleGoBackClick={() => setDialogView(DialogView.ADD_LOCATION_DEFAULT)} />
      )}
      {view === DialogView.NEW_FMS && <ConnectNewFmsView handleGoBackClick={() => setDialogView(DialogView.ADD_LOCATION_DEFAULT)} />}
    </>
  );
};

export default AddLocation;
