import React from "react";
import { Avatar } from "@mui/material";

interface MenuItemAvatarProps {
  menuItemObject: {
    label: string; 
    value: number;
  }
}

const MenuItemAvatar: React.FC<MenuItemAvatarProps> = ({ menuItemObject }) => {
  const { label } = menuItemObject;
  const initials =
    label
      ?.split(" ")
      ?.map((name: string) => name[0])
      .join("")
      .toUpperCase() || "NA";

  return (
    <Avatar
      sx={{
        height: "24px",
        width: "24px",
        marginRight: "10px",
      }}
    >
      <span style={{ fontSize: "14px" }}>{initials}</span>
    </Avatar>
  );
};

export default MenuItemAvatar;