import { Stack, Typography } from "@mui/material";
import React from "react";

const ModelValueRenderer = ({ value, sx }) => {

  if (!value && typeof value !== "boolean") {
    return <Typography sx={sx}>N/A</Typography>;
  } else if (typeof value === "string" || typeof value === "number") {
    return <Typography sx={sx}>{value}</Typography>;
  } else if (typeof value === "boolean") {
    return <Typography sx={sx}>{JSON.stringify(value)}</Typography>;
  } else if (typeof value === "object") { // protection plan values are the only object type so far. May need to be changed in the future if other objects are added
    return (
      <Stack>
        {value.map((protectionPlanValueObj) => {
          return <Stack key={protectionPlanValueObj.premium} direction="row" spacing={2}>
            {Object.keys(protectionPlanValueObj).map((key) => {
              return (
                <Stack key={key} spacing={2} direction='row' sx={{ border: "1px black" }}>
                  <Typography sx={sx}>{key}:</Typography>
                  <Typography sx={sx}>{`$${protectionPlanValueObj[key] / 100}`}</Typography>
                </Stack>
              );
            })}
          </Stack>;
        })}
      </Stack>
    );
  }
};

export default ModelValueRenderer;
