import { create } from "zustand";
import { persist } from "zustand/middleware";
import { GridFilterModel, GridRowSelectionModel } from "@mui/x-data-grid-premium";
import { Invoice } from "../../utilities/generated/gql-types";

interface AdminBillingState {
  // Manage visible columns
  visibleColumns: {
    [key: string]: boolean;
  };
  updateVisibleColumns: (model: { [key: string]: boolean }) => void;

  // Manage selected rows
  selectedInvoiceIds: GridRowSelectionModel;
  updateSelectedInvoiceIds: (model: GridRowSelectionModel) => void;

  //Manage filters
  filterModel: GridFilterModel;
  updateFilterModel: (model: GridFilterModel) => void;

  //Manage the state of the drawer
  selectedInvoiceForDrawer: Invoice; // TODO: type this
  setSelectedInvoiceForDrawer: (invoice: Invoice) => void;
}

export const useAdminBillingStore = create<AdminBillingState>()(
  persist(
    (set, get) => ({
      // Manage which columns are visible
      visibleColumns: {
        billingEntityName: true,
        invoiceLink: true,
        confirmed: true,
        attachmentId: true,
        emailSent: true,
        status: true,
        balance: false,
      },
      updateVisibleColumns: (newModel) => set({ visibleColumns: newModel }),
      // Manage selected rows
      selectedInvoiceIds: [],
      updateSelectedInvoiceIds: (newModel) => set({ selectedInvoiceIds: newModel }),

      filterModel: {
        items: [],
      },
      updateFilterModel: (newModel) => set({ filterModel: newModel }),
      selectedInvoiceForDrawer: null,
      setSelectedInvoiceForDrawer: (invoice) => set({ selectedInvoiceForDrawer: invoice }),
    }),
    {
      name: "admin-billing-store",
      partialize: (state) => ({
        visibleColumns: state.visibleColumns,
        filterModel: state.filterModel,
      }),
    },
  ),
);
