import { useState } from 'react';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import SetupDataService from './services/setup.service';
import { getAccounts } from './queries';
import { useQuery } from '@apollo/client';
import { fmsData } from './utilities/fms-data';
import { Image } from './image';
import Error from './shared/Error'
import FmsLoginFields from './shared/fms-login-fields';

function UpdateCredentialsModal(props) {
  const { location, onClose } = props;

  const [open, setOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [accountCredentials, setAccountCredentials] = useState({});

  const accountsQuery = useQuery(getAccounts, {
    variables: { relationshipId: location.relationshipId },
    onCompleted: (data) => {
      const account = data.getAccounts.find((account) => account.id == location.accountId);
      if (account?.credentials) {
        setAccountCredentials({ ...account.credentials });
      }
    },
  });

  if (accountsQuery.loading) return <CircularProgress />;
  if (accountsQuery.error) return <Error />;

  const activeAccount = accountsQuery.data.getAccounts.find(
    (account) => account.id == location.accountId
  );
  if (!activeAccount) return <Error />;

  const setFmsCredential = (key, value) => {
    let fmsCredential = { ...accountCredentials };
    fmsCredential[key] = value;
    setAccountCredentials(fmsCredential);
  };

  const saveAccount = async () => {
    const addAccountPayload = { ...accountCredentials };
    addAccountPayload.id = activeAccount.id;
    addAccountPayload.relationshipId = location.relationshipId;
    addAccountPayload.type = activeAccount.type;

    setLoading(true);
    const response = await SetupDataService.addAccount(addAccountPayload);
    setLoading(false);

    if (response.data.errors || response.data.error) {
      setErrors(response.data.errors || response.data.error);
      return;
    }

    setErrors({});
    setOpen(false);
    onClose(response.data);
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={() => onClose()}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        className="popup-modal"
      >
        <Box className="modal-box">
          <div className="modal-logo">
            {activeAccount.type === 'unsupported' ? (
              <h2>Unsupported FMS</h2>
            ) : (
              <>
                <Image src={fmsData[activeAccount.type].image} alt="Fms logo" />
                <h2>{fmsData[activeAccount.type].name}</h2>
              </>
            )}
          </div>
          <div className="modal-location-title">
            <p className="modal-address-text">{location.address}</p>
          </div>
          <div className="modal-fields">
            <FmsLoginFields accountCredentials={accountCredentials} setFmsCredential={setFmsCredential} accountType={activeAccount.type} credentialErrors={errors} />
          </div>

          {loading ? (
            <Box textAlign="center" marginTop="10px">
              <CircularProgress />
            </Box>
          ) : (
            <Box textAlign="center">
              <Button className="modal-login-button" onClick={() => saveAccount()}>
                Update Credentials
              </Button>
            </Box>
          )}
        </Box>
      </Modal>
    </div>
  );
}

export default UpdateCredentialsModal;
