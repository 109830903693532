import RouterHelper from '../utilities/router-helper';
import { NavIcons } from './icons';

export interface NavigationRoute {
  path?: string; // Path should only be optional for routes with children.
  label: string;
  icon?: NavIcons;
  hasNotification?: boolean;
  children?: NavigationRoute[];
}

interface NavigationSection {
  title?: string;
  routes: NavigationRoute[];
  showIf?: boolean;
}

export function getNavigationStructure(
  routerHelper: RouterHelper,
  notifications: Record<string, boolean>,
  userContext: {
    isAdmin: boolean;
    isReputationUser: boolean;
    isTenantCoverageUser: boolean;
  },
): NavigationSection[] {
  const { isAdmin, isReputationUser, isTenantCoverageUser } = userContext;

  return [
    {
      routes: [
        isTenantCoverageUser && {
          label: 'Dashboard',
          path: routerHelper.dashboardRoute(),
          icon: NavIcons.DashboardIcon,
          hasNotification: notifications[routerHelper.dashboardRoute()],
        },
      ].filter(Boolean) as NavigationRoute[],
    },
    {
      title: 'My apps',
      showIf: isReputationUser || isTenantCoverageUser,
      routes: [
        isTenantCoverageUser && {
          label: 'Tenant coverage',
          icon: NavIcons.TenantCoverage,
          children: tenantCoverageRoutes(routerHelper, notifications),
        },
        isReputationUser && reputationRoute(routerHelper),
      ].filter(Boolean) as NavigationRoute[],
    },
    {
      title: 'General',
      routes: generalRoutes(routerHelper, notifications),
    },
    {
      title: 'Explore new apps',
      showIf: !isReputationUser, // We will need to change this once we enable the TC landing page.
      routes: [
        !isReputationUser && reputationRoute(routerHelper),
        // !isTenantCoverageUser && {
        //   path: '/tenant-coverage',
        //   label: 'Tenant coverage',
        //   icon: NavIcons.TenantCoverage
        // } // We are not showing this until the zero state page is finished.
      ].filter(Boolean) as NavigationRoute[],
    },
    {
      title: 'Internal admin tools',
      showIf: isAdmin,
      routes: adminRoutes(),
    },
  ];
}

export function generalRoutes(routerHelper: RouterHelper, notificationsContext: any) {
  return [
    {
      label: 'Knowledge center',
      path: '/knowledge-center',
      icon: NavIcons.KnowledgeCenterIcon,
    },
    {
      label: 'Documents hub',
      path: routerHelper.documentsRoute(),
      icon: NavIcons.DocumentManagerIcon,
      hasNotification: notificationsContext[routerHelper.documentsRoute()],
    },
    {
      label: 'Settings',
      path: '/settings',
      icon: NavIcons.SettingsIcon,
    },
  ];
}

export function tenantCoverageRoutes(
  routerHelper: RouterHelper,
  notificationsContext: any,
): NavigationRoute[] {
  return [
    {
      label: 'Compliance',
      path: routerHelper.complianceRoute(),
      hasNotification: notificationsContext[routerHelper.complianceRoute()],
    },
    {
      label: 'Plan upgrades',
      path: routerHelper.planUpgradesRoute(),
    },
    {
      label: 'Reports',
      path: routerHelper.reportsRoute(),
      hasNotification: notificationsContext[routerHelper.reportsRoute()],
    },
  ];
}

export function adminRoutes(): NavigationRoute[] {
  return [
    {
      label: 'Relationships',
      path: '/admin/relationships',
      icon: NavIcons.RelationshipsIcon,
    },
    {
      label: 'Admin tools',
      path: '/admin/tools',
      icon: NavIcons.AdminToolsIcon,
    },
  ];
}

export function reputationRoute(routerHelper: RouterHelper): NavigationRoute {
  return {
    label: 'Reputation center',
    path: routerHelper.reputationRoute(),
    icon: NavIcons.ReputationIcon,
  };
}
