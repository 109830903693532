export enum DialogView {
  ADD_LOCATION_DEFAULT = "addLocationDefault",
  MOVE_EXISTING = "moveExisting",
  NEW_FMS = "newFms",
  LLC_SETTINGS = "llcSettings",
}

export const DialogViewTitles: { [key in DialogView]: string } = {
  [DialogView.ADD_LOCATION_DEFAULT]: "Add Location",
  [DialogView.MOVE_EXISTING]: "Move Existing",
  [DialogView.NEW_FMS]: "New FMS",
  [DialogView.LLC_SETTINGS]: "LLC Settings",
};

const LocationDialogViews = [DialogView.ADD_LOCATION_DEFAULT, DialogView.MOVE_EXISTING, DialogView.NEW_FMS];

export const isLocationView = (view: DialogView) => {
  return LocationDialogViews.includes(view);
};
