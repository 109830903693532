import { Box } from "@mui/material";
import { PrivatePolicy } from "../../../utilities/generated/gql-types";
import { PrivatePolicyAcceptanceCriteria } from "./PrivatePolicyAcceptanceCriteria";
import { PrivatePolicyMetadata } from "./PrivatePolicyMetadata";
import { PrivatePolicyRejectionCriteria } from "./PrivatePolicyRejectionCriteria";

interface PrivatePolicyDetailsListProps {
  privatePolicy: PrivatePolicy;
}

export function PrivatePolicyDetailsList({
  privatePolicy,
}: PrivatePolicyDetailsListProps) {
  return (
    <Box>
      {privatePolicy.validationStatus === "accepted" && (
        <PrivatePolicyAcceptanceCriteria privatePolicy={privatePolicy} />
      )}
      {privatePolicy.validationStatus === "rejected" && (
        <PrivatePolicyRejectionCriteria privatePolicy={privatePolicy} />
      )}
      <PrivatePolicyMetadata privatePolicy={privatePolicy} />
    </Box>
  );
}
