import { IconButton, styled } from "@mui/material";
import { Box } from "@mui/system";
import MoreVertIcon from "@mui/icons-material/MoreVert";

/** Any styled component which lives in this file is used across two or more files in the organization settings directory **/

// Container for each row (Llc row, location row etc) in the organization settings page
export const OrgRowBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  border: "1px solid #EBEFF7",
  borderRadius: "4px",
  padding: "16px",
  width: "100%",
}));

// Common container used throughout the organization settings page for row layout
export const FlexRowBox = styled(Box)(({ theme }) => ({
  display: "flex",
}));

// Common container used throughout the organization settings page for column layout
export const FlexColumnBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
}));

// Container for the status and action icon for each row (Llc row, location row etc) in the organization settings page
export const FlexStatusActionBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "end",
  alignItems: "center",
  marginRight: "16px",
  flexGrow: 1,
}));

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  padding: 0,
  margin: 0,
  display: "flex",
}));

// End icon for each row (Llc row, location row etc) in the organization settings page that sits to the right of the status icon (FiberIcon)
export const StyledMoreVertIcon = styled(MoreVertIcon)(({ theme }) => ({
  color: "#929292",
  fontSize: "24px",
  marginLeft: "4px",
  cursor: "pointer",
}));
