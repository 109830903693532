import { create } from "zustand";
import { organizationApi } from "../../../utilities/apiInstances/organization-settings-api";
import { RelationshipUser, Llc, RelationshipAccount } from "../../../types/OrganizationSettingsTypes";
import { DialogView } from "./OrganizationDialog/DialogViews";

interface OrganizationSettingsState {
  llcs: Llc[];
  setLlcs: (llcs: Llc[]) => void;

  selectedLlc: Llc | null;
  setSelectedLlc: (llc: Llc) => void;

  relationshipUsers: RelationshipUser[];
  setRelationshipUsers: (relationshipUsers: RelationshipUser[]) => void;

  accounts: RelationshipAccount[];
  setAccounts: (accounts: RelationshipAccount[]) => void;

  loading: boolean;
  setLoading: (loading: boolean) => void;

  dialogOpen: boolean;
  setDialogOpen: (open: boolean) => void;

  dialogView: DialogView; // manages the view state for the OrganizationDialog component
  setDialogView: (view: DialogView) => void;

  error: string | null;
  setError: (error: string) => void;
}

export const useOrganizationSettingsState = create<OrganizationSettingsState>()((set, get) => ({
  llcs: [],
  setLlcs: (llcs) => set({ llcs }),

  selectedLlc: null,
  setSelectedLlc: (llc) => set({ selectedLlc: llc }),

  relationshipUsers: [],
  setRelationshipUsers: (relationshipUsers) => set({ relationshipUsers }),

  accounts: [],
  setAccounts: (accounts) => set({ accounts }),

  loading: false,
  setLoading: (loading) => set({ loading }),

  dialogOpen: false,
  setDialogOpen: (open: boolean) => set({ dialogOpen: open }),

  dialogView: DialogView.ADD_LOCATION_DEFAULT,
  setDialogView: (view: DialogView) => set({ dialogView: view }),

  error: null,
  setError: (error) => set({ error }),
}));

export const useOrganizationSettings = () => {
  const {
    llcs,
    setLlcs,
    selectedLlc,
    setSelectedLlc,
    relationshipUsers,
    setRelationshipUsers,
    accounts,
    setAccounts,
    loading,
    setLoading,
    dialogOpen,
    setDialogOpen,
    dialogView,
    setDialogView,
    error,
    setError,
  } = useOrganizationSettingsState();

  const fetchOrgSettings = async () => {
    const response = await organizationApi.getOrganizationSettings();
    setLlcs(response.data.llcs);
    setRelationshipUsers(response.data.relationshipUsers);
    setAccounts(response.data.accounts);
    return response.data;
  };

  const fetchRelationshipsSelector = async () => {
    const response = await organizationApi.getRelationshipsSelector();
    return response.data;
  };

  // Used only by admins or super admins
  const fetchOrgSettingsByRelationshipId = async (relationshipId: number) => {
    setLoading(true);

    try {
      const response = await organizationApi.getOrgSettingsByRelationshipId(relationshipId);
      setLlcs(response.data?.llcs || []);
      setRelationshipUsers(response.data?.relationshipUsers || []);
      setAccounts(response.data?.accounts || []);
      setLoading(false);
      setError(null);
      return response.data;
    } catch (err) {
      setLoading(false);
      setError(err.message);
    }
  };

  // Update the point of contact for a location or llc
  const updatePointOfContact = async (userId: number, entityId: number, entityType: string) => {
    setLoading(true);
    const response = await organizationApi.updatePointOfContact(userId, entityId, entityType);
    setLoading(false);
    return response.data;
  };

  return {
    llcs,
    selectedLlc,
    relationshipUsers,
    loading,
    accounts,
    dialogView,
    dialogOpen,
    error,
    fetchOrgSettings,
    fetchRelationshipsSelector,
    fetchOrgSettingsByRelationshipId,
    updatePointOfContact,
    setDialogOpen,
    setDialogView,
    setSelectedLlc,
  };
};
