import { Box, Divider, Stack, Theme, Typography } from "@mui/material";
import { SafeLeaseButton, SafeLeaseCard } from "@safelease/components";
import { PendingReviewsCounter } from "../../../PortfolioView/PendingReviewsCounter";
import { FacilityLogoAvatar } from "../../../../components/FacilityLogoAvatar";

interface FacilityOverviewHeaderProps {
  logo?: string;
  facilityName?: string;
  facilityAddress?: string;
  activeCampaignCount?: number;
  reviewsPendingReplyCount?: number;
  handleReviewReplyDrawerOpen: () => void;
}

export function FacilityOverviewHeader({
  logo,
  facilityName,
  facilityAddress,
  reviewsPendingReplyCount,
  handleReviewReplyDrawerOpen,
}: FacilityOverviewHeaderProps) {
  return (
    <SafeLeaseCard sx={{ p: 2 }}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction="row" spacing={2} alignItems="center">
          <Stack direction="row" spacing={2} alignItems="center" justifyContent="flex-start">
            <FacilityLogoAvatar src={logo} />
            <Box>
              <Typography variant="h6" fontWeight="700">
                {facilityName}
              </Typography>
              <Typography sx={{ color: (theme: Theme) => theme.palette.grey.A100 }}>{facilityAddress}</Typography>
            </Box>
          </Stack>
          <Divider flexItem orientation="vertical" />
          <SafeLeaseButton onClick={handleReviewReplyDrawerOpen}>
            <PendingReviewsCounter count={reviewsPendingReplyCount} />
          </SafeLeaseButton>
        </Stack>
      </Stack>
    </SafeLeaseCard>
  );
}
