import { useEffect, Key } from 'react';
import { ColumnDef } from '../utilities/column-defs';
import { QueryResult } from '@apollo/client';
import { useTableStore, TableRow } from '../utilities/table-state'
import SortingTableRowLegacy from './sorting-table-row-legacy';

function SortingTableLegacy(props: {
    title: string,
    columns: ColumnDef[],
    queryResultData: any[],
    queryResult?: QueryResult, // This is only necessary if a row click can trigger a refetch, as is the case in the match claims table
    uniqueKey?: string | number, // unique column key used by React to iterate over components. Defaults to "id," but in cases where id is identical across rows (ie in the claim matching drawer), we can pass a different unique identifier
    onRowClick?: (row: any) => (event: KeyboardEvent | MouseEvent) => void,
    modifyRows?: (rows: TableRow[]) => TableRow[],
    totalsRowRenderer?: (props: {tableTitle: string}) => JSX.Element,
    subTableInfo?: { 
      component: (props: any) => JSX.Element, 
      queryResult: QueryResult 
    }
    isSubTable?: boolean,
    defaultSorter?: (row1: TableRow, row2: TableRow) => number
    cellPadding?: string
}) {

  const { 
    title, 
    columns, 
    onRowClick, 
    queryResultData, 
    queryResult, 
    uniqueKey, 
    modifyRows,
    totalsRowRenderer,
    subTableInfo,
    isSubTable,
    defaultSorter,
    cellPadding
   } = props;

  const { rows, setRows, sortRows } = useTableStore(title, columns)(
    (state) => ({
      rows: state.rows,
      setRows: state.setRows,
      sortRows: state.sortRows,
    })
  )

  useEffect(() => {
    if (!modifyRows) {
      setRows(queryResultData || [])
      sortRows(defaultSorter)
    } else {
      setRows(modifyRows(queryResultData || []))
      sortRows(defaultSorter)
    }
  }, [JSON.stringify(queryResultData)])

  if (!queryResultData) return <>Loading...</>

  return (
    <>
      {rows?.map((row) => (
        <SortingTableRowLegacy
          columns={columns}
          row={row}
          key={uniqueKey ? (row[uniqueKey] as Key) : (row.id as Key)}
          onRowClick={onRowClick ? (onRowClick(row) as any) : null}
          queryResult={queryResult}
          subTableInfo={subTableInfo}
          title={title}
          cellPadding={cellPadding}
        />
      ))}
      {totalsRowRenderer && totalsRowRenderer({ tableTitle: title })}
    </>
  );
}

export enum TableTitles {
  Relationships = 'Relationships',
  DocumentsTable = 'DocumentsTable',
  LlcsSub = 'LLcsSub',
  LocationsByLlcSub = 'LocationsByLlcSub',
  LocationsByRelationshipSub = 'LocationsByRelationshipSub',
  RelationshipsByClaims = 'RelationshipsByClaims',
}

export default SortingTableLegacy;
