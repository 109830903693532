import ServiceAbstract from './abstract';
import http from "./http-common";

class AdminDataService extends ServiceAbstract {
  report(type, locationId) {
    return http.post("/admin/report", {type: type, locationId: locationId});
  }
  billing(llcId, month) {
    return http.post("/admin/billing", {llcId: llcId, month: month});
  }
  attachRateReport(date, forceRerunReport) {
    return http.post("/admin/attach_rate_report", {date, forceRerunReport});
  }
  invitationReport() {
    return http.get("/admin/invitation_report");
  }
  updateInvitation(invitation) {
    return http.post("/admin/tools/invitations/update", invitation);
  }
  deleteInvitations(ids) {
    return http.post("/admin/tools/invitations/delete", {ids});
  }
  sendInvitations(ids) {
    return http.post("/admin/tools/invitations/send", {ids});
  }
  addInvitations(csv) {
    return http.post("/admin/tools/invitations/add", {csv});
  }
  otpEnroll(secret, token) {
    return http.post('/admin/otp/enroll', {secret, token});
  }
  otpValidate(token) {
    return http.post('/admin/otp/validate', {token});
  }
  otpPing() {
    return http.get('/admin/otp/ping');
  }
  getCredentials(params) {
    return http.get('/admin/tools/credentials' + '?' + new URLSearchParams(params));
  }
  getBillingCredentials(params) {
    return http.get('/admin/tools/billing_credentials' + '?' + new URLSearchParams(params));
  }
  billingStatusReport() {
    return http.get("/admin/billing_status_report");
  }
  userFeedbackReport() {
    return http.get("/admin/user_feedback_report");
  }
  privatePolicyReport() {
    return http.get("/admin/private_policy_report");
  }
  bonusLocationsReport() {
    return http.get("/admin/bonus_locations_report");
  }
  retryJob(jobId) {
    return http.post("/admin/retry_job", {jobId});
  }
  createNote(payload) {
    return http.post('/admin/create_note', payload);
  }
  impactReport(date) {
    return http.post("/admin/impact_report", {date});
  }
  updateJob(payload) {
    return http.post('/admin/update_job', payload);
  }
  auditReason(payload) {
    return http.post('/admin/audit_reason', payload);
  }
  coverageReport() {
    return http.get('/admin/coverage_report');
  }
  upcomingJobsReport() {
    return http.get('/admin/upcoming_jobs_report');
  }
}

export default new AdminDataService();
