import { Box, Divider, Stack } from "@mui/material";
import { SafeLeaseTab, SafeLeaseTabContent, SafeLeaseTabs } from "@safelease/components";
import { useEffect, useState } from "react";
import { FacilityInformation } from "./FacilityInformation";
import { IntegrationSettings } from "./IntegrationSettings";
import { useLocation } from "react-router-dom";

interface FacilitySettingsProps {}

// Tab container component to control which tab of the reputation settings menu is displayed
export function FacilitySettings({}: FacilitySettingsProps) {
  const queryParamDefaultTab = new URLSearchParams(useLocation().search).get("defaultTab");

  // If there is a default tab passed into the URL, open that tab
  // Otherwise, default to the "Facility info" tab
  const [settingsTab, setSettingsTab] = useState<number>(0);

  // When landing on the facility settings page, if there's a default tab set it
  // pre-navigate to that tab
  useEffect(() => {
    setSettingsTab(Number(queryParamDefaultTab ?? 0));
  }, [queryParamDefaultTab]);

  return (
    <Stack direction="row" spacing={2} divider={<Divider orientation="vertical" flexItem />}>
      <SafeLeaseTabs
        sx={{ minWidth: 240, height: 500 }}
        appearance="bubble"
        onChange={(_, value) => setSettingsTab(value)}
        value={settingsTab}
        orientation="vertical"
      >
        <SafeLeaseTab label="Facility Info" value={0} />
        <SafeLeaseTab label="Google Place ID" value={1} /> {/*if/when rep expands beyond just google we can rename Integrations*/}
      </SafeLeaseTabs>
      <Box>
        <SafeLeaseTabContent value={0} currentTab={settingsTab}>
          <FacilityInformation />
        </SafeLeaseTabContent>
        <SafeLeaseTabContent value={1} currentTab={settingsTab}>
          <IntegrationSettings />
        </SafeLeaseTabContent>
      </Box>
    </Stack>
  );
}
