import React, { useState } from "react";

// UI - libs
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  DialogContentText,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import { useSnackbar } from "notistack";

// UI - internal
import { muiRedButton, muiSigninButton } from "../../../../styles/mui-overrides";

// Data - libs
import { useApolloClient } from "@apollo/client";

// Data - internal
import UserDataService from "../../../../services/user.service";
import { User } from "../../../../utilities/generated/gql-types";
import { getUsers } from "../../../../queries";

interface DeleteUserDialogProps {
  user: User;
  open: boolean;
  onClose: () => void;
  closeDrawer: (event: any) => void;
}

// Confirmation screen before deleting an user
export default function DeleteUserDialog({
  user,
  open,
  onClose,
  closeDrawer,
}: DeleteUserDialogProps) {
  const { enqueueSnackbar } = useSnackbar();
  const client = useApolloClient();
  const [loading, setLoading] = useState<boolean>(false);
  const [repeatId, setRepeatId] = useState<string>("");

  const handleDeleteUser = async () => {
    setLoading(true);
    const response = await UserDataService.delete({
      id: user?.id,
    });
    client.refetchQueries({ include: [getUsers] });

    if (!response.data?.errors) {
      enqueueSnackbar("User deleted.", { variant: "success" });
    } else {
      enqueueSnackbar(
        "Error deleting user: \n" +
          Object.values(response.data.errors)
            .map(({ msg }: any) => msg)
            .join("\n"),
        { variant: "error" }
      );
    }

    // Reset form
    setLoading(false);
    setRepeatId("");

    closeDrawer(null);
    onClose();
  };

  const handleClose = () => {
    if (loading) return;
    onClose();
  };

  if (!user) return null;

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Delete User</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent className="tw-space-y-2">
        <DialogContentText sx={{ mb: 2 }}>
          Delete user <span style={{ fontWeight: 900 }}>{user.name}</span>?
        </DialogContentText>
        <DialogContentText>
          To confirm you want to delete this user, type "
          <span style={{ fontWeight: 900 }}>{user.id}</span>" in the field below.
        </DialogContentText>
        <TextField
          label="Enter ID"
          value={repeatId}
          onChange={(e) => setRepeatId(e.target.value)}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" sx={{...muiSigninButton, width: 'initial' }}>
          Cancel
        </Button>
        <LoadingButton
          onClick={handleDeleteUser}
          sx={muiRedButton()}
          loading={loading}
          disabled={repeatId !== user.id}
        >
          Delete
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
