import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Button from '@mui/material/Button';
import SafeleaseWrapper from '../shared/safelease-wrapper';
import { blackButton } from '../styles/mui-overrides';

export default function LocationSettingsZeroState(props: { onAddLocations: () => void }) {
  const { onAddLocations } = props;

  return (
    <SafeleaseWrapper>
      <div className="tw-font-bold tw-text-2xl tw-mb-4">You have no facilities yet</div>
      <div className="tw-font-semibold tw-text-lg tw-max-w-2xl">
        Need tenant protection, additional revenue, and business insights? Start adding your
        facilities to our app.
      </div>
      <div className="tw-mt-8">
        <Button onClick={onAddLocations} sx={{...blackButton, width: '10rem'}}>
          Add Facility +
        </Button>
      </div>

      <div className="tw-mt-12">
        <div className="tw-font-bold tw-text-3xl tw-mb-2">Get there in 2 simple steps</div>
        <div className="tw-mt-4">
          <ArrowForwardIcon />
          Add a facility via your FMS login
        </div>
        <div className="tw-mt-4">
          <ArrowForwardIcon />
          Get immediate access to insights
        </div>
      </div>
    </SafeleaseWrapper>
  );
}