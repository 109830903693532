import ServiceAbstract from "./abstract";
import http from "./http-common";

class SetupDataService extends ServiceAbstract {
  addLocation(data) {
    if (data.setupVerified) this.validateReason();
    return http.post("/setup/add_location", data, this.config);
  }
  addLocationAddress(data) {
    return http.post("/setup/add_location_address", data);
  }
  updateLocationAddress(data) {
    return http.post("/setup/update_location_address", data);
  }
  deleteLocationAddress(data) {
    return http.post("/setup/delete_location_address", data);
  }
  addAccount(account) {
    return http.post("/setup/add_account", account);
  }
  deleteAccount(accountId) {
    return http.post("/setup/delete_account", {accountId});
  }
  createSafeleaseUserJob(payload) {
    return http.post("/onboarding/create_safelease_user_job", payload);
  }
  createFetchLocationsJob(payload) {
    return http.post("/onboarding/create_fetch_locations_job", payload);
  }
  setupLocationOnFms(payload) {
    return http.post("/onboarding/setup_location_on_fms", payload);
  }
  saveLocationMigration(payload) {
    if (payload.setupVerified) this.validateReason();
    return http.post("/onboarding/save_location_migration", payload, this.config);
  }
  unconfirmLocation(location) {
    this.validateReason();
    return http.post("/setup/unconfirm_location", location, this.config);
  }
  confirmLocation(location, accountId, serviceId) {
    this.validateReason();
    return http.post("/setup/confirm_location", {id: location.id, accountId, serviceId}, this.config);
  }
  fetchAccountLocations(accountId) {
    return http.post("/onboarding/fetch_account_locations", {accountId});
  }
  setRolloverDates(relationshipId, locations) {
    return http.post("/setup/set_rollover_dates", {relationshipId: relationshipId, locations});
  }
  getLogs(jobId) {
    return http.post("/setup/get_logs", {jobId});
  }
  saveRolloverMappings(payload) {
    return http.post("/setup/save_rollover_mappings", payload);
  }
  setStrategy(payload) {
    return http.post("/setup/set_strategy", payload);
  }
  editSubscription(payload) {
    return http.post("/setup/edit_subscription", payload);
  }
  rerunSetup(payload) {
    return http.post("/setup/rerun_setup", payload);
  }
  createAndMatchAccountLocations(accountId, serviceIds) {
    return http.post("/setup/create_and_match_account_locations", {accountId, serviceIds});
  }
  getLeasesForLocationId(locationId: string) {
    return http.get("/setup/leases" + '?' + new URLSearchParams({ locationId }));
  }
  getTwilioSetupData(locationId) {
    return http.get("/setup/twilio_setup_data" + "?" + new URLSearchParams({ locationId }));
  }
}

export default new SetupDataService();
