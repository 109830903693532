import { SafeLeaseAPI as SafeLeaseAPIClass, SafeLeaseEnvironment } from "@safelease/service-utilities";
import UserDataService from "../services/user.service";

export const SafeLeaseAPI = ({ environment = process.env.REACT_APP_SAFELEASE_ENVIRONMENT }) =>
  new SafeLeaseAPIClass({
    service: "reputation",
    userId: "1047",
    accessTokens: {
      reputation: localStorage.getItem("reputationToken"),
      // claims
    },
    tokenGetters: {
      reputation: async () => {
        return await UserDataService.serviceToken({ service: "reputation" });
      },
    },
    environment: environment as SafeLeaseEnvironment,
  });
