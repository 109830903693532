import { Image } from '../../image';
import { muiTooltip } from '../../styles/mui-overrides';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';

export default function Totals(props: {
  totals: { number: string; label: string; tooltipText?: string }[];
  dropdown?: JSX.Element;
}) {
  const { totals, dropdown = <></> } = props;

  return (
    <div className="report-details">
      <div className="totals">
        <div className="title tw-flex tw-justify-between">
          Key Stats
          {dropdown}
        </div>
        <div className="body">
          {totals.map(total => (
            <Total
              key={total.label}
              number={total.number}
              label={total.label}
              tooltipText={total.tooltipText}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

function Total({ number, label, tooltipText }) {
  return (
    <div className="total">
      <div className="number">{number || 0}</div>
      <div className="label">
        {label}
        {tooltipText && <TableToolTip title={tooltipText} />}
      </div>
    </div>
  );
}

function TableToolTip(props: { title: string }) {
  const { title } = props;
  return (
    <Tooltip placement="bottom" title={title}>
      <Button sx={muiTooltip}>
        <span className="badge">
          <Image src="/images/tooltip-icon.svg" alt="warning" />
        </span>
      </Button>
    </Tooltip>
  );
}
