import React from 'react';

// UI - libs
import { Grid, Paper, Skeleton, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { muiSettingsCard } from '../../../styles/mui-overrides';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';

// Data
import { User } from '../../../utilities/generated/gql-types';

interface ContactCardProps {
  label: string;
  user: User;
  loading: boolean;
}

export default function ContactCard({
  label,
  user,
  loading
}: ContactCardProps) {
  return (
    <Paper
    elevation={0}
    sx={{
      ...muiSettingsCard(),
      p: 2,
      height: '100%'
    }}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="caption" sx={{ color: 'rgba(146, 146, 146, 1)' }}>
            {label}
          </Typography>
          {!user && loading && <Skeleton variant='text' height={24} />}
          {!user && !loading && (
            <Typography variant="h6" sx={{ mb: 1 }}>
              <b>No contact assigned</b>
            </Typography>
          )}
          {user && (
            <Typography variant="h6" sx={{ mb: 1 }}>
              <b>{user.name}</b>
            </Typography>
          )}
          <Box
          sx={{
            display: 'flex',
            alignItems: 'center'
          }}>
            <EmailIcon sx={{ mr: 2 }} fontSize='small' />
            {!user && loading && <Skeleton variant='text' height={24} />}
            {!user && !loading && '-'}
            {user && (user.email ? <a href={`mailto:${user.email}`}>{user.email}</a> : '-')}
          </Box>
          <Box
          sx={{
            display: 'flex',
            alignItems: 'center'
          }}>
            <PhoneIcon sx={{ mr: 2 }} fontSize='small'/>
            {!user && loading && <Skeleton variant='text' height={24} />}
            {!user && !loading && '-'}
            {user && (user.phone ? user.phone : '-')}
          </Box>
        </Grid>
      </Grid>
    </Paper>
  )
}