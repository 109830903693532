import { Stepper, StepperProps } from "@mui/material";
import { PropsWithChildren } from "react";

interface SafeLeaseStepperProps extends StepperProps {}

export function SafeLeaseStepper({
  children,
  ...props
}: PropsWithChildren<SafeLeaseStepperProps>) {
  return <Stepper {...props}>{children}</Stepper>;
}
