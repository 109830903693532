import { Stack, Typography, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, Tooltip } from "@mui/material";
import { SafeLeaseCard } from "@safelease/components";
import { useReputation } from "../../../../../useReputation";
import { enqueueSnackbar } from "notistack";
import { useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { mixpanelEventHandler } from "../../../../../../utilities/reactMixpanelHandler";
import { useAuth } from "../../../../../../auth";

export const OffboardFacilityCard = () => {
  const { facility, SafeLeaseAPI } = useReputation();
  const [open, setOpen] = useState(false);
  const queryClient = useQueryClient();
  const auth = useAuth();

  const handleOffboard = async () => {
    try {
      await SafeLeaseAPI.reputation.offboardFacilityById(facility.id);
      enqueueSnackbar("Facility offboarded", { variant: "success" });

      mixpanelEventHandler("Facility Offboarded", {
        facilityId: facility.id,
        facilityName: facility.companyName,
        userEmail: auth.user.email,
        userId: auth.user.id,
        offboardedDate: facility.offboardedDate,
      });

      queryClient.invalidateQueries(["facilityDetails", facility.id]);
    } catch (error) {
      enqueueSnackbar("Error offboarding facility", { variant: "error" });
      console.error(error);
    } finally {
      setOpen(false);
    }
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <SafeLeaseCard sx={{ p: 2 }}>
      <Stack direction={"column"} spacing={1}>
        <Typography variant="h6">Offboard Facility</Typography>
        {facility.offboardedDate ? (
          <Typography variant="body1" color="error">
            Facility Offboarded on {new Date(facility.offboardedDate).toLocaleDateString()}
          </Typography>
        ) : (
          <Stack direction={"row"} spacing={1}>
            <Tooltip title="Only Visible to Admins">
              <Button variant="contained" color="error" onClick={handleOpen} sx={{ width: "200px" }}>
                Offboard Facility
              </Button>
            </Tooltip>
            <Typography variant="body1">
              This action will allow Admin users to remove the facility from the Reputation platform and all associated data will be
              deleted. This action cannot be undone.
            </Typography>
          </Stack>
        )}
      </Stack>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Confirm Offboarding</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to offboard this facility? This action cannot be undone.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleOffboard} color="error" variant="contained">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </SafeLeaseCard>
  );
};
