import { StateCreator } from "zustand";
import { PlanUpgradeSettingsStore } from "./usePlanUpgradeSettingsStore";
import PlanUpgradesService from "../../../services/plan-upgrades.service";
import dayjs from "dayjs";

export interface PlanUpgradeSettingsQueueingSliceState {
  /* track the uploading state */
  isQueueingPlanUpgrades: boolean;
  enqueueProgress: number;
}

export interface PlanUpgradeSettingsQueueingSlice
  extends PlanUpgradeSettingsQueueingSliceState {
  handleQueuePlanUpgrades: () => Promise<boolean>;

  /* Reset the form to be empty after upgrades are queued */
  resetForm: () => void;
}

const initialState: PlanUpgradeSettingsQueueingSliceState = {
  isQueueingPlanUpgrades: false,
  enqueueProgress: 0,
};

/**
 * Control the enqueueing / submission process for plan upgrades
 */
export const createPlanUpgradeSettingsQueueingSlice: StateCreator<
  Partial<PlanUpgradeSettingsStore> & PlanUpgradeSettingsQueueingSlice
> = (set, get) => {
  return {
    ...initialState,
    handleQueuePlanUpgrades: async () => {
      const {
        selectedRelationship,
        selectedLocations,
        enforcementDate,
        premiumBuckets,
        planUpgradeSteps,
        omitPlanUpgradeSteps,
        skusAtSelectedLocations,
      } = get();

      if (!selectedRelationship || !enforcementDate) {
        return;
      }

      set({ isQueueingPlanUpgrades: true });

      const planUpgrades: CreateUpgradeRequestBody[] = selectedLocations
        .map((location) => {
          /* List of individual steps (SKU movements) that will be taken to upgrade the plans */
          const listOfPlanUpgrades = planUpgradeSteps
            .filter((upgradeStep) => {
              /* User has not manually omitted this plan upgrade step */
              const isIncludedBySelection =
                omitPlanUpgradeSteps.findIndex(
                  (omittedPlan) =>
                    omittedPlan.oldCoverage === upgradeStep.oldCoverage &&
                    omittedPlan.oldPremium === upgradeStep.oldPremium &&
                    omittedPlan.newCoverage === upgradeStep.newCoverage &&
                    omittedPlan.newPremium === upgradeStep.newPremium
                ) === -1;

              /* This SKU exists at this location */
              const isIncludedInLocation = skusAtSelectedLocations[
                upgradeStep.oldPremium
              ][upgradeStep.oldCoverage].has(Number(location.id));

              return isIncludedBySelection && isIncludedInLocation;
            })
            .map((upgradeStep) => {
              return {
                oldPremium: upgradeStep.oldPremium,
                oldCoverage: upgradeStep.oldCoverage,
                newPremium: upgradeStep.newPremium,
                newCoverage: upgradeStep.newCoverage,
                removeAfterComplete: true, // For MVP we will always remove plans. In V2 we will make a separate tool for changing available plans
              };
            });

          return {
            locationId: Number(location.id),
            upgradeDate: enforcementDate.toISOString(),
            listOfPlanUpgrades: listOfPlanUpgrades,
          };
        })
        .filter((planUpgrade) => planUpgrade.listOfPlanUpgrades.length > 0);

      try {
        for (const planUpgrade of planUpgrades) {
          await PlanUpgradesService.createUpgrade(planUpgrade);
        }
        set({ isQueueingPlanUpgrades: false });

        return true;
      } catch {
        set({ isQueueingPlanUpgrades: false });
        return false;
      }
    },
    resetForm: () => set({
      selectedRelationship: null,
      selectedLocations: [],
      enforcementDate: dayjs(),
      premiumBuckets: [],
      planUpgradeSteps: [],
      omitPlanUpgradeSteps: [],
      skusAtSelectedLocations: {},
      disableContinue: true,
    })
  };
};
