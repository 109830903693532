import create, {UseBoundStore, StoreApi} from 'zustand'
import { Formatters } from './formatters';
import FileSaver from 'file-saver';
const { convertArrayToCSV } = require('convert-array-to-csv');
import { ColumnDef } from './column-defs';

const tableStores: {[index: string]: TableStore}= {}

interface SortedColumn {
  direction: SortDirection,
  key: string
}

export enum SortDirection {
  Forward = 'Forward',
  Reverse = 'Reverse',
  Neutral = 'Neutral'
}
export interface TableRow {
  id: string | number;
  [index: string]: number | string | boolean | Date;
  status?: string
}

// Slightly-modified natural sorting algorithm from https://greywyvern.com/362
function alphanum(a, b) {
  a = a.toString();
  b = b.toString();

  function chunkify(t) {
    var tz = [], x = 0, y = -1, n = 0, i, j;

    while (i = (j = t.charAt(x++)).charCodeAt(0)) {
      var m = (i == 46 || (i >=48 && i <= 57));
      if (m !== n) {
        tz[++y] = "";
        n = m;
      }
      tz[y] += j;
    }
    return tz;
  }

  var aa = chunkify(a);
  var bb = chunkify(b);

  for (var x = 0; aa[x] && bb[x]; x++) {
    if (aa[x] !== bb[x]) {
      var c = Number(aa[x]), d = Number(bb[x]);
      if (c == aa[x] && d == bb[x]) {
        return c - d;
      } else return (aa[x] > bb[x]) ? 1 : -1;
    }
  }
  return aa.length - bb.length;
}

export const useTableStore = 
    (tableTitle: string, columns: ColumnDef[]): UseBoundStore<TableStore> => {
  if (tableStores[tableTitle]) {
    return tableStores[tableTitle]
  } else {
    const newTableStore: TableStore = create((set, get) => ({
      activeColumnKeys: columns?.map((column) => column.key),
      setActiveColumnKeys: (activeColumnKeys: string[]) => set({activeColumnKeys}),
      rows: [],
      sortedColumn: null,
      expandedRowId: null,
      statusFilters: ['inProgress', 'active', 'comingSoon', 'test', 'canceled'],
      filter: 'location',
      setFilter: (filter: string) => set({ filter }),
      setStatusFilters: (statusFilters: string[]) => set({ statusFilters }),
      setRows: (rows: TableRow[]) => set({rows}),
      updateSortedColumn: (columnKey: string) => {
        if (columnKey === get().sortedColumn?.key) {
          const newDirection = rotateSortDirection(get().sortedColumn?.direction)
          set({
            sortedColumn: { 
              key: columnKey,
              direction: newDirection
            }
          })
        } else {
          set({
            sortedColumn: {
              key: columnKey,
              direction: SortDirection.Forward
            }
          })
        }
        get().sortRows()
      },
      sortRows: (defaultSorter?: (row1: TableRow, row2: TableRow) => number) => {
        const column = get().sortedColumn
        if (!column) {
          if (!defaultSorter){
            return
          } else {
            get().setRows(JSON.parse(JSON.stringify(get().rows)).sort(defaultSorter))
            return
          }
        }
        get().setRows(
          JSON.parse(JSON.stringify(get().rows)).sort((row1: TableRow, row2: TableRow) => {
            const keys = column.key.split('.')
            let val1 = row1[keys[0]]
            let val2 = row2[keys[0]]
            if (keys.length > 1) {
              for (const subKey of keys.slice(1)) {
                val1 = val1[subKey]
                val2 = val2[subKey]
              }
            }

            if (val1 === val2) return 0;
            if (val1 === null || val1 === undefined) {
              return column.direction === SortDirection.Forward ? 1 : -1;
            }
            if (val2 === null || val2 === undefined) {
              return column.direction === SortDirection.Forward ? -1 : 1;
            }

            if (column.direction === SortDirection.Forward) {
              return alphanum(val1, val2);
            } 
            return alphanum(val2, val1);
          })
        )
      },
      getSortDirectionByKey: (columnKey: string): SortDirection => {
        if (get().sortedColumn?.key == columnKey) {
          return get().sortedColumn?.direction
        } else {
          return SortDirection.Neutral
        }
      },
      changeExpandedRow: (rowId) => set({expandedRowId: rowId}),
      isRowExpanded: (rowId) => rowId == get().expandedRowId,
      exportCsv: (tableName: string, totals?: TableRow) => {
        writeCsv(get().rows, columns, tableName, totals)
      },
    }))
    tableStores[tableTitle] = newTableStore
    return tableStores[tableTitle] 
  }
}

const rotateSortDirection = (direction: SortDirection): SortDirection => {
  if (direction === SortDirection.Neutral) {
    direction = SortDirection.Forward
  } else if (direction === SortDirection.Forward) {
    direction = SortDirection.Reverse
  } else if (direction === SortDirection.Reverse) {
    direction = SortDirection.Forward
  }
  return direction
}

const writeCsv = (
    rows: TableRow[],
    columns: ColumnDef[],
    tableName: string,
    totals?: TableRow): void => {
  const csvArray = rows.map((row) => { 
    return columns.reduce((acc, column) => {
        return {...acc, [column.key]: row[column.key]}
      }, {})
    })
  if (totals) {
    csvArray.push(columns.reduce((acc, column) => {
      return {...acc, [column.key]: totals[column.key]}
    }, {}))
    csvArray[csvArray.length - 1]['address'] = 'Totals'
  }
  const csvString = convertArrayToCSV(csvArray)
    var date = Formatters.jsDateToGraphQlDate(new Date());
  var csvBlob = new Blob([csvString],
    { type: "text/plain;charset=utf-8" });
  FileSaver.saveAs(csvBlob, `${tableName}-table-${date}.csv`);
}

interface TableState {
  rows: TableRow[],
  sortedColumn: SortedColumn,
  expandedRowId: number,
  filter: string,
  statusFilters: string[],
  setFilter: (filter: string) => void,
  setStatusFilters: (statusFilters: string[]) => void,
  sortRows: () => void,
  setRows: (rows: TableRow[]) => void,
  getSortDirectionByKey: (columnKey: string) => SortDirection, 
  updateSortedColumn: (columnKey: string) => void,
  changeExpandedRow: (rowId: number) => void,
  isRowExpanded: (rowId: number) => boolean,
  exportCsv: (tableName: string, totals?: TableRow) => void,
  activeColumnKeys: string[],
  setActiveColumnKeys: (columnKeys: string[]) => void,
}

export type TableStore = UseBoundStore<StoreApi<TableState>>
