/*  Functions for building navigation routes based on the user's admin status and
    current relationshipId. Perhaps a better name for this would be GodModeHelper */

import { matchPath, useLocation } from "react-router-dom";

class RouterHelper {

  private relationshipId;
  private locationId: string;
  private isAutoProtectUser: boolean;
  private isInsuranceUser: boolean;
  private isInsightsUser: boolean;
  private featureFlags: string[];
  private isLocationsExistUser: boolean;

  constructor(public user) {
  }

  public setUser(user) {
    this.user = user;
  }

  public setRelationshipId(relationshipId: string) {
    if (relationshipId && !parseInt(relationshipId)) return
    if (relationshipId == this.relationshipId) {
      return
    }
    this.setLocationId(null)
    this.relationshipId = relationshipId;
  }

  public getRelationshipId() {
    return this.relationshipId;
  }

  public setIsAutoProtectUser(isAutoProtectUser: boolean) {
    this.isAutoProtectUser = isAutoProtectUser;
  }
  public getIsAutoProtectUser() {
    return this.isAutoProtectUser;
  }

  public setIsInsuranceUser(isInsuranceUser: boolean) {
    this.isInsuranceUser = isInsuranceUser;
  }
  public getIsInsuranceUser() {
    return this.isInsuranceUser;
  }

  public setIsInsightsUser(isInsightsUser: boolean) {
    this.isInsightsUser = isInsightsUser;
  }
  public getIsInsightsUser() {
    return this.isInsightsUser;
  }

  public setFeatureFlags(flags: string[]) {
    this.featureFlags = flags;
  }
  public getFeatureFlags() {
    return this.featureFlags;
  }

  public setIsLocationsExistUser(isLocationsExistUser: boolean) {
    this.isLocationsExistUser = isLocationsExistUser;
  }
  public getIsLocationsExistUser() {
    return this.isLocationsExistUser;
  }

  private isUserAdmin() {
    if (!this.user) {
      return false
    }
    return ['admin', 'superAdmin'].includes(this.user.role);
  }

  // Used when migrating to/from claims page back to dashboard
  public setLocationId(locationId: string) {
    this.locationId = locationId
  }
  public getLocationId() {
    return this.locationId
  }

  public dashboardRoute(): string {
    if (!this.isUserAdmin() || !this.relationshipId) {
      return '/'
    } else if (this.isUserAdmin()) {
      this.setLocationId(null); // always clear the locationId if an admin is changing relationships
      return '/admin/' + this.relationshipId;
    }
    return '/'
  }

  public reportsRoute(): string {
    return '/reports'
  }

  // public vendorSourcingRoute(): string {
  //   return "/vendor-sourcing";
  // }

  public complianceRoute(): string {
    return '/compliance'
  }

  public reputationRoute(): string {
    if (!this.isUserAdmin() || !this.relationshipId) {
      return `/reputation`;
    } else if (this.isUserAdmin()) {
      return `/admin/reputation/${this.relationshipId}`;
    }
  }

  public claimRoute(claimId): string {
    if (!this.isUserAdmin() || !this.relationshipId) {
      return `/claim/${claimId}`
    } else if (this.isUserAdmin()) {
      return `/admin/${this.relationshipId}/claim/${claimId}`;
    }
  }

  public userSettingsRoute() {
    if (!this.isUserAdmin() || !this.relationshipId) {
      return `/settings/user`;
    } else if (this.isUserAdmin()) {
      return `/admin/settings/user/${this.relationshipId}`;
    }
  }

  public locationSettingsRoute() {
    if (!this.isUserAdmin() || !this.relationshipId) {
      return `/settings/locations`;
    } else if (this.isUserAdmin()) {
      return `/admin/settings/locations/${this.relationshipId}`;
    }
  }

  public billingSettingsRoute() {
    if (!this.isUserAdmin() || !this.relationshipId) {
      return `/settings/billing`;
    } else if (this.isUserAdmin()) {
      return `/admin/settings/billing/${this.relationshipId}`;
    }
  }

  public autoProtectSettingsRoute() {
    if (!this.isUserAdmin() || !this.relationshipId) {
      return `/settings/auto_protect`;
    } else if (this.isUserAdmin()) {
      return `/admin/settings/auto_protect/${this.relationshipId}`;
    }
  }

  public privatePolicySettingsRoute() {
    if (this.isUserAdmin()) {
      return `/admin/settings/private_policy/${this.relationshipId}`;
    }
  }

  public jobSettingsRoute() {
    if (this.isUserAdmin()) {
      return `/admin/settings/jobs/${this.relationshipId}`;
    }
  }

  public notificationSettingsRoute() {
    return `/settings/notifications`;
  }

  public contactSettingsRoute() {
    return `/settings/contact`;
  }

  public organizationRoute(): string {
    return '/settings/organization';
  }
  
  public myAccountRoute(): string {
    return '/settings/my-account';
  }

  public insightsRoute(queryParams: string | null) {
    if (!this.isUserAdmin() || !this.relationshipId) {
      return queryParams ? `/insights?${queryParams}` : '/insights';
    } else if (this.isUserAdmin()) {
      return queryParams ? `/admin/insights/${this.relationshipId}?${queryParams}` : `/admin/insights/${this.relationshipId}`;
    }
  }

  public documentsRoute() {
    if (!this.isUserAdmin() || !this.relationshipId) {
      return `/documents`;
    } else if (this.isUserAdmin()) {
      return `/admin/documents/${this.relationshipId}`;
    }
  }

  public referRoute() {
    return `/refer`;
  }

  public planUpgradesRoute() {
    return "/plan-upgrades"
  }

  public navigateToRoute(
    routeName: string,
    relationshipId: string,
    history: any,
    //TODO claimId and query params should be in the same object to avoid having to pass null to 
    //reach query params in the arg list
    claimId?: string,
    queryParams?: string
  ) {
    this.setRelationshipId(relationshipId);
    if (routeName === 'reports') {
      history.push(this.reportsRoute())
    } else if (routeName === 'dashboard') {
      history.push(this.dashboardRoute())
    } else if (routeName === 'claim') {
      history.push(this.claimRoute(claimId))
    } else if(routeName === 'userSettings') {
      history.push(this.userSettingsRoute())
    } else if (routeName === 'locationSettings') {
      history.push(this.locationSettingsRoute())
    } else if (routeName === 'billingSettings') {
      history.push(this.billingSettingsRoute())
    } else if (routeName === 'autoProtectSettings') {
      history.push(this.autoProtectSettingsRoute())
    } else if (routeName === 'privatePolicySettings') {
      history.push(this.privatePolicySettingsRoute())
    } else if (routeName === 'jobSettings') {
      history.push(this.jobSettingsRoute())
    } else if (routeName === 'notificationSettings') {
      history.push(this.notificationSettingsRoute())
    } else if (routeName === 'contactSettings') {
      history.push(this.contactSettingsRoute())
    } else if (routeName === 'insights') {
      history.push(this.insightsRoute(queryParams))
    } else if (routeName === 'documents') {
      history.push(this.documentsRoute())
    } else if (routeName === 'compliance') {
      history.push(this.complianceRoute())
    } else if (routeName === "reputation") {
      history.push(this.reputationRoute())
    } else if(routeName === "organizationSettings") {
      history.push(this.organizationRoute())
    } else if(routeName === "myAccountSettings") {
      history.push(this.myAccountRoute())
    }
  }

  public static godModePaths = [
    '/admin/relationships', 
    '/documents',
    '/admin/documents/:relationshipId',
    '/admin/tools',
    '/admin/tools/reports',
    '/admin/tools/billing',
    '/admin/tools/billing_report',
    '/admin/tools/match_claims',
    '/admin/tools/dashboard_testing',
    '/admin/',
    '/', 
    '/reports', 
    '/admin/reports/:relationshipId', 
    '/admin/:relationshipId/claim/:claimId', 
    '/admin/:relationshipId', 
    '/report_details/:locationId',
    '/settings',
    '/settings/users',
    '/admin/settings/users/:relationshipId',
    '/settings/locations',
    '/admin/settings/locations/:relationshipId',
    '/settings/billing',
    '/admin/settings/billing/:relationshipId',
    '/settings/auto_protect',
    '/admin/settings/auto_protect/:relationshipId',
    '/admin/settings/jobs/:relationshipId',
    '/claim/:claimId',
    '/admin/insights/:relatioshipId',
    "/admin/reputation/:relationshipId",
    "/admin/reputation/:relationshipId/facility",
  ];

  // Matches routes belonging to god mode
  public static matchGodModeParams(path: string): {[index: string]: string} {
    let params: {};
    for (let godModePath of this.godModePaths) {
      const match = matchPath(path, {
        path: godModePath,
        exact: true,
        strict: false
      });
      if (match?.params) {
        params = match.params
      }
      /* If we find a match, params or not, break. This allows routes like admin/reports/relationships 
         not match with admin/reports/:relationshipId. The order of godModPaths matters for this. */
      if (match) { 
        break
      }
    }
    return params;
  }
}

export default RouterHelper
