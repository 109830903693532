import { create } from "zustand";
import { persist } from "zustand/middleware";
import { JobError } from "../../utilities/generated/gql-types";
import { GridFilterModel, GridLogicOperator } from "@mui/x-data-grid-premium";

interface JobsErrorCenterState {

  // Manage visible columns
  visibleColumns: {
    [key: string]: boolean;
  };
  updateVisibleColumns: (model: {[key: string]: boolean}) => void;

  // Job error visible in the drawer
  selectedJobError: JobError | null;
  setSelectedJobError: (jobError: JobError | null) => void;

  // use this as an intermediate step in de-rendering filters
  // In the JobsErrorCenter compnent, we conditionally apply the filterModel prop to the SafeleaseDataGrid
  // We want to force at least one rerender with the items prop set to [] to de-render the filters
  // then after that, we set the filterModel prop to undefined by spreading a ternary to remove the prop from the SafeleaseDataGrid
  // so the UX lifecycle is
  // Applying filter: user applies filter via button -> add filter to filterModel + set shouldUseFilterModel = true (render 1) -> renders DataGrid with filters
  // Removing filter: user deapplies filter via button -> set filter to empty array (render 1) ->  set shouldUseFilterModel = false after delay (render 2) -> renders DataGrid without filters
  shouldUseFilterModel: boolean;
  filterModel: GridFilterModel;

  // filter to only show errors assigned to the user, or remove the filter if it's already applied
  filterForUser: (name: string) => void;

  // Manage editing state in the drawer
  editingSection: string | null;
  setEditingSection: (section: string | null) => void;

  submittingForm: boolean;
  setSubmittingForm: (submitting: boolean) => void;
}

export const useJobsErrorCenter = create<JobsErrorCenterState>()(
  persist(
    (set, get) => ({

      // Manage which columns are visible
      visibleColumns: {
        id: true,
        team: true,
        csm: true,
        relationshipName: true,
        locationAddress: true,
        failedAt: true,
        method: true,
        triagingStatus: true,
        error: true,
        errorNotes: false,
        csmNotes: false,
        csmResolutionSteps: false,
      },
      updateVisibleColumns: (newModel) => set({ visibleColumns: newModel }),

      // Manage drawer content
      selectedJobError: null,
      setSelectedJobError: (jobError) => set(({ selectedJobError: jobError })),

      shouldUseFilterModel: false,
      // Manage filters
      filterModel: {
        items: [],
      },
      filterForUser: (name: string) => {
        if (get().shouldUseFilterModel) {
          set({ filterModel: { items: [] } })
          // Force delayed rerender, perhaps there's a better way to do this
          // but if we do two sets without a delay, the sets are batched in the render cycle
          setTimeout(() => {
            set({ shouldUseFilterModel: false })
          }, 1)
        } else {
          set({
            shouldUseFilterModel: true,
          });
        }
      },

      // Manage editing job details
      editingSection: null,
      setEditingSection: (section) => set({ editingSection: section }),

      submittingForm: false,
      setSubmittingForm: (submitting) => set({ submittingForm: submitting }),
    }),
    {
      name: 'jobs-error-center'
    }
  )
)
