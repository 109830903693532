import { Box, Skeleton, Stack, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { SafeLeaseCard, SafeLeaseTabs, SafeLeaseTab, SafeLeaseTabContent } from "@safelease/components";
import { MetricGroup, Metric } from "@safelease/components";
import { useState } from "react";
import { CumulativeReviewsGraph } from "./Graphs/CumulativeReviewsGraph";
import { ReviewSentimentGraph } from "./Graphs/ReviewSentimentGraph";
import { ReviewSourcesGraph } from "./Graphs/ReviewSourcesGraph";
import dayjs from "dayjs";

export type GraphRange = "6" | "12" | "36" | "60";

export type PeriodDataPoints = {
  periodStart: Date;
  periodEnd: Date;
  rating: number;
  elicited: number;
  organic: number;
  employee?: number;
  negativeCount: number;
  positiveCount: number;
  total: number;
};

interface RatingBreakdownGraphsProps {
  // Labels for each of the graphs' x-axis
  periods: Array<PeriodDataPoints>;
  // To display skeletons in place of the graphs
  loading?: boolean;
  // Date that this portfolio / facility was turned on in Reputation
  liveDate?: Date;
}

export function RatingBreakdownGraphs({ periods, loading, liveDate }: RatingBreakdownGraphsProps) {
  const [activeGraph, setActiveGraph] = useState<"totalReviews" | "reviewSentiment" | "organicReviews">("totalReviews");
  const [afterGoLiveOnly, setAfterGoLiveOnly] = useState<boolean>(false);

  if (loading) return <Skeleton variant="rectangular" height={300} />;

  const hasData = periods.length > 0;

  const handleChangeDateRange = (newValue: boolean) => {
    setAfterGoLiveOnly(newValue);
  };

  const periodsAfterGoLiveDate = periods.filter((period: PeriodDataPoints) => {
    const dayjsliveDate = dayjs(liveDate);

    return !afterGoLiveOnly || dayjs(period.periodEnd).isAfter(dayjsliveDate);
  });

  const usablePeriodRange = afterGoLiveOnly ? periodsAfterGoLiveDate : periods;

  // Create a running tabulation of the total number of reviews after either go-live or all time
  const cumulativeSums = usablePeriodRange.reduce((acc: number[], item, index) => {
    if (index === 0) {
      acc.push(item.total);
    } else {
      acc.push(item.total + acc[index - 1]);
    }
    return acc;
  }, []);

  const totalReviews = cumulativeSums[cumulativeSums.length - 1];

  return (
    <SafeLeaseCard sx={{ p: 2 }}>
      <ToggleButtonGroup onChange={(_, newValue) => handleChangeDateRange(newValue)} value={afterGoLiveOnly} exclusive sx={{ mb: 2 }}>
        <ToggleButton value={false}>All Time</ToggleButton>
        <ToggleButton value={true}>After Enabling Reputation</ToggleButton>
      </ToggleButtonGroup>
      <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mb: 4 }}>
        <SafeLeaseTabs appearance="bubble" value={activeGraph} onChange={(_, value) => setActiveGraph(value)}>
          <SafeLeaseTab label={`Total Reviews (${totalReviews})`} value="totalReviews" />
          <SafeLeaseTab label="Reviews by Sentiment" value="reviewSentiment" />
          <SafeLeaseTab label="Reviews by Source" value="organicReviews" />
        </SafeLeaseTabs>
      </Stack>
      <SafeLeaseTabContent currentTab={activeGraph} value="totalReviews">
        {!hasData && (
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            height={300}
            bgcolor="background.paper"
            borderRadius={2}
            boxShadow={1}
          >
            <Typography variant="h6" color="text.secondary">
              No reviews available for this view
            </Typography>
          </Box>
        )}
        {hasData && (
          <>
            <Box sx={{ height: 348 }}>
              <CumulativeReviewsGraph cumulativeSums={cumulativeSums} periods={usablePeriodRange} loading={loading} />
            </Box>
          </>
        )}
      </SafeLeaseTabContent>
      <SafeLeaseTabContent currentTab={activeGraph} value="reviewSentiment">
        <Box sx={{ height: 348 }}>
          {!hasData && (
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              height={300}
              bgcolor="background.paper"
              borderRadius={2}
              boxShadow={1}
            >
              <Typography variant="h6" color="text.secondary">
                No reviews available for this view
              </Typography>
            </Box>
          )}
          {hasData && <ReviewSentimentGraph periods={usablePeriodRange} loading={loading} />}
        </Box>
      </SafeLeaseTabContent>

      <SafeLeaseTabContent currentTab={activeGraph} value="organicReviews">
        <MetricGroup sx={{ mb: 2 }} justifyContent="flex-start">
          <Metric label="Organic Reviews" value={usablePeriodRange.map((period) => period.organic).reduce((a, b) => a + b, 0)} />
          <Metric label="Elicited Reviews" value={usablePeriodRange.map((period) => period.elicited).reduce((a, b) => a + b, 0)} />
          <Metric label="Employee-Generated" value={usablePeriodRange.map((period) => period.employee).reduce((a, b) => a + b, 0)} />
        </MetricGroup>
        <Box sx={{ height: 300 }}>
          {!hasData && (
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              height={300}
              bgcolor="background.paper"
              borderRadius={2}
              boxShadow={1}
            >
              <Typography variant="h6" color="text.secondary">
                No reviews available for this view
              </Typography>
            </Box>
          )}
          {hasData && <ReviewSourcesGraph periods={usablePeriodRange} loading={loading} />}
        </Box>
      </SafeLeaseTabContent>
    </SafeLeaseCard>
  );
}
