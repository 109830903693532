// UI - libs
import {
  DataGridPremium,
  DataGridPremiumProps,
  GridColDef,
  GridRenderCellParams,
} from "@mui/x-data-grid-premium";
import { ColumnDef } from "../utilities/column-defs";

// Intersecting the ColumnDef interface to accomodate for old tables
type TransitionColumnDef = Partial<ColumnDef> & Partial<GridColDef>;

interface SafeleaseDataGridProps extends DataGridPremiumProps {
  rowProps?: any;
  clickable?: boolean;
}

export default function SafeleaseDataGrid({
  columns,
  rows,
  rowProps = {},
  clickable = false,
  ...props
}: SafeleaseDataGridProps) {
  const { sx, ...rest } = props;

  const remappedRows = rows.map((row) => ({
    ...row,
    ...rowProps,
  }));

  // Remapping exists to not alter existing table resources
  const remappedColumns: GridColDef[] = columns.map(
    ({
      field,
      headerName,
      key,
      title,
      renderer,
      styles = {},
      ...rest
    }: TransitionColumnDef) => ({
      field: field ? field : key,
      headerName: headerName ? headerName : title,
      ...(styles?.width ? { width: styles?.width } : {}), // Apply width if present
      ...(renderer
        ? {
            renderCell: (params: GridRenderCellParams<Number>) =>
              renderer(params.value, { row: params.row }),
          }
        : {}), // Render a different UI for the cell if a renderer is specified
      ...rest,
    })
  );

  return (
    <DataGridPremium
      rows={remappedRows}
      columns={remappedColumns}
      hideFooterRowCount
      hideFooter
      sx={{
        borderColor: "#EBEFF7",
        "& .MuiDataGrid-cell": {
          borderColor: "#EBEFF7",
          cursor: clickable ? "pointer" : "initial",
        },
        "& .MuiDataGrid-cell:focus": {
          outline: "none",
        },
        "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
          outline: "none", // Removes cell selection outline
        },
        ...sx,
      }}
      //  Disable premium props by default
      disableAggregation={
        props.disableAggregation ? props.disableAggregation : true
      }
      disableRowGrouping={
        props.disableRowGrouping ? props.disableRowGrouping : true
      }
      disableColumnReorder={
        props.disableColumnReorder ? props.disableColumnReorder : true
      }
      // Inherit any additional props and override default props
      {...rest}
    />
  );
}
