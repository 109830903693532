import { create } from "zustand";
import { BaseSlice, createBaseReportSlice } from "./baseReportSlice";
import {
  PrivatePolicyReportSlice,
  createPrivatePolicyReportSlice,
} from "./privatePolicyReportSlice";
import { SummaryReportSlice, createSummaryReportSlice } from "./summaryReportSlice";

type ReportsStore = BaseSlice & PrivatePolicyReportSlice & SummaryReportSlice;

export const sliceResetFns = new Set<() => void>();

export const resetAllSlices = () => {
  sliceResetFns.forEach((resetFn) => {
    resetFn();
  });
};

/* Manage all of the various report states */
export const useReportsStore = create<ReportsStore>()((...a) => ({
  ...createBaseReportSlice(...a),
  ...createPrivatePolicyReportSlice(...a),
  ...createSummaryReportSlice(...a),
  reset: () => resetAllSlices(),
}));
