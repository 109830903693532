import { Box, Checkbox, FormControlLabel, Stack, TextField, Tooltip, Typography } from "@mui/material";
import { usePrivatePolicySettingsStore } from "../../usePrivatePolicySettingsStore/usePrivatePolicySettingsStore";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import DatePicker from "../../../../../components/lib/SafeLeaseDatePicker";
import dayjs from "dayjs";

type FmsEnforcementOptionsProps = {};

function FmsEnforcementOptions({ }: FmsEnforcementOptionsProps) {
  const fmsEnforcementEnabled = usePrivatePolicySettingsStore((state) => state.fmsEnforcementEnabled);
  const selectedFmsEnforcementDate = usePrivatePolicySettingsStore((state) => state.selectedFmsEnforcementDate);
  const setSelectedFmsEnforcementDate = usePrivatePolicySettingsStore((state) => state.setSelectedFmsEnforcementDate);
  const fmsValidPolicyNumber = usePrivatePolicySettingsStore((state) => state.fmsValidPolicyNumber);
  const setFmsValidPolicyNumber = usePrivatePolicySettingsStore((state) => state.setFmsValidPolicyNumber);
  const fmsExpirationDate = usePrivatePolicySettingsStore((state) => state.fmsExpirationDate);
  const setFmsExpirationDate = usePrivatePolicySettingsStore((state) => state.setFmsExpirationDate);

  return (
    <>
      <FormControlLabel
        label="Remove private policies with invalid policy numbers"
        control={<Checkbox checked={fmsValidPolicyNumber} onChange={(e) => setFmsValidPolicyNumber(e.target.checked)} disabled={!fmsEnforcementEnabled} />}
      />
      <FormControlLabel
        label="Remove private policies with invalid or null expiration dates"
        control={<Checkbox checked={fmsExpirationDate} onChange={(e) => setFmsExpirationDate(e.target.checked)} disabled={!fmsEnforcementEnabled} />}
      />
      <Stack alignItems="center" spacing={1} direction="row">
        <Typography variant="h6">FMS Enforcement Date</Typography>
        <Tooltip placement="right" title="This is the date that SafeLease will begin enforcing private policy compliance that is set for the private policies that are found in the FMS.">
          <InfoOutlinedIcon color="disabled" />
        </Tooltip>
      </Stack>
      <Box>
        <DatePicker
          label="Date"
          disabled={!fmsEnforcementEnabled}
          value={selectedFmsEnforcementDate}
          onChange={(newDate: Date) => setSelectedFmsEnforcementDate(dayjs(newDate))}
        />
      </Box>
    </>
  );
}

export default FmsEnforcementOptions;
