import { QueryResult } from "@apollo/client";
import LetterAvatar from "../../shared/LetterAvatar";
import PriorityIcon from "../../shared/PriorityIcon";
import { Teams } from "../../utilities/field-enums";
import { Formatters } from "../../utilities/formatters";
import { TableRow } from "../../utilities/table-state";
import { editableField } from "../../column-renderers/common-renderers";
import {
  Warning as WarningIcon,
  FormatListBulleted as FormatListBulletedIcon
} from "@mui/icons-material";
import {
  GridColDef,
  GridRowParams,
  GridValueGetterParams,
} from "@mui/x-data-grid-premium";
import AdminDataService from "../../services/admin.service";
import { Stack, Tooltip } from "@mui/material";
import { grey } from "@mui/material/colors";
import getJobErrorActions from "./JobErrorActions";

// TODO controlled team updates
export const jobsErrorReportGridColDefs: GridColDef[] = [
  {
    field: "team",
    headerName: "Team",
    width: 100,
    editable: true,
    type: "singleSelect",
    valueOptions: () => [
      { value: Teams.Success, label: "Success" },
      { value: Teams.Support, label: "Support" },
      { value: Teams.Product, label: "Product" },
    ],
    valueGetter: (params: GridValueGetterParams) => {
      return params.value
    },
  },
  {
    field: "assignee",
    headerName: "Assignee",
    width: 80,
    renderCell: ({ row }) => <LetterAvatar name={row.assignee?.name} sx={{ m: 1 }} />,
    valueGetter: (params: GridValueGetterParams) => {
      return params.row.assignee?.name;
    },
  },
  {
    field: "relationshipName",
    headerName: "Relationship Name",
    width: 200,
  },
  {
    field: "locationAddress",
    headerName: "Location",
  },
  {
    field: "failedAt",
    headerName: "Failed At",
    width: 120,
    renderCell: (value) => Formatters.jsDateToLocalTime(value.value),
  },
  {
    field: "method",
    headerName: "Method",
    width: 240,
  },
  {
    field: "triagingStatus",
    headerName: "Triaging Status",
    width: 120,
    align: "center",
    renderCell: ({ value }) => <PriorityIcon priority={value} />,
  },
  {
    field: "error",
    headerName: "Error",
    flex: 1,
    minWidth: 350,
    renderCell: (value) => (
      <div className=" tw-w-full tw-break-words">{value?.value?.slice(0, 100)}{value?.value?.length > 100 ? "..." : ""}</div>
    ),
  },
  {
    field: "errorNotes",
    headerName: "Error Notes",
    width: 400,
    renderCell: (value) => (
      <div className="tw-w-full tw-break-words">{value.value}</div>
    ),
  },
  {
    field: "csmNotes",
    headerName: "CSM Notes",
    width: 400,
    renderCell: (value) =>
      editableField(
        value.value,
        { row: value.row, queryResult: value.row.queryResult },
        {
          callback: async (
            newValue: string,
            tableInfo: { row: TableRow; queryResult: QueryResult }
          ) => {
            await AdminDataService.updateJob({
              csmNotes: newValue,
              jobId: tableInfo.row.id,
            });
            await tableInfo.queryResult.refetch();
          },
          nonAdminsCannotEdit: true,
        }
      ),
  },
  {
    field: "csmResolutionSteps",
    headerName: "CSM Resolution Steps",
    minWidth: 400,
    renderCell: (value) => (
      <div className="tw-w-[20rem] tw-break-words">{value.value}</div>
    ),
  },
  {
    field: "id",
    headerName: "Actions",
    headerAlign: "center",
    renderHeader: () => null,
    align: "center",
    type: "actions",
    width: 50,
    getActions: (params: GridRowParams) =>
      getJobErrorActions({
        jobId: params.id,
        jobError: params.row,
        jobErrorQuery: params.row.queryResult,
        history: params.row.history
      }),
    // renderCell: (value) => JobErrorActionsRenderer(value.value, { row: value.row, queryResult: value.row.queryResult }),
  },

  // Notes field renders as icons that are highlighed when the notes exist
  // To view notes, open click the row to open the drawer
  {
    field: "notes",
    headerName: "Notes",
    align: "center",
    headerAlign: "center",
    renderCell: ({ row }) => (
      <Stack direction="row" spacing={1}>
        <Tooltip title="Error notes" disableHoverListener={!row.errorNotes}>
          <WarningIcon
            sx={{ color: !!row.errorNotes ? grey[600] : grey[200] }}
          />
        </Tooltip>
        <Tooltip
          title="CSM Resolution Steps"
          disableHoverListener={!row.csmResolutionSteps}
        >
          <FormatListBulletedIcon
            sx={{ color: !!row.csmResolutionSteps ? grey[600] : grey[200] }}
          />
        </Tooltip>
      </Stack>
    ),
  },
];
