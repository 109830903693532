import { useEffect, useContext, useState } from 'react'
import { TitleValuePair } from '../common'
import { Claim, Note, NoteHistoryAndActiveUsers } from '../utilities/generated/gql-types'
import ClaimsDataService from '../services/claims.service'
import { useAuth } from '../auth'
import { NoteType } from '../utilities/field-enums'
import { QueryResult } from '@apollo/client';
import { Button } from '@mui/material'
import { muiAltModalButton } from '../styles/mui-overrides'
import { useAtom } from 'jotai'
import { useResetAtom } from 'jotai/utils'
import { NotificationDot } from '../common'
import { Notification } from '../utilities/generated/gql-types'
import { NotificationType } from '../utilities/field-enums'
import NotificationsDataService from '../services/notifications.service'
import { NotificationsContext, NotificationsContextType } 
    from '../utilities/notifications-context';
import { claimNotesEditingStateAtom } from './claim-details-drawer'


const ClaimNote = (props: {
    claim: Claim,
    claimNotesQuery: QueryResult<{getClaimNotes: NoteHistoryAndActiveUsers}>
    note?: Note,
    defaultIsEditing?: boolean
}) => {
  const { claim, claimNotesQuery, note, defaultIsEditing } = props
  const auth = useAuth()
  const notifsHook = useContext(NotificationsContext)
  const [claimNotesEditingState, setClaimNotesEditingState] =
      useAtom(claimNotesEditingStateAtom)
  const resetClaimNotesEditingState = useResetAtom(claimNotesEditingStateAtom)
    
  const [canEdit, setCanEdit] = useState<boolean>(false)
  const [isEditing, setIsEditing] = useState<boolean>(defaultIsEditing)
  const [newNoteRequestPending, setNewNoteRequestPending] = useState(false)

  useEffect(() => {
    if (!claimNotesEditingState.activeNoteId && defaultIsEditing) {
      setIsEditing(true)
      return
    }
    if (claimNotesEditingState.activeNoteId !== note?.id) {
      setIsEditing(false)
    }
  }, [claimNotesEditingState.activeNoteId])


  useEffect(() => {
    setCanEdit(!!defaultIsEditing ||
        (auth.user.isAdmin && note?.senderUserId === 0) ||
        auth.user.id == note?.senderUserId)
  }, [note])

  const deleteNotification = (note: Note) => {
    //TODO readd this ?
    //if (claimNotesQuery?.data?.getClaimNotes?.notes?.length === 0) return
//  if (auth.user.role === 'admin' && 
//      !(auth.user.email === 'ogarcia@safelease.com' ||
//          auth.user.email === 'chshieh@safelease.com' ||
//          auth.user.email === 'alocke@safelease.com')) {
//    return
//  }
    const noteHasNotification = 
        notifsHook.NotificationsHelper.noteHasNotification(
            note,
            notifsHook.allNotifications)
    if (noteHasNotification) {
      const notificationsToDelete = 
          notifsHook.allNotifications.filter((notification: Notification) => {
            return notification.notificationType === NotificationType.ClaimNote &&
                notification.claimId == claim.id &&
                notification.noteId == note.id
          })
      NotificationsDataService.deleteNotifications(notificationsToDelete).then(() => {
        console.log('deletingNotification')
        notifsHook.allNotificationsQuery.refetch()
      })
    }
  }

  const markAsReadClicked = () => {
    deleteNotification(note);
  }

  const submitNewNote = async (newNoteText: string) => { 
    console.log({claim})
    if (!newNoteText || newNoteRequestPending) return
    setNewNoteRequestPending(true)
    const newNote: Note = {
      type: NoteType.ClaimNote,
      text: newNoteText,
      claimId: parseInt(claim.id),
      claimExternalId: claim.externalId,
      locationId: claim.locationId,
      senderUserId: auth.user.isAdmin ? 
          0 :
          auth.user.id,
    }
    await ClaimsDataService.createNote(newNote)
    await claimNotesQuery.refetch()
    resetClaimNotesEditingState()
    setNewNoteRequestPending(false)
  }

  const submitEditedNote = async (noteToEdit: Note, newNoteText: string) => {
    if (!newNoteText || newNoteRequestPending) return
    setNewNoteRequestPending(true)
    const editedNote = JSON.parse(JSON.stringify(noteToEdit))
    editedNote.text = newNoteText
    await ClaimsDataService.editNote(editedNote)
    await claimNotesQuery.refetch()
    setIsEditing(false)
    setNewNoteRequestPending(false)
    resetClaimNotesEditingState()
  }

  const deleteNoteClicked = async (note: Note) => {
    await ClaimsDataService.deleteNote(note)
    await claimNotesQuery.refetch()
  }

  const cancelEditing = (note: Note) => {
    resetClaimNotesEditingState()
    setIsEditing(false)
  }
  const editNoteClicked = (note: Note) => {
    setClaimNotesEditingState((s) => ({
      ...s,
      activeNoteId: note?.id || 0,
      pendingText: note.text,
    }))
    setIsEditing(true)
  }

  const displayDate = (dateStr: string | null) => {
    if (!dateStr) return ''
    const localDateStr = new Date(dateStr).toLocaleString([], {
        hour: '2-digit',
        minute:'2-digit',
        year: 'numeric',
        day: '2-digit',
        month: '2-digit'})
    const modifiedDateStr = localDateStr.replace(', ',' | ').replaceAll('/','.')
    return modifiedDateStr
  }

  const noteActionHeader = (note: Note) => {
    if (!note?.senderName) return ''
    if (note?.text?.includes('?')) return `${note?.senderName} asked a question.`
    return `${note?.senderName} left a message.`
  }

  return (
    <NotificationsContext.Consumer>
      {(notificationsContext: NotificationsContextType) =>
        <div className="tw-w-[350px] tw-mb-[26px]">
          <div className="tw-mb-1 tw-flex tw-items-center">
            {notificationsContext.NotificationsHelper.noteHasNotification(
                note,
                notificationsContext.allNotifications) &&
              <>
                <NotificationDot />
                <a className="tw-ml-2 tw-text-[#6E82A9] tw-cursor-pointer tw-text-xs"
                    onClick={(e) => deleteNotification(note)}>
                  Mark as read
                </a>
              </>
            }
          </div>
          <div className="tw-flex tw-flex-row tw-justify-between">
            <TitleValuePair title={noteActionHeader(note)}
                extraStyles="tw-mb-3"
                value="" />
            <div className='tw-text-[#6E82A9] tw-text-xs'>
              {displayDate(note?.createdAt)}
            </div>
          </div>
          {isEditing &&
            <textarea value={claimNotesEditingState.pendingText}
                onChange={(event) => setClaimNotesEditingState(
                    (s) => ({...s, pendingText: event.target.value}))}
                className="tw-w-[350px] tw-overflow-y-scroll tw-h-full
                    tw-text-xs tw-mb-2 tw-outline-none tw-pb-2">
              {note?.text}
            </textarea>
          }
          {!isEditing &&
            <div className="tw-text-xs tw-break-words">
              {note?.text}
            </div>
          }
          <div className={canEdit ? 
              "" : 
              "tw-pb-[17px] tw-border-solid tw-border-0 tw-border-[#E3E9F7] tw-border-b"}>
            {isEditing &&
              <>
                <Button disabled={!claimNotesEditingState.pendingText || newNoteRequestPending}
                    sx={muiAltModalButton}
                    onClick={defaultIsEditing ? 
                        (event) => submitNewNote(claimNotesEditingState.pendingText) :
                        (event) => submitEditedNote(note, claimNotesEditingState.pendingText)}>
                  Submit
                </Button>
                {!defaultIsEditing &&
                  <a className="tw-mt-4 tw-ml-4 tw-text-[#6E82A9] tw-cursor-pointer tw-text-xs"
                      onClick={(event) => cancelEditing(note) }>
                    Cancel 
                  </a>
                }
              </>
            }
            {!isEditing && !!canEdit && defaultIsEditing &&
              <textarea disabled 
                  className="tw-w-[350px] tw-overflow-y-scroll tw-h-full
                      tw-text-xs tw-mb-2 tw-outline-none tw-pb-2">
              </textarea>
            }
            {!isEditing && !!canEdit && !defaultIsEditing &&
              <div className="tw-flex tw-flex-row">
                <a className="tw-mt-4 tw-mr-4 tw-text-[#6E82A9] tw-cursor-pointer tw-text-xs"
                    onClick={(event) => editNoteClicked(note) }>
                  Edit
                </a>
                <a className="tw-mt-4 tw-text-[#6E82A9] tw-cursor-pointer tw-text-xs"
                    onClick={(event) => deleteNoteClicked(note) }>
                  Delete
                </a>
              </div>
            }
          </div>
        </div>
      }
    </NotificationsContext.Consumer>
  )
}

export default ClaimNote
