import {
  AdminPanelSettingsOutlined,
  CampaignOutlined,
  FeedbackOutlined,
  KeyboardArrowLeft,
  PersonOutline,
  TrendingUp,
  Tune,
} from "@mui/icons-material";
import { Alert, Box, CircularProgress, MenuItem, Select, Skeleton, Stack, Theme, Typography } from "@mui/material";
import { SafeLeaseButton, SafeLeaseTab, SafeLeaseTabContent, SafeLeaseTabs } from "@safelease/components";
import { useState, useEffect } from "react";
import { FacilityOverview } from "./FacilityViewTabs/FacilityOverview";
import { CampaignsOverview } from "./FacilityViewTabs/CampaignsOverview";
import { ContactsOverview } from "./FacilityViewTabs/ContactsOverview";
import { FacilitySettings } from "./FacilityViewTabs/FacilitySettings";
import { Redirect, useHistory, useParams } from "react-router-dom";
import { FeedbackOverview } from "./FacilityViewTabs/FeedbackOverview";
import { useReputation } from "../../useReputation";
import { mixpanelEventHandler } from "../../../utilities/reactMixpanelHandler";
import TitleHeader from "../../../shared/title-header";
import RouterHelper from "../../../utilities/router-helper";
import { useAuth } from "../../../auth";
import { SetupValidationDisplay } from "../../components/SetupValidationDisplay";
import { AdminToolsFacilityContainer } from "./FacilityViewTabs/Admin";
import { AdminsOnly } from "../../components/AdminsOnly";

interface FacilityViewProps {
  routerHelper: RouterHelper;
}

enum Tabs {
  Overview = "Overview",
  Campaigns = "Campaigns",
  PrivateReviews = "Private Reviews",
  Contacts = "Contacts",
  Settings = "Settings",
  Admin = "Admin",
}

export function FacilityView({ routerHelper }: FacilityViewProps) {
  const { statuses, selectedFacilityId, facility, setSelectedFacilityId, portfolio, setSelectedRelationshipId, setReputationRoute } =
    useReputation();
  const [currentTab, setTab] = useState<string>(Tabs.Overview);
  const { push: navigate } = useHistory();

  const { relationshipId: relationshipIdFromUrl, facilityId: facilityIdFromUrl } = useParams<{
    relationshipId?: string;
    facilityId?: string;
  }>();

  const auth = useAuth();
  const isAdmin = auth.user.isAdmin;
  const relationshipId = relationshipIdFromUrl ?? auth.user.relationshipId;

  // This is a temporary fix to ticket SA7383 fixing the type error for when the facility redirect causes the historical records to return as null. Ticket #SA-7407 is put in the tech debt epic to improve the logic once the future of reputation as a standalone or decoupled serivce is decided.
  function shouldRedirect(): boolean {
    return !selectedFacilityId || (portfolio && facility && facility.portfolioId !== portfolio.id);
  }

  useEffect(() => {
    if (relationshipId) {
      setSelectedRelationshipId(relationshipId);
      setReputationRoute(routerHelper.reputationRoute());
    }
  }, [relationshipId]);

  if (facilityIdFromUrl && !selectedFacilityId) {
    setSelectedFacilityId(facilityIdFromUrl);
  }

  useEffect(() => {
    if (facility) {
      mixpanelEventHandler("Reputation - Page View - Facility", { facilityId: facility.id });
    }
  }, [facility]);

  if (shouldRedirect()) {
    return <Redirect to={routerHelper.reputationRoute()} />;
  }
  if (statuses.facility === "loading" || statuses.portfolio === "loading")
    return <Skeleton variant="rectangular" height={400} sx={{ width: "100%" }} />;
  if (statuses.facility === "error") return <Alert severity="error">Something went wrong. Please refresh the page.</Alert>;

  const settingsTabIcon = <SetupValidationDisplay facility={facility} displayType="icon" fallbackComponent={<Tune sx={{ mr: 1 }} />} />;

  return (
    <Box>
      <TitleHeader title="Facility Overview" />
      <Box sx={{ maxWidth: (theme: Theme) => theme.breakpoints.values.xl }}>
        <Stack direction="row" spacing={4} alignItems="center">
          <Stack direction="row" spacing={1} alignItems="center">
            <SafeLeaseButton
              variant="text"
              color="navy"
              onClick={() => navigate(routerHelper.reputationRoute())}
              startIcon={<KeyboardArrowLeft />}
              sx={{ fontWeight: 400, fontSize: "16px", fontFamily: "Roboto" }}
            >
              Portfolio
            </SafeLeaseButton>
            <Typography>/</Typography>
            {statuses.portfolio === "loading" && <CircularProgress size={20} />}
            {statuses.portfolio === "success" && (
              <Select
                value={selectedFacilityId}
                onChange={(e) => {
                  setSelectedFacilityId(e.target.value);
                  navigate(`${routerHelper.reputationRoute()}/facility/${e.target.value}`);
                }}
                variant="standard"
                sx={{
                  borderBottom: "none",
                  "&:before": { borderBottom: "none" },
                  "&:hover": { borderBottom: "none", "&:before": { borderBottom: "none" } },
                }}
              >
                {portfolio.facilities.map((facility) => (
                  <MenuItem value={facility.id} key={facility.id}>
                    {`${facility.companyName} - ${facility.address} (${facility.customerLocationId})`}
                  </MenuItem>
                ))}
              </Select>
            )}
          </Stack>
        </Stack>
        <SetupValidationDisplay facility={facility} displayType="fullCard" />

        <SafeLeaseTabs
          onChange={(_, value) => {
            setTab(value);
            mixpanelEventHandler(`Reputation - Tab - ${value}`, { facilityId: facility.id });
          }}
          value={currentTab}
          sx={{ mt: 4 }}
        >
          <SafeLeaseTab label={Tabs.Overview} icon={<TrendingUp />} value={Tabs.Overview} />
          <SafeLeaseTab label={Tabs.Campaigns} icon={<CampaignOutlined />} value={Tabs.Campaigns} />
          <SafeLeaseTab label={Tabs.PrivateReviews} icon={<FeedbackOutlined />} value={Tabs.PrivateReviews} />
          <SafeLeaseTab label={Tabs.Contacts} icon={<PersonOutline />} value={Tabs.Contacts} />
          <SafeLeaseTab label={Tabs.Settings} icon={settingsTabIcon} value={Tabs.Settings} />
          {isAdmin && <SafeLeaseTab label={Tabs.Admin} icon={<AdminPanelSettingsOutlined />} value={Tabs.Admin} />}
        </SafeLeaseTabs>
        <Box sx={{ py: 2 }}>
          <SafeLeaseTabContent value={Tabs.Overview} currentTab={currentTab}>
            <FacilityOverview />
          </SafeLeaseTabContent>
          <SafeLeaseTabContent value={Tabs.Campaigns} currentTab={currentTab}>
            <CampaignsOverview campaigns={[]} />
          </SafeLeaseTabContent>
          <SafeLeaseTabContent value={Tabs.PrivateReviews} currentTab={currentTab}>
            <FeedbackOverview />
          </SafeLeaseTabContent>
          <SafeLeaseTabContent value={Tabs.Contacts} currentTab={currentTab}>
            <ContactsOverview />
          </SafeLeaseTabContent>
          <SafeLeaseTabContent value={Tabs.Settings} currentTab={currentTab}>
            <FacilitySettings />
          </SafeLeaseTabContent>
          <AdminsOnly>
            <SafeLeaseTabContent value={Tabs.Admin} currentTab={currentTab}>
              <AdminToolsFacilityContainer />
            </SafeLeaseTabContent>
          </AdminsOnly>
        </Box>
      </Box>
    </Box>
  );
}
