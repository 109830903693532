import React from "react";
import { Dialog, DialogTitle, IconButton, Divider, DialogContent } from "@mui/material";
import { useOrganizationSettings } from "../useOrganizationSettingsStore";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import AddLocation from "./AddLocation";
import { LlcSettings } from "./Llc/LlcSettings";
import { DialogView, DialogViewTitles, isLocationView } from "./DialogViews";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const StyledDivider = styled(Divider)(({ theme }) => ({
  width: "95%",
  display: "flex",
  alignSelf: "center",
  backgroundColor: "#EBEFF7",
}));

interface OrganizationDialogProps {
  open: boolean;
  handleClose: () => void;
}

const OrganizationDialog: React.FC<OrganizationDialogProps> = ({ open, handleClose }) => {
  const { dialogView: view } = useOrganizationSettings();

  let dialogComponent = <div>No supported view found</div>;
  if (isLocationView(view)) {
    dialogComponent = <AddLocation />;
  } else if (view === DialogView.LLC_SETTINGS) {
    dialogComponent = <LlcSettings />;
  }

  return (
    <>
      <BootstrapDialog
        onClose={handleClose}
        open={open}
        sx={{
          "& .MuiDialog-paper": {
            minWidth: "600px",
          },
        }}
      >
        <DialogTitle
          sx={{
            m: 0,
            p: 2,
            fontColor: "#000000",
            fontWeight: 700,
            fontSize: "20px",
            fontFamily: "Open Sans",
            lineHeight: "27px",
          }}
        >
          {DialogViewTitles[view]}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <StyledDivider />
        <DialogContent>{dialogComponent}</DialogContent>
      </BootstrapDialog>
    </>
  );
};

export default OrganizationDialog;
