import { useState } from "react";

// UI - libs
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Paper,
  Switch,
  TextField,
  Tooltip,
} from "@mui/material";

// UI - internal
import DatePicker from "../../components/lib/SafeLeaseDatePicker";
import { Image } from "../../image";
import { SafeleaseDropdown } from "../../common";

// Data
import SetupDataService from "../../services/setup.service";
import { LocationSettingsErrors } from "../../shared/LocationSettingsModal";
import { Location } from "../../utilities/generated/gql-types";

interface LocationSettingsKeyDatesProps {
  loading: boolean;
  setLoading: (val: boolean) => void;
  location: Location;
  setErrors: (errors: LocationSettingsErrors) => void;
  setErrorMessage: (message: string) => void;
  onClose: () => void;
}

export default function KeyDates({
  loading,
  setLoading,
  location,
  setErrors,
  setErrorMessage,
  onClose,
}: LocationSettingsKeyDatesProps) {
  const [liveDate, setLiveDate] = useState<Date>(location.liveDate);
  const [processRollover, setProcessRollover] = useState<boolean>(
    location.processRollover
  );
  const [rolloverDate, setRolloverDate] = useState<Date>(location.rolloverDate);
  const [autoEnrollNewEnabled, setAutoEnrollNewEnabled] = useState<boolean>(
    location.autoEnrollNewEnabled
  );
  const [autoEnrollNewEffectiveDate, setAutoEnrollNewEffectiveDate] =
    useState<Date>(location.autoEnrollNewEffectiveDate);
  const [autoEnrollGracePeriod, setAutoEnrollGracePeriod] = useState<number>(
    location.autoEnrollGracePeriod
  );
  const [autoEnrollExistingEnabled, setAutoEnrollExistingEnabled] =
    useState<boolean>(location.autoEnrollExistingEnabled);
  const [autoEnrollExistingEffectiveDate, setAutoEnrollExistingEffectiveDate] =
    useState<Date>(location.autoEnrollExistingEffectiveDate);
  const [firstChargeNextBillingCycle, setFirstChargeNextBillingCycle] =
    useState<boolean>(location.firstChargeNextBillingCycle);

  const saveLocationDates = async function () {
    if (!window.confirm("Are you sure you want to save the dates?")) return;

    setLoading(true);
    setErrors({});
    const response = await SetupDataService.addLocation({
      ...location,
      liveDate,
      processRollover,
      rolloverDate,
      autoEnrollNewEnabled,
      autoEnrollNewEffectiveDate,
      autoEnrollGracePeriod:
        autoEnrollGracePeriod === 0 ? null : autoEnrollGracePeriod,
      autoEnrollExistingEnabled,
      autoEnrollExistingEffectiveDate,
      firstChargeNextBillingCycle,
    });
    setLoading(false);

    if (response.data.errors) {
      setErrors(response.data.errors);
      setErrorMessage("A problem occurred while saving the location.");
      return;
    }

    onClose();
  };

  const gracePeriodOptions = {
    "No Grace Period": 0,
  };
  for (var i = 1; i <= 14; i++) {
    const days = i > 1 ? "days" : "day";
    gracePeriodOptions[`${i} ${days}`] = i;
  }

  return (
    <>
      <Paper
        sx={{
          maxHeight: 400,
          overflow: "auto",
          backgroundColor: "inherit",
          boxShadow: "none",
          padding: 1,
        }}
      >
        <div className="tw-flex tw-flex-col tw-items-start">
          <DatePicker
            label="Live Date"
            value={liveDate}
            onChange={(newDate) => setLiveDate(newDate)}
            disabled={new Date(liveDate) < new Date()}
          />

          <FormControlLabel
            labelPlacement={"start"}
            label={
              <div>
                Are you transitioning from another coverage provider? (Rollover)
                <Tooltip
                  placement="top"
                  title="This is the date that your previous coverage provider will no longer be providing coverage for your tenants. This should typically be 30-60 days after a cancellation notice has been delivered."
                >
                  <Button className="info-tooltip" sx={{ m: 1 }}>
                    <span className="badge">
                      <Image src="/images/tooltip-icon.svg" alt="warning" />
                    </span>
                  </Button>
                </Tooltip>
              </div>
            }
            control={
              <Switch
                checked={processRollover}
                onChange={(e) => setProcessRollover(e.target.checked)}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
            style={{ marginLeft: 0 }}
          />

          <DatePicker
            label="Rollover Date"
            value={rolloverDate}
            onChange={(newDate) => setRolloverDate(newDate)}
            disabled={!processRollover}
          />

          <FormControlLabel
            labelPlacement={"start"}
            label={
              <div>
                Will all new tenants be required to have coverage? (AP New)
                <Tooltip
                  placement="top"
                  title={[
                    "This is the effective move-in date for new tenants to be required to have coverage. All tenants moving in before this date will not be required to have coverage until all tenants are required to be protected.",
                    <br />,
                    "A grace period can be set to allow new tenants time to provide an existing policy. Once the grace period has ended, a new tenant without protection will be auto-enrolled in your lowest cost SafeLease plan. ",
                  ]}
                >
                  <Button className="info-tooltip" sx={{ m: 1 }}>
                    <span className="badge">
                      <Image src="/images/tooltip-icon.svg" alt="warning" />
                    </span>
                  </Button>
                </Tooltip>
              </div>
            }
            control={
              <Switch
                checked={autoEnrollNewEnabled}
                onChange={(e) => setAutoEnrollNewEnabled(e.target.checked)}
              />
            }
            style={{ marginLeft: 0 }}
          />

          <div>
            <DatePicker
              label="Effective Date"
              value={autoEnrollNewEffectiveDate}
              onChange={(newDate) => setAutoEnrollNewEffectiveDate(newDate)}
              disabled={!autoEnrollNewEnabled}
            />

            <SafeleaseDropdown
              disabled={!autoEnrollNewEffectiveDate}
              value={Object.keys(gracePeriodOptions).find(
                (key) =>
                  gracePeriodOptions[key] === (autoEnrollGracePeriod || 0)
              )}
              menuOptionList={Object.keys(gracePeriodOptions)}
              onChange={(e) =>
                setAutoEnrollGracePeriod(gracePeriodOptions[e.target.value])
              }
              selectStyles={{
                marginLeft: 5,
                marginBottom: 0,
                width: "inherit",
                maxHeight: "inherit",
                fontSize: "initial",
              }}
            />
          </div>

          <FormControlLabel
            labelPlacement={"start"}
            label={
              <div>
                Will all existing tenants be required to have coverage? (AP All)
                <Tooltip
                  placement="top"
                  title="This is the date that the insurance requirement comes into effect. If no insurance is found on their account, they will be auto-enrolled in your lowest cost SafeLease plan."
                >
                  <Button className="info-tooltip" sx={{ m: 1 }}>
                    <span className="badge">
                      <Image src="/images/tooltip-icon.svg" alt="warning" />
                    </span>
                  </Button>
                </Tooltip>
              </div>
            }
            control={
              <Switch
                checked={autoEnrollExistingEnabled}
                onChange={(e) => setAutoEnrollExistingEnabled(e.target.checked)}
              />
            }
            style={{ marginLeft: 0 }}
          />

          <div className="tw-flex">
            <DatePicker
              label="Effective Date"
              value={autoEnrollExistingEffectiveDate}
              onChange={(newDate) =>
                setAutoEnrollExistingEffectiveDate(newDate)
              }
              disabled={!autoEnrollExistingEnabled}
            />
            {["storedge", "wss", "ess", "ssm"].includes(location.accountType) && (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={firstChargeNextBillingCycle}
                    onChange={(e) =>
                      setFirstChargeNextBillingCycle(e.target.checked)
                    }
                  />
                }
                label={
                  <div className="account-migration-label">
                    First charge next billing cycle
                    <Tooltip
                      placement="top"
                      title="Tenants will be charged for insurance immediately. Checking this box will charge tenants $0 immediately and delay charging until the first day of their next billing cycle."
                    >
                      <Button className="info-tooltip" sx={{ m: 1 }}>
                        <span className="badge">
                          <Image src="/images/tooltip-icon.svg" alt="warning" />
                        </span>
                      </Button>
                    </Tooltip>
                  </div>
                }
                style={{ marginLeft: 30 }}
              />
            )}
          </div>
        </div>
      </Paper>
      {loading ? (
        <CircularProgress />
      ) : (
        <Button className="modal-login-button" onClick={saveLocationDates}>
          Save Dates
        </Button>
      )}
    </>
  );
}
