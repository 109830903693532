import ServiceAbstract from './abstract';
import http from "./http-common";

class BlockedUnitDataService extends ServiceAbstract {
  update(locationId: number) {
    return http.post("/blocked_units/update", {locationId: locationId});
  }

  bulkUpdate(locationId: number, blockedUnits: any[]) {
    return http.post("/blocked_units/bulkupdate", {locationId: locationId, blockedUnits: blockedUnits});
  }
}

export default new BlockedUnitDataService();
