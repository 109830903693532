const defaultEnabledFmsPrivatePolicyComplianceConfigs = {
  fmsValidPolicyNumber: true,
  fmsExpirationDate: true,
};
const defaultDisabledFmsPrivatePolicyComplianceConfigs = {
  fmsValidPolicyNumber: false,
  fmsExpirationDate: false,
};

const defaultEnabledInternalPrivatePolicyComplianceConfigs = {
  intPoliciesOnly: true,
  intCompliantDeclaration: true,
};
const defaultDisabledInternalPrivatePolicyComplianceConfigs = {
  intPoliciesOnly: false,
  intCompliantDeclaration: false,
};


export {
  defaultEnabledFmsPrivatePolicyComplianceConfigs,
  defaultDisabledFmsPrivatePolicyComplianceConfigs,
  defaultEnabledInternalPrivatePolicyComplianceConfigs,
  defaultDisabledInternalPrivatePolicyComplianceConfigs
};