import { Card, Stack, Typography } from "@mui/material";
import ModelValueRenderer from "./ModelValueRenderer";

const OpportunityDataVisualizer = (props: { opportunityData: OpportunitySyncMetaData; }) => {
  const { opportunityData } = props;
  const dataToMap = opportunityData.existingId ? opportunityData.existingData : opportunityData.newData;

  return (
    <div>
      <Card style={{ padding: "20px", display: "inline-block", marginBottom: "10px" }}>
        <div style={{ marginBottom: "15px" }}>
          {opportunityData.existingId && (
            <h5>{`Updates for Opportunity ID - ${opportunityData.existingId}:`}</h5>
          )}
          {opportunityData.createNew && (
            <h5>This Opportunity does not exist yet. This will create a new Opportunity with this data:</h5>
          )}
        </div>
        <Stack sx={{ paddingLeft: "10px" }} spacing={2}>
          {Object.keys(dataToMap).map((key) => {
            // Exclude contractEffectiveDate from being displayed
            if (key !== 'contractEffectiveDate') {
              return (
                <Stack sx={{ padding: "15px", border: "0.5px solid #c7c7c7" }} key={key} spacing={2} direction='row' alignItems="center">
                  <Typography sx={{ fontWeight: "bold", fontSize: 20 }}>{key}:</Typography>
                  <ModelValueRenderer value={opportunityData.existingData[key]} sx={{ fontSize: 20 }} />
                  <Typography sx={{ fontWeight: "bold", fontSize: 20 }}>{'-->'}</Typography>
                  <ModelValueRenderer value={opportunityData.newData[key]} sx={{ color: "#b52a21", fontSize: 20, fontWeight: "bold" }} />
                </Stack>
              );
            }
            return null;
          })}
        </Stack>
      </Card>
    </div>
  );
};

export default OpportunityDataVisualizer;
