import React, { forwardRef, useState } from "react";
import classNames from 'classnames';
import { TextField, Button, MenuItem, Modal, Paper, IconButton, FormControlLabel, Checkbox } from "@mui/material";
import { muiTextField } from "./styles/mui-overrides";
import CircularProgress from "@mui/material/CircularProgress";
import DatePicker from "./components/lib/SafeLeaseDatePicker";
import AttachmentDataService from "./services/attachments.service";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css'

const FormContext = React.createContext();


function Form(props) {
  const [errors, setErrors] = useState({});
  const [values, setValues] = useState({...props.values} || {});
  const [busy, setBusy] = useState(false);
  const [successMessageOpen, setSuccessMessageOpen] = useState(false);
  const [file, setFile] = useState(null);
    
  const handleInputChange = (e) => {
    if(e.target.files) {
      var file = e.target.files[0];
      setFile(file);
    }

    setValues({
      ...values,
      [e.target.name]: e.target.value,
    })
  };

  const onFormSubmit = async (e) => {
    e.preventDefault();
    
    if(busy) return false; // Prevent double submits.

    setBusy(true);

    var formdata = new FormData();
    for(var [key, value] of Object.entries(values)) {
      if(typeof value === 'string' && !value.trim()){// keep blank strings from counting as valid values
        continue;
      }
      formdata.append(key, value);
    }

    let isValid = false;
    if(file) {
      const filename = await AttachmentDataService.directUpload(file);
      formdata.append('attachment_filename', filename);
      formdata.append('attachment_desired_filename', file.name);
      formdata.append('attachment_mime_type', file.type);
    }

    props.onFormAttempt(formdata, (response) => {
      if(response.data.errors) {
        setErrors(response.data.errors);
      } else {
        setErrors({});
        if(props.onFormSuccess) {
          props.onFormSuccess(response);
        }

        setValues({});
        setSuccessMessageOpen(true);
      }
      setBusy(false);
    });
    return false;
  };

  return (
    <form className={classNames({busy})}>
      {!props.disablePopupSuccessMessage && 
        <SuccessPopup open={successMessageOpen} onCloseCallback={() => setSuccessMessageOpen(false)} >
          {props.customPopupContent}
        </SuccessPopup>
      }
      <FormContext.Provider value={{handleInputChange, errors, values}}>
        {props.children}
      </FormContext.Provider>
      {props.submitLabel && 
       <div className="buttons" style={props.buttonClassOverride}>
        <Button variant="contained" onClick={onFormSubmit}>
          <span className="busy-indicator">
            <CircularProgress size="13px" sx={{color:"white", marginRight: "10px"}}/>{' '}
          </span>
          {props.submitLabel}
        </Button>
      </div>
      }
    
      {props.submitOptions && props.submitOptions(onFormSubmit)}
    </form>
  );
}


function FormElement(props) {
  var id = `input-${props.name}`;

  return (
    <FormContext.Consumer>
      {data => (
        <div>
          {
            props.type === 'date' ? (
              <DatePicker
                clearable
                label={props.label}
                value={data.values[props.name] || ''}
                onChange={(value) => data.handleInputChange({ target: { name: props.name, value: value } })}
                error={!!data.errors[props.name]}
                slotProps={{
                  textField: {
                    error: !!data.errors[props.name],
                    helperText: data.errors[props.name]?.msg || props.helperText,
                    sx: muiTextField
                  }
                }}
              />
            ) : props.type === 'phone number' ? (
              <PhoneInput
                placeholder="Enter phone number"
                defaultCountry="US"
                international
                value={data.values[props.name] || ''}
                onChange={(value) => data.handleInputChange({ target: { name: props.name, value: value } })}
                inputComponent={MuiTextFieldForPhone}
                error={!!data.errors[props.name]}
                helperText={data.errors[props.name]?.msg || props.helperText}
                disabled={props.disabled}
              />
            ) :
              (
                <TextField label={props.label}
                  type={props.type || 'text'}
                  name={props.name}
                  value={data.values[props.name] || ''}
                  onChange={data.handleInputChange}
                  sx={props.textFieldStylingOveride ?? muiTextField}
                  multiline={props.type === 'textarea'}
                  helperText={data.errors[props.name]?.msg || props.helperText}
                  error={!!data.errors[props.name]}
                  inputProps={props.inputProps}
                  disabled={props.disabled}
                  autoFocus={props.autoFocus || false}
                  InputLabelProps={{ shrink: props.type == 'file' || undefined }}
                  select={props.type == 'select'}
                  FormHelperTextProps={props.helperTextStyles ? { 'sx': props.helperTextStyles } : undefined}
                >
                  {props.type == 'select' && props.blank &&
                    <MenuItem value="">{props.blank}</MenuItem>
                  }
                  {props.type == 'select' && props.options.map((key, i) => {
                    var label = key;
                    if (props.labels) {
                      label = props.labels[key];
                    }
                    return (
                      <MenuItem key={key} value={key}>{label}</MenuItem>
                    );
                  })}
               }
                </TextField>
              )
          }
        </div>
      )}
    </FormContext.Consumer>
  );
}

const MuiTextFieldForPhone = forwardRef((props, ref) => {
  return (
    <TextField
      sx={{ marginLeft: 1 }}
      error={props.error}
      {...props}
      inputRef={ref}
      fullWidth
      label='Phone Number'
      variant='outlined'
      name='phone'
    />
  )
});


const SuccessPopup = (props) => {

  const { open, onCloseCallback, children } = props;

  return (
    <Modal
      onClose={onCloseCallback}
      open={open}
      sx={{ display: "flex", justifyContent: "center", alignItems: "center", padding: 2 }}
    >
      <Paper sx={{ minWidth: "150px", minHeight: "100px" }}>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: "-15px",
            padding: 1,
          }}
        >
          <IconButton onClick={onCloseCallback}>
            <CloseIcon />
          </IconButton>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <CheckCircleIcon sx={{ color: "green", fontSize: "40px" }} />
        </div>
        {children ? (
          <>{children}</>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "15px",
            }}
          >
            Success!
          </div>
        )}
      </Paper>
    </Modal>
  );
};

export { Form, FormContext, FormElement };
