import { KeyboardArrowRight, Lens } from "@mui/icons-material";
import { Stack, Theme, Typography } from "@mui/material";

interface PendingReviewsCounterProps {
  count?: number;
}

export function PendingReviewsCounter({
  count = 0,
}: PendingReviewsCounterProps) {
  return (
    <Stack
      direction="row"
      spacing={1}
      alignItems="center"
      sx={{ color: (theme: Theme) => theme.palette.grey.A100 }}
    >
      {count > 0 && (
        <Lens
          sx={{
            height: 10,
            width: 10,
            color: (theme: Theme) => theme.palette.yellow.main,
          }}
        />
      )}
      <Typography
        variant="subtitle2"
        sx={{ color: (theme: Theme) => theme.palette.grey.A100 }}
      >
        <Typography
          component="span"
          variant="subtitle2"
          sx={{
            color: (theme: Theme) => theme.palette.navy.main,
            fontWeight: 700,
          }}
        >
          {count}
        </Typography>{" "}
        reviews pending reply
      </Typography>
      <KeyboardArrowRight sx={{ height: 18, width: 'auto' }} />
    </Stack>
  );
}
