import React from "react";
import { FlexColumnBox, FlexRowBox, RowTitleText, RowSubTitleText, TextFieldLabel, StyledTextField } from "../MyAccountStyledComponents";

interface ContactDetailsSectionProps {
  email: string;
  phone: string;
  handleTextFieldChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const ContactDetailsSection: React.FC<ContactDetailsSectionProps> = ({ email, phone, handleTextFieldChange }) => {
  return (
    <FlexRowBox>
      <FlexColumnBox
        sx={{
          width: "149px",
        }}
      >
        <RowTitleText>Contact Details</RowTitleText>
        <RowSubTitleText>Keep your email and phone number up to date</RowSubTitleText>
      </FlexColumnBox>
      <FlexColumnBox
        sx={{
          marginLeft: "80px",
        }}
      >
        <TextFieldLabel>Email</TextFieldLabel>
        <StyledTextField id="email" onChange={handleTextFieldChange} value={email} />
      </FlexColumnBox>
      <FlexColumnBox
        sx={{
          marginLeft: "12px",
        }}
      >
        <TextFieldLabel>Phone</TextFieldLabel>
        <StyledTextField id="phone" onChange={handleTextFieldChange} value={phone} />
      </FlexColumnBox>
    </FlexRowBox>
  );
};

export default ContactDetailsSection;
