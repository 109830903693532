// UI - libs
import {
  Button,
  IconButton,
  List,
  Stack,
  SxProps,
  Tooltip,
  Typography,
} from "@mui/material";
import { Edit as EditIcon } from "@mui/icons-material";
import { useSnackbar } from "notistack";

// UI - internal
import {
  ghostButtonNavy,
  primaryColorButton,
} from "../../../styles/mui-overrides";

// Hooks & State
// import { useJobsErrorCenter } from "../useJobsErrorCenter";
import LoadingButton from "@mui/lab/LoadingButton";
// import JobDetailsDrawerListItem from "./JobDetailsDrawerListItem";
import { SubmitHandler, useForm } from "react-hook-form";

// Data
import _ from "lodash";
import { useState } from "react";
import { PrivatePolicy } from "../../../utilities/generated/gql-types";
import PrivatePolicyDeclarationsPageFormListItem from "./PrivatePolicyDetailsFormListItem";
import { PrivatePolicyValidationStatus } from "../../../reports/useReportsStore/privatePolicyReportSlice";

interface IFormInput {}

interface PrivatePolicyDetailsFormProperties {
  label: string; // human readable label
  propertyName: string;
  renderer?: (value: any, sx: SxProps) => JSX.Element | string; // conditional render (for chips, bold, etc)
  editable?: boolean; // is this section editable
  asDropdown?: boolean;
  options?: { value: string; label: string }[];
}

interface PrivatePolicyDetailsFormProps {
  label: string;
  privatePolicy: PrivatePolicy & { privatePolicyValidationStatus: PrivatePolicyValidationStatus }
  properties: PrivatePolicyDetailsFormProperties[];
  editable?: boolean;
}

/* Currently not enabled as a placeholder as of 2023-11-3 */
/* Foundation is there for if we want to enable editing */
/* For now, just display the information */
export default function PrivatePolicyDetailsForm({
  label,
  properties,
  privatePolicy,
  editable = false,
}: PrivatePolicyDetailsFormProps) {

  const [isEditing, setIsEditing] = useState<boolean>(false);

  const [submittingForm, setSubmittingForm] = useState(false);

  const { handleSubmit, control } = useForm({
    defaultValues: {
      expirationDate: privatePolicy?.expiration
    }
  });

  // On click of edit button, let all sections know this one is being edited
  const handleBeginEditingSection = () => {
    setIsEditing(true);
  };

  // Save section updates to database
  const handleSaveSection: SubmitHandler<IFormInput> = async (values) => {
    setSubmittingForm(true);
  };

  if (!privatePolicy) return null;

  return (
    <form onSubmit={handleSubmit(handleSaveSection)}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ mt: 2 }}
      >
        <Typography variant="body1" sx={{ fontWeight: "bold" }}>
          {label}
        </Typography>
        {editable && !isEditing && (
          <Tooltip title="Edit section" arrow placement="left">
            <IconButton
              size="small"
              onClick={handleBeginEditingSection}
              disabled={!isEditing}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
        )}
        {editable && isEditing && (
          <Stack direction="row" spacing={1}>
            <Button
              variant="text"
              sx={{ textTransform: "none" }}
              disabled={submittingForm}
              onClick={() => setIsEditing(false)}
            >
              Cancel
            </Button>
            <LoadingButton
              size="small"
              sx={{ ...primaryColorButton, height: 34 }}
              type="submit"
              loading={submittingForm}
              disabled={submittingForm}
            >
              Save
            </LoadingButton>
          </Stack>
        )}
      </Stack>
      <List>
        {properties.map(({ ...rest }, i) => (
          <PrivatePolicyDeclarationsPageFormListItem
            privatePolicy={privatePolicy}
            key={i}
            isEditingSection={isEditing}
            control={control}
            {...rest}
          />
        ))}
      </List>
    </form>
  );
}
