import { create } from "zustand";
import { persist } from "zustand/middleware";

interface AdminToolsStoreState {

  /** List of favorite tools */
  favorites: string[];

  /** Toggle a tool as favorite or non favorite  */
  toggleFavorite: (id: string) => void; 

  /** Clear all favorites */
  clearFavorites: () => void;

  /** View mode: list is list items, grid is squares, split has favorites in a grid at the top and list underneath*/
  mode: "list" | "grid" | "split";

  /** Switch view mode */
  toggleMode: (mode:  "list" | "grid" | "split") => void;
}

/** Manage the state of favorite tools / layout of tools. Persists locally in the browser. */
export const useAdminToolsStore = create<AdminToolsStoreState>()(
  persist(
    (set, get) => ({
      favorites: [],
      toggleFavorite: (id: string) => {
        const favorites = get().favorites;
        const index = favorites.indexOf(id);
        if (index === -1) {
          set({ favorites: [...favorites, id] });
        }
        else {
          set({ favorites: favorites.filter(fav => fav !== id) });
        }
      },
      clearFavorites: () => set({ favorites: [] }),

      mode: "grid",
      toggleMode: (mode) => set({ mode }),
    }),
    {
      name: 'admin-tools-store'
    }
  ),
)