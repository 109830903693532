import Button from '@mui/material/Button';
import { Relationship } from '../utilities/generated/gql-types';
import { QueryResult } from '@apollo/client'
import { Attachment } from '../utilities/generated/gql-types'
import AttachmentsDataService from "../services/attachments.service";
import { getFileTypeInfo } from '../utilities/field-enums'
import { useOtpStore } from '../utilities/otp-state';
import IconButton from '@mui/material/IconButton';
import LockIcon from '@mui/icons-material/Lock';
import DeleteIcon from '@mui/icons-material/Delete';

function DocumentActions(props: {
  id: number,
  documentsQuery: QueryResult,
  document: Attachment
}) {
  const { id, documentsQuery, document } = props

  const { otpVerified } = useOtpStore(
    (state) => ({
      otpVerified: state.otpVerified,
    })
  )

  const deleteDocument = (document: Attachment) => 
    async (event) => {
      event.stopPropagation()
      await AttachmentsDataService.delete({id: document.id})
      documentsQuery.refetch()
    }

  return (
    <div className='tw-flex'>
      <Button onClick={deleteDocument(document)}
          disabled={getFileTypeInfo()[document.fileType].otpRequiredToUpload && !otpVerified}>
        <DeleteIcon titleAction="Delete"/>
      </Button>
      {getFileTypeInfo()[document.fileType].otpRequiredToUpload && !otpVerified &&
        <IconButton
          color="error"
          onClick={() => {
            window.location.replace( '/secure-redirect' + '?' + 
                new URLSearchParams({ 
                  safeleaseRoute: '/documents',
                  relationshipId: document.relationshipId.toString(),
                  fileType: document.fileType,
                }))
          }} >
          <LockIcon />
        </IconButton>
      }
    </div>
  )
}

export function documentActionsRenderer(
    id: number,
    tableInfo: {row: Attachment, queryResult: QueryResult}
) {
  return (
    <DocumentActions id={id}
        documentsQuery={tableInfo.queryResult}
        document={tableInfo.row} />
  )
}
