import { ReportType, ReportTypeOptions, SelectOptions } from '../../utilities/field-enums';
import { insurancePenetrationColumns, dailySummaryColumns, occupancySummaryColumns } from '../../utilities/column-defs';
import { dailySummaryTotals, insurancePenetrationTotals, occupancySummaryTotals } from './totals-defs';
import { ColumnDef } from '../../utilities/column-defs';

export type ReportDef = {
  reportTypeOption: SelectOptions<ReportType>;
  columns: ColumnDef[],
  datePickerType: DatePickerType, // if false, date filters will be by day,
  totalDefs: string[],
  defaultQueryDate: { startDate: Date, endDate?: Date },
}

export enum DatePickerType {
  Day = 'Day',
  Month = 'Month',
  Year = 'Year',
}

export const reportDefs: ReportDef[] = [
  {
    reportTypeOption: ReportTypeOptions.find(option => 
        option.value === ReportType.InsurancePenetration),
    columns: insurancePenetrationColumns,
    datePickerType: DatePickerType.Month,
    totalDefs: insurancePenetrationTotals,
    defaultQueryDate: {
      startDate: (() => { // default the start day to the beginning of the month
        const date = new Date();
        // date.addMinutes(date.getTimezoneOffset());
        date.setDate(1);
        return date;
      })(),
      endDate: (() => { // default the end day to the end of the month
        const date = new Date();
        // date.addMinutes(date.getTimezoneOffset());
        date.setMonth(date.getMonth() + 1);
        date.setDate(0);
        return date;
      })(),
    },
  },
  {
    reportTypeOption: ReportTypeOptions.find(option => 
        option.value === ReportType.DailySummary),
    columns: dailySummaryColumns,
    datePickerType: DatePickerType.Day,
    totalDefs: dailySummaryTotals,
    defaultQueryDate: {
      startDate: (() => { // default the start date to yesterday
        const date = new Date();
        // date.addMinutes(date.getTimezoneOffset());
        date.setDate(date.getDate() - 1);
        return date;
      })(),
    },
  },
  {
    reportTypeOption: ReportTypeOptions.find(option => 
      option.value === ReportType.OccupancySummary),
      columns: occupancySummaryColumns,
    datePickerType: DatePickerType.Day,
    totalDefs: occupancySummaryTotals,
    defaultQueryDate: { // default the start date to today
      startDate: (() => { 
        const date = new Date();
        // date.addMinutes(date.getTimezoneOffset());
        return date;
      })(),
    },
  },
]


