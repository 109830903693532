import { TimelineItem, TimelineItemProps, TimelineSeparator } from "@mui/lab";
import { CampaignTimelineConnector, CampaignTimelineDot } from "../CampaignConfigurationTimeline";
import { IconButton, Stack, Typography } from "@mui/material";
import { CampaignStep_Reputation as CampaignStep } from "@safelease/service-utilities";
import { DeleteOutline } from "@mui/icons-material";
import { useReputation } from "../../../useReputation";
import { useFormContext } from "react-hook-form";
import { CampaignSMSConfigurationStepContent } from "./CampaignSMSConfigurationStepContent";
import { AdminsOnly } from "../../../components/AdminsOnly";

interface CampaignConfigurationStepProps extends TimelineItemProps {
  campaignId: string;
  campaignStep: Partial<CampaignStep>;
  stepIndex: number;
  // Remove a step from the form
  handleRemoveStep: (stepIndex: number) => void;
}

export function MobileCampaignConfigurationStep({ campaignId, campaignStep, stepIndex, handleRemoveStep }: CampaignConfigurationStepProps) {
  const { watch } = useFormContext();
  const { statuses } = useReputation();

  return (
    <TimelineItem>
      <TimelineSeparator>
        <CampaignTimelineConnector style={{ backgroundColor: "#EBEFF7", width: 1 }} />
        <CampaignTimelineDot>
          <Typography>Text</Typography>
        </CampaignTimelineDot>
        <CampaignTimelineConnector />
      </TimelineSeparator>
      <Stack direction="row" alignItems="center" spacing={1}>
        <CampaignSMSConfigurationStepContent
          campaignId={campaignId}
          campaignStep={campaignStep}
          stepIndex={stepIndex}
          handleRemoveStep={handleRemoveStep}
        />
        {watch("campaignSteps").length > 1 && (
          <IconButton size="small" onClick={() => handleRemoveStep(stepIndex)} disabled={statuses.campaignConfigurationForm}>
            <AdminsOnly>
              <DeleteOutline color="error" />
            </AdminsOnly>
          </IconButton>
        )}
      </Stack>
    </TimelineItem>
  );
}
