import { KeyboardArrowDown, PinDropOutlined } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AvatarGroup,
  Box,
  Checkbox,
  Stack,
  Theme,
  Typography,
  alpha,
  useTheme,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { SafeLeaseLetterAvatar } from "@safelease/components";
import _ from "lodash";
import { usePerformanceBoard } from "../usePerformanceBoard";
import { EmployeeSelector } from "./EmployeeSelector";

export function FacilityTeamAccordion({ facility }) {
  const theme = useTheme();
  const { selectedUsers, updateUserSelectionStatus } = usePerformanceBoard();

  if (!selectedUsers[facility.id]) return null;

  const facilityUsers = selectedUsers[facility.id];

  // Find only users of this facility, if all users are selected, then the facility is checked
  const numSelectedUsersAtFacility = Object.values(selectedUsers[facility.id]).filter((selectedUserBool) => selectedUserBool).length;

  const allFacilityUsersSelected = numSelectedUsersAtFacility === facility.users.length;
  const partialFacilityUsersSelected = numSelectedUsersAtFacility > 0 && !allFacilityUsersSelected;
  const noFacilityUsersSelected = numSelectedUsersAtFacility === 0;
  const someFacilityUsersSelected = partialFacilityUsersSelected || allFacilityUsersSelected;

  // Handle selecting the facility-level checkbox that influences status of all users at that facility
  const handleFacilityCheckboxClick = (event) => {
    event.stopPropagation();
    event.preventDefault();

    if (allFacilityUsersSelected || noFacilityUsersSelected) {
      Object.keys(facilityUsers).forEach((userId) => {
        updateUserSelectionStatus(userId, facility.id);
      });
    }

    // Turn on all users if only some are selected
    if (partialFacilityUsersSelected) {
      Object.keys(facilityUsers).forEach((userId) => {
        if (!facilityUsers[userId]) {
          updateUserSelectionStatus(userId, facility.id);
        }
      });
    }
  };

  return (
    <Accordion
      elevation={0}
      sx={{
        py: 0,
        "&::before": {
          backgroundColor: "transparent",
        },
        "& .Mui-disabled": {
          bgcolor: facility.users.length === 0 ? grey[50] : "transparent",
        },
      }}
      disabled={facility.users.length === 0}
    >
      <AccordionSummary
        expandIcon={<KeyboardArrowDown />}
        sx={{
          py: 0,
          border: "1px solid",
          borderColor: someFacilityUsersSelected ? theme.palette.navy.main : grey[200],
          bgcolor: someFacilityUsersSelected ? alpha(theme.palette.navy.main, 0.025) : "transparent",
          position: "initial",
          borderRadius: 1,
        }}
      >
        <Stack direction="row" alignItems="center" sx={{ width: "100%" }}>
          <Checkbox
            onClick={handleFacilityCheckboxClick}
            checked={allFacilityUsersSelected && facility.users.length > 0}
            indeterminate={partialFacilityUsersSelected && facility.users.length > 0}
          />
          <Stack direction="row" spacing={1} alignItems="center">
            <PinDropOutlined sx={{ color: (theme: Theme) => theme.palette.navy.main }} />
            <Typography>{facility.companyName ?? facility.address}</Typography>
          </Stack>
          <AvatarGroup max={3} sx={{ ml: "auto" }}>
            {facility.users.map((user) => (
              <SafeLeaseLetterAvatar key={user.id} name={user.name} />
            ))}
          </AvatarGroup>
          <Typography variant="body1" sx={{ mx: 1 }}>
            {facility.users.length} {facility.users.length === 1 ? "employee" : "employees"}
          </Typography>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        <Box>
          <Stack direction="column" spacing={2}>
            {facility.users
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((user) => (
                <EmployeeSelector user={user} facilityId={facility.id} key={user.id} />
              ))}
          </Stack>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}
