import { useState, useEffect } from "react";
import SetupDataService from "../services/setup.service";
import { fmsData } from "../utilities/fms-data";
import { Image } from "../image";
import { muiStatisticTooltip } from "../styles/mui-overrides";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  IconButton,
  Modal,
  Paper,
  Tooltip,
} from "@mui/material";
import { useApolloClient, useQuery } from "@apollo/client";
import { getAccount, getLocations } from "../queries";
import Error from "./Error";
import _ from "lodash";
import { closeSnackbar, enqueueSnackbar } from "notistack";
import ConfirmDialog from "../salesforce/sharedComponents/ConfirmDialog";

export default function AccountLocationsModal(props) {
  const { account, onClose, setAlertMessage } = props;
  const client = useApolloClient();

  const [serviceIds, setServiceIds] = useState<string[]>(
    account.locations?.map((location) => location.serviceId) || [],
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  const locationsQuery = useQuery(getLocations, {
    skip: !account.relationshipId,
    variables: { relationshipId: account.relationshipId },
  });
  if (locationsQuery.loading) return <CircularProgress />;
  if (locationsQuery.error) return <Error />;
  const locations = locationsQuery.data.getLocations;

  const handleConfirmDialogOpen = async () => {
    if (account.locations.some(location => !locations.find(l => l.serviceId === location.serviceId))) {
      setDialogOpen(true);
    } else {
      await matchAccountLocations();
    }
  };

  const handleConfirmDialogClose = () => {
    setDialogOpen(false);
  };

  const handleConfirmSync = async () => {
    setDialogOpen(false);
    await matchAccountLocations();
  };

  const matchAccountLocations = async function () {
    setLoading(true);
    try {
      const response = await SetupDataService.createAndMatchAccountLocations(
        account.id,
        serviceIds,
      );
      setAlertMessage({
        message: `Successfully processed your ${
          fmsData[account.type].name
        } locations.${
          response.data.matchedLocations || response.data.createdLocations
            ? ` ${response.data.matchedLocations} SafeLease location${
                response.data.matchedLocations > 1 ? "s were" : " was"
              } updated, and ${
                response.data.createdLocations
              } SafeLease location${
                response.data.matchedLocations > 1 ? "s were" : " was"
              } created.`
            : ""
        }`,
        severity: "success",
      });
      client.refetchQueries({ include: [getAccount, getLocations] });
      onClose();
    } catch (e) {
      enqueueSnackbar(
        `Failed to process your ${fmsData[account.type].name} locations.`,
        { variant: "error" },
      );
    } finally {
      setLoading(false);
    }
  };

  const handleCheck = async (serviceId, checked) => {
    const tempServiceIds = [...serviceIds];
    checked
      ? tempServiceIds.push(serviceId)
      : tempServiceIds.splice(tempServiceIds.indexOf(serviceId), 1);
    setServiceIds(tempServiceIds);
  };

  const accountLocationDisplayName = (accountLocation) => {
    return `${accountLocation.name} - ${accountLocation.address}, ${
      accountLocation.city
    }, ${accountLocation.state}${
      accountLocation.zip ? ` ${accountLocation.zip}` : ""
    } (${accountLocation.serviceId})`;
  };

  const accountLocations =
    account.locations?.length > 0
      ? _.sortBy(account.locations, (accountLocation) => {
          if (
            locations.find(
              (location) => location.serviceId === accountLocation.serviceId,
            )
          )
            return 1;
          else return 0;
        })
      : [];

  return (
    <div>
      <Modal
        open={true}
        onClose={() => onClose()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="popup-modal"
      >
        <Box className="modal-box" style={{ width: 700 }}>
          <div className="tw-flex tw-justify-center tw-w-full">
            <Image
              src={fmsData[account.type].image}
              alt={fmsData[account.type].name}
              style={{ width: 40, marginRight: 10 }}
            />
            <h3>{fmsData[account.type].name}</h3>
          </div>
          <div className="tw-flex tw-justify-center tw-mt-5">
            Select the locations to connect to SafeLease.
            <Tooltip
              placement="bottom"
              title="SafeLease will automatically match and connect the selected location(s)."
              componentsProps={{
                tooltip: {
                  sx: { backgroundColor: "#152744" },
                },
              }}
            >
              <Button sx={muiStatisticTooltip}>
                <span className="badge">
                  <Image src="/images/tooltip-icon.svg" alt="warning" />
                </span>
              </Button>
            </Tooltip>
          </div>

          <Paper
            sx={{
              maxHeight: 400,
              overflow: "auto",
              backgroundColor: "inherit",
              boxShadow: "none",
            }}
          >
            {accountLocations.map((accountLocation, index) => {
              return (
                <div
                  key={index}
                  className="tw-flex tw-justify-center tw-w-full tw-mt-5 tw-items-center"
                >
                  {locations.find(
                    (location) =>
                      location.serviceId === accountLocation.serviceId,
                  ) ? (
                    <Tooltip title="Matched to a SafeLease location">
                      <IconButton sx={{ padding: 0 }}>
                        <Image
                          src="/images/checkmark.svg"
                          style={{ padding: "11px" }}
                        />
                      </IconButton>
                    </Tooltip>
                  ) : (
                    <Checkbox
                      onChange={(e) =>
                        handleCheck(accountLocation.serviceId, e.target.checked)
                      }
                      checked={serviceIds.includes(accountLocation.serviceId)}
                    />
                  )}
                  {accountLocationDisplayName(accountLocation)}
                </div>
              );
            })}
          </Paper>

          <Box textAlign="center">
            {loading ? (
              <CircularProgress />
            ) : (
              <Button
                className="modal-login-button"
                onClick={handleConfirmDialogOpen}
              >
                Done
              </Button>
            )}
          </Box>
        </Box>
      </Modal>

      <ConfirmDialog
        open={dialogOpen}
        title="Create New Location"
        description="We are going to create a new location based on the address in the FMS, please confirm you want to do this."
        onClose={handleConfirmDialogClose}
        onConfirm={handleConfirmSync}
      />
    </div>
  );
}
