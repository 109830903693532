import dayjs from "dayjs";
import { PeriodDataPoints } from "../RatingBreakdownGraphs";
import _ from "lodash";

// Build a tooltip with all of the values at that x-axis point
export function sharedToolTip(periods: Array<PeriodDataPoints>) {
  return {
    enabled: true,
    formatter() {
      // Has to be a regular function to access "this"
      const index = this.key;
      const period = periods[index];
      const dateRangeOfPeriod = `${dayjs(period.periodStart).format("M/D/YYYY")} - ${dayjs(period.periodEnd).format("M/D/YYYY")}`;

      const header = `<span style="color: black;font-weight:bold;">${dateRangeOfPeriod}</span><br/>`;

      return (
        header +
        this.points.reduce(function (s, point) {
          return s + "<br/>" + point.series.name + ": " + point.y;
        }, "")
      );
    },
    shared: true,
  };
}

// Reduce multi-dataset y axis configurations and scale the graph appropriately
export function yAxisBareConfiguration(data: Array<{ data: Array<number> }>, color) {
  return {
    min: Math.min(..._.flatMapDeep(data.map(({ data }) => data))),
    max: Math.max(..._.flatMapDeep(data.map(({ data }) => data))),
    labels: {
      style: {
        color,
      },
    },
    grid: {
      enabled: false,
    },
    title: {
      text: "",
    },
  };
}

// X-axis configuration; mostly for date formatting
export function xAxisConfiguration(periods: Array<PeriodDataPoints>) {
  return {
    endOnTick: false,
    startOnTick: true,
    tickLength: 1,
    title: {
      text: null,
    },
    grid: {
      enabled: true,
    },
    labels: {
      formatter: (context) => {
        const periodIndex = context.value;
        const period = periods[periodIndex];
        if (!period) return "";

        return `${dayjs(period.periodStart).format("M/D/YY")}`;
      },
    },
    lineColor: "#e6e6e6",
  };
}
