import { Box, Stack, Typography } from "@mui/material";
import { FacilityFeedbackCategoryRow } from "./FacilityFeedbackCategoryRow";
import { issueTypeLabels } from "./issueTypeLabels";

interface FacilityFeedbackCategoriesProps {
  issueTypes: { [key: string]: number };
}

export function FacilityFeedbackCategories({ issueTypes }: FacilityFeedbackCategoriesProps) {
  // Sort the issue types by count
  const sorted = Object.entries(issueTypes).sort((a, b) => b[1] - a[1]);

  return (
    <Box sx={{ flex: 1 }}>
      <Typography variant="body1" sx={{ mb: 1 }}>
        Top Categories
      </Typography>
      <Stack spacing={1} sx={{ width: "100%" }}>
        {sorted.slice(0, 5).map(([category, count]) => (
          <FacilityFeedbackCategoryRow category={issueTypeLabels[category]} count={count} max={sorted[0][1]} key={category} />
        ))}
        {sorted.length === 0 && (
          <Typography variant="body2" color="grey.A100">
            No private reviews collected yet.
          </Typography>
        )}
      </Stack>
    </Box>
  );
}
