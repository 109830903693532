import {
  InputAdornment,
  OutlinedInput,
  OutlinedInputProps,
} from "@mui/material";
import { useState } from "react";

interface CurrencyTextfieldProps extends OutlinedInputProps {
  changeCallback: (value: any) => void;
}

export function CurrencyTextfield({
  changeCallback,
  defaultValue,
  ...props
}: CurrencyTextfieldProps) {
  const [value, setValue] = useState<string>(Number(defaultValue).toFixed(2));

  const handleChange = (event) => {
    let inputValue = event.target.value;

    // Remove non-numeric characters except the decimal point
    inputValue = inputValue.replace(/[^0-9.]/g, '');

    // Limit the input to only two decimal places
    const match = inputValue.match(/^(\d+\.?\d{0,2})/);
    inputValue = match ? match[1] : '';

    setValue(inputValue);
    changeCallback(inputValue)
  };

  const handleBlur = () => {
    // Format the value as a floating-point number to ensure two decimal places
    // This will be displayed in the input field
    setValue(value => value ? parseFloat(value).toFixed(2) : '');
  };


  return (
    <OutlinedInput
      {...props}
      value={value}
      onChange={handleChange}
      onBlur={handleBlur}
      startAdornment={<InputAdornment position="start">$</InputAdornment>}
      inputProps={{
        inputMode: "decimal", // This ensures a numeric keyboard on mobile devices
      }}
    />
  );
}
