import { useQuery } from "@apollo/client";
import { getRelationships } from "../../../queries";
import { Autocomplete, TextField } from "@mui/material";
import { usePlanUpgradeStore } from "../usePlanUpgradeSettingsStore";
import { Relationship } from "../../../utilities/generated/gql-types";
import _ from "lodash";

type RelationshipWithFirstLetter = Relationship & { firstLetter: string };

interface SelectRelationshipStepProps {}

export function SelectRelationshipStep({}: SelectRelationshipStepProps) {
  const selectedRelationship = usePlanUpgradeStore(
    (state) => state.selectedRelationship
  );
  const setSelectedRelationship = usePlanUpgradeStore(
    (state) => state.setSelectedRelationship
  );

  const relationshipsQuery = useQuery(getRelationships, { variables: {} });

  const relationships = relationshipsQuery.data?.getRelationships ?? [];

  const options: RelationshipWithFirstLetter[] = _.uniqBy(
    relationships.map((option: Relationship) => {
      const firstLetter = option.name[0].toUpperCase();
      return {
        firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
        ...option,
      };
    }),
    (option) => option.name
  );

  return (
    <Autocomplete
      value={selectedRelationship}
      onChange={(_, newValue) => setSelectedRelationship(newValue)}
      options={options.sort(
        (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
      )}
      groupBy={(option: Relationship & { firstLetter: string }) =>
        option.firstLetter
      }
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Relationship"
          variant="outlined"
          inputProps={{
            ...params.inputProps,
          }}
        />
      )}
    />
  );
}
