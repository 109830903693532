import { Stack, Theme, Typography } from "@mui/material";
import { SafeLeaseButton } from "@safelease/components";
import _ from "lodash";
import { useState } from "react";
import { TeamQRCodeSetupDialog } from "./TeamPerformanceBoardSetup";
import { usePerformanceBoard } from "./usePerformanceBoard";

export function TeamPerformanceBoardZeroState() {
  // Control if the setup dialog is open
  const { configDialogOpen, setConfigDialogOpen } = usePerformanceBoard();

  return (
    <>
      <Typography sx={{ fontWeight: 600 }} variant="h6">
        Team Performance Board{" "}
      </Typography>
      <Typography sx={{ fontWeight: 300, mb: 2, color: "gray" }} variant="subtitle2">
        Track reviews solicited by your employees using their unique QR codes and links
      </Typography>
      <Stack direction="column" spacing={2} justifyContent="center" alignItems="center" sx={{ height: "100%", px: 6 }}>
        <Typography sx={{ fontWeight: 600 }} variant="h6">
          Track reviews solicited by your employees
        </Typography>
        <SafeLeaseButton color="navy" variant="contained" onClick={() => setConfigDialogOpen(true)}>
          Get started
        </SafeLeaseButton>
      <Typography sx={{ textAlign: "center", color: (theme: Theme) => theme.palette.grey.A100 }} variant="body1">
          Get started by activating QR codes for all your employees. Each employee will receive a personalized review solicitation QR code
          and link via email or text message for each facility they work at.
        </Typography>
      </Stack>
      <TeamQRCodeSetupDialog open={configDialogOpen} handleClose={() => setConfigDialogOpen(false)} />
    </>
  );
}
