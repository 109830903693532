import { useRef, useState } from 'react'
import Linkify from 'react-linkify';
import { Formatters } from '../utilities/formatters'
import ClaimStatusPill from '../column-renderers/claim-status-pill';
import { TitleValuePair, NotificationDot } from '../common'
import PhotoPicker from '../photo-picker';
import { 
  Claim,
  Note,
  NoteHistoryAndActiveUsers,
  UserInfo,
  Notification } from '../utilities/generated/gql-types'
import { getClaimNotes } from '../queries';
import { muiDrawer, muiRedButton } from '../styles/mui-overrides'
import { Box, Button } from '@mui/material'
import { useQuery, QueryResult } from '@apollo/client';
import ClaimNote from '../claims/claim-note';
import ClaimDetailAccordion from './claim-detail-accordion';
import { ghostButtonNavy } from '../styles/mui-overrides'
import { NotificationsContext, NotificationsContextType } 
    from '../utilities/notifications-context';
import UnsavedChangesModal from './unsaved-changes-modal'
import { useAtom } from 'jotai'
import { atomWithReset } from 'jotai/utils'
import { useAuth } from '../auth';

export const claimNotesEditingStateAtom = atomWithReset({
  pendingText: '',
  userIsTryingToExit: false,
  activeNoteId: 0
})

const ClaimDetailsDrawer = (props: {claim: Claim, closeDrawer: () => any}) => {
  const { claim, closeDrawer } = props
  const auth = useAuth();
  const topOfNotes = useRef(null);
  const [claimNotesEditingState, setClaimNotesEditingState] =
      useAtom(claimNotesEditingStateAtom)

  const claimNotesQuery: QueryResult<{ getClaimNotes: NoteHistoryAndActiveUsers }> = useQuery<{
    getClaimNotes: NoteHistoryAndActiveUsers;
  }>(getClaimNotes, {
    variables: { claimId: claim?.id, locationId: claim?.locationId },
    skip: !claim,
  });

  const scrollToNotes = () => {
    var topOfNotes = document.getElementById('top-of-notes')
    var topOfNotesPosition = topOfNotes.getBoundingClientRect().top;
    const claimsDrawerElement = document.getElementById('claim-details-drawer')
    claimsDrawerElement.scrollTo({
      top: topOfNotesPosition,
      behavior: 'smooth'
    })
  }

  if (!claim) return <></>;

  return (
    <NotificationsContext.Consumer>
      {(notificationsContext: NotificationsContextType) =>
        <Box sx={muiDrawer}
            role="presentation"    
            className="claim-details" id="claim-details-drawer">
          <div className="claim-details--content">
            <UnsavedChangesModal 
                exit={closeDrawer} />
            <div className="tw-flex tw-justify-between tw-items-end">
              <div>
                <div className="widget-title widget-title__claim-details">Claim Details</div>
                <div className="claim-details--unit">{claim.unit}</div>
                <div className="claim-details--status">
                  <ClaimStatusPill status={claim.status} />
                  {claim.status === 'Settled - Denied' && !!claim.basisForClaimDenial &&
                    <span className="claim-details--status--basis">
                      {claim.basisForClaimDenial}
                    </span>
                  }
                </div>
              </div>
              <Button onClick={(event) => scrollToNotes() }
                  sx={{...ghostButtonNavy, width: '136px', 
                      height: '36px', fontWeight: 'bold', display: 'flex',
                      justifyContent: 'space-between', paddingRight: '15px',
                      paddingLeft: '15px'}}>
                <NotificationDot extraStyles='tw-invisible'/>
                Notes
                <NotificationDot extraStyles={
                    notificationsContext.NotificationsHelper.claimHasNotification(
                        claim,
                        notificationsContext.allNotifications) ? 
                            '' : 'tw-invisible'}/>
              </Button>
            </div>
            {claim.status !== 'Settled - Paid' && 
                claim.needsFromTenant?.length > 0 &&
              <ClaimDetailAccordion title="NEED FROM TENANT">
                <ul className="tw--ml-4 tw-mb-0 tw-h-16 tw-flex tw-flex-col tw-flex-wrap">
                  {claim.needsFromTenant.map((need, i) => 
                    <li className="tw-text-xs" key={`${need}-${i}`}>{need}</li>
                  )}
                </ul>
              </ClaimDetailAccordion>
            }
            <ClaimDetailAccordion title="DETAILS">
              <div>
                <div className="claim-details--row">
                    <TitleValuePair title={claim.name} 
                        value={<a href={`mailto:${claim.email}`}>{claim.email}</a>} />
                    <TitleValuePair title={claim.matchedLocation} value={''/*TODO*/}/>
                </div>
                <div className="claim-details--row">
                  <TitleValuePair title={'Coverage Amount'} value={Formatters.formatDollarSign(claim.coverageAmount)} />
                  <TitleValuePair title={'Claim Amount'} value={Formatters.formatDollarSign(claim.claimAmount)} />
                  <TitleValuePair title={'Cause'} value={claim?.causes?.join(', ')} />
                  <TitleValuePair title={'Discovery Date'} value={Formatters.localize(claim.discoveryDate)} />
                  <TitleValuePair title={'Claim Submisison'} value={Formatters.localize(claim.claimSubmissionDate)} />
                </div>
              </div>
            </ClaimDetailAccordion>
            <ClaimDetailAccordion title="DESCRIPTION">
              <>
                <div className="claim-details--row">
                  <div className="claim-details--description">
                    <TitleValuePair title={'Description'} value={<Linkify>{claim.description}</Linkify>} />
                  </div>
                  <div className="claim-details--images">
                    <PhotoPicker photos={claim.lossPhoto} />
                  </div>
                </div>
                <div className="claim-details--row claim-details--row__bottom">
                  {/*<ClaimTitleValuePair title={'Covered Status'} value={'Unknown'} />*/}
                  <TitleValuePair title={'Discovery'} value={<Linkify>{claim.whoDiscovered}</Linkify>} grow={true} />
                  <TitleValuePair title={'Filed to Police'} value={claim.filedToPolice ? 'Yes' : 'No'} />
                  {claim.filedToPolice &&
                    <TitleValuePair title={'File Recipient'} value={claim.fileRecipient} />
                  }
                  {claim.filedToPolice &&
                    <TitleValuePair title={'Date Filed'} value={Formatters.localize(claim.dateFiled)} />
                  }
                </div>
              </>
            </ClaimDetailAccordion>
            <div id="top-of-notes"></div>
            <ClaimDetailAccordion ref={topOfNotes}
                title="MESSAGES" 
                collapsedTitle={claimNotesQuery?.data?.getClaimNotes?.notes?.length > 0 ?
                    `View Messages (${claimNotesQuery?.data?.getClaimNotes?.notes?.length})` :
                'Create Messages'}
                subtitle={auth.user.role === 'employee'
                  ? 'View claims messages'
                  : 'Send a message to the SafeLease Claims Agent'
                }
              >
              <div className="tw-flex tw-flex-row claim-details--row">
                <div>
                  <div>
                    {auth.user.role !== 'employee' && (
                      <ClaimNote
                        claim={claim} 
                        claimNotesQuery={claimNotesQuery} 
                        defaultIsEditing={true}
                      />
                    )}
                  </div>
                  {claimNotesQuery.data?.getClaimNotes?.notes?.map((note: Note) =>
                    <ClaimNote
                      key={note.id}
                      claim={claim} 
                      note={note} 
                      claimNotesQuery={claimNotesQuery}
                    />
                  )}
                </div>
                <div className="tw-w-[260px] tw-mr-4">
                  <TitleValuePair title="Active Users"
                      value="" 
                      extraStyles="tw-mb-4"/>
                  <TitleValuePair title="SafeLease"
                      value="claims@safelease.com" 
                      value2="512.767.1408" 
                      valueStyles="tw-text-[#6E82A9] tw-underline"
                      extraStyles="tw-mb-4 "/>
                  {claimNotesQuery.data?.getClaimNotes?.activeUserInfo?.filter(userInfo => userInfo.userId !== 0)
                      .map((userInfo: UserInfo, i: number) =>
                        <TitleValuePair
                          key={i}
                          title={userInfo.userName}
                          value={userInfo.userEmail}
                          valueStyles="tw-text-[#6E82A9] tw-underline"
                          extraStyles="tw-mb-4"
                        />
                      )
                  }
                </div>
              </div>
            </ClaimDetailAccordion>
            <footer style={{color: "gray", position: "fixed", bottom: 0, padding: 0, width: 833}}>
                      
              <div className="claim-details--bottom">
                <Button sx={muiRedButton({maxWidth: true})} 
                    onClick={claimNotesEditingState.pendingText ? 
                        (e) => setClaimNotesEditingState(
                            (s) => ({...s, userIsTryingToExit: true})) :
                        closeDrawer() }>
                  Close
                </Button>
              </div>
            </footer>
          </div>
        </Box>
      }
    </NotificationsContext.Consumer>
  );
}

export default ClaimDetailsDrawer
