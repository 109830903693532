import { useState, useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import { InfoOutlined, Lens } from "@mui/icons-material";
import { Box, Divider, Grid, Skeleton, Stack, Theme, Tooltip, Typography, TextField } from "@mui/material";
import { SafeLeaseCard } from "@safelease/components";
import TenantsSVG from "../../../../assets/tenants.svg";
import { Contact_Reputation as Contact } from "@safelease/service-utilities";
import { useReputation } from "../../../../useReputation";
import { Formatters } from "../../../../../utilities/formatters";
import { ContactInfoDrawer } from "./ContactInfoDrawer";
import { ContactsGrid } from "./ContactsGrid";

interface ContactsOverviewProps {}

export function ContactsOverview({}: ContactsOverviewProps) {
  const { selectedFacilityId, SafeLeaseAPI } = useReputation();

  const [selectedTenantId, setSelectedTenantId] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState("");

  const getFacilityContactsQuery = useQuery({
    queryKey: ["facilityContacts", selectedFacilityId],
    queryFn: () => SafeLeaseAPI.reputation.getFacilityContacts(selectedFacilityId),
  });

  const contacts: Array<Contact & { unsubscribeGroupMemberships: Array<string> }> = getFacilityContactsQuery.data?.data ?? [];
  const selectedContact = contacts.find((contact) => contact.id === selectedTenantId);

  const drawerOpen = Boolean(selectedTenantId);

  const handleClose = () => {
    setSelectedTenantId(null);
  };

  const numberBelongingToUnsubscribeGroup = contacts.filter((contact) => contact.unsubscribeGroupMemberships.length > 0).length;
  const numberManuallyOptedOut = contacts.filter((contact) => contact.manualOptOut).length;
  const numberFullySubscribed = contacts.length - numberBelongingToUnsubscribeGroup - numberManuallyOptedOut;

  const filteredContacts = useMemo(() => {
    return contacts.filter((contact) => contact.name.toLowerCase().includes(searchTerm.toLowerCase()));
  }, [contacts, searchTerm]);

  if (getFacilityContactsQuery.isLoading) {
    return <Skeleton variant="rectangular" height={400} sx={{ width: "100%" }} />;
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <SafeLeaseCard sx={{ p: 2 }}>
          <Stack direction="row" justifyContent="space=between" alignItems="center">
            <Stack direction="column" spacing={2}>
              <Typography variant="h6">Manage Your Tenants</Typography>
              <Typography
                variant="body2"
                sx={{
                  color: (theme: Theme) => theme.palette.grey.A100,
                  maxWidth: 600,
                }}
              >
                View and manage your current and previous tenants. See who is currently receiving communications from your campaigns and who
                has opted out. Additionally, you can see who has submitted a public or private review and who still needs to be replied to.
              </Typography>
              <Stack direction="row" alignItems="center" spacing={2} divider={<Divider orientation="vertical" flexItem />}>
                <Typography variant="body2">
                  <Typography component="span" variant="body2" fontWeight="bold">
                    {contacts.length}
                  </Typography>{" "}
                  Tenants
                </Typography>
                {contacts.length > 0 && (
                  <>
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <Lens
                        sx={{
                          height: 10,
                          width: 10,
                          color: (theme: Theme) => theme.palette.green.main,
                        }}
                      />
                      <Typography variant="body2">
                        <Typography component="span" variant="body2" fontWeight="bold">
                          {Formatters.formatPercentageShort(numberFullySubscribed / contacts.length, 0)}
                        </Typography>{" "}
                        Opted In
                      </Typography>
                      <Tooltip title="Tenants that are currently receiving communications from this campaign.">
                        <InfoOutlined
                          sx={{
                            height: 16,
                            width: "auto",
                            color: (theme: Theme) => theme.palette.grey.A100,
                          }}
                        />
                      </Tooltip>
                    </Stack>
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <Lens
                        sx={{
                          height: 10,
                          width: 10,
                          color: (theme: Theme) => theme.palette.red.main,
                        }}
                      />
                      <Typography variant="body2">
                        <Typography component="span" variant="body2" fontWeight="bold">
                          {Formatters.formatPercentageShort(
                            (numberBelongingToUnsubscribeGroup + numberManuallyOptedOut) / contacts.length,
                            0,
                          )}
                        </Typography>{" "}
                        Opted Out
                      </Typography>
                      <Tooltip title="Tenants that are NOT currently receiving communications from this campaign.">
                        <InfoOutlined
                          sx={{
                            height: 16,
                            width: "auto",
                            color: (theme: Theme) => theme.palette.grey.A100,
                          }}
                        />
                      </Tooltip>
                    </Stack>
                  </>
                )}
                <Box sx={{ width: "100%" }}>
                  <TextField
                    label="Search Contacts"
                    variant="outlined"
                    size="medium"
                    fullWidth
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    sx={{ ml: 2 }}
                  />
                </Box>
              </Stack>
            </Stack>
            <img src={TenantsSVG} alt="Tenants" style={{ height: "100%", width: "auto", marginLeft: "auto" }} />
          </Stack>
        </SafeLeaseCard>
      </Grid>
      <Grid item xs={12}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="h6" fontWeight="bold">
            Contacts
          </Typography>
        </Stack>
        <Box sx={{ height: 400 }}>
          <ContactsGrid contacts={filteredContacts} setSelectedTenant={setSelectedTenantId} />
        </Box>
        <ContactInfoDrawer open={drawerOpen} handleClose={handleClose} contact={selectedContact} />
      </Grid>
    </Grid>
  );
}
