import { useEffect, useState } from 'react';
import {
  Alert,
  Button,
  CircularProgress,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Snackbar,
  FormControl,
  Select,
  MenuItem,
  Box,
  Collapse,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import RuleIcon from '@mui/icons-material/Rule';
import RefreshIcon from '@mui/icons-material/Refresh';
import SettingsIcon from '@mui/icons-material/Settings';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyIcon from '@mui/icons-material/Key';
import ArticleIcon from '@mui/icons-material/Article';
import { setupStatuses } from '../../utilities/setup-statuses';
import { useQuery, QueryResult, useApolloClient } from '@apollo/client';
import { useAuth } from '../../auth.js';
import { Relationship, Location, Account } from '../../utilities/generated/gql-types';
import {
  getAccounts,
  getLocations,
  getRelationship,
  getRelationshipsSelector,
} from '../../queries';
import { mixpanelEventHandler } from '../../utilities/reactMixpanelHandler';
import {
  muiSelect,
  muiSettingsItem,
  muiStatisticTooltip,
  muiTextFieldInput,
  ghostButtonNavy,
} from '../../styles/mui-overrides';
import RouterHelper from '../../utilities/router-helper.js';
import { useHistory } from 'react-router-dom';
import { Image } from '../../image';
import SetupDataService from '../../services/setup.service';
import RelationshipDataService from '../../services/relationship.service';
import Loader from '../../shared/Loader';
import Error from '../../shared/Error';
import { fmsData } from '../../utilities/fms-data';
import FmsLoginModal from '../../shared/fms-login-modal';
import AccountLocationsModal from '../../shared/account-locations-modal';
import LocationSettingsModal from '../../shared/LocationSettingsModal';
import LocationCreateModal from '../../shared/LocationCreateModal';
import RelationshipEditModal from '../../shared/RelationshipEditModal';
import LocationSettingsZeroState from '../../components/LocationSettingsZeroState';
import { useSnackbar } from "notistack";

function LocationSettings(props: { routerHelper: RouterHelper }) {
  const { routerHelper } = props;

  const history = useHistory();
  const auth = useAuth();
  const isAdmin = auth.user.isAdmin;
  const client = useApolloClient();

  const [alertMessage, setAlertMessage] = useState<{
    message: string;
    severity: 'error' | 'warning' | 'info' | 'success';
  } | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [fmsLoginModalOpen, setFmsLoginModalOpen] = useState<boolean>(false);
  const [selectedAccountId, setSelectedAccountId] = useState<string | null>(null);
  const [selectedLocationId, setSelectedLocationId] = useState<string | null>(null);
  const [accountLocationsModalOpen, setAccountLocationsModalOpen] = useState<boolean>(false);
  const [locationSettingsModalOpen, setLocationSettingsModalOpen] = useState<boolean>(false);
  const [locationCreateModalOpen, setLocationCreateModalOpen] = useState<boolean>(false);
  const [relationshipEditModalOpen, setRelationshipEditModalOpen] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();

  if (!(isAdmin || auth.user.role === 'manager')) history.push('/');

  const relationshipId = routerHelper?.getRelationshipId() || auth.user?.relationshipId;

  const relationshipsSelectorQuery: QueryResult = useQuery(getRelationshipsSelector, {
    skip: !isAdmin,
    fetchPolicy: 'cache-and-network',
  });

  const relationshipQuery: QueryResult<{ getRelationship: Relationship }> = useQuery(
    getRelationship,
    {
      variables: { id: relationshipId },
    }
  );

  const locationsQuery: QueryResult<{ getLocations: Location[] }> = useQuery(getLocations, {
    variables: { relationshipId },
  });

  const accountsQuery: QueryResult<{ getAccounts: Account[] }> = useQuery(getAccounts, {
    variables: { relationshipId },
  });

  const deleteAccount = async (accountId: number) => {
    if (window.confirm('Are you sure you want to remove this account from SafeLease?')) {
      try {
        setLoading(true);
        await SetupDataService.deleteAccount(accountId);
        setLoading(false);

        setAlertMessage({
          message: 'Account deleted successfully.',
          severity: 'success',
        });
        client.refetchQueries({ include: [getAccounts, getLocations] });
      } catch (e) {
        setAlertMessage({
          message: e?.response?.data?.error || 'Failed to delete account from SafeLease.',
          severity: 'error',
        });
      }
    }
  };

  const saveLocation = async (locationId: string, accountId: string, serviceId: string) => {
    try {
      const location = locationsQuery.data.getLocations.find(
        (location) => location.id === locationId
      );
      setLoading(true);
      await SetupDataService.addLocation({ ...location, accountId, serviceId });
      setLoading(false);

      setAlertMessage({
        message: 'Location successfully saved.',
        severity: 'success',
      });
      client.refetchQueries({ include: [getAccounts, getLocations] });
      return true;
    } catch (e) {
      setLoading(false);
      setAlertMessage({
        message: e?.response?.data?.error || 'Failed to save the location.',
        severity: 'error',
      });
      return false;
    }
  };

  const accountLocationDisplayName = (accountLocation) => {
    if (!accountLocation.serviceId) return accountLocation.displayName;
    return `${accountLocation.name} - ${accountLocation.address}, ${accountLocation.city}, ${accountLocation.state} (${accountLocation.serviceId})`;
  };

  if (relationshipQuery.loading || locationsQuery.loading || accountsQuery.loading)
    return <Loader />;
  if (relationshipQuery.error || locationsQuery.error || accountsQuery.error) return <Error />;

  const selectedAccount = accountsQuery.data.getAccounts.find(
    (account) => account.id == selectedAccountId
  );

  const selectedLocation = locationsQuery.data.getLocations.find(
    (location) => location.id == selectedLocationId
  );

  const handleGeneratePartnerPolicy = async () => {
    try {
      setLoading(true);
      await RelationshipDataService.generateMonthlyPartnerPolicy({ relationshipId });
      setLoading(false);
      enqueueSnackbar('Partner Policy generated successfully', { variant: 'success' });
    } catch (e) {
      setLoading(false);
      enqueueSnackbar('Failed to generate Partner Policy', { variant: 'error' });
    }
  }

  return (
    <div>
      {auth.user && isAdmin && relationshipsSelectorQuery.data && (
        <FormControl sx={{ marginTop: 1, marginBottom: 2, minWidth: 120 }}>
          <Select
            value={relationshipId}
            displayEmpty
            onChange={(e) => {
              routerHelper.navigateToRoute('locationSettings', e.target.value, history);
              setLoading(false);
            }}
            sx={{ ...muiSelect, marginLeft: '0px', padding: '0px' }}
          >
            {relationshipsSelectorQuery.data.getRelationshipsSelector?.map((relationship) => {
              return (
                <MenuItem key={relationship.id} value={relationship.id}>
                  {relationship.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      )}

      {accountsQuery?.data?.getAccounts?.length > 0 ? (
        <>
          <div className="tw-flex tw-flex-row tw-justify-end">
            {isAdmin && (
              <Button
                sx={{ ...ghostButtonNavy, height: '36px' }}
                type="button"
                disabled={loading}
                onClick={() => setRelationshipEditModalOpen(true)}
              >
                Edit Relationship
              </Button>
            )}
            <Button
              sx={{ ...ghostButtonNavy, height: '36px' }}
              type="button"
              disabled={loading}
              onClick={() => {
                setFmsLoginModalOpen(true);
                mixpanelEventHandler('Location Settings - Initiate adding FMS credentials');
              }}
            >
              Connect more Facilities
            </Button>
          </div>
          <TableContainer component={Paper} className="tw-mt-4">
            <Table sx={{ minWidth: 650 }} size="small">
              <TableHead>
                <TableRow>
                  <TableCell>
                    Facility Management Software
                    <Tooltip
                      placement="bottom"
                      title={'Facility management software accounts.'}
                      componentsProps={{
                        tooltip: {
                          sx: { backgroundColor: '#152744' },
                        },
                      }}
                    >
                      <Button sx={muiStatisticTooltip}>
                        <span className="badge">
                          <Image src="/images/tooltip-icon.svg" alt="warning" />
                        </span>
                      </Button>
                    </Tooltip>
                  </TableCell>
                  <TableCell align="left">Credentials</TableCell>
                  <TableCell align="left">Status</TableCell>
                  <TableCell align="right">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {accountsQuery.data.getAccounts.map((account) => {
                  return (
                    <AccountRow
                      key={account.id}
                      account={account}
                      locations={locationsQuery.data.getLocations}
                      deleteAccount={deleteAccount}
                      openAccountModal={(accountId) => {
                        setSelectedAccountId(accountId);
                        setFmsLoginModalOpen(true);
                      }}
                      accountLocationDisplayName={accountLocationDisplayName}
                      isAdmin={isAdmin}
                      client={client}
                      setAlertMessage={setAlertMessage}
                      openAccountLocationsModal={(accountId) => {
                        setSelectedAccountId(accountId);
                        setAccountLocationsModalOpen(true);
                      }}
                    />
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      ) : (
        <div className="tw-flex tw-flex-col tw-justify-center">
          {isAdmin ? (
            <div className="tw-flex tw-flex-row tw-justify-center">
              <Button
                sx={{ ...ghostButtonNavy, height: '36px' }}
                type="button"
                disabled={loading}
                onClick={() => setRelationshipEditModalOpen(true)}
              >
                Edit Relationship
              </Button>
              <Button
                sx={{ ...ghostButtonNavy, height: '36px' }}
                type="button"
                disabled={!true}
                onClick={() => {
                  setFmsLoginModalOpen(true);
                  mixpanelEventHandler('Location Settings - Initiate adding FMS credentials');
                }}
              >
                Connect Facilities
              </Button>
            </div>
          ) : (
            <LocationSettingsZeroState onAddLocations={() => {
              setFmsLoginModalOpen(true);
              mixpanelEventHandler('Location Settings - Initiate adding FMS credentials');
            }} />
          )}
        </div>
      )}

      {locationsQuery?.data?.getLocations?.length > 0 && locationsQuery?.data?.getLocations && (
        <>
          <div className="settings--breaker-bar" style={{ marginTop: '24px', height: '16px' }} />
          <TableContainer component={Paper} className="tw-mt-4">
            <Table sx={{ minWidth: 650 }} size="small">
              <TableHead>
                <TableRow>
                  <TableCell>
                    Location
                    <Tooltip
                      placement="bottom"
                      title={'An individual facility.'}
                      componentsProps={{
                        tooltip: {
                          sx: { backgroundColor: '#152744' },
                        },
                      }}
                    >
                      <Button sx={muiStatisticTooltip}>
                        <span className="badge">
                          <Image src="/images/tooltip-icon.svg" alt="warning" />
                        </span>
                      </Button>
                    </Tooltip>
                  </TableCell>
                  <TableCell align="left">Account</TableCell>
                  <TableCell align="left">Products</TableCell>
                  <TableCell align="left">Status</TableCell>
                  <TableCell align="right">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!locationsQuery.loading &&
                  locationsQuery.data.getLocations.map((location) => {
                    return (
                      <LocationRow
                        key={location.id}
                        location={location}
                        accounts={accountsQuery?.data?.getAccounts || []}
                        saveLocation={saveLocation}
                        accountLocationDisplayName={accountLocationDisplayName}
                        isAdmin={isAdmin}
                        openLocationSettingsModal={(locationId) => {
                          setSelectedLocationId(locationId);
                          setLocationSettingsModalOpen(true);
                        }}
                      />
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}

      {!!alertMessage && (
        <Snackbar open anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
          <Alert
            className="tw-mt-4 tw-mb-4"
            severity={alertMessage?.severity}
            action={
              <IconButton
                color="inherit"
                onClick={() => {
                  setAlertMessage(null);
                }}
              >
                <CloseIcon />
              </IconButton>
            }
          >
            {alertMessage?.message}
          </Alert>
        </Snackbar>
      )}

      {fmsLoginModalOpen && (
        <FmsLoginModal
          relationshipId={relationshipId}
          account={accountsQuery.data.getAccounts.find(
            (account) => account.id === selectedAccountId
          )}
          onClose={(accountId) => {
            setFmsLoginModalOpen(false);
            setSelectedAccountId(accountId);
            if (accountId) setAccountLocationsModalOpen(true);
            client.refetchQueries({ include: [getAccounts, getLocations] });
          }}
          onAddFacilityManually={() => {
            setFmsLoginModalOpen(false);
            setSelectedAccountId(null);
            setLocationCreateModalOpen(true);
          }}
          setAlertMessage={setAlertMessage}
        />
      )}

      {accountLocationsModalOpen && selectedAccount && selectedAccount.type !== 'unsupported' && (
        <AccountLocationsModal
          account={selectedAccount}
          onClose={() => {
            setAccountLocationsModalOpen(false);
            setSelectedAccountId(null);
            client.refetchQueries({ include: [getAccounts, getLocations] });
          }}
          setAlertMessage={setAlertMessage}
        />
      )}

      {locationSettingsModalOpen && selectedLocation && (
        <LocationSettingsModal
          location={selectedLocation}
          onClose={() => {
            setLocationSettingsModalOpen(false);
            setSelectedLocationId(null);
            client.refetchQueries({ include: [getAccounts, getLocations, getRelationship] });
          }}
          isAdmin={isAdmin}
        />
      )}

      {locationCreateModalOpen && (
        <LocationCreateModal
          relationshipId={relationshipId}
          onClose={(locationId) => {
            setLocationCreateModalOpen(false);
            if (locationId && isAdmin) {
              setLocationSettingsModalOpen(true);
              setSelectedLocationId(locationId);
            }
            client.refetchQueries({ include: [getAccounts, getLocations] });
          }}
        />
      )}

      {relationshipEditModalOpen && (
        <RelationshipEditModal
          relationship={relationshipQuery.data.getRelationship}
          onClose={() => {
            setRelationshipEditModalOpen(false);
            client.refetchQueries({ include: [getRelationship, getAccounts, getLocations] });
          }}
        />
      )}
    </div>
  );
}

function AccountRow(props) {
  const {
    account,
    deleteAccount,
    locations,
    openAccountModal,
    accountLocationDisplayName,
    isAdmin,
    client,
    setAlertMessage,
    openAccountLocationsModal,
  } = props;

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState<boolean>(false);

  const refreshAccountLocations = async () => {
    try {
      setLoading(true);
      const fetchLocationsResponse = await SetupDataService.createFetchLocationsJob({
        accountId: account.id,
      });
      setAlertMessage({
        message: `Refreshing the locations from ${fmsData[account.type].name}...`,
        severity: 'info',
      });
      let jobResponse;
      do {
        await new Promise((r) => setTimeout(r, 2500));
        jobResponse = await SetupDataService.fetchAccountLocations(fetchLocationsResponse.data.id);
      } while (jobResponse.data.status !== 'ok');
      setAlertMessage({
        message: `Successfully refreshed SafeLease locations from ${fmsData[account.type].name}`,
        severity: 'success',
      });
      openAccountLocationsModal(account.id);
    } catch (e) {
      console.log(e.message);
      setAlertMessage({
        message: `An error occured while refreshing the locations from ${
          fmsData[account.type].name
        }.`,
        severity: 'error',
      });
    } finally {
      setLoading(false);
      client.refetchQueries({ include: [getAccounts, getLocations] });
    }
  };

  return (
    <>
      <TableRow key={account.id} sx={{ 'td, th': { border: 0 } }}>
        <TableCell align="left">
          <Tooltip title={fmsData[account.type].name}>
            <IconButton style={{ marginRight: 10 }}>
              <Image
                src={fmsData[account.type].image}
                alt={fmsData[account.type].name}
                style={{ width: 40 }}
              />
            </IconButton>
          </Tooltip>
          {account.credentials?.displayName ||
            `${
              account.type === 'unsupported'
                ? account.credentials?.unsupportedFmsType
                : fmsData[account.type].name
            } - ${account.id}`}
          {account.type !== 'unsupported' && (
            <Tooltip title={open ? 'Hide locations from software' : 'View locations from software'}>
              <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </Tooltip>
          )}
        </TableCell>
        <TableCell align="left">
          {account.credentials?.url && (
            <div>
              <strong>URL: </strong>
              {account.credentials.url}
            </div>
          )}
          {account.credentials?.corpCode && (
            <div>
              <strong>Corp Code: </strong>
              {account.credentials.corpCode}
            </div>
          )}
          {account.credentials?.username && (
            <div>
              <strong>Username: </strong>
              {account.credentials.username}
            </div>
          )}
        </TableCell>
        <TableCell align="left">
          {setupStatuses[account.setupStatus].label}
          <span className="badge">
            <Image
              src={setupStatuses[account.setupStatus].image}
              alt={setupStatuses[account.setupStatus].label}
            />
          </span>
        </TableCell>
        <TableCell align="right">
          {loading ? (
            <CircularProgress />
          ) : (
            <>
              {isAdmin && (
                <>
                  <Tooltip title="Edit">
                    <IconButton onClick={() => openAccountModal(account.id)}>
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  {account.type !== 'unsupported' && (
                    <Tooltip title="Refresh locations from software">
                      <IconButton onClick={() => refreshAccountLocations()}>
                        <RefreshIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                </>
              )}
              <Tooltip title="Delete">
                <IconButton onClick={() => deleteAccount(account.id)}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </>
          )}
        </TableCell>
      </TableRow>
      <TableRow key={`${account.id}-locations`}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="account-locations" sx={{ 'td, th': { border: 0 } }}>
                <TableBody>
                  <TableRow key={'header'}>
                    <TableCell component="th" scope="row">
                      <div className="tw-flex tw-items-center">
                        <strong>Sync Status</strong>
                      </div>
                    </TableCell>
                  </TableRow>
                  {account.locations?.length > 0 &&
                    account.locations.map((location) => {
                      return (
                        <TableRow key={location.serviceId}>
                          <TableCell component="th" scope="row">
                            <div className="tw-flex tw-items-center">
                              {locations?.find((l) => l.serviceId === location.serviceId && l.accountId == location.accountId) ? (
                                <Tooltip title="Matched to a SafeLease location">
                                  <IconButton>
                                    <CheckIcon color="success" />
                                  </IconButton>
                                </Tooltip>
                              ) : (
                                <Tooltip title="Not matched to a SafeLease location">
                                  <IconButton>
                                    <RuleIcon color="warning" />
                                  </IconButton>
                                </Tooltip>
                              )}
                              {accountLocationDisplayName(location)}
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

function LocationRow(props) {
  const {
    location,
    accounts,
    saveLocation,
    accountLocationDisplayName,
    isAdmin,
    openLocationSettingsModal,
  } = props;

  const [accountId, setAccountId] = useState<string | null>(location.accountId);
  const [serviceId, setServiceId] = useState<string | null>(location.serviceId);
  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState<boolean>(
    !location.accountId || (!location.serviceId && !location.unsupported)
  );
  const [setupStatus, setSetupStatus] = useState<string>(location.setupStatus);

  useEffect(() => {
    setSetupStatus(location.setupStatus);
    setEdit(!location.accountId || (!location.serviceId && !location.unsupported));
  }, [location]);

  useEffect(() => {
    if (!location.unsupported && (!location.accountId || !location.serviceId)) {
      for (const account of accounts) {
        if (!account.locations) continue;
        for (const accountLocation of account.locations) {
          if (
            accountLocation.geometry &&
            location.lat === accountLocation.geometry.coordinates[1] &&
            location.lng === accountLocation.geometry.coordinates[0]
          ) {
            setAccountId(account.id);
            setServiceId(accountLocation.serviceId);
            return;
          }
        }
      }
      
    }

    if (location.accountId) {
      const account = accounts.find(account => account.id == location.accountId);
      setAccountId(account.id);
      setServiceId(location.serviceId);
    }
  }, [accounts, location]);

  const save = async () => {
    if (!accountId) return;

    setLoading(true);
    const success = await saveLocation(location.id, accountId, serviceId);
    setLoading(false);

    setEdit(!success);
  };

  const accountLocations = [];
  accounts.forEach((account) => {
    if (account.type === 'unsupported') {
      accountLocations.push({
        serviceId: null,
        accountId: account.id,
        displayName: account.credentials?.displayName || account.credentials?.unsupportedFmsType,
      });
    } else {
      account.locations?.forEach((accountLocation) =>
        accountLocations.push({ ...accountLocation, accountId: account.id })
      );
    }
  });

  // If a location has been manually connected to an account, don't show nothing in the dropdown.
  if(!accountLocations.find(accountLocation => accountLocation.accountId == location.accountId && accountLocation.serviceId == location.serviceId)) {
    accountLocations.push({...location});
  }

  if (
    location?.accountId &&
    location.accountId == accountId &&
    setupStatus !== location.setupStatus
  ) {
    setSetupStatus(location.setupStatus);
  }

  return (
    <TableRow key={location.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
      <TableCell component="th" scope="row" sx={{ maxWidth: 350 }}>
        {location.fullAddress}
      </TableCell>
      <TableCell align="left">
        <Select
          sx={Object.assign(muiSettingsItem(), muiTextFieldInput, {
            width: 300,
            maxWidth: 300,
            marginBottom: 0,
          })}
          value={
            accountLocations.find(
              (accountLocation) =>
                accountLocation.accountId == accountId && accountLocation.serviceId == serviceId
            ) || null
          }
          onChange={(event) => {
            setAccountId(event.target.value.accountId);
            setServiceId(event.target.value.serviceId);
          }}
          disabled={!edit}
        >
          {accountLocations.map((accountLocation, i) => (
            <MenuItem key={i} value={accountLocation} style={muiTextFieldInput}>
              {accountLocationDisplayName(accountLocation)}
            </MenuItem>
          ))}
        </Select>
      </TableCell>
      <TableCell align="left">
        <div className="tw-flex">
          <ul>
            {location.insightsEnabled && <li>Insights</li>}
            {location.insuranceEnabled && (
              <li>{`Tenant Protection${
                location.autoEnrollExistingEnabled
                  ? ': Auto-Protect All'
                  : location.autoEnrollNewEnabled
                  ? ': Auto-Protect New'
                  : location.safeleaseLiteEnabled
                  ? ': SafeLease Lite'
                  : ''
              }`}</li>
            )}
          </ul>
        </div>
      </TableCell>
      <TableCell align="left">
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            {setupStatuses[setupStatus].label}
            <span className="badge">
              <Image
                src={setupStatuses[setupStatus].image}
                alt={setupStatuses[setupStatus].label}
              />
            </span>
            {setupStatuses[setupStatus].description && (
              <Tooltip
                placement="bottom"
                title={setupStatuses[setupStatus].description}
                componentsProps={{
                  tooltip: {
                    sx: { backgroundColor: '#152744' },
                  },
                }}
              >
                <Button sx={muiStatisticTooltip}>
                  <span className="badge">
                    <Image src="/images/tooltip-icon.svg" alt="warning" />
                  </span>
                </Button>
              </Tooltip>
            )}
          </>
        )}
      </TableCell>
      <TableCell align="right" sx={{ minWidth: 230 }}>
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            {edit ? (
              <Button sx={{ ...ghostButtonNavy, height: '36px' }} onClick={() => save()}>
                Save
              </Button>
            ) : (
              <Tooltip title="Edit Account">
                <IconButton
                  onClick={() => {
                    setEdit(true);
                    setSetupStatus('matched');
                  }}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
            )}
            {isAdmin && (
              <>
                <Tooltip title="Location Settings">
                  <IconButton onClick={() => openLocationSettingsModal(location.id)}>
                    <SettingsIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="View Credentials">
                  <IconButton href={`/admin/tools/credentials/${location.id}`} disabled={!location.accountId} target="_blank">
                    <KeyIcon />
                  </IconButton>
                </Tooltip>
              </>
            )}
          </>
        )}
      </TableCell>
    </TableRow>
  );
}

export default LocationSettings;
