import { GridColDef } from "@mui/x-data-grid-premium";

export const liveProductionLatencyByFMSColumnDefs: GridColDef[] = [
  {
    field: "type",
    headerName: "FMS",
    width: 400,
  },
  {
    field: "latency",
    headerName: "Latency",
    width: 400,
  },
];
