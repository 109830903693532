import { QueryResult, useQuery } from "@apollo/client";
import {
  Box,
  Button,
  Grid,
  List,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { getLocations } from "../../../queries";
import { useReportsStore } from "../../../reports/useReportsStore";
import { Location } from "../../../utilities/generated/gql-types";
import { Add, InfoOutlined, RestartAlt } from "@mui/icons-material";
import { usePlanUpgradeStore } from "../usePlanUpgradeSettingsStore";
import { PlanEditingRow } from "./PlanEditingRow";
import { ghostButtonNavy } from "../../../styles/mui-overrides";
import { v4 as uuidv4 } from "uuid";

interface SelectPlansStepProps {}

export function SelectPlansStep({}: SelectPlansStepProps) {
  /* Form state */
  const premiumBuckets = usePlanUpgradeStore((state) => state.premiumBuckets);
  const setPremiumBuckets = usePlanUpgradeStore(
    (state) => state.setPremiumBuckets
  );
  const addPremiumBucket = usePlanUpgradeStore(
    (state) => state.addPremiumBucket
  );

  /* Keep a reference to the original premium buckets */
  const defaultPremiumBuckets = usePlanUpgradeStore(
    (state) => state.defaultPremiumBuckets
  );

  return (
    <Box
      sx={{
        p: 1,
      }}
    >
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography variant="body1" fontWeight="bold">
            Standard plans
          </Typography>
          <Tooltip title="Standard plans are the plans available in the FMS for customers and partners to select from.">
            <InfoOutlined sx={{ height: 16, width: 16, color: grey[500] }} />
          </Tooltip>
        </Stack>
        <Button
          sx={{
            ...ghostButtonNavy,
            border: "none",
            lineHeight: "18px",
          }}
          size="small"
          onClick={() => setPremiumBuckets(defaultPremiumBuckets)}
          startIcon={<RestartAlt sx={{ height: 18, width: 18 }} />}
        >
          Reset to default
        </Button>
      </Stack>
      <Grid container spacing={1}>
        <Grid item xs={2} />
        <Grid item xs={2} textAlign="right">
          <Typography variant="body2" fontWeight="bold">
            Coverage
          </Typography>
        </Grid>
        <Grid item xs={3} textAlign="right">
          <Typography variant="body2" fontWeight="bold">
            Current Premium
          </Typography>
        </Grid>
        <Grid item xs={3} textAlign="right">
          <Typography variant="body2" fontWeight="bold">
            Updated Premium
          </Typography>
        </Grid>
      </Grid>
      <List>
        {premiumBuckets.map((bucket) => (
          <PlanEditingRow
            premiumBucket={bucket}
            key={bucket.id}
            defaultPremiumBuckets={defaultPremiumBuckets}
          />
        ))}
      </List>
      <Stack direction="row" justifyContent="flex-end">
        <Button
          startIcon={<Add />}
          sx={{ textTransform: "none" }}
          onClick={() =>
            addPremiumBucket({
              oldCoverage: null,
              oldPremium: null,
              newCoverage: 0,
              newPremium: 0,
              removeAfterComplete: false,
            })
          }
        >
          Add Plan
        </Button>
      </Stack>
    </Box>
  );
}
