import { Stack, Theme, Typography } from "@mui/material";
import { PrivatePolicyDetailsSection } from "./PrivatePolicyDetailsSection";
import { PrivatePolicy } from "../../../utilities/generated/gql-types";

interface PrivatePolicyAcceptanceCriteriaProps {
  privatePolicy: PrivatePolicy;
}

export const PrivatePolicyAcceptanceCriteria: React.FC<
  PrivatePolicyAcceptanceCriteriaProps
> = ({ privatePolicy }) => {
  const { tenantNameSource, policyNumberSource, expirationSource } =
    privatePolicy;

  // Gets the tenant name based on the tenantNameSource
  const getTenantName = () => {
    // Tenant's input was selected as a valid tenant name
    if (tenantNameSource === "tenantInput") return privatePolicy.tenantName;

    // Reviewer has overridden the tenant name because they found a valid one in the declaration page
    if (tenantNameSource === "manualOverride")
      return privatePolicy.overrideTenantName;

    return "-";
  };

  // Gets policy number based on the policyNumberSource
  const getPolicyNumber = () => {
    // Reviewer selected the AI extracted number as the valid source
    if (policyNumberSource === "aiExtracted")
      return privatePolicy.extractedPolicyNumber;

    // Tenant's input was selected as a valid policy number
    if (policyNumberSource === "tenantInput") return privatePolicy.policyNumber;

    // Reviewer has overridden the policy number because they found a valid one in the declaration page
    if (policyNumberSource === "manualOverride")
      return privatePolicy.overridePolicyNumber;

    return "-";
  };

  // Gets the expiration date based on the expirationDateSource
  const getPolicyExpiration = () => {
    // Reviewer selected the AI extracted date as the valid source
    if (expirationSource === "aiExtracted")
      return privatePolicy.extractedExpiration;

    // Tenant's input was selected as a valid policy expiration date
    if (expirationSource === "tenantInput") return privatePolicy.expiration;

    // Reviewer has overridden the expiration date because they found a valid one in the declaration page
    if (expirationSource === "manualOverride")
      return privatePolicy.overrideExpiration;

    return "-";
  };

  return (
    <Stack
      direction="column"
      sx={{
        flex: 2,
        mb: 4,
      }}
    >
      <Typography
        variant="body1"
        sx={{ color: (theme: Theme) => theme.palette.grey[600] }}
      >
        Private Policy Acceptance Criteria
      </Typography>
      <Stack
        direction="column"
        sx={{
          p: 2,
          border: "1px solid",
          borderColor: (theme: Theme) => theme.palette.divider,
          boxShadow: "0 0 20px rgba(0,0,0,0.05)",
          borderRadius: 2,
        }}
        spacing={1.5}
      >
        <PrivatePolicyDetailsSection
          title="Tenant Name"
          value={getTenantName()}
        />

        <PrivatePolicyDetailsSection
          title="Policy Number"
          value={getPolicyNumber()}
        />
        <PrivatePolicyDetailsSection
          title="Policy Expiration Date"
          value={getPolicyExpiration()}
        />
      </Stack>
    </Stack>
  );
};
