import Button from '@mui/material/Button';
import SafeleaseWrapper from '../shared/safelease-wrapper';
import Box from '@mui/material/Box';
import { blackButton } from '../styles/mui-overrides';
import RouterHelper from '../utilities/router-helper';
import FmsLoginModal from '../shared/fms-login-modal';
import AccountLocationsModal from '../shared/account-locations-modal';
import { useState } from 'react';
import { useAuth } from '../auth';
import { getAccounts } from '../queries';
import { QueryResult, useQuery } from '@apollo/client';
import { Account } from '../utilities/generated/gql-types';
import Loader from '../shared/Loader';
import Error from '../shared/Error';
import { Alert, IconButton, Snackbar } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Image } from '../image';

export default function WelcomeToSafelease(props: { routerHelper: RouterHelper }) {
  const { routerHelper } = props;
  const auth = useAuth();

  const [alertMessage, setAlertMessage] = useState<{
    message: string;
    severity: 'error' | 'warning' | 'info' | 'success';
  } | null>(null);
  const [selectedAccountId, setSelectedAccountId] = useState<string | null>(null);
  const [accountLocationsModalOpen, setAccountLocationsModalOpen] = useState<boolean>(false);
  const [fmsLoginModalOpen, setFmsLoginModalOpen] = useState<boolean>(false);

  const relationshipId = routerHelper?.getRelationshipId() || auth.user?.relationshipId;

  const accountsQuery: QueryResult<{ getAccounts: Account[] }> = useQuery(getAccounts, {
    variables: { relationshipId },
  });

  if (accountsQuery.loading) return <Loader />;
  if (accountsQuery.error) return <Error />;

  const selectedAccount = accountsQuery.data.getAccounts.find(
    (account) => account.id == selectedAccountId
  );

  return (
    <SafeleaseWrapper>
      <Image className="tw-p-5" src="/images/safelease_logo.svg" alt="SafeLease logo" />
      <div className="tw-p-20">
        <div className="tw-font-bold tw-text-3xl tw-mb-2">Welcome To SafeLease!</div>
        <div className="tw-font-semibold tw-text-xl">
          Add facilities to discover our unique products below
        </div>
        <Box
          sx={{
            backgroundColor: 'lightgray',
            border: 'gray 2px solid',
            display: 'flex',
            borderRadius: '5px',
            padding: '0.25rem',
            maxWidth: '70%',
            justifyContent: 'space-between',
            marginTop: '2rem',
          }}
        >
          <div style={{ padding: '0.5rem' }}>
            <div className="tw-font-extrabold tw-text-xl">Get started by adding a facility</div>
            <div className="tw-font-semibold tw-mt-4">
              Uncover opportunities to make more revenue with SafeLease insights. Link your facility management software.
            </div>
          </div>

          <div style={{ padding: '0.5rem' }}>
            <div className="tw-mt-4">
              <Button onClick={() => setFmsLoginModalOpen(true)} sx={{...blackButton, width: '10rem'}}>
                Add Facility +
              </Button>
            </div>
          </div>
        </Box>
        {/* here will be the image whenever we have a good one */}

        {!!alertMessage && (
          <Snackbar open anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
            <Alert
              className="tw-mt-4 tw-mb-4"
              severity={alertMessage?.severity}
              action={
                <IconButton
                  color="inherit"
                  onClick={() => {
                    setAlertMessage(null);
                  }}
                >
                  <CloseIcon />
                </IconButton>
              }
            >
              {alertMessage?.message}
            </Alert>
          </Snackbar>
        )}

        {fmsLoginModalOpen && (
          <FmsLoginModal
            relationshipId={relationshipId}
            account={accountsQuery.data.getAccounts.find(
              (account) => account.id === selectedAccountId
            )}
            onClose={(accountId) => {
              setFmsLoginModalOpen(false);
              setSelectedAccountId(accountId);
              if (accountId) setAccountLocationsModalOpen(true);
              accountsQuery.refetch();
            }}
            onAddFacilityManually={null}
            setAlertMessage={setAlertMessage}
          />
        )}

        {accountLocationsModalOpen && selectedAccount && selectedAccount.type !== 'unsupported' && (
          <AccountLocationsModal
            account={selectedAccount}
            onClose={() => {
              setAccountLocationsModalOpen(false);
              setSelectedAccountId(null);
              accountsQuery.refetch();
              window.location.reload();
            }}
            setAlertMessage={setAlertMessage}
          />
        )}
      </div>
    </SafeleaseWrapper>
  );
}