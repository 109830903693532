import { Alert, Box, Card, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { Formatters } from "../../../utilities/formatters";
import { InvoiceLineItem } from "../../../utilities/generated/gql-types";
import styled from "@emotion/styled";

type InvoiceLineItemsCardProps = {
  lineItems: InvoiceLineItem[];
};

const StyledTableRow = styled(TableRow)({
  borderBottom: "1px solid #EBEFF7",
});


const InvoiceLineItemsCard = ({
  lineItems,
}: InvoiceLineItemsCardProps) => {
  return (
    <Card
      elevation={0}
      sx={{ border: `1px solid #EBEFF7`, padding: "20px" }}
    >
      <Stack spacing={1}>
        <Typography
          color="rgba(21, 39, 68, 1)"
          noWrap
          variant="body1"
        >
          Location: {lineItems[0].locationFullAddress}
        </Typography>
        {
          !lineItems[0].autoConfirmed && <Alert severity="error">{lineItems[0].failureReason}</Alert>
        }
        <TableContainer
          component={Box}
          borderRadius="5px"
          border="1px solid #EBEFF7"
          borderBottom={0}
        >
          <Table>
            <TableHead>
              <StyledTableRow>
                <TableCell>Line Item</TableCell>
                <TableCell align="right">Amount due to SafeLease</TableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {
                lineItems.map((lineItem) => (
                  <StyledTableRow key={lineItem.id}>
                    <TableCell>{lineItem.label}</TableCell>
                    <TableCell align="right">{Formatters.penniesToDollars(lineItem.amount)}</TableCell>
                  </StyledTableRow>
                ))
              }
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
    </Card>
  );
};

export default InvoiceLineItemsCard;
