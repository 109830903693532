import create, {UseBoundStore, StoreApi} from 'zustand'
import jwt from 'jsonwebtoken'

export const useOtpStore = create((set, get) => ({
  otpVerified: false,
  setOtpVerified: (otpVerified: boolean) => set({otpVerified}),
  checkOtpVerified: (localStorage) => {
    const decoded = jwt.decode(localStorage.getItem('otpToken'))
    if (!decoded) {
      get().setOtpVerified(false)
      return false
    }
    const otpTokenCreationTime = new Date(decoded.iat * 1000)
    const yesterday = new Date(new Date().getTime() - (24 * 60 * 60 * 1000));
    const otpTokenExpired = otpTokenCreationTime < yesterday
    if (otpTokenExpired) {
      get().setOtpVerified(false)
      return false
    }
    get().setOtpVerified(true)
    return true
  }
}))

