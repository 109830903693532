// UI - Libs
import {
  Box,
  Dialog,
  Divider,
  Grid,
  IconButton,
  Stack,
  Theme,
  Typography,
} from "@mui/material";
import { Close } from "@mui/icons-material";

// UI - Internal
import { PrivatePolicyReviewForm } from "./PrivatePolicyReviewForm";
import { PrivatePolicyStatusChip } from "./PrivatePolicyStatusChip";
import { PDFViewer } from "../../shared/PDFViewer/PDFViewer";

// Hooks
import { useQuery } from "@apollo/client";
import { FormProvider, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";

// Data
import { PrivatePolicy } from "../../utilities/generated/gql-types";
import { getAttachmentEphemeralLink } from "../../queries";
import { PrivatePolicyValidationStatus } from "../../reports/useReportsStore/privatePolicyReportSlice";
import PrivatePolicyService from "../../services/private-policy.service";
import { ReviewPrivatePolicyActions } from "./ReviewPrivatePolicyActions";
import { PrivatePolicyDetailsList } from "./PrivatePolicyDetailsList";
import { useComplianceCenterStore } from "./useComplianceCenter";
import _ from "lodash";

export type FieldType = "tenantName" | "policyNumber" | "expirationDate";
export type FieldValueSource = "userInput" | "aiExtracted" | "manualOverride";
export type RejectionReason =
  | "invalidDeclarationPage"
  | "invalidPolicyNumber"
  | "invalidExpiration"
  | "invalidTenantName";

interface ReviewPrivatePolicyDialogProps {
  open: boolean;
  privatePolicy: PrivatePolicy;
  handleClose: () => void;
}

/* A dialog containing a PDF viewer and a form to review and accept or reject a private policy */
export function ReviewPrivatePolicyDialog({
  open,
  privatePolicy,
  handleClose,
}: ReviewPrivatePolicyDialogProps) {
  const methods = useForm({});
  const { enqueueSnackbar } = useSnackbar();
  const privatePolicies = useComplianceCenterStore(
    (state) => state.privatePolicies,
  );
  const updatePrivatePolicy = useComplianceCenterStore(
    (state) => state.updatePrivatePolicy,
  );

  const attachmentLink = useQuery(getAttachmentEphemeralLink, {
    variables: {
      attachmentId: privatePolicy?.attachmentId,
    },
    skip: !privatePolicy,
  });

  const [submittingType, setSubmittingType] =
    useState<PrivatePolicyValidationStatus | null>(null);

  // Reset the form whenever the modal closes
  useEffect(() => {
    if (!open) {
      methods.reset();
    }
  }, [open]);

  /* Approve this private policy, tenant will be opted out of tenant protection */
  const handleApprove = async () => {
    const formValues = methods.getValues();

    try {
      setSubmittingType(PrivatePolicyValidationStatus.accepted);
      await PrivatePolicyService.updateStatus({
        generalUnitId: privatePolicy.generalUnitId,
        privatePolicyId: privatePolicy.id,
        validationStatus: PrivatePolicyValidationStatus.accepted,
        overrideTenantName: formValues.tenantName.manualOverrideValue,
        overridePolicyNumber: formValues.policyNumber.manualOverrideValue,
        overrideExpiration: formValues.expirationDate.manualOverrideValue,
        tenantNameSource: formValues.tenantName.fieldSource,
        policyNumberSource: formValues.policyNumber.fieldSource,
        expirationSource: formValues.expirationDate.fieldSource,
        sendEmail: formValues.sendEmail,
      });

      const index = privatePolicies.findIndex(
        (policy) => policy.id === privatePolicy.id,
      );

      const updatedPrivatePolicy = {
        ...privatePolicies[index],
        validationStatus: PrivatePolicyValidationStatus.accepted as string,
        overrideTenantName: formValues.tenantName.manualOverrideValue,
        overridePolicyNumber: formValues.policyNumber.manualOverrideValue,
        overrideExpiration: formValues.expirationDate.manualOverrideValue,
        tenantNameSource: formValues.tenantName.fieldSource,
        policyNumberSource: formValues.policyNumber.fieldSource,
        expirationSource: formValues.expirationDate.fieldSource,
        sendEmail: formValues.sendEmail,
      };

      // Update this newly updated private policy locally locally in the private policy list
      updatePrivatePolicy(updatedPrivatePolicy);

      enqueueSnackbar(`Private policy approved`, {
        variant: "success",
      });
      handleClose();
    } catch (err) {
      Sentry.captureException(err);

      enqueueSnackbar(
        <Box>
          <Typography fontWeight={700}>
            Something went wrong while approving private policy.
          </Typography>
          <Typography>{err.response?.data?.error}</Typography>
        </Box>,
        { variant: "info" },
      );
    } finally {
      setSubmittingType(null);
    }
  };

  // /* Deny this private policy
  //  * Private policy can be denied for one of many reasons:
  //  * - invalid expiration date
  //  * - invalid policy number
  //  * - invalid person name
  //  * - private policy doesn't cover self storage units
  //  * - declarations page is missing
  //  * - declarations page is not real
  //  */
  const handleReject = async (rejectionReason: RejectionReason) => {
    const formValues = methods.getValues();

    try {
      setSubmittingType(PrivatePolicyValidationStatus.rejected);
      await PrivatePolicyService.updateStatus({
        generalUnitId: privatePolicy.generalUnitId,
        privatePolicyId: privatePolicy.id,
        validationStatus: PrivatePolicyValidationStatus.rejected,
        overrideTenantName: formValues.tenantName?.manualOverrideValue,
        overridePolicyNumber: formValues.policyNumber?.manualOverrideValue,
        overrideExpiration: formValues.expirationDate?.manualOverrideValue,
        tenantNameSource: formValues.tenantName?.fieldSource,
        policyNumberSource: formValues.policyNumber?.fieldSource,
        expirationSource: formValues.expirationDate?.fieldSource,
        rejectionReason,
        sendEmail: formValues.sendEmail,
      });

      const index = privatePolicies.findIndex(
        (policy) => policy.id === privatePolicy.id,
      );

      const updatedPrivatePolicy = {
        ...privatePolicies[index],
        validationStatus: PrivatePolicyValidationStatus.rejected as string,
        overrideTenantName: formValues.tenantName?.manualOverrideValue,
        overridePolicyNumber: formValues.policyNumber?.manualOverrideValue,
        overrideExpiration: formValues.expirationDate?.manualOverrideValue,
        tenantNameSource: formValues.tenantName?.fieldSource,
        policyNumberSource: formValues.policyNumber?.fieldSource,
        expirationSource: formValues.expirationDate?.fieldSource,
        sendEmail: formValues.sendEmail,
      };

      // Update this newly updated private policy locally locally in the private policy list
      updatePrivatePolicy(updatedPrivatePolicy);

      enqueueSnackbar(`Private policy denied`, {
        variant: "success",
      });
      handleClose();
    } catch (err) {
      Sentry.captureException(err);

      enqueueSnackbar(
        <Box>
          <Typography fontWeight={700}>
            Something went wrong while denying private policy.
          </Typography>
          <Typography>{err.response?.data?.error}</Typography>
        </Box>,
        { variant: "info" },
      );
    } finally {
      setSubmittingType(null);
    }
  };

  const handleArchive = async () => {
    setSubmittingType(PrivatePolicyValidationStatus.archived);

    try {
      await PrivatePolicyService.updateStatus({
        generalUnitId: privatePolicy.generalUnitId,
        privatePolicyId: privatePolicy.id,
        validationStatus: PrivatePolicyValidationStatus.archived,
      });

      const index = privatePolicies.findIndex(
        (policy) => policy.id === privatePolicy.id,
      );

      const updatedPrivatePolicy = {
        ...privatePolicies[index],
        validationStatus: PrivatePolicyValidationStatus.archived as string,
      };

      // Update this newly updated private policy locally locally in the private policy list
      updatePrivatePolicy(updatedPrivatePolicy);

      enqueueSnackbar(`Private policy archived`, {
        variant: "success",
      });
      handleClose();
    } catch (err) {
      Sentry.captureException(err);

      enqueueSnackbar(
        <Box>
          <Typography fontWeight={700}>
            Something went wrong while archiving private policy{" "}.
          </Typography>
          <Typography>{err.response?.data?.error}</Typography>
        </Box>,
        { variant: "info" },
      );
    } finally {
      setSubmittingType(null);
    }
  };

  // None of the fields are checked as "invalid"
  const allFieldsValid =
    methods
      .watch([
        "tenantName.invalid",
        "policyNumber.invalid",
        "expirationDate.invalid",
      ])
      .filter((field: boolean | string | undefined) => !field).length === 3;

  // All the fields have been selected and have a value
  // primarily used for catching if an manually entered value is empty
  const allFieldsSelected =
    methods
      .watch([
        "tenantName.fieldValue",
        "policyNumber.fieldValue",
        "expirationDate.fieldValue",
        "tenantName.manualOverrideValue",
        "policyNumber.manualOverrideValue",
        "expirationDate.manualOverrideValue",
      ])
      .filter((field: string | undefined) => field?.length > 0).length >= 3;

  // All field sources are not "invalid" i.e. the user last checked one of the rows
  const allFieldSourcesValid =
    methods
      .watch([
        "tenantName.fieldSource",
        "policyNumber.fieldSource",
        "expirationDate.fieldSource",
      ])
      .filter(
        (fieldSource: string | undefined) =>
          fieldSource?.length > 0 && fieldSource !== "invalid",
      ).length === 3;

  const needsReview =
    privatePolicy?.validationStatus === "humanReview" ||
    !privatePolicy?.validationStatus;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="xl"
      fullWidth
      PaperProps={{
        sx: {
          p: 4,
        },
      }}
    >
      <FormProvider {...methods}>
        <form onSubmit={() => null}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
              >
                <Stack direction="row" spacing={2} alignItems="center">
                  <Typography variant="h5">
                    Submission ID: {privatePolicy?.id}
                  </Typography>
                  {privatePolicy && (
                    <PrivatePolicyStatusChip
                      status={privatePolicy?.validationStatus}
                      showInfo={needsReview}
                    />
                  )}
                </Stack>
                <IconButton onClick={handleClose}>
                  <Close />
                </IconButton>
              </Stack>
            </Grid>
            <Grid item xs={4}>
              {needsReview && (
                <PrivatePolicyReviewForm privatePolicy={privatePolicy} />
              )}
              {!needsReview && privatePolicy && (
                <PrivatePolicyDetailsList privatePolicy={privatePolicy} />
              )}
            </Grid>
            <Grid item xs={8}>
              <Box
                sx={{
                  borderRadius: 2,
                  border: "1px solid",
                  borderColor: (theme: Theme) => theme.palette.divider,
                  width: "100%",
                  height: 617,
                  overflow: "hidden",
                  boxShadow: "0 0 20px rgba(0,0,0,0.05)",
                }}
              >
                <PDFViewer
                  file={attachmentLink.data?.getAttachmentEphemeralLink.url}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Divider
                sx={{ bgcolor: (theme: Theme) => theme.palette.divider }}
              />
            </Grid>
            <Grid item xs={12}>
              <ReviewPrivatePolicyActions
                handleApprove={handleApprove}
                handleReject={handleReject}
                handleArchive={handleArchive}
                submittingType={submittingType}
                disablePrimaryActions={
                  privatePolicy?.validationStatus ===
                    PrivatePolicyValidationStatus.accepted ||
                  privatePolicy?.validationStatus ===
                    PrivatePolicyValidationStatus.rejected ||
                  privatePolicy?.validationStatus ===
                    PrivatePolicyValidationStatus.archived
                }
                allFieldsValid={allFieldsValid}
                allFieldsSelected={allFieldsSelected}
                allFieldSourcesValid={allFieldSourcesValid}
              />
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </Dialog>
  );
}
