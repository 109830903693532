import http from './http-common'
import { Notification } from '../utilities/generated/gql-types'

class NotificationsDataService {
  deleteNotifications(notificationsToDelete: Notification[]) {
    return http.post('notifications/delete', notificationsToDelete)
  }
}

export default new NotificationsDataService();
