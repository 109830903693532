import { Circle } from "@mui/icons-material";
import Check from "@mui/icons-material/Check";
import { StepIcon, StepIconProps } from "@mui/material";
import { green } from "@mui/material/colors";
import { PropsWithChildren } from "react";

interface SafeLeaseStepIconProps extends StepIconProps {}

export function SafeLeaseStepIcon({
  children,
  active,
  completed,
  icon,
  ...props
}: PropsWithChildren<SafeLeaseStepIconProps>) {
  return (
    <StepIcon {...props} active={active} completed={completed} icon={completed ? <Check sx={{ color: green[500] }} /> : icon} />
  );
}
