import { useAuth } from "../../auth";

// Wrapper component that only renders its children if the user is an admin
export const AdminsOnly = ({ children }: { children: React.ReactNode }) => {
  const auth = useAuth();

  if (!auth.user.isAdmin) {
    return null;
  }

  return <>{children}</>;
};
