export const fmsData = {
  sitelink: {
    name: 'SiteLink',
    image: '/images/no-data-zero-state.svg',
    sections: {
      "Login credentials": ['corpCode', 'username', 'password'],
    }
  },
  storedge: {
    name: 'storEDGE',
    image: '/images/no-data-zero-state.svg',
    sections: {
      "Login credentials": ['username', 'password'],
    }
  },
  ess: {
    name: 'Easy Storage Solutions',
    image: '/images/no-data-zero-state.svg',
    sections: {
      "Login credentials": ['url', 'username', 'password'],
    }
  },
  wss: {
    name: 'Web Self Storage',
    image: '/images/no-data-zero-state.svg',
    sections: {
      "Login credentials": ['username', 'password'],
    }
  },
  ssm: {
    name: 'Self Storage Manager',
    image: '/images/no-data-zero-state.svg',
    sections: {
      "API credentials": ['apiUrl', 'apiUsername', 'apiPassword'],
      "Login credentials": ['url', 'username', 'password'],
    },
  },
  cubby: {
    name: 'Cubby',
    image: '/images/no-data-zero-state.svg',
    sections: {
      '': ['organizationId'],
    },
  },
  unsupported: {
    name: 'Unsupported Software',
    image: '/images/no-data-zero-state.svg',
    sections: {
      "Login credentials": ['unsupportedFmsType', 'url', 'username', 'password'],
    }
  },
};

export const attributeLabels = {
  displayName: "Display Name",
  corpCode: "Corp Code",
  unsupportedFmsType: "Software Type",
  url: "Web Address",
  username: "Username",
  password: "Password",
  apiUrl: "API Web Address",
  apiUsername: "API Username",
  apiPassword: "API Password",
  organizationId: "Organization",
};
