import {
  GridToolbarExport,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarColumnsButton,
} from "@mui/x-data-grid-premium";

interface PrivatePolicyDataGridToolbarProps {}

/* Custom toolbar for displaying data-grid related actions at the top of the data grid */
export function PrivatePolicyDataGridToolbar({}: PrivatePolicyDataGridToolbarProps) {
  return (
    <GridToolbarContainer sx={{ p: 2 }}>
      <GridToolbarFilterButton
        sx={{ textTransform: "none", color: "rgba(140, 140, 140, 1)" }}
      />
      <GridToolbarColumnsButton
        sx={{ textTransform: "none", color: "rgba(140, 140, 140, 1)" }}
      />
      <GridToolbarExport
        printOptions={{ disableToolbarButton: true }} 
        sx={{
          textTransform: "none",
          ml: "auto",
          color: "rgba(140, 140, 140, 1)",
        }}
      />
    </GridToolbarContainer>
  );
}
