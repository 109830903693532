import { PortfolioView } from "./pages";
import { ReputationPreActivationPage } from "./pages/PreActivation";
import { useReputation } from "./useReputation";
import { useEffect, useState } from "react";
import { useAuth } from "./../auth.js";
import { QueryResult, useQuery } from "@apollo/client";
import { getRelationship } from "../queries";
import RouterHelper from "../utilities/router-helper";
import { Backdrop, CircularProgress } from "@mui/material";
import { useParams } from "react-router-dom";
import Loader from "../shared/Loader";
import Error from "../shared/Error";

// Wrapping the portfolio view for name's sake, future context wrappers, etc.
export function ReputationManagement({ routerHelper }: { routerHelper: RouterHelper }) {
  const { portfolio, setSelectedRelationshipId, loadingRelationship, setReputationRoute } = useReputation();
  const [syncError] = useState<boolean>(false);

  // Get relationshipId from the URL query parameters or from the auth user
  const auth = useAuth();
  const { relationshipId: relationshipIdFromUrl } = useParams<{ relationshipId?: string }>();
  const relationshipIdFromAuth = auth.user.relationshipId;
  const relationshipId = relationshipIdFromUrl ?? relationshipIdFromAuth;

  useEffect(() => {
    if (relationshipId) {
      setSelectedRelationshipId(relationshipId);
      setReputationRoute(routerHelper.reputationRoute());
    }
  }, [relationshipId]);

  const relationshipQueryResult: QueryResult = useQuery(getRelationship, {
    variables: { id: relationshipId },
    skip: !relationshipId,
  });

  if (relationshipQueryResult.loading) {
    return <Loader />;
  }
  if (relationshipQueryResult.error) {
    return <Error />;
  }

  // If the user hasn't gone through setup, show the marketing modal
  const hasCompletedSetup = !!portfolio;

  return (
    <>
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1, display: "" }} open={loadingRelationship}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {(!hasCompletedSetup || syncError) && <ReputationPreActivationPage routerHelper={routerHelper} />}
      {hasCompletedSetup && !syncError && <PortfolioView routerHelper={routerHelper} />}
    </>
  );
}
