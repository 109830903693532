export const TableStyles = {
  header: {
    fontFamily: 'Open Sans',
    fontSize: '11px',
    fontWeight: 'medium',
    color: 'rgba(21, 39, 68, 0.5)',
    padding: '0.25rem',
  },
  cell: {
    fontFamily: 'Open Sans',
    fontSize: '12px',
    fontWeight: 'medium',
    color: '#152744',
    padding: '0.25rem',
  },
  wrapper: {
    backgroundColor: 'white',
    border: '1px solid #F5F7FC',
    borderRadius: '4px',
    padding: '0.5rem'
  },
  sticky: {
    position: 'sticky',
    left: 0,
    background: 'white',
    zIndex: 100,
  },
};