import { useEffect, useState } from "react";
import { TableWrapperProps } from "./table-wrapper";
import { withSize } from 'react-sizeme';

const TableWrapperVirtualized = ({
  isSubTable,
  columns,
  title,
  children,
  preTable,
  cellPadding,
  sx,
  tableHeight = 400,
  size
}: TableWrapperProps) => {

  const [height, setHeight] = useState(size.height);

  useEffect(() => {
    // We want to set the height just once
    if (height || !size.height) return
    setHeight(size.height)

  }, [])

  return (
    <div
      className={`${
        isSubTable ? "tw-border-solid tw-border-[#CBDFF6] tw-rounded-[4px]" : ""
      }`}
      style={{ flexGrow: 1 }}
    >
      <div className="widget widget__long">
        <div className="widget-content" style={{ height: height }}>
          {preTable}
          {children}
        </div>
      </div>
    </div>
  );
};

export default withSize({ monitorHeight: true })(TableWrapperVirtualized);
