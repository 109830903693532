import React, { useState } from "react";

// UI - libs
import { MenuItem, Select } from "@mui/material";

// Data
import { Llc, User } from "../../../../utilities/generated/gql-types";
import _ from "lodash";
import { Box } from "@mui/system";

interface SelectBillingEntityDropdownProps {
  llcs: Llc[];
  value: Llc[];
  user: User;
}

export default function SelectBillingEntityDropdown({
  llcs = [],
  value,
  user,
}: SelectBillingEntityDropdownProps) {

  const [open, setOpen] = useState<boolean>(false);

  // # todo llcs and value are redundant at the moment`

  const userIsBillingContactForLlcs = llcs.filter((llc) => {
    if (!llc.nameOnBankAccount && !llc.email) return false;
    return user.name?.toLowerCase() === llc.nameOnBankAccount?.toLowerCase() || user.email?.toLowerCase() == llc.email?.toLowerCase();
  })

  if (userIsBillingContactForLlcs.length === 0) return (
    <Box sx={{ px: 2, fontFamily: "Open Sans" }}>
      -
    </Box>
  );

  if (userIsBillingContactForLlcs.length === 1) return (
    <Box sx={{ px: 2, fontFamily: "Open Sans" }}>
      {userIsBillingContactForLlcs[0].name}
    </Box>
  )

  return (
    <Select
      sx={{
        width: "100%",
        "& fieldset": {
          // override base border (no pseudo classes)
          border: "none",
        },
        "&:hover fieldset": {
          border: "none !important", // Hovered pseudo class
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          border: "none !important", // Focused pseudo class (menu is open)
        },
      }}
      labelId="location-select-label"
      id="location-select"
      multiple
      displayEmpty
      value={userIsBillingContactForLlcs}
      renderValue={(selected) => {
        if (selected.length === 0) return <em>-</em>;

        return (
          <span
            style={{
              color: "rgba(3,30,48,1)",
              fontFamily: "Open Sans",
              fontSize: 14,
            }}
          >
            {selected.length > 1
              ? `${selected.length} entities`
              : selected[0]?.name }
          </span>
        );
      }}
    >
      {userIsBillingContactForLlcs?.map((llc) => (
        <MenuItem
          key={llc.id}
          value={llc.id}
          sx={{
            color: "rgba(3,30,48,1)",
            fontFamily: "Open Sans",
            fontSize: 14,
          }}
        >
          {llc.name}
        </MenuItem>
      )) || []}
    </Select>
  );
}
