import { HelpOutline } from "@mui/icons-material";
import { Chip, Tooltip } from "@mui/material";
import { grey } from "@mui/material/colors";

interface PrivatePolicyStatusChipProps {
  status: string;
  showInfo?: boolean;
}
/**
 * Component that displays a chip with varying colors and labels based on the
 * status of the private policy.
 */
export function PrivatePolicyStatusChip({
  status,
  showInfo = false,
}: PrivatePolicyStatusChipProps) {
  const { label, ...sx }: StatusConfig = statusConfigs[status]
    ? statusConfigs[status]
    : defaultStatusConfig;

  if (!showInfo) {
    return <Chip size="small" label={label} sx={{ ...sx, fontWeight: 600 }} />;
  }

  return (
    <Tooltip title="Policies we cannot automatically validate based on user inputs or AI-extracted are sent to human review.">
      <Chip
        size="small"
        label={label}
        sx={{ ...sx, fontWeight: 600 }}
        icon={<HelpOutline />}
      />
    </Tooltip>
  );
}

// Status config visuals
type StatusConfig = {
  label: string;
  color: string;
  bgcolor: string;
};

const statusConfigs: {
  [key: string]: StatusConfig;
} = {
  humanReview: {
    label: "Flagged",
    color: "rgb(3, 30, 48)",
    bgcolor: "rgb(255, 195, 0)",
  },
  accepted: {
    label: "Accepted",
    color: "white",
    bgcolor: "rgb(37, 155, 36)",
  },
  rejected: {
    label: "Rejected",
    color: "white",
    bgcolor: "rgb(233,100,95)",
  },
  pendingRejected: {
    label: "Rejected",
    color: "white",
    bgcolor: "rgb(233,100,95)",
  },
  archived: {
    label: "Archived",
    color: "rgb(3, 30, 48)",
    bgcolor: grey[100],
  },
  null: {
    label: "Flagged",
    color: "rgb(3, 30, 48)",
    bgcolor: "rgb(255, 195, 0)",
  },
};

const defaultStatusConfig: StatusConfig = {
  label: "Flagged",
  color: "rgb(3, 30, 48)",
  bgcolor: "rgb(255, 195, 0)",
};
