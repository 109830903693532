// UI - libs
import { Divider, Grid, Skeleton, Stack, Theme, Typography, useTheme } from "@mui/material";
import { SafeLeaseButton, SafeLeaseCard, SafeLeaseFormTextField } from "@safelease/components";

// Hooks
import { useEffect, useState } from "react";
import { enqueueSnackbar } from "notistack";
import { useReputation } from "../../../../useReputation";

// Data
import { reactQueryClient } from "../../../../../utilities/reactQueryClent";
import { Facility_Reputation as Facility } from "@safelease/service-utilities";

// Utils
import axios from "axios";
import { FormProvider, useForm } from "react-hook-form";
import z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

interface FacilityInformationProps {}

const settingsSchema = z.object({
  companyName: z.string().nonempty("Company name is required; it will be displayed in communications."),
  address: z.string().nonempty("Address is required."),
  city: z.string().nonempty("City is required."),
  state: z.string().min(2, "Enter 2-digit state code.").max(2, "Enter 2-digit state code."),
  postalCode: z.string().min(5, "Postal code must be 5 digits.").max(5, "Postal code must be 5 digits."),
  companyEmail: z.string().email("Must be an email.").nonempty("Company email required"),
  notificationEmail: z.string().email("Must be an email."),
});

export function FacilityInformation({}: FacilityInformationProps) {
  const theme = useTheme();

  const [tempFile, setTempFile] = useState<File | null>(null);
  const [submitting, setSubmitting] = useState<boolean>(false);

  const { facility, statuses, SafeLeaseAPI } = useReputation();

  const facilityForm = useForm({
    defaultValues: {
      companyName: "",
      address: "",
      city: "",
      state: "",
      postalCode: "",
      companyEmail: "",
      notificationEmail: "",
    },
    resolver: zodResolver(settingsSchema),
  });

  // Apply fetched form values to form
  useEffect(() => {
    if (facility) {
      facilityForm.reset({
        companyName: facility.companyName,
        address: facility.address,
        city: facility.city,
        state: facility.state,
        postalCode: facility.postalCode,
        companyEmail: facility.companyEmail,
        notificationEmail: facility.notificationEmail,
      });
    }
  }, [facility, facilityForm]);

  const handleLogoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // if user cancels file selection, do nothing
    if (e.target.files?.[0]) {
      const file = e.target.files[0];
      if (file.type === "image/jpeg" || file.type === "image/png") {
        setTempFile(file);
      } else {
        setTempFile(null);
        e.target.value = "";
        enqueueSnackbar("Only JPEG and PNG files are allowed. Please choose a different file.", { variant: "error" });
      }
    }
  };

  const handleSubmit = async (data: Facility) => {
    setSubmitting(true);
    if (tempFile) {
      const targetS3Path = `public/facilities/${facility.id}/largeLogo/${tempFile.name}`;

      // Get a presigned URL to do the file upload for the new logo
      const presignedUrlResponse = await SafeLeaseAPI.reputation.getPresignedUrl(targetS3Path, tempFile.type);
      const { presignedUrl } = presignedUrlResponse.data;

      try {
        // handle file upload of the new logo
        const response = await axios.put(presignedUrl, tempFile, {
          headers: {
            "Content-Type": tempFile.type,
          },
        });
        const imageUrl = presignedUrl.split("?")[0]; // url without query params is location of s3 resource
        data.logoUrlLarge = imageUrl;
        data.logoUrlSmall = imageUrl; // use imageUrl for both large and small logo until we create different upload for small
      } catch (err) {
        console.log(err.message);
        enqueueSnackbar("Failed to store logo. Please try again.", { variant: "error" });
      }
    }

    try {
      await SafeLeaseAPI.reputation.updateFacilityDetails(facility.id, data);
      reactQueryClient.invalidateQueries({
        queryKey: ["facilityDetails", facility.id],
      });
      enqueueSnackbar("Facility details updated successfully", { variant: "success" });
    } catch {
      enqueueSnackbar("Failed to update facility details. Please try again.", { variant: "error" });
    } finally {
      setSubmitting(false);
    }
  };

  if (statuses.facility === "loading") {
    return <Skeleton variant="rectangular" sx={{ height: 400, width: 700 }} />;
  }

  const errors = facilityForm.formState.errors;

  return (
    <SafeLeaseCard sx={{ p: 2, width: 700 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6">Manage Facility Details</Typography>
          <Typography sx={{ color: (theme: Theme) => theme.palette.grey.A100 }}>
            Manage your brand information, logo, contact information, and more.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <FormProvider {...facilityForm}>
            <form onSubmit={facilityForm.handleSubmit(handleSubmit)}>
              <fieldset disabled={submitting}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Stack direction="row" alignItems="center" spacing={2}>
                      {(tempFile || facility?.logoUrlLarge) && (
                        <img
                          src={tempFile ? URL.createObjectURL(tempFile) : facility?.logoUrlLarge}
                          style={{
                            width: "auto",
                            height: "auto",
                            maxWidth: 400,
                            maxHeight: 100,
                            border: `1px solid #EBEFF7`,
                            borderRadius: theme.shape.borderRadius,
                          }}
                        />
                      )}
                      <SafeLeaseButton variant="outlined" size="small" color="navy" role={undefined} component="label">
                        Choose Logo
                        <input
                          onChange={handleLogoChange}
                          accept="image/*"
                          multiple={false}
                          type="file"
                          style={{
                            clip: "rect(0 0 0 0)",
                            clipPath: "inset(50%)",
                            height: 1,
                            overflow: "hidden",
                            position: "absolute",
                            bottom: 0,
                            left: 0,
                            whiteSpace: "nowrap",
                            width: 1,
                          }}
                        />
                      </SafeLeaseButton>
                    </Stack>
                  </Grid>
                  <Grid item xs={12}>
                    <SafeLeaseFormTextField
                      fullWidth
                      name="companyName"
                      label="Facility Name"
                      helperText={errors.companyName?.message}
                      error={!!errors.companyName?.message}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <SafeLeaseFormTextField
                      fullWidth
                      name="address"
                      label="Facility Address"
                      helperText={errors.address?.message}
                      error={!!errors.address?.message}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <SafeLeaseFormTextField
                      name="city"
                      label="City"
                      fullWidth
                      helperText={errors.city?.message}
                      error={!!errors.city?.message}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <SafeLeaseFormTextField
                      name="state"
                      label="State"
                      fullWidth
                      helperText={errors.state?.message}
                      error={!!errors.state?.message}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <SafeLeaseFormTextField
                      name="postalCode"
                      label="Postal Code"
                      fullWidth
                      helperText={errors.postalCode?.message}
                      error={!!errors.postalCode?.message}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <SafeLeaseFormTextField
                      name="companyEmail"
                      label="Company Email"
                      fullWidth
                      helperText={errors.companyEmail?.message}
                      error={!!errors.companyEmail?.message}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <SafeLeaseFormTextField
                      name="notificationEmail"
                      label="Notification Email"
                      description="Receives notifications when private reviews are submitted"
                      fullWidth
                      helperText={errors.notificationEmail?.message}
                      error={!!errors.notificationEmail?.message}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Stack direction="row" justifyContent="flex-end">
                      <SafeLeaseButton color="blue" variant="contained" sx={{ ml: "auto" }} type="submit">
                        Save
                      </SafeLeaseButton>
                    </Stack>
                  </Grid>
                </Grid>
              </fieldset>
            </form>
          </FormProvider>
        </Grid>
      </Grid>
    </SafeLeaseCard>
  );
}
