import { Link, useLocation } from 'react-router-dom';
import { NavigationRoute } from './routes';
import { Box, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import * as NavIcons from './icons';
import { mixpanelEventHandler } from '../utilities/reactMixpanelHandler';
import { useState, useMemo } from 'react';
import { NotificationDot } from '../common';

const safeleasePrimaryColor = '#3781FB';
export function NavigationDrawerListItem({
  path,
  label,
  icon,
  hasNotification,
  isChild,
}: NavigationRoute & { isChild?: boolean }) {
  const location = useLocation();
  const [isHovered, setIsHovered] = useState(false);
  const isSelected = useMemo(() => {
    if (path === '/') return location.pathname === '/';
    return location.pathname.includes(path);
  }, [location.pathname, path]);

  const handleClick = () => {
    mixpanelEventHandler('Navigation Drawer Click', {
      item: label,
      hasNotification,
    });
  };

  return (
    <Link to={path} style={{ textDecoration: 'none' }}>
      <ListItem
        key={label}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={handleClick}
        sx={{
          ...navigationDrawerListItemStyles,
          bgcolor: isSelected ? safeleasePrimaryColor : 'initial',
          '&:hover': {
            bgcolor: isSelected ? safeleasePrimaryColor : '#635BFF0A',
          },
          '&::before': {
            ...navigationDrawerListItemStyles['&::before'],
            backgroundColor: isSelected ? safeleasePrimaryColor : '#635BFF0A',
          },
        }}
      >
        {icon ? (
          <ListItemIcon
            sx={{
              color: isSelected ? 'white' : isHovered ? safeleasePrimaryColor : '#8A94A6',
              minWidth: 44,
            }}
          >
            {NavIcons[icon]()}
          </ListItemIcon>
        ) : (
          // This is a spacer for the absence of an icon.
          <>{!isChild && <Box sx={{ minWidth: 44 }} />}</>
        )}
        <ListItemText
          primary={label}
          primaryTypographyProps={{
            fontSize: 12,
            fontFamily: 'Open Sans',
            color: isSelected ? 'white' : isHovered ? safeleasePrimaryColor : '#434A60',
          }}
        />
        {hasNotification && <NotificationDot />}
      </ListItem>
    </Link>
  );
}

const navigationDrawerListItemStyles = {
  width: '95%',
  height: '36px',
  color: 'white',
  my: 0.5,
  mx: 0.6,
  cursor: 'pointer',
  borderRadius: '8px',
  position: 'relative', // Add this
  '&::before': {
    content: '""',
    position: 'absolute',
    left: '-1.5rem',
    top: 0,
    height: '100%',
    width: '3px',
    backgroundColor: safeleasePrimaryColor,
    borderRadius: '2px',
    transition: 'opacity 0.2s',
  },
};
