import { StateCreator } from "zustand";
import { PlanUpgradeStepWithUnitCount } from "../ScheduleUpgradeForm/ReviewStep";

export interface PlanUpgradeSettingsPlanStepsSliceState {
  /* Total list of premium/coverage movements that could in theory be required to execute */
  planUpgradeSteps: PlanUpgradeStepWithUnitCount[];

  /* Specific premium/coverage movements to omit from the plan upgrade */
  omitPlanUpgradeSteps: PlanUpgradeStep[];
}

export interface PlanUpgradeSettingsPlanStepsSlice
  extends PlanUpgradeSettingsPlanStepsSliceState {
  setPlanUpgradeSteps: (planUpgradeSteps: PlanUpgradeStepWithUnitCount[]) => void;
  setOmitPlanUpgradeSteps: (planUpgradeStep: PlanUpgradeStep) => void;
}

const initialState: PlanUpgradeSettingsPlanStepsSliceState = {
  planUpgradeSteps: [],
  omitPlanUpgradeSteps: [],
};

/**
 * Control which plan upgrade steps are selected in the plan upgrade form
 * We want to first get a list of all potential SKU movements
 * Then allow the user to specifically omit individual SKU movements
 *
 * EXAMPLE
 * ----------------------------------------------------
 * New standard plans are $12/$17/$27
 * Current units are on plans of $7.95, $9, $10, $15, $27
 * We want to move all units onto one of these plans, except $7.95
 * `planUpgradeSteps` would be an array of:
 * - $7.95  -> $9     ({ oldPremium: 795, oldCoverage: 200000, newPremium: 900, newCoverage: 200000 })
 * - $9.00  -> $12    ({ oldPremium: 900, oldCoverage: 200000, newPremium: 1200, newCoverage: 200000 })
 * - $10.00 -> $12    ({ oldPremium: 1000, oldCoverage: 200000, newPremium: 1200, newCoverage: 200000 })
 * - $15.00 -> $17.00 ({ oldPremium: 1500, oldCoverage: 300000, newPremium: 1700, newCoverage: 300000 })
 *
 * - $27.00 -> $27.00 is not included because there is no movement
 *
 * `omitPlanUpgradeSteps` would be an array of:
 * - $7.95 -> $9      ({ oldPremium: 795, oldCoverage: 200000, newPremium: 900, newCoverage: 200000 })
 *
 * This instructs the eventually constructed plan upgrade request to exclude the $7.95 -> $9 movement
 */
export const createPlanUpgradeSettingsPlanStepsSlice: StateCreator<
  PlanUpgradeSettingsPlanStepsSlice
> = (set, get) => {
  return {
    ...initialState,

    /* If the plan upgrade step exists remove it, otherwise add it */
    setPlanUpgradeSteps: (planUpgradeSteps: PlanUpgradeStepWithUnitCount[]) => {
      set({ planUpgradeSteps })
    },

    /* If the plan upgrade step omission exists, remove it, otherwise add it */
    setOmitPlanUpgradeSteps: (planUpgradeStep: PlanUpgradeStep) => {
      const index = get().omitPlanUpgradeSteps.findIndex(
        (omission) =>
          omission.oldCoverage === planUpgradeStep.oldCoverage &&
          omission.oldPremium === planUpgradeStep.oldPremium
      );
      if (index !== -1) {
        // Remove the migration from the omissions list
        set({
          omitPlanUpgradeSteps: get().omitPlanUpgradeSteps.filter(
            (_, i) => i !== index
          ),
        });
      } else {
        // Add the migration to the omissions list
        set({
          omitPlanUpgradeSteps: [
            ...get().omitPlanUpgradeSteps,
            planUpgradeStep,
          ],
        });
      }
    },
  };
};
