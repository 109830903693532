import { useState } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { getInternalNotes, getChanges } from '../queries';
import { useAuth } from '../auth';
import AdminDataService from '../services/admin.service';
import TableWrapper from '../shared/table-wrapper';
import SortingTable from '../shared/sorting-table';
import { internalNotesColumnDefs, changesColumnDefs } from '../utilities/column-defs';
import Button from '@mui/material/Button';
import { SafeleaseTextField } from '../common';
import { ghostButtonNavy, muiRedButton } from '../styles/mui-overrides';
import TitleHeader from '../shared/title-header';
import Loader from '../shared/Loader'
import Error from '../shared/Error'
import '../styles/admin.scss';

export function Notes(props) {
  const params: { relationshipId: string; locationId?: string; llcId?: string } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const locationId = searchParams.get('locationId');
  const llcId = searchParams.get('llcId');
  const { relationshipId } = params;
  const notes = useQuery(getInternalNotes, {
    variables: { relationshipId, locationId, llcId },
  });
  const changes = useQuery(getChanges, {
    variables: { relationshipId, locationId, llcId },
  });

  const auth = useAuth();
  const history = useHistory();
  const [newNoteOpen, setNewNoteOpen] = useState(false);
  const [newNoteContent, setNewNoteContent] = useState('');

  if (notes.loading || changes.loading) return <Loader />;
  if (notes.error || changes.error) return <Error />;

  const submitNewNote = async () => {
    await AdminDataService.createNote({
      relationshipId,
      locationId,
      llcId,
      content: newNoteContent,
    });
    setNewNoteContent('');
    setNewNoteOpen(false);
    notes.refetch();
  };

  return (
    <>
      <TitleHeader title="Notes and Changes" />
      <div className="notes">
      <h6>Notes</h6>
      <div className="tw-flex">
        <Button
          onClick={() => history.goBack()}
          sx={{
            ...ghostButtonNavy,
            height: '36px',
            marginTop: '15px',
            marginRight: '0.25rem',
            marginBottom: '0.5rem',
          }}
        >
          Back
        </Button>
        {!newNoteOpen && (
          <Button
            onClick={() => setNewNoteOpen(true)}
            sx={{ ...muiRedButton({}), marginLeft: '.25rem' }}
          >
            New Note
          </Button>
        )}
      </div>
      {newNoteOpen && (
        <>
          <div className="tw-mt-2">
            <SafeleaseTextField
              showLabel={true}
              innerLabel="Add a New Note"
              onChange={(e) => setNewNoteContent(e.target.value)}
              defaultValue={newNoteContent}
            />
          </div>
          <Button
            onClick={() => setNewNoteOpen(false)}
            sx={{ ...ghostButtonNavy, height: '36px', marginRight: '0.25rem' }}
          >
            Cancel
          </Button>
          <Button sx={{ ...muiRedButton({}), marginLeft: '.25rem' }} onClick={submitNewNote} disabled={!newNoteContent}>
            Submit
          </Button>
        </>
      )}
      
      <TableWrapper columns={internalNotesColumnDefs} title="Notes">
        <SortingTable
          title="Notes"
          queryResultData={notes.data?.getInternalNotes}
          columns={internalNotesColumnDefs}
        />
      </TableWrapper>
      </div>
      <div className="changes">

      <h6>Changes</h6>
      <TableWrapper columns={changesColumnDefs} title="Changes">
        <SortingTable
          title="Changes"
          queryResultData={changes.data?.getChanges}
          columns={changesColumnDefs}
        />
      </TableWrapper>
      </div>
    </>
  );
}
