export const billingStatuses = {
  special: {
    label: 'Special Case',
  },
  verified: {
    label: 'Verified',
  },
  unsetup: {
    label: 'No Information',
  },
  partiallyVerified: {
    label: 'Partially Verified',
  },
};
