// UI - libs
import { Box } from "@mui/material";

// UI - internal
import Loader from "../../../shared/Loader";

// Hooks & State
import { useGeneralUnitsHistory } from "../useGeneralUnitsHistory";

// Data
import { useQuery } from "@apollo/client";
import { getGeneralUnitHistory } from "../../../queries";
import {
  GeneralUnitHistoryItem,
} from "../../../utilities/generated/gql-types";

// Libs - other
import _ from "lodash";
import GeneralUnitHistoryTimelineItem from "./GeneralUnitHistoryTimelineItem";

export default function GeneralUnitHistoryTimeline() {
  const { selectedGeneralUnit } = useGeneralUnitsHistory();

  const generalUnitsHistoryQuery = useQuery<{
    getGeneralUnitsHistoryItems: GeneralUnitHistoryItem[];
  }>(getGeneralUnitHistory, {
    variables: { generalUnitId: selectedGeneralUnit?.id },
    skip: !selectedGeneralUnit,
  });

  if (generalUnitsHistoryQuery.loading) return <Loader />;
  if (!selectedGeneralUnit) return null;

  const generalUnitsHistoryItems =
    generalUnitsHistoryQuery.data?.getGeneralUnitsHistoryItems;

  return (
    <Box>
      <GeneralUnitHistoryTimelineItem
        currItem={selectedGeneralUnit}
        prevItem={generalUnitsHistoryItems[0]}
        latestVersion
      />
      {generalUnitsHistoryItems
        ?.slice(1)
        .map((generalUnitHistoryItem, prevItemIndex) => (
          <GeneralUnitHistoryTimelineItem
            key={prevItemIndex}
            currItem={generalUnitHistoryItem}
            prevItem={generalUnitsHistoryItems[prevItemIndex]}
          />
        ))}
    </Box>
  );
}
