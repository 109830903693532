import { Card } from "@mui/material";
import React from "react";

const NoChangesDetectedCard = () => {
  return (
    <Card style={{ padding: "15px", display: "inline-block", marginBottom: "10px" }}>
      <h6>No changes to sync...</h6>
    </Card>
  )
};

export default NoChangesDetectedCard;
