// UI
import Loader from "./Loader";
import { FormControl, ListSubheader, MenuItem, Select } from "@mui/material";
import { muiSelect } from "../styles/mui-overrides";

// Data
import RouterHelper from "../utilities/router-helper";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import { useAuth } from "../auth";
import { QueryResult, useQuery } from "@apollo/client";
import { getRelationshipsSelector } from "../queries";
import {
  Relationship,
  RelationshipIdAndName,
} from "../utilities/generated/gql-types";
import { useState } from "react";

interface RelationshipSelectorProps {
  routerHelper: RouterHelper;

  // if using the routerHelper, this is the settings tab to navigate to
  settingsTab?: string;

  // If we want to use the relationship selector component
  // without dealing with routes, we can set nonRoute to true
  // and pass in a callback to handle changes
  nonRoute?: boolean;
  nonRouteCallback?: (relationshipId: string | number | null) => void;
  
  disabled?: boolean;
}

export default function RelationshipSelector({
  routerHelper,
  settingsTab = "userSettings",
  nonRoute = false,
  nonRouteCallback,
  disabled=false
}: RelationshipSelectorProps) {
  const history = useHistory();
  const auth = useAuth();
  const isAdmin = auth.user.isAdmin;
  const relationshipId =
    routerHelper.getRelationshipId() || auth.user.relationshipId;

  // Used for nonroute state management
  const [selectedRelationshipId, setSelectedRelationshipId] = useState<
    string | number
  >(relationshipId);

  const relationshipsSelectorQuery: QueryResult<{
    getRelationshipsSelector: Relationship;
  }> = useQuery(getRelationshipsSelector, {
    skip: !isAdmin,
  });

  if (!auth.user || !isAdmin) return null;

  if (relationshipsSelectorQuery.loading) return <Loader />;

  const groupedRelationships: { [key: string]: RelationshipIdAndName[] } =
    _.groupBy(
      relationshipsSelectorQuery.data?.getRelationshipsSelector || [],
      (relationship: Relationship) =>
        relationship.name?.charAt(0)?.toUpperCase()
    );

  return (
    auth.user &&
    isAdmin &&
    relationshipsSelectorQuery.data && (
      <FormControl sx={{ minWidth: 120 }}>
        <Select
          disabled={disabled}
          value={nonRoute ? selectedRelationshipId : relationshipId}
          displayEmpty
          onChange={(e) => {
            if (nonRoute) {
              setSelectedRelationshipId(e.target.value);
              nonRouteCallback && nonRouteCallback(e.target.value);
            } else {
              routerHelper.navigateToRoute(
                settingsTab,
                e.target.value,
                history
              );
            }
          }}
          sx={{
            ...muiSelect,
            marginLeft: 0,
            padding: "0px",
            border: '1px solid #EBEFF7',
            borderRadius: 2,
            boxShadow: '0 0 20px rgba(0,0,0,0.05)',
            fontSize: '14px',
            fontWeight: 'initial',
            height: 38.5,
            duration: "250ms",
            "& fieldset": {
              // override base border (no pseudo classes)
              border: "1px solid #EBEFF7",
              duration: "250ms",
            },
            "&:hover fieldset": {
              border: "1px solid rgba(0,0,0,0.23) !important", // Hovered pseudo class
              backgroundColor: 'rgba(0,0,0,0.05)',
              duration: "250ms !important",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              border: "1px solid #2879FB !important", // Focused pseudo class (menu is open)
            },
          }}
        >
          {Object.entries(groupedRelationships).map(
            ([letter, relationships]) => [
              <ListSubheader>{letter}</ListSubheader>,
              relationships.map((relationship) => (
                <MenuItem key={relationship.id} value={relationship.id}>
                  {relationship.name}
                </MenuItem>
              )),
            ]
          )}
        </Select>
      </FormControl>
    )
  );
}
