// This component automatically prefixes image URLs with PUBLIC_URL to use S3 for serving.
// When running in development mode, this is connected to code in frontend/src/setupProxy.js which looks for the handled=true parameter and only serves images if it is there.

function Image(props) {
  var src = props.src;
  
  if(!src.startsWith('http')) {
    if(process.env.NODE_ENV == 'production' && process.env.PUBLIC_URL) {
      src = process.env.PUBLIC_URL + src;
    } else {
      src += '?' + new URLSearchParams({handled: 'true'});
    }
  }
  
  return (
    <img {...props} src={src} />
  );
};

export { Image };
