import { Typography } from '@mui/material';

export function NavigationSectionHeader({ title }: { title: string }) {
  return (
    <Typography
      variant='overline'
      sx={{ textTransform: 'none', px: 1, color: '#434A60', letterSpacing: '0.025em' }}
    >
      {title}
    </Typography>
  );
}
