import { LinearProgress, Stack, Theme, Typography, linearProgressClasses } from "@mui/material";
import { amber } from "@mui/material/colors";

interface FacilityFeedbackCategoryRowProps {
  category: string;
  count: number;
  max: number;
}

export function FacilityFeedbackCategoryRow({ category, count, max }: FacilityFeedbackCategoryRowProps) {
  return (
    <Stack
      direction="row"
      spacing={0.5}
      sx={{ flex: 1, maxWidth: 700 }}
      alignItems="center"
    >
      <Typography variant="caption" sx={{ width: 150, textAlign: "right" }}>{category}</Typography>
      <LinearProgress
        variant="determinate"
        value={(count / max) * 100}
        sx={{
          flex: 1,
          [linearProgressClasses.bar]: {
            strokeLinecap: "round",
          },
          height: 10,
          borderRadius: 5,
          [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: (theme: Theme) =>
              theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
          },
          [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 5,
            backgroundColor: amber[400],
          },
        }}
      />
      <Typography
        variant="caption"
        sx={{ color: (theme: Theme) => theme.palette.grey.A100, width: 60 }}
      >
        {count}
      </Typography>
    </Stack>
  );
}