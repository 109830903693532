import { useState, SyntheticEvent } from "react";

// UI - Libs
import { InfoOutlined, Lens, SettingsOutlined } from "@mui/icons-material";
import { Box, Divider, IconButton, Stack, Theme, Tooltip, Typography, alpha } from "@mui/material";
import { grey } from "@mui/material/colors";

// UI - Internal
import { Metric, SafeLeaseCard, SafeLeaseChip, SafeLeaseSwitch } from "@safelease/components";
import { CampaignConfigurationDialog } from "./CampaignConfigurationDialog";

// Data
import { reactQueryClient } from "../../../utilities/reactQueryClent";
import { Campaign_Reputation as Campaign, CampaignMetrics } from "@safelease/service-utilities";
import { useReputation } from "../../useReputation";
import { AdminsOnly } from "../../components/AdminsOnly";
import { CampaignEmailConfigurationStepContent } from "./CampaignConfigurationDialog/CampaignEmailConfigurationStepContent";

interface CampaignCardProps {
  campaign: Campaign & { funnelMetrics: CampaignMetrics };
}

/**
 * Card that displays a campaign's name, description, metrics, and a toggle switch to enable/disable the campaign.
 */
export function CampaignCard({ campaign }: CampaignCardProps) {
  const { facilityMetrics, SafeLeaseAPI } = useReputation();

  const [disableInteraction, setDisableInteraction] = useState<boolean>(false);
  const [campaignConfigurationDialogOpen, setCampaignConfigurationDialogOpen] = useState<boolean>(false);

  const { id, name, description, enabled, funnelMetrics, campaignType, facilityId } = campaign;
  const { messageSendCount } = funnelMetrics;

  // Reviews attributed to this campaign
  const campaignReviews = facilityMetrics.reviews.filter((review) => review.campaignId === campaign.id);

  const handleActiveToggle = async (event: SyntheticEvent) => {
    if (disableInteraction) return;

    event.stopPropagation();
    event.preventDefault();
    setDisableInteraction(true);
    try {
      await SafeLeaseAPI.reputation.updateCampaign(id, { enabled: !enabled });
      reactQueryClient.invalidateQueries({ queryKey: ["facilityDashboard", facilityId] }); // refetch facility overview
    } finally {
      setDisableInteraction(false);
    }
  };

  const handleOpenCampaignConfigurationDialog = (event) => {
    // Disble clicking through to the underlying card
    event.preventDefault();
    event.stopPropagation();

    setCampaignConfigurationDialogOpen(true);
  };

  return (
    <>
      <SafeLeaseCard
        sx={{
          p: 2,
          cursor: "pointer",
          transition: "0.15s ease-in-out",
          "&:hover": {
            borderColor: (theme: Theme) => theme.palette.blue.main,
            backgroundColor: (theme: Theme) => alpha(theme.palette.blue.main, 0.05),
          },
          height: "100%",
        }}
        onClick={handleOpenCampaignConfigurationDialog}
      >
        <Stack direction="column" spacing={1} justifyContent={"space-between"} sx={{ height: "100%", width: "100%" }}>
          <Stack spacing={1}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Stack id="upper-right-label" direction="row" spacing={1} alignItems="center">
                <Lens
                  sx={{
                    color: (theme: Theme) => (enabled ? theme.palette.blue.main : theme.palette.grey.A100),
                    height: 10,
                    width: 10,
                  }}
                />
                <Typography sx={{ fontWeight: 700 }} variant="body2">
                  {name}
                </Typography>
                <Tooltip title={description}>
                  <InfoOutlined
                    sx={{
                      height: 16,
                      width: "auto",
                      color: (theme: Theme) => theme.palette.grey.A100,
                    }}
                  />
                </Tooltip>
              </Stack>
              <AdminsOnly>
                <Tooltip title="Visible only to Safelease Admins">
                  <Stack direction="row" alignItems="center" spacing={1}>
                    {campaignType === "recurring" && (
                      <SafeLeaseSwitch
                        disabled={disableInteraction}
                        checked={enabled}
                        onLabel="Live"
                        addWidth={14}
                        onChange={handleActiveToggle}
                        onClick={(e) => e.stopPropagation()}
                      />
                    )}
                    <Tooltip title="Edit campaign steps">
                      <IconButton size="small" onClick={handleOpenCampaignConfigurationDialog}>
                        <SettingsOutlined />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                </Tooltip>
              </AdminsOnly>
              {enabled && <SafeLeaseChip color="green" label="Enabled" sx={{ borderRadius: "50em" }} />}
              {!enabled && <SafeLeaseChip color="red" label="Disabled" sx={{ borderRadius: "50em" }} />}
            </Stack>
            <Typography sx={{ color: (theme: Theme) => theme.palette.grey.A100 }} variant="caption">
              {description}
            </Typography>
          </Stack>
          <Box sx={{ bgcolor: alpha(enabled ? "#EBEFF7" : grey[200], 0.6), p: 2, borderRadius: 2 }}>
            <Stack direction="row" spacing={4} divider={<Divider flexItem orientation="vertical" sx={{ borderColor: "#EBEFF7" }} />}>
              <Metric label="Messages sent" value={messageSendCount} fontWeight="700" />
              <Metric label="Reviews generated" value={campaignReviews.length} type="count" fontWeight="700" />
            </Stack>
          </Box>
        </Stack>
      </SafeLeaseCard>
      <CampaignConfigurationDialog
        open={campaignConfigurationDialogOpen}
        handleClose={() => setCampaignConfigurationDialogOpen(false)}
        campaign={campaign}
      />
    </>
  );
}
