import { Step, StepProps } from "@mui/material";
import { PropsWithChildren } from "react";

interface SafeLeaseStepProps extends StepProps { }

export function SafeLeaseStep({
  children,
  ...props
}: PropsWithChildren<SafeLeaseStepProps>) {
  return (
    <Step {...props}>
      {children}
    </Step>
  )
}