import { create } from "zustand";
import { persist } from "zustand/middleware";
import { useEffect } from "react";

/*
 * VisitedStoreState is the type of the Zustand store that keeps track of
 * whether the user has visited a page before.
 */
interface VisitedStoreState {
  pageVisits: Record<string, boolean>; // Record of page names to their visited status
  setVisited: (page: string, visited: boolean) => void;
}

/*
 * useVisitedStore is a Zustand store that keeps track of whether the user has
 * visited a page before. It is persisted to local storage so that the user's
 * visited pages are remembered across sessions.
 */
const useVisitedStore = create<VisitedStoreState>()(
  persist(
    (set) => ({
      pageVisits: {},
      setVisited: (page, visited) =>
        set((state) => ({
          pageVisits: { ...state.pageVisits, [page]: visited },
        })),
    }),
    {
      name: "visited-pages-store",
    }
  )
);

/*
 * useVisited is a hook that returns a boolean value indicating whether the
 * user has visited a page before. It also returns a function that can be
 * called to set the page as visited.
 *
 * @param pageName - The name of the page to check, 
 * can be any string as long as it makes sense for the page being tracked
 * and is unique from other page names.
 * 
 * @returns - An object containing the hasVisited boolean and a handleOnClick
 *            function to set the page as visited
 */
const useVisited = (pageName: string) => {
  const { pageVisits, setVisited } = useVisitedStore();

  useEffect(() => {
    if (pageVisits[pageName] === undefined) {
      setVisited(pageName, false);
    }
  }, [pageName, pageVisits, setVisited]);

  const handleOnClick = () => {
    setVisited(pageName, true);
  };

  return {
    hasVisited: pageVisits[pageName],
    handleOnClick,
  };
};

export default useVisited;
