import { Box, Grid, Typography } from "@mui/material";
import { EmployeeQRCode } from "./EmployeeQRCode";
import { ReputationUser } from "@safelease/service-utilities";
import _ from "lodash";
import { useQuery } from "@tanstack/react-query";
import { SafeLeaseAPI as SafeLeaseAPIDefaultEnvironment} from "../../../../SafeLeaseAPIInstance";

interface EmployeeActiveQRCodeTabProps {
  user: ReputationUser;
}

/**
 * Tab content displaying the active + inactive QR codes for an employee
 */
export function EmployeeQRCodesTab({ user }: EmployeeActiveQRCodeTabProps) {

  const SafeLeaseAPI = SafeLeaseAPIDefaultEnvironment({
    environment: process.env.REACT_APP_SAFELEASE_ENVIRONMENT,
  });

  const userQrCodesQuery = useQuery({
    queryFn: () => SafeLeaseAPI.reputation.getQRCodesForUserHandler({ userId: user.id }),
    queryKey: ["getQRCodesForUser", user.id],
  });

  const userQrCodes = userQrCodesQuery.data?.data ?? [];

  return (
    <Box sx={{ p: 4 }}>
      <Grid container spacing={2}>
        {userQrCodes.map((userQrCode) => (
          <Grid item xs={6} md={4} key={_.uniqueId()}>
            <EmployeeQRCode userQrCode={userQrCode} />
          </Grid>
        ))}
        {userQrCodes.length === 0 && (
          <Grid item xs={12}>
            <Typography variant="body1" sx={{ textAlign: "center", mt: 4 }}>
              No QR codes found for employee.
            </Typography>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}
