import { SafeLeaseDataGrid } from "@safelease/components";
import { memo } from "react";
import { contactGridColumns } from "./contactGridColumns";
import { Contact_Reputation as Contact } from "@safelease/service-utilities";

interface ContactsGridProps {
  contacts: Array<Contact>;
  setSelectedTenant: (contactId: string) => void;
}

/**
 * Memoized instance of the SafeLeaseDataGrid to improve performance and reduce rerenders
 * Some of the numbers in here don't render correctly without memoization + disabling
 * of virtualization
 */
export const ContactsGrid = memo(({ contacts, setSelectedTenant }: ContactsGridProps): any => {
  return (
    <SafeLeaseDataGrid
      rows={contacts}
      columns={contactGridColumns}
      disableColumnFilter={true}
      disableAggregation={true}
      sx={{
        "& .MuiDataGrid-cell": {
          cursor: "pointer",
        },
      }}
      onRowClick={(row) => setSelectedTenant(row.row.id)}
      disableVirtualization={true}
    />
  );
});
