import { FacilityEmployeeSelector } from "./FacilityEmployeeSelector";
import { Dialog } from "@mui/material";
import { QRCodeMessageConfig } from "./QRCodeMessageConfig";
import { usePerformanceBoard } from "../usePerformanceBoard";
import { QRCodeDeliveredMessage } from "./QRCodeDeliveredMessage";
import { useEffect } from "react";

export function TeamQRCodeSetupDialog({ open, handleClose }) {
  const { step, setStep } = usePerformanceBoard();

  useEffect(() => {
    setStep(0);
  }, [open]);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      {step === 0 && <FacilityEmployeeSelector />}
      {step === 1 && <QRCodeMessageConfig />}
      {step === 2 && <QRCodeDeliveredMessage />}
    </Dialog>
  );
}
