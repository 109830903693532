export const safeleaseStatuses = {
  inProgress: {
    label: 'In Progress',
  },
  active: {
    label: 'Active',
  },
  archived: {
    label: 'Archived',
  },
  canceled: {
    label: 'Canceled',
  },
  comingSoon: {
    label: 'Coming Soon',
  },
  test: {
    label: 'Test',
  }
};
