import http from "./http-common";

export interface AgencyAppointmentCertificationRequestBody {
  partnerPolicyDocumentId: number;
  signatureName: string;
  relationshipId: number;
}

class AgencyAppointmentCertificationService {
  signAgencyAppointmentCertification(data: AgencyAppointmentCertificationRequestBody) {
    return http.post("/agency-appointment-certification", data);
  }
}

export default new AgencyAppointmentCertificationService();
