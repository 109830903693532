import { MenuItem, SelectChangeEvent } from "@mui/material";
import SortIcon from "@mui/icons-material/Sort";
import { SafeLeaseSelect } from "@safelease/components";

export enum EmployeeReviewSortStrategies {
  TOTAL_REVIEWS_EARNED_HIGH_TO_LOW = "Total reviews earned (high → low)",
  TOTAL_REVIEWS_EARNED_LOW_TO_HIGH = "Total reviews earned (low → high)",
  CURRENT_REVIEW_RATING_HIGH_TO_LOW = "Current review rating (high → low)",
  CURRENT_REVIEW_RATING_LOW_TO_HIGH = "Current review rating (low → high)",
  REVIEWS_IN_THE_30_DAYS_HIGH_TO_LOW = "Reviews in the last 30 days (high → low)",
  REVIEWS_IN_THE_30_DAYS_LOW_TO_HIGH = "Reviews in the last 30 days (low → high)",
  EMPLOYEE_NAME_A_TO_Z = "Employee name (A → Z)",
  EMPLOYEE_NAME_Z_TO_A = "Employee name (Z → A)",
}

export function PerformanceSortButton({
  sortStrategy,
  setSortStrategy,
}: {
  sortStrategy: string;
  setSortStrategy: (value: EmployeeReviewSortStrategies) => void;
}) {
  const handleChange = (event: SelectChangeEvent) => {
    setSortStrategy(event.target.value as EmployeeReviewSortStrategies);
  };

  return (
    <SafeLeaseSelect
      value={sortStrategy}
      onChange={handleChange}
      startAdornment={<SortIcon sx={{ mr: 1 }} />}
      sx={{ height: 36, borderColor: "#EBEFF7 !important", "& fieldset": { border: "none" } }}
    >
      <MenuItem value={EmployeeReviewSortStrategies.TOTAL_REVIEWS_EARNED_HIGH_TO_LOW}>
        {EmployeeReviewSortStrategies.TOTAL_REVIEWS_EARNED_HIGH_TO_LOW}
      </MenuItem>
      <MenuItem value={EmployeeReviewSortStrategies.TOTAL_REVIEWS_EARNED_LOW_TO_HIGH}>
        {EmployeeReviewSortStrategies.TOTAL_REVIEWS_EARNED_LOW_TO_HIGH}
      </MenuItem>
      <MenuItem value={EmployeeReviewSortStrategies.CURRENT_REVIEW_RATING_HIGH_TO_LOW}>
        {EmployeeReviewSortStrategies.CURRENT_REVIEW_RATING_HIGH_TO_LOW}
      </MenuItem>
      <MenuItem value={EmployeeReviewSortStrategies.CURRENT_REVIEW_RATING_LOW_TO_HIGH}>
        {EmployeeReviewSortStrategies.CURRENT_REVIEW_RATING_LOW_TO_HIGH}
      </MenuItem>
      <MenuItem value={EmployeeReviewSortStrategies.REVIEWS_IN_THE_30_DAYS_HIGH_TO_LOW}>
        {EmployeeReviewSortStrategies.REVIEWS_IN_THE_30_DAYS_HIGH_TO_LOW}
      </MenuItem>
      <MenuItem value={EmployeeReviewSortStrategies.REVIEWS_IN_THE_30_DAYS_LOW_TO_HIGH}>
        {EmployeeReviewSortStrategies.REVIEWS_IN_THE_30_DAYS_LOW_TO_HIGH}
      </MenuItem>
      <MenuItem value={EmployeeReviewSortStrategies.EMPLOYEE_NAME_A_TO_Z}>{EmployeeReviewSortStrategies.EMPLOYEE_NAME_A_TO_Z}</MenuItem>
      <MenuItem value={EmployeeReviewSortStrategies.EMPLOYEE_NAME_Z_TO_A}>{EmployeeReviewSortStrategies.EMPLOYEE_NAME_Z_TO_A}</MenuItem>
    </SafeLeaseSelect>
  );
}
