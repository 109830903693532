import { useState } from 'react';
import { QueryResult, useQuery } from '@apollo/client';
import { getLocations, getRelationshipsSelector } from '../../queries';
import RelationshipDataService from '../../services/relationship.service';
import { SafeleaseDropdown } from '../../common';
import { useAuth } from '../../auth';
import Loader from '../../shared/Loader';
import Error from '../../shared/Error';
import RouterHelper from '../../utilities/router-helper';
import { FormControl, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { muiSelect } from '../../styles/mui-overrides';
import { useHistory } from 'react-router-dom';
import client from '../../utilities/apolloClient';
import { Formatters } from '../../utilities/formatters';
import _ from 'lodash';
import BlockedUnitsAccordion from './BlockedUnitAccordion';

export default function AutoProtect(props: { routerHelper: RouterHelper }) {
  const { routerHelper } = props;

  const auth = useAuth();
  const isAdmin = auth.user.isAdmin;
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  // The user must be an admin or manager and have the auto protect feature flag enabled to view the page.
  const userNotAllowed = !((isAdmin || auth.user.role === 'manager') && routerHelper.getIsAutoProtectUser());
  if (userNotAllowed) history.push('/settings');

  const options = {
    'No Grace Period': 0,
  };
  for (var i = 1; i <= 14; i++) {
    const days = i > 1 ? 'days' : 'day';
    options[`${i} ${days}`] = i;
  }

  const relationshipId = routerHelper?.getRelationshipId() || auth.user.relationshipId;

  const relationshipsSelectorQuery: QueryResult = useQuery(getRelationshipsSelector, {
    skip: !isAdmin,
  });

  const locationsQuery: QueryResult = useQuery(getLocations, {
    variables: { relationshipId },
  });

  const updateGlobalGracePeriod = async (gracePeriod, locationId) => {
    try {
      setLoading(true);
      await RelationshipDataService.updateGracePeriod({
        relationshipId,
        locationId,
        gracePeriod: gracePeriod === 0 ? null : gracePeriod,
      });
      client.refetchQueries({include: [getLocations]});
    } finally {
      setLoading(false);
    }
  };

  if (locationsQuery.loading) return <Loader />;
  if (locationsQuery.error) return <Error />;

  const mostCommonGracePeriodCounts = _.countBy(locationsQuery.data.getLocations, (location => location.autoEnrollGracePeriod || 0));
  const mostCommonGracePeriod = _.maxBy(Object.keys(mostCommonGracePeriodCounts), (gracePeriod) => mostCommonGracePeriodCounts[gracePeriod]);

  return (
    <div className="settings">
      {auth.user && isAdmin && relationshipsSelectorQuery.data && (
        <FormControl sx={{ marginTop: 1, marginBottom: 2, minWidth: 120 }}>
          <Select
            value={relationshipId}
            displayEmpty
            onChange={(e) => {
              routerHelper.navigateToRoute('autoProtectSettings', e.target.value, history);
            }}
            sx={{ ...muiSelect, marginLeft: '0px', padding: '0px' }}
          >
            {relationshipsSelectorQuery.data.getRelationshipsSelector?.map((relationship) => {
              return (
                <MenuItem key={relationship.id} value={relationship.id}>
                  {relationship.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      )}

      {(isAdmin) && (
        <BlockedUnitsAccordion routerHelper={routerHelper}/>
      )}

      <div className="tw-ml-2 tw-mt-2">
          <SafeleaseDropdown
            disabled={loading || !isAdmin}
            label="Global Grace Period for New Tenants"
            value={Object.keys(options).find(key => options[key] == mostCommonGracePeriod)}
            menuOptionList={Object.keys(options)}
            onChange={(e) => {updateGlobalGracePeriod(options[e.target.value], null)}}
          />
      </div>

      <TableContainer component={Paper} className="tw-mt-4">
        <Table sx={{ minWidth: 650 }} size="small">
          <TableHead>
            <TableRow>
              <TableCell>Location</TableCell>
              <TableCell>Live Date</TableCell>
              <TableCell>Auto-Protect Existing Date</TableCell>
              <TableCell>Auto-Protect New Date</TableCell>
              <TableCell>Grace Period for New Tenants</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {locationsQuery.data.getLocations.map(location => {
              return (
                <TableRow key={location.id}>
                  <TableCell>{location.fullAddress}</TableCell>
                  <TableCell>{location.liveDate || 'N/A'}</TableCell>
                  <TableCell>{location.autoEnrollExistingEffectiveDate || 'N/A'}</TableCell>
                  <TableCell>{location.autoEnrollNewEffectiveDate || 'N/A'}</TableCell>
                  <TableCell>
                    <SafeleaseDropdown
                      disabled={loading || !isAdmin}
                      value={Object.keys(options).find(key => options[key] === (location.autoEnrollGracePeriod || 0))}
                      menuOptionList={Object.keys(options)}
                      onChange={(e) => {updateGlobalGracePeriod(options[e.target.value], location.id)}}
                      selectStyles={{marginBottom: 0}}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
