import React, { JSXElementConstructor, PropsWithChildren, ReactElement } from 'react';
import { forwardRef, useEffect, useState } from 'react'

import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Alert, Button,
  Checkbox,
  FormControlLabel,
  FormGroup, 
  MenuItem, 
  RadioGroup,
  Select,
  TextField,
  TextFieldProps,
  Tooltip,
  Typography,
} from '@mui/material';
import { 
  muiLabelSmall,
  muiSettingsButton, 
  muiSettingsButtonIcon,
  muiSettingsItem,
  muiStatisticTooltip,
  muiTextFieldInput
} from './styles/mui-overrides';
import { Formatters } from './utilities/formatters';
import { EmailFrequency } from './settings/email-settings';
import { Location } from './utilities/generated/gql-types';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import { muiArrow } from './styles/mui-overrides'
import { Image } from './image';
import { useRouteMatch } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { getPermission } from './queries';
import { OnboardingScreen } from './users';

function PageMessage(props) {
  const match = useRouteMatch();
  console.log(props);

  const messages = {
    invited: "Invitation sent.",
    reset: "If it was found in our system, an email has been sent to your account with a link to reset your password.",
    requestEmailSigninSent: "If the email address was found in our system, we just sent you a link. Please click the link in the email to sign in.",
  };

  useEffect(() => {
    document.body.className = 'signin';
    return () => { document.body.className = ''; }
  }, []);

  return (
    <div className="signin-page">
      <OnboardingScreen>
        <Image src="/images/safelease_logo.svg" alt="SafeLease logo" />
        <div className="page-error">
          {messages[match.params.type]}
        </div>
      </OnboardingScreen>
    </div>
  );
};

function ChangeStatistic(props: {
  relationship: any,
  loading: any, 
  prevMonth: Date | number,
  currentValue: number | string,
  title: string,
  tooltip: string,
  increase: number,
  showAutoProtect?: boolean,
}) {
  const { loading, prevMonth, currentValue, title, increase, showAutoProtect } = props;
  const increasePercent = Math.round(increase * 100);
  return (
    <div className="statistic">
      {showAutoProtect &&
        <p className="statistic-auto-protect-title">
          with 
          <span className="badge"
            ><Image src="/images/safelease-logo-small.svg" alt="warning" />
          </span> Auto-Protect
          <sup>&trade;</sup>
        </p>
      }
       <p></p>  {/* For spacing unevenly spaces */}
      <div className="statistic-value">{currentValue}</div>
      <div className="statistic-label">{title}
      
        {props.tooltip &&
          <Tooltip placement="bottom" title={props.tooltip}
                componentsProps={{
                  tooltip: {
                    sx: { backgroundColor: '#152744'},
                  },
                }}>
            <Button sx={muiStatisticTooltip}>
              <span className="badge">
                <Image src="/images/tooltip-icon.svg" alt="warning" />
              </span>
            </Button>
          </Tooltip>
        }
      
      </div>

      {!props.relationship.test && increase !== null && increase !== undefined && (
          <div className="statistic-trend">
          {!loading && increasePercent > 0 ? <ArrowUpwardIcon sx={muiArrow({direction: 'up'})}/> : increasePercent < 0 ? <ArrowDownwardIcon sx={muiArrow({direction: 'down'})}/> : null}
          {!loading && increasePercent > 0 ? 'Up' : increasePercent < 0 ? 'Down' : 'No Change'}
          {' '}
          {increasePercent > 0 || increasePercent < 0 ? `${increasePercent}%` : null}
          {` from pre-SafeLease`}
          </div>
        )}

    </div>
  )
}

function ZeroState(props: {title: string, body: string, icon?: string, svg?: string}) {
  return (
    <div className="zero-state">
      <div className="icon">
        {props.icon &&
          <i className={props.icon}></i>
        }
        {props.svg &&
          <Image src={props.svg} />
        }
      </div>
      
      <div className="title">
        {props.title}
      </div>
      <div className="body">
        {props.body}
      </div>
    </div>
  );
}

const TitleValuePair = (props: {
    title: string, 
    value: string | Date | JSX.Element, 
    value2?: string,
    grow?: boolean,
    large?: boolean,
    valueStyles?: string,
    titleStyles?: string,
    extraStyles?: string
}) => {
  return (
    <div className={`claim-details--row--item 
        ${props.grow ? 'claim-details--row--item__grow' : ''}
        ${props.large ? 'claim-details--row--item__large' : ''} `
        .concat(props.extraStyles)}>
      <div className={
          `claim-key ${props.large ? 'claim-key__large' : ''}`.concat(props.titleStyles) }>
        {props.title}
      </div>
      <div className={"claim-value".concat(' '.concat(props.valueStyles || null))}>
        {props.value}
      </div>
      <div className={"claim-value".concat(' '.concat(props.valueStyles || null))}>
        {props.value2}
      </div>
    </div>
  )
}

const SafeleaseLongButtonWithIcon = (props: {
    onClick: (event) => void,
    text: string,
    round?: boolean,
    style?: any,
}) => {
  const { onClick, text, round, style } = props
  const [isHovered, setIsHovered] = useState<boolean>(false);
  return (
    <Button sx={{ ...muiSettingsButton({round}), ...style }}
        onClick={(event) => onClick(event)}
        onMouseEnter={(e) => setIsHovered(true)}
        onMouseLeave={(e) => setIsHovered(false)}>
      <span className={`${round ? 'tw-text-xs' : ''}`}>{text}</span>
      <AddCircleIcon sx={muiSettingsButtonIcon({isHovered})}/>
    </Button>
  )
}

const SafeleaseDropdown = (props: {
  defaultValue?: EmailFrequency | string;
  menuOptionList: string[];
  onChange?: (event) => void;
  label?: string;
  disabled?: boolean;
  tooltip?: { title: string; content: JSX.Element | string };
  value?: string | string[];
  fullWidth?: boolean;
  multiple?: boolean;
  selectStyles?: object;
}) => {
  const { defaultValue, disabled, label, menuOptionList, onChange, tooltip, value, fullWidth = true, multiple, selectStyles } = props;
  return (
    <>
      {!!label && (
        <span
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: fullWidth ? 275 : 'auto',
            cursor: 'pointer',
            fontSize: 12,
          }}
        >
          <div className="claim-key claim-key__large">{label}</div>
          {!!tooltip && (
            <Tooltip
              placement="right"
              title={tooltip.content}
              componentsProps={{
                tooltip: {
                  sx: { backgroundColor: '#152744' },
                },
              }}
            >
              <a className="settings-content--link">{tooltip.title}</a>
            </Tooltip>
          )}
        </span>
      )}
      <Select
        sx={Object.assign(muiSettingsItem(), muiTextFieldInput, !fullWidth ? { width: 'auto' } : {}, selectStyles)}
        defaultValue={value ? undefined : defaultValue}
        value={value ? value : undefined}
        onChange={onChange}
        disabled={!!disabled}
        multiple={multiple}
        renderValue={multiple ? (selected) => {
          const newSelected = selected.map(value => Formatters.camelCaseToUpperCase(value))
          return newSelected.join(', ');
        }: undefined}
      >
        {menuOptionList.map((menuOption, i) => (
          <MenuItem key={i} value={menuOption} style={muiTextFieldInput}>
            {multiple && <Checkbox checked={value.indexOf(menuOption) > -1} />}
            {menuOption ? Formatters.camelCaseToUpperCase(menuOption) : ''}
          </MenuItem>
        ))}
      </Select>
    </>
  );
};

const SafeleaseRadioButtonGroup = (props: {
    children: JSX.Element[],
    onChange: (event) => void,
}) => {
  const { onChange, children } = props;
  return (
    <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        onChange={(event) => {
          onChange(event)
          console.log(event.target.id)
        }}>
      {props.children}
    </RadioGroup>
  )
}

const SafeleaseTextField = (props: {
    showLabel: boolean,
    innerLabel: string,
    onChange: (event) => void,
    defaultValue: string,
    titleLabel?: string,
    disabled?: boolean,
    long?: boolean,
    halfLong?: boolean,
    error?: string
}) => {
  const {defaultValue, disabled, showLabel, titleLabel, innerLabel, onChange, long, halfLong, error} = props;
  const [value, setValue] = useState(defaultValue)
  useEffect(() => {
    setValue(defaultValue)
    onChange({target: {value: defaultValue}})
  }, [defaultValue])
  return (
    <div>
      {showLabel &&
        <div className="claim-key claim-key__large">
          {titleLabel}
        </div>
      }
      <TextField label={<Typography sx={muiTextFieldInput}>{innerLabel}</Typography>}
          sx={muiSettingsItem({long, halfLong})} 
          defaultValue={defaultValue} 
          onChange={(event) => {
            onChange(event)
            setValue(event.target.value)
          }} 
          disabled={disabled} 
          value={value}
          error={error}
          helperText={error}
          InputProps={{style: muiTextFieldInput}} />
    </div>
  )
}


interface ISafeLeaseFormTextFieldProps {}

const SafeleaseFormTextField: React.FC<ISafeLeaseFormTextFieldProps & TextFieldProps> = forwardRef(({
  sx,
  ...rest
}, ref) => {
  return (
    <TextField
      inputRef={ref}
      sx={{ ...muiSettingsItem(), ...sx }}
      {...rest}
    />
  );
});

// TODO now this just works for locations but could work for anything with an "id" property
const SafeleaseCheckboxList = (props: {
    locations: any[], //TODO this should be SelectOptions<any>[] and not called locations
    pendingIds: number[],
    setPendingIds: (ids: number[]) => void,
    allDisabled?: boolean,
    allChecked?: boolean,
    displayAsGrid?: boolean,
    title?: string
}) => {
  const { title, allChecked, allDisabled, displayAsGrid, locations, pendingIds, setPendingIds } = props;

  const showDeselect = (pendingIds: number[]) => 
      pendingIds?.length === locations?.length

  const selectAll = () => {
    setPendingIds(locations?.map(
      location => parseInt(location.id) || location.value
    ))
  }

  const handleCheck = (location: Location) => 
      (event) => setPendingIds(
          event.target.checked === true ?
              pendingIds?.concat(location.id ? parseInt(location.id) : location.value) :
              pendingIds?.filter(id => 
                  id !== (location.id ? parseInt(location.id) : location.value)))

  const deselectAll = () => setPendingIds([]);

  const isItemPending = 
      (item: Location, pendingIds: number[]) =>
          pendingIds?.includes(item.id ? parseInt(item.id) : item.value);

  return (
    <>
      <div className="claim-details__email-settings-drawer--assign-locations-header">
        <div className="claim-key claim-key__large">
          {title || 'Assign Location(s)'}
        </div>
        {!showDeselect(pendingIds) && !allDisabled &&
          <a className="settings-content--link"
              onClick={() => selectAll()}>
            Select All
          </a>
        }
        {showDeselect(pendingIds) && !allDisabled &&
          <a className="settings-content--link"
              onClick={() => deselectAll()}>
            Deselect All
          </a>
        }
      </div>
      <FormGroup> 
        <div sx={displayAsGrid ? 
            {display: 'flex', width: '100%'} : 
            {}}>
          {locations?.map(location => {
            return (
              <FormControlLabel
                  key={location.id || location.value}
                  label={
                    <Typography sx={muiLabelSmall()}>
                      {location.fullAddress || location.label}
                    </Typography>
                  } 
                  control={
                    <Checkbox onChange={handleCheck(location)}
                        checked={!!isItemPending(location, pendingIds) || !!allChecked} 
                        disabled={allDisabled} />
                  } />
            )
          })}
        </div>
      </FormGroup>
    </>
  )
}

const NotificationDot = (props: {extraStyles?: string, totalNotifs?: number}) => 
  <div className={`tw-h-[9px] tw-w-[9px] tw-bg-[#E9645F] tw-rounded-full 
      ${props.extraStyles || ''}`}>
    <div className="tw-cursor-pointer tw-text-white tw-text-xs tw-font-bold tw-flex tw-justify-center 
        tw-items-center tw-h-full tw-w-full">
      {props.totalNotifs}
    </div>
  </div>


import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { useAuth } from './auth.js';
import { cloneDeep } from 'lodash';
function ProtectionLevelsDeprecated(props) {
  const auth = useAuth();
  const isAdmin = auth.user.isAdmin;
  const { protectionLevels, setProtectionLevels } = props;
  const [counter, setCounter] = useState(protectionLevels.length);
  
  const newValues = function (i, key, value) {
    let protectionLevelsClone = cloneDeep(protectionLevels);
    protectionLevelsClone[i][key] = parseFloat(value) * 100;
    setProtectionLevels(protectionLevelsClone);
  };

  const removeRow = function (index) {
    const tempProtectionLevels = [...protectionLevels];
    tempProtectionLevels.splice(index, 1);
    setProtectionLevels(tempProtectionLevels);
  };

  const addRow = function () {
    let protectionLevelsClone = [...protectionLevels];
    protectionLevelsClone.push({ key: counter });
    setCounter(counter + 1);
    setProtectionLevels(protectionLevelsClone);
  };

  return (
    <div className="protection-levels">
      <p className="protection-level-title coverage">Coverage Amount</p>
      <p className="protection-level-title premium">Monthly Premium</p>
      
      <Paper className="protection-levels-list-paper">
        {protectionLevels.map((level, i) => (
          <Grid
            className="small-input-field"
            key={level.key}
            container
            spacing={2}
            direction="row"
            sx={{ flexWrap: 'nowrap', alignItems: 'center' }}
          >
            <Grid item xs={6} className="input-grid">
              <TextField
                name="number"
                label=""
                type="number"
                value={level.coverage ? level.coverage / 100 : null}
                onChange={(e) => newValues(i, 'coverage', e.target.value)}
                disabled={!isAdmin}
              />
            </Grid>
            <Grid item xs={6} className="input-grid">
              <TextField
                name="number"
                label=""
                type="number"
                step="0.01"
                value={level.premium ? level.premium / 100 : null}
                onChange={(e) => newValues(i, 'premium', e.target.value)}
                disabled={!isAdmin}
              />
            </Grid>
            <IconButton
              onClick={(e) => removeRow(i)}
              sx={{ marginLeft: '75px', height: 'fit-content' }}
            >
              <DeleteIcon />
            </IconButton>
          </Grid>
        )
        )}
      </Paper>

      {isAdmin && (
        <Button className="plan-detail-addnew-button" onClick={(e) => addRow()}>
          Add New Protection Level
        </Button>
      )}
    </div>
  );
}

function ProtectionLevels(props) {
  const auth = useAuth();
  const isAdmin = auth.user.isAdmin;
  let { protectionLevels, setProtectionLevels } = props;
  // Ensure protectionLevels is always an array (in case it's passed as null)
  protectionLevels = protectionLevels || [];
  const [counter, setCounter] = useState(protectionLevels.length);
  
  const newValues = function (i, key, value) {
    let protectionLevelsClone = cloneDeep(protectionLevels);
    protectionLevelsClone[i][key] = parseFloat(value) * 100;
    setProtectionLevels(protectionLevelsClone);
  };

  const removeRow = function (index) {
    const tempProtectionLevels = [...protectionLevels];
    tempProtectionLevels.splice(index, 1);
    setProtectionLevels(tempProtectionLevels);
  };

  const addRow = function () {
    let protectionLevelsClone = [...protectionLevels];
    protectionLevelsClone.push({ key: counter });
    setCounter(counter + 1);
    setProtectionLevels(protectionLevelsClone);
  };

  return (
    <div className="protection-levels">
      {protectionLevels.length > 0 && (
        <Grid
          key={'headers'}
          container
          spacing={2}
          direction="row"
          sx={{ flexWrap: 'nowrap', alignItems: 'center' }}
        >
          <Grid item xs={5}>
            <p>Coverage Amount</p>
          </Grid>
          <Grid item xs={5}>
            <p>Monthly Premium</p>
          </Grid>
        </Grid>
      )}

      {protectionLevels.map((level, i) => (
        <Grid
          key={i}
          container
          spacing={2}
          direction="row"
          sx={{ flexWrap: 'nowrap', alignItems: 'center', marginBottom: '5px' }}
        >
          <Grid item xs={5} className="input-grid">
            <TextField
              name="number"
              label=""
              type="number"
              value={level.coverage ? level.coverage / 100 : null}
              onChange={(e) => newValues(i, 'coverage', e.target.value)}
              disabled={!isAdmin}
            />
          </Grid>
          <Grid item xs={5} className="input-grid">
            <TextField
              name="number"
              label=""
              type="number"
              step="0.01"
              value={level.premium ? level.premium / 100 : null}
              onChange={(e) => newValues(i, 'premium', e.target.value)}
              disabled={!isAdmin}
            />
          </Grid>
          <Grid item xs={2} className="input-grid">
            <IconButton onClick={(e) => removeRow(i)} sx={{ height: 'fit-content' }}>
              <DeleteIcon />
            </IconButton>
          </Grid>
        </Grid>
      ))}

      {isAdmin && (
        <Button className="plan-detail-addnew-button" onClick={(e) => addRow()}>
          Add New Protection Level
        </Button>
      )}
    </div>
  );
}

interface AccessControlledProps {
  type: string; // Corresponding model name, e.g. 'Location', 'User', 'Relationship'
  id: number; // id of the resource (found in db)
  permission: string; // Permission name, e.g. 'view', 'edit', 'delete', see ability.js
  showError?: boolean; // Whether to show an error message if the user does not have access
}

export { 
  ChangeStatistic,
  SafeleaseCheckboxList,
  SafeleaseDropdown,
  PageMessage, 
  TitleValuePair, 
  ZeroState, 
  SafeleaseLongButtonWithIcon,
  SafeleaseRadioButtonGroup,
  SafeleaseTextField,
  SafeleaseFormTextField,
  NotificationDot,
  ProtectionLevelsDeprecated,
  ProtectionLevels
}
