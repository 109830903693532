import { Box, Grid, Stack, Typography } from "@mui/material";
import { ReputationUser } from "@safelease/service-utilities";
import { GoogleReviewCard } from "../../../FacilityView/FacilityViewTabs/ContactsOverview/ProfileDrawer/GoogleReviewCard";

interface EmployeeActiveQRCodeTabProps {
  user: ReputationUser;
}

/**
 * A tab displaying the reviews that are attributed to an employee
 */
export function EmployeeReviewsTab({ user }: EmployeeActiveQRCodeTabProps) {
  const reviews = user.referralLinkEvents?.map((event) => event.googleReview) ?? [];

  return (
    <Box
      sx={{
        overflowY: "auto",
        height: "100%",
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
      }}
    >
      {reviews.length > 0 && (
        <Stack direction="column" spacing={2} sx={{ p: 4 }}>
          {reviews?.map((review) => (
            <GoogleReviewCard review={review} key={review.id} />
          ))}
        </Stack>
      )}
      {reviews.length === 0 && (
        <Typography variant="body1" sx={{ textAlign: "center", mt: 8 }}>
          No reviews found for employee.
        </Typography>
      )}
    </Box>
  );
}
