import { Box, Card, Grid, Theme, Typography } from "@mui/material";
import TitleHeader from "../../shared/title-header";
import JobsErrorCenterMetricCard from "./JobsErrorCenterMetricCard";
import dayjs from "dayjs";
import { useJobsErrorCenter } from "./useJobsErrorCenter";
import { useQuery } from "@apollo/client";
import { getJobsErrorReport, getLiveProductionJobData, getLiveProductionLatencyByFMS } from "../../queries";
import useWindowSize from "../../utilities/useWindowSize";
import SafeleaseDataGrid from "../../shared/safelease-data-grid";
import { JobError } from "../../utilities/generated/gql-types";
import JobErrorDetailsDrawer from "./JobErrorDetailsDrawer/JobErrorDetailsDrawer";
import { liveProductionJobReportColumnDefs } from "./liveProductionJobReportColumnDefs";
import { liveProductionLatencyByFMSColumnDefs } from "./liveProductionLatencyByFmsColumnDefs";
import { Formatters } from "../../utilities/formatters";
import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

interface FmsLatency {
  fms: string;
  latency: string;
}

interface FmsLatencyRow extends FmsLatency {
  id: string;
}

interface EngineeringErrorReportProps {}

export const EngineeringErrorReport: React.FC<
  EngineeringErrorReportProps
> = () => {
  const size = useWindowSize();
  const history = useHistory();
  const jobsErrorReportQuery = useQuery(getJobsErrorReport);
  const getLiveProductionJobDataQuery = useQuery(getLiveProductionJobData);
  const getLiveProductionLatencyByFMSQuery = useQuery(getLiveProductionLatencyByFMS);
  const { visibleColumns, updateVisibleColumns, setSelectedJobError } =
    useJobsErrorCenter();

  if(jobsErrorReportQuery.loading || getLiveProductionJobDataQuery.loading || getLiveProductionLatencyByFMSQuery.loading) return "Loading...";
  if(jobsErrorReportQuery.error || getLiveProductionJobDataQuery.error || getLiveProductionLatencyByFMSQuery.error) return "Error...";

  const jobs = jobsErrorReportQuery.data.getJobsErrorReport;
  const pendingJobs = getLiveProductionJobDataQuery.data.getLiveProductionJobData.pendingCount;
  const latestCompletedJob = getLiveProductionJobDataQuery.data.getLiveProductionJobData.lastCompletedTimestamp;
  const latencyByFMS = getLiveProductionLatencyByFMSQuery.data.getLiveProductionLatencyByFMS;

  const productFailures = jobs.filter(
    (error: JobError) => error.team === "product",
  );
  const nonProductFailures = jobs.filter(
    (error: JobError) => error.team !== "product",
  );
  const productJobs = jobs.filter(
    (jobError: JobError) => jobError.team === "product",
  );
  const latencyByFMSRows: FmsLatencyRow[] = latencyByFMS.map((fmsLatencyObj: FmsLatency): FmsLatencyRow => { 
    return {
      ...fmsLatencyObj,
      id: uuidv4(),
    }
  });

  return (
    <Box>
      <TitleHeader title="Live Production Job Report" />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography
            variant="body2"
            sx={{ color: (theme: Theme) => theme.palette.grey[600] }}
          >
            Last job completed at{" "}
            {dayjs(latestCompletedJob).format("MM/DD/YYYY hh:mm A")}
          </Typography>
        </Grid>
        <Grid item xs={12} md={3}>
          <JobsErrorCenterMetricCard
            value={Formatters.formatWithCommas(pendingJobs)}
            label="Pending jobs"
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <JobsErrorCenterMetricCard
            value={Formatters.formatWithCommas(productFailures.length)}
            label="Product failures"
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <JobsErrorCenterMetricCard
            value={Formatters.formatWithCommas(nonProductFailures.length)}
            label="Non product failures"
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <JobsErrorCenterMetricCard
            value={getLiveProductionJobDataQuery.data.getLiveProductionJobData.latency}
            label="Maximum successful ingestion latency"
          />
        </Grid>
        <Grid item xs={12}>
          <Card elevation={0} sx={{ p: 2 }}>
            <Typography sx={{ fontWeight: "bold", mb: 2 }} variant="body1">
              Product Job Errors
            </Typography>
            <Box sx={{ height: size.height - 600 }}>
              <SafeleaseDataGrid
                clickable
                columns={liveProductionJobReportColumnDefs}
                rows={productJobs}
                autosizeOnMount
                rowProps={{ queryResult: jobsErrorReportQuery, history }}
                getRowHeight={() => "auto"}
                editMode="cell"
                onRowClick={(params) => {
                  const { queryResult, ...rest } = params.row; // remove queryResult from the row data to avoid circular reference error
                  setSelectedJobError(rest);
                }}
                columnVisibilityModel={visibleColumns}
                onColumnVisibilityModelChange={(newModel) =>
                  updateVisibleColumns(newModel)
                }
              />
            </Box>
          </Card>
          <Card elevation={0} sx={{ p: 2 }}>
            <Typography sx={{ fontWeight: "bold", mb: 2 }} variant="body1">
              Latency By FMS
            </Typography>
            <Box sx={{ height: size.height - 600 }}>
              <SafeleaseDataGrid
                columns={liveProductionLatencyByFMSColumnDefs}
                rows={latencyByFMSRows}
                autosizeOnMount
                getRowHeight={() => "auto"}
              />
            </Box>
          </Card>
        </Grid>
      </Grid>
      <JobErrorDetailsDrawer />
    </Box>
  );
};
