// UI - Libraries
import { Box, Stack, Theme, Typography } from "@mui/material";
import { SafeLeaseCard } from "@safelease/components";

// UI - internal
import { NewQuickCampaignDialog } from "../FacilityView/FacilityViewTabs/CampaignsOverview/NewQuickCampaignDialog";

// State / hooks
import { useState } from "react";
import { useReputation } from "../../useReputation";
import RelationshipSelector from "../../../shared/RelationshipSelector";
import RouterHelper from "../../../utilities/router-helper";
import { useHistory } from "react-router-dom";

interface PortfolioViewHeaderProps {
  routerHelper: RouterHelper;
}

/* A display of portfolio information at the top of the dashboard */
export function PortfolioViewHeader({ routerHelper }: PortfolioViewHeaderProps) {
  const { portfolio } = useReputation();
  const { push: navigate } = useHistory();
  const [quickCampaignDialogOpen, setQuickCampaignDialogOpen] = useState<boolean>(false);

  const handleRelationshipChange = (inputRelationshipId: string) => {
    routerHelper.setRelationshipId(inputRelationshipId);
    // routerHelper is used here in place of zustand reputationRoute as we're change global app state with the change in relationship
    navigate(routerHelper.reputationRoute());
  };

  return (
    <SafeLeaseCard sx={{ p: 2 }}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction="row" spacing={2} alignItems="center">
          <Box>
            <Typography variant="h6" fontWeight="700">
              {portfolio.name}
            </Typography>
            <Typography sx={{ color: (theme: Theme) => theme.palette.grey.A100 }}> {portfolio.facilities.length} facilities</Typography>
          </Box>
        </Stack>
        <RelationshipSelector
          routerHelper={routerHelper}
          nonRoute
          nonRouteCallback={(relationshipId: string) => handleRelationshipChange(relationshipId)}
        />
      </Stack>
      <NewQuickCampaignDialog open={quickCampaignDialogOpen} handleClose={() => setQuickCampaignDialogOpen(false)} />
    </SafeLeaseCard>
  );
}
