import { Metric, Location } from '../../utilities/generated/gql-types';
import { SummarizedMetrics, MetricsHelpers, DataPoint } from '../../utilities/metrics-helpers';
import { ChangeStatistic, ZeroState } from '../../common';
import { Line } from 'react-chartjs-2';
require('datejs');

function SafeLeaseUnitsChart(props: {
    loading: any, 
    error: any, 
    locationId?: string | number, // not used
    occupiedUnits?: any, // not used
    locations: Location[],
    aggregateMetrics: {
      getAggregateMetrics: Metric[]
    },
    unsupportedFms: boolean
    title?: string
    showTotals?: boolean
}) {
  const { locations, aggregateMetrics, loading, error, unsupportedFms, title, showTotals = true } = props;

  if (loading) return <>Loading...</>;
  if (error) return <>Error!</>;

  const currentMonth = (() => {
    const date = new Date();
    date.setHours(0, 0, 0, 0);
    date.setDate(1);
    return date;
  })();
  
  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
  function format(value, index, ticks) {
    return formatter.format(value) + '%';
  }

  const summarizedMetricsByKey: {[index: string]: SummarizedMetrics} = {};
  ['safelease', 'occupied'].map(key => {
    summarizedMetricsByKey[key] = MetricsHelpers.getSummaryByKey(
        aggregateMetrics?.getAggregateMetrics,
        key,
        currentMonth);
  })

  const { safelease, occupied } = summarizedMetricsByKey;

  const makeDataset = (summarized: {[index: string]: any}): DataPoint[] => {
    return Object.entries(summarized).map(([key, value]) => {
      return {x: key, y: value}
    });
  }

  /**
   * Data validator for SLUnits % change metrics
   * If data not valid, returns false which will nullify the %increase data,
   * preventing the rendering of the % increase at all
   * 
   * @param {String} param - what metrics data you are validating ex: safelease or occupied
   * @return {Boolean} whether or not the data is valid
   */
  const metricsDataValid = (param: string): boolean => {
    if (
      param === 'safelease' &&
      safelease.lastMetric?.value &&
      safelease.lastMetricBeforeMonth?.value
    ) return true
    if (
      param === 'occupied' &&
      occupied.lastMetric?.value &&
      occupied.lastMetricBeforeMonth?.value
      ) return true
    return false;
  };

  const totalBaselineOccupiedUnits = locations.reduce((total, location) => {
    return total += location.baselineOccupiedUnits;
  }, 0)

  const totalBaselineProtectedUnits = locations.reduce((total, location) => {
    return total += location.baselineProtectedUnits;
  }, 0)

  // % change is calculated by (current mo - baseline) / baseline
  const protectedIncrease =
    metricsDataValid('safelease') && locations.every(loc => loc.baselineProtectedUnits) ? 
    (safelease.lastMetric.value - totalBaselineProtectedUnits) /
      totalBaselineProtectedUnits : null;

  const occupiedIncrease =
    metricsDataValid('occupied') && locations.every(loc => loc.baselineOccupiedUnits) ?
    (occupied.lastMetric.value - totalBaselineOccupiedUnits) /
      totalBaselineOccupiedUnits : null;
  
  const datasets: { newCustomerAttachRate: DataPoint[], attachRate: DataPoint[] } = 
    { newCustomerAttachRate: [], attachRate: [] };
  
  for(var key of ['attachRate', 'newCustomerAttachRate']) {
    datasets[key] = [];
    for(var row of aggregateMetrics?.getAggregateMetrics) {
      if (key != row.key) continue;
      // getAggregateMetrics returns the current date for the most recent month, causing a scrunched X axis. 
      // eg. 2022-06-30, 2022-07-31, 2022-08-04 <- the last date being the problem as the date is not evenly spaced
      //Here, we are setting the most recent metric to the end of the month for a prettier x axis
      const endDate = new Date(row.date);
      endDate.addMinutes(endDate.getTimezoneOffset());
      if (endDate.getDate() < 27) {
        endDate.setDate(27);
        row.date = endDate.toISOString().split('T')[0];
      }
      datasets[key].push({
        x: row.date,
        y: row.value,
      });
    }
  }
  
  var dataset = Object.entries(safelease.summarized).map(([key, value]) => {
    return {x: key, y: value};
  });

  const occupiedDataset = makeDataset(occupied.summarized);

  const data = {
    datasets: [
      {
        label: 'New Move-In Enrollment Rate Trailing 30 Days',
        data: datasets.newCustomerAttachRate,
        borderColor: '#84D43D',
        backgroundColor: '#84D43D',
      },
      {
        label: 'Enrollment Rate',
        data: datasets.attachRate,
        borderColor: '#2879FB',
        backgroundColor: '#2879FB'
      }
    ],
  };

  let currentOccupiedUnits, currentSafeleaseUnits, percentageSafeleaseCustomers, percentageChange;
  if (aggregateMetrics && !props.loading && !props.error && data.datasets[0].data.length > 0) {
    currentSafeleaseUnits = dataset[dataset.length - 1]?.y;
    currentOccupiedUnits =  occupiedDataset[occupiedDataset.length - 1]?.y;
    percentageSafeleaseCustomers = Math.round(
        (currentSafeleaseUnits / currentOccupiedUnits) * 100
    ).toFixed(0);
    if (safelease.lastMetricBeforeMonth !== null && 
        occupied.lastMetricBeforeMonth !== null) {
      const percentageSafeleaseCustomersBeforeMonth = 
          (safelease.lastMetricBeforeMonth.value / safelease.lastMetricBeforeMonth.value) * 100;
      percentageChange = parseInt(percentageSafeleaseCustomers) - percentageSafeleaseCustomersBeforeMonth;
    }
  }

  // if we do not have all the baseline metrics, do not render any change metric at all
  const calculateAttachRateChange = () => {
    if (
      datasets?.attachRate?.length > 1 &&
      locations.every(loc => loc.baselineProtectedUnits) &&
      locations.every(loc => loc.baselineOccupiedUnits)
    ) {
      return (
        (datasets.attachRate[datasets.attachRate.length - 1].y -
          (totalBaselineProtectedUnits / totalBaselineOccupiedUnits) * 100) /
        100
      );
    } else return null;
  }

  const isZeroState = (
    data: { datasets: { data: any[] }[] },
    currentOccupiedUnits: number,
    currentSafeleaseUnits: number,
    percentageSafeleaseCustomers: number,
    unsupportedFms: boolean
  ): boolean => {
    return (
      unsupportedFms ||
      currentOccupiedUnits === null ||
      currentOccupiedUnits === undefined ||
      currentSafeleaseUnits === null ||
      currentSafeleaseUnits === undefined ||
      percentageSafeleaseCustomers === null ||
      percentageSafeleaseCustomers === undefined ||
      data.datasets.filter((dataset) => dataset.data.length === 0).length > 0
    );
  };

  if (isZeroState(data, 
    currentOccupiedUnits, 
    currentSafeleaseUnits, 
    percentageSafeleaseCustomers,
    unsupportedFms)) {
    return (
      <div className="widget widget__dashboard-widget">
        <div className="widget-title">{title || 'SAFELEASE UNITS'}</div>
          {!unsupportedFms && (
            <div className="widget--zero-state">
              <ZeroState
                title="No data found"
                body="Try adjusting your search or filter settings for better results."
                svg="/images/no-data-zero-state.svg"
              />
            </div>
          )}
          {!!unsupportedFms && (
            <div className="widget--zero-state">
              <ZeroState
                title="No data found"
                body="This location currently uses a non-supported FMS."
                svg="/images/no-data-zero-state.svg"
              />
            </div>
          )}
      </div>
    );
  }

  return(    
    <div className="widget widget__dashboard-widget">
      <div className="widget-title">{title || 'SAFELEASE UNITS'}</div>
        <div className="widget-content">
            {showTotals &&
              <div className="statistics">
                <ChangeStatistic
                  relationship={props.relationship}
                  loading={props.loading}
                  prevMonth={new Date(occupied.lastMetricBeforeMonth?.date) || null}
                  currentValue={currentOccupiedUnits.toLocaleString()}
                  tooltip={'The current number of rented units at your facility.'}
                  title={'Occupied Units'} />
                <ChangeStatistic 
                  relationship={props.relationship}
                  loading={props.loading} 
                  increase={protectedIncrease}
                  prevMonth={safelease.lastMetricBeforeMonth?.date? new Date(safelease.lastMetricBeforeMonth?.date): null}
                  currentValue={currentSafeleaseUnits.toLocaleString()} 
                  tooltip={'The number of occupied units enrolled in the protection program.'}
                  title={'Covered Units'} />
                <ChangeStatistic 
                  relationship={props.relationship}
                  loading={props.loading} 
                  increase={calculateAttachRateChange()}
                  prevMonth={occupied.lastMetricBeforeMonth?.date ? new Date(safelease.lastMetricBeforeMonth?.date) : null}
                  currentValue={percentageSafeleaseCustomers ? percentageSafeleaseCustomers.toString() + '%' : ''} 
                  tooltip={'The percentage of occupied units enrolled in the protection program.'}
                  title={'Enrollment Rate'} />
              </div>
            }

          {/* "as any" prevents a nasty type error thrown by chart js internals */}
          <Line options={MetricsHelpers.getChartOptions(data, {yFormat: format}) as any}
              data={data} 
              className="widget-content--line-chart"/>
        </div>
    </div>
  )
}

export default SafeLeaseUnitsChart
