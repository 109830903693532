import { useState, useEffect } from 'react';
import { Button, Modal, IconButton } from '@mui/material';
import { muiPhotosBg, muiPhotosXButtons, muiRedButton } from './styles/mui-overrides';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import CloseIcon from '@mui/icons-material/Close';
import { Image } from './image';

function PhotoPicker(props: {photos: string[]}) {
  let { photos } = props

  photos = photos.filter(photo => {
    return !photo.toLowerCase().endsWith('.heic') &&
        !photo.toLowerCase().endsWith('.mov');
  });

  const [modalOpen, setModalOpen] = useState(false);
  const [modalPhoto, setModalPhoto] = useState('');
  const [modalPhotoIdx, setModalPhotoIdx] = useState(0);
  const [isMainPhotoHovered, setIsMainPhotoHovered] = useState(false);

  const handleOpen = (allPhotos: string[], photoIdx: number) => {
    if (allPhotos[photoIdx]) {
      setModalPhoto(allPhotos[photoIdx])
      setModalPhotoIdx(photoIdx)
      setModalOpen(true);
    }
  }

  const handleClose = () => setModalOpen(false);

  const changePhoto = (photos: string[], incrementBy: number) => {
    let newIdx = modalPhotoIdx + incrementBy;
    if (newIdx < 0) {
      newIdx = photos.length - 1;
    }
    setModalPhotoIdx(newIdx % photos.length)
  }

  document.onkeydown = checkKey;

  function checkKey(e) {
    e = e || window.event;
    if (e.keyCode == '37') {
      // left arrow
      changePhoto(photos, -1)
    }
    else if (e.keyCode == '39') {
     // right arrow
      changePhoto(photos, 1)
    }
  }

  return (
    <>
      <div className="photo-picker">
        <div className="photo-picker--main-photo" 
            onClick={() => handleOpen(photos, 0)}
            onMouseEnter={(e) => setIsMainPhotoHovered(true)}
            onMouseLeave={(e) => setIsMainPhotoHovered(false)}>
          {photos[0] &&
            <>
              <Image src={`${photos[0]}`} 
                  className="photo-picker--image" />
              {isMainPhotoHovered &&
                <div className="tw-h-[150px] tw-w-[150px] tw-relative tw--top-[150px] tw-flex 
                        tw-justify-center tw-items-center tw-cursor-pointer tw-bg-[#152744aa]">
                  <Button
                      sx={muiRedButton({bg: '#2879FB'})}>
                    View
                  </Button>
                </div>
              }
            </>
          }
        </div>
        <div className="photo-picker--list">
          {[1, 2, 3, 4, 5, 6].map(key =>
            <SafeleaseThumbnail photoIdx={key} photos={photos} handleOpen={handleOpen} key={key} />
          )}
        </div>
      </div>
      <Modal
          open={modalOpen}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={muiPhotosBg}>
        <>
          <IconButton onClick={handleClose} sx={muiPhotosXButtons}>
            <CloseIcon sx={{fontSize: '64px'}}/>
          </IconButton>
          <IconButton sx={{width: '64px', height: '64px', position: 'absolute', top: '50%', 
                left: '0', transform: 'translateY(-50%)', color: 'white', marginLeft: '200px'}}
              onClick={() => changePhoto(photos, -1)}>
            <ChevronLeftIcon sx={{color: 'white', width: '64px', height: '64px'}}/>
          </IconButton>
          <Image src={photos[modalPhotoIdx]} 
              className="photo-picker--modal-image" 
              onClick={handleClose} />
          <IconButton sx={{width: '64px', height: '64px', position: 'absolute', top: '50%', 
                right: '0', transform: 'translateY(-50%)', color: 'white', marginRight: '200px'}}
              onClick={() => changePhoto(photos, 1)}>
            <ChevronRightIcon sx={{color: 'white', width: '64px', height: '64px'}}/>
          </IconButton>
          <div className='tw-text-white tw-absolute tw-ml-[50%] tw-mr-[50%] tw-bottom-[10px]
              tw-w-[100px] tw-text-center'>
            {modalPhotoIdx + 1} of {photos.length}
          </div>
        </>
      </Modal>
    </>
  )
}

function SafeleaseThumbnail(props: {photoIdx: number, photos: string[], handleOpen: (photo: string) => void}) {
  const { photoIdx, photos, handleOpen } = props
  const [isThumbnailHovered, setIsThumbnailHovered] = useState(false)

  return (
    <div className="photo-picker--list--thumbnail" key={photoIdx}
        onClick={() => handleOpen(photos, photoIdx)} 
        onMouseEnter={(e) => setIsThumbnailHovered(true)}
        onMouseLeave={(e) => setIsThumbnailHovered(false)}>
      {photos[photoIdx] &&
        <>
          <Image src={`${photos[photoIdx]}`} 
              className="photo-picker--image" />
          {isThumbnailHovered &&
            <div onClick={() => handleOpen(photos[photoIdx])}
                className="tw-h-[40px] tw-w-[40px] tw-relative tw--top-[40px] tw-bg-[#152744aa] 
                    tw-cursor-pointer tw-flex tw-flex tw-justify-center tw-items-center">
              <VisibilityIcon sx={{color: '#2879fb'}}/>
            </div>
          }
        </>
      }
    </div>
  )
}
export default PhotoPicker;
