import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import RouterHelper from "../../utilities/router-helper";
import ReportsQueries from "../ReportsQueries";
import TitleHeader from "../../shared/title-header";
import { useReportsStore } from "../useReportsStore";
import { TenantProtectionActivityReport } from "./TenantProtectionActivityReport";
import RelationshipSelector from "../../shared/RelationshipSelector";
import ReportsLocationSelector from "../../compliance/PrivatePolicyReport/ReportsLocationSelector";
import { getRelationship } from "../../queries";
import { QueryResult, useQuery } from "@apollo/client";

interface PlanUpgradeToolProps {
  routerHelper: RouterHelper;
}

export function PlanUpgradeTool({ routerHelper }: PlanUpgradeToolProps) {
  const setRelationshipId = useReportsStore((state) => state.setRelationshipId);
  const isReportLoading = useReportsStore((state) => state.loading);
  
  const relationshipQuery: QueryResult<{ getRelationship: Relationship }> =
    useQuery(getRelationship, {
      variables: {
        id: useReportsStore((state) => state.relationshipId),
      },
    });

  return (
    <Box>
      <ReportsQueries routerHelper={routerHelper} />
      <TitleHeader title="Plan Upgrades" />
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        sx={{ mb: 2 }}
      >
        <RelationshipSelector
          routerHelper={routerHelper}
          nonRoute
          nonRouteCallback={setRelationshipId}
          disabled={isReportLoading}
        />
    
      {relationshipQuery.data && !relationshipQuery.data.getRelationship.test && 
        <ReportsLocationSelector disabled={isReportLoading}/>
      }
      </Stack>
      {isReportLoading && (
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          sx={{ height: 400 }}
        >
          <CircularProgress size={100} />
          <Typography variant="h4" sx={{ mt: 4, fontFamily: "Open Sans" }}>
            Pulling together plan upgrade data...
          </Typography>
        </Stack>
      )}
      {!isReportLoading && <TenantProtectionActivityReport routerHelper={routerHelper}/>}
    </Box>
  );
}
