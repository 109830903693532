import { useState } from 'react';
import JobsDataService from '../services/jobs.service';
import SetupDataService from '../services/setup.service';
import { useAuth } from '../auth.js';
import { fmsData } from '../utilities/fms-data';
import { Image } from '../image';
import { ghostButtonNavy, muiStatisticTooltip } from '../styles/mui-overrides';
import { Alert, Box, Button, CircularProgress, Modal, TextField, Tooltip } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import FmsLoginFields from './fms-login-fields';

export default function FmsLoginModal(props) {
  const { relationshipId, account, onClose, setAlertMessage, onAddFacilityManually } = props;
  const auth = useAuth();
  const isAdmin = auth.user.isAdmin;

  const [loading, setLoading] = useState<boolean>(false);
  const [credentialErrors, setCredentialErrors] = useState<{
    unsupportedFmsType?: { msg?: string };
    username?: { msg?: string };
    password?: { msg?: string };
  }>({});
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [accountType, setAccountType] = useState<string | null>(account?.type || null);
  const [accountCredentials, setAccountCredentials] = useState<{
    displayName?: string;
    unsupportedFmsType?: string;
    corpCode?: string;
    url?: string;
    username?: string;
    password?: string;
  }>(account?.credentials || {});
  const [fmsAdminCredentialsEnabled, setFmsAdminCredentialsEnabled] = useState<boolean>(!isAdmin);
  const [unsupportedFormSuccess, setUnsupportedFormSuccess] = useState<boolean>(false);

  const accountId = account?.id || null;

  const setFmsCredential = function (key, value) {
    let fmsCredential = { ...accountCredentials };
    fmsCredential[key] = value;
    setAccountCredentials(fmsCredential);
  };

  const toggleAdminCredentials = async function () {
    setAccountCredentials({});
    setFmsAdminCredentialsEnabled(!fmsAdminCredentialsEnabled);
  };

  const saveAccount = async function () {
    const saveAccountPayload = {
      ...accountCredentials,
      relationshipId,
      id: accountId,
      type: accountType,
      ownerCredentials: fmsAdminCredentialsEnabled,
    };

    setLoading(true);
    setCredentialErrors({});
    setErrorMessage(null);
    const addAccountResponse = await SetupDataService.addAccount(saveAccountPayload);

    if (addAccountResponse.data.errors) {
      setLoading(false);
      setCredentialErrors(addAccountResponse.data.errors);
      return;
    }

    if (accountId) {
      setAlertMessage({
        message: `Successfully updated the credentials.`,
        severity: 'success',
    });
      onClose();
      return;
    }

    if (!accountId && accountType !== 'unsupported') {
      if (fmsAdminCredentialsEnabled) {
        try {
          const createUserResponse = await SetupDataService.createSafeleaseUserJob({
            accountId: addAccountResponse.data.id,
            relationshipId,
          });
          let jobResponse;
          do {
            await new Promise((r) => setTimeout(r, 2500));
            jobResponse = await JobsDataService.status(createUserResponse.data.jobId);
          } while (jobResponse.data.status === 'pending');

          if (jobResponse.data.status === 'failed') {
            setLoading(false);
            setErrorMessage('Failed to provision SafeLease user.');
            return;
          }
        } catch (e) {
          setLoading(false);
          setErrorMessage('Failed to provision SafeLease user.');
          return;
        }
      }

      try {
        const fetchLocationsResponse = await SetupDataService.createFetchLocationsJob({
          accountId: addAccountResponse.data.id,
        });
        let jobResponse;
        do {
          await new Promise((r) => setTimeout(r, 2500));
          jobResponse = await SetupDataService.fetchAccountLocations(
            fetchLocationsResponse.data.id
          );
        } while (jobResponse.data.status !== 'ok');
        setLoading(false);

        if (setAlertMessage) {
          setAlertMessage({
            message: `Successfully connected to ${fmsData[accountType].name}.`,
            severity: 'success',
          });
        }
      } catch (e) {
        setLoading(false);
        setErrorMessage(
          'Failed to fetch the connected facilities for the facility management software.'
        );
        return;
      }
    }

    if (accountType === 'unsupported') {
      setLoading(false);
      setUnsupportedFormSuccess(true);
      return;
    }

    onClose(addAccountResponse.data.id);
  };

  return (
    <div>
      <Modal
        open={true}
        onClose={() => {
          if (!loading) onClose();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="popup-modal"
      >
        <Box className="modal-box" style={{ width: 700}}>
          {!accountType ? (
            <>
              <div className="tw-flex tw-text-2xl tw-font-bold">Software Login</div>
              <div className="tw-flex tw-text-xs">
                Login with your facility’s management software credentials and get key facility
                insights to help you uncover opportunities.
              </div>
              <div
                className="settings--breaker-bar"
                style={{ marginBottom: 0, height: '20px' }}
              ></div>
              <div className="tw-flex tw-justify-center tw-font-bold">
                Choose your facility's management software.
              </div>
              {Object.keys(fmsData).map((fmsType) => {
                if (!isAdmin && fmsType === 'unsupported') return null;
                return (
                  <div key={fmsType} className="tw-flex tw-m-2">
                    <Button
                      onClick={() => setAccountType(fmsType)}
                      sx={{ ...ghostButtonNavy, height: '60px', width: '100%', marginLeft: 0 }}
                    >
                      {fmsData[fmsType].name}
                    </Button>
                  </div>
                );
              })}
              <div className="tw-flex tw-justify-center tw-mt-4 tw-text-xs">
                <div>If your facility's management software is not listed above, please email us at{' '}
                <a href="mailto:support@safelease.com" target="_blank" rel="noreferrer">
                  support@safelease.com
                </a> or call us at (512) 767-1408.</div>
              </div>
              {isAdmin && onAddFacilityManually && (
                <>
                  <div
                    className="settings--breaker-bar"
                    style={{ marginBottom: 0, height: '20px' }}
                  ></div>
                  <div key={'manual'} className="tw-flex tw-m-2">
                    <Button
                      onClick={onAddFacilityManually}
                      sx={{ ...ghostButtonNavy, height: '60px', width: '100%', marginLeft: 0 }}
                    >
                      <div className="tw-ml-20 tw-font-bold">Add facility manually</div>
                      <ArrowForwardIcon className="tw-ml-12" />
                    </Button>
                  </div>
                </>
              )}
            </>
          ) : (
            <>
              {unsupportedFormSuccess ? (
                <div className="tw-flex tw-flex-col tw-items-center">
                  <span className="badge tw-mb-5">
                    <Image style={{ width: 50 }} src="/images/checkmark.svg" alt="checkmark" />
                  </span>
                  Your response has been successfully recorded. A member of our sales team will be
                  in touch with you.
                  <Button className="modal-login-button" onClick={() => onClose()}>
                    Exit
                  </Button>
                </div>
              ) : (
                <>
                  <div className="tw-flex tw-justify-center tw-w-full tw-text-2xl tw-font-bold">
                    <Image
                      src={fmsData[accountType].image}
                      alt={fmsData[accountType].name}
                      style={{ width: 40, marginRight: 10 }}
                    />
                    {fmsData[accountType].name}
                  </div>
                  <div className="tw-flex tw-justify-center tw-mt-5">
                    {accountType === 'unsupported' ? (
                      <div className="tw-ml-2 tw-mr-2">
                        Enter your software details and we will contact you. This option does not
                        include insights.
                      </div>
                    ) : (
                      <>
                        {fmsAdminCredentialsEnabled ? (
                          <div className="tw-text-sm">
                            Login to your facility management software. SafeLease will create a
                            provisioned user on your behalf.
                          </div>
                        ) : (
                          <>
                            <div className="tw-text-sm">
                              Login using the SafeLease user's credentials.
                            </div>
                            <Tooltip
                              placement="bottom"
                              title={'SafeLease will use the SafeLease user that is already provisioned to connect with your software going forward.'}
                              componentsProps={{
                                tooltip: {
                                  sx: { backgroundColor: '#152744' },
                                },
                              }}
                            >
                              <Button sx={muiStatisticTooltip}>
                                <span className="badge">
                                  <Image src="/images/tooltip-icon.svg" alt="warning" />
                                </span>
                              </Button>
                            </Tooltip>
                          </>
                        )}
                      </>
                    )}
                  </div>

                  <FmsLoginFields accountCredentials={accountCredentials} setFmsCredential={setFmsCredential} accountType={accountType} credentialErrors={credentialErrors}/>

                  {loading ? (
                    <Box textAlign="center" marginTop="10px">
                      <CircularProgress />
                    </Box>
                  ) : (
                    <Box textAlign="center">
                      <Button className="modal-login-button" onClick={() => saveAccount()}>
                        {accountId
                          ? 'Update Credentials'
                          : accountType === 'unsupported'
                          ? 'Submit'
                          : fmsAdminCredentialsEnabled
                          ? 'Login'
                          : 'Login as SafeLease'}
                      </Button>
                      {!accountId && ['ess', 'storedge', 'wss'].includes(accountType) && (
                        <div className="tw-mt-10">
                          <Button
                            style={{ textTransform: 'inherit' }}
                            onClick={() => toggleAdminCredentials()}
                          >
                            {fmsAdminCredentialsEnabled
                              ? 'Already have a SafeLease user?'
                              : 'Want SafeLease to handle this for you?'}
                          </Button>
                          <Tooltip
                            placement="bottom"
                            title={
                              fmsAdminCredentialsEnabled
                                ? "In the case where you have already provisioned SafeLease as an admin user in your facility management software, you can connect by simply adding the SafeLease user's credentials."
                                : 'You can have SafeLease temporarily use your credentials to securely provision a SafeLease user which is how SafeLease will connect with your software going forward.'
                            }
                            componentsProps={{
                              tooltip: {
                                sx: { backgroundColor: '#152744' },
                              },
                            }}
                          >
                            <Button sx={muiStatisticTooltip}>
                              <span className="badge">
                                <Image src="/images/tooltip-icon.svg" alt="warning" />
                              </span>
                            </Button>
                          </Tooltip>
                        </div>
                      )}
                    </Box>
                  )}

                  {errorMessage && (
                    <Box textAlign="center" marginTop="10px">
                      <Alert severity="error">{errorMessage}</Alert>
                    </Box>
                  )}
                </>
              )}
            </>
          )}
        </Box>
      </Modal>
    </div>
  );
}
