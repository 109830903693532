import dayjs from "dayjs";
import { StateCreator } from "zustand";
import _ from "lodash";
import { ReportDetailsTotals } from "../../utilities/generated/gql-types";
import { DailySummaryReportTotals } from "../DailySummaryReport/DailySummaryReport";
import { sliceResetFns } from "./useReportsStore";

export enum SummaryType {
  Daily,
  Range,
  MonthToDate,
  YearToDate,
  LastYearMonthToDate,
  LastYearYearToDate,
}

export interface SummaryReportSliceState {
  summaryType: SummaryType;
  startDate?: Date;
  endDate?: Date;
  selectedLocationsInReport: number[];
  dailySummaries: any[];
  dailySummaryTotals: DailySummaryReportTotals;
  dailySummaryExcludedTotals: DailySummaryReportTotals;
}

export interface SummaryReportSlice extends SummaryReportSliceState {
  setSummaryType: (summaryType: SummaryType) => void;
  setStartDate: (startDate: Date) => void;
  setEndDate: (endDate: Date) => void;
  setSelectedLocationsInReport: (selectedLocationsInReport: number[]) => void;
  setDailySummaries: (dailySummaries: any[]) => void;
  updateDailySummaryTotalsWithExcludedLocations: () => void;
}

/* Default summary totals */
const defaultTotals: DailySummaryReportTotals = {
  projectedRent: 0,
  unoccupiedUnits: 0,
  totalUnits: 0,
  totalSquareFootage: 0,
  occupiedSquareFootage: 0,
  occupiedSquareFootagePrevMonth: 0,
  deltaSquareFootage: 0,
  beginningOccupancy: 0,
  endingOccupancy: 0,
  moveIns: 0,
  moveOuts: 0,
  netMoveIns: 0,
  receivableRent: 0,
  receivableRentMoreThanThirtyDays: 0,
  receivableRentMoreThanThirtyDaysPercent: "0",
  occupiedSquareFootagePercent: "0",
  occupiedUnitsPercent: "0",
};

const summaryReportSliceDefaults: SummaryReportSliceState = {
  summaryType: SummaryType.Daily,
  startDate: dayjs(new Date()).startOf("day").toDate(),
  endDate: dayjs(new Date()).endOf("day").toDate(),
  selectedLocationsInReport: [],
  dailySummaries: [],
  dailySummaryTotals: defaultTotals,
  dailySummaryExcludedTotals: defaultTotals,
};

// Slice defaults to "today"
export const createSummaryReportSlice: StateCreator<SummaryReportSlice> = (
  set,
  get
) => {
  sliceResetFns.add(() => set(summaryReportSliceDefaults));
  return {
    ...summaryReportSliceDefaults,
    setSummaryType: (summaryType: SummaryType) => set({ summaryType }),
    setStartDate: (startDate: Date) => set({ startDate }),
    setEndDate: (endDate: Date) => set({ endDate }),
    setSelectedLocationsInReport: (selectedLocationsInReport: number[]) =>
      set({ selectedLocationsInReport }),
    setDailySummaries: (dailySummaries: any[]) => {
      set({ dailySummaries });
      // get().updateDailySummaryTotalsWithExcludedLocations()
    },
    updateDailySummaryTotalsWithExcludedLocations: () => {
      /* Create a list of location IDs that should not be included in the report */
      const locationsIdsToExcludeFromReport = _.differenceWith(
        get().dailySummaries.map((row) => row.Location.id) ?? [],
        get().selectedLocationsInReport,
        _.isEqual
      );

      /* Use the excluded location ID list to grab all the daily summaries that we need to ignore */
      const dailySummariesToExcludeFromReport =
        get().dailySummaries?.filter((row) =>
          locationsIdsToExcludeFromReport.includes(row.Location.id)
        ) ?? [];

      const totalsToExcludeFromDailyReport: DailySummaryReportTotals = {
        projectedRent: dailySummariesToExcludeFromReport.reduce(
          (total, summary) => total + summary.projectedRent,
          0
        ),
        receivableRent: dailySummariesToExcludeFromReport.reduce(
          (total, summary) => total + summary.receivableRent,
          0
        ),
        netMoveIns: dailySummariesToExcludeFromReport.reduce(
          (total, summary) => total + (summary.moveIns - summary.moveOuts),
          0
        ),
        unoccupiedUnits: dailySummariesToExcludeFromReport.reduce(
          (total, summary) =>
            total + (summary.totalUnits - summary.endingOccupancy),
          0
        ),
        totalUnits: dailySummariesToExcludeFromReport.reduce(
          (total, summary) => total + summary.totalUnits,
          0
        ),
        totalSquareFootage: dailySummariesToExcludeFromReport.reduce(
          (total, summary) => total + summary.totalSquareFootage,
          0
        ),
        occupiedSquareFootage: dailySummariesToExcludeFromReport.reduce(
          (total, summary) => total + summary.occupiedSquareFootage,
          0
        ),
        occupiedSquareFootagePrevMonth:
          dailySummariesToExcludeFromReport.reduce(
            (total, summary) => total + summary.occupiedSquareFootagePrevMonth,
            0
          ),
        deltaSquareFootage: dailySummariesToExcludeFromReport.reduce(
          (total, summary) =>
            total +
            (summary.occupiedSquareFootage -
              summary.occupiedSquareFootagePrevMonth),
          0
        ),
        beginningOccupancy: dailySummariesToExcludeFromReport.reduce(
          (total, summary) => total + summary.beginningOccupancy,
          0
        ),
        endingOccupancy: dailySummariesToExcludeFromReport.reduce(
          (total, summary) => total + summary.endingOccupancy,
          0
        ),
        moveIns: dailySummariesToExcludeFromReport.reduce(
          (total, summary) => total + summary.moveIns,
          0
        ),
        moveOuts: dailySummariesToExcludeFromReport.reduce(
          (total, summary) => total + summary.moveOuts,
          0
        ),
        receivableRentMoreThanThirtyDays:
          dailySummariesToExcludeFromReport.reduce(
            (total, summary) =>
              total + summary.receivableRentMoreThanThirtyDays,
            0
          ),
        receivableRentMoreThanThirtyDaysPercent: (
          (_.sumBy(
            dailySummariesToExcludeFromReport,
            (s) => s.receivableRentMoreThanThirtyDays
          ) /
            _.sumBy(
              dailySummariesToExcludeFromReport,
              (s) => s.projectedRent
            )) *
          100
        ).toFixed(2),
        occupiedSquareFootagePercent: (
          (_.sumBy(
            dailySummariesToExcludeFromReport,
            (s) => s.occupiedSquareFootage
          ) /
            _.sumBy(
              dailySummariesToExcludeFromReport,
              (s) => s.totalSquareFootage
            )) *
          100
        ).toFixed(2),
        occupiedUnitsPercent: (
          (_.sumBy(
            dailySummariesToExcludeFromReport,
            (s) => s.endingOccupancy
          ) /
            _.sumBy(dailySummariesToExcludeFromReport, (s) => s.totalUnits)) *
          100
        ).toFixed(2),
      };

      set({ dailySummaryExcludedTotals: totalsToExcludeFromDailyReport });
    },
  };
};
