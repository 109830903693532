import RelationshipDataService from '../services/relationship.service';
import { QueryResult } from '@apollo/client';
import { Relationship } from '../utilities/generated/gql-types';
import { useHistory } from 'react-router-dom';
import { TableActionsDropdown } from './table-actions-dropdown';

function RelationshipActions(props: {
  id: number;
  relationshipsQuery: QueryResult;
  relationship: Relationship;
}) {
  const { id: relationshipId, relationshipsQuery, relationship } = props;
  const history = useHistory();
  const menuItems = [
    {
      label: 'Edit Partner Details',
      callback: () => {
        history.push(`/relationships/add_plan?relationshipId=${relationshipId}`);
      },
    },
    {
      label: 'View Jobs',
      callback: () => {
        history.push(`/admin/settings/jobs/${relationshipId}`);
      },
    },
    {
      label: 'View Notes/Changes',
      callback:() => {
        history.push(`/admin/notes/${relationshipId}`);
      }
    },
    {
      label: 'View Invoices',
      callback:() => {
        history.push(`/admin/invoices/${relationshipId}`);
      }
    },
    {
      label: 'View Locations',
      callback:() => {
        history.push(`/admin/settings/locations/${relationshipId}`);
      }
    },
    {
      label: relationship.safeleaseStatus === 'test' ? 'Remove Test Flag' : 'Mark as Test',
      callback: async () => {
        await RelationshipDataService.updateByField({
          relationshipId,
          key: 'test',
          value: relationship.safeleaseStatus === 'test' ? false : true,
        });
        await relationshipsQuery.refetch();
      },
      style: { color: 'darkorange', '&:hover': { backgroundColor: 'darkorange', color: 'white' } },
    },
    {
      label: relationship.salesforceSync ? 'Disable Salesforce Syncing' : 'Enable Salesforce Syncing',
      callback: async () => {
        if (!window.confirm(`Are you sure you want to ${relationship.salesforceSync ? 'disable' : 'enable'} Salesforce syncing for this relationship?`)) return;
        await RelationshipDataService.updateSalesforceSync({ relationshipId, salesforceSync: !relationship.salesforceSync });
        await relationshipsQuery.refetch();
      },
      style: { color: 'darkorange', '&:hover': { backgroundColor: 'darkorange', color: 'white' } },
    },
  ];

  return <TableActionsDropdown menuItems={menuItems} />;
}

export function relationshipActionsRenderer(
  id: number,
  tableInfo: { row: Relationship; queryResult: QueryResult }
) {
  return (
    <RelationshipActions
      id={id}
      relationshipsQuery={tableInfo.queryResult}
      relationship={tableInfo.row}
    />
  );
}
