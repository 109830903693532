import AvatarDropdown from "./avatar-dropdown";
import { useHistory, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { ghostButtonNavy } from "../styles/mui-overrides";
import { Button } from "@mui/material";
import { Image } from '../image';
import { mixpanelEventHandler, mixpanelReset } from "../utilities/reactMixpanelHandler";
import { useAuth } from "../auth";
import { useReportsStore } from "../reports/useReportsStore";

function TitleHeader (props: {
  title: string,
  exportCsvFunction?: Function
}) {
  const location = useLocation();
  const auth = useAuth();
  const history = useHistory();
  const resetReportStore = useReportsStore((state) => state.reset)
  const [isBetaIndicatorVisible, setIsBetaIndicatorVisible] = useState<boolean>(false);
  useEffect(() => {
    if (location.pathname.includes("documents") || location.pathname.includes("settings") || location.pathname.includes("insights")) {
      setIsBetaIndicatorVisible(true);
    }
  }, [JSON.stringify(location.pathname)])

  const logOut = () => {
    resetReportStore();
    mixpanelEventHandler('Logout');
    mixpanelReset(); 
    auth.signout(() => history.push("/"));
  };

  const goToSettings = () => {
    history.push('/settings');
  }

  const dropdownOptions = [
    {
      label: "Settings",
      cb: goToSettings,
    },
    {
      label: "Logout",
      cb: logOut,
    },
  ];

  return (
    <>
      <div className="page-title">
        <div>
          {props.title}
        </div>
        <div className="tw-ml-[74px] tw-flex tw-items-center">
          {isBetaIndicatorVisible && 
            <div className='tw-flex tw-items-center tw-mr-[10px]'>
              <div className="tw-h-8 tw-w-8 tw-bg-cover">
                <Image src="/images/safelease-logo-small.svg" />
              </div>
              <div className="tw-flex tw-items-end">
                <span className="tw-text-sm tw-ml-1">Beta |</span>
                <span className="tw-text-xs tw-ml-1">This feature is currently in testing.</span>
              </div>
            </div>
          }
          {props.exportCsvFunction &&
            <Button
              sx={{...ghostButtonNavy, marginRight: '1rem', height: '36px'}}
              onClick={(e) => props.exportCsvFunction(e)}
            >
              Export CSV
            </Button>
          }
          <AvatarDropdown dropdownOptions={dropdownOptions} />
        </div>
      </div>
    </>
  )
}
  
export default TitleHeader;
