import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import { muiRedButton, muiDrawer, ghostButtonNavy } from '../styles/mui-overrides';

export default function SafeleaseDrawer(props: {
  isOpen: boolean;
  children: any;
  title: string;
  close: () => void;
  callback?: () => void;
  primaryFooterButtonText?: string;
  secondaryFooterButtonText?: string;
  secondaryFooterButtonCallback?: () => void;
}) {
  const {
    isOpen,
    close,
    children,
    title,
    primaryFooterButtonText,
    secondaryFooterButtonText,
    secondaryFooterButtonCallback = () => {},
    callback = () => {},
  } = props;

  const handleClose = () => {
    callback();
    close();
  };

  return (
    <>
      <Drawer open={isOpen} anchor="right" onClose={close}>
        <Box
          role="presentation"
          sx={muiDrawer}
          className="claim-details claim-details__email-settings-drawer"
        >
          <span className="claim-details__email-settings-drawer--drawer-title">{title}</span>

          <div className="claim-details__email-setting-drawer--body">{children}</div>

          <div style={{ color: 'gray', position: 'fixed', bottom: 0, padding: 0, width: '833px' }}>
            <p className="tw-mb-2">
              Please contact your Customer Success Manager if you would like to modify any dates.
            </p>
            <div className="claim-details--bottom claim-details--bottom__email-settings">
              {secondaryFooterButtonText ? (
                <Button sx={ghostButtonNavy} onClick={secondaryFooterButtonCallback}>
                  {secondaryFooterButtonText}
                </Button>
              ) : (
                // this lives to properly space the single action button if there is only one
                <div className="" />
              )}
              <Button sx={muiRedButton({ maxWidth: true })} onClick={handleClose}>
                {primaryFooterButtonText ? primaryFooterButtonText : 'OK'}
              </Button>
            </div>
          </div>
        </Box>
      </Drawer>
    </>
  );
}
