import React from "react";
import { muiSelect } from "../../../../styles/mui-overrides";
import { StyledFormControl, FlexRowBox } from "../MyAccountStyledComponents";
import { MenuItem, Select, SelectChangeEvent, Typography } from "@mui/material";
import { FlexColumnBox } from "../../OrganizationSettings/OrganizationStyledComponents";

interface AdminSelectorsProps {
  relationshipSelectorId: string;
  userSelectorId: string;
  fetchRelationshipsSelectorResult: any;
  fetchUsersForRelationshipResult: any;
  handleRelationshipSelectorChange: (event: SelectChangeEvent) => void;
  handleUserSelectorChange: (event: SelectChangeEvent) => void;
}

const AdminSelectors: React.FC<AdminSelectorsProps> = ({
  relationshipSelectorId,
  userSelectorId,
  fetchRelationshipsSelectorResult,
  fetchUsersForRelationshipResult,
  handleRelationshipSelectorChange,
  handleUserSelectorChange,
}) => {
  return (
    <>
      <FlexColumnBox
        sx={{
          border: "1px solid #E9E9E9",
          paddingLeft: "5px",
          paddingTop: "5px",
          marginBottom: "15px",
          borderRadius: "5px",
        }}
      >
        <Typography variant="body1" sx={{ fontSize: '14px', opacity: '0.75' }}>These selectors are only visible and accessible to admins and superadmins.</Typography>
        <FlexRowBox>
          <StyledFormControl>
            <Select
              value={relationshipSelectorId}
              displayEmpty
              onChange={handleRelationshipSelectorChange}
              sx={{ ...muiSelect, marginLeft: "0px", padding: "0px" }}
            >
              {fetchRelationshipsSelectorResult.data?.data?.map((relationship: { id: number; name: string }) => {
                return (
                  <MenuItem key={relationship.id} value={relationship.id}>
                    {relationship.name}
                  </MenuItem>
                );
              })}
            </Select>
          </StyledFormControl>
          <StyledFormControl>
            <Select
              value={userSelectorId}
              displayEmpty
              onChange={handleUserSelectorChange}
              sx={{ ...muiSelect, marginLeft: "20px", padding: "0px" }}
            >
              {fetchUsersForRelationshipResult.data?.edges.map((user) => {
                return (
                  <MenuItem key={user.id} value={user.id}>
                    {user.name}
                  </MenuItem>
                );
              })}
            </Select>
          </StyledFormControl>
        </FlexRowBox>
      </FlexColumnBox>
    </>
  );
};

export default AdminSelectors;
