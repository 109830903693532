import EmailIcon from '@mui/icons-material/Email';
import SafeleaseWrapper from '../shared/safelease-wrapper';
import { useHistory } from 'react-router-dom';
import { mixpanelEventHandler, mixpanelReset } from '../utilities/reactMixpanelHandler';
import { useAuth } from '../auth';

export default function EmailVerification(props: { refetch: () => void }) {
  const auth = useAuth();
  const history = useHistory();

  async function refetch() {
    while (true) {
      await new Promise((r) => setTimeout(r, 5000));
      props.refetch();
    }
  }
  refetch();

  const logout = () => {
    mixpanelEventHandler('Logout');
    mixpanelReset(); 
    auth.signout(() => history.push("/"));
  };

  return (
    <SafeleaseWrapper>
      <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-h-screen">
        <EmailIcon sx={{ height: '8rem', width: '8rem' }} />
        <div className="tw-text-3xl tw-font-bold">Email verification sent!</div>
        <div className="tw-mt-20">Please check your email and follow the instructions to verify your account.</div>
        <div>Having trouble? <a onClick={logout} href="/">Start here</a>.</div>
      </div>
    </SafeleaseWrapper>
  );
}