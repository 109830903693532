import React, { useState } from "react";

// UI - libs
import { Popover, MenuItem, IconButton } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";

// UI - internal
import DeleteLocationAddressDialog from "./DeleteLocationAddressDialog";
import UpdateLocationAddressDialog from "./UpdateLocationAddressDialog";

enum LocationAddressSettingsPopoverOptions {
  Delete = "delete",
  Edit = "edit",
}

// Popover with settings to interact with location address
// (edit, delete, etc)
export default function LocationAddressSettingsPopover({
  location,
  locationAddress,
}) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const [openDialog, setOpenDialog] =
    useState<LocationAddressSettingsPopoverOptions | null>();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handleMenuOptionClick = (option) => {
    setOpenDialog(option);
    setAnchorEl(null);
  };

  const handleCloseDialog = () => {
    setOpenDialog(null);
  };

  // const handleDeleteOptionClick = () => {
  //   setIsDeleting(true);
  // };

  return (
    <>
      <div className="tw-flex tw-justify-center">
        <IconButton
          size="small"
          aria-label="more"
          id="popover-button"
          onClick={handleClick}
          disabled={locationAddress.primary}
        >
          <MoreVertIcon />
        </IconButton>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <MenuItem
            onClick={() =>
              handleMenuOptionClick(LocationAddressSettingsPopoverOptions.Edit)
            }
          >
            Edit
          </MenuItem>
          <MenuItem
            onClick={() =>
              handleMenuOptionClick(
                LocationAddressSettingsPopoverOptions.Delete
              )
            }
          >
            Delete
          </MenuItem>
        </Popover>
      </div>
      <UpdateLocationAddressDialog
        open={openDialog === LocationAddressSettingsPopoverOptions.Edit}
        onClose={handleCloseDialog}
        location={location}
        locationAddress={locationAddress}
      />
      <DeleteLocationAddressDialog
        open={openDialog === LocationAddressSettingsPopoverOptions.Delete}
        onClose={handleCloseDialog}
        location={location}
        locationAddress={locationAddress}
      />
    </>
  );
}
