// UI - libs
import { Divider, Drawer, Grid, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Place as PlaceIcon } from "@mui/icons-material";

// UI - internal
import GeneralUnitHistoryTimeline from "./GeneralUnitHistoryTimeline";

// Hooks & State
import { useGeneralUnitsHistory } from "../useGeneralUnitsHistory";
import { useQuery } from "@apollo/client";
import { getLocation } from "../../../queries";
import { Location } from "../../../utilities/generated/gql-types";

export default function GeneralUnitsHistoryDrawer() {
  const { drawerOpen, setDrawerOpen, selectedGeneralUnit } = useGeneralUnitsHistory();

  const locationQuery = useQuery<{ getLocation: Location }>(getLocation, {
    variables: {
      locationId: selectedGeneralUnit ? selectedGeneralUnit.locationId : null,
    },
    // Optional chaining in the skip allows us to not run the query if the locationId is not present
    // Additionally, this allows us to continue to render the contents of the drawer without the location information
    // Many of the fields will be empty, but it looks better than returning null -- we'll lose the drawer open animation
    // if we do
    skip: !selectedGeneralUnit?.locationId,
  });

  return (
    <Drawer open={drawerOpen} anchor="right" onClose={() => setDrawerOpen(false)}>
      <Box sx={{ width: 500, p: 2 }} role="presentation">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box>
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="h5">Unit {selectedGeneralUnit?.unitId}</Typography>
                <Typography variant="caption" sx={{ color: "gray" }}>
                  General Unit ID #{selectedGeneralUnit?.id}
                </Typography>
              </Stack>

              <Stack direction="row" spacing={1}>
                <PlaceIcon sx={{ color: "gray" }} fontSize="small" />
                <Typography variant="caption" sx={{ color: "gray" }}>
                  {locationQuery.data?.getLocation?.fullAddress}
                </Typography>
              </Stack>
            </Box>
            <Divider sx={{ my: 2 }} />
          </Grid>
          <Grid item xs={12}>
            <GeneralUnitHistoryTimeline />
          </Grid>
        </Grid>
      </Box>
    </Drawer>
  );
}
