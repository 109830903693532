import { StateCreator } from "zustand";
import { Relationship } from "../../../utilities/generated/gql-types";
import { PlanUpgradeSettingsStore } from "./usePlanUpgradeSettingsStore";

export interface PlanUpgradeSettingsRelationshipSliceState {
  selectedRelationship: Relationship | null;
}

export interface PlanUpgradeSettingsRelationshipSlice
  extends PlanUpgradeSettingsRelationshipSliceState {
  setSelectedRelationship: (relationship: Relationship) => void;
}

const initialState: PlanUpgradeSettingsRelationshipSliceState = {
  selectedRelationship: null,
};

/**
 * Control which relationship is selected in the plan upgrade form
 */
export const createPlanUpgradeSettingsRelationshipSlice: StateCreator<
  Partial<PlanUpgradeSettingsStore> & PlanUpgradeSettingsRelationshipSlice
> = (set) => {
  return {
    ...initialState,
    setSelectedRelationship: (relationship: Relationship) => {
      set({
        selectedRelationship: relationship,
        disableContinue: !relationship,
        selectedLocations: [], // Reset locations on relationship change
      });
    },
  };
};
