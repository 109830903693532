import {
  Button,
  Checkbox,
  Snackbar,
  Tooltip,
  Typography,
  Stack,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
  IconButton,
} from "@mui/material";
import { useQuery } from "@apollo/client";
import { useMemo, useState } from "react";
import { enqueueSnackbar } from "notistack";
import RouterHelper from "../../../utilities/router-helper";
import { getLocations } from "../../../queries";
import { AdminsOnly } from "../../components/AdminsOnly";
import { SafeLeaseCard } from "@safelease/components";
import { useReputation } from "../../useReputation";
import { PartnersOnly } from "../../components/PartnersOnly";

type Props = { routerHelper: RouterHelper };

export default function FacilityNotInRepList({ routerHelper }: Props) {
  const selectedRelationshipId = routerHelper.getRelationshipId();
  const { portfolio, SafeLeaseAPI, refresh } = useReputation();
  //For checkbox selection
  const [selectedFacilities, setSelectedFacilities] = useState<string[]>([]);
  //For loading state of facilities once Activate is clicked.
  const [loadingLocationIds, setLoadingLocationIds] = useState<string[]>([]);
  //For confirm dialog
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  //List of facilities that have been activated for reputation management before the page has been reloaded.
  //This is used to prevent double activation of facilities.
  const [activatedFacilities, setActivatedFacilities] = useState<string[]>([]);
  // pop up for partners to contact CS to activate facilities
  const [openContactCS, setOpenContactCS] = useState(false);

  const { loading, data, refetch } = useQuery(getLocations, {
    variables: { relationshipId: selectedRelationshipId },
    skip: !selectedRelationshipId,
    pollInterval: 60000,
  });

  // Memoized array of facility IDs that have not been activated in Reputation Management but exist as a location in Core.
  // Only recomputes when loading, data, portfolio or activatedFacilities change
  const facilitiesIdsNotActivatedInRep = useMemo(() => {
    // Return empty array if still loading or no data available
    if (loading || !data) return [];

    return Array.from(
      new Set(
        data.getLocations
          // Filter locations that:
          // 1. Don't exist in portfolio's facilities by matching customerLocationId
          // 2. Haven't been activated during this session held in activatedFacilities
          .filter(
            (location) =>
              !portfolio?.facilities.some((facility) => String(facility.customerLocationId) === String(location.id)) &&
              !activatedFacilities.includes(String(location.id)),
          )
          // Convert filtered locations to array of location IDs as strings
          .map((location) => String(location.id)),
      ),
    );
  }, [loading, data, portfolio, activatedFacilities]);

  const handleConfirmActivate = async () => {
    setOpenConfirmDialog(false);
    await handleActivateForRepClick(selectedFacilities);
  };

  // Handler function to activate facilities for reputation management
  const handleActivateForRepClick = async (locationIds: string[]) => {
    setLoadingLocationIds((prev) => [...prev, ...locationIds]);

    // Show initial "please wait" message
    enqueueSnackbar("Please wait while we activate the facilities...", {
      variant: "info",
      autoHideDuration: 3000, // Will hide after 3 seconds
    });

    try {
      await SafeLeaseAPI.reputation.activateFacilityInReputation(portfolio.id, locationIds);

      // Refetch the data immediately after successful activation
      refresh;
      await refetch();

      // Remove activated facilities from selected list
      setSelectedFacilities((prev) => prev.filter((id) => !locationIds.includes(id)));
      setActivatedFacilities((prev) => [...prev, ...locationIds]);

      // Delay the success message by 3 seconds
      setTimeout(() => {
        enqueueSnackbar(
          <>
            <strong style={{ textDecoration: "underline" }}>
              {data.getLocations.filter((loc) => locationIds.includes(String(loc.id))).length} facilities
            </strong>{" "}
            : Successfully activated for reputation management
          </>,
          { variant: "success" },
        );
      }, 3000);
    } catch (error) {
      // Delay the error message as well
      setTimeout(() => {
        enqueueSnackbar("Failed to activate facility for reputation management", {
          variant: "error",
        });
      }, 3000);
    } finally {
      setLoadingLocationIds((prev) => prev.filter((id) => !locationIds.includes(id)));
    }
  };

  //  open the confirmation dialog when user clicks "Activate Selected" button
  const handleActivateSelected = () => {
    if (!selectedFacilities.length) {
      enqueueSnackbar("No facilities selected", { variant: "error" });
      return;
    }
    setOpenConfirmDialog(true);
  };

  //  select all facilities that haven't been activated in Reputation Management
  const handleSelectAll = () => setSelectedFacilities(facilitiesIdsNotActivatedInRep as string[]);

  //clear all selected facilities
  const handleCancelSelection = () => setSelectedFacilities([]);

  // pop up for partners to contact CS to activate facilities
  const handleContactCS = () => {
    setOpenContactCS(true);
  };

  // If there are no facilities to activate, don't render anything in the Facilities Without Reputation section
  if (!facilitiesIdsNotActivatedInRep.length) return null;

  return (
    <>
      <Stack direction="column" spacing={1} sx={{ mt: 2 }}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography sx={{ mb: 1, fontWeight: "bold", mt: 2 }}>Facilities Without Reputation Management</Typography>
          <AdminsOnly>
            <Tooltip title="Buttons only visible to admins">
              <Stack direction="row" spacing={1}>
                <Button variant="outlined" color="primary" onClick={handleSelectAll}>
                  Select All
                </Button>
                <Button variant="outlined" onClick={handleCancelSelection}>
                  Clear
                </Button>
                <Button variant="contained" color="primary" onClick={handleActivateSelected}>
                  Activate Selected ({selectedFacilities.length})
                </Button>
              </Stack>
            </Tooltip>
          </AdminsOnly>
          <PartnersOnly>
            <Button variant="contained" color="primary" onClick={handleContactCS}>
              Activate Additional Facilities
            </Button>
          </PartnersOnly>
        </Stack>

        {data?.getLocations
          .filter((location) => facilitiesIdsNotActivatedInRep.includes(String(location.id)))
          .map((location) => (
            <SafeLeaseCard key={location.name} sx={{ p: 2, width: "100%", backgroundColor: "grey.200" }}>
              <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Stack>
                  <Typography sx={{ fontWeight: 700 }}>{location.name}</Typography>
                  <Typography sx={{ fontWeight: 700 }}>{location.address}</Typography>
                </Stack>
                <AdminsOnly>
                  {loadingLocationIds.includes(location.id) ? (
                    <Typography>Activating...</Typography>
                  ) : (
                    <Checkbox
                      checked={selectedFacilities.includes(String(location.id))}
                      onChange={(e) => {
                        const newSelected = e.target.checked
                          ? [...selectedFacilities, String(location.id)]
                          : selectedFacilities.filter((id) => id !== String(location.id));
                        setSelectedFacilities(newSelected);
                      }}
                    />
                  )}
                </AdminsOnly>
              </Stack>
            </SafeLeaseCard>
          ))}
      </Stack>
      {/* Modal for confirming activation of facilities, extra check for the Multi Select/ Select All buttons */}
      <Dialog open={openConfirmDialog} onClose={() => setOpenConfirmDialog(false)}>
        <DialogTitle sx={{ px: 3, pt: 2 }}>Confirm Activation</DialogTitle>
        <DialogContent sx={{ px: 3 }}>
          <DialogContentText>
            <Typography>
              Are you sure you want to activate{" "}
              <Box component="span" sx={{ fontWeight: "bold" }}>
                {selectedFacilities.length}
              </Box>{" "}
              facilities for reputation management?
            </Typography>
            <Box sx={{ mt: 1 }}>
              {data?.getLocations
                .filter((location) => selectedFacilities.includes(String(location.id)))
                .map((location) => (
                  <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Typography key={location.id}>{location.name}</Typography>
                    <Typography>Location ID: {location.id}</Typography>
                  </Stack>
                ))}
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ px: 3, pb: 2 }}>
          <Button onClick={() => setOpenConfirmDialog(false)} color="primary" variant="outlined">
            Cancel
          </Button>
          <Button onClick={handleConfirmActivate} color="primary" variant="contained">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openContactCS} onClose={() => setOpenContactCS(false)} sx={{ "& .MuiDialog-paper": { p: 2 } }}>
        <DialogTitle>
          Activate Additional Facilities
          <IconButton
            aria-label="close"
            onClick={() => setOpenContactCS(false)}
            sx={{
              position: "absolute",
              right: 12,
              top: -8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            x
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please contact{" "}
            <Box component="span" sx={{ fontWeight: "bold" }}>
              Customer Success
            </Box>{" "}
            to activate additional facilities for reputation management.
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
}
