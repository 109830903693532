// UI - libs
import { Drawer, IconButton, List } from '@mui/material';
import { Box, SxProps } from '@mui/system';
import { Menu } from '@mui/icons-material';

// UI - internal
import {
  NavigationAccordion,
  NavigationSectionHeader,
  AdminModeActiveBox,
  SafeleaseNavLogo,
} from './';

// Hooks
import { useState, useCallback, Fragment, useContext } from 'react';
import { NotificationsContext } from '../utilities/notifications-context';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../auth';
import useVisited from './useVisited';

// Utils
import RouterHelper from '../utilities/router-helper';
import { NavigationDrawerListItem } from './NavigationDrawerListItem';
import { getNavigationStructure } from './routes';

interface NavigationDrawerProps {
  routerHelper: RouterHelper;
}

/* Left-hand navigation drawer */
export function NavigationDrawer({ routerHelper }: NavigationDrawerProps) {
  const [drawerOpen, setDrawerOpen] = useState(true);
  const location = useLocation();
  const auth = useAuth();
  const { hasVisited: hasVisitedCompliance } = useVisited('compliance');
  const notificationsContext = useContext(NotificationsContext);

  const activeNotifications = useCallback(
    notificationsContext => ({
      [routerHelper.dashboardRoute()]:
        notificationsContext.NotificationsHelper.hasClaimNoteNotification(
          notificationsContext.allNotifications,
        ),
      [routerHelper.complianceRoute()]: !hasVisitedCompliance,
      [routerHelper.documentsRoute()]:
        notificationsContext.NotificationsHelper.hasInvoiceNotification(
          notificationsContext.allNotifications,
        ) > 0,
    }),
    [hasVisitedCompliance, routerHelper],
  );

  if (!drawerOpen) {
    return <MinimizedNavDrawer setDrawerOpen={setDrawerOpen} />;
  }

  const navigationSections = getNavigationStructure(
    routerHelper,
    activeNotifications(notificationsContext),
    {
      isAdmin: auth.user?.isAdmin ?? false,
      isReputationUser: !!localStorage.getItem('reputationToken'),
      isTenantCoverageUser: routerHelper.getIsInsuranceUser(),
    },
  );

  return (
    <Drawer variant='permanent' sx={navigationDrawerStyles}>
      <SafeleaseNavLogo setDrawerOpen={setDrawerOpen} />
      <List>
        {navigationSections.map(
          section =>
            section.showIf !== false && (
              <Fragment key={section.title}>
                {section.title && <NavigationSectionHeader title={section.title} />}
                {section.routes.map(route =>
                  route.children ? (
                    <NavigationAccordion
                      key={route.path}
                      title={route.label}
                      icon={route.icon}
                      showEnabled={route.children.some(child =>
                        location.pathname.includes(child.path),
                      )}
                    >
                      {route.children.map(child => (
                        <NavigationDrawerListItem isChild {...child} key={child.path} />
                      ))}
                    </NavigationAccordion>
                  ) : (
                    <NavigationDrawerListItem {...route} key={route.path} />
                  ),
                )}
              </Fragment>
            ),
        )}
      </List>
      {auth.user?.isAdmin && <AdminModeActiveBox />}
    </Drawer>
  );
}

function MinimizedNavDrawer({ setDrawerOpen }) {
  return (
    <Box sx={{ height: '100vh', bgcolor: '#3781FB' }}>
      <IconButton onClick={() => setDrawerOpen(true)}>
        <Menu fontSize='small' sx={{ color: 'white' }} />
      </IconButton>
    </Box>
  );
}

const navigationDrawerStyles: SxProps = {
  width: 240,
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    width: 240,
    boxSizing: 'border-box',
  },
  '&:hover .chevron-left': {
    visibility: 'visible',
    cursor: 'pointer',
    opacity: 1, // Fully visible on hover
    transition: 'opacity .25s ease, visibility 0s ease 0s', // Fade in over 2 seconds
  },
};
