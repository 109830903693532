import SalesforceDataServices from "../../services/salesforce.service";
import _ from 'lodash';

export const constructSyncDataForOpportunity = async (opLocationIds: string[]) => {
  const existingLocationIds = new Set(); // keep track of salesforce Ids that have already been added to the response
  const existingLlcIds = new Set(); // keep track of salesforce Ids that have already been added to the response
  const syncDataForOpportunity: SyncDataForOpportunity = {
    relationship: null,
    locations: [],
    llcs: [],
    opLocationSyncDataList: [],
    opportunity: null
  };
  for (const id of opLocationIds) {
    try {
      const response = await SalesforceDataServices.getDesiredSyncDataForOpportunityLocation({ opportunityLocationId: id });
      const oppLocDataResult: SyncDataForOpportunityLocation = response.data;
      if (!syncDataForOpportunity.relationship && !_.isEmpty(oppLocDataResult.relationship.newData)) {
        syncDataForOpportunity.relationship = oppLocDataResult.relationship; // set the first relationship data returned as the one to use since every opLocDataResult should contain the same relationship data
      }
      // check the newData field because that will always contain a value if there were location changes to be synced regardless of there being an existing location in the db or not. also want to skip salesforce Ids that have already been set because the same location can be linked in several opLocations
      if (!_.isEmpty(oppLocDataResult.location.newData)) {
        if (existingLocationIds.has(oppLocDataResult.location.salesforceId)) {
          throw "There are multiple Opportunity Locations with the same Location. Cannot perform data sync.";
        }
        syncDataForOpportunity.locations.push(oppLocDataResult.location);
        existingLocationIds.add(oppLocDataResult.location.salesforceId);
      }
      // check the newData field because that will always contain a value if there were location changes to be synced regardless of there being an existing location in the db or not. also want to skip salesforce Ids that have already been set because the same location can be linked in several opLocations
      if (!_.isEmpty(oppLocDataResult.llc.newData) && !existingLlcIds.has(oppLocDataResult.llc.salesforceId)) {
        syncDataForOpportunity.llcs.push(oppLocDataResult.llc);
        existingLlcIds.add(oppLocDataResult.llc.salesforceId);
      }
      if (!syncDataForOpportunity.opportunity && oppLocDataResult.opportunity && !_.isEmpty(oppLocDataResult.opportunity.newData)) {
        // Assuming oppLocDataResult contains Opportunity data and it has newData that needs syncing
        syncDataForOpportunity.opportunity = oppLocDataResult.opportunity;
      }
      
      syncDataForOpportunity.opLocationSyncDataList.push(oppLocDataResult);
    } catch (err) {
      if (typeof err === 'string') throw err
      throw err.response?.data?.error;
    }
  }
  return syncDataForOpportunity;
};

export const checkForSyncChanges = (syncData) => {
  if ((syncData.existingId && !_.isEmpty(syncData.existingData)) || syncData.createNew) {
    return true;
  }
  return false;
};