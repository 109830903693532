import {
  Box,
  Grid,
  Skeleton,
  Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { ReviewStepSKUTransitionRow } from "./ReviewStepSKUTransitionRow";
import { useEffect, useMemo } from "react";
import { calculateMigration, skuConfig } from "./skuConfig";
import { QueryResult, useQuery } from "@apollo/client";
import { GeneralUnit } from "../../../utilities/generated/gql-types";
import { getGeneralUnitsForPlanUpgrades } from "../../../queries";
import { usePlanUpgradeStore } from "../usePlanUpgradeSettingsStore";
import { PlanUpgradeStepWithUnitCount } from "./ReviewStep";

interface SelectImpactedSKUsStepProps {}

/* Review individual SKU movements based on the selected plans */
export function SelectImpactedSKUsStep({}: SelectImpactedSKUsStepProps) {
  const selectedRelationship = usePlanUpgradeStore(
    (state) => state.selectedRelationship
  );
  const selectedLocations = usePlanUpgradeStore(
    (state) => state.selectedLocations
  );

  const premiumBuckets = usePlanUpgradeStore((state) => state.premiumBuckets);

  const setPlanUpgradeSteps = usePlanUpgradeStore((state) => state.setPlanUpgradeSteps);

  const omittedPlanSteps = usePlanUpgradeStore(
    (state) => state.omitPlanUpgradeSteps
  );

  const setNumberOfImpactedUnits = usePlanUpgradeStore(
    (state) => state.setNumberOfImpactedUnits
  );

  const generalUnitsQuery: QueryResult<{ getGeneralUnits: GeneralUnit[] }> =
    useQuery(getGeneralUnitsForPlanUpgrades, {
      variables: {
        relationshipId: selectedRelationship.id,
        occupied: true,
        policyType: "safelease",
      },
    });

  /* General units, filtered to only include the selected locations */
  const generalUnits = useMemo(() => {
    return (
      generalUnitsQuery?.data?.getGeneralUnits?.filter((unit) =>
        selectedLocations
          .map((location) => Number(location.id))
          .includes(Number(unit.locationId))
      ) ?? []
    );
  }, [generalUnitsQuery?.data?.getGeneralUnits, selectedLocations]);

  /* State of the unit coverage/premium before any changes */
  const skuConfigBefore = useMemo(
    () =>
      skuConfig(
        generalUnits,
        premiumBuckets.map((premiumBucket) => ({
          premium: premiumBucket.newPremium,
          coverage: premiumBucket.newCoverage,
        })),
        "before"
      ),
    [generalUnits, premiumBuckets]
  );

  /* State of the unit coverage/premium before any changes */
  const skuConfigAfter = useMemo(
    () =>
      skuConfig(
        generalUnits,
        premiumBuckets.map((premiumBucket) => ({
          premium: premiumBucket.newPremium,
          coverage: premiumBucket.newCoverage,
        })),
        "after"
      ),
    [generalUnits, premiumBuckets]
  );

  /* Get a breakdown of how many units are moving between SKUs */
  const migrations: PlanUpgradeStepWithUnitCount[] = useMemo(
    () =>
      calculateMigration(
        skuConfigBefore,
        premiumBuckets.map((premiumBucket) => ({
          premium: premiumBucket.newPremium,
          coverage: premiumBucket.newCoverage,
        }))
      )
        .filter(
          (migration) =>
            !(
              migration.oldCoverage === migration.newCoverage &&
              migration.oldPremium === migration.newPremium
            )
        )
        .sort((a, b) => a.oldPremium - b.newPremium),
    [skuConfigBefore, skuConfigAfter]
  );

  useEffect(() => {
    const totalNumberOfNonOmittedUnits = migrations
      .filter(
        (migration) =>
          // Filter out any plans that were omitted
          omittedPlanSteps.findIndex(
            (omittedPlan) =>
              omittedPlan.oldCoverage === migration.oldCoverage &&
              omittedPlan.oldPremium === migration.oldPremium &&
              omittedPlan.newCoverage === migration.newCoverage &&
              omittedPlan.newPremium === migration.newPremium
          ) === -1
      )
      .reduce((acc, migration) => {
        return acc + migration.numberAtPremiumBefore;
      }, 0);

    setNumberOfImpactedUnits(totalNumberOfNonOmittedUnits);
  }, [migrations, omittedPlanSteps]);

  useEffect(() => {
    setPlanUpgradeSteps(migrations);
  }, [migrations])

  return (
    <Box>
      <Typography variant="caption" sx={{ color: grey[600] }}>
        Premium/Coverage Movement Breakdown:
      </Typography>
      <Grid container spacing={1}>
        <Grid item xs={1}>
          <Typography fontWeight="bold" variant="caption">
            Include
          </Typography>
        </Grid>
        <Grid item xs={1} textAlign="right">
          <Typography fontWeight="bold" variant="caption">
            Units
          </Typography>
        </Grid>
        <Grid item xs={2} textAlign="right">
          <Typography fontWeight="bold" variant="caption">
            Premium Before
          </Typography>
        </Grid>
        <Grid item xs={2} textAlign="right">
          <Typography fontWeight="bold" variant="caption">
            Coverage Before
          </Typography>
        </Grid>
        <Grid item xs={2} textAlign="right">
          <Typography fontWeight="bold" variant="caption">
            Premium After
          </Typography>
        </Grid>
        <Grid item xs={2} textAlign="right">
          <Typography fontWeight="bold" variant="caption">
            Coverage After
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography fontWeight="bold" variant="caption">
            Impacted Locations
          </Typography>
        </Grid>
        {migrations.length === 0 && !generalUnitsQuery.loading && (
          <Grid item xs={12} textAlign="center">
            <Typography variant="caption">
              No units are moving between SKUs
            </Typography>
          </Grid>
        )}
        {migrations.map((migration, index) => (
          <ReviewStepSKUTransitionRow
            key={index}
            migration={migration}
            skuConfigAfter={skuConfigAfter}
          />
        ))}
        {generalUnitsQuery.loading && (
          <Skeleton variant="rectangular" height={100} sx={{ width: '100%' }} />
        )}
      </Grid>
    </Box>
  );
}
