import React from 'react';
import SetupDataService from '../services/setup.service';
import { QueryResult } from '@apollo/client'
import { AdminLocation } from '../utilities/generated/gql-types'
import { useHistory } from 'react-router-dom'
import { TableActionsDropdown } from './table-actions-dropdown';
import UpdateCredentialsModal from './../update-credentials-modal';

function LocationActions(props: {
  id: number,
  locationsQuery: QueryResult
  location: AdminLocation
}) {
  const [updateCredentialsModalOpen, setUpdateCredentialsModalOpen] = React.useState(false);
  const [selectedLocation, setSelectedLocation] = React.useState({});

  const onOpenUpdateCredentialsModal = (location) => {
    setSelectedLocation(location);
    setUpdateCredentialsModalOpen(true);
  };

  const onCloseUpdateCredentialsModal = async (account) => {
    setUpdateCredentialsModalOpen(false);
    setSelectedLocation({});
  };

  const { id: locationId, location, locationsQuery } = props
  const history = useHistory();
  var menuItems = [];

  if(location.accountId) {
    if(location.deauthenticatedAt) {
      menuItems.push({
        label: 'Update Credentials',
        callback: () => {
          onOpenUpdateCredentialsModal(location);
        }
      });
      
    } else {
      menuItems.push({
        label: 'View Credentials',
        callback: () => {
          history.push(`/admin/tools/credentials/${locationId}`)
        }
      });
    }
  }

  const defaults = [
    {
      label: 'View Facility Information',
      callback: () => {
        history.push(
          `/admin/settings/locations/${location.relationshipId}`);
      }
    },
    {
      label: 'View Billing Information', 
      callback:() => { 
        history.push(
          `/relationships/${location.relationshipId}/onboarding/add_llc?${new URLSearchParams({ locationId: location.id })}`);
      },  
    },
    {
      label: 'View Notes/Changes',
      callback: () => {
        history.push(`/admin/notes/${location.relationshipId}?${new URLSearchParams({ locationId: location.id })}`);
      }
    },
    {
      label: 'View Invoices',
      callback:() => {
        history.push(`/admin/invoices/${location.relationshipId}?${new URLSearchParams({ billingEntityId: location.billingEntityId?.toString() || '' })}`);
      }
    },
  ];

  menuItems.push(...defaults);

  return (
    <>
      <TableActionsDropdown menuItems={menuItems}/>
      {updateCredentialsModalOpen && (
        <UpdateCredentialsModal
          location={location}
          onClose={(updatedAccount) => onCloseUpdateCredentialsModal(updatedAccount)}
        />
      )}
    </>
  )

}

export function locationActionsRenderer(
    id: number, 
    tableInfo: {row: AdminLocation, queryResult: QueryResult}
) {
  return <LocationActions
    id={id}
    locationsQuery={tableInfo.queryResult}
    location={tableInfo.row}
  />
}
