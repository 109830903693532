import { Star } from "@mui/icons-material";
import { Divider, Rating, Stack, Theme } from "@mui/material";
import { SafeLeaseCard, MetricWithChange, MetricGroup } from "@safelease/components";
import { ReputationRatings } from "../pages/PortfolioView/Visualizations";
import { useRef } from "react";

interface RatingsDisplayProps {
  averageRating: number;
  previousRating: number;
  ratings: {
    1: number;
    2: number;
    3: number;
    4: number;
    5: number;
  };
}

export function RatingsDisplay({ averageRating, previousRating, ratings }: RatingsDisplayProps) {
  const ref = useRef();

  return (
    <SafeLeaseCard sx={{ p: 2, height: "100%" }}>
      <Stack direction="row" sx={{ width: "100%" }} id="METRIC-STACK" ref={ref}>
        <MetricWithChange
          label="Average Rating"
          type="count"
          value={averageRating}
          decimalPlaces={1}
          previousValue={previousRating}
          periodSize={7}
          periodType="days"
          formatOptions={{ maximumFractionDigits: 1}}
        >
          <Rating
            size="large"
            value={averageRating}
            readOnly
            precision={0.5}
            emptyIcon={<Star sx={{ color: (theme: Theme) => theme.palette.grey[300] }} fontSize="inherit" />}
          />
        </MetricWithChange>
        <Divider orientation="vertical" sx={{ color: (theme: Theme) => theme.palette.grey.A100, mx: 3 }} flexItem />
        <ReputationRatings ratings={ratings} />
      </Stack>
    </SafeLeaseCard>
  );
}
