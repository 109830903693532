import React from "react";
import { useAuth } from "../auth.js";
import { TextField, Select, MenuItem } from "@mui/material";
import { fmsData, attributeLabels } from "../utilities/fms-data";
import { Grid } from "@mui/material";
import { useQuery, QueryResult } from "@apollo/client";
import { getCubbyOrganizations } from "../queries";
import CircularProgress from "@mui/material/CircularProgress";
import Error from "./Error";
import { muiSelect } from "../styles/mui-overrides";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";

interface FmsLoginFieldsProps {
  accountCredentials?: {
    displayName?: string;
    unsupportedFmsType?: string;
    corpCode?: string;
    url?: string;
    username?: string;
    password?: string;
  };
  accountType: string;
  credentialErrors: object;
  setFmsCredential: (attribute: string, value: string) => void;
}

const FmsLoginFields: React.FC<FmsLoginFieldsProps> = (props) => {
  const { setFmsCredential, credentialErrors, accountType, accountCredentials } = props;
  const auth = useAuth();
  const isAdmin = auth.user.isAdmin;

  const { loading, error, data } = useQuery(getCubbyOrganizations, { skip: accountType != "cubby" });
  if (accountType == "cubby") {
    if (loading)
      return (
        <div style={{ textAlign: "center", width: "100%" }}>
          <CircularProgress />
        </div>
      );
    if (error) return <Error />;
  }

  return (
    <div className="modal-fields fms-login-fields">
      {accountType != "cubby" && (
        <TextField
          className="modal-input-field"
          name="Display Name"
          label={attributeLabels.displayName}
          value={accountCredentials.displayName || ""}
          onChange={(e) => setFmsCredential("displayName", e.target.value)}
        />
      )}

      <Grid container spacing={2} alignItems="center" justifyContent="center" className="sections">
        {accountType &&
          Object.entries(fmsData[accountType].sections).map(([label, attributes], i) => {
            return (
              <Grid item xs={6} className="section" key={i}>
                <div className="section-label">{label}</div>
                {(attributes as string[]).map((attribute) => {
                  if (!isAdmin && accountType == "unsupported" && ["url", "username", "password"].includes(attribute)) return null;

                  if (accountType == "cubby" && attribute == "organizationId") {
                    return (
                      <FormControl key={attribute}>
                        <Select
                          displayEmpty
                          onChange={(e) => setFmsCredential(attribute, e.target.value)}
                          value={accountCredentials[attribute] || ""}
                          sx={muiSelect}
                        >
                          <MenuItem value="">
                            <em>Select an organization</em>
                          </MenuItem>
                          {data.getCubbyOrganizations.map((organization) => {
                            return (
                              <MenuItem key={organization.id} value={organization.id}>
                                {organization.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        <FormHelperText id="my-helper-text">{credentialErrors[attribute]?.msg}</FormHelperText>
                      </FormControl>
                    );
                  } else {
                    return (
                      <TextField
                        key={attribute}
                        className="modal-input-field"
                        name={attribute}
                        type={["password", "directPassword"].includes(attribute) ? "password" : "text"}
                        label={attributeLabels[attribute]}
                        value={accountCredentials[attribute] || ""}
                        onChange={(e) => setFmsCredential(attribute, e.target.value)}
                        helperText={credentialErrors[attribute]?.msg}
                      />
                    );
                  }
                })}
              </Grid>
            );
          })}
      </Grid>
    </div>
  );
};

export default FmsLoginFields;
