import { StateCreator } from "zustand";

export interface PlanUpgradeSettingsButtonsSliceState {
  disableContinue: boolean;
}

export interface PlanUpgradeSettingsButtonsSlice
  extends PlanUpgradeSettingsButtonsSliceState {
  setDisableContinue: (disableContinue: boolean) => void;
}

/* Manage the disabling of any buttons throughout the form */
export const createPlanUpgradeSettingsButtonsSlice: StateCreator<
  PlanUpgradeSettingsButtonsSlice
> = (set) => ({
  disableContinue: true,

  setDisableContinue: (disableContinue) => {
    set({ disableContinue });
  },
});
