// UI - libs
import { Button, SxProps } from "@mui/material";
import { PopupModal } from "react-calendly";
import { ArrowForward } from "@mui/icons-material";

// UI - internal
import { ghostButtonNavy, navyButton } from "../styles/mui-overrides";

// Hooks
import { useAuth } from "../auth";
import { useState } from "react";
import { QueryResult, useQuery } from "@apollo/client";
import { Relationship } from "../utilities/generated/gql-types";
import { getRelationship } from "../queries";

interface CalendlyButtonProps {
  /* link name to use to find the calendly event */
  linkName: string;

  /* relationshipId to use to find the calendly contact */
  relationshipId?: string | number;

  /* variant of the button */
  variant?: "filled" | "outlined";

  /* Grow to fill width of container */
  fullWidth?: boolean;

  /* Label override */
  label?: string;

  /* Hide the end icon */
  hideEndIcon?: boolean;

  /* Additional MUI styles */
  sx?: SxProps
}

/**
 * A button that, when clicked, opens a calendly modal
 */
export function CalendlyButton({
  linkName,
  relationshipId,
  variant,
  fullWidth = false,
  label = "Learn More",
  hideEndIcon = false,
  sx={}
}: CalendlyButtonProps) {
  const auth = useAuth();

  const relationshipQuery: QueryResult<{ getRelationship: Relationship }> =
    useQuery(getRelationship, {
      variables: {
        id: relationshipId,
      },
      skip: !relationshipId,
    });

  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const userCalendlyLinks =
    relationshipQuery?.data?.getRelationship?.am?.calendlyLink ??
    relationshipQuery.data?.getRelationship?.csm?.calendlyLink ??
    {};

  const calendlyLink =
    userCalendlyLinks[linkName] ??
    "https://calendly.com/gaalexander";

  return (
    <>
      <Button
        sx={{
          ...(variant === "filled" ? navyButton : ghostButtonNavy),
          mx: 0,
          minWidth: 200,
          width: fullWidth ? "100%" : "auto",
          fontWeight: "bold",
          px: 2,
          justifyContent: "space-between",
          ...sx,
        }}
        endIcon={!hideEndIcon ? <ArrowForward /> : undefined}
        onClick={() => setModalOpen(true)}
      >
        {label}
      </Button>
      <PopupModal
        url={`${calendlyLink}?hide_landing_page_details=1&hide_gdpr_banner=1&text_color=152744`}
        prefill={{
          name: auth.user?.name,
          email: auth.user?.email,
        }}
        rootElement={document.getElementById("root")}
        onModalClose={() => setModalOpen(false)}
        open={modalOpen}
      />
    </>
  );
}
