import { useEffect, forwardRef } from "react";
import { TableRow, useTableStore } from "../utilities/table-state";
import Loader from "./Loader";
import { TableComponents, TableVirtuoso } from "react-virtuoso";
import { ColumnDef } from "../utilities/column-defs";
import { QueryResult } from "@apollo/client";
import { SxProps } from "@mui/system";
import SortingTableHeadVirtualized from "./sorting-table-head-virtualized";
import SortingTableRowVirtualized from "./sorting-table-row-virtualized";
import {
  Table,
  TableBody,
  TableContainer,
  TableRow as MuiTableRow,
} from "@mui/material";

export enum TableTitles {
  Relationships = "Relationships",
  DocumentsTable = "DocumentsTable",
  LlcsSub = "LLcsSub",
  LocationsByLlcSub = "LocationsByLlcSub",
  LocationsByRelationshipSub = "LocationsByRelationshipSub",
  RelationshipsByClaims = "RelationshipsByClaims",
}

interface MuiTableComponentsParams {
  sx: SxProps;
}

interface Data {}

const MuiTableComponents: (
  params: MuiTableComponentsParams
) => TableComponents<Data> = ({ sx }) => {
  return {
    Scroller: forwardRef<HTMLDivElement>((props, ref) => (
      <TableContainer sx={{ ...sx }} {...props} ref={ref} />
    )),
    Table: (props) => <Table stickyHeader {...props} />,
    // TableHead, //,
    TableBody: forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
    TableRow: ({ item: _item, ...props }) => <MuiTableRow {...props} />,
  };
};

function fixedHeaderContent({ columns, title, cellPadding }) {
  return (
    <SortingTableHeadVirtualized
      columns={columns}
      tableTitle={title}
      cellPadding={cellPadding}
    />
  );
}

const rowContent = ({
  row,
  columns,
  uniqueKey,
  onRowClick,
  queryResult,
  subTableInfo,
  title,
  cellPadding,
  isRowHovered,
  expandedRowId,
  isRowExpanded,
  getSortDirectionByKey,
  activeColumnKeys,
}) => (
  <SortingTableRowVirtualized
    row={row}
    columns={columns}
    isRowHovered={isRowHovered}
    cellPadding={cellPadding}
    expandedRowId={expandedRowId}
    isRowExpanded={isRowExpanded}
    getSortDirectionByKey={getSortDirectionByKey}
    activeColumnKeys={activeColumnKeys}
    queryResult={queryResult}
    onRowClick={onRowClick}
  />
);

interface SortingTableVirtualizedProps {
  title: string;
  columns: ColumnDef[];
  queryResultData: any[];
  queryResult?: QueryResult; // This is only necessary if a row click can trigger a refetch, as is the case in the match claims table
  uniqueKey?: string | number; // unique column key used by React to iterate over components. Defaults to "id," but in cases where id is identical across rows (ie in the claim matching drawer), we can pass a different unique identifier
  onRowClick?: (row: any) => (event: KeyboardEvent | MouseEvent) => void;
  modifyRows?: (rows: TableRow[]) => TableRow[];
  totalsRowRenderer?: (props: { tableTitle: string }) => JSX.Element;
  subTableInfo?: {
    component: (props: any) => JSX.Element;
    queryResult: QueryResult;
  };
  isSubTable?: boolean;
  defaultSorter?: (row1: TableRow, row2: TableRow) => number;
  cellPadding?: string;
}

function SortingTableVirtualized({
  title,
  columns,
  onRowClick,
  queryResultData,
  queryResult,
  uniqueKey,
  modifyRows,
  totalsRowRenderer,
  subTableInfo,
  isSubTable,
  defaultSorter,
  cellPadding,
}: SortingTableVirtualizedProps) {
  const { rows, setRows, sortRows } = useTableStore(
    title,
    columns
  )((state) => ({
    rows: state.rows,
    setRows: state.setRows,
    sortRows: state.sortRows,
  }));

  const {
    changeExpandedRow,
    expandedRowId,
    isRowExpanded,
    getSortDirectionByKey,
    activeColumnKeys,
  } = useTableStore(
    title,
    columns
  )((state) => ({
    isRowExpanded: state.isRowExpanded,
    expandedRowId: state.expandedRowId,
    changeExpandedRow: state.changeExpandedRow,
    getSortDirectionByKey: state.getSortDirectionByKey,
    activeColumnKeys: state.activeColumnKeys,
  }));

  useEffect(() => {
    if (!modifyRows) {
      setRows(queryResultData || []);
      sortRows(defaultSorter);
    } else {
      setRows(modifyRows(queryResultData || []));
      sortRows(defaultSorter);
    }
  }, [JSON.stringify(queryResultData)]);

  if (!queryResultData) return <Loader />;

  return (
    <>
      <TableVirtuoso
        data={rows}
        components={MuiTableComponents({ sx: {}, columns, title, cellPadding })}
        fixedHeaderContent={() =>
          fixedHeaderContent({ columns, title, cellPadding })
        }
        itemContent={(_index, row) =>
          rowContent({
            row,
            columns,
            uniqueKey,
            onRowClick,
            queryResult,
            subTableInfo,
            title,
            cellPadding,
            isRowHovered: false,
            expandedRowId,
            isRowExpanded,
            getSortDirectionByKey,
            activeColumnKeys,
          })
        }
      />
      {totalsRowRenderer && totalsRowRenderer({ tableTitle: title })}
    </>
  );
}

export default SortingTableVirtualized;
