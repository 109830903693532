import { CheckCircle } from "@mui/icons-material";
import { DialogContent, Stack, Theme, Typography, alpha } from "@mui/material";
import { SafeLeaseButton } from "@safelease/components";
import { usePerformanceBoard } from "../usePerformanceBoard";

export function QRCodeDeliveredMessage() {
  const { setConfigDialogOpen } = usePerformanceBoard();

  return (
    <>
      <DialogContent>
        <Stack direction="column" alignItems="center" spacing={2}>
          <CheckCircle
            sx={{
              color: "green.main",
              fontSize: 80,
              borderRadius: 10,
              boxShadow: (theme: Theme) => `0px 0px 0px 10px ${alpha(theme.palette.green.main, 0.3)} inset`,
            }}
          />
          <Typography>Unique QR Codes Sent to selected Employees!</Typography>
          <SafeLeaseButton
            variant="contained"
            color="navy"
            onClick={() => {
              console.log(":Clicked")
              setConfigDialogOpen(false);
            }}
          >
            Go to Dashboard
          </SafeLeaseButton>
        </Stack>
      </DialogContent>
    </>
  );
}
