// UI - libs
import {
  Checkbox,
  FormControl,
  ListItemText,
  MenuItem,
  Select,
} from "@mui/material";
import { muiMenu, muiMenuItem, muiSelect } from "../styles/mui-overrides";

// Data
import { QueryResult, useQuery } from "@apollo/client";
import { getLocations } from "../queries";
import { Location } from "../utilities/generated/gql-types";

// Hooks & State
import { useAuth } from "../auth";

type ValidLocationId = string | string[] | number | number[] | null;

interface LocationSelectorProps {
  locationId: ValidLocationId;
  setLocationId: (locationId: ValidLocationId) => void;
  relationshipId: ValidLocationId;
  multiple?: boolean; // should be multiple with checkboxes
  width?: string | null;
}

export default function LocationSelector({
  locationId,
  setLocationId,
  relationshipId,
  multiple = false,
  width = null,
}: LocationSelectorProps) {
  const {
    user: { isAdmin },
  } = useAuth();

  const locationsQuery: QueryResult<{ getLocations: Location[] }> = useQuery(
    getLocations,
    { variables: { relationshipId }, skip: !relationshipId }
  );

  const locations = locationsQuery.data?.getLocations || [];

  return (
    <FormControl sx={{ minWidth: 120, width: width }}>
      <Select
        value={locationId || ""}
        displayEmpty
        multiple={multiple}
        onChange={(e) => setLocationId(e.target.value)}
        renderValue={(selected) => {
          if (multiple) {
            const selectedLocations = selected as number[];
            if (selectedLocations.length === 0) {
              return "All Locations";
            } else if (selectedLocations.length === 1) {
              return (
                locations.find((l) => Number(l.id) === selected[0])?.name || ""
              );
            } else {
              return `${selectedLocations.length} locations selected`;
            }
          }
          return locations.find((l) => Number(l.id) === selected)?.name || "All Locations";
        }}
        sx={{
          width: width,
          ...muiSelect,
          marginLeft: 0,
          padding: "0px",
          height: 36,
          duration: "250ms",
          "& fieldset": {
            // override base border (no pseudo classes)
            border: "1px solid #EBEFF7",
            duration: "250ms",
          },
          "&:hover fieldset": {
            border: "1px solid rgba(0,0,0,0.23) !important", // Hovered pseudo class
            duration: "250ms !important",
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: "1px solid #2879FB !important", // Focused pseudo class (menu is open)
          },
        }}
      >
        <MenuItem value={""}>All Locations</MenuItem>
        {locationsQuery.data?.getLocations
          ?.filter((l) => l.liveDate)
          .map((location) => (
            <MenuItem
              key={location.id}
              value={Number(location.id)}
              sx={{ muiMenuItem, pl: 0 }}
            >
              {multiple && (
                <Checkbox
                  checked={
                    (locationId as number[]).indexOf(Number(location.id)) > -1
                  }
                />
              )}
              <ListItemText
                primary={
                  isAdmin
                    ? location.name || location.fullAddress
                    : location.fullAddress
                }
              />
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
}
