// UI - libs
import { InfoOutlined } from "@mui/icons-material";
import { Box, Card, Chip, Color, IconButton, Stack, Tooltip, TooltipProps, Typography, styled, tooltipClasses } from "@mui/material";
import { green, grey, red } from "@mui/material/colors";

interface MetricHighlightCardProps {

  /** Label for the card, in the upper left corner */
  label: string;
  secondaryText?: string;

  /** Label for a chip in the upper right corner
   * if not passed, no chip will be rendered
   */
  chipLabel?: string;

  /* Specify when the value is a string or react element */
  nonNumeric?: boolean;

  value: number | any;
  valueFormatter?: (value: number) => string | number;
  action?: React.ReactNode;
  variant: 'success' | 'error' | 'info' | 'neutral';

  /* Defaults the card to being taller to accomodate for larger actions */
  tall?: boolean;

  /* If empty, don't display an info tooltip */
  tooltipText?: string | React.ReactElement;
}

// MetricHighlightCardProps renders a card displaying a metric. The card includes a value,
// tinted background, an accessory chip, and optionally a CTA.
export default function MetricHighlightCard({
  label,
  secondaryText,
  chipLabel,
  nonNumeric = false,
  value,
  valueFormatter = (value) => value,
  action,
  variant,
  tall=false,
  tooltipText
}: MetricHighlightCardProps) {

  /** Change color based on the variant of the card */
  const getSecondaryColor = () => {
    if (variant === "success") return green[50];
    if (variant === "error") return red[50];
    if (variant === "info") return "rgba(39, 121, 251, 0.1)";
    return grey[100];
  }

  const getPrimaryColor = () => {
    if (variant === "success") return green[500];
    if (variant === "error") return red[500];
    if (variant === "info") return "#152744";
    return grey[500];
  }

  return (
    <Card
      elevation={0}
      sx={{
        bgcolor: getSecondaryColor(),
        p: 2,
        height: '100%',
        boxShadow: '0 0 20px rgba(0, 0, 0, 0.05)',
      }}
    >
      <Stack direction="column" justifyContent="space-between" sx={{ height: '100%' }}> 
        <Stack direction="row" alignItems="top" justifyContent="space-between">
          <Box>
            <Stack direction="row" justifyContent="flex-start" alignItems="center">
              <Typography
                sx={{
                  color: "rgba(21, 39, 68, 1)",
                  fontWeight: "bold",
                }}
              >
                {label}
              </Typography>
              {tooltipText && (
                <CustomWidthMetricHighlightCardTooltip title={tooltipText} sx={{ ml: 2 }}>
                  <IconButton size="small">
                    <InfoOutlined sx={{ height: 20, width: 20, color: 'rgba(146, 146, 146, 1)' }} />
                  </IconButton>
                </CustomWidthMetricHighlightCardTooltip>
              )}
            </Stack>
            {secondaryText && (
              <Typography
                sx={{
                  color: "rgba(100,100,100, 0.7)",
                  fontSize: '14px'
                }}
              >
                {secondaryText}
              </Typography>
            )}
          </Box>
          {chipLabel && <Chip label={chipLabel} sx={{ bgcolor: getPrimaryColor(), color: "white", fontWeight: "bold" }}  size="small" />}
        </Stack>
        {nonNumeric && value}
        {!nonNumeric && (
          <Typography variant="h3" sx={{ color: getPrimaryColor(), my: 2 }}>
            {value !== undefined ? valueFormatter(value) : "-"}
          </Typography>
        )}
        <Stack direction="column" justifyContent="flex-end" alignItems="center" sx={{ height: tall ? 60 : 36 }}>
          <Box sx={{ width: '100%' }}>
            {action}
          </Box>
        </Stack>
      </Stack>
    </Card>
  );
}

/* Enable the tooltip */
const CustomWidthMetricHighlightCardTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 600,
  },
});
