import { QueryClient } from "@tanstack/react-query";

// Client for all non-graphQL queries
export const reactQueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // When true, every time you switch tabs it refetches API calls; we want to disable this behavior
      refetchOnWindowFocus: false,
    },
  },
});
