import { Box, Dialog, Divider, Typography } from "@mui/material";
import { ScheduleUpgradeForm } from "./ScheduleUpgradeForm";

interface ScheduleUpgradeFormDialog {
  open: boolean;
  handleClose: () => void;
}

export function ScheduleUpgradeFormDialog({
  open,
  handleClose,
}: ScheduleUpgradeFormDialog) {
  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <Box sx={{ p: 4 }}>
        <Typography variant="h5">Schedule New Plan Upgrade Job</Typography>
        <Divider sx={{ my: 4 }} />
        <ScheduleUpgradeForm handleClose={handleClose}/>
      </Box>
    </Dialog>
  );
}
