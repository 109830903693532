import { QueryResult } from "@apollo/client";
import PriorityIcon from "../../shared/PriorityIcon";
import { TableRow } from "../../utilities/table-state";
import { editableField } from "../../column-renderers/common-renderers";
import { GridColDef, GridRowParams } from "@mui/x-data-grid-premium";
import AdminDataService from "../../services/admin.service";
import getJobErrorActions from "./JobErrorActions";
import dayjs from "dayjs";

export const liveProductionJobReportColumnDefs: GridColDef[] = [
  {
    field: "relationshipName",
    headerName: "Relationship Name",
    width: 200,
  },
  {
    field: "locationAddress",
    headerName: "Location",
    width: 200,
  },
  {
    field: "failedAt",
    headerName: "Failed At",
    width: 200,
    // Sat Nov 11 2023 12:00:00 AM format
    renderCell: ({ value }) => dayjs(value).format("MMM DD YYYY hh:mm A"),
  },
  {
    field: "method",
    headerName: "Method",
    width: 240,
  },
  {
    field: "triagingStatus",
    headerName: "Triaging Status",
    width: 120,
    align: "center",
    renderCell: ({ value }) => <PriorityIcon priority={value} />,
  },
  {
    field: "error",
    headerName: "Error",
    flex: 1,
    minWidth: 350,
    renderCell: (value) => (
      <div className=" tw-w-full tw-break-words">
        {value?.value?.slice(0, 100)}
        {value?.value?.length > 100 ? "..." : ""}
      </div>
    ),
  },
  {
    field: "errorNotes",
    headerName: "Error Notes",
    width: 400,
    renderCell: (value) => (
      <div className="tw-w-full tw-break-words">{value.value}</div>
    ),
  },
  {
    field: "csmNotes",
    headerName: "CSM Notes",
    width: 400,
    renderCell: (value) =>
      editableField(
        value.value,
        { row: value.row, queryResult: value.row.queryResult },
        {
          callback: async (
            newValue: string,
            tableInfo: { row: TableRow; queryResult: QueryResult },
          ) => {
            await AdminDataService.updateJob({
              csmNotes: newValue,
              jobId: tableInfo.row.id,
            });
            await tableInfo.queryResult.refetch();
          },
          nonAdminsCannotEdit: true,
        },
      ),
  },
  {
    field: "csmResolutionSteps",
    headerName: "CSM Resolution Steps",
    minWidth: 400,
    renderCell: (value) => (
      <div className="tw-w-[20rem] tw-break-words">{value.value}</div>
    ),
  },
  {
    field: "id",
    headerName: "Actions",
    headerAlign: "center",
    renderHeader: () => null,
    align: "center",
    type: "actions",
    width: 50,
    getActions: (params: GridRowParams) =>
      getJobErrorActions({
        jobId: params.id,
        jobError: params.row,
        jobErrorQuery: params.row.queryResult,
        history: params.row.history,
      }),
    // renderCell: (value) => JobErrorActionsRenderer(value.value, { row: value.row, queryResult: value.row.queryResult }),
  },
];
