import {
  EventBusy,
  RadioButtonChecked,
  RadioButtonUnchecked,
} from "@mui/icons-material";
import {
  Box,
  IconButton,
  Input,
  Stack,
  Theme,
  Typography,
} from "@mui/material";
import { blue, red } from "@mui/material/colors";
import { JSXElementConstructor, ReactElement, cloneElement } from "react";
import { FieldValueSource, SectionType } from "./useComplianceCenter";
import { useFormContext } from "react-hook-form";
import dayjs, { Dayjs } from "dayjs";
import { DatePicker } from "@mui/x-date-pickers-pro";

interface PrivatePolicyFieldButtonProps {
  fieldValue: string;
  fieldTitle: string;
  fieldSource: FieldValueSource;
  sectionId: SectionType;
  icon: ReactElement<any, string | JSXElementConstructor<any>>;
  textfield?: boolean;
}

/* A button that allows a user to select a value from a specific source a private policy field */
/* The source may be manual input, AI-extracted or manual overridden by an internal user */
export function PrivatePolicyFieldButton({
  fieldValue,
  fieldTitle,
  fieldSource,
  sectionId,
  icon,
  textfield,
}: PrivatePolicyFieldButtonProps) {
  const { register, setValue, watch } = useFormContext();

  /* If this option is selected in the section */
  const isSelected = watch(`${sectionId}.fieldSource`) === fieldSource;

  /* Update the date when overriding manually */
  const handleDateChange = (date: Dayjs) => {
    setValue(`${sectionId}.fieldValue`, date.format("MM-DD-YYYY"));
    setValue(`${sectionId}.fieldSource`, "manualOverride");
    setValue(`${sectionId}.manualOverrideValue`, date.format("MM-DD-YYYY"));
    setValue(`${sectionId}.invalid`, false);
  };

  // Policy has already expired
  const isInPast =
    sectionId === SectionType.EXPIRATION_DATE &&
    dayjs(fieldValue).isBefore(dayjs());

  // Policy is too far in the future
  const isGreaterThanTwoYearsInFuture =
    sectionId === SectionType.EXPIRATION_DATE &&
    dayjs(fieldValue).isAfter(dayjs().add(2, "year"));

  return (
    <Box
      sx={{
        flexGrow: 1,
        border: "1px solid",
        p: 0.5,
        borderRadius: 2,
        cursor: "pointer",
        transition: (theme) =>
          theme.transitions.create(["border-color", "box-shadow"]),
        transitionDuration: "0.15s",
        borderColor: (theme: Theme) =>
          isSelected ? "#80bdff" : theme.palette.divider,
        boxShadow: isSelected
          ? "0 0 0 0.2rem rgba(0,123,255,.25)"
          : "0 0 20px rgba(0,0,0,0.05)",
      }}
      onClick={() => {
        setValue(`${sectionId}.fieldSource`, fieldSource);
        setValue(`${sectionId}.fieldValue`, fieldValue);
        setValue(`${sectionId}.invalid`, false);
      }}
    >
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        justifyContent="space-between"
      >
        <IconButton sx={{ p: 0, position: "relative", height: 24, width: 24 }}>
          <RadioButtonUnchecked
            sx={{
              opacity: isSelected ? 0 : 1,
              transition: (theme) => theme.transitions.create("opacity"),
              transitionDuration: "0.15s",
              color: (theme: Theme) => theme.palette.grey[300],
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          />
          <RadioButtonChecked
            sx={{
              opacity: isSelected ? 1 : 0,
              transition: (theme) => theme.transitions.create("opacity"),
              transitionDuration: "0.15s",
              color: blue[500],
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          />
        </IconButton>
        {!textfield && (
          <Typography variant="body2" sx={{ flex: 1 }}>
            {fieldValue}
          </Typography>
        )}
        {textfield && sectionId === SectionType.EXPIRATION_DATE && (
          <DatePicker
            slotProps={{
              textField: {
                sx: {
                  border: "none",
                  "& fieldset": {
                    border: "none", // Removes border from date picker
                  },
                  "& .MuiInputAdornment-root": {
                    ml: 0,
                  },
                },
                inputProps: {
                  style: {
                    padding: 0,
                    border: "none",
                    borderBottom: "1px solid",
                    width: 105,
                    fontSize: "14px",
                  },
                },
              },
            }}
            value={watch(`${sectionId}.manualOverrideValue`)}
            onChange={handleDateChange}
          />
        )}
        {textfield && sectionId !== SectionType.EXPIRATION_DATE && (
          <Input
            sx={{ flex: 1 }}
            {...register(`${sectionId}.manualOverrideValue`)}
          />
        )}
        {sectionId == SectionType.EXPIRATION_DATE ? (
          isInPast || isGreaterThanTwoYearsInFuture ? (
            <Box
              sx={{
                borderRadius: 2,
                color: (theme: Theme) => {
                  if (isInPast || isGreaterThanTwoYearsInFuture) {
                    return red[500];
                  }
                  return theme.palette.grey[600];
                },
                py: 0.25,
                display: "flex",
                alignItems: "center",
              }}
            >
              <EventBusy sx={{ mr: 0.5 }} />
              <Typography variant="body2">
                {isInPast && "In Past"}
                {isGreaterThanTwoYearsInFuture && ">2yr ahead"}
              </Typography>
            </Box>
          ) : (
            <Box sx={{ flex: 1 }} />
          )
        ) : null}
        <Box
          sx={{
            borderRadius: 2,
            border: "1px solid",
            borderColor: (theme: Theme) => theme.palette.divider,
            px: 1,
            py: 0.25,
            display: "flex",
            alignItems: "center",
          }}
        >
          {cloneElement(icon, {
            sx: {
              mr: 1,
              height: 20,
              width: "auto",
              color: (theme: Theme) =>
                isSelected ? blue[500] : theme.palette.grey[600],
              transition: (theme) => theme.transitions.create("color"),
              transitionDuration: "0.15s",
            },
          })}
          <Typography variant="body2">{fieldTitle}</Typography>
        </Box>
      </Stack>
    </Box>
  );
}
