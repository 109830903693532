import { Box, IconButton, Modal, Paper, Stack, Theme } from "@mui/material";
import { PDFViewer } from "../../shared/PDFViewer/PDFViewer";
import { Close } from "@mui/icons-material";

interface ExpandedPDFModalProps {
  file: string;
  open: boolean;
  handleClose: () => void;
}

export function ExpandedPDFModal({
  file,
  open,
  handleClose=() => null,
}: ExpandedPDFModalProps) {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <Paper
        elevation={0}
        sx={{
          p: 2,
          width: 1200,
          height: "91vh",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Stack
          direction="row"
          justifyContent="flex-end"
          sx={{ mb: 2, width: "100%" }}
        >
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </Stack>
        <Box
          sx={{
            border: "1px solid",
            borderRadius: 2,
            borderColor: (theme: Theme) => theme.palette.divider,
            overflow: "hidden",
            flex: 1,
            width: "100%",
          }}
        >
          <PDFViewer file={file} handleClose={handleClose} expandedView />
        </Box>
      </Paper>
    </Modal>
  );
}
