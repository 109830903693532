import axios from "axios";
import * as Sentry from "@sentry/react";


const instance = axios.create({
  baseURL: "/api",
  headers: {
    "Content-type": "application/json",
    "X-SafeLease-Build": process.env.REACT_APP_BUILD || '',
  },
});

instance.interceptors.request.use(function (config) {
  const user = JSON.parse(localStorage.getItem('user'));

  var headers = config.headers.common;
  if (user && user.accessToken) {
    headers['Authorization'] = "Bearer " + user.accessToken;
  }
  
  var otpToken = localStorage.getItem('otpToken');
  if(otpToken) headers['X-OTP-Authorization'] = otpToken;

  config.headers.common = headers;

  return config;
}, function (error) {
  return Promise.reject(error);
});

instance.interceptors.response.use(function (response) {
  if(response.status == 200 && response?.config?.url === '/admin/otp/ping' && response?.data?.error === 'No credentials' && localStorage.getItem('otpToken')) {
    localStorage.removeItem('otpToken');
    location.reload();
  }
  return response;
}, function(error) {
  if(error.response?.status == 502) {
    Sentry.captureException(error);
    Sentry.flush();
  }

  return Promise.reject(error);
});

  
export default instance;
