import { Stack, Typography } from "@mui/material";
import { GoogleReviewCard } from "./GoogleReviewCard";
import { Review } from "@safelease/service-utilities";

interface TenantReviewsProps {
  reviews: Array<Review>;
}

export function TenantReviews({ reviews = [] }: TenantReviewsProps) {
  if (!reviews || reviews.length === 0) {
    return (
      <Typography variant="body1" color="grey.A100">
        No reviews to respond to. You're all caught up!
      </Typography>
    );
  }

  return (
    <Stack direction="column" spacing={1}>
      {reviews.map((review) => (
        <GoogleReviewCard review={review} key={review.id} />
      ))}
    </Stack>
  );
}
