import { useEffect } from 'react';

// TODO remove useEffect for css application 1 by 1 and replace with this component
function SafeleaseWrapper({ children }) {
  useEffect(() => {
    document.body.classList.add('dashboard');
    return () => { document.body.classList.remove('dashboard')}
  }, []);
  return <>{children}</>
}

export default SafeleaseWrapper;
