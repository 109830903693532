import React, { Children, PropsWithChildren } from "react";
import { SxProps, Tabs, TabsProps } from "@mui/material";
import RouterHelper from "../utilities/router-helper";

interface SafeleaseTabsProps extends TabsProps {
  routerHelper?: RouterHelper;
}

// Extension of MUI's tab component with our own styles
export default function SafeleaseTabs({
  sx = {},
  TabIndicatorProps = {},
  children,
  routerHelper,
  ...props
}: PropsWithChildren<SafeleaseTabsProps>) {
  
  const featureFlags = routerHelper?.getFeatureFlags() || [];

  // Filter out children that are access controlled
  // This is to help the <Tabs /> component correctly
  // identify the number of children to render
  // This is necessary because MUI assigns each child of <Tabs /> an index
  // under the hood that we can't control, so we have to only pass the number of tabs
  // that we want to render
  const filteredChildren = Children.toArray(children).filter((child) => {
    if (React.isValidElement(child)) {
      if (
        typeof child.type !== "string" &&
        child.type?.name === "AccessControlled" &&
        child?.props?.by === "feature" &&
        !featureFlags.includes(child?.props?.featureFlag)
      )
        return false;
    }
    return true;
  });

  return (
    <Tabs
      {...props}
      TabIndicatorProps={{
        sx: {
          backgroundColor: "#2879FB",
        },
        ...TabIndicatorProps,
      }}
      sx={{
        borderBottom: "1px solid rgba(233,233,233,1)",
        ...sx,
      }}
    >
      {filteredChildren}
    </Tabs>
  );
}
