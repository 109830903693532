// UI - libs
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Stack,
  TextField,
} from "@mui/material";
import {
  CheckBox as CheckboxIcon,
  CheckBoxOutlineBlank,
} from "@mui/icons-material";

// UI - internal

// Hooks
import { useEffect } from "react";
import { useQuery } from "@apollo/client";

// Data
import { getLocations } from "../../../queries";
import { usePlanUpgradeStore } from "../usePlanUpgradeSettingsStore";

// Utils
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";

const icon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckboxIcon fontSize="small" />;

interface SelectLocationsStepProps {}

export function SelectLocationsStep({}: SelectLocationsStepProps) {
  /* Form state */
  const selectedRelationship = usePlanUpgradeStore(
    (state) => state.selectedRelationship
  );
  const selectedLocations = usePlanUpgradeStore(
    (state) => state.selectedLocations
  );
  const setSelectedLocations = usePlanUpgradeStore(
    (state) => state.setSelectedLocations
  );
  const setDisableContinue = usePlanUpgradeStore(
    (state) => state.setDisableContinue
  );
  const addPremiumBucket = usePlanUpgradeStore(
    (state) => state.addPremiumBucket
  );
  const premiumBuckets = usePlanUpgradeStore((state) => state.premiumBuckets);
  const setPremiumBuckets = usePlanUpgradeStore(
    (state) => state.setPremiumBuckets
  );
  const setDefaultPremiumBuckets = usePlanUpgradeStore(
    (state) => state.setDefaultPremiumBuckets
  );

  /* Get all locations for the selected relationship */
  const locationsQuery = useQuery(getLocations, {
    variables: { relationshipId: selectedRelationship.id },
  });

  const locations = locationsQuery.data?.getLocations ?? [];

  const protectionPlansAreUniform =
    configOnlyContainsPlansWithSameProtectionLevelValues(selectedLocations);

  /* Disable the continue button if the plans aren't uniform */
  useEffect(() => {
    if (!protectionPlansAreUniform) {
      setDisableContinue(true);
    } else {
      setDisableContinue(false);
    }
  }, [protectionPlansAreUniform]);

  useEffect(() => {
    if (selectedLocations.length === 0) {
      /* If no locations are selected, set premium buckets to empty */
      setPremiumBuckets([]);
    } else {
      if (premiumBuckets.length === 0) {
        const firstLocation = selectedLocations[0];

        for (const premiumPlan of firstLocation.protectionLevelValues) {
          addPremiumBucket({
            oldCoverage: premiumPlan.coverage,
            newCoverage: premiumPlan.coverage,
            oldPremium: premiumPlan.premium,
            newPremium: premiumPlan.premium,
            removeAfterComplete: false,
          });

          /* Keep a record of the old buckets initial state */
          setDefaultPremiumBuckets(
            firstLocation.protectionLevelValues.map((premiumPlan) => ({
              id: uuidv4(),
              oldCoverage: premiumPlan.coverage,
              newCoverage: premiumPlan.coverage,
              oldPremium: premiumPlan.premium,
              newPremium: premiumPlan.premium,
              removeAfterComplete: false,
            }))
          );
        }
      }
    }
  }, [selectedLocations]);

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ mb: 1 }}
      >
        <Box>
          {!protectionPlansAreUniform && (
            <Alert severity="error">
              Selected locations contain plans that aren't shared among all
              selected locations.
            </Alert>
          )}
        </Box>
        <Button
          sx={{ float: "right", textTransform: "none" }}
          onClick={() => {
            if (selectedLocations.length === locations.length) {
              setSelectedLocations([]);
            } else {
              setSelectedLocations(locations);
            }
          }}
        >
          {selectedLocations.length === locations.length
            ? "Deselect all"
            : "Select all"}
        </Button>
      </Stack>
      <Autocomplete
        disabled={locationsQuery.loading}
        multiple
        limitTags={8}
        disableCloseOnSelect
        value={selectedLocations}
        onChange={(_, newValue) => setSelectedLocations(newValue)}
        options={locations}
        getOptionLabel={(option) => option.address}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.address}
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Locations"
            variant="outlined"
            inputProps={{
              ...params.inputProps,
            }}
          />
        )}
      />
    </>
  );
}

/* If the selected locations contains plans that aren't perfectly overlapping with other selected locations,
  /* use this to up an alert in the UI
  /* 
  /* for example:
  /* selected location 1: { premium: 1, coverage: 1 }, { premium: 2, coverage: 2 }, { premium: 3, coverage: 3 }
  /* selected location 2: { premium: 1, coverage: 1 }, { premium: 2, coverage: 2 }, { premium: 3, coverage: 4 },
  /* the 3rd (3, 4) plan doesn't perfectly overlap with the 3rd plan of the first location (3, 3), so we'd return false */
const configOnlyContainsPlansWithSameProtectionLevelValues = (
  selectedLocations
) => {
  if (selectedLocations.length === 0) return true;

  /* Get all the unique plans across all locations */
  const uniquePlansAcrossAllLocations = [];
  for (const location of selectedLocations) {
    for (const plan of location.protectionLevelValues) {
      const hasPlan =
        uniquePlansAcrossAllLocations.findIndex(
          (p) => p.premium === plan.premium && p.coverage === plan.coverage
        ) !== -1;

      if (!hasPlan) {
        uniquePlansAcrossAllLocations.push(plan);
      }
    }
  }

  /* If the set of selected locatiosn has a different set of plans than the first selected location, return true */
  return _.isEqual(
    selectedLocations[0]?.protectionLevelValues,
    uniquePlansAcrossAllLocations
  );
};
