import {
  Box,
  Chip,
  FormControl,
  Grid,
  IconButton,
  ListItem,
  Typography,
} from "@mui/material";
import { Formatters } from "../../../utilities/formatters";
import { usePlanUpgradeStore } from "../usePlanUpgradeSettingsStore";
import { blue, green } from "@mui/material/colors";
import { Remove } from "@mui/icons-material";
import { PlanUpgradeStepWithId } from "../usePlanUpgradeSettingsStore/usePlanUpgradeSettingsPlansSlice";
import { CurrencyTextfield } from "../../../components/lib/CurrencyTextfield";

interface PlanEditingRowProps {
  premiumBucket: PlanUpgradeStepWithId;
  defaultPremiumBuckets: PlanUpgradeStep[];
}

export function PlanEditingRow({ premiumBucket }: PlanEditingRowProps) {
  /* If the current setup of this bucket is an existing bucket */
  const existingBucket =
    !!premiumBucket.oldCoverage || !!premiumBucket.oldPremium;

  const handleAdjustPremiumBucket = usePlanUpgradeStore(
    (state) => state.adjustPremiumBucket
  );

  const handleRemovePremiumBucket = usePlanUpgradeStore(
    (state) => state.removePremiumBucket
  );

  return (
    <ListItem
      sx={{
        border: "1px solid #EBEFF7",
        boxShadow: "0 0 20px rgba(0,0,0,0.05)",
        p: 1,
        my: 0.5,
        borderRadius: 2,
      }}
    >
      <Grid container spacing={1}>
        <Grid item xs={2} sx={{ display: "flex", alignItems: "center" }}>
          {existingBucket ? (
            <Chip
              label="Current"
              sx={{
                height: 20,
                bgcolor: blue[500],
                color: "white",
                borderRadius: 1,
              }}
            />
          ) : (
            <Chip
              label="New"
              sx={{
                height: 20,
                bgcolor: green[500],
                color: "white",
                borderRadius: 1,
              }}
            />
          )}
        </Grid>
        <Grid item xs={2} sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="body2" sx={{ flexGrow: 1, textAlign: "right" }}>
            {!existingBucket && (
              <FormControl size="small">
                <CurrencyTextfield
                  defaultValue={premiumBucket.newCoverage / 100}
                  changeCallback={(value) => {
                    handleAdjustPremiumBucket({
                      id: premiumBucket.id,
                      coverage: Number(value) * 100,
                      premium: premiumBucket.newPremium,
                    });
                  }}
                />
              </FormControl>
            )}
            {existingBucket &&
              Formatters.formatDollarSign(premiumBucket.oldCoverage / 100)}
          </Typography>
        </Grid>
        <Grid item xs={3} sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="body2" sx={{ flexGrow: 1, textAlign: "right" }}>
            {existingBucket &&
              Formatters.formatDollarSign(premiumBucket.oldPremium / 100)}
          </Typography>
        </Grid>
        <Grid item xs={3} sx={{ flexGrow: 1, textAlign: "right" }}>
          <FormControl size="small" sx={{ pl: 6 }}>
            <CurrencyTextfield
              defaultValue={premiumBucket.newPremium / 100}
              changeCallback={(value) => {
                handleAdjustPremiumBucket({
                  id: premiumBucket.id,
                  coverage: premiumBucket.newCoverage,
                  premium: Number(value) * 100,
                });
              }}
            />
          </FormControl>
        </Grid>
        <Grid
          item
          xs={2}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <IconButton
            size="small"
            onClick={() => handleRemovePremiumBucket(premiumBucket.id)}
          >
            <Remove sx={{ height: 20, width: "auto" }} />
          </IconButton>
        </Grid>
      </Grid>
    </ListItem>
  );
}
