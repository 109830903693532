import {
  DatePickerProps,
  DatePicker as MuiDatePicker,
} from "@mui/x-date-pickers-pro";
import dayjs, { Dayjs } from "dayjs";

type DateTypes = Dayjs | Date | string | null;

interface SafeLeaseDatePickerProps<T extends DateTypes> extends DatePickerProps<T> {
  value: T;
  onChange: (date: T) => void;
  minDate?: T;
}

function SafeLeaseDatePicker({
  value,
  onChange,
  minDate,
  ...other
}: SafeLeaseDatePickerProps<DateTypes>) {

  const onChangeWrapper = function (date) {
    // If the user has chosen a valid date option, we want to make it a normal JS date object.  Otherwise, we need to pass the MUI object so it can track incomplete data.
    if (date) {
      var parsed = new Date(date);
      if (parsed.toString() != "Invalid Date") date = parsed;
    }

    onChange(date);
  };

  return (
    <MuiDatePicker
      {...other}
      value={dayjs(value)}
      onChange={onChangeWrapper}
      minDate={minDate}
    />
  );
}

export default SafeLeaseDatePicker;
