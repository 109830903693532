// UI - libs
import {
  Box,
  Divider,
  Drawer,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { Close as CloseIcon, Key } from "@mui/icons-material";

// UI - internal
import PriorityIcon from "../../../shared/PriorityIcon";

// Hooks & State
import { useJobsErrorCenter } from "../useJobsErrorCenter";

// Data
import JobsService from "../../../services/jobs.service";

// Libs
import dayjs from "dayjs";
import _ from "lodash";
import JobsErrorDetailsDrawerSection from "./JobErrorDetailsDrawerSection";
import { Teams } from "../../../utilities/field-enums";
import { AssignmentField } from "./AssignmentField";
import { User } from "../../../utilities/generated/gql-types";
import client from "../../../utilities/apolloClient";
import { getJobsErrorReport } from "../../../queries";
import { useState } from "react";

interface JobErrorDetailsDrawerProps {}

export default function JobErrorDetailsDrawer({}: JobErrorDetailsDrawerProps) {
  const [loading, setLoading] = useState<boolean>(false);

  const { selectedJobError, setSelectedJobError, editingSection } =
    useJobsErrorCenter();

  const drawerOpen = selectedJobError !== null;

  const handleClose = () => {
    if (!editingSection) setSelectedJobError(null);
  };

  const handleJobReassign = async (user: User) => {
    setLoading(true);
    await JobsService.updateAssignee(selectedJobError.id, user.id);
    await client.refetchQueries({
      include: [getJobsErrorReport],
    });
    setLoading(false);
  };

  return (
    <Drawer anchor="right" onClose={handleClose} open={drawerOpen}>
      <Box sx={{ width: 800, position: "relative" }}>
        <Box sx={{ backgroundColor: "white", p: 2 }}>
          <Typography variant="h4">Job Error Report</Typography>
          <Typography variant="caption" sx={{ color: "gray" }}>
            Last failed at:{" "}
            {dayjs(selectedJobError?.failedAt).format("YYYY-MM-DD hh:mm:ss")}
          </Typography>
        </Box>
        <IconButton
          sx={{ position: "absolute", top: 16, right: 16 }}
          disabled={!!editingSection}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
        <Divider />
        <Box sx={{ backgroundColor: "rgba(245,245,245,1)", p: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <JobsErrorDetailsDrawerSection
                label="Management"
                properties={[
                  {
                    label: "Assignee",
                    jobErrorKey: "assignee",
                    renderer: (csm, sx = {}) => (
                      <Stack
                        direction="row"
                        spacing={1}
                        justifyContent="flex-end"
                        alignItems="center"
                        sx={sx}
                      >
                        <AssignmentField
                          assignee={selectedJobError?.assignee}
                          onReassign={handleJobReassign}
                          loading={loading}
                        />
                        <Typography>
                          {selectedJobError.assignee?.id !== "-1" &&
                            selectedJobError.assignee?.name}
                        </Typography>
                      </Stack>
                    ),
                  },
                ]}
              />
            </Grid>
            <Grid item xs={12}>
              <JobsErrorDetailsDrawerSection
                label="Job Details"
                properties={[
                  {
                    label: "Job ID",
                    jobErrorKey: "id",
                  },
                  {
                    label: "Relationship",
                    jobErrorKey: "relationshipName",
                  },
                  {
                    label: "Location Address",
                    jobErrorKey: "locationAddress",
                    renderer: (locationAddress, sx = {}) => (
                      <Stack
                        direction="row"
                        spacing={2}
                        justifyContent="flex-end"
                        alignItems="center"
                      >
                        <Tooltip title="View credentials">
                          <IconButton
                            target="_blank"
                            href={`/admin/tools/credentials/${selectedJobError.locationId}`}
                            size="small"
                          >
                            <Key />
                          </IconButton>
                        </Tooltip>
                        <Typography sx={sx}>{locationAddress}</Typography>
                      </Stack>
                    ),
                  },
                  {
                    label: "Method",
                    jobErrorKey: "method",
                  },
                ]}
              />
            </Grid>
            {Object.keys(selectedJobError?.arguments ?? {})?.length > 0 && (
              <Grid item xs={12}>
                <JobsErrorDetailsDrawerSection
                  label="Arguments"
                  properties={Object.entries(selectedJobError.arguments).map(
                    ([key, value]) => ({
                      label: key,
                      jobErrorKey: `arguments.${key}`,
                      renderer: () => (
                        <pre style={{ textAlign: 'left' }}>{JSON.stringify(value, null, 2)}</pre>
                      ),
                    }),
                  )}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <JobsErrorDetailsDrawerSection
                editable
                label="SafeLease Info"
                properties={[
                  {
                    label: "Triaging Status",
                    jobErrorKey: "triagingStatus",
                    renderer: (priority, sx = {}) => (
                      <Stack
                        direction="row"
                        spacing={1}
                        justifyContent="flex-end"
                        sx={sx}
                      >
                        <PriorityIcon priority={priority} />
                        <Typography>{priority}</Typography>
                      </Stack>
                    ),
                  },
                  {
                    label: "Team",
                    jobErrorKey: "team",
                    editable: true,
                    renderer: (team, sx = {}) => (
                      <Typography sx={sx}>{_.capitalize(team)}</Typography>
                    ),
                    asDropdown: true,
                    options: [
                      { value: Teams.Success, label: "Success" },
                      { value: Teams.Support, label: "Support" },
                      { value: Teams.Product, label: "Product" },
                    ],
                  },
                  {
                    label: "Resolution Notes",
                    jobErrorKey: "csmNotes",
                    editable: true,
                  },
                  {
                    label: "CSM Resolution Steps",
                    jobErrorKey: "csmResolutionSteps",
                  },
                ]}
              />
            </Grid>
            <Grid item xs={12}>
              <JobsErrorDetailsDrawerSection
                editable
                label="Error Info"
                properties={[
                  {
                    label: "Failed at",
                    jobErrorKey: "failedAt",
                    renderer: (failedAt, sx = {}) => (
                      <Typography sx={sx}>
                        {dayjs(failedAt).format("YYYY-MM-DD hh:mm:ss")}
                      </Typography>
                    ),
                  },
                  {
                    label: "Error",
                    jobErrorKey: "error",
                  },
                  {
                    label: "Error Notes",
                    jobErrorKey: "errorNotes",
                    editable: true,
                  },
                ]}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Drawer>
  );
}
