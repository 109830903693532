import BillingDataService from '../services/billing.service';
import { QueryResult } from '@apollo/client';
import { Llc } from '../utilities/generated/gql-types';
import { useHistory } from 'react-router-dom';
import { TableActionsDropdown } from './table-actions-dropdown';

function LlcActions(props: {
  id: number;
  llcsQuery: QueryResult;
  llc: Llc;
}) {
  const { id: llcId, llcsQuery, llc } = props;
  const history = useHistory();
  const menuItems = [
    {
      label: 'View Billing Credentials',
      callback: () => {
        history.push(`/admin/tools/billing_credentials/${llcId}`)
      },
    },
    // this functionality will be built out soon
    // {
    //   label: 'Edit Billing Information',
    //   callback: () => {
    //   },
    // },
    {
      label: 'View Notes/Changes',
      callback: () => {
        history.push(`/admin/notes/${llc.relationshipId}?llcId=${llc.id}`)
      }
    },
  ];

  return <TableActionsDropdown menuItems={menuItems} />;
};

export function llcActionsRenderer(
  id: number,
  tableInfo: { row: Llc; queryResult: QueryResult }
) {
  return (
    <LlcActions
      id={id}
      llcsQuery={tableInfo.queryResult}
      llc={tableInfo.row}
    />
  );
}
