import React from "react";

// UI - libs
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Tooltip,
} from "@mui/material";
import StarIcon from "@mui/icons-material/Star";

// UI - internal
import LocationAddressSettingsPopover from "./LocationAddressSettingsPopover";

// Data
import {
  Location,
  LocationAddress,
} from "../../../utilities/generated/gql-types";

type LocationOrLocationAddress = Location | LocationAddress;

interface LocationSettingsLocationAddressesTableProps {
  location: Location;
  locationAddresses: LocationOrLocationAddress[];
}

export default function LocationAddressesTable({
  location,
  locationAddresses,
}: LocationSettingsLocationAddressesTableProps) {

  return (
    <TableContainer style={{ maxHeight: '300px', overflowY: 'auto' }}>
      <Table>
        <LocationAddressesTableHead />
        <TableBody>
          {locationAddresses.map((locationAddress: LocationAddress) => (
            <LocationAddressesTableRow
              location={location}
              locationAddress={locationAddress}
              key={locationAddress.id}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

// Header row
function LocationAddressesTableHead() {
  return (
    <TableHead>
      <TableRow>
        <TableCell>
          <Typography>Primary</Typography>
        </TableCell>
        <TableCell>
          <Typography>ID</Typography>
        </TableCell>
        <TableCell>
          <Typography>Address</Typography>
        </TableCell>
        <TableCell>
          <Typography>City</Typography>
        </TableCell>
        <TableCell>
          <Typography>State</Typography>
        </TableCell>
        <TableCell>
          <Typography>Actions</Typography>
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

type LocationAddressesTableRowProps = {
  location: Location;
  locationAddress: LocationAddress;
};

// Address row
function LocationAddressesTableRow({
  location,
  locationAddress,
}: LocationAddressesTableRowProps) {
  return (
    <TableRow key={locationAddress.id}>
      <TableCell>
        {locationAddress.primary && (
          <div className="tw-flex tw-justify-center tw-text-[#2879FB]">
            <Tooltip title="Primary location" className="tw-mx-auto">
              <StarIcon />
            </Tooltip>
          </div>
        )}
      </TableCell>
      <TableCell>
        <Typography>{locationAddress.id}</Typography>
      </TableCell>
      <TableCell>
        <Typography>{locationAddress.address}</Typography>
      </TableCell>
      <TableCell>
        <Typography>{locationAddress.city}</Typography>
      </TableCell>
      <TableCell>
        <Typography>{locationAddress.state}</Typography>
      </TableCell>
      <TableCell>
        <LocationAddressSettingsPopover
          location={location}
          locationAddress={locationAddress}
        />
      </TableCell>
    </TableRow>
  );
}
