// Convert HTML string to Slate JSON
export const deserialize = (htmlString: string) => {
  // if there was no body text passed, then we should return
  // an empty slate node --> { type: "paragraph" } ;
  // with a single empty leaf { text: "" }
  if (!htmlString) return [{ type: "paragraph", children: [{ text: "" }] }];

  // If the htmlString isn't properly formatted for Slate, wrap it in a <p> tag
  // our `CampaignStep` structure should always have a <p> tag as the root element
  if (htmlString.slice(0, 3) !== "<p>") {
    htmlString = `<p>${htmlString}</p>`;
  }
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, "text/html");

  const traverseNode = (node) => {
    // If the node contains only text, return a leaf
    if (node.nodeType === Node.TEXT_NODE) {
      return {
        text: node.textContent.replace(/\n/g, ""), // Remove newlines
      };
    }

    // If the node is a line break, return a newline character
    if (node.nodeName.toLowerCase() === "br") {
      return {
        text: "\n",
      };
    }

    // If node is an element, return a node with a recursive call to traverse all inner nodes
    if (node.nodeName.toLowerCase() === "p") {
      return {
        type: "paragraph",
        children: Array.from(node.childNodes).map(traverseNode),
      };
    }

    // Occasionally, when copy pasting in, there will be some additioanl element nodes (usually <span>)
    // that need to have their text extracted
    if (node.nodeType === Node.ELEMENT_NODE) {
      return {
        text: node.textContent,
      };
    }

    return;
  };

  const body = doc.body;

  return Array.from(body.childNodes).map(traverseNode);
};
