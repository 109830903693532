import React from "react";

// UI - libs
import {
  ListItemText,
  MenuItem,
  Select,
} from "@mui/material";

// Data
import { Location, User } from "../../../../utilities/generated/gql-types";
import _ from "lodash";
import { Box } from "@mui/system";

interface SelectLocationsDropdownProps {
  locations: Location[];
  value: number[];
  user: User;
}

export default function SelectLocationsDropdown({
  locations = [],
  value,
  user,
}: SelectLocationsDropdownProps) {

  const filteredLocations = locations.filter((location) => {
    return value.indexOf(Number(location.id)) > -1;
  })

  if (filteredLocations.length === 0) return (
    <Box sx={{ px: 2, fontFamily: "Open Sans" }}>
      -
    </Box>
  );

  if (filteredLocations.length === 1) return (
    <Box sx={{ px: 2, fontFamily: "Open Sans" }}>
      {filteredLocations[0].address}
    </Box>
  )
  
  return (
    <Select
      sx={{
        width: "100%",
        "& fieldset": {
          // override base border (no pseudo classes)
          border: "none",
        },
        "&:hover fieldset": {
          border: "none !important", // Hovered pseudo class
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          border: "none !important", // Focused pseudo class (menu is open)
        },
      }}
      MenuProps={{
        sx: {
          '& .Mui-selected': {
            backgroundColor: 'transparent'
          }
        }
      }}
      displayEmpty
      labelId="location-select-label"
      id="location-select"
      multiple
      value={value}
      renderValue={(selected) => {

        if (selected.length === 0) return <em>-</em>;

        return (
          <span
            style={{
              color: "rgba(3,30,48,1)",
              fontFamily: "Open Sans",
              fontSize: 14,
            }}
          >
            	({selected.filter((location) => !!location).length}) selected locations
          </span>
        );
      }}
    >
      {filteredLocations?.map((location) => (
        <MenuItem
        value={Number(location.id)}
        key={location.id}
        >
          <ListItemText
            primary={location.address}
            sx={{
              color: "rgba(3,30,48,1)",
              fontFamily: "Open Sans"
            }}
          />
        </MenuItem>
      )) || []}
    </Select>
  );
}
