import {
  GridColDef,
} from "@mui/x-data-grid-premium";
import { PrivatePolicyStatusChip } from "./PrivatePolicyStatusChip";
import { PrivatePolicyValidationStatus } from "../../reports/useReportsStore/privatePolicyReportSlice";

export const complianceCenterGridColDefs: GridColDef[] = [
  {
    headerName: "Status",
    field: "validationStatus",
    flex: 1,
    renderCell: ({ row }) => (
      <PrivatePolicyStatusChip status={row.validationStatus} />
    ),
    sortComparator: (v1, v2) => {
      // Returns private policies in this order (top to bottom):
      // 1. Flagged ("humanReview")
      // 2. Accepted ("accepted")
      // 3. Rejected ("rejected")
      // 4. Archived ("archived")

      const statusOrder = ["humanReview", "accepted", "pendingAccepted", "rejected", "pendingRejected", "archived"];
      const index1 = statusOrder.indexOf(v1);
      const index2 = statusOrder.indexOf(v2);

      if (index1 < index2) {
        return -1;
      }
      if (index1 > index2) {
        return 1;
      }
      return 0;
    },
  },
  {
    headerName: "Submission ID",
    field: "id",
    flex: 1,
  },
  {
    headerName: "Tenant Name",
    field: "tenantName",
    flex: 1,
    valueGetter: ({ row }) => {
      if (row.validationStatus === PrivatePolicyValidationStatus.accepted) {
        const { tenantNameSource } = row;

        // Reviewer selected the AI extracted name as the valid source
        if (tenantNameSource === "aiExtracted") return row.extractedTenantName;

        // Tenant's input was selected as a valid tenant name
        if (tenantNameSource === "tenantInput") return row.tenantName;

        // Reviewer has overridden the tenant name because they found a valid one in the declaration page
        if (tenantNameSource === "manualOverride")
          return row.overrideTenantName;
      }

      return row.tenantName;
    },
  },
  {
    headerName: "Private Policy #",
    field: "privatePolicyNumber",
    flex: 1,
    valueGetter: ({ row }) => {
      if (row.validationStatus === PrivatePolicyValidationStatus.accepted) {
        const { policyNumberSource } = row;

        // Reviewer selected the AI extracted number as the valid source
        if (policyNumberSource === "aiExtracted")
          return row.extractedPolicyNumber;

        // Tenant's input was selected as a valid policy number
        if (policyNumberSource === "tenantInput") return row.policyNumber;

        // Reviewer has overridden the policy number because they found a valid one in the declaration page
        if (policyNumberSource === "manualOverride")
          return row.overridePolicyNumber;
      }

      return row.policyNumber;
    },
  },
  {
    headerName: "Private Policy Expiration",
    field: "privatePolicyExpiration",
    flex: 1,
    valueGetter: ({ row }) => {
      if (row.validationStatus === PrivatePolicyValidationStatus.accepted) {
        const { expirationSource } = row;

        // Reviewer selected the AI extracted date as the valid source
        if (expirationSource === "aiExtracted") return row.extractedExpiration;

        // Tenant's input was selected as a valid policy expiration date
        if (expirationSource === "tenantInput") return row.expiration;

        // Reviewer has overridden the expiration date because they found a valid one in the declaration page
        if (expirationSource === "manualOverride")
          return row.overrideExpiration;
      }

      return row.expiration;
    },
  },
  {
    headerName: "Unit",
    field: "unitName",
    flex: 1,
  },
  {
    headerName: "Partner",
    field: "relationshipName",
    flex: 1,
  },
  {
    headerName: "Facility",
    field: "locationAddress",
    flex: 1,
  },
  {
    headerName: "Invalidation Reason",
    field: "rejectionReason",
    flex: 1,
    valueFormatter: ({ value }) => {
      if (value === "invalidPolicyNumber") {
        return "Invalid Policy Number";
      }

      if (value === "invalidExpiration") {
        return "Invalid Policy Expiration";
      }

      if (value === "invalidDeclarationPage") {
        return "Invalid Declaration Page";
      }

      if (value === "invalidTenantName") {
        return "Invalid Tenant Name";
      }

      return value;
    },
  },
];
