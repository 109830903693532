import { useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { CircularProgress, FormControl, InputBase, useTheme } from "@mui/material";

interface SafeleaseSearchBarProps {
  defaultValue: string;
  searchValue: string;
  setSearchValue: (value: string) => void;
  onSubmitCallback?: () => void;
  placeholder?: string;
  loading?: boolean; // If not set, no loading indicator will ever appear
}

export const SafeleaseSearchBar = ({
  defaultValue,
  searchValue,
  setSearchValue,
  onSubmitCallback,
  placeholder="Search",
  loading=false
}: SafeleaseSearchBarProps) => {

  const theme = useTheme()

  const [isFocused, setIsFocused] = useState<boolean>(false);

  // Unfocus search bar when user presses enter or escape keys
  document.onkeydown = checkKey;
  function checkKey(e) {
    e = e || window.event;
    if (!!["27", "13"].find((key) => key == e.keyCode) && !!isFocused) {
      if (e.keyCode == "27") {
        setSearchValue("");
        e.target.blur();
      }
      if (e.keyCode == "13" && onSubmitCallback) {
        onSubmitCallback();
      }
    }
  }

  return (
    <FormControl sx={{ m: 1, position: "relative", height: 36 }} variant="standard">
      <InputBase
        onFocus={(e) => setIsFocused(true)}
        onBlur={(e) => setIsFocused(false)}
        onChange={(e) => setSearchValue(e.target.value)}
        placeholder={placeholder}
        startAdornment={
          <SearchIcon
            sx={{
              color: 'rgba(146, 146, 146, 1)',
              position: "absolute",
              zIndex: 500,
              top: "50%",
              left: 12,
              transform: "translateY(-50%)",
            }}
          />
        }
        endAdornment={
          loading
          ? <CircularProgress
            size={24}
            sx={{
              color: '#2879FB',
              position: 'absolute',
              right: 8,
            }}
            thickness={4.8}
          />
          : null
        }
        sx={{
          "label + &": {
            mt: 3,
          },
          "& .MuiInputBase-input": {
            backgroundColor: "white",
            borderRadius: "50rem",
            width: 450,
            position: "relative",
            border: "1px solid #EBEFF7",
            fontSize: 16,
            padding: "6px 40px 6px 40px",
            transition: theme.transitions.create([
              "border-color",
              "box-shadow",
            ]),
            //   // Use the system font instead of the default Roboto font.
            "&:focus": {
              borderRadius: "50rem",
              borderColor: "#80bdff",
              boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
            },
          },
        }}
      />
    </FormControl>
  );
}
