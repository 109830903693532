import { Badge, Box, Divider, Drawer, IconButton, Stack, Theme, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { PrivateReview_Reputation as PrivateReview, GoogleReview_Reputation as GoogleReview } from "@safelease/service-utilities";
import { TenantReviews } from "./FacilityView/FacilityViewTabs/ContactsOverview/ProfileDrawer/TenantReviews";
import Close from "@mui/icons-material/Close";
import { useReputation } from "../useReputation";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { PrivateTenantReviews } from "./NonPublicReplyDrawer";
import { SafeLeaseTabs, SafeLeaseTab, SafeLeaseTabContent } from "@safelease/components";
import PublicIcon from "@mui/icons-material/Public";
import PublicOffIcon from "@mui/icons-material/PublicOff";
import dayjs from "dayjs";

export enum ReviewReplyDrawerTab {
  PUBLIC = "Public Reviews",
  NONPUBLIC = "Private Reviews",
}

export enum ReviewReplyDateDurationFilter {
  LAST_30_DAYS = "Last 30 days",
  LAST_3_MONTHS = "Last 3 months",
  LAST_6_MONTHS = "Last 6 months",
  ALL_TIME = "All time",
}

interface ReviewReplyDrawerProps {
  publicReviews: Array<GoogleReview>;
  privateReviews: Array<PrivateReview>;
  presetTab?: ReviewReplyDrawerTab;
}

export function ReviewReplyDrawer({
  publicReviews = [],
  privateReviews = [],
  presetTab = ReviewReplyDrawerTab.PUBLIC,
}: ReviewReplyDrawerProps) {
  // get tab to show from the URL query parameters if provided
  const queryParamReviewReplyDrawerTab = new URLSearchParams(useLocation().search).get("reviewReplyDrawerTab");
  if (queryParamReviewReplyDrawerTab) {
    presetTab = queryParamReviewReplyDrawerTab === "public" ? ReviewReplyDrawerTab.PUBLIC : ReviewReplyDrawerTab.NONPUBLIC;
  }

  const { reviewReplyDrawerOpen, setReviewReplyDrawerOpen } = useReputation();
  const [currentTab, setCurrentTab] = useState<ReviewReplyDrawerTab>(presetTab);
  const [showReviewsSince, setShowReviewsSince] = useState<ReviewReplyDateDurationFilter>(ReviewReplyDateDurationFilter.LAST_30_DAYS);

  // When landing on the facility overview page, if there is a Query Parameter to open the drawer, open it on first render
  useEffect(() => {
    if (queryParamReviewReplyDrawerTab) {
      setReviewReplyDrawerOpen(true);
    }
  }, []);

  const handleClose = () => {
    setReviewReplyDrawerOpen(false);
  };

  const sortedFilteredPublicReviews = publicReviews
    .filter((publicReview) => publicReviewFilterByDateRange(publicReview, showReviewsSince))
    .sort((a, b) => new Date(b.actualDate).getTime() - new Date(a.actualDate).getTime());

  const sortedFilteredPrivateReviews = privateReviews
    .filter((privateReview) => privateReviewFilterByDateRange(privateReview, showReviewsSince))
    .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());

  const privateReviewsPendingReply = privateReviews.filter((review) => !review.responseSnippet);

  return (
    <Drawer open={reviewReplyDrawerOpen} onClose={handleClose} PaperProps={{ sx: { width: 700, p: 2 } }} anchor="right">
      <Stack direction="row" alignItems="flex-start" justifyContent="space-between">
        <Box>
          <Typography variant="h6" fontWeight="bold">
            Reviews Pending Reply
          </Typography>
        </Box>
        <IconButton onClick={handleClose}>
          <Close />
        </IconButton>
      </Stack>
      <Divider />
      <SafeLeaseTabs
        sx={{ mt: 1 }}
        onChange={(_, value) => {
          setCurrentTab(value);
        }}
        value={currentTab}
      >
        <SafeLeaseTab
          label={ReviewReplyDrawerTab.PUBLIC}
          value={ReviewReplyDrawerTab.PUBLIC}
          icon={
            <Badge badgeContent={publicReviews.length} color="primary" anchorOrigin={{ vertical: "top", horizontal: "left" }}>
              <PublicIcon />
            </Badge>
          }
        />
        <SafeLeaseTab
          label={ReviewReplyDrawerTab.NONPUBLIC}
          value={ReviewReplyDrawerTab.NONPUBLIC}
          icon={
            <Badge badgeContent={privateReviewsPendingReply.length} color="primary" anchorOrigin={{ vertical: "top", horizontal: "left" }}>
              <PublicOffIcon />
            </Badge>
          }
        />
      </SafeLeaseTabs>
      <ToggleButtonGroup
        color="primary"
        value={showReviewsSince}
        exclusive
        onChange={(_, value) => setShowReviewsSince(value)}
        sx={{ mt: 2 }}
      >
        <ToggleButton value={ReviewReplyDateDurationFilter.LAST_30_DAYS}>Last 30 days</ToggleButton>
        <ToggleButton value={ReviewReplyDateDurationFilter.LAST_3_MONTHS}>Last 3 months</ToggleButton>
        <ToggleButton value={ReviewReplyDateDurationFilter.LAST_6_MONTHS}>Last 6 months</ToggleButton>
        <ToggleButton value={ReviewReplyDateDurationFilter.ALL_TIME}>All time</ToggleButton>
      </ToggleButtonGroup>
      <Box sx={{ py: 2 }}>
        <SafeLeaseTabContent value={currentTab} currentTab={ReviewReplyDrawerTab.PUBLIC}>
          <TenantReviews reviews={sortedFilteredPublicReviews} />
        </SafeLeaseTabContent>
        <SafeLeaseTabContent value={currentTab} currentTab={ReviewReplyDrawerTab.NONPUBLIC}>
          <PrivateTenantReviews reviews={sortedFilteredPrivateReviews} />
        </SafeLeaseTabContent>
      </Box>
    </Drawer>
  );
}

// Filter down the list of public reviews to those matching the date range selected
export function publicReviewFilterByDateRange(publicReview: GoogleReview, showReviewsSince: ReviewReplyDateDurationFilter) {
  if (showReviewsSince === ReviewReplyDateDurationFilter.ALL_TIME) {
    return publicReview;
  }

  if (showReviewsSince === ReviewReplyDateDurationFilter.LAST_30_DAYS) {
    return dayjs(publicReview.actualDate).isAfter(dayjs().subtract(30, "days"));
  }

  if (showReviewsSince === ReviewReplyDateDurationFilter.LAST_3_MONTHS) {
    return dayjs(publicReview.actualDate).isAfter(dayjs().subtract(3, "months"));
  }

  if (showReviewsSince === ReviewReplyDateDurationFilter.LAST_6_MONTHS) {
    return dayjs(publicReview.actualDate).isAfter(dayjs().subtract(6, "months"));
  }
}

// Filter down the list of private reviews to those matching the date range selected
export function privateReviewFilterByDateRange(privateReview: PrivateReview, showReviewsSince: ReviewReplyDateDurationFilter) {
  if (showReviewsSince === ReviewReplyDateDurationFilter.ALL_TIME) {
    return privateReview;
  }

  if (showReviewsSince === ReviewReplyDateDurationFilter.LAST_30_DAYS) {
    return dayjs(privateReview.createdAt).isAfter(dayjs().subtract(30, "days"));
  }

  if (showReviewsSince === ReviewReplyDateDurationFilter.LAST_3_MONTHS) {
    return dayjs(privateReview.createdAt).isAfter(dayjs().subtract(3, "months"));
  }

  if (showReviewsSince === ReviewReplyDateDurationFilter.LAST_6_MONTHS) {
    return dayjs(privateReview.createdAt).isAfter(dayjs().subtract(6, "months"));
  }
}
