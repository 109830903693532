// This file is the configuration of which columns are visible for each data grid "slice" is selected in the General Units History page

import { generalUnitsHistoryColumns } from "./generalUnitsHistoryColumns";

// Default all columns to false, then override individual columns for specific slices
const generalUnitsHistoryColumnsAsFalse = generalUnitsHistoryColumns.reduce(
  (acc, curr) => {
    acc[curr.field] = false;
    return acc;
  },
  {}
);

const unitColumns = {
  ...generalUnitsHistoryColumnsAsFalse,
  unitId: true,
  unitName: true,
  unitType: true,
  unitSize: true,
  fmsUnitStatus: true,
  safeleaseUnitStatus: true,
  insurable: true,
};

const tenantColumns = {
  ...generalUnitsHistoryColumnsAsFalse,
  unitId: true,
  tenantName: true,
  tenantEmail: true,
  moveInDate: true,
  moveOutDate: true,
};

const policyColumns = {
  ...generalUnitsHistoryColumnsAsFalse,
  unitId: true,
  policyType: true,
  policySource: true,
  premium: true,
  coverage: true,
  policyStartDate: true,
  policyCancelDate: true,
  privatePolicyNumber: true,
  privatePolicyExpiration: true,
};

const financialColumns = {
  ...generalUnitsHistoryColumnsAsFalse,
  unitId: true,
  currentMonthlyRent: true,
  leasePaidThroughDate: true,
  premium: true,
  coverage: true,
};

const metadatacolumns = {
  ...generalUnitsHistoryColumnsAsFalse,
  unitId: true,
  id: true,
  createdAt: true,
  updatedAt: true,
  lastIngestedAt: true,
  leaseId: true,
  serviceId: true,
};

export const generalUnitsColumnVisibilityModel = {
  Unit: unitColumns,
  Tenant: tenantColumns,
  Policy: policyColumns,
  Financial: financialColumns,
  Metadata: metadatacolumns,
};
