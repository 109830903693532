import { Box, Skeleton, Stack, Typography } from "@mui/material";
import { FacilityListItem } from "./FacilityListItem";
import { useReputation } from "../../useReputation";
import { FacilityMetrics } from "@safelease/service-utilities";

interface FacilityListProps {}

/**
 *  List of all facilities in this portfolio
 */
export function FacilityList({}: FacilityListProps) {
  const { statuses, metrics } = useReputation();

  if (statuses.metrics === "loading") {
    return <Skeleton variant="rectangular" height={140} />;
  }

  if (statuses.metrics === "error") return null;

  return (
    <Box>
      <Stack direction="row" justifyContent="space-between">
        <Typography sx={{ mb: 1, fontWeight: "bold", mt: 2 }}>Facilities</Typography>
      </Stack>
      <Stack direction="column" spacing={1}>
        {metrics.facilities.map((facilityMetrics: FacilityMetrics) => (
          <FacilityListItem {...facilityMetrics} key={facilityMetrics.id} />
        ))}
      </Stack>
    </Box>
  );
}
