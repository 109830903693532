import { Tooltip } from "@mui/material";
import Avatar, { AvatarProps } from "@mui/material/Avatar";

function stringToColor(string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name: string, sx: AvatarProps["sx"] = {}) {
  return {
    sx: {
      bgcolor: stringToColor(name),
      fontSize: 16,
      ...sx,
    },
    children: `${name.split(" ")[0][0]}${name.split(" ").length > 1 ? name.split(" ")[1][0] : ""}`,
  };
}

interface LetterAvatarProps extends AvatarProps {
  name?: string;
}

export default function LetterAvatar({ name, sx }: LetterAvatarProps) {
  const noNameProvided = name === "N/A" || !name || name.length === 0;
  return (
    <Tooltip title={name} arrow>
      <Avatar {...stringAvatar(noNameProvided ? "Unassigned" : name, sx)} />
    </Tooltip>
  );
}
