import { Avatar, Box } from "@mui/material";
import StoreOutlinedIcon from "@mui/icons-material/StoreOutlined";
import { useState } from "react";
import { alpha } from "@mui/system";
import { blue } from "@mui/material/colors";

interface FacilityLogoAvatarProps {
  src: string;
}

export function FacilityLogoAvatar({ src }: FacilityLogoAvatarProps) {
  const [failedToLoad, setFailedToLoad] = useState<boolean>(false);

  if (failedToLoad || !src)
    return (
      <Box
        sx={{
          height: 40,
          width: 40,
          bgcolor: alpha(blue[100], 0.2),
          color: "navy.main",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "50%",
        }}
      >
        <StoreOutlinedIcon />
      </Box>
    );

  return (
    <img
      src={src}
      alt="company-logo"
      style={{ height: "auto", width: "auto", maxWidth: 100, maxHeight: 40 }}
      onError={() => setFailedToLoad(true)}
    />
  );
}
