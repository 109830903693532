import { Download, Star } from "@mui/icons-material";
import {
  Box,
  Card,
  CardContent,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { amber, blue, grey } from "@mui/material/colors";
import { ReactElement, cloneElement, useState } from "react";
import { useHistory } from "react-router-dom";
import { useAdminToolsStore } from "./useAdminToolsStore";
import { useSnackbar } from "notistack";

export interface AdminToolNavProps {
  icon: ReactElement;
  downloadable?: boolean;
  label: string;
  path?: string;
  onClick?: (event: React.SyntheticEvent) => void;
  favorite?: boolean;
  id?: string;
}

export default function AdminToolNavCard({
  icon=null,
  downloadable = false,
  label,
  path,
  onClick,
  id,
}: AdminToolNavProps) {
  const history = useHistory();
  const { favorites, toggleFavorite } = useAdminToolsStore();
  const { enqueueSnackbar } = useSnackbar();

  const isFavorite = favorites.findIndex((favorite) => favorite === id) > -1;

  /* Can't remove "event" as reports need it */
  const handleClick = (event) => {
    if (path) {
      history.push(path);
    } else {
      onClick(event);
    }
  };

  /* Toggle the favorite status of a list item */
  const handleFavorite = (event: React.SyntheticEvent) => {
    event.stopPropagation();
    if (isFavorite) {
      enqueueSnackbar(`Removed ${label} from favorites`, {
        variant: "success",
      });
    } else {
      enqueueSnackbar(`Added ${label} to favorites`, { variant: "success" });
    }
    toggleFavorite(id);
  };

  return (
    <Card
      onClick={(event) => handleClick(event)}
      sx={{
        boxShadow: `0 0 20px rgba(0, 0, 0, 0.05)`,
        border: "1px solid #EBEFF7",
        bgcolor: "white",
        color: blue[400],
        width: "100%",
        pb: 2,
        cursor: "pointer",
        transition: "150ms",
        position: "relative",
        "&:hover": {
          bgcolor: blue[100],
        },
      }}
    >
      <CardContent>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          {downloadable && (
            <Tooltip title="File download">
              <Download sx={{ color: grey[300] }} />
            </Tooltip>
          )}
          <Tooltip title={`${isFavorite ? "Remove from" : "Add to"} favorites`}>
            <IconButton sx={{ ml: "auto" }} onClick={handleFavorite}>
              <Star
                sx={{
                  float: "right",
                  color: isFavorite ? amber[500] : grey[300],
                }}
              />
            </IconButton>
          </Tooltip>
        </Stack>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            height: 40,
            width: "100%",
          }}
        >
          {cloneElement(icon, { sx: { height: "48px", width: "auto" } })}
        </Box>
        <Typography
          variant="subtitle1"
          sx={{ textAlign: "center", fontWeight: "bold", color: "#152744", mt: 2 }}
        >
          {label}
        </Typography>
      </CardContent>
    </Card>
  );
}
