import React from "react";
import { Avatar, Typography } from "@mui/material";
import PersonIcon from "@mui/icons-material/PersonOutlined";
import { FlexColumnBox, FlexRowBox } from "../OrganizationStyledComponents";

interface CustomSelectContentProps {
  value: string;
  valueColor: string;
  marginLeft: string;
  imageRefs?: string[];
  infoText: string;
  contentWrapperStyling?: any;
}

export const CustomSelectContent: React.FC<CustomSelectContentProps> = ({
  value,
  valueColor,
  marginLeft,
  imageRefs,
  infoText,
  contentWrapperStyling,
}) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        ...contentWrapperStyling,
      }}
    >
      <FlexRowBox>
        {/* If imageRefs are given, map up to a max of 3 images to the select field  */}
        {imageRefs &&
          imageRefs.slice(0, 3).map((imageRef, index) => {
            return (
              <Avatar
                src={imageRef || undefined} // Show the image if the imageRef is defined, otherwise show the default avatar
                key={index}
                style={{
                  position: "relative",
                  right: index > 0 ? `${index * 20}px` : 0, // Adjust the offset as needed
                  zIndex: 3 - index, // Ensure the stacking order
                  height: "30px",
                  width: "30px",
                  marginLeft: "4px", // Adjust spacing between avatars
                }}
              >
                <PersonIcon />
              </Avatar>
            )
        })}
      </FlexRowBox>
      <FlexColumnBox>
        <Typography
          sx={{
            fontSize: "12px",
            color: valueColor,
            marginLeft,
          }}
        >
          {value}
        </Typography>
        <Typography
          sx={{
            fontSize: "12px",
            color: "#929292",
            marginLeft,
          }}
        >
          {infoText}
        </Typography>
      </FlexColumnBox>
      {/* TODO: Comment back in when we have a use case and copy
       * Needs a way to control the visility of it
       * Needs a strategy for showing, prolly tooltip?
       
       <InfoIcon 
        sx={{
          fontSize: "16px",
          color: "#929292",
          marginLeft: "10px",
        }}
      /> */}
    </div>
  );
};
