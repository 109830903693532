import styled from "@emotion/styled";
import { Box, Button, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@mui/material";
import { usePrivatePolicySettingsStore } from "./usePrivatePolicySettingsStore/usePrivatePolicySettingsStore";
import { useQuery } from "@apollo/client";
import { getPrivatePolicyConfigurations } from "../../../queries";
import RouterHelper from "../../../utilities/router-helper";
import { useAuth } from "../../../auth";
import { navyButton } from "../../../styles/mui-overrides";
import RelationshipSelector from "../../../shared/RelationshipSelector";
import { PrivatePolicyConfigMenu } from "./PrivatePolicyConfigMenu";
import { useEffect } from "react";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';


type PrivatePolicySettingsProps = {
  routerHelper: RouterHelper;
};

const PrivatePolicySettings = ({
  routerHelper
}: PrivatePolicySettingsProps) => {

  const StyledTableRow = styled(TableRow)({
    borderBottom: "1px solid #EBEFF7",
  });
  const auth = useAuth();

  const relationshipId =
    routerHelper.getRelationshipId() || auth.user.relationshipId;

  const setConfigMenuOpen = usePrivatePolicySettingsStore((state) => state.setConfigMenuOpen);
  const reset = usePrivatePolicySettingsStore((state) => state.reset);

  const privatePolicyConfigurationsQuery = useQuery(getPrivatePolicyConfigurations, {
    variables: { relationshipId },
  });

  useEffect(() => {
    reset(); // reset the store when the relationshipId changes
  }, [relationshipId, reset]);


  return (
    <Box>
      <Box sx={{ paddingBottom: "15px" }}>
        <RelationshipSelector
          routerHelper={routerHelper}
          settingsTab="privatePolicySettings"
        />
      </Box>
      <Stack direction="row" justifyContent="space-between" paddingBottom={3}>
        <Typography variant="h6">Private Policy Configurations</Typography>
        <Button disableElevation variant="contained" sx={{ ...navyButton }} onClick={() => setConfigMenuOpen(true)}>Enforce Compliance</Button>
      </Stack>
      <TableContainer
        component={Box}
        borderRadius="5px"
        border="1px solid #EBEFF7"
        borderBottom={0}
      >
        <Table>
          <TableHead>
            <StyledTableRow>
              <TableCell>Locations</TableCell>
              <CustomTableCellWithToolTip
                text="FMS Fake # Enforcement"
                tooltipText="Remove policies that have invalid policy numbers from the FMS."
              />
              <CustomTableCellWithToolTip
                text="FMS Blank/Invalid Expiration Enforcement"
                tooltipText="Remove policies that have invalid or null expiration dates from the FMS."
              />
              <CustomTableCellWithToolTip
                text="FMS Enforcement Date"
                tooltipText="The date that we SafeLease will begin enforcing the private policy compliance rules for private policies that are found in the FMS."
              />
              <CustomTableCellWithToolTip
                text="Internal Policies Only"
                tooltipText="Require all private policies to be submitted through SafeLease. All FMS policies will be removed."
              />
              <CustomTableCellWithToolTip
                text="Internal Reviewed/Compliant Declaration Page"
                tooltipText="All policies submitted through SafeLease will require declaration page review for compliance. Any private policy that is deemed uncompliant will be rejected."
              />
              <CustomTableCellWithToolTip
                text="Internal Enforcement Date"
                tooltipText="The date that we SafeLease will begin enforcing the private policy compliance rules for private policies submitted directly through SafeLease."
              />
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {
              privatePolicyConfigurationsQuery.data?.getPrivatePolicyConfigurations?.map((location: any) => (
                <StyledTableRow key={location.locationId}>
                  <TableCell>{location.locationAddress}</TableCell>
                  <TableCell>{location.fmsValidPolicyNumber ? "Yes" : "No"}</TableCell>
                  <TableCell>{location.fmsExpirationDate ? "Yes" : "No"}</TableCell>
                  <TableCell>{location.fmsEnforcementDate}</TableCell>
                  <TableCell>{location.intPoliciesOnly ? "Yes" : "No"}</TableCell>
                  <TableCell>{location.intCompliantDeclaration ? "Yes" : "No"}</TableCell>
                  <TableCell>{location.intEnforcementDate}</TableCell>
                </StyledTableRow>
              ))
            }
          </TableBody>
        </Table>
      </TableContainer>
      <PrivatePolicyConfigMenu privatePolicyConfigurationsQuery={privatePolicyConfigurationsQuery} routerHelper={routerHelper} />
    </Box>
  );
};

const CustomTableCellWithToolTip = ({ text, tooltipText }: { text: string, tooltipText: string; }) => (
  <TableCell>
    {text}
    <Tooltip title={tooltipText} >
      <InfoOutlinedIcon sx={{ fontSize: 18, marginLeft: "3px" }} color="disabled" />
    </Tooltip>
  </TableCell>
);

export { PrivatePolicySettings };
