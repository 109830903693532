import { upgradeAlgorithm } from "../../../reports/TenantProtectionActivityReport/upgradeAlgorithms";
import { GeneralUnit } from "../../../utilities/generated/gql-types";
import { PlanUpgradeStepWithUnitCount } from "./ReviewStep";

/* Get the columns, rows, and total units for the SKU matrix and impacted units */
export function skuConfig(
  generalUnits: Partial<GeneralUnit>[],
  premiumBuckets: PremiumBucket[],
  variant: "before" | "after"
) {

  const columns = new Set<number>();
  const premiums = new Set<number>();

  // Create a map of locations and the premiums that are present at each location
  const skusAtLocations = {}

  const premiumMap: {
    [premium: number]: { premium: number; [coverage: number]: number };
  } = {};
  let impactedUnits = 0;

  // Add premiums and columns from generalUnits and premiumBuckets
  generalUnits.forEach(({ premium, coverage, ...rest }) => {
    if (!skusAtLocations[premium]) skusAtLocations[premium] = {};
    if (!skusAtLocations[premium][coverage]) skusAtLocations[premium][coverage] = new Set<number>();
    skusAtLocations[premium][coverage].add(rest.locationId);

    premiums.add(premium);
    columns.add(coverage);
  });

  premiumBuckets.forEach(({ premium, coverage }) => {
    premiums.add(premium);
    columns.add(coverage);
  });

  // Initialize rows for each premium
  premiums.forEach((premium: number) => {
    premiumMap[premium] = { premium: premium };
  });

  // Process generalUnits
  generalUnits.forEach((unit) => {
    const adjustedUnit =
      variant === "before"
        ? unit
        : upgradeAlgorithm(unit.premium, unit.coverage, premiumBuckets);

    const unitHasChanges =
      unit.premium !== adjustedUnit.premium ||
      unit.coverage !== adjustedUnit.coverage;

    if (unitHasChanges) impactedUnits++;

    // Initialize coverage count if not present
    premiumMap[adjustedUnit.premium][adjustedUnit.coverage] =
      (premiumMap[adjustedUnit.premium][adjustedUnit.coverage] || 0) + 1;
  });

  // Create rows from the premiumMap
  const rows = Object.values(premiumMap).map((row) => ({
    id: row.premium,
    ...row,
  }));

  return { rows, columns, impactedUnits, skusAtLocations };
}

export function calculateMigration(
  skuConfigBefore: ReturnType<typeof skuConfig>,
  premiumBuckets
): PlanUpgradeStepWithUnitCount[] {
  const migrationList = [];

  // Iterate through skuConfigBefore rows
  skuConfigBefore.rows.forEach((rowBefore) => {
    const id = rowBefore.id;

    for (const column in rowBefore) {
      if (column !== "id" && column !== "premium") {
        const oldPremium = rowBefore.premium;
        const numberAtPremiumBefore = rowBefore[column];
        const oldCoverage = parseInt(column);

        // Calculate premiumAfter using the upgradeAlgorithm function
        const upgradedBucket = upgradeAlgorithm(oldPremium, oldCoverage, premiumBuckets);
        const newPremium = upgradedBucket.premium;
        const newCoverage = upgradedBucket.coverage;

        // Add to migrationList
        migrationList.push({
          oldPremium,
          numberAtPremiumBefore,
          newPremium,
          oldCoverage,
          newCoverage,
        });
      }
    }
  });

  return migrationList;
}
