import http from './http-common';
import { Note } from '../utilities/generated/gql-types'

class ClaimsDataService {

  confirm(payload: {
      claimId: string,
      locationId: number, 
      confirmed: boolean,
      matchedLocation: string, 
      matchAccuracy: number}) {
    const { claimId, locationId, confirmed, matchedLocation, matchAccuracy } = payload
    return http.post('claims/confirm', {
      claimId, 
      locationId,
      confirmed,
      matchedLocation,
      matchAccuracy
    })
  }

  createNote(newNote: Note) {
    console.log(newNote)
    return http.post('claims/create_note', newNote)
  }
      
  deleteNote(noteToDelete: Note) {
    return http.post('claims/delete_note', noteToDelete)
  }

  editNote(editedNote: Note) {
    return http.post('claims/edit_note', editedNote)
  }
}


export default new ClaimsDataService();
