import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

/**
 * Default styles are for navbar = true routes and will render at the very top of the page.
 * Adjust as necessary with the 'styles' property.
 * 
 * TODO: Abstract the 'content' class from App.js into a wrapper used in every component rendered by the router.
 */
export default function Loader(props?: { styles?: object }) {
  const { styles = {} } = props;
  return (
    <Box sx={{ marginTop: '-20px', width: '100%', ...styles }}> 
      <LinearProgress />
    </Box>
  );
}
