// Convert the Slate JSON node tree into a stringified HTML DOM tree
// We need to iterate through the body paragraphs because hitting the
// "enter" key in your text editor will create a new paragraph
export const serializeBody = (node) => {
  return node.children.map((child) => serializeParagraph(child)).join("<br>");
};

// Line breaks happen at the inter-paragraph level and inner-paragraph level, therefore we don't
// re-join on a <br> for paragraphs
export const serializeParagraph = (node) => {
  return node.children.map((child) => serializeText(child)).join("");
};

// Handles the text nodes in the Slate JSON node tree
// New lines will be replaced with a <br> tag (but will remain in the same paragraph)
export const serializeText = (node) => {
  let text = node.text;

  // console.log({ text, split: text.split("\n") });
  if (text === "\n") return ["<br>"];

  return text.replaceAll("\n", "<br>");
};
