import { StateCreator } from "zustand";
import { v4 as uuidv4 } from "uuid";
import { PlanUpgradeSettingsStore } from "./usePlanUpgradeSettingsStore";

export type PlanUpgradeStepWithId = PlanUpgradeStep & { id: string };

export interface PlanUpgradeSettingsPlansSliceState {
  /* New standard premium buckets after user has made changes */
  premiumBuckets: PlanUpgradeStepWithId[];
  defaultPremiumBuckets: PlanUpgradeStep[];
}

export interface PlanUpgradeSettingsPlansSlice
  extends PlanUpgradeSettingsPlansSliceState {
  /* Change premim or coverage of a bucket */
  adjustPremiumBucket: ({ id, coverage, premium }) => void;

  /* Add a new premium bucket */
  addPremiumBucket: ({
    oldCoverage,
    oldPremium,
    newCoverage,
    newPremium,
    removeAfterComplete,
  }) => void;

  /* Remove a premium bucket */
  removePremiumBucket: (id: string) => void;

  /* Set (or reset) all premium buckets at once */
  setPremiumBuckets: (buckets: PlanUpgradeStep[]) => void;

  setDefaultPremiumBuckets: (buckets: PlanUpgradeStep[]) => void;
}

export const createPlanUpgradeSettingsPlansSlice: StateCreator<
  Partial<PlanUpgradeSettingsStore> & PlanUpgradeSettingsPlansSlice
> = (set, get) => ({
  premiumBuckets: [],

  setPremiumBuckets: (premiumBuckets) => {
    set({
      premiumBuckets: premiumBuckets.map((bucket) => ({
        ...bucket,
        id: uuidv4(),
      })),
    });
  },

  adjustPremiumBucket: ({ id, coverage, premium }) => {
    set((state) => ({
      premiumBuckets: state.premiumBuckets.map((bucket) => {
        if (bucket.id === id) {
          return {
            ...bucket,
            newCoverage: coverage ? coverage : bucket.newCoverage,
            newPremium: premium ? premium : bucket.newPremium,
          };
        }

        return bucket;
      }),
    }));
  },

  addPremiumBucket: ({
    oldCoverage,
    oldPremium,
    newCoverage,
    newPremium,
    removeAfterComplete,
  }) => {
    set((state) => ({
      premiumBuckets: [
        ...state.premiumBuckets,
        {
          id: uuidv4(),
          oldCoverage,
          oldPremium,
          newCoverage,
          newPremium,
          removeAfterComplete,
        },
      ],
      disableContinue: false /* Enable continue button if it was set to false */,
    }));
  },

  removePremiumBucket: (id) => {
    set((state) => ({
      premiumBuckets: state.premiumBuckets.filter((bucket) => bucket.id !== id),
    }));

    /* Disable conntinue button if no plans exist */
    if (!get().premiumBuckets.length) {
      set({ disableContinue: true });
    }
  },

  defaultPremiumBuckets: [],
  setDefaultPremiumBuckets: (defaultPremiumBuckets) => {
    set({ defaultPremiumBuckets });
  },
});
