import { Check, Error, PhoneIphoneOutlined } from "@mui/icons-material";
import { TimelineContent, TimelineContentProps } from "@mui/lab";
import { Alert, Box, CircularProgress, Divider, Stack, Typography, Theme, alpha, FormControlLabel, Checkbox } from "@mui/material";
import { SafeLeaseButton, SafeLeaseChip, SafeLeaseFormTextField, SafeLeaseSwitch, SafeLeaseTextField } from "@safelease/components";
import { Controller, useFormContext } from "react-hook-form";
import { useAuth } from "../../../../auth";
import { CampaignStepBodyEditor, deserialize, serializeBody } from "./CampaignStepBodyEditor";
import { useEffect, useMemo, useState } from "react";
import { CampaignStep_Reputation as CampaignStep } from "@safelease/service-utilities";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import { blue, green, red } from "@mui/material/colors";
import { useReputation } from "../../../useReputation";
import { parsePhoneNumberFromString } from "libphonenumber-js";

interface CampaignSMSConfigurationStepContentProps extends TimelineContentProps {
  campaignId?: string;
  campaignStep?: Partial<CampaignStep>;
  stepIndex: number;
  handleRemoveStep?: (stepIndex: number) => void;
}

/** House the content for an individual step in the campaign */
export function CampaignSMSConfigurationStepContent({ campaignId, campaignStep, stepIndex }: CampaignSMSConfigurationStepContentProps) {
  const { statuses } = useReputation();
  const auth = useAuth();
  const isAdmin = auth.user?.isAdmin;
  const { watch, control } = useFormContext();

  return (
    <TimelineContent>
      <Box
        sx={{
          height: "100%",
          bgcolor: alpha("#EBEFF7", 0.4),
          minHeight: 200,
          p: 2,
          borderRadius: 2,
          width: (theme: Theme) => theme.breakpoints.values.sm,
        }}
      >
        <Stack direction="column" spacing={2}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Stack direction="row" alignItems="center" sx={{ mb: 2, flex: 1 }} spacing={2}>
              <PhoneIphoneOutlined sx={{ color: (theme: Theme) => theme.palette.blue.main, width: "28px", height: "auto" }} />
              <Typography variant="body1" fontWeight="600">
                Message {stepIndex + 1}
              </Typography>
            </Stack>
            {isAdmin && (
              <Controller
                name={`campaignSteps.${stepIndex}.enabled`}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <SafeLeaseSwitch onChange={onChange} checked={value} value={value} disabled={statuses.campaignConfigurationForm} />
                )}
              />
            )}
            {!isAdmin && watch(`campaignSteps.${stepIndex}.enabled`) && (
              <SafeLeaseChip color="green" label="Enabled" sx={{ borderRadius: "50em" }} />
            )}
            {!isAdmin && !watch(`campaignSteps.${stepIndex}.enabled`) && (
              <SafeLeaseChip color="red" label="Disabled" sx={{ borderRadius: "50em" }} />
            )}
          </Stack>
          {isAdmin && <AdminContent campaignId={campaignId} stepIndex={stepIndex} />}
          {!isAdmin && <PartnerContent stepIndex={stepIndex} />}
          <TestSMSSender stepIndex={stepIndex} campaignId={campaignId} campaignStep={campaignStep} />
        </Stack>
      </Box>
    </TimelineContent>
  );
}

/** Content to display to admins for editing campaign configuration */
function AdminContent({ stepIndex }: CampaignSMSConfigurationStepContentProps) {
  const { statuses } = useReputation();
  const { formState, setValue } = useFormContext();

  const handleStepDelayDaysChange = (newValue: number) => {
    setValue(`campaignSteps.${stepIndex}.stepDelayDays`, newValue);
  };

  return (
    <>
      {stepIndex > 0 && (
        <SafeLeaseFormTextField
          type="number"
          onChange={(event) => handleStepDelayDaysChange(Number(event.target.value))}
          name={`campaignSteps.${stepIndex}.stepDelayDays`}
          label="Step delay (days)"
          sx={{ bgcolor: "white", width: 100 }}
          helperText={formState.errors?.campaignSteps?.[stepIndex]?.stepDelayDays?.message}
          error={Boolean(formState.errors?.campaignSteps?.[stepIndex]?.stepDelayDays)}
          disabled={statuses.campaignConfigurationForm}
          tooltip="The number of days to wait before sending this email after the previous email has been sent."
        />
      )}
      <SafeLeaseFormTextField
        name={`campaignSteps.${stepIndex}.templateText`}
        label="Body"
        sx={{ width: "100%", bgcolor: "white" }}
        multiline
        rows={4}
      />
    </>
  );
}

/** Content to display to partners for viewing campaign configuration */
function PartnerContent({ stepIndex }: CampaignSMSConfigurationStepContentProps) {
  const { watch } = useFormContext();
  const stepTemplateText = useMemo(() => deserialize(watch(`campaignSteps.${stepIndex}.templateText`)), []);

  return (
    <>
      <Divider />
      <Stack direction="row" alignItems="center" spacing={2}>
        <Typography component="label" sx={{ width: 80, color: "grey.A100" }}>
          Body
        </Typography>
        <CampaignStepBodyEditor value={stepTemplateText} readOnly={true} />
      </Stack>

      <Divider />
      <Stack direction="row" alignItems="center" spacing={2}>
        <Typography component="label" sx={{ width: 80, color: "grey.A100" }}>
          Delay
        </Typography>
        <Typography fontWeight="500">
          {stepIndex === 0 ? "Immediately" : `${watch(`campaignSteps.${stepIndex}.stepDelayDays`)} day(s)`}
        </Typography>
      </Stack>
      <Divider />
    </>
  );
}

const formatPhoneNumber = (phoneNumber: string) => {
  if (!phoneNumber || phoneNumber.trim() === "") {
    return { formatted: "", e164: "" };
  }

  const parsedNumber = parsePhoneNumberFromString(phoneNumber, "US");
  if (parsedNumber && parsedNumber.isValid()) {
    const formattedNumber = `${parsedNumber.countryCallingCode} (${parsedNumber.nationalNumber.slice(
      0,
      3,
    )}) ${parsedNumber.nationalNumber.slice(3, 6)} - ${parsedNumber.nationalNumber.slice(6)}`;
    const e164 = parsedNumber.format("E.164");

    return { formattedNumber, e164 };
  }
  return { formattedNumber: phoneNumber, e164: phoneNumber };
};

function TestSMSSender({ campaignId, stepIndex, campaignStep }: CampaignSMSConfigurationStepContentProps) {
  const { statuses, SafeLeaseAPI } = useReputation();
  const { watch } = useFormContext();

  /** Which email should the test send go to? */
  const [testPhoneNumber, setTestPhoneNumber] = useState<string>("");
  const [testTextSendStatus, setTestTextSendStatus] = useState<"idle" | "loading" | "success" | "error" | "invalid">("idle");
  const [confirmedPhoneNumberOwnership, setConfirmedPhoneNumberOwnership] = useState<boolean>(false);

  useEffect(() => {
    setTestTextSendStatus("idle");
  }, [testPhoneNumber]);

  /* Send a test email with whatever the current subjec/body is */
  const sendTextSMSMessage = async () => {
    // Format the phone number before sending
    const { formatted, e164 } = formatPhoneNumber(testPhoneNumber);

    // If the phone number is invalid, don't send the test email
    // and instead display a warning that the phone number seems to be invalid
    if (!isPossiblePhoneNumber(e164, "US")) {
      setTestTextSendStatus("invalid");
      return;
    }

    const testTemplateText = watch(`campaignSteps.${stepIndex}.templateText`);

    try {
      setTestTextSendStatus("loading");
      await SafeLeaseAPI.reputation.sendTestText({
        to: e164,
        body: testTemplateText,
        campaignId,
        campaignStepId: campaignStep.id,
      });

      setTestTextSendStatus("success");
    } catch (err) {
      setTestTextSendStatus("error");
    }
  };

  return (
    <>
      <Stack direction="row" spacing={2} alignItems="center" justifyContent="flex-start">
        <Box sx={{ width: 380 }}>
          <SafeLeaseTextField
            placeholder="Enter phone number"
            sx={{ bgcolor: "white" }}
            name="phone"
            value={formatPhoneNumber(testPhoneNumber).formattedNumber}
            onChange={(e) => setTestPhoneNumber(e.target.value)}
            disabled={testTextSendStatus === "loading" || statuses.campaignConfigurationForm}
          />
        </Box>
        <SafeLeaseButton
          variant="outlined"
          color="navy"
          size="small"
          sx={{ minWidth: 100, height: "100%" }}
          onClick={sendTextSMSMessage}
          disabled={statuses.campaignConfigurationForm || !confirmedPhoneNumberOwnership}
        >
          Send test
        </SafeLeaseButton>
      </Stack>
      <FormControlLabel
        control={<Checkbox />}
        value={confirmedPhoneNumberOwnership}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setConfirmedPhoneNumberOwnership(e.target.checked)}
        slotProps={{
          typography: {
            sx: { fontSize: 12, color: "grey.A100" },
          },
        }}
        label="I confirm that I am the owner of this phone number and I have the proper authorization to send and receive messages from this number."
      />
      {testTextSendStatus === "invalid" && (
        <Alert severity="warning" sx={{ bgcolor: alpha(red[50], 0.5), color: red[500] }}>
          The phone number you entered seems to be invalid. Please double check the number and try again.
        </Alert>
      )}
      {testTextSendStatus === "loading" && (
        <Alert severity="info" icon={<CircularProgress size={16} />} sx={{ bgcolor: blue[50], color: blue[500] }}>
          Sending test text...
        </Alert>
      )}
      {testTextSendStatus === "success" && (
        <Alert severity="success" sx={{ bgcolor: green[50], color: green[500] }} icon={<Check fontSize="inherit" />}>
          Test text sent to {formatPhoneNumber(testPhoneNumber).formattedNumber}.
        </Alert>
      )}
      {testTextSendStatus === "error" && (
        <Alert severity="error" sx={{ bgcolor: red[50], color: red[500] }} icon={<Error fontSize="inherit" />}>
          Failed to send test text
        </Alert>
      )}
    </>
  );
}
