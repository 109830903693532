import { useState } from 'react';
import SetupDataService from '../services/setup.service';
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import _ from 'lodash';
import { stateAbbr } from '../utilities/field-enums';

export default function LocationCreateModal(props) {
  const { relationshipId, onClose } = props;

  const [errors, setErrors] = useState<{
    name?: { msg?: string };
    address?: { msg?: string };
    city?: { msg?: string };
    state?: { msg?: string };
    postalCode?: { msg?: string };
  }>({});
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [name, setName] = useState<string | null>(null);
  const [address, setAddress] = useState<string | null>(null);
  const [city, setCity] = useState<string | null>(null);
  const [state, setState] = useState<string | null>(null);
  const [postalCode, setPostalCode] = useState<string | null>(null);

  const addLocation = async function () {
    setLoading(true);
    setErrors({});
    const response = await SetupDataService.addLocation({
      relationshipId,
      name,
      address,
      city,
      state,
      postalCode,
    });
    setLoading(false);

    if (response.data.errors) {
      setErrors(response.data.errors);
      setErrorMessage('A problem occurred while adding a location.');
      return;
    }

    onClose(response.data.location.id);
  };

  return (
    <Modal
      open={true}
      onClose={() => onClose()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="popup-modal"
    >
      <Box className="modal-box" style={{ width: 700, padding: 2 }}>
        <div className="tw-flex tw-justify-end">
          <IconButton>
            <CloseIcon onClick={() => onClose()} />
          </IconButton>
        </div>
        <div className="tw-flex tw-justify-center tw-pl-10 tw-pr-10">
          <strong>Create a Facility</strong>
        </div>

        <div className="tw-p-5">
          <TextField
            name="name"
            label={'Name'}
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            sx={{ marginBottom: 1, width: '100%' }}
            helperText={errors.name?.msg}
          />
          <TextField
            className="tw-w-full"
            name="address"
            label={'Address'}
            type="address"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            sx={{ marginBottom: 1, width: '100%' }}
            helperText={errors.address?.msg}
          />
          <Grid className="split-input-fields" container spacing={2}>
            <Grid item xs={4} className="input-grid">
              <TextField
                className="tw-w-full"
                name="city"
                label={'City'}
                type="city"
                value={city}
                onChange={(e) => setCity(e.target.value)}
                sx={{ marginBottom: 1, width: '100%' }}
                helperText={errors.city?.msg}
              />
            </Grid>
            <Grid item xs={4} className="input-grid">
              <FormControl fullWidth>
                <InputLabel id="state-selector-label">State</InputLabel>
                <Select
                  autoWidth
                  id="state-selector"
                  labelId="state-selector-label"
                  label="State"
                  name="state"
                  value={state}
                  onChange={(e) => setState(e.target.value)}
                >
                  {stateAbbr.map((state) => (
                    <MenuItem value={state}>{state}</MenuItem>
                  ))}
                </Select>
                <FormHelperText>{errors.state?.msg}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={4} className="input-grid">
              <TextField
                className="tw-w-full"
                name="postalCode"
                label={'Postal Code'}
                type="postalCode"
                value={postalCode}
                onChange={(e) => setPostalCode(e.target.value)}
                helperText={errors.postalCode?.msg}
              />
            </Grid>
          </Grid>

          <Box textAlign="center">
            {loading ? (
              <CircularProgress />
            ) : (
              <Button className="modal-login-button" onClick={addLocation}>
                Create
              </Button>
            )}
          </Box>

          {errorMessage && (
            <Box textAlign="center" marginTop="10px">
              <Alert severity="error">{errorMessage}</Alert>
            </Box>
          )}
        </div>
      </Box>
    </Modal>
  );
}
