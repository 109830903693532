import { useState, KeyboardEvent, MouseEvent } from 'react';
import { QueryResult } from '@apollo/client';
import { TableCell, TableRow } from '@mui/material';
import { muiTableCell } from '../styles/mui-overrides';
import { ColumnDef, isColumnHidden } from '../utilities/column-defs';
import { useTableStore } from '../utilities/table-state'
import { useAuth } from '../auth.js';
import { User } from '../utilities/generated/gql-types';


/**
 * @deprecated use SortingTableRowContents instead
 */
function SortingTableRowLegacy(props: {
    columns: ColumnDef[], 
    title: string,
    row: any, // TODO make custom row type
    queryResult?: QueryResult,
    onRowClick?: (e: KeyboardEvent<Element> | MouseEvent<Element, MouseEvent>) => void | null,
    subTableInfo?: { 
      component: (props: any) => JSX.Element, 
      queryResult: QueryResult,
      structureQuery: (id: number) => {[index: string]: any}
      grandChildQuery?: QueryResult
    },
    cellPadding?: string
}) {
  const {
    columns,
    title,
    row,
    onRowClick,
    queryResult,
    subTableInfo,
    cellPadding
  } = props;

  const [isRowHovered, setIsRowHovered] = useState<boolean>(false)
  const auth: {user: User} = useAuth();

  const { changeExpandedRow, expandedRowId, isRowExpanded, getSortDirectionByKey, activeColumnKeys } = 
      useTableStore(title, columns)(
        (state) => ({
          isRowExpanded: state.isRowExpanded,
          expandedRowId: state.expandedRowId,
          changeExpandedRow: state.changeExpandedRow,
          getSortDirectionByKey: state.getSortDirectionByKey,
          activeColumnKeys: state.activeColumnKeys,
        })
      )

  const toggleExpanded = async (e) => {
    if (!isRowExpanded(row.id)) {
      await subTableInfo.queryResult.refetch(subTableInfo.structureQuery(row.id))
      changeExpandedRow(row.id)
    } else {
      changeExpandedRow(null)
    }
  }

  return (
    <>
      <TableRow
          className="widget-table-row"
          onClick={ onRowClick && subTableInfo ? (e) => toggleExpanded(e) && onRowClick(e) :
              (onRowClick ? onRowClick as any : 
                  (subTableInfo ? toggleExpanded : (e) => null))}
          onMouseEnter={(e) => setIsRowHovered(true)}
          onMouseLeave={(e) => setIsRowHovered(false)}>
        {columns.map(column => {
          const { key, renderer, renderOptions } = column
          let value = row;
          for (const part of key.split('.')) {
            value = value[part];
          }

          let empty = value == null || value == undefined;
          
          return (
            <TableCell key={key}
                sx={
                  muiTableCell({
                    cellPadding: cellPadding ? `${cellPadding}px` : '9px',
                    sortDirection: getSortDirectionByKey(key),
                    isRowHovered,
                    isRowExpanded: isRowExpanded(row.id) && !!expandedRowId,
                    // TODO find out why this is necessary. SA-1259 and SA-1267
                    // are both related to this issue. Sometimes activeColumnKeys is
                    // undefined when it should not be. This results in a "cannot
                    // read property 'includes' of undefined" error.
                    // I suspect it has to do with <TableWrapper /> racing
                    // with the state of <SortingTable />. It has been observered on two
                    // suspiciously slow endpoints supporting the RelationshipsTable, 
                    // and the JobErrorTable 
                    isColumnHidden: 
                        isColumnHidden(column, auth.user.isAdmin, activeColumnKeys)
                  }) 
                }>
              {empty &&
                <>N/A</>
              }
              {!empty && !renderer && 
                <> {value} </>
              }
              {!empty && !!renderer &&
                (renderer(value, { row, queryResult }, renderOptions))
              }
            </TableCell>
          );
        })}
      </TableRow>
      {isRowExpanded(row.id) && !!expandedRowId &&
        <TableRow>
          <TableCell colSpan={columns.length}>
            {subTableInfo.component({
              queryId: row.id, 
              queryResult: subTableInfo.queryResult,
              grandChildQuery: subTableInfo.grandChildQuery
            })}
          </TableCell>
        </TableRow>
      }
    </>
  )
}

export default SortingTableRowLegacy;
