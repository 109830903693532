const mixpanel = require('mixpanel-browser')
const isProd = window.location.hostname.includes('app.safelease.com')
import apolloClient from "../utilities/apolloClient";
import { me } from '../queries'

const isLoggerEnabled = false;
const isDevelopmentTracked = false;
const isSafeleaseEmailTracked = true;

const mixpanelDevLogger = (msg) => {
  if (!isProd && isLoggerEnabled) console.log(`🟢 ${msg}`)
}

/* Send a defualt page view event to mixpanel */
export const mixpanelTrackPageViewHandler = (pageName: string) => {
  mixpanel.track_pageview({ page: pageName });
}

export const mixpanelIdentifyHandler = async () => { 
  try {
    if (!localStorage.getItem('user') || (!isProd && !isDevelopmentTracked)) return
    const meResult = await (await (apolloClient.query({ query: me }))).data.me
    if (!isSafeleaseEmailTracked && meResult?.email.includes('@safelease')) return
    
    if (meResult.relationshipId) {
      mixpanel.identify(meResult.id + '-' + meResult.relationshipId?.toString()); // DO NOT alter this - scopes MP user profiles to user/rel permissions combos
      const userProfileAndSuperProperties = {
        'Email': meResult.email, // title-cased so mixpanel email column populates
        'relationshipName': meResult.relationshipName,
        'userRole': meResult.role
      }
      const userProfileAndSuperPropertiesOnce = {
        'userId': meResult.id, // camel-cased properties by default to prevent conflation with properties like SL and MP-specific "user id"
        'relationshipId': meResult.relationshipId,
      }

      mixpanel.people.set_once(userProfileAndSuperPropertiesOnce); // Set user profile properties
      mixpanel.people.set(userProfileAndSuperProperties); 
      mixpanel.register_once(userProfileAndSuperPropertiesOnce); // Set super properties
      mixpanel.register(userProfileAndSuperProperties);
      mixpanelDevLogger('Mixpanel user identified: ' + meResult.email + ' for relationship: ' + meResult.relationshipId)
    } 
  } catch(e) {
    // Used to prevent 'me' query race condition in prod from causing Sentry errors. Does not impact usability/event tracking.
    console.log('mp id error:', e)
  }
}

// The event automatically includes a current URL with the full URL as automatic property - MP lets you filter on partials
mixpanel.init(isProd ? '92bd6b08b0dedf96e645dbd01766bb34' : 'a9cc8e735adf3eb3a1a3f075c28dcbe4', { debug: false })
mixpanelDevLogger('Initialized Mixpanel')
mixpanelIdentifyHandler()

export const mixpanelEventHandler = async (event: string, properties: object={}) => {
  if (!isProd && !isDevelopmentTracked) return
  
  let meResult;
  if(localStorage.getItem('user')) {
    meResult = await (await (apolloClient.query({ query: me }))).data.me;
  }

  if (!isSafeleaseEmailTracked && meResult?.email.includes('@safelease')) return

  if (meResult?.email.includes('@safelease')) {
    properties["internal"] = true
  } else {
    properties["internal"] = false
  }

  mixpanel.track(event, properties && { ...properties })
  mixpanelDevLogger('Mixpanel event tracked' + ': ' + event)
};

export const mixpanelReset = () => {
  mixpanel.reset()
  mixpanelDevLogger('MP reset')
}
