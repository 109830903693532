import { Box, Drawer } from "@mui/material";
import _ from "lodash";
import { SafeLeaseTab, SafeLeaseTabContent, SafeLeaseTabs } from "@safelease/components";
import { useState } from "react";
import { ReputationUser } from "@safelease/service-utilities";
import { EmployeeQRCodesTab } from "./EmployeeQRCodesTab";
import { EmployeeReviewsTab } from "./EmployeeReviewsTab";
import { EmployeeInfoCard } from "./EmployeeInfoCard";

enum EmployeeDrawerTab {
  REVIEWS = "reviews",
  QR_CODES = "qr_codes",
}

interface EmployeeDetailsDrawerProps {
  user: ReputationUser;
  open: boolean;
  handleClose: () => void;
}

/**
 * A drawer that houses the solicited reviews and QR codes for an employee
 */
export function EmployeeDetailsDrawer({ user, open, handleClose }: EmployeeDetailsDrawerProps) {
  const [tab, setTab] = useState<EmployeeDrawerTab>(EmployeeDrawerTab.REVIEWS);

  return (
    <Drawer open={open} onClose={handleClose} anchor="right" PaperProps={{ sx: { width: 700 } }}>
      <EmployeeInfoCard user={user} />
      <SafeLeaseTabs sx={{ mt: 2, borderBottom: "1px solid #EBEFF7" }} onChange={(_, newTab) => setTab(newTab)} value={tab}>
        <SafeLeaseTab label="Attributed Reviews" value={EmployeeDrawerTab.REVIEWS} sx={{ ml: 4 }} />
        <SafeLeaseTab label="Manage QR Codes / Referral Links" value={EmployeeDrawerTab.QR_CODES} sx={{ mr: 4 }} />
      </SafeLeaseTabs>
      <Box sx={{ position: "relative", height: "100%" }}>
        <SafeLeaseTabContent value={EmployeeDrawerTab.REVIEWS} currentTab={tab}>
          <EmployeeReviewsTab user={user} />
        </SafeLeaseTabContent>
        <SafeLeaseTabContent value={EmployeeDrawerTab.QR_CODES} currentTab={tab}>
          <EmployeeQRCodesTab user={user} />
        </SafeLeaseTabContent>
      </Box>
    </Drawer>
  );
}
