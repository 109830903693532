export const issueTypeLabels = {
  "claim-processing": "Claim Processing",
  "customer-service": "Customer Service",
  "ease-of-use": "Ease of Use",
  "legal-support": "Legal Support",
  "maintenance-repairs": "Maintenance & Repairs",
  "payment-processing": "Payment Processing",
  "policy-changes": "Policy Changes",
  "pricing-fees": "Pricing & Fees",
  "protection-clarity": "Protection Clarity",
  "response-time": "Response Time",
  "security-privacy": "Security & Privacy",
  "support-availability": "Support Availability",
  "tenant-disputes": "Tenant Disputes",
  "unit-cleanliness": "Unit Cleanliness",
  "web-app-functionality": "Web/App Functionality",
  other: "Other",
};
