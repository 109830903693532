import ServiceAbstract from './abstract';
import http from "./http-common";

class RelationshipDataService extends ServiceAbstract {
  save(relationship) {
    return http.post("/relationships/delete", relationship);
  }
  addRelationship(relationship) {
    return http.post("/relationships/add_relationship", relationship);
  }
  updateByField({ relationshipId, key, value }) {
    this.validateReason()
    return http.post("/relationships/update_by_field", { relationshipId, key, value }, this.config)
  }
  updateGracePeriod({ relationshipId, locationId, gracePeriod }) {
    return http.post("/relationships/update_grace_period", { relationshipId, locationId, gracePeriod });
  }
  updateSalesforceSync({ relationshipId, salesforceSync }) {
    return http.post("/relationships/update_salesforce_sync", { relationshipId, salesforceSync });
  }
  generateMonthlyPartnerPolicy({ relationshipId, source = 'FRONTEND' }) {
    return http.post("/relationships/generate_monthly_partner_policy", { relationshipId, source });
  }
}

export default new RelationshipDataService();
