import { Table, TableBody, TableContainer } from "@mui/material";
import SortingTableHead from "./sorting-table-head-virtualized";
import { TableWrapperProps } from "./table-wrapper";
import SortingTableHeadLegacy from "./sorting-table-head-legacy";

/**
 * @deprecated use TableWrapper with virtualized=true TableWrapperVirtualized or SafeleaseDataGrid instead
 */
export default function TableWrapperLegacy({
  isSubTable,
  columns,
  title,
  children,
  preTable,
  cellPadding,
  sx,
}: TableWrapperProps) {
  return (
    <div
      className={`${
        isSubTable ? "tw-border-solid tw-border-[#CBDFF6] tw-rounded-[4px]" : ""
      }`}
    >
      <div className="widget widget__long">
        <div className="widget-content">
          {preTable}
          <TableContainer sx={{ overflowX: "initial", ...sx }}>
            <Table stickyHeader>
              <SortingTableHeadLegacy
                columns={columns}
                tableTitle={title}
                cellPadding={cellPadding}
              />
              <TableBody>{children}</TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  );
}
