import React from "react";
import { FlexRowBox, FlexColumnBox, StyledTextField, RowTitleText, RowSubTitleText, TextFieldLabel } from "../MyAccountStyledComponents";
import { CustomSelect, CustomSelectContent } from "../../OrganizationSettings/CustomSelect";
import { SelectChangeEvent } from "@mui/material";

interface NameAndTitleSectionProps {
  fullName: string;
  jobTitle: string;
  jobPosition: string; // will be twilio Enum eventually
  handleTextFieldChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSelectChange: (event: SelectChangeEvent, targetProperty: string) => void;
}

// TODO: Replace with TwilioJobPosition enum in the types directory
enum TwilioJobPosition {
  CEO = "CEO",
  CFO = "CFO",
  VP = "VP",
  GM = "GM",
  DIRECTOR = "Director",
  GENERAL_COUNSEL = "General Counsel",
  OTHER = "Other",
}

const twilioJobPositionMenuItems = Object.values(TwilioJobPosition).map((jobPosition) => {
  return { value: jobPosition, label: jobPosition };
});

const NameAndTitleSection: React.FC<NameAndTitleSectionProps> = ({
  fullName,
  jobTitle,
  jobPosition,
  handleTextFieldChange,
  handleSelectChange,
}) => {
  return (
    <FlexRowBox>
      <FlexColumnBox
        sx={{
          width: "149px",
        }}
      >
        <RowTitleText>Name & Title</RowTitleText>
        <RowSubTitleText>User details</RowSubTitleText>
      </FlexColumnBox>

      {/* Full Name */}
      <FlexColumnBox
        sx={{
          marginLeft: "80px",
        }}
      >
        <TextFieldLabel>Full name</TextFieldLabel>
        <StyledTextField id="fullName" onChange={handleTextFieldChange} value={fullName} />
      </FlexColumnBox>

      {/* Job Title */}
      <FlexColumnBox
        sx={{
          marginLeft: "12px",
        }}
      >
        <TextFieldLabel>Job title</TextFieldLabel>
        <StyledTextField id="jobTitle" onChange={handleTextFieldChange} value={jobTitle} />
      </FlexColumnBox>

      {/* Job Position */}
      <FlexColumnBox
        sx={{
          marginLeft: "12px",
        }}
      >
        <TextFieldLabel>Job position</TextFieldLabel>
        <CustomSelect
          value={jobPosition}
          onChange={(e) => handleSelectChange(e, "jobPosition")}
          menuItems={twilioJobPositionMenuItems}
          sx={{
            height: "40px",
            width: "194px",
            ".MuiOutlinedInput-notchedOutline": {
              borderColor: jobPosition ? "inherit" : "#E9645F",
              backgroundColor: jobPosition ? "inherit" : "rgba(233, 100, 95, 0.1)",
            },
          }}
          displayEmpty
          renderValue={(jobPosition: string) => {
            return (
              <CustomSelectContent value={jobPosition} valueColor="#031E30" marginLeft="0px" infoText={"required for reputation sms"} />
            );
          }}
        ></CustomSelect>
      </FlexColumnBox>
    </FlexRowBox>
  );
};

export default NameAndTitleSection;
