// UI - external
import SafeleaseDataGrid from "../../shared/safelease-data-grid";
import { GridAggregationFunction, GridColDef } from "@mui/x-data-grid-premium";

// UI - internal
import { Formatters } from "../../utilities/formatters";

interface SkuConfig {
  rows: object[];
  columns: Set<number>;
}

interface TenantSKUMatrixProps {
  skuConfig: SkuConfig;
}

export function TenantSKUMatrix({
  skuConfig
}: TenantSKUMatrixProps) {
  
  const getSkuDataGridColumns: () => GridColDef[] = () => {

    return [
      /* Premium column */ 
      {
        field: 'premium',
        minWidth: 200,
        headerName: 'Plan Amount',
        valueFormatter: (params) => Formatters.penniesToDollars(params.value),
      },

      /* Coverage columns */
      ...Array.from(skuConfig.columns).sort((a, b) => a - b).map((coverageAmountPennies) => ({
        field: coverageAmountPennies.toString(),
        headerName: Formatters.penniesToDollars(coverageAmountPennies),
        flex: 1,
        type: 'number',
        renderCell: (params) => params.value === 0 ? null : params.value
      }))
    ]
  }

  return (
    <SafeleaseDataGrid
      columns={getSkuDataGridColumns()}
      rows={skuConfig.rows}
      initialState={{
        sorting: {
          sortModel: [{ field: 'premium', sort: 'asc' }]
        },
      }}
      sx={{ border: 'none' }}
    />
  );
}
