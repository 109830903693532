export function upgradeAlgorithm(
  premium: number,
  coverage: number,
  buckets = [
    { coverage: 200000, premium: 1200 }, // $12 for $2,000 of coverage
    { coverage: 300000, premium: 1700 }, // $17 for $3,000 of coverage
    { coverage: 500000, premium: 2700 }, // $27 for $5,000 of coverage
  ]
) {
  for (let i = 0; i < buckets.length - 1; i++) {
    const currentBucket = buckets[i];
    const nextBucket = buckets[i + 1];

    if (coverage < nextBucket.coverage) {
      // If the coverage is less than the next bucket
      return {
        coverage: currentBucket.coverage,
        premium: Math.max(currentBucket.premium, premium),
      };
    } else if (i < buckets.length - 2) {
      continue;
    }

    return {
      coverage: buckets[buckets.length - 1].coverage,
      premium: Math.max(buckets[buckets.length - 1].premium, premium),
    };
  }

  return {
    coverage: buckets[buckets.length - 1].coverage,
    premium: Math.max(buckets[buckets.length - 1].premium, premium),
  };
}
