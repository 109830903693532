import React from "react";
import { Typography, Paper, PaperProps, styled } from "@mui/material";
import LaptopIcon from "@mui/icons-material/Laptop";
import FiberIcon from "@mui/icons-material/FiberManualRecord";
import InfoIcon from "@mui/icons-material/InfoOutlined";

// The 'status icon' for the FMS. If the FMS is present, the icon will be green. If the FMS is not present, the icon will be red.
const StyledFiberIcon = styled(FiberIcon)<{ fms?: string }>(({ fms }) => ({
  color: fms ? "#25C280" : "#E9645F",
  fontSize: "10px",
  marginRight: "4px",
}));

// The 'laptop icon' for the FMS. This icon is displayed in between the status icon and the FMS. 
const StyledLaptopIcon = styled(LaptopIcon)({
  color: "#031E30",
  fontSize: "24px",
  marginRight: "4px",
});

// Text used to display the FMS.
const FmsText = styled(Typography)({
  color: "#031E30",
  fontSize: "12px",
});

// The 'info icon' for the FMS. This icon is displayed to the right of the FMS.
const StyledInfoIcon = styled(InfoIcon)({
  fontSize: "16px",
  marginLeft: "2px",
});

interface FmsPaperProps extends PaperProps {
  fms?: string;
  showLaptopIcon?: boolean;
  showInfoIcon?: boolean;
}

// This component is used to display the FMS for each location row. 
const FmsPaper: React.FC<FmsPaperProps> = ({
  fms,
  showLaptopIcon = false,
  showInfoIcon = false,
  ...rest
}) => {
  return (
    <Paper elevation={0} {...rest}>
      <StyledFiberIcon fms={fms} />
      {showLaptopIcon && <StyledLaptopIcon />}
      <FmsText variant="body1">
        {fms || "N/A"}
      </FmsText>
      {showInfoIcon && <StyledInfoIcon />}
    </Paper>
  );
};

export default FmsPaper;
