import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import SalesforceDataServices from "../services/salesforce.service";
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import RelationshipDataVisualizer from "./RelationshipDataVisualizer";
import OpportunityDataVisualizer from "./OpportunityDataVisualizer";
import LocationDataVisualizer from "./LocationDataVisualizer";
import LlcDataVisualizer from "./LlcDataVisualizer";
import NoChangesDetectedCard from "./NoChangesDetectedCard";
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import { useSnackbar } from "notistack";
import { checkForSyncChanges, constructSyncDataForOpportunity } from "./helper-functions/salesforceHelpers";
import DOMPurify from 'dompurify';
import ConfirmDialog from "./sharedComponents/ConfirmDialog";

const SyncOnOpportunityId = () => {
  const params: { opportunityId: string; } = useParams();
  const [syncDataForOpportunity, setSyncDataForOpportunity] = useState<SyncDataForOpportunity | null>(null);
  const [relationshipData, setRelationshipData] = useState<RelationshipSyncMetaData | null>(null);
  const [llcs, setLlcs] = useState<LlcSyncMetaData[] | null>(null);
  const [locations, setLocations] = useState<LocationSyncMetaData[] | null>(null);
  const [getSyncDataLoading, setGetSyncDataLoading] = useState<boolean>(false);
  const [syncRequestLoading, setSyncRequestLoading] = useState<boolean>(false);
  const [tryAgainShown, setTryAgainShown] = useState<boolean>(false);
  const [opportunityData, setOpportunityData] = useState<OpportunitySyncMetaData | null>(null);
  const { enqueueSnackbar } = useSnackbar();
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [confirmSync, setConfirmSync] = useState<boolean>(false);

  const getDesiredSyncDataForOpportunity = async () => {
    setGetSyncDataLoading(true);
    let opLocIds: string[];
    try {
      const response = await SalesforceDataServices.getOpLocationIdsForOpportunity({ opportunityId: params.opportunityId });
      opLocIds = response.data;
    } catch (error) {
      enqueueSnackbar(error.response.data.error, { variant: "error", autoHideDuration: 10000 });
      setTryAgainShown(true);
    }
    try {
      const syncDataForOpportunity = await constructSyncDataForOpportunity(opLocIds);
      setRelationshipData(checkForSyncChanges(syncDataForOpportunity.relationship) ? syncDataForOpportunity.relationship : null);
      setLlcs(syncDataForOpportunity.llcs.filter((llcData: LlcSyncMetaData) => checkForSyncChanges(llcData)));
      setLocations(syncDataForOpportunity.locations.filter((locationData: LocationSyncMetaData) => checkForSyncChanges(locationData)));
      setOpportunityData(checkForSyncChanges(syncDataForOpportunity.opportunity) ? syncDataForOpportunity.opportunity : null);
      setSyncDataForOpportunity(syncDataForOpportunity);
      setTryAgainShown(false);
    } catch (error) {
      let errorMessage = error.toString();
      const sanitizedHtml = DOMPurify.sanitize(errorMessage);
      enqueueSnackbar(<div dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />, { variant: "error", autoHideDuration: 10000 });
      setTryAgainShown(true);
    }
    setGetSyncDataLoading(false);
  };

  useEffect(() => {
    getDesiredSyncDataForOpportunity();
  }, []);

  const handleConfirmDialogOpen = () => {
    if (locations && locations.some(location => location.createNew)) {
      setDialogOpen(true);
    } else {
      performDataSync();
    }
  };

  const handleConfirmDialogClose = () => {
    setDialogOpen(false);
  };

  const handleConfirmSync = async () => {
    setDialogOpen(false);
    setConfirmSync(true);
  };

  useEffect(() => {
    if (confirmSync) {
      performDataSync();
      setConfirmSync(false);
    }
  }, [confirmSync]);

  const performDataSync = async () => {
    setSyncRequestLoading(true);
    try {
      // these objects will keep track of all salesforceLlcIds that have been created so far and map the database id so that we do not make multiple objects in our backend for the same salesforce object
      // do not need to track syncedLocations because multiple oplocation with the same location object is not allowed and is caught when constructing the sync data
      const syncedRelationships = {};
      const syncedLlcs = {};
      for(const syncDataForOpportunityLocation of syncDataForOpportunity.opLocationSyncDataList){
        let syncedRelationshipId = null;
        let syncedLlcId = null;
        if (syncedRelationships[syncDataForOpportunityLocation.relationship.salesforceId]) syncedRelationshipId = syncedRelationships[syncDataForOpportunityLocation.relationship.salesforceId];
        if (syncedLlcs[syncDataForOpportunityLocation.llc.salesforceId]) syncedLlcId = syncedLlcs[syncDataForOpportunityLocation.llc.salesforceId];

        const performSyncForOpportunityLocationResponseObject : PerformSyncForOpportunityLocationResponseObject = {
          ...syncDataForOpportunityLocation,
          syncedRelationshipId,
          syncedLlcId
        }

        const response = await SalesforceDataServices.performDataSyncForOpportunityLocation(performSyncForOpportunityLocationResponseObject);

        if(response.data.relationship) syncedRelationships[response.data.relationship.salesforceId] = response.data.relationship.relationshipId;
        if(response.data.llc) syncedLlcs[response.data.llc.salesforceId] = response.data.llc.llcId;
      }
    } catch (error) {
      enqueueSnackbar(error.response?.data?.error ? error.response.data.error : "error syncing data" , { variant: "error" });
    }
    enqueueSnackbar('Successfuly synced data.', { variant: "success" });
    setSyncRequestLoading(false);
    getDesiredSyncDataForOpportunity();
  };

  if (getSyncDataLoading) return <CircularProgress />;
  if (tryAgainShown) return (
    <div>
      <h5>Failed to retrieve sync data</h5>
      <Button sx={{ marginTop: "15px" }} variant='contained' onClick={getDesiredSyncDataForOpportunity}>
        Try again
      </Button>
    </div>
  );

  if (!relationshipData && !llcs?.length && !locations?.length && !opportunityData) return (
    <h5>
      No changes detected to sync
    </h5>
  );

  return (
    <div>
      <div>
        <h3>Relationship</h3>
        {
          relationshipData ?
            (
              <RelationshipDataVisualizer relationshipData={relationshipData} />
            ) :
            (
              <NoChangesDetectedCard />
            )
        }
      </div>
      <div>
      <h3>Opportunity</h3>
          {
              opportunityData ?
              <OpportunityDataVisualizer opportunityData={opportunityData} />
              :
              <NoChangesDetectedCard />
          }
      </div>
      <div>
        <h3>LLC</h3>
        {
          llcs?.length ?
            (
              <>
                {
                  llcs.map((llcData) => (
                    <LlcDataVisualizer key={llcData.salesforceId} llcData={llcData} />
                  ))
                }
              </>
            ) :
            (
              <NoChangesDetectedCard />
            )
        }
      </div>
      <div>
        <h3>Location</h3>
        {
          locations?.length ?
            (
              <>
                {
                  locations.map((locationData) => (
                    <LocationDataVisualizer key={locationData.salesforceId} locationData={locationData} />
                  ))
                }
              </>
            ) :
            (
              <NoChangesDetectedCard />
            )
        }
      </div>
      <LoadingButton sx={{ marginTop: "15px" }} color="error" loading={syncRequestLoading} variant='contained' onClick={handleConfirmDialogOpen}>
        <span>Sync data</span>
      </LoadingButton>

      <ConfirmDialog
        open={dialogOpen}
        title="Create New Location"
        description="We are going to create a new location based on the address in the FMS, please confirm you want to do this."
        onClose={handleConfirmDialogClose}
        onConfirm={handleConfirmSync}
      />
    </div>
  );
};

export default SyncOnOpportunityId;
