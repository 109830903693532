// UI - libs
import {
  Replay as ReplayIcon,
  Check as CheckIcon,
  Warning as WarningIcon,
} from "@mui/icons-material";
import { GridActionsCellItem } from "@mui/x-data-grid-premium";

// Data
import JobsDataService from "../../services/jobs.service";
import { JobError } from "../../utilities/generated/gql-types";
import { QueryResult } from "@apollo/client";

interface JobErrorActionsParams {
  jobId: string;
  jobError: JobError;
  jobErrorQuery: QueryResult<{ getJobsErrorReport: JobError[] }>;
  history: {
    push(url: string): void;
  };
}

export default function getJobErrorActions({
  jobId,
  jobError,
  jobErrorQuery,
  history,
}: JobErrorActionsParams) {
  const handleRetry = () => {
    history.push(`/admin/retry/${jobId}`);
  };

  const markAsComplete = async () => {
    if (!window.confirm("Are you sure you want to mark this job as complete?"))
      return;
    await JobsDataService.completeJob(jobId);
    await jobErrorQuery.refetch();
  };

  const handleOverrideSafetyMechanism = async () => {
    if (
      !window.confirm(
        `Are you sure you want to rerun this job and override the safety mechanism?\n${jobError.errorNotes}`
      )
    )
      return;
    await JobsDataService.overrideSafeties(jobId);
    await jobErrorQuery.refetch();
  };

  const menuItems = [];

  // Always display retry job
  menuItems.push(
    <GridActionsCellItem
      showInMenu
      icon={<ReplayIcon />}
      label="Retry Job"
      onClick={handleRetry}
    />
  );

  if (jobError.method === "setup") {
    menuItems.push(
      <GridActionsCellItem
        showInMenu
        icon={<CheckIcon />}
        label="Mark as Complete"
        onClick={markAsComplete}
        sx={{
          color: "darkorange",
          "&:hover": { backgroundColor: "darkorange", color: "white" },
        }}
      />
    );
  }

  if (["autoEnroll", "rollover"].includes(jobError.method)) {
    menuItems.push(
      <GridActionsCellItem
        showInMenu
        icon={<WarningIcon />}
        label="Override Safety Mechanism"
        onClick={handleOverrideSafetyMechanism}
        sx={{
          color: "red",
          "&:hover": { backgroundColor: "red", color: "white" },
        }}
      />
    );
  }

  return menuItems;
}
