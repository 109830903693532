import { GridToolbarContainer, GridToolbarExport } from "@mui/x-data-grid-premium";
import dayjs from "dayjs";

interface CustomToolbarProps {
    fileNamePrefix: string;
}

const CustomToolbar: React.FC<CustomToolbarProps> = ({ fileNamePrefix }) => {
    return (
      <GridToolbarContainer>
        <GridToolbarExport 
          csvOptions={{
            disableToolbarButton: false,
            fileName: `${fileNamePrefix}-${dayjs().format("YYYY-MM-DD-HH-mm-ss")}`,
          }}
          printOptions={{
            disableToolbarButton: true
          }}
        />
      </GridToolbarContainer>
    );
}

export default CustomToolbar;