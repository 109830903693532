import { Modal, Typography, Card, Stack, Box, IconButton, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import RouterHelper from "../utilities/router-helper";
import { useQuery } from "@apollo/client";
import { AgencyAppointmentCertification } from "../utilities/generated/gql-types";
import { getAgencyAppointmentCertificationForRelationship } from "../queries";
import { useAuth } from "../auth";
import { Close } from "@mui/icons-material";
import dayjs from "dayjs";
import * as Sentry from "@sentry/react";
import { enqueueSnackbar } from "notistack";
import agencyAppointmentCertificationService from "../services/agencyAppointmentCertification.service";
import { LoadingButton } from "@mui/lab";

type AgencyAppointmentCertificationModalProps = {
  routerHelper: RouterHelper;
};

function AgencyAppointmentCertificationModal({ routerHelper }: AgencyAppointmentCertificationModalProps) {
  const auth = useAuth();
  const [isVisible, setIsVisible] = useState(false);
  const [signRequestLoading, setSignRequestLoading] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const relationshipId = routerHelper.getRelationshipId();

  const { loading, error, data } = useQuery<{ getAgencyAppointmentCertificationForRelationship: AgencyAppointmentCertification }>(
    getAgencyAppointmentCertificationForRelationship,
    { variables: { relationshipId } },
  );

  useEffect(() => {
    /**
     * Only show the modal if the user is a manager, the signature does not exist, and the named insured and policy number are present.
     */
    if (
      !loading &&
      !error &&
      !data?.getAgencyAppointmentCertificationForRelationship.signatureExists &&
      auth.user?.role === "manager" &&
      data?.getAgencyAppointmentCertificationForRelationship.namedInsured &&
      data?.getAgencyAppointmentCertificationForRelationship.policyNumber
    ) {
      setIsVisible(true);
    }
  }, [data, error, auth.user, loading]);

  const handleSignButtonClick = async () => {
    if (!firstName.trim() || !lastName.trim()) {
      enqueueSnackbar("Please fill out all of the fields before agreeing.", { variant: "error", autoHideDuration: 1500 });
      return;
    }

    const capitalizedFirstName = firstName.trim().charAt(0).toUpperCase() + firstName.trim().slice(1);
    const capitalizedLastName = lastName.trim().charAt(0).toUpperCase() + lastName.trim().slice(1);
    const sanitizedName = `${capitalizedFirstName} ${capitalizedLastName}`;

    try {
      setSignRequestLoading(true);
      await agencyAppointmentCertificationService.signAgencyAppointmentCertification({
        partnerPolicyDocumentId: data?.getAgencyAppointmentCertificationForRelationship.partnerPolicyDocumentId,
        signatureName: sanitizedName,
        relationshipId,
      });
      setSignRequestLoading(false);
      setIsVisible(false);
    } catch (error) {
      console.log(error);
      enqueueSnackbar("Error signing the certificate.", { variant: "error", autoHideDuration: 1500 });
      Sentry.captureException(error);
      setSignRequestLoading(false);
    }
  };

  return (
    // the conditions in the open prop are important to prevent the modal from being open when the query is loading or there is an error.
    <Modal open={isVisible} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
      <Card sx={{ width: 1200, height: 700 }}>
        <Stack padding={1} height="100%">
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <IconButton onClick={() => setIsVisible(false)}>
              <Close />
            </IconButton>
          </Box>
          <Stack paddingX={5} paddingBottom={5} overflow="auto" spacing={3}>
            <Stack alignItems="center" spacing={1}>
              <Typography sx={{ fontWeight: 700, fontSize: 19 }}>APPOINTMENT AND AUTHORIZATION CERTIFICATE</Typography>
              <Typography>
                Facility Owner/Operator: <b>{data?.getAgencyAppointmentCertificationForRelationship.namedInsured}</b>
              </Typography>
              <Typography>
                Policy Number: <b>{data?.getAgencyAppointmentCertificationForRelationship.policyNumber}</b>
              </Typography>
            </Stack>
            <Stack alignItems="center" spacing={4}>
              <Typography>
                1. <b>Agent.</b> The Facility Owner or Facility Operator (“Insured”), which is named as the insured or additional insured on
                the policy bearing the policy number above (the “Policy”), authorizes SafeLease Insurance Services LLC (the “Agency”) to act
                as the Facility Owner’s agent for the purpose of managing the Policy and claims thereon.{" "}
              </Typography>
              <Typography>
                2. <b>Access and Use Right.</b> The Insured understands and agrees that to administer the Policy, and to adjust claims on
                the Policy, the Agency will access and use the software systems used by the Insured to risk manage one or more of their
                self-storage facilities.
              </Typography>
              <Typography>
                3. <b>Compliance.</b> Insured appoints the Agency as an authorized user within the software systems to take actions on its
                behalf to ensure compliance with the Policy and all applicable insurance laws and regulations. Such actions may include, but
                are not limited to, data retrieval or entry to accurately adjust claims, measure and modify coverage under the Policy,
                actions in connection with insurance regulation or applicable laws.
              </Typography>
              <Typography>
                4. <b>Software Systems.</b> The software systems that the Agency is designated and authorized to use include but are not
                limited to the following: SiteLink, Cubby, StorEDGE, Monument, Easy Storage Solutions, Web Self Storage, and Self Storage
                Manager.
              </Typography>
              <Typography>
                5. <b>Term and Scope.</b> The foregoing authorizations shall remain in effect so long as the Insured and the Agency are
                parties to a Storage Partner Agreement that is in effect, or so long as there is an active Policy issued to the Insured. For
                the avoidance of doubt, nothing in this certificate amends or supersedes any terms in the Storage Partner Agreement. In the
                event of any conflict between the terms of this agreement and the Storage Partner Agreement, the terms of the Storage
                Partner Agreement shall control.
              </Typography>
            </Stack>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Stack spacing={1}>
                <Stack direction="row" spacing={1} alignItems="flex-end">
                  <Typography>First name:</Typography>
                  <TextField
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    autoFocus
                    inputProps={{ style: { height: 2 }, maxLength: 125 }}
                  />
                </Stack>
                <Stack direction="row" spacing={1} alignItems="flex-end">
                  <Typography>Last name:</Typography>
                  <TextField
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    inputProps={{ style: { height: 2 }, maxLength: 125 }}
                  />
                </Stack>
                <Typography>Authorized Representative, Insured</Typography>
                <Typography>Date: {dayjs().format("MM/DD/YYYY")}</Typography>
                <LoadingButton
                  loading={signRequestLoading}
                  onClick={handleSignButtonClick}
                  size="small"
                  disableElevation
                  variant="contained"
                >
                  Agree
                </LoadingButton>
              </Stack>
            </Box>
          </Stack>
        </Stack>
      </Card>
    </Modal>
  );
}

export { AgencyAppointmentCertificationModal };
