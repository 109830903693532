import React, { useEffect, useState, useRef, useMemo } from "react";

// UI - Libs
import {
  Button,
  Box,
  Grid,
} from "@mui/material";
import { blackButton } from "../../../styles/mui-overrides";
import AddIcon from "@mui/icons-material/Add";

// UI - internal
import PrimaryContactCards from "./PrimaryContactCards";
import UsersTable from "./UsersTable";
import { SafeleaseSearchBar } from "../../../shared/safelease-search.bar";

// Hooks & data
import RouterHelper from "../../../utilities/router-helper";

// Utils
import _, { debounce } from "lodash";
import { EditMode } from "../../../shared/drawer-footer";
import { useUserManagementSettings } from "./useUserManagementSettings";
import RelationshipSelector from "../../../shared/RelationshipSelector";
import AccessControlled from "../../../components/AccessControlled";

interface UserSettingsProps {
  routerHelper: RouterHelper;
}

export default function UserSettings({ routerHelper }: UserSettingsProps) {

  const { setEditDrawerOpenMode, searchValue, setSearchValue } = useUserManagementSettings();

  const handleInputChange = (value: string) => {
    setSearchValue(value);
  };

  const debouncedSearch = useMemo(() => {
    return debounce(handleInputChange, 300);
  }, []);

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  });

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <RelationshipSelector routerHelper={routerHelper} />
          <Box
            sx={{
              display: "flex",
              flexGrow: 1,
              alignItems: "center",

            }}
          >
            <SafeleaseSearchBar
              defaultValue={"Search"}
              searchValue={searchValue}
              setSearchValue={debouncedSearch}
              onSubmitCallback={() => handleInputChange(searchValue)}
            />
            <AccessControlled type="Relationship" id={routerHelper.getRelationshipId()} permission="update">
              <Button
                sx={{
                  ...blackButton,
                  marginLeft: "auto",
                  backgroundColor: "#152744",
                }}
                startIcon={<AddIcon />}
                onClick={() => setEditDrawerOpenMode(EditMode.Add)}
              >
              New User
            </Button>
            </AccessControlled>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} container spacing={2}>
        <PrimaryContactCards routerHelper={routerHelper} />
      </Grid>
      <Grid item xs={12}>
        <UsersTable routerHelper={routerHelper} searchValue={searchValue} />
      </Grid>
    </Grid>
  );
}
