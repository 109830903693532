import { Card, Stack, Typography } from "@mui/material";
import ModelValueRenderer from "./ModelValueRenderer";

const RelationshipDataVisualizer = (props: { relationshipData: RelationshipSyncMetaData; }) => {
  const { relationshipData } = props;
  const dataToMap = relationshipData.existingId ? relationshipData.existingData : relationshipData.newData;

  return (
    <div>
      <Card style={{ padding: "20px", display: "inline-block", marginBottom: "10px" }}>
        <div style={{ marginBottom: "15px" }}>
          {relationshipData.existingId && (
            <h5>{`Updates for relationshipId - ${relationshipData.existingId}:`}</h5>
          )}
          {relationshipData.createNew && (
            <h5>This relationship does not exist yet. This will create a new relationship with this data:</h5>
          )}
        </div>
        <Stack sx={{ paddingLeft: "10px" }} spacing={2}>
          {
            Object.keys(dataToMap).map((key) => {
              return (
                <Stack sx={{ padding: "15px", border: "0.5px solid #c7c7c7" }} key={key} spacing={2} direction='row' alignItems="center">
                  <Typography sx={{ fontWeight: "bold", fontSize: 20 }}>{key}:</Typography>
                  <ModelValueRenderer value={relationshipData.existingData[key]} sx={{ fontSize: 20 }} />
                  <Typography sx={{ fontWeight: "bold", fontSize: 20 }}>{'-->'}</Typography>
                  <ModelValueRenderer value={relationshipData.newData[key]} sx={{ color: "#b52a21", fontSize: 20, fontWeight: "bold" }} />
                </Stack>
              );
            }
            )
          }
        </Stack>
      </Card>
    </div>
  );
};

export default RelationshipDataVisualizer;
