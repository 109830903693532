import { muiSortButton } from '../styles/mui-overrides';
import { IconButton } from '@mui/material';
import { SortDirection, useTableStore } from '../utilities/table-state';
import UnfoldMore from '@mui/icons-material/UnfoldMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

function Sorter(props: {
    columnKey: string, 
    tableTitle: string,
  }) {

  const { columnKey, tableTitle } = props;

  const { updateSortedColumn, sortedColumn } = useTableStore(tableTitle)(
    (state) => ({
      updateSortedColumn: state.updateSortedColumn,
      sortedColumn: state.sortedColumn 
    })
  )

  return (
    <>
      {(!sortedColumn || sortedColumn.key != columnKey) &&
        <IconButton sx={muiSortButton({isSorted: false})} 
            onClick={() => updateSortedColumn(columnKey)}>
          <UnfoldMore/>
        </IconButton>
      }
      {sortedColumn?.key == columnKey && 
          sortedColumn.direction === SortDirection.Forward &&
        <IconButton sx={muiSortButton({isSorted: true})} 
            onClick={() => updateSortedColumn(columnKey)}>
          <ExpandLess/>
        </IconButton>
      }
      {sortedColumn?.key == columnKey && 
          sortedColumn.direction === SortDirection.Reverse &&
        <IconButton sx={muiSortButton({isSorted: true})} 
            onClick={() => updateSortedColumn(columnKey)}>
          <ExpandMore/>
        </IconButton>
      }
    </>
  );
}

export default Sorter;
