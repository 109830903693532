import { QueryResult, useQuery } from "@apollo/client";
import React from "react";
import { getRelationship, getUserById, getUsers } from "../../../queries";
import { Relationship, User } from "../../../utilities/generated/gql-types";
import { useAuth } from "../../../auth";
import { Grid } from "@mui/material";
import ContactCard from "./ContactCard";
import { Roles } from "../../../utilities/field-enums";

interface PrimaryContactCardsProps {
  routerHelper: any;
}

export default function PrimaryContactCards({ routerHelper }) {
  const auth = useAuth();
  const relationshipId =
    routerHelper.getRelationshipId() || auth.user.relationshipId;

  const usersQuery: QueryResult<{ getUsers: { edges: User[] } }> = useQuery(
    getUsers,
    {
      variables: {
        relationshipId,
      },
    }
  );

  const relationshipQuery: QueryResult<{ getRelationship: Relationship }> =
    useQuery(getRelationship, {
      variables: { id: relationshipId },
    });

  const users: User[] = usersQuery.data?.getUsers.edges || [];
  const csmUserId = relationshipQuery.data?.getRelationship?.csmUserId;
  const amUserId = relationshipQuery.data?.getRelationship?.amUserId;

  const customerServiceManagerContactQuery: QueryResult<{ getUserById: User }> =
    useQuery(getUserById, {
      variables: { id: csmUserId, relationshipId },
      skip: !csmUserId,
    });

  const accountManagerContactQuery: QueryResult<{ getUserById: User }> =
    useQuery(getUserById, {
      variables: { id: amUserId, relationshipId },
      skip: !amUserId,
    });

  // Find the non-employee user in the relationship with the lowest id
  // For the time being, this represents the default primary contact for the relationship
  // in the future we will add the ability to switch the primary contact
  const relationshipContact = users.reduce((minIdObject, obj) => {
    if (obj.role === Roles.Employee) return minIdObject; 

    if (!minIdObject || parseInt(obj.id) < parseInt(minIdObject.id)) {
      return obj;
    }

    return minIdObject;
  }, null);

  return (
    <>
      <Grid item xs={4}>
        <ContactCard
          user={relationshipContact}
          label="Primary Contact"
          loading={relationshipQuery.loading}
        />
      </Grid>
      <Grid item xs={4}>
        <ContactCard
          user={customerServiceManagerContactQuery.data?.getUserById}
          label="SafeLease Onboarding Specialist"
          loading={customerServiceManagerContactQuery.loading}
        />
      </Grid>
      <Grid item xs={4}>
        <ContactCard
          user={accountManagerContactQuery.data?.getUserById}
          label="SafeLease Account Manager"
          loading={accountManagerContactQuery.loading}
        />
      </Grid>
    </>
  );
}
