// UI - external
import {
  Box,
  Button,
  Grid,
  Paper,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import { RestartAlt } from "@mui/icons-material";
import { green, grey } from "@mui/material/colors";

// UI - internal
import MetricHighlightCard from "../MetricComponents/MetricHighlightCard";
import { CalendlyButton } from "../../shared/CalendlyButton";
import { TenantSKUMatrix } from "./TenantSKUMatrix";
import { ghostButtonNavy } from "../../styles/mui-overrides";
import { PricingReportCoveragePlanSlider } from "./PricingReportCoverageSlider";

// Hooks
import { useEffect, useMemo, useState } from "react";
import { useReportsStore } from "../useReportsStore";

// Data
import { getGeneralUnits } from "../../queries";
import { QueryResult, useQuery } from "@apollo/client";
import { upgradeAlgorithm } from "./upgradeAlgorithms";
import { GeneralUnit } from "../../utilities/generated/gql-types";

// Utilities
import { Formatters } from "../../utilities/formatters";
import RouterHelper from "../../utilities/router-helper";

interface TenantProtectionActivityReportProps {
  routerHelper: RouterHelper;
}

/* Show a report that breaks down the current SKUs for a relationship and
 * includes configuration to demonstrate change in revenue with plan price increases */
export function TenantProtectionActivityReport({
  routerHelper,
}: TenantProtectionActivityReportProps) {
  const relationshipId = useReportsStore((state) => state.relationshipId);
  const locations = useReportsStore((state) => state.locations);

  const selectedLocationsInReport = useReportsStore(
    (state) => state.selectedLocationsInReport
  );

  const [defaultBucketSizes, setDefaultBucketSizes] = useState<number[]>([
    1200, 1700, 2700,
  ]);

  useEffect(() => {
    if (locations[0]?.protectionLevelValues) {
      const firstLocation = locations[0];
      setDefaultBucketSizes(firstLocation.protectionLevelValues.map(value => value.premium));
    }
  }, [locations]);

  /* Each of the individual bucket premiums */
  const [premiumBucket2000, setPremBucket2000] = useState<number>(1200);
  const [premiumBucket3000, setPremBucket3000] = useState<number>(1700);
  const [premiumBucket5000, setPremBucket5000] = useState<number>(2700);

  const [showBeforeOrAfter, setShowBeforeOrAfter] = useState<
    "before" | "after"
  >("before");

  const premiumBuckets = [
    { coverage: 200000, premium: premiumBucket2000 },
    { coverage: 300000, premium: premiumBucket3000 },
    { coverage: 500000, premium: premiumBucket5000 },
  ];

  const generalUnitsQuery: QueryResult<{ getGeneralUnits: GeneralUnit[] }> =
    useQuery(getGeneralUnits, {
      variables: {
        relationshipId,
        occupied: true,
        policyType: "safelease",
        report: "upgradePlans"
      },
    });
  
  /* General units, filtered to only include the selected locations */
  const generalUnits = useMemo(() => {
    return generalUnitsQuery?.data?.getGeneralUnits?.filter((unit) => selectedLocationsInReport.includes(unit.locationId)) ?? [];
  }, [generalUnitsQuery?.data?.getGeneralUnits, selectedLocationsInReport]);

  const totalCurrentPremium = useMemo(() => {
    /* Skip if not ready */
    if (generalUnits.length === 0) return 0;

    const totalPremium = generalUnits.reduce(
      (acc, unit) => acc + unit.premium,
      0
    );

    return totalPremium;
  }, [generalUnits]);

  const totalUpgradedPremium = useMemo(() => {
    /* Skip if not ready */
    if (generalUnits.length === 0) return 0;

    const totalPremium = generalUnits.reduce(
      (acc, unit) =>
        acc +
        upgradeAlgorithm(unit.premium, unit.coverage, premiumBuckets).premium,
      0
    );

    return totalPremium;
  }, [generalUnits, premiumBuckets]);

  /* Get the columns, rows, and total units for the SKU matrix and impacted units */
  const skuConfig = (variant: "before" | "after") => {
    const columns = new Set<number>();
    const premiums = new Set<number>();
    const premiumMap: {
      [premium: number]: { premium: number; [coverage: number]: number };
    } = {};
    let impactedUnits = 0;

    // Add premiums and columns from generalUnits and premiumBuckets
    generalUnits.forEach(({ premium, coverage }) => {
      if (premium && coverage) {
        premiums.add(premium);
        columns.add(coverage);
      }
    });

    premiumBuckets.forEach(({ premium, coverage }) => {
      if (premium && coverage) {
        premiums.add(premium);
        columns.add(coverage);
      }
    });

    // Initialize rows for each premium
    premiums.forEach((premium: number) => {
      premiumMap[premium] = { premium: premium };
    });

    // Process generalUnits
    generalUnits.forEach((unit) => {
      const adjustedUnit =
        variant === "before"
          ? unit
          : upgradeAlgorithm(unit.premium, unit.coverage, premiumBuckets);

      const unitHasChanges =
        unit.premium !== adjustedUnit.premium ||
        unit.coverage !== adjustedUnit.coverage;

      if (unitHasChanges) impactedUnits++;

      if (adjustedUnit.premium && adjustedUnit.coverage) {
        // Initialize coverage count if not present
        premiumMap[adjustedUnit.premium][adjustedUnit.coverage] =
          (premiumMap[adjustedUnit.premium][adjustedUnit.coverage] || 0) + 1;
      }
    });

    // Create rows from the premiumMap
    const rows = Object.values(premiumMap).map((row) => ({
      id: row.premium,
      ...row,
    }));

    return { rows, columns, impactedUnits };
  };

  /* State of the unit coverage/premium before any changes */
  const skuConfigBefore = useMemo(
    () => skuConfig("before"),
    [generalUnits, premiumBuckets]
  );

  /* State of the unit coverage/premium after any changes */
  const skuConfigAfter = useMemo(
    () => skuConfig("after"),
    [generalUnits, premiumBuckets]
  );

  /* Put the sliders / config back to the current state */
  const resetPlansToExisting = () => {
    setPremBucket2000(defaultBucketSizes[0]);
    setPremBucket3000(defaultBucketSizes[1]);
    setPremBucket5000(defaultBucketSizes[2]);
  };

  return (
    <Paper
      elevation={0}
      sx={{
        p: 2,
        border: "1px solid #EBEFF7",
        boxShadow: "0 0 20px rgba(0, 0, 0, 0.05)",
        overflow: "hidden",
        borderRadius: "8px",
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} lg={6}>
          <MetricHighlightCard
            label="Make more revenue with price increases"
            secondaryText="Increase your plan amounts"
            chipLabel="Revenue opportunity"
            variant="success"
            value={totalUpgradedPremium - totalCurrentPremium}
            valueFormatter={Formatters.penniesToDollars}
            action={
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
              >
                <CalendlyButton
                  relationshipId={relationshipId}
                  variant="filled"
                  label="Increase Plan Amounts"
                  linkName="planUpgrades"
                />
                <Typography variant="h6" sx={{ color: green[600] }}>
                  {Formatters.penniesToDollars(totalUpgradedPremium).slice(
                    0,
                    -3
                  )}{" "}
                  per month total
                </Typography>
              </Stack>
            }
          />
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <MetricHighlightCard
            label="Number of Impacted Units"
            secondaryText="Units that will be impacted by the price increase"
            chipLabel="Delta"
            variant="info"
            value={skuConfigAfter.impactedUnits}
            valueFormatter={Formatters.formatWithCommas}
            action={
              <CalendlyButton
                relationshipId={relationshipId}
                linkName="planUpgrades"
              />
            }
          />
        </Grid>
        <Grid item xs={12} md={12} lg={4}>
          <Paper
            elevation={0}
            sx={{
              border: "1px solid #EBEFF7",
              height: "100%",
              p: 2,
            }}
          >
            <Stack direction="column" spacing={1}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ mb: 1 }}
              >
                <Typography sx={{ fontWeight: "bold" }}>
                  Plan Amounts
                </Typography>
                <Button
                  sx={{
                    ...ghostButtonNavy,
                    border: "none",
                  }}
                  size="small"
                  onClick={resetPlansToExisting}
                  startIcon={<RestartAlt sx={{ height: 16, width: 16 }} />}
                >
                  Reset to default
                </Button>
              </Stack>
              <PricingReportCoveragePlanSlider
                coverage={200000}
                existingValue={defaultBucketSizes[0]}
                value={premiumBucket2000}
                setValue={setPremBucket2000}
                min={900}
                max={1600}
                marks={[
                  {
                    value: defaultBucketSizes[0],
                    label: (
                      <Stack direction="column">
                        <Typography variant="caption">Default</Typography>
                        <Typography variant="caption">
                          {Formatters.penniesToDollars(defaultBucketSizes[0])}
                        </Typography>
                      </Stack>
                    ),
                  },
                ]}
              />
              <PricingReportCoveragePlanSlider
                coverage={300000}
                existingValue={defaultBucketSizes[1]}
                value={premiumBucket3000}
                setValue={setPremBucket3000}
                min={1500}
                max={2500}
                marks={[
                  {
                    value: defaultBucketSizes[1],
                    label: (
                      <Stack direction="column">
                        <Typography variant="caption">Default</Typography>
                        <Typography variant="caption">
                          {Formatters.penniesToDollars(defaultBucketSizes[1])}
                        </Typography>
                      </Stack>
                    ),
                  },
                ]}
              />
              <PricingReportCoveragePlanSlider
                coverage={500000}
                existingValue={defaultBucketSizes[2]}
                value={premiumBucket5000}
                setValue={setPremBucket5000}
                min={2200}
                max={5000}
                marks={[
                  {
                    value: defaultBucketSizes[2],
                    label: (
                      <Stack direction="column">
                        <Typography variant="caption">Default</Typography>
                        <Typography variant="caption">
                          {Formatters.penniesToDollars(defaultBucketSizes[2])}
                        </Typography>
                      </Stack>
                    ),
                  },
                ]}
              />
            </Stack>
          </Paper>
        </Grid>
        <Grid item xs={12} md={12} lg={8}>
          <Paper
            elevation={0}
            sx={{ border: "1px solid #EBEFF7", height: 496 }}
          >
            <Stack direction="column" sx={{ p: 2 }} spacing={2}>
              <Typography sx={{ fontWeight: "bold" }}>
                Unit Breakdown by Plan Amount / Protection Limit
              </Typography>
              <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
              >
                <Typography
                  sx={{
                    p: 1,
                    borderRadius: 2,
                    bgcolor:
                      showBeforeOrAfter === "before" ? grey[100] : "initial",
                    color:
                      showBeforeOrAfter === "before" ? grey[700] : "initial",
                    fontWeight:
                      showBeforeOrAfter === "before" ? "bold" : "initial",
                  }}
                >
                  Before upgrade
                </Typography>
                <Switch
                  checked={showBeforeOrAfter === "after"}
                  color="success"
                  onChange={() =>
                    setShowBeforeOrAfter(
                      showBeforeOrAfter === "before" ? "after" : "before"
                    )
                  }
                />
                <Typography
                  sx={{
                    p: 1,
                    borderRadius: 2,
                    bgcolor:
                      showBeforeOrAfter === "after" ? green[50] : "initial",
                    color:
                      showBeforeOrAfter === "after" ? green[700] : "initial",
                    fontWeight:
                      showBeforeOrAfter === "after" ? "bold" : "initial",
                  }}
                >
                  After upgrade
                </Typography>
              </Stack>
            </Stack>
            <Box sx={{ height: 382 }}>
              <TenantSKUMatrix
                skuConfig={
                  showBeforeOrAfter === "before"
                    ? skuConfigBefore
                    : skuConfigAfter
                }
              />
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Paper>
  );
}
