import { Check } from "@mui/icons-material";
import { Avatar, Divider, Rating, Stack, Theme, Typography } from "@mui/material";
import { SafeLeaseAvatarWithText, SafeLeaseButton, SafeLeaseCard } from "@safelease/components";
import { Contact_Reputation as Contact, Review } from "@safelease/service-utilities";
import dayjs from "dayjs";

interface FacilityReviewCardProps {
  review: Review;
  contact: Contact;
}

/* Displays a card with a CTA to leave a review or displays a response to a review */
export function FacilityReviewCard({ review, contact }: FacilityReviewCardProps) {
  return (
    <SafeLeaseCard sx={{ p: 2, width: "100%" }}>
      <Stack direction="column" spacing={2}>
        <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
          <SafeLeaseAvatarWithText text={contact.name} />
          <Typography variant="body2" color="grey.A100">
            {review.actualDate ? dayjs(review.actualDate).format("MMM D, YYYY") : review.date}
          </Typography>
        </Stack>
        <Rating value={review.rating} readOnly />
        <Typography variant="body2">{review.reviewSnippet}</Typography>
        {!review.responseSnippet && (
          <Stack direction="row" justifyContent="flex-end">
            <SafeLeaseButton variant="contained" color="navy" onClick={() => window.open(review.link, "_blank")}>
              Respond
            </SafeLeaseButton>
          </Stack>
        )}
      </Stack>
      {review.responseSnippet && <Divider sx={{ my: 4 }} />}
      {review.responseSnippet && (
        <Stack direction="column" spacing={2} sx={{ pl: 6 }}>
          <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
            <Stack direction="row" spacing={1} alignItems="center">
              <Avatar src={contact.facility?.logoUrlSmall} />
              <Typography variant="subtitle2">{contact.facility?.companyName}</Typography>
            </Stack>
            <Typography variant="body2" color="grey.A100">
              <Check sx={{ color: (theme: Theme) => theme.palette.green.main }} /> Replied{" "}
              {review.actualDate ? dayjs(review.actualDate).format("MMM D, YYYY") : review.date}
            </Typography>
          </Stack>
          <Typography variant="body2">{review.responseSnippet}</Typography>
          <Stack direction="row" justifyContent="flex-end" alignItems="center" sx={{ mt: 2 }}>
            <SafeLeaseButton variant="outlined" color="navy" onClick={() => window.open(review.link, "_blank")}>
              View on Google
            </SafeLeaseButton>
          </Stack>
        </Stack>
      )}
    </SafeLeaseCard>
  );
}
