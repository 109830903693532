import ServiceAbstract from "./abstract";
import http from "./http-common";

class JobsDataService extends ServiceAbstract{
  status(jobId, format = 'json') {
    return http.post("/jobs/status", {jobId: jobId, format: format});
  }

  completeJob(jobId){
    return http.post("/jobs/complete_job", {jobId: jobId});
  }

  overrideSafeties(jobId) {
    this.validateReason();
    return http.post("/jobs/override_safeties", { jobId: jobId }, this.config);
  }

  updateAssignee(jobId, assigneeId) {
    return http.post("/jobs/update_assignee", { jobId, assigneeId }, this.config);
  }
}

export default new JobsDataService();
