export default class ServiceAbstract {
  // config is a axios config options object
  config: {
    headers?: object
  };
  
  constructor() {
    this.config = {};
  }

  validateReason() {
    const reason = window.prompt('Please enter a reason.');
    if (!reason) {
      window.alert('No action taken. You must enter a reason.');
      return;
    }

    this.config = {
      headers: { 'x-safelease-reason': reason }
    }
  }
}