// UI - libs
import { Button, Card, Grid, Stack } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-premium";

// UI - internal
import { muiRedButton } from "../../styles/mui-overrides";
import TitleHeader from "../../shared/title-header";
import Error from "../../shared/Error";
import Loader from "../../shared/Loader";
import SafeleaseDataGrid from "../../shared/safelease-data-grid";
import { jobsErrorReportGridColDefs } from "./jobsErrorCenterColumnDefs";
import JobErrorDetailsDrawer from "./JobErrorDetailsDrawer/JobErrorDetailsDrawer";
import JobsErrorCenterMetricCard from "./JobsErrorCenterMetricCard";
import { navyButton } from "../../styles/mui-overrides";

// Hooks & State
import { useQuery } from "@apollo/client";
import { useTableStore } from "../../utilities/table-state";
import { useJobsErrorCenter } from "./useJobsErrorCenter";
import { useAuth } from "../../auth";

// Data
import { getJobsErrorReport } from "../../queries";
import { JobError } from "../../utilities/generated/gql-types";
import { legacyJobsErrorReportColumnDefs } from "../../utilities/column-defs";

// Libs
import _ from "lodash";
import dayjs from "dayjs";
import { useEffect } from "react";
import useWindowSize from "../../utilities/useWindowSize";
import { useHistory } from "react-router-dom";
import AccessControlled from "../../components/AccessControlled";

// Report summarizing the errors experienced in jobs
// Meat is in the table
export default function JobsErrorCenter({ routerHelper }) {
  const auth = useAuth();
  const size = useWindowSize();
  const history = useHistory();

  const {
    visibleColumns,
    updateVisibleColumns,
    selectedJobError,
    setSelectedJobError,
    shouldUseFilterModel,
  } = useJobsErrorCenter();

  const jobsErrorReportQuery = useQuery(getJobsErrorReport, {
    onCompleted: (data) => {
      // After a refetch, if we've selected a job error we should update
      // the selected job error with the latest data
      // this should really only apply when we do updates in the drawer
      const currentSelectedJobErrorId = selectedJobError?.id;

      if (currentSelectedJobErrorId) {
        const updatedSelectedJobError = data.getJobsErrorReport.find(
          (jobError: JobError) => jobError.id === currentSelectedJobErrorId,
        );
        setSelectedJobError(updatedSelectedJobError);
      }
    },
  });

  const jobs = jobsErrorReportQuery.data?.getJobsErrorReport || [];

  const tableTitle = "Jobs Error Center";

  // Todo: probably remove after upgrading to data grid premium
  const { exportJobsErrorCsv, setRows } = useTableStore(
    tableTitle,
    legacyJobsErrorReportColumnDefs,
  )((state) => ({
    exportJobsErrorCsv: state.exportCsv,
    // rows: state.rows,
    setRows: state.setRows,
  }));

  useEffect(() => {
    setRows(jobs);
  }, [JSON.stringify(jobs)]);

  if (jobsErrorReportQuery.error) return <Error />;
  if (jobsErrorReportQuery.loading) return <Loader />;

  const jobsAssignedToMe = jobs.filter(
    (error: JobError) => error.assignee?.name === auth.user?.name,
  );

  return (
    <div
      style={{
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <TitleHeader title={tableTitle} />
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Button
          sx={muiRedButton()}
          onClick={() => exportJobsErrorCsv(tableTitle)}
        >
          Export
        </Button>
        <AccessControlled
          controlBy="feature"
          featureFlag="engineering-error-report"
          id="engineering-error-report"
          routerHelper={routerHelper}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={() =>
              history.push("/admin/tools/engineering_error_report")
            }
            disableElevation
            sx={{ ...navyButton }}
          >
            Engineering Error Report
          </Button>
        </AccessControlled>
      </Stack>
      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <JobsErrorCenterMetricCard
            value={jobsAssignedToMe.length}
            label="Errors assigned to me"
            showButton={jobsAssignedToMe.length > 0}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <JobsErrorCenterMetricCard
            value={
              jobs.filter(
                (error: JobError) => error.triagingStatus === "Critical",
              ).length
            }
            label="Critical errors"
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <JobsErrorCenterMetricCard
            value={
              jobs.filter(
                (error: JobError) =>
                  error.triagingStatus === "Critical" ||
                  error.triagingStatus === "Medium",
              ).length
            }
            label="Jobs in failure state"
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <JobsErrorCenterMetricCard
            value={
              jobs.filter((error: JobError) => {
                const dateInstance = dayjs(error.failedAt);
                const daysOld = dayjs().diff(dateInstance, "day");
                return daysOld > 10;
              }).length
            }
            label="Errors over 10 days old"
          />
        </Grid>
        <Grid item xs={12}>
          <Card
            sx={{
              height: size.height - (jobsAssignedToMe.length > 0 ? 400 : 360),
            }}
            elevation={0}
          >
            <SafeleaseDataGrid
              clickable
              columns={jobsErrorReportGridColDefs}
              rows={shouldUseFilterModel ? jobsAssignedToMe : jobs}
              autosizeOnMount
              rowProps={{ queryResult: jobsErrorReportQuery, history }}
              getRowHeight={() => "auto"}
              editMode="cell"
              onRowClick={(params) => {
                const { queryResult, ...rest } = params.row; // remove queryResult from the row data to avoid circular reference error
                setSelectedJobError(rest);
              }}
              columnVisibilityModel={visibleColumns}
              onColumnVisibilityModelChange={(newModel) =>
                updateVisibleColumns(newModel)
              }
              initialState={{
                pinnedColumns: {
                  right: ["id"],
                },
              }}
            />
          </Card>
        </Grid>
      </Grid>
      <JobErrorDetailsDrawer />
    </div>
  );
}
