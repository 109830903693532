import { useState } from "react";
import {
  Autocomplete,
  Avatar,
  Box,
  CircularProgress,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Popover,
  TextField,
} from "@mui/material";
import { useQuery } from "@apollo/client";
import _ from "lodash";
import { getUsers } from "../../../queries";
import LetterAvatar from "../../../shared/LetterAvatar";
import { useAuth } from "../../../auth";
import { User } from "../../../utilities/generated/gql-types";

interface AssignmentFieldProps {
  assignee: any;
  onReassign: (assignee: any) => void;
  loading: boolean;
}

/* Avatar with a dropdown to select a user in the SafeLease relationship */
export function AssignmentField({
  assignee,
  onReassign,
  loading = false,
}: AssignmentFieldProps) {
  const auth = useAuth();
  /* Keep track of the current selection in the menu */
  const [selected, setSelected] = useState<object>(assignee);

  /* Keep track of the previous selction, for avatar button */
  const [lastSelected, setLastSelected] = useState<object>(assignee);

  /* Keep track of the search value */
  const [inputValue, setInputValue] = useState<string>(assignee?.name ?? "");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  /* Fetch all users in SafeLease */
  const getUsersQuery = useQuery(getUsers, {
    variables: {
      relationshipId: 271,
    },
  });

  const safeLeaseUsers = getUsersQuery?.data?.getUsers?.edges || [];

  /* Picking a person */
  const handleSelect = (value) => {
    setSelected(value);
    setLastSelected(value);
    handleClose();
    onReassign(value);
  };

  const handleOpenMenu = (event) => {
    handleStopPropagation(event);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleStopPropagation = (event) => {
    event?.stopPropagation();
  };

  /* Hide current user if they're the selected user */
  const shouldIncludeCurrentUser =
    Number(selected?.id) !== Number(auth.user.id);

  const availableOptions = safeLeaseUsers.filter((user) => {
    if (inputValue !== "" && selected === null) {
      /* If the search string matches part of the name */
      const nameContainsPartOfInputValue = user.name
        .toLowerCase()
        .includes(inputValue.toLowerCase().trim());
      if (nameContainsPartOfInputValue) {
        return true;
      } else {
        return false;
      }
    }

    if (shouldIncludeCurrentUser) {
      return true;
    }

    return Number(user.id) !== Number(auth.user.id);
  });

  return (
    <>
      <IconButton onClick={handleOpenMenu} disabled={loading}>
        {loading ? (
          <CircularProgress size={40} />
        ) : (
          <LetterAvatar name={assignee?.name} />
        )}
      </IconButton>
      <Popover
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClick={handleStopPropagation}
        onClose={handleClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <Box sx={{ p: 0.5, width: 240 }}>
          <Autocomplete
            options={availableOptions}
            sx={{ width: 216 }}
            value={selected}
            getOptionLabel={(option: User) =>
              assignee?.name?.length > 0 ? option.name : "Unassigned"
            }
            inputValue={inputValue}
            clearOnBlur={false}
            onInputChange={(_, newInputValue) => setInputValue(newInputValue)}
            onFocus={() => {
              setInputValue("");
              setSelected(null);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                variant="outlined"
                sx={{ m: 1 }}
                InputProps={{
                  sx: {
                    px: 1,
                  },
                  startAdornment:
                    selected === null ? (
                      <Avatar
                        sx={{ mr: 1, height: 30, width: 30, fontSize: "14px" }}
                      />
                    ) : (
                      <LetterAvatar
                        name={assignee?.name}
                        sx={{ height: 30, width: 30, mr: 1, fontSize: "14px" }}
                      />
                    ),
                }}
              />
            )}
          />
        </Box>
        <Divider sx={{ color: "#152744" }} />
        <Box
          sx={{
            height: Math.min(availableOptions.length * 40 + 20, 220),
            overflow: "scroll",
          }}
        >
          {availableOptions.map((user) => (
            <MenuItem
              key={user.id}
              sx={{
                borderLeft: "2px solid transparent",
                height: 40,
                "&:hover": {
                  borderLeft: "2px solid rgba(40, 121, 251, 1)",
                },
              }}
              onClick={(event) => handleSelect(user)}
            >
              <ListItemIcon>
                <LetterAvatar
                  name={user?.name}
                  sx={{ mr: 1, height: 32, width: 32, fontSize: "14px" }}
                />
              </ListItemIcon>
              <ListItemText primary={user.name ? user.name : "something"} />
            </MenuItem>
          ))}
        </Box>
      </Popover>
    </>
  );
}
