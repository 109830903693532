// UI - libs
import { Box, Card, IconButton, Stack, SxProps, Tooltip, Typography } from "@mui/material";

// UI - internal
import { amber, green } from "@mui/material/colors";
import { Formatters } from "../../utilities/formatters";
import { InfoOutlined } from "@mui/icons-material";

type StatisticCardVariants = "micro" | "regular";

export interface StatisticCardProps {

  /* Label for the card, in the upper left corner */
  label: string;
  
  /* Base value to display */ 
  value: number;
  
  /* Value to represent the denominator in a fractional representation */
  outOfValue?: number;

  /* String formatter for the value */
  formatter?: (value: number) => string | number;
  
  /* Hide denominator in fractional representations */ 
  hideDenominator?: boolean;

  /* If true, flip color scheme for percent */
  inverse?: boolean;
  
  /* Size of the card */
  variant?: StatisticCardVariants;

  /* Override props for text */ 
  textSx?: SxProps;

  /* Hide icon in bottom right corner */
  hideIcon?: boolean;

  /* If empty, don't display an info tooltip */
  tooltipText?: string;
}

export default function StatisticCard({
  value = 0,
  outOfValue,
  formatter,
  hideDenominator = false,
  label,
  variant = "regular",
  tooltipText
}: StatisticCardProps) {
  /* If a prevValue is provided, yield the appropriate color
   * for the percent change display. */
  const getTextColor = () => {
    if (!outOfValue || isNaN(value)) return "initial";
    if (value / outOfValue >= 0.8) return green[500];
    if (value / outOfValue >= 0.5) return amber[500];
    return "rgba(233, 100, 95, 1)";
  };

  return (
    <Card
      elevation={0}
      sx={{
        p: 1,
        border: "1px solid #EBEFF7",
        height: "100%",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
      }}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mb: 2 }}>
        <Typography
          variant={variant === "micro" ? "caption" : "subtitle1"}
          sx={{ color: "rgba(21, 39, 68, 1)", fontFamily: "Open Sans"}}
        >
          {label}
        </Typography>
        {tooltipText && (
          <Tooltip title={tooltipText}>
            <IconButton size="small">
              <InfoOutlined sx={{ height: 20, width: 20, color: 'rgba(201, 201, 201, 1)' }} />
            </IconButton>
          </Tooltip>
        )}
      </Stack>
      <Box>
        <Stack direction="row" justifyContent="space-between">
          <Typography
            variant={variant === "micro" ? "h4" : "h3"}
            sx={{
              color: getTextColor()
            }}
          >
            {value !== undefined ? (formatter ? formatter(value) : value) : "-"}
            {outOfValue !== undefined && outOfValue !== 0 && !hideDenominator && (
              <span style={{ fontSize: "14px" }}>{` / ${Formatters.formatWithCommas(outOfValue)}`}</span>
            )}
          </Typography>
        </Stack>
      </Box>
    </Card>
  );
}
