import { useAuth } from "../../auth";

// Wrapper component that only renders its children if the user is a partner
export const PartnersOnly = ({ children }: { children: React.ReactNode }) => {
  const auth = useAuth();

  if (auth.user.isAdmin) {
    return null;
  }

  return <>{children}</>;
};
