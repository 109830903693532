import SafeleaseWrapper from '../shared/safelease-wrapper';
import { ReportType } from '../utilities/field-enums';
import Report from './reports/report';
import RouterHelper from '../utilities/router-helper';
import { useState } from 'react';
import TitleHeader from '../shared/title-header';
import { reportDefs, ReportDef } from './reports/report-defs';
import { SafeleaseDropdown } from '../common';
import { Link, useHistory, useParams } from 'react-router-dom';
import Loader from '../shared/Loader';
import { muiSelect } from '../styles/mui-overrides';
import { useReportQueryParams } from './hooks';
import { LinearProgress, SelectChangeEvent } from '@mui/material';
import { useAuth } from '../auth'
import { mixpanelEventHandler } from '../utilities/reactMixpanelHandler';
import { NetworkStatus, QueryResult, useQuery } from '@apollo/client';
import { getLocations } from '../queries';
import { Location } from '../utilities/generated/gql-types';
import Error from '../shared/Error';
import BarChartIcon from '@mui/icons-material/BarChart';
import { Formatters } from '../utilities/formatters';

function Insights(props: { routerHelper: RouterHelper }) {
  const { routerHelper } = props;
  const auth = useAuth();
  const history = useHistory();
  const queryParams = useReportQueryParams();
  const { relationshipId: paramsRelationshipId = null } = useParams<{relationshipId: string}>();
  const relationshipId = paramsRelationshipId || routerHelper?.getRelationshipId() || auth.user?.relationshipId;
  const [reportDef, setReportDef] = useState<ReportDef>(
    reportDefs.find(
      r => r.reportTypeOption.queryParam === (queryParams.get('reportType') || 'dailySummary')
    )
  );

  const locations: QueryResult<{ getLocations: Location[] }> = useQuery(getLocations, {
    variables: { relationshipId },
  });

  const navigateToReport = (e: SelectChangeEvent): void => {
    mixpanelEventHandler('Insights - Report Picker');
    queryParams.set(
      'reportType',
      reportDefs.find(r => r.reportTypeOption.value === e.target.value).reportTypeOption.queryParam
    );
    const newReportDef = reportDefs.find(r => r.reportTypeOption.value === e.target.value);

    // if we are navigating TO anything besides the IP report, we should take out the endDate
    if (e.target.value !== 'InsurancePenetration') queryParams.delete('endDate');

    // if we are navigating TO the insurance penetration report, we should set the startDate to the current month but day 1
    if (e.target.value === 'InsurancePenetration' && queryParams.has('startDate')) {
      let date = new Date(queryParams.get('startDate'));
      date.addMinutes(date.getTimezoneOffset());
      date.setDate(1);
      queryParams.set('startDate', date.toISOString().split('T')[0]);
    }

    queryParams.set('startDate', newReportDef.defaultQueryDate.startDate.toISOString().split('T')[0]);
    setReportDef(newReportDef);
    routerHelper.navigateToRoute('insights', relationshipId, history, null, queryParams.toString());
  };

  if (!reportDef || (locations.loading && locations.networkStatus !== NetworkStatus.refetch)) return <Loader />;
  if (locations.error) return <Error />;

  // Only supported locations should be visible in insights.
  let visibleLocations = locations.data.getLocations.filter(location => location.accountType != 'unsupported');

  // ignore the demo locations from the uningested locations since they will never actually be ingested
  const isIngested = (location) => (location.lastIngestedAt || location.relationshipId === 271);

  const ingestedLocations = visibleLocations.filter(location => isIngested(location));
  const uningestedLocationsCount = visibleLocations.length - ingestedLocations.length;

  // Refetch for the uningested locations every 30 seconds
  async function refetchUningestedLocations() {
    while (true) {
      await new Promise((r) => setTimeout(r, 30000));
      locations.refetch();
    }
  }
  if (uningestedLocationsCount > 0) refetchUningestedLocations();

  return (
    <SafeleaseWrapper>
      <TitleHeader title="Insights" />
      {uningestedLocationsCount > 0 && (
        <>
          {ingestedLocations.length === 0 && (
            <div className="tw-flex tw-flex-row tw-items-center tw-text-3xl tw-font-bold tw-mb-4">
              <BarChartIcon sx={{ fontSize: '5rem'}} />
              Gathering business intelligence and adding facilities
            </div>
          )}
          <div className="tw-flex tw-flex-col tw-mb-10 tw-ml-4 tw-mr-4">
            <div className="tw-flex tw-flex-row tw-mb-2">
              {ingestedLocations.length} of {visibleLocations.length} {visibleLocations.length !== 1 ? 'facilities' : 'facility'} completed data ingestion. {Formatters.formatMinutesToPrettyString(10 * uningestedLocationsCount)} estimated time remaining.
            </div>
          <LinearProgress variant="determinate" value={(ingestedLocations.length / visibleLocations.length) * 100} />
          </div>
        </>
      )}
      {auth.user.isAdmin || ingestedLocations.length > 0 ? (
        // TODO: safelease dropdown is jacked up and needs an overhaul or replacement component
        <Report
          reportSelector={
            <SafeleaseDropdown
              selectStyles={{ ...muiSelect, width: 'auto', maxWidth: '11.5rem'}}
              defaultValue={ReportType.DailySummary}
              value={reportDef.reportTypeOption.value}
              menuOptionList={reportDefs.map(reportDef => reportDef.reportTypeOption.value)}
              onChange={e => navigateToReport(e)}
              fullWidth={false}
            />
          }
          reportDef={reportDef}
          routerHelper={routerHelper}
          relationshipId={relationshipId}
          isIngested={isIngested}
        />
      ) : (
        <div className="tw-flex tw-flex-col tw-p-4">
          <div className="tw-flex tw-flex-row tw-justify-center tw-mb-6">
            Want to add more facilities? You can do this in your&nbsp;<Link to='/settings/locations'>Settings</Link>.
          </div>
          <div className="tw-flex tw-flex-row tw-items-center">
            <div className="tw-flex tw-flex-col tw-w-1/4">
              <div className="tw-flex tw-flex-row tw-justify-center tw-font-bold tw-text-xl tw-mb-2">Free product offering</div>
              <div className="tw-flex tw-flex-col tw-text-white tw-bg-[#2879fb] tw-rounded tw-p-2">
                <div className="tw-flex tw-flex-row tw-justify-center tw-font-bold tw-pb-3">
                  Explore our business insights tool
                </div>
                <div className="tw-flex tw-flex-row tw-text-center">
                  Offering complimentary business intelligence to help you uncover opportunities to grow your business.
                </div>
              </div>
            </div>
            <div className="tw-flex tw-flex-col tw-w-1/12" />
            <div className="tw-flex tw-flex-col tw-w-2/3">
              <div className="tw-flex tw-flex-row tw-justify-center tw-font-bold tw-text-xl tw-mb-2">Partnering with us</div>
              <div className="tw-flex tw-flex-row tw-text-white">
                <div className="tw-flex tw-flex-col tw-w-1/2 tw-bg-[#ec504a] tw-rounded-l tw-p-2">
                  <div className="tw-flex tw-flex-row tw-justify-center tw-font-bold tw-pb-3">
                    Maximum tenant protection
                  </div>
                  <div className="tw-flex tw-flex-row tw-text-center">
                    Superior risk coverage and automated program management result in ~92% tenant participation and less work for your store manager.
                  </div>
                </div>
                <div className="tw-flex tw-flex-col tw-w-1/2 tw-bg-[#152744] tw-rounded-r tw-p-2">
                  <div className="tw-flex tw-flex-row tw-justify-center tw-font-bold tw-pb-3">
                    Industry-leading revenue share
                  </div>
                  <div className="tw-flex tw-flex-row tw-text-center">
                    SafeLease offers the highest percentage of program revenue in the industry, averaging 70%+.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </SafeleaseWrapper>
  );
}
export default Insights;
