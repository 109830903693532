import { ghostButtonNavy, muiRedButton } from "../styles/mui-overrides"
import { Button, Modal } from '@mui/material'
import { claimNotesEditingStateAtom } from './claim-details-drawer'
import { useAtom } from 'jotai'
import { useResetAtom } from 'jotai/utils'

const UnsavedChangesModal = (props: { exit: () => void }) => {
  const { exit } = props
  const [claimNotesEditingState, setClaimNotesEditingState] = 
      useAtom(claimNotesEditingStateAtom)
  const resetClaimNotesEditingState = useResetAtom(claimNotesEditingStateAtom)
  return (
    <Modal open={!!claimNotesEditingState.pendingText && 
        claimNotesEditingState.userIsTryingToExit}>
      <div className="tw-w-96 tw-h- tw-rounded-3xl tw-bg-white 
          tw-m-auto tw-mt-52 tw-outline-none tw-flex tw-flex-col 
          tw-items-center tw-justify-center tw-p-6">
        <h1 className="tw-text-xl tw-text-center tw-font-extrabold">
          You have unsaved changes.
        </h1>
        <p className="tw-text-xs tw-text-center">
          Are you sure you want to navigate away from this page?
        </p>
        <div className="tw-flex tw-items-center tw-justify-between">
          <Button sx={{...ghostButtonNavy, width: '135px',
                height: '36px',
                marginLeft: '0px',
                marginRight: '17px'}}
              onClick={(e) => setClaimNotesEditingState(s => 
                  ({...s, userIsTryingToExit: false}))}>
            Continue Editing
          </Button>
          <Button sx={{...muiRedButton({}), width: '135px'}}
              onClick={exit()}>
            Discard Changes
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default UnsavedChangesModal

