import { TableCell } from "@mui/material";
import { muiTableCell } from "../styles/mui-overrides";
import { useAuth } from "../auth";
import { ColumnDef, isColumnHidden } from "../utilities/column-defs";
import { QueryResult } from "@apollo/client";

interface SortingTableRowVirtualizedProps {
  row: any;
  columns: ColumnDef[];
  isRowHovered: boolean;
  cellPadding?: string;
  expandedRowId: number;
  isRowExpanded: (id: number) => boolean;
  onRowClick: (row: any) => (event: KeyboardEvent | MouseEvent) => void;
  getSortDirectionByKey: (key: string) => "asc" | "desc" | undefined;
  activeColumnKeys: string[];
  queryResult?: QueryResult;
}

function SortingTableRowVirtualized({
  row,
  columns,
  isRowHovered,
  cellPadding,
  expandedRowId,
  isRowExpanded,
  onRowClick,
  getSortDirectionByKey,
  activeColumnKeys,
  queryResult,
}: SortingTableRowVirtualizedProps) {
  const auth = useAuth();

  return columns.map((column) => {
    const { key, renderer, renderOptions } = column;
    let value = row;
    for (const part of key.split(".")) {
      value = value[part];
    }

    let empty = value == null || value == undefined;

    return (
      <TableCell
        key={key}
        sx={{
          ...muiTableCell({
            cellPadding: cellPadding ? `${cellPadding}px` : "9px",
            sortDirection: getSortDirectionByKey(key),
            isRowHovered,
            isRowExpanded: isRowExpanded(row.id) && !!expandedRowId,
            // TODO find out why this is necessary. SA-1259 and SA-1267
            // are both related to this issue. Sometimes activeColumnKeys is
            // undefined when it should not be. This results in a "cannot
            // read property 'includes' of undefined" error.
            // I suspect it has to do with <TableWrapper /> racing
            // with the state of <SortingTable />. It has been observered on two
            // suspiciously slow endpoints supporting the RelationshipsTable,
            // and the JobErrorTable
            isColumnHidden: isColumnHidden(
              column,
              auth.user.isAdmin,
              activeColumnKeys
            ),
          }),
        }}
        {...(onRowClick ? { onClick: onRowClick(row) } : {})}
      >
        {empty && <>N/A</>}
        {!empty && !renderer && <>{value}</>}
        {!empty &&
          !!renderer &&
          renderer(value, { row, queryResult }, renderOptions)}
      </TableCell>
    );
  });
}

export default SortingTableRowVirtualized;
