import { Divider, Stack, Theme, Typography } from "@mui/material";
import { SafeLeaseLetterAvatar } from "@safelease/components";
import { ReputationUser } from "@safelease/service-utilities";
import * as phoneUtil from "google-libphonenumber";

interface EmployeeInfoCardProps {
  user: ReputationUser;
}

/**
 * A small card displaying the name and contact info for an employee
 */
export function EmployeeInfoCard({ user }: EmployeeInfoCardProps) {
  return (
    <Stack
      direction="row"
      spacing={1}
      alignItems="center"
      sx={{ border: "1px solid", borderColor: "#EBEFF7", p: 2, m: 4, borderRadius: 2 }}
    >
      <SafeLeaseLetterAvatar name={user.name} src={null} />
      <Stack direction="column">
        <Typography variant="body1" sx={{ fontWeight: 500 }}>
          {user.name}
        </Typography>
        <Stack direction="row" spacing={1} divider={<Divider orientation="vertical" flexItem />}>
          <Typography variant="body2" sx={{ color: (theme: Theme) => theme.palette.grey.A100 }}>
            {user.email}
          </Typography>
          {user.phone && (
            <Typography variant="body2" sx={{ color: (theme: Theme) => theme.palette.grey.A100 }}>
              {phoneUtil.PhoneNumberUtil.getInstance().format(
                phoneUtil.PhoneNumberUtil.getInstance().parse(user.phone, "US"),
                phoneUtil.PhoneNumberFormat.NATIONAL,
              )}
            </Typography>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
}
