import { Image } from "../../image";
import { CSSProperties, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useAuth } from "../../auth";
import UserDataService from "../../services/user.service";
import { Form, FormElement } from "../../forms.js";
import { Button } from "@mui/material";
import { muiRedButton, muiSigninButton } from "../../styles/mui-overrides";
import { AxiosResponse } from "axios";

function SigninOptions(submitFn) {
  const history = useHistory();

  return (
    <div className="signin-options">
      <div className="forgot-password">
        <a href="/email_signin">Sign in using email</a>
        {" or "}
        <a href="/reset_password">Forgot password?</a>
      </div>
      <Button
        sx={Object.assign(muiRedButton({ maxWidth: false }), muiSigninButton, {
          marginTop: 5,
        })}
        onClick={submitFn}
        type="submit"
        className="signin-options--button"
      >
        Sign in
      </Button>
      <Button
        onClick={() => history.push("/signup")}
        className="signin-options--button"
        sx={{ ...muiSigninButton, display: "none" }}
      >
        {" "}
        {/* INSIGHTS TODO: remove the `display: 'none'` (SA-1914) */}
        Create Account
      </Button>
      <p className="policy-text">
        <small>
          By continuing, you agree to the SafeLease{" "}
          <a
            href="https://info.safelease.com/terms-of-service"
            target="_blank"
            rel="noreferrer"
          >
            Terms of Service
          </a>{" "}
          and{" "}
          <a
            href="https://info.safelease.com/safelease-privacy-policy"
            target="_blank"
            rel="noreferrer"
          >
            Privacy Policy
          </a>
          .
        </small>
      </p>
    </div>
  );
}

export default function SignInPage() {
  const auth = useAuth();
  const location = useLocation<{ from: { pathname: string } }>();
  const history = useHistory();

  useEffect(() => {
    document.body.className = "signin";
    return () => {
      document.body.className = "";
    };
  }, []);

  if (auth.user) {
    history.replace("/");
    return <></>;
  }

  const onFormSuccess = async (response: AxiosResponse) => {
    let { from } = location.state || { from: { pathname: "/" } };
    await auth.signin(response.data);
    history.replace(from);
  };

  const onFormAttempt = (input, cb) => {
    UserDataService.signin(input).then((response) => {
      cb(response);
    });
  };

  const imageOne: CSSProperties = {
    position: "absolute",
    top: "-270px",
    left: "-175px",
  };
  const imageTwo: CSSProperties = {
    position: "absolute",
    bottom: "150px",
    right: "-50px",
    opacity: "0.4",
  };
  const waveSVG: CSSProperties = {
    position: "absolute",
    bottom: "-50px",
  };

  return (
    <div className="signin-page">
      <div className="tw-grid tw-grid-cols-2 tw-h-screen">
        <div
          style={{
            backgroundColor: "#152744",
            position: "relative",
            overflow: "hidden",
          }}
        >
          <div style={imageOne}>
            <Image src="/images/login-bg.svg" alt="SafeLease logo" />
          </div>
          <div style={imageTwo}>
            <Image src="/images/login-bg.svg" alt="SafeLease logo" />
          </div>
          <div style={waveSVG}>
            <WaveSVG />
          </div>
        </div>

        <div className="col right">
          <Image
            className="safelease-logo tw-mb-[60px]"
            src="/images/safelease_logo.svg"
            alt="SafeLease logo"
          />
          <Form
            onFormSuccess={onFormSuccess}
            onFormAttempt={onFormAttempt}
            submitOptions={SigninOptions}
            disablePopupSuccessMessage
          >
            <FormElement name="email" label="Email" />
            <FormElement type="password" name="password" label="Password" />
          </Form>
        </div>
      </div>
    </div>
  );
}

function WaveSVG() {
  return (
    <svg
      width="800"
      height="249"
      viewBox="0 0 800 249"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M799.187 80.7946C799.303 80.7875 799.408 80.7811 799.5 80.7754V329.5H0.5V0.845512C0.554251 0.874722 0.611496 0.905444 0.671749 0.937663C1.28626 1.26626 2.21366 1.75065 3.46956 2.37624C5.98135 3.62744 9.80709 5.44348 15.0715 7.70784C25.6004 12.2366 41.8841 18.5585 64.9206 25.7414C110.993 40.1071 184.078 57.9168 292.159 71.7118C400.249 85.508 527.221 87.7645 627.159 86.5733C677.13 85.9777 720.347 84.5199 751.072 83.2111C766.434 82.5567 778.673 81.9395 787.072 81.4859C791.272 81.2591 794.511 81.0732 796.701 80.944C797.796 80.8793 798.629 80.8289 799.187 80.7946Z"
        fill="#D9D9D9"
        fillOpacity="0.1"
        stroke="black"
      />
    </svg>
  );
}
