import React from "react";
import { Button } from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";

interface GoBackButtonProps {
  onClick: () => void;
}

// Go back button used in NewLocationDialog to go back to the default view
const GoBackButton: React.FC<GoBackButtonProps> = ({ onClick }) => {
  return (
    <Button
      startIcon={
        <KeyboardArrowLeftIcon
          sx={{
            color: "#5C5C5C",
            width: "25px",
            height: "25px",
          }}
        />
      }
      sx={{
        color: "#929292",
        textTransform: "none",
        fontSize: "14px",
        fontWeight: 500,
        "&:hover": {
          backgroundColor: "transparent",
          color: "#929292",
        },
      }}
      onClick={onClick}
    >
      Go back
    </Button>
  );
};

export default GoBackButton;
