import { useEffect, useState } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useQuery, QueryResult, useApolloClient } from '@apollo/client';
import BillingDataService from '../services/billing.service';
import {
  getBillingEntities,
  getInvoiceLineItems,
  getRecurringInvoiceLineItems,
  getInvoices,
  getLocations,
  getRelationship,
} from '../queries';
import { useAuth } from '../auth';
import Button from '@mui/material/Button';
import { ghostButtonNavy, muiRedButton } from '../styles/mui-overrides';
import {
  Alert,
  Box,
  CircularProgress,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  SelectChangeEvent,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import {
  BillingEntity,
  Invoice,
  InvoiceLineItem,
  RecurringInvoiceLineItem,
  Location,
  Relationship,
} from '../utilities/generated/gql-types';
import { Formatters } from '../utilities/formatters';
import _ from 'lodash';
import DatePicker from '../components/lib/SafeLeaseDatePicker';
import Loader from '../shared/Loader';
import dayjs from 'dayjs';

export function RecurringInvoiceLineItems(props) {
  const params: { relationshipId: string } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const client = useApolloClient();
  const history = useHistory();
  const auth = useAuth();
  const isSuperAdmin = auth.user.role === 'superAdmin';
  const isAdmin = auth.user.isAdmin;

  const [alertMessage, setAlertMessage] = useState<{
    message: string;
    severity: 'error' | 'warning' | 'info' | 'success';
  } | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [recurringInvoiceLineItemModalOpen, setRecurringInvoiceLineItemModalOpen] = useState<boolean>(false);
  const [billingEntityId, setBillingEntityId] = useState<string>(
    searchParams.get('billingEntityId')
  );
  const [invoiceId, setInvoiceId] = useState<string>(searchParams.get('invoiceId'));
  const [recurringInvoiceLineItem, setRecurringInvoiceLineItem] = useState<RecurringInvoiceLineItem | null>(null);

  const relationshipQuery: QueryResult<{ getRelationship: Relationship }> = useQuery(
    getRelationship,
    {
      variables: { id: params.relationshipId },
      skip: !isAdmin,
    }
  );

  const billingEntitiesQuery: QueryResult<{ getBillingEntities: BillingEntity[] }> = useQuery(
    getBillingEntities,
    {
      variables: { relationshipId: params.relationshipId },
      skip: !isAdmin,
    }
  );

  const invoicesQuery: QueryResult<{ getInvoices: Invoice[] }> = useQuery(getInvoices, {
    variables: { billingEntityId },
    skip: !isAdmin || !billingEntityId,
  });

  const locationsQuery: QueryResult<{ getLocations: Location[] }> = useQuery(getLocations, {
    variables: { relationshipId: params.relationshipId },
    skip: !isAdmin,
  });

  const invoiceLineItemsQuery: QueryResult<{ getInvoiceLineItems: InvoiceLineItem[] }> = useQuery(
    getInvoiceLineItems,
    {
      variables: { invoiceId },
      skip: !isAdmin || !billingEntityId || !invoiceId,
    }
  );

  const recurringInvoiceLineItemsQuery: QueryResult<{ getRecurringInvoiceLineItems: RecurringInvoiceLineItem[] }> = useQuery(
    getRecurringInvoiceLineItems,
    {
      variables: { billingEntityId },
      skip: !isAdmin || !billingEntityId,
    }
  );

  if (
    relationshipQuery.error ||
    billingEntitiesQuery.error ||
    invoicesQuery.error ||
    locationsQuery.error ||
    invoiceLineItemsQuery.error || 
    recurringInvoiceLineItemsQuery.error
  ) {
    return <>Error fetching data from server.</>;
  }

  if (
    relationshipQuery.loading ||
    billingEntitiesQuery.loading ||
    invoicesQuery.loading ||
    locationsQuery.loading ||
    invoiceLineItemsQuery.loading || 
    recurringInvoiceLineItemsQuery.loading
  ) {
    return <Loader />;
  }

  if (
    (invoiceId && !billingEntityId) ||
    (billingEntityId &&
      billingEntitiesQuery?.data?.getBillingEntities &&
      !billingEntitiesQuery.data.getBillingEntities.find(
        (billingEntity) => billingEntity.id == billingEntityId
      ))
  ) {
    history.push(`/admin/invoices/recurring_invoice_line_items/${params.relationshipId}`);
    setBillingEntityId(null);
    setInvoiceId(null);
  }

  const saveRecurringInvoiceLineItem = async (recurringInvoiceLineItem) => {
    try {
      setAlertMessage(null);
      await BillingDataService.saveRecurringInvoiceLineItem(recurringInvoiceLineItem);

      client.refetchQueries({ include: [getRecurringInvoiceLineItems] });
    } catch (e) {
      setAlertMessage({
        message: 'Failed to save the recurring invoice line item',
        severity: 'error',
      });
    }
  };

  const deleteRecurringInvoiceLineItem = async (recurringInvoiceLineItemId) => {
    try {
      setAlertMessage(null);
      await BillingDataService.deleteRecurringInvoiceLineItem(recurringInvoiceLineItemId);

      client.refetchQueries({ include: [getRecurringInvoiceLineItems] });
    } catch (e) {
      setAlertMessage({
        message: 'Failed to delete the recurring invoice line item.',
        severity: 'error',
      });
    }
  };

  const locationIdsToRecurringInvoiceLineItems: [number, RecurringInvoiceLineItem[]] = _.groupBy(
    recurringInvoiceLineItemsQuery?.data?.getRecurringInvoiceLineItems,
    'locationId'
  );

  const invoice = invoiceId
    ? invoicesQuery?.data?.getInvoices?.find((invoice) => invoice.id == invoiceId)
    : null;

  const locations = billingEntityId
    ? locationsQuery?.data?.getLocations?.filter(
        (location) => location.billingEntityId == parseInt(billingEntityId)
      )
    : [];

  return (
    <>
      <div className="tw-flex tw-items-center">
        <FormControl variant="standard" sx={{ m: 1, minWidth: 250 }}>
          <InputLabel id="billing-entity-select-label">Billing Entity</InputLabel>
          <Select
            labelId="billing-entity-select-label"
            value={billingEntityId}
            onChange={(event) => {
              setAlertMessage(null);
              history.push(
                `/admin/invoices/recurring_invoice_line_items/${params.relationshipId}?${new URLSearchParams({
                  billingEntityId: event.target.value,
                })}`
              );
              setBillingEntityId(event.target.value);
            }}
          >
            {billingEntitiesQuery?.data?.getBillingEntities?.map((billingEntity) => {
              return <MenuItem key={billingEntity.id} value={billingEntity.id}>{billingEntity.name}</MenuItem>;
            })}
          </Select>
        </FormControl>

        {billingEntityId && (
          <div className="tw-flex tw-w-full tw-justify-end tw-items-center">            
          </div>
        )}
      </div>
      {invoice && (
        <>
          <TableContainer component={Paper} className="tw-mt-4">
            <Table sx={{ minWidth: 650 }} size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Location</TableCell>
                  <TableCell align="center">Action</TableCell>
                  <TableCell sx={{ minWidth: '300px' }}>Recurring Line Item</TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell>Start Date</TableCell>
                  <TableCell>End Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(locationIdsToRecurringInvoiceLineItems).map((locationId) => {
                  const recurringInvoiceLineItems = locationIdsToRecurringInvoiceLineItems[locationId];

                  return (
                    <>
                      <TableRow
                        key={locationId}
                        sx={{
                          backgroundColor: '#c9daf8',
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {recurringInvoiceLineItems[0].locationFullAddress}
                        </TableCell>
                        <TableCell />
                        <TableCell />
                        <TableCell align="right">
                        </TableCell>
                        <TableCell />
                        <TableCell />
                      </TableRow>
                      {recurringInvoiceLineItems.map((recurringInvoiceLineItem) => {
                        return (
                          <TableRow
                            key={recurringInvoiceLineItem.id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            <TableCell />
                            <TableCell align="center">
                              <Button
                                onClick={() => {
                                  setRecurringInvoiceLineItemModalOpen(true);
                                  setRecurringInvoiceLineItem(recurringInvoiceLineItem);
                                }}
                              >
                                <EditIcon />
                              </Button>
                            </TableCell>
                            <TableCell align="left">{recurringInvoiceLineItem.label}</TableCell>
                            <TableCell align="right">
                              {recurringInvoiceLineItem.isDynamic ? Formatters.formatIntToDecimalPercentage(recurringInvoiceLineItem.amount) : Formatters.penniesToDollars(recurringInvoiceLineItem.amount)}
                            </TableCell>
                            <TableCell align="center">{recurringInvoiceLineItem.servicePeriodStartDate}</TableCell>
                            <TableCell align="center">{recurringInvoiceLineItem.servicePeriodEndDate || 'N/A'}</TableCell>
                          </TableRow>
                        );
                      })}
                    </>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <div className="tw-flex tw-w-full tw-justify-end tw-items-center">
            {loading ? (
              <CircularProgress sx={{ marginTop: '1rem', marginLeft: '1rem' }} />
            ) : (
              <>
                <Button
                  onClick={async (event) => {
                    await client.refetchQueries({ include: [getInvoices, getInvoiceLineItems] });
                    history.push(
                      `/admin/invoices/${params.relationshipId}?${new URLSearchParams({
                        billingEntityId,
                        invoiceId,
                      })}`
                    );
                  }}
                  sx={{ ...ghostButtonNavy, marginTop: '1rem', marginLeft: '1rem' }}
                >
                  Invoice Page
                </Button>
                <Button
                  onClick={() => setRecurringInvoiceLineItemModalOpen(true)}
                  sx={{ ...ghostButtonNavy, marginTop: '1rem', marginLeft: '1rem' }}
                >
                  Add Recurring Line Item
                </Button>
              </>
            )}
          </div>
        </>
      )}

      {alertMessage && (
        <div className="tw-flex tw-justify-center">
          <Alert className="tw-mt-5" severity={alertMessage.severity}>
            {alertMessage.message}
          </Alert>
        </div>
      )}

      {recurringInvoiceLineItemModalOpen && (
        <InvoiceLineItemModal
          locations={locations}
          recurringInvoiceLineItem={recurringInvoiceLineItem}
          billingEntityId={billingEntityId}
          destroy={(recurringInvoiceLineItemId) => deleteRecurringInvoiceLineItem(recurringInvoiceLineItemId)}
          save={(recurringInvoiceLineItem) => saveRecurringInvoiceLineItem(recurringInvoiceLineItem)}
          close={() => {
            setRecurringInvoiceLineItemModalOpen(false);
            setRecurringInvoiceLineItem(null);
          }}
        />
      )}
    </>
  );
}

function InvoiceLineItemModal(props) {
  const { locations, recurringInvoiceLineItem, billingEntityId, destroy, save, close } = props;

  const [open, setOpen] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [locationId, setLocationId] = useState<number | string>(recurringInvoiceLineItem?.locationId);
  const [type, setType] = useState<string>(recurringInvoiceLineItem?.type || 'Tenant Protection - Premium');
  const [description, setDescription] = useState<string>(recurringInvoiceLineItem?.description);
  const [amount, setAmount] = useState<number>(recurringInvoiceLineItem?.amount / 100);
  const [isDynamic, setIsDynamic] = useState<boolean>(recurringInvoiceLineItem?.isDynamic || false);
  const [servicePeriodStartDate, setServicePeriodStartDate] = useState<Date>(recurringInvoiceLineItem?.servicePeriodStartDate || dayjs().startOf('month').toDate());
  const [servicePeriodEndDate, setServicePeriodEndDate] = useState<Date>(recurringInvoiceLineItem?.servicePeriodEndDate || dayjs().endOf('month').toDate());

  const saveAndClose = async () => {
    if (!locationId || !type || !amount) {
      setError('Location, Type, Amount, and Start Month are required');
      return;
    }
    save({
      id: recurringInvoiceLineItem?.id,
      billingEntityId,
      locationId,
      type,
      description,
      isDynamic,
      amount: Math.trunc(amount * 100),
      servicePeriodStartDate: servicePeriodStartDate,
      servicePeriodEndDate: servicePeriodEndDate,
    });
    onClose();
  };

  const deleteAndClose = async () => {
    if (!window.confirm('Are you sure you want to delete this recurring invoice line item? It will also delete the associated unconfirmed invoice line item, if present.')) return;
    destroy(recurringInvoiceLineItem.id);
    onClose();
  };

  const onClose = async () => {
    setOpen(false);
    close();
  };
  
  const recurringInvoiceLineItemTypes = [
    'Credit',
    'Fee'
  ];

  let newDate = dayjs();
  const servicePeriodStartDateMinDate = newDate.subtract(1, 'month').startOf('month');
  const servicePeriodEndDateMinDate = newDate.startOf('month');;

  return (
    <Modal
      open={open}
      onClose={() => onClose()}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      className="popup-modal"
    >
      <Box className="modal-box">
        <div className="modal-fields">
          <FormControl variant="standard" sx={{ width: 280 }}>
            <InputLabel id="location-select-label">Location</InputLabel>
            <Select
              labelId="location-select-label"
              value={locationId}
              onChange={(event: SelectChangeEvent) => {
                setLocationId(event.target.value);
              }}
            >
              {locations.map((location) => {
                return <MenuItem key={location.id} value={location.id}>{location.fullAddress}</MenuItem>;
              })}
            </Select>
          </FormControl>
          <FormControl sx={{ marginTop: '1rem', width: 280 }}>
            <InputLabel id="type-select-label">Recurring Line Item Type</InputLabel>
            <Select
              labelId="type-select-label"
              id="type-select"
              value={type}
              onChange={(event: SelectChangeEvent) => {
                setType(event.target.value);
              }}
            >
              {recurringInvoiceLineItemTypes.map((type) => {
                return <MenuItem key={type} value={type}>{type}</MenuItem>;
              })}
            </Select>
          </FormControl>
          <TextField
            className="modal-input-field"
            name="description"
            label={'Description'}
            type="text"
            value={description}
            onChange={(event) => setDescription(event.target.value)}
            required={type === 'Other'}
          />
          <FormControlLabel
            sx={{ marginTop: '1rem' }}
            value="top"
            control={
              <Switch
                checked={isDynamic}
                onChange={(event) => setIsDynamic(!isDynamic)}
                inputProps={{ 'aria-label': 'controlled' }}
                color={isDynamic ? 'warning' : 'success'}
              />
            }
            label="Dollar/Percentage Amount"
          />
          
          <TextField
            className="modal-input-field"
            name="amount"
            label={isDynamic ? '% Amount' : '$ Amount'}
            type="number"
            value={amount}
            onChange={(event) => setAmount(parseFloat(event.target.value))}
            required
          />

          <FormControl sx={{ marginTop: '1rem', width: 280 }}>
            <DatePicker
              value={servicePeriodStartDate}
              minDate={servicePeriodStartDateMinDate}
              onChange={(newDate: Date) => {
                const dayJsDate = dayjs(newDate);
                const firstDayOfMonth = dayJsDate.startOf('month');
                setServicePeriodStartDate(firstDayOfMonth.toDate());
              }}
              label="Recurring Service Start Month"
              views={['year', 'month']}
            />
          </FormControl>

          <FormControl sx={{ marginTop: '1rem', width: 280, display: 'flex' }}>
            <Box sx={{display: 'flex'}}>
              <DatePicker
                sx={{ display: 'flex', flexGrow: 1 }}
                value={servicePeriodEndDate}
                minDate={servicePeriodEndDateMinDate}
                onChange={(newDate) => {
                  const dayJsDate = dayjs(newDate);
                  const lastDayOfMonth = dayJsDate.endOf('month');
                  setServicePeriodEndDate(lastDayOfMonth.toDate());
                }}
                label="Recurring Service End Month"
                views={['year', 'month']}
              />
              {servicePeriodEndDate && (
                <IconButton
                color="inherit"
                  onClick={() => {
                    setServicePeriodEndDate(null);
                  }}
                >
                  <CloseIcon />
                </IconButton>
              )}
            </Box>
          </FormControl>
        </div>

        {recurringInvoiceLineItem && (<p style={{ marginTop: 20, color: 'orange' }}>When deleting, associated invoiceLineItems will remain as regular, non-recurring lineItems.</p>)}
        <Box textAlign="center">
          {recurringInvoiceLineItem && (
            <Button
              sx={{ ...muiRedButton({}), marginRight: '1rem' }}
              onClick={() => deleteAndClose()}
            >
              Delete
            </Button>
          )}
          <Button sx={{ ...muiRedButton({ bg: '#0f2846' }) }} onClick={() => saveAndClose()}>
            Save
          </Button>
        </Box>
        <p style={{ marginTop: 20, color: 'red' }}>{error}</p>
      </Box>
    </Modal>
  );
}
