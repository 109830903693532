import { useState } from 'react'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { NotificationsContext, NotificationsContextType } 
    from '../utilities/notifications-context';
import { Claim } from '../utilities/generated/gql-types'

const ClaimDetailAccordion = (props: {
    title: string,
    children: JSX.Element,
    collapsedTitle?: string,
    claim?: Claim;
    subtitle?: String
}) => {
  const { 
    title,
    children,
    collapsedTitle,
    subtitle } = props

  const [isExpanded, setIsExpanded] = useState(true)

  return (
    <NotificationsContext.Consumer>
      {(notificationsContext: NotificationsContextType) =>
        <div className="tw-mt-4">
          <Accordion defaultExpanded={true} 
              sx={{boxShadow: 'none', border: '1px solid #E3E9F7'}}
              expanded={isExpanded}
              onChange={(e, expanded) => {
                setIsExpanded(expanded)
              }}>
            {/*TODO what does aria-controls do? */}
            <AccordionSummary expandIcon={<ExpandMoreIcon />}
                sx={{
                  "&.Mui-expanded": {
                    minHeight: 0
                  },
                  "& .MuiAccordionSummary-content.Mui-expanded": {
                    // margin from https://github.com/mui-org/material-ui/blob/cc0e2ab63e8be9ec4d51a49bfde17ef28fc77b9c/packages/mui-material/src/AccordionSummary/AccordionSummary.js#L64-L64
                    marginBottom: "0px",
                    marginTop: "13px"
                  }
                }}>
              <div className="widget-title widget-title__claim-details">
                {collapsedTitle && !isExpanded ? 
                  collapsedTitle : title
                }
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <div className='widget-subtitle widget-subtitle__claim-details'>
                {subtitle && isExpanded && subtitle}
              </div>
              { children }
            </AccordionDetails>
          </Accordion>
        </div>
      }
    </NotificationsContext.Consumer>
  )
}

export default ClaimDetailAccordion
