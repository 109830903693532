import { TimelineItem, TimelineItemProps, TimelineSeparator } from "@mui/lab";
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { CampaignTimelineConnector, CampaignTimelineDot } from "../CampaignConfigurationTimeline";
import { Box, SpeedDial, SpeedDialAction, SpeedDialIcon } from "@mui/material";
import { EmailOutlined, PhoneIphoneOutlined } from "@mui/icons-material";
import { useState } from "react";

interface AddCampaignConfigurationStepProps extends TimelineItemProps {
  // Index that this step will be inserted at
  stepIndex: number;

  // Add a new step into the form
  handleAddNewStep: (stepIndex: number, medium: "text" | "email") => void;
}

export function AddCampaignConfigurationStep({ stepIndex, handleAddNewStep }: AddCampaignConfigurationStepProps) {
  const [open, setOpen] = useState(false);

  return (
    <TimelineItem>
      <TimelineSeparator>
        <CampaignTimelineConnector sx={{ bgcolor: "red" }} />
        <CampaignTimelineDot>
          <Box sx={{ position: "relative", height: 40, width: 40, borderRadius: "1em" }}>
            <ClickAwayListener onClickAway={() => setOpen(false)}>
              <SpeedDial
                open={open}
                onClick={() => setOpen(!open)}
                icon={<SpeedDialIcon sx={{ color: "black" }} />}
                direction="right"
                ariaLabel="add campaign step"
                sx={{ position: "absolute", top: "50%", left: "50%", height: 40, width: 48, transform: "translateY(-50%)" }}
              >
                <SpeedDialAction key="email" icon={<EmailOutlined />} onClick={() => handleAddNewStep(stepIndex, "email")} />
                <SpeedDialAction key="text" icon={<PhoneIphoneOutlined />} onClick={() => handleAddNewStep(stepIndex, "text")} />
              </SpeedDial>
            </ClickAwayListener>
          </Box>
        </CampaignTimelineDot>
        <CampaignTimelineConnector />
      </TimelineSeparator>
    </TimelineItem>
  );
}
