import { useEffect } from "react";
import { useQuery, useApolloClient } from '@apollo/client';
import SetupDataService from "../../services/setup.service";
import { getJobs, getRelationshipsSelector } from '../../queries';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import pusher from "../../pusher";
import FileSaver from 'file-saver';
import Loader from '../../shared/Loader'
import Error from '../../shared/Error'
import { useAuth } from "../../auth";
import { FormControl, MenuItem, Select } from "@mui/material";
import { useHistory } from "react-router-dom";
import { muiSelect } from "../../styles/mui-overrides";

function RelationshipJobs(props) {
  const { routerHelper } = props;

  const auth = useAuth();
  const isAdmin = auth.user.isAdmin;
  const client = useApolloClient();
  const history = useHistory();

  const relationshipId = routerHelper?.getRelationshipId() || auth.user?.relationshipId;

  const jobs = useQuery(getJobs, {variables: {relationshipId}});
  const relationshipsSelectorQuery = useQuery(getRelationshipsSelector);

  useEffect(() => {
    const channel = pusher.subscribe(`relationship-${relationshipId}`);
    channel.bind('jobs', async function(data) {
      await client.refetchQueries({
        include: [getJobs],
      });
    });
  }, [client, relationshipId]);

  if (jobs.loading || relationshipsSelectorQuery.loading) return <Loader />;
  if (jobs.error || relationshipsSelectorQuery.error) return <Error />;

  const downloadLogs = async function(jobId) {
    console.log(jobId);
    var response = await SetupDataService.getLogs(jobId);
    const csvData = new Blob([response.data.output], { type: 'text/csv;charset=utf-8;' });
    FileSaver.saveAs(csvData, 'logs.csv');
  }

  return (
    <div>
      {auth.user && isAdmin && relationshipsSelectorQuery.data && (
        <FormControl sx={{ marginTop: 1, marginBottom: 2, minWidth: 120 }}>
          <Select
            value={relationshipId}
            displayEmpty
            onChange={(e) => {
              routerHelper.navigateToRoute('jobSettings', e.target.value, history);
            }}
            sx={{ ...muiSelect, marginLeft: '0px', padding: '0px' }}
          >
            {relationshipsSelectorQuery.data.getRelationshipsSelector?.map((relationship) => {
              return (
                <MenuItem key={relationship.id} value={relationship.id}>
                  {relationship.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      )}

      <TableContainer component={Paper} className="tw-mt-4">
        <Table sx={{ minWidth: 650 }} size="small">
          <TableHead>
            <TableRow>
              <TableCell>Job</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Next run</TableCell>
              <TableCell>Last completion</TableCell>
              <TableCell>Logs</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
          {jobs.data.getJobs.map(job => {
              var runAt = new Date(Date.parse(job.runAt));
              var lastCompletedAt = job.lastCompletedAt && new Date(Date.parse(job.lastCompletedAt));
              return (
              <TableRow key={job.id}>
                <TableCell>{job.methodDescription}</TableCell>
                <TableCell>
                  <a href={job.statusDescription == 'Failed' && `/admin/retry/${job.id}`}>{job.statusDescription}</a>&nbsp;
                  {job.statusDescription == 'Failed' && job.error && 
                   <>
                     ({job.error})
                   </>
                  }
                  {job.failureCount &&
                   <>
                     ({job.failureCount} failures)
                   </>
                  }
                </TableCell>
                <TableCell>{runAt.toLocaleDateString()} {runAt.toLocaleTimeString()}</TableCell>
                <TableCell>{lastCompletedAt && lastCompletedAt.toLocaleDateString()} {lastCompletedAt && lastCompletedAt.toLocaleTimeString()}</TableCell>
                <TableCell>
                  { job.hasLogs &&
                    <Button onClick={e => downloadLogs(job.id)}>
                      Download
                    </Button>
                  }
                </TableCell>
              </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export { RelationshipJobs };
