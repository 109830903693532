import { InfoOutlined, KeyboardArrowRight, StarOutline } from "@mui/icons-material";
import { ButtonBase, Divider, Stack, Tooltip, Typography } from "@mui/material";
import { SafeLeaseLetterAvatar } from "@safelease/components";
import { useState } from "react";
import { EmployeeDetailsDrawer } from "../EmployeeDetailsDrawer";
import { ReputationUser } from "@safelease/service-utilities";
import dayjs from "dayjs";

/**
 * An individual employee's overview card in the performance board
 */
export function EmployeeReviewPerformanceBoardListItem({ user }: { user: ReputationUser }) {
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);

  const reviews = user.referralLinkEvents?.map((event) => event.googleReview) ?? [];
  const reviewsInLast30 = reviews.filter((review) => dayjs(review.actualDate).isAfter(dayjs().subtract(30, "days")));
  const rating = reviews.length ? reviews.reduce((acc, review) => acc + review.rating, 0) / reviews.length : 0;

  return (
    <>
      <ButtonBase onClick={() => setDrawerOpen(true)}>
        <Stack
          spacing={1}
          direction="row"
          sx={{ border: "1px solid", borderColor: "#EBEFF7", width: "100%", p: 1, borderRadius: 1 }}
          divider={<Divider flexItem orientation="vertical" />}
          alignItems="center"
        >
          <Stack direction="row" alignItems="center" spacing={1} flex={1}>
            <SafeLeaseLetterAvatar name={user.name} />
            <Typography variant="body2" textAlign="left">
              {user.name}
            </Typography>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={1} flex={1}>
            <Typography variant="caption" color="grey.A100">
              <span style={{ fontWeight: 700, color: "black" }}>{reviews?.length ?? 0}</span> total / <span style={{ fontWeight: 700, color: "black" }}>{reviewsInLast30.length}</span> in last 30 days
            </Typography>
            <Tooltip title="Reviews earned by the employee">
              <InfoOutlined sx={{ height: 16, width: 16 }} />
            </Tooltip>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={1} flex={1}>
            <StarOutline />
            <Typography variant="caption" color="grey.A100">
              <span style={{ fontWeight: 700, color: "black" }}>{rating ? rating.toFixed(1) : "-"}</span> average rating
            </Typography>
            <Tooltip title="Average rating of reviews earned by the employee">
              <InfoOutlined sx={{ height: 16, width: 16 }} />
            </Tooltip>
          </Stack>
          <KeyboardArrowRight />
        </Stack>
      </ButtonBase>
      <EmployeeDetailsDrawer user={user} open={drawerOpen} handleClose={() => setDrawerOpen(false)} />
    </>
  );
}
