import { Box, Tooltip, Typography } from '@mui/material';
import { useAuth } from '../auth';

export function AdminModeActiveBox() {
  const auth = useAuth();
  return (
    <Box
      sx={{ display: 'flex', alignItems: 'flex-end', height: '100%', width: '100%', padding: 2 }}
    >
      <Tooltip
        title={`${auth.user.name} you are viewing this app as an Admin. As an Admin you can access different relationships and manage settings that partners cannot.`}
      >
        <Typography variant='caption' color='grey' sx={{ paddingRight: 1, alignContent: 'center' }}>
          Admin Role Active
        </Typography>
      </Tooltip>
    </Box>
  );
}
