import dayjs from "dayjs";
import { StateCreator } from "zustand";
import { Location } from "../../utilities/generated/gql-types";
import { sliceResetFns } from "./useReportsStore";

export interface BaseSliceState {
  relationshipId: number;
  locations: Location[];
  locationIds: number[];
  startDate: Date | null;
  endDate: Date | null;
  loading: boolean;
}

export interface BaseSlice extends BaseSliceState {
  setRelationshipId: (relationshipId: number) => void;
  setLocations: (locations: Location[]) => void;
  setLocationIds: (locationIds: number[]) => void;
  setStartDate: (startDate: Date) => void;
  setEndDate: (endDate: Date) => void;
  setLoading(loading: boolean): void;
}

const initialBaseSliceValues: BaseSliceState = {
  relationshipId: 271,
  locations: [],
  locationIds: [],
  startDate: dayjs(new Date()).subtract(1, "days").toDate(),
  endDate: new Date(),
  loading: false,
};

export const createBaseReportSlice: StateCreator<BaseSlice> = (set) => {
  /* Add resetting this slice to master resetter */
  sliceResetFns.add(() => set(initialBaseSliceValues));

  return {
    ...initialBaseSliceValues,
    setRelationshipId: (relationshipId: number) => set({ relationshipId }),
    setLocations: (locations: Location[]) => set({ locations }),
    setLocationIds: (locationIds: number[]) => set({ locationIds }),
    setStartDate: (startDate: Date) => set({ startDate }),
    setEndDate: (endDate: Date) => set({ endDate }),
    setLoading: (loading: boolean) => set({ loading }),
  };
};
