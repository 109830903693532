import http from "./http-common";

class SalesforceDataServices {
  getDesiredSyncDataForOpportunityLocation(params: {opportunityLocationId: string}) {
    return http.post("/salesforce/get_desired_sync_data_for_opportunity_location", params);
  }
  
  getOpLocationIdsForOpportunity(params: {opportunityId: string}) {
    return http.post("/salesforce/get_op_location_ids_for_opportunity", params);
  }

  performDataSyncForOpportunityLocation(params: PerformSyncForOpportunityLocationResponseObject) {
    return http.post("/salesforce/perform_data_sync_for_opportunity_location", params);
  }
}

export default new SalesforceDataServices();