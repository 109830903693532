import { InfoOutlined } from "@mui/icons-material";
import { Box, Stack, Theme, Tooltip, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-premium";
import { SafeLeaseAvatarWithText, SafeLeaseChip } from "@safelease/components";
import dayjs from "dayjs";
import { issueTypeLabels } from "./issueTypeLabels";
import { FeedbackResponseActionArea } from "../../../../components/FeedbackResponseActionArea";

export const facilityFeedbackListGridColDefs: GridColDef<any>[] = [
  {
    field: "contact",
    headerName: "Tenant",
    sortable: true,
    disableColumnMenu: true,

    renderHeader: () => (
      <Stack alignItems="center" spacing={1} direction="row">
        <Typography>Tenant</Typography>
        <Tooltip title="Name of the person who left the feedback">
          <InfoOutlined
            sx={{
              height: 18,
              color: (theme: Theme) => theme.palette.grey.A100,
            }}
          />
        </Tooltip>
      </Stack>
    ),
    width: 200,
    renderCell: ({ value }) => <SafeLeaseAvatarWithText text={value?.name ?? "Anonymous"} subText={value?.unit} />,
    sortComparator: (v1, v2) => {
      if (!v1 || !v2) return 0;
      return v1.name.localeCompare(v2.name);
    },
  },
  {
    field: "feedback",
    headerName: "Tenant Feedback",
    flex: 2,
    disableColumnMenu: true,
    sortable: false,
  },
  {
    field: "issueTypes",
    headerName: "Topics",
    width: 350,
    sortable: false,
    disableColumnMenu: true,
    renderCell: ({ value }) => {
      return (
        <Box sx={{ display: "flex", flexWrap: "wrap", alignItems: "flex-start" }}>
          {value.map((issueType: string) => (
            <SafeLeaseChip key={issueType} label={issueTypeLabels[issueType]} sx={{ mr: 1, mb: 1 }} color="neutral" />
          ))}
        </Box>
      );
    },
    flex: 1.5,
  },
  {
    field: "createdAt",
    headerName: "Date Submitted",
    flex: 0.75,
    valueFormatter: ({ value }) => dayjs(value).format("MMM DD, YYYY"),
    sortable: true,
    disableColumnMenu: true,
    sortComparator: (v1, v2) => {
      const date1 = dayjs(v1);
      const date2 = dayjs(v2);
      return date1.diff(date2);
    },
  },
  {
    field: "campaign",
    headerName: "Campaign",
    flex: 0.75,
    sortable: true,
    disableColumnMenu: true,
    renderHeader: () => (
      <Stack alignItems="center" spacing={1} direction="row">
        <Typography variant="body2">Campaign</Typography>
        <Tooltip title="If we were able to associate a review with a campaign, we will identify it here.">
          <InfoOutlined sx={{ height: 18, color: (theme: Theme) => theme.palette.grey.A100 }} />
        </Tooltip>
      </Stack>
    ),
    valueGetter: ({ row }) => row.message?.campaign?.name,
  },
  {
    field: "id",
    headerName: "Response Actions",
    sortable: true,
    disableColumnMenu: false,
    renderCell: ({ row }) => {
      return (
        <Stack direction="row" alignItems="flex-right">
          <FeedbackResponseActionArea feedback={row} />
        </Stack>
      );
    },
    flex: 2,
  },
];
