import {
  Chip,
  Grid,
  List,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Formatters } from "../../../utilities/formatters";
import { AccessTime, ArrowForward, Block, Check } from "@mui/icons-material";
import { blue, green, grey } from "@mui/material/colors";
import {
  UpgradeProtectionPlanConfig,
  UpgradeProtectionPlanStep,
} from "../../../utilities/generated/gql-types";

interface PlanUpgradesDataGridDetailPanelProps {
  row: UpgradeProtectionPlanConfig & { steps: UpgradeProtectionPlanStep[] };
}

export function PlanUpgradesDataGridDetailPanel({
  row,
}: PlanUpgradesDataGridDetailPanelProps) {
  return (
    <Paper
      elevation={0}
      sx={{
        border: "1px solid #EBEFF7",
        p: 2,
        my: 2,
        mr: 2,
        ml: 6,
        bgcolor: grey[100],
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography fontWeight="bold" variant="h6">
            Upgrade Plan Steps at {row.location.address}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Step</TableCell>
                <TableCell>Old Premium</TableCell>
                <TableCell>Old Coverage</TableCell>
                <TableCell></TableCell>
                <TableCell>New Premium</TableCell>
                <TableCell>New Coverage</TableCell>
                <TableCell>Step Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {row.steps?.map((step, index) => (
                <TableRow key={step.id}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>
                    <Typography variant="caption">
                      {Formatters.penniesToDollars(step.oldPremium)}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="caption">
                      {Formatters.penniesToDollars(step.oldCoverage)}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <ArrowForward sx={{ color: grey[400], height: 16 }} />
                  </TableCell>
                  <TableCell>
                    <Typography variant="caption">
                      {Formatters.penniesToDollars(step.newPremium)}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="caption">
                      {Formatters.penniesToDollars(step.newCoverage)}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    {step.deletedAt && (
                      <Chip
                        label="Cancelled"
                        sx={{
                          color: grey[500],
                          border: `1px solid ${grey[400]}`,
                          bgcolor: grey[50],
                        }}
                        deleteIcon={
                          <Block sx={{ color: `${grey[400]} !important` }} />
                        }
                        onDelete={() => null}
                      />
                    )}
                    {!step.deletedAt && !step.completed && (
                      <Chip
                        label="Scheduled"
                        sx={{
                          color: blue[800],
                          border: `1px solid ${blue[800]}`,
                          bgcolor: blue[50],
                        }}
                        deleteIcon={
                          <AccessTime
                            sx={{ color: `${blue[800]} !important` }}
                          />
                        }
                        onDelete={() => null}
                      />
                    )}
                    {!step.deletedAt && step.completed && (
                      <Chip
                        label="Completed"
                        sx={{
                          color: green[800],
                          border: `1px solid ${green[800]}`,
                          bgcolor: green[50],
                        }}
                        deleteIcon={
                          <Check sx={{ color: `${green[800]} !important` }} />
                        }
                        onDelete={() => null}
                      />
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Grid>
      </Grid>
    </Paper>
  );
}
