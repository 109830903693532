import { Divider, Grid, Skeleton, Stack, Theme, Tooltip, Typography } from "@mui/material";
import { SafeLeaseCard } from "@safelease/components";
import { FacilityFeedbackCategories } from "./FacilityFeedbackCategories";
import { FacilityFeedbackList } from "./FacilityFeedbackList";
import { InfoOutlined } from "@mui/icons-material";
import { useQuery } from "@tanstack/react-query";
import { useReputation } from "../../../../useReputation";

interface FeedbackOverviewProps {}

export function FeedbackOverview({}: FeedbackOverviewProps) {
  const { selectedFacilityId, statuses, SafeLeaseAPI } = useReputation();

  const getFacilityFeedbackQuery = useQuery({
    queryKey: ["facilityFeedback", selectedFacilityId],
    queryFn: () => SafeLeaseAPI.reputation.getFacilityFeedback(selectedFacilityId),
  });

  if (getFacilityFeedbackQuery.isLoading || statuses.portfolio === "loading") {
    return <Skeleton variant="rectangular" height={400} sx={{ width: "100%" }} />;
  }

  // prefer feedbackSubmissions on portfolio as they are referenced by mutliple components dealing with feedback
  const feedback = getFacilityFeedbackQuery?.data?.data?.feedbackSubmissions;

  const issueTypes = getFacilityFeedbackQuery?.data?.data.issueTypes;

  // Number of people that left a review in addition to leaving feedback
  const submissionsThatAlsoLeftReview = feedback.filter((privateReview) => !!privateReview.contact?.reviewId).length;

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <SafeLeaseCard sx={{ p: 2 }}>
            <Stack direction="row" divider={<Divider flexItem orientation="vertical" />} spacing={2}>
              <Stack direction="column" justifyContent="space-between">
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Typography variant="h6">Private Reviews</Typography>
                  <Tooltip title="Reviews that were left in the a private form. These reviews are not visible to the public.">
                    <InfoOutlined sx={{ height: 18, width: 18, color: (theme: Theme) => theme.palette.grey.A100 }} />
                  </Tooltip>
                </Stack>
                <Typography variant="h2">{feedback.length}</Typography>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Typography variant="body2" color="grey.A100">
                    <Typography variant="body2" component="span" fontWeight="bold">
                      {submissionsThatAlsoLeftReview}
                    </Typography>{" "}
                    also left a public review
                  </Typography>
                  <Tooltip title="Tenants that submitted a private review and continued to leave a review on Google.">
                    <InfoOutlined
                      sx={{
                        height: 18,
                        width: 18,
                        color: (theme: Theme) => theme.palette.grey.A100,
                      }}
                    />
                  </Tooltip>
                </Stack>
              </Stack>
              <FacilityFeedbackCategories issueTypes={issueTypes} />
            </Stack>
          </SafeLeaseCard>
        </Grid>
        <Grid item xs={12}>
          <FacilityFeedbackList feedback={feedback} />
        </Grid>
      </Grid>
    </>
  );
}
