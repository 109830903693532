import {
  Checkbox,
  Chip,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Popover,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { Formatters } from "../../../utilities/formatters";
import { usePlanUpgradeStore } from "../usePlanUpgradeSettingsStore";
import { ArrowDropDown, Check } from "@mui/icons-material";
import { PlanUpgradeStepWithId } from "../usePlanUpgradeSettingsStore/usePlanUpgradeSettingsPlansSlice";
import { green } from "@mui/material/colors";

interface ReviewStepSKUTransitionRowProps {
  migration: any;
  skuConfigAfter: any;
  readOnly?: boolean;
}

export function ReviewStepSKUTransitionRow({
  skuConfigAfter,
  migration,
  readOnly = false,
}: ReviewStepSKUTransitionRowProps) {
  const selectedLocations = usePlanUpgradeStore(
    (state) => state.selectedLocations
  );

  const migrationOmissions = usePlanUpgradeStore(
    (state) => state.omitPlanUpgradeSteps
  );
  const setMigrationOmissions = usePlanUpgradeStore(
    (state) => state.setOmitPlanUpgradeSteps
  );

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  /* Allow the user to omit a specific migration of an SKU */
  const handleOmitSKUMigration = (migration: PlanUpgradeStepWithId) => {
    setMigrationOmissions(migration);
  };

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  /* Should this particular SKU movement be included in the upgrade plan? */
  const isIncluded =
    migrationOmissions.findIndex(
      (omission) =>
        omission.oldCoverage === migration.oldCoverage &&
        omission.oldPremium === migration.oldPremium
    ) === -1;

  return (
    <>
      <Grid
        item
        xs={1}
        sx={{
          padding: readOnly ? "initial" : "0px !important", // vertically centers checkbox and text
          textAlign: "center",
        }}
      >
        {!readOnly ? (
          <Checkbox
            size="small"
            checked={isIncluded}
            onChange={() => handleOmitSKUMigration(migration)}
          />
        ) : isIncluded ? (
          <Check sx={{ color: green[500] }} />
        ) : (
          <></>
        )}
      </Grid>
      <Grid item xs={1} textAlign="right">
        <Typography variant="caption">
          {Formatters.formatWithCommas(migration.numberAtPremiumBefore)}
        </Typography>
      </Grid>
      <Grid item xs={2} textAlign="right">
        <Typography variant="caption">
          {Formatters.penniesToDollars(migration.oldPremium)}
        </Typography>
      </Grid>
      <Grid item xs={2} textAlign="right">
        <Typography variant="caption">
          {Formatters.penniesToDollars(migration.oldCoverage)}
        </Typography>
      </Grid>
      <Grid item xs={2} textAlign="right">
        <Typography variant="caption">
          {Formatters.penniesToDollars(migration.newPremium)}
        </Typography>
      </Grid>
      <Grid item xs={2} textAlign="right">
        <Typography variant="caption">
          {Formatters.penniesToDollars(migration.newCoverage)}
        </Typography>
      </Grid>
      <Grid item xs={2} textAlign="right">
        <Typography variant="caption" sx={{ mr: 1 }}>
          {skuConfigAfter.skusAtLocations[migration.oldPremium][
            migration.oldCoverage
          ].size ?? 0}
        </Typography>
        <IconButton size="small" onClick={handlePopoverOpen}>
          <ArrowDropDown sx={{ height: 18, width: 18 }} />
        </IconButton>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handlePopoverClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <List
            sx={{
              maxHeight: 410 /* 410 cuts off one of the list items, indicating the user can/should scroll */,
            }}
          >
            {Array.from(
              skuConfigAfter.skusAtLocations[migration.oldPremium][
                migration.oldCoverage
              ]
            ).map((locationId: number) => {
              const locationAddress = selectedLocations.find(
                (location) => Number(location.id) === locationId
              )?.address;

              return (
                <ListItem key={locationId}>
                  <ListItemText
                    primary={locationAddress}
                    primaryTypographyProps={{ variant: "caption" }}
                  />
                </ListItem>
              );
            })}
          </List>
        </Popover>
      </Grid>
    </>
  );
}
