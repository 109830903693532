// UI - libs

// UI - internal
import SafeleaseDataGrid from "../../../shared/safelease-data-grid";
import { privatePolicyReportColumnDefs } from "./privatePolicyReportColumnDefs";

// Data
import { useReportsStore } from "../../../reports/useReportsStore";
import { Paper } from "@mui/material";
import { useState } from "react";
import { PrivatePolicyDetailsModal } from "../PrivatePolicyDetailsModal";
import { PrivatePolicyDataGridToolbar } from "./PrivatePolicyReportDataGridToolbar";
import useWindowSize from "../../../utilities/useWindowSize";

interface PrivatePolicyReportDataGridProps {}

/* Grouping of private policies by validation status with high level data about each policy */

export function PrivatePolicyReportDataGrid({}: PrivatePolicyReportDataGridProps) {
  const size = useWindowSize();

  const selectedLocationsInReport = useReportsStore(
    (state) => state.selectedLocationsInReport
  );

  const allGeneralUnitsForRelationship = useReportsStore(
    (state) => state.allUnits
  );

  const [selectedGeneralUnit, setSelectedGeneralUnit] = useState<any | null>(null);

  // Wrap state update in setTimeout to avoid race condition
  const handleRowClick = (params) => {
    setTimeout(() => {
      setSelectedGeneralUnit(params.row);
    });
  };

  // TODO: rewrite all this, it's garbage code
  const privatePolicies = allGeneralUnitsForRelationship
    .filter(
      (generalUnit) =>
        !!generalUnit.privatePolicyValidationStatus && 
        selectedLocationsInReport.includes(Number(generalUnit.locationId))
    )
    .map((remappedPrivatePolicy: any) => ({
      ...remappedPrivatePolicy,
      handleOpenDeclarationsPageDialog: () =>
        setSelectedGeneralUnit(remappedPrivatePolicy),
    }));

  return (
    <Paper
      elevation={0}
      sx={{
        border: "1px solid #EBEFF7",
        boxShadow: "0 0 20px rgba(0, 0, 0, 0.05)",
        overflow: "hidden",
        borderRadius: "8px",
        height: size.height - 410,
      }}
    >
      <SafeleaseDataGrid
        clickable
        columns={privatePolicyReportColumnDefs}
        rows={privatePolicies}
        disableRowGrouping={false}
        sx={{ border: "none" }}
        initialState={{
          sorting: {
            sortModel: [
              { field: "privatePolicyValidationStatus", sort: "asc" },
            ],
          },
          columns: {
            columnVisibilityModel: {
              locationCity: false,
              locationState: false,
              locationPostalCode: false,
              privatePolicyCompanyName: false,
              privatePolicySource: false,
            },
          },
        }}
        slots={{
          toolbar: PrivatePolicyDataGridToolbar,
        }}
        onRowClick={(params) => handleRowClick(params)}
      />
      <PrivatePolicyDetailsModal
        generalUnit={selectedGeneralUnit}
        open={selectedGeneralUnit !== null}
        handleClose={() => setSelectedGeneralUnit(null)}
      />
    </Paper>
  );
}
