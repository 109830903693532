import { Stack, Typography, Card } from "@mui/material";
import ModelValueRenderer from "./ModelValueRenderer";

const LocationDataVisualizer = (props: { locationData: LocationSyncMetaData; }) => {
  const { locationData } = props;

  const dataToMap = locationData.existingId ? locationData.existingData : locationData.newData;

  return (
    <div>
      <Card style={{ padding: "20px", display: "inline-block", marginBottom: "10px" }}>
        <div style={{ marginBottom: "15px" }}>
          {locationData.existingId && (
            <h5>{`Updates for locationId - ${locationData.existingId}:`}</h5>
          )}
          {locationData.createNew && (
            <h5>This Location does not exist yet. This will create a new Location with this data:</h5>
          )}
        </div>
        <Stack sx={{paddingLeft: "10px"}} spacing={2}>
          {
            Object.keys(dataToMap).map((key) => {
              return (
                <Stack sx={{ padding: "15px", border: "0.5px solid #c7c7c7"}} key={key} spacing={2} direction='row' alignItems="center">
                  <Typography sx={{ fontWeight: "bold", fontSize: 20 }}>{key}:</Typography>
                  <ModelValueRenderer value={locationData.existingData[key]} sx={{ fontSize: 20 }} />
                  <Typography sx={{ fontWeight: "bold", fontSize: 20 }}>{'-->'}</Typography>
                  <ModelValueRenderer value={locationData.newData[key]} sx={{ color: "#b52a21", fontSize: 20, fontWeight: "bold" }} />
                </Stack>
              );
            }
            )
          }
        </Stack>
      </Card>
    </div>
  );
};

export default LocationDataVisualizer;
