import { CircularProgress, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { SafeLeaseButton, SafeLeaseCard, SafeLeaseFormTextField } from "@safelease/components";
import { useState } from "react";
import { z } from "zod";
import { useReputation } from "../../../../../useReputation";
import { Facility_Reputation } from "@safelease/service-utilities";
import { enqueueSnackbar } from "notistack";
import { reactQueryClient } from "../../../../../../utilities/reactQueryClent";
import { FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { mixpanelEventHandler } from "../../../../../../utilities/reactMixpanelHandler";
import { useAuth } from "../../../../../../auth";

export const FacilityBilling = () => {
  const [saving, setSaving] = useState(false);
  const { facility, SafeLeaseAPI, selectedRelationshipId } = useReputation();
  const auth = useAuth();

  const dollarAmountSchema = z.string().refine((value) => /^\d+\.\d{2}$/.test(value), {
    message: "Must be a valid dollar amount with exactly two decimal places",
  });

  const facilityBillingSchema = z.object({
    monthlyFee: dollarAmountSchema,
  });

  const facilityBillingForm = useForm({
    defaultValues: {
      monthlyFee: facility.monthlyFee ? (facility.monthlyFee / 100).toFixed(2) : "", // Convert cents to dollars
    },
    resolver: zodResolver(facilityBillingSchema),
  });

  const handleSave = async (data: Partial<Facility_Reputation>) => {
    // Convert monthlyFee to cents if it's a string
    if (typeof data.monthlyFee === "string") {
      data.monthlyFee = Math.round(parseFloat(data.monthlyFee) * 100);
    }

    const previousMonthlyFee = facility.monthlyFee;

    setSaving(true);
    try {
      await SafeLeaseAPI.reputation.updateFacilityDetails(facility.id, data);
      await reactQueryClient.invalidateQueries({
        queryKey: ["facilityDetails", facility.id],
      });
      await mixpanelEventHandler("Facility Monthly Fee Updated", {
        relationshipId: selectedRelationshipId,
        facilityId: facility.id,
        facilityName: facility.companyName,
        previousMonthlyFee: previousMonthlyFee,
        newMonthlyFee: data.monthlyFee,
        userEmail: auth.user.email,
        userId: auth.user.id,
      });
      enqueueSnackbar("Billing updated successfully", { variant: "success" });
    } catch (error) {
      console.error("Error updating facility", error);
      enqueueSnackbar("Error updating facility", { variant: "error" });
    } finally {
      setSaving(false);
    }
  };

  const errors = facilityBillingForm.formState.errors;

  const formattedMonthlyFee = facility.monthlyFee ? (facility.monthlyFee / 100).toFixed(2) : "";
  return (
    <SafeLeaseCard sx={{ p: 2 }}>
      <Stack direction={"column"} spacing={1}>
        <FormProvider {...facilityBillingForm}>
          <form
            onSubmit={(e) => {
              console.log("Form submitted");
              console.log("Form errors:", facilityBillingForm.formState.errors);
              facilityBillingForm.handleSubmit(handleSave)(e);
            }}
          >
            <Stack direction={"row"} justifyContent={"space-between"}>
              <Typography variant="h6">Billing</Typography>
              <SafeLeaseButton type="submit"> {saving ? <CircularProgress /> : "Save"} </SafeLeaseButton>
            </Stack>
            <SafeLeaseCard sx={{ p: 2 }}>
              <Stack direction={"row"} alignItems={"end"}>
                <SafeLeaseFormTextField
                  name="monthlyFee"
                  label="Facility Monthly Fee for SafeLease Reputation"
                  description="Fee that the facility pays for SafeLease Reputation on a monthly basis. Must be filled out for each facility."
                  sx={{ width: "100px" }}
                  placeholder="e.g. 120.00"
                  error={!!errors.monthlyFee?.message}
                  helperText={errors.monthlyFee?.message}
                />
              </Stack>
            </SafeLeaseCard>
          </form>
        </FormProvider>
      </Stack>
    </SafeLeaseCard>
  );
};
