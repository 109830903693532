export const dailySummaryTotals = [
  'projectedRent',
  'receivableRent',
  'netMoveIns',
  'unoccupiedUnits',
];

export const insurancePenetrationTotals = [
  'newPolicies',
  'newLeasesCount',
  'reportSafeleaseUnits',
  'reportOccupiedUnits',
  'reportTotalCoverage',
];

export const occupancySummaryTotals = [
  'projectedRent',
  'totalRate',
  'occupied',
  'economicOccupancy',
];