import { useEffect, useState } from 'react';
import SortingTable from './shared/sorting-table';
import RouterHelper from './utilities/router-helper';
import { matchClaimsColumnDefs, possibleClaimMatchesColumnDefs } from './utilities/column-defs';
import { useAuth } from './auth.js';
import { useQuery, QueryResult } from '@apollo/client';
import { getClaims, getPossibleClaimMatches } from './queries';
import TitleHeader from './shared/title-header';
import { Drawer, Box } from '@mui/material';
import { TitleValuePair } from './common';
import { muiDrawer } from './styles/mui-overrides';
import { getAllLocations } from './queries'
import ClaimsDataService from "./services/claims.service";
import TableWrapper from './shared/table-wrapper';


// This, claims, reports, and report-details have a lot of repeated code,
// and could become a new abstracted component
function MatchClaims(props: {routerHelper: RouterHelper}) {
  const auth = useAuth();

  //TODO maybe non manager users shouldn't even have a routerHelper, but this works for now
  //const relationshipId = props.routerHelper.getRelationshipId() || auth.user.relationshipId;

  useEffect(() => {
    document.body.classList.add('dashboard');
    return () => { document.body.classList.remove('dashboard')}
  }, []);

  const claims: QueryResult = 
      useQuery(getClaims, {variables: {}, fetchPolicy: 'no-cache'});

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedClaim, setSelectedClaim] = useState(null);

  const onRowClick = (claim: any) => {
    return toggleDrawer(true, claim)
  }

  const toggleDrawer =
  (open: boolean, claim: any) =>
  (event: KeyboardEvent | MouseEvent) => {
    setSelectedClaim(claim);
    setDrawerOpen(open);
    // Refetch table on drawer close in case a claim was updated
    if (!open) {
      claims.refetch();
    }
  };

  return (
    <>
      <Drawer
          anchor={'right'}
          open={drawerOpen}
          onClose={toggleDrawer(false, null)}>
        <MatchClaimsDrawer claim={selectedClaim} closeDrawer={toggleDrawer(false, null)}/>
      </Drawer>
      <TitleHeader title="Match Claims" />
      <TableWrapper title='Match Claims' columns={matchClaimsColumnDefs}>
        <SortingTable title='Match Claims'
            queryResult={claims}
            columns={matchClaimsColumnDefs}
            onRowClick={onRowClick}
            queryResultData={claims?.data?.getClaims?.filter(claim => claim.isDemo !== true)} />
      </TableWrapper>
    </>

  );
}

function MatchClaimsDrawer(props: {claim: any, closeDrawer: (event: KeyboardEvent | MouseEvent) => void}) {

  const { claim, closeDrawer } = props

  if(!claim) return null;

  const possibleMatches = useQuery(getPossibleClaimMatches, {variables: {
      externalLocation: claim?.externalLocation,
      numberOfMatches: 1000,
      claimId: claim?.id,
      currentConfirmedLocationId: claim?.locationConfirmed ? claim?.locationId : null
    },
    fetchPolicy: 'no-cache'
  })

  // These stateful variables are used to track the matchedLocatoin used to populate the red/green text at the top of the drawer
  const [matchedLocation, setMatchedLocation] = useState(
      possibleMatches?.data?.getPossibleMatches?.filter(row => row.locationConfirmed === true)
  )

  // This useEffect hook is to track if the matched location changes
  useEffect(() => {
    setMatchedLocation(      
        possibleMatches?.data?.getPossibleClaimMatches?.filter(row => row.locationConfirmed === true)[0]
    )
  }, [
    JSON.stringify(possibleMatches?.data?.getPossibleClaimMatches.filter(row => row.locationConfirmed === true)),
  ])

  return (
    <Box
        sx={muiDrawer}
        role="presentation"    
        className="claim-details claim-details__with-table">
      <div className="claim-details__with-table--title">
        <TitleValuePair
            title={'External Location'} 
            value={claim?.externalLocation} />
      </div>
      {!matchedLocation?.locationConfirmed &&
        <span className="claim-details--status--basis">
          This claim's location is not confirmed.
        </span>
      }
      {matchedLocation?.locationConfirmed &&
        <span className="claim-details--status--basis claim-details--status--basis__success">
          {`This claim's location is confirmed as ${matchedLocation?.matchedLocation}.`}
        </span>
      }
      <pre style={{fontSize: 10}}>{JSON.stringify(claim, null, '\t')}</pre>
      <TableWrapper title='Possible Matches' columns={possibleClaimMatchesColumnDefs}>
        <SortingTable title='Possible Matches'
            columns={possibleClaimMatchesColumnDefs}
            queryResult={possibleMatches}
            queryResultData={possibleMatches?.data?.getPossibleClaimMatches}
            uniqueKey={'locationId'} />
        </TableWrapper>
    </Box>
  )
}

export default MatchClaims
