import React, { JSXElementConstructor, PropsWithChildren, ReactElement } from "react";

// UI - libs
import { Alert } from "@mui/material";

// Data
import { useQuery } from "@apollo/client";
import { getPermission } from "../queries";

// Hooks & State
import RouterHelper from "../utilities/router-helper";

interface AccessControlledProps {
  type?: string; // Corresponding model name, e.g. 'Location', 'User', 'Relationship'
  id?: number; // id of the resource (found in db)
  permission?: string; // Permission name, e.g. 'view', 'edit', 'delete', see ability.js
  showError?: boolean; // Whether to show an error message if the user does not have access
  controlBy?: "role" | "feature"; // "role" mode checks the user's role, "feature" mode checks the user's feature flags
  featureFlag?: string | undefined; // feature flags to check for when in "feature" mode
  routerHelper?: RouterHelper;
  propsOverrides?: { [key: string]: any }; // List of props to ignore when cloning the children
  alternateRender?: ReactElement<any, string | JSXElementConstructor<any>>; // Alternate component to render if the user does not have access
}

// This component allows arbitrary React to conditionally render based on
// whether the user has access to the specified objectType/objectId/permission.
// This should be used in preference to any explicit role checking.
// This component can also be used to check for per-user or per-relationship feature access
// for individual features (such as beta features)
export default function AccessControlled<T>({
  children,
  type,
  id,
  permission,
  showError = false,
  controlBy = "role",
  featureFlag,
  routerHelper,
  propsOverrides = {},
  alternateRender = null,
  ...other
}: PropsWithChildren<AccessControlledProps & T>) {
  const permissionQuery = useQuery(getPermission, {
    variables: { objectType: type, objectId: id, permission: permission },
    skip: controlBy !== "role" || !id,
  });

  // If this is erroneously called with no object ID, deny access instead of crashing.  This can happen spuriously as pages load and unload.
  if (!id) return null;

  // If no data is returned and we are in role mode
  if (controlBy === "role" && !permissionQuery.data) return null;

  if (controlBy === "role") {
    // If the user has adequate role permissions to view this resource, we can show them the content
    if (permissionQuery.data.getPermission.allowed) {
      // Use cloneElement to get a Tab to work when they are not directly nested under a Tabs.
      return (
        <>
          {React.Children.map(children, (child) => {
            if (!child) return null;
            return React.cloneElement(child as ReactElement<any, string | JSXElementConstructor<any>>, other);
          })}
        </>
      );
    } else if (alternateRender) {
      /* If we have an alternate render, show that instead */
      return alternateRender;
    } else if (showError) {
      /* Display an error to the user if they do not have access */
      return <Alert severity="error">You do not have access to this.</Alert>;
    } else {
      return null;
    }
  } else if (controlBy === "feature") {
    const featureFlags = routerHelper.getFeatureFlags();
    // If the user or relationship has this feature flag enabled, we can show them the content
    if (featureFlags.includes(featureFlag)) {
      // If we are feature controlling only text, return as is
      // but wrap in fragment to help typescript understand
      if (typeof children === "string") return <>{children}</>;

      // Manually override any inherited props from a parent component with values to pass to a child
      const coalescedProps = { ...other, ...propsOverrides };

      /* Loop through each of the children in a react-friendly way to render potentially multiple children */
      return (
        <>
          {React.Children.map(children, (child) => {
            if (!child) return null;
            return React.cloneElement(child as ReactElement<any, string | JSXElementConstructor<any>>, coalescedProps);
          })}
        </>
      );
    } else {
      return alternateRender;
    }
  }
}
