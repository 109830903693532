import { Star } from "@mui/icons-material";
import { Box, LinearProgress, Stack, Theme, Typography, linearProgressClasses } from "@mui/material";
import { amber } from "@mui/material/colors";

interface ReputationRatings {
  ratings: {
    1: number;
    2: number;
    3: number;
    4: number;
    5: number;
  };
}

export function ReputationRatings({ ratings }: ReputationRatings) {

  const maxReviewCount = Math.max(...Object.values(ratings).map((count) => count));

  return (
    <Box sx={{ flex: 1 }}>
      <Typography variant="body1" sx={{ mb: 1 }}>
        By Rating
      </Typography>
      <Stack spacing={1} sx={{ width: "100%" }}>
        <ReputationRatingRow rating={5} count={ratings["5"]} max={Math.max(1, maxReviewCount)} />
        <ReputationRatingRow rating={4} count={ratings["4"]} max={Math.max(1, maxReviewCount)} />
        <ReputationRatingRow rating={3} count={ratings["3"]} max={Math.max(1, maxReviewCount)} />
        <ReputationRatingRow rating={2} count={ratings["2"]} max={Math.max(1, maxReviewCount)} />
        <ReputationRatingRow rating={1} count={ratings["1"]} max={Math.max(1, maxReviewCount)} />
      </Stack>
    </Box>
  );
}

export function ReputationRatingRow({ rating, count, max }) {
  return (
    <Stack direction="row" spacing={0.5} sx={{ flex: 1 }} alignItems="center">
      <Typography variant="caption">{rating}</Typography> <Star sx={{ height: 16, width: "auto" }} />
      <LinearProgress
        variant="determinate"
        value={(count / max) * 100}
        sx={{
          flex: 1,
          [linearProgressClasses.bar]: {
            strokeLinecap: "round",
          },
          height: 10,
          borderRadius: 5,
          [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: (theme: Theme) => theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
          },
          [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 5,
            backgroundColor: amber[400],
          },
        }}
      />
      <Typography variant="caption" sx={{ color: (theme: Theme) => theme.palette.grey.A100, width: 30 }}>
        {count}
      </Typography>
    </Stack>
  );
}
