import { useState, useEffect } from "react";
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { stateAbbr } from "../../utilities/field-enums";
import { ProtectionLevels } from "../../common";
import SetupDataService from "../../services/setup.service";
import {
  ESS_TEXTING_OPT_IN_INSTRUCTIONS,
  STOREDGE_TEXTING_OPT_IN_INSTRUCTIONS,
  WSS_TEXTING_OPT_IN_INSTRUCTIONS,
} from "./FMSTextOptInInstructions";

interface LocationSettingsFacilityDetailsProps {
  location: any; // # todo track down type
  isAdmin: boolean;
  loading: boolean;
  setLoading: (val: boolean) => void;
  setErrorMessage: (message: string) => void;
  onClose: () => void;
}

export default function FacilityDetails({
  location,
  isAdmin,
  loading,
  setLoading,
  setErrorMessage,
  onClose,
}: LocationSettingsFacilityDetailsProps) {
  const [errors, setErrors] = useState<{
    name?: { msg?: string };
    address?: { msg?: string };
    city?: { msg?: string };
    state?: { msg?: string };
    postalCode?: { msg?: string };
    revenueSharePercent?: { msg?: string };
    landingWebsiteUrl?: { msg?: string };
    privacyPolicyUrl?: { msg?: string };
    textingOptInInstructions?: { msg?: string };
  }>({});

  const [name, setName] = useState<string>(location.name ?? "");
  const [address, setAddress] = useState<string>(location.address ?? "");
  const [city, setCity] = useState<string>(location.city ?? "");
  const [state, setState] = useState<string>(location.state ?? "");
  const [postalCode, setPostalCode] = useState<string>(location.postalCode ?? "");
  const [revenueSharePercent, setRevenueSharePercent] = useState<string>(location.revenueSharePercent ?? "");
  const [insightsEnabled, setInsightsEnabled] = useState<boolean>(location.insightsEnabled);
  const [insuranceEnabled, setInsuranceEnabled] = useState<boolean>(location.insuranceEnabled);
  const [customProtectionLevels, setCustomProtectionLevels] = useState<boolean>(location.protectionLevelValues);
  const [protectionLevels, setProtectionLevels] = useState(location.protectionLevelValues);
  const [landingWebsiteUrl, setLandingWebsiteUrl] = useState(location.landingWebsiteUrl);
  const [privacyPolicyUrl, setPrivacyPolicyUrl] = useState(location.privacyPolicyUrl);
  const [textingOptInInstructions, setTextingOptInInstructions] = useState(location.textingOptInInstructions);
  const [includeTextingOptInInstructions, setIncludeTextingOptInInstructions] = useState<boolean>(
    Boolean(location.textingOptInInstructions),
  );
  const [providedFMSTextingOptInInstructions, setProvidedFMSTextingOptInInstructions] = useState<string>("");

  const instructionsMap = {
    storedge: STOREDGE_TEXTING_OPT_IN_INSTRUCTIONS,
    wss: WSS_TEXTING_OPT_IN_INSTRUCTIONS,
    ess: ESS_TEXTING_OPT_IN_INSTRUCTIONS,
  };

  const accountTypeKey = location.accountType?.trim().toLowerCase();

  const updateTextingOptInInstructions = () => {
    const defaultInstructions = textingOptInInstructions || providedFMSTextingOptInInstructions || "";
    setTextingOptInInstructions(instructionsMap[accountTypeKey] || defaultInstructions);
  };

  useEffect(() => {
    updateTextingOptInInstructions();
  }, [location.accountType]);

  useEffect(() => {
    // Set providedFMSTextingOptInInstructions on initial load if textingOptInInstructions has a value
    if (includeTextingOptInInstructions && textingOptInInstructions) {
      if (location.accountType) {
        setProvidedFMSTextingOptInInstructions(`${location.accountType} Texting Opt In Instructions Included`);
      } else {
        setProvidedFMSTextingOptInInstructions("");
      }
    }
  }, [includeTextingOptInInstructions, textingOptInInstructions, location.accountType]);

  const saveLocationDetails = async function () {
    const locationClone = {
      ...location,
      name,
      address,
      city,
      state,
      postalCode,
      insightsEnabled,
      insuranceEnabled,
      protectionLevelValues: customProtectionLevels ? protectionLevels : null,
      revenueSharePercent: revenueSharePercent === "" ? null : revenueSharePercent,
      landingWebsiteUrl,
      privacyPolicyUrl,
      textingOptInInstructions,
    };

    setLoading(true);
    setErrors({});
    const response = await SetupDataService.addLocation(locationClone);
    setLoading(false);

    if (response.data.errors) {
      setErrors(response.data.errors);
      setErrorMessage("A problem occurred while saving the location.");
      return;
    }

    // Update providedFMSTextingOptInInstructions only after saving
    if (textingOptInInstructions && textingOptInInstructions.trim() !== "") {
      setProvidedFMSTextingOptInInstructions("Texting Opt In Instructions Provided");
    } else {
      setProvidedFMSTextingOptInInstructions("No Texting Opt In Instructions");
    }

    onClose();
  };

  let label = "Include Texting Opt In Instructions";
  if (includeTextingOptInInstructions) {
    if (instructionsMap[accountTypeKey]) {
      label = `${location.accountType.toUpperCase()} Texting Opt In Instructions Included`;
    } else {
      label = "Provide Texting Opt In Instructions";
    }
  }

  return (
    <>
      <Paper
        sx={{
          maxHeight: 400,
          overflow: "auto",
          backgroundColor: "inherit",
          boxShadow: "none",
          padding: 1,
        }}
      >
        <TextField
          name="name"
          label={"Name"}
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          sx={{ marginBottom: 1, width: "100%" }}
          helperText={errors.name?.msg}
        />
        <TextField
          className="tw-w-full"
          name="address"
          label={"Address"}
          type="address"
          value={address}
          onChange={(e) => setAddress(e.target.value)}
          sx={{ marginBottom: 1, width: "100%" }}
          helperText={errors.address?.msg}
        />
        <Grid className="split-input-fields" container spacing={2}>
          <Grid item xs={4} className="input-grid">
            <TextField
              className="tw-w-full"
              name="city"
              label={"City"}
              type="city"
              value={city}
              onChange={(e) => setCity(e.target.value)}
              sx={{ marginBottom: 1, width: "100%" }}
              helperText={errors.city?.msg}
            />
          </Grid>
          <Grid item xs={4} className="input-grid">
            <FormControl fullWidth>
              <InputLabel id="state-selector-label">State</InputLabel>
              <Select
                autoWidth
                id="state-selector"
                labelId="state-selector-label"
                label="State"
                name="state"
                value={state}
                onChange={(e) => setState(e.target.value)}
              >
                {stateAbbr.map((state) => (
                  <MenuItem value={state} key={state}>
                    {state}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{errors.state?.msg}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={4} className="input-grid">
            <TextField
              name="postalCode"
              label={"Postal Code"}
              type="postalCode"
              value={postalCode}
              onChange={(e) => setPostalCode(e.target.value)}
              helperText={errors.postalCode?.msg}
            />
          </Grid>
        </Grid>

        {isAdmin && (
          <div className="tw-flex tw-flex-col admin-only-tools">
            <Typography variant="body2" sx={{ marginTop: 2 }}>
              Fields below are only visible to admins
            </Typography>
            <FormControlLabel
              sx={{ marginRight: "-5px" }}
              control={<Checkbox checked={insightsEnabled} onChange={(e) => setInsightsEnabled(e.target.checked)} />}
              label="Insights Enabled"
            />
            <FormControlLabel
              sx={{ marginRight: "-5px" }}
              control={<Checkbox checked={insuranceEnabled} onChange={(e) => setInsuranceEnabled(e.target.checked)} />}
              label="Insurance Enabled"
            />

            <div className="tw-flex tw-flex-row">
              <ProtectionLevels protectionLevels={protectionLevels} setProtectionLevels={setProtectionLevels} />
            </div>

            {/* <Divider sx={{ margin: "5px", color: "grey" }} /> */}

            <TextField
              name="revenueSharePercent"
              label="Location-Specific Revenue Share Percentage (SafeLease Share)"
              type="number"
              sx={{ marginTop: 4, width: "100%" }}
              value={revenueSharePercent}
              onChange={(e) => setRevenueSharePercent(e.target.value)}
              helperText={errors.revenueSharePercent?.msg}
            />

            <Typography variant="button" sx={{ marginTop: 2 }}>
              KYC Verification URLs
            </Typography>

            <TextField
              name="landingWebsiteUrl"
              label="Landing Website URL"
              type="text"
              sx={{ marginTop: 1, width: "100%" }}
              value={landingWebsiteUrl}
              onChange={(e) => setLandingWebsiteUrl(e.target.value)}
              helperText={errors.landingWebsiteUrl?.msg}
            />

            <TextField
              name="privacyPolicyUrl"
              label="Privacy Policy URL"
              type="text"
              sx={{ marginTop: 1, width: "100%" }}
              value={privacyPolicyUrl}
              onChange={(e) => setPrivacyPolicyUrl(e.target.value)}
              helperText={errors.privacyPolicyUrl?.msg}
            />
            <FormControlLabel
              sx={{ marginRight: "-5px" }}
              control={
                <Checkbox
                  checked={includeTextingOptInInstructions}
                  onChange={(e) => setIncludeTextingOptInInstructions(e.target.checked)}
                />
              }
              label={label}
            />

            {!providedFMSTextingOptInInstructions && includeTextingOptInInstructions && (
              <TextField
                name="textingOptInInstructions"
                label="Texting Opt-In Instructions"
                type="text"
                sx={{ marginTop: 1, width: "100%" }}
                multiline
                rows={4}
                value={textingOptInInstructions}
                onChange={(e) => setTextingOptInInstructions(e.target.value)}
                helperText={errors.textingOptInInstructions?.msg}
                placeholder="Enter instructions for how to opt in to texting"
              />
            )}
          </div>
        )}
      </Paper>
      {loading ? (
        <CircularProgress />
      ) : (
        <Button className="modal-login-button" onClick={saveLocationDetails}>
          Save Details
        </Button>
      )}
    </>
  );
}
