/* React */
import { useState } from "react";

/* UI - libs */
import {
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  Tooltip,
} from "@mui/material";

/* UI - internal */
import PrivatePolicyDeclarationsPageForm from "./PrivatePolicyDetailsForm";
import {
  validationStatusColors,
  validationStatusLabels,
} from "../PrivatePolicyReportDataGrid/privatePolicyReportColumnDefs";

/* Data */
import { getAttachmentEphemeralLink } from "../../../queries";
import { useQuery } from "@apollo/client";
import { PrivatePolicyValidationStatus } from "../../../reports/useReportsStore/privatePolicyReportSlice";

/* Libs */
import _ from "lodash";
import { Download, FileDownloadOff, FileOpen, ErrorOutline } from "@mui/icons-material";
import dayjs from "dayjs";
import { ExpandedPDFModal } from "./ExpandedPDFModal";

interface PrivatePolicyDetailsModalProps {
  open: boolean;
  handleClose: () => void;
  generalUnit: any; // todo some intersection of data types
}

/* Display our current information about the private policy */
/* In the future we may want to display declaration pages inline */
/* If the user is an admin, they can also see the approve/deny buttons */
export function PrivatePolicyDetailsModal({
  open,
  handleClose,
  generalUnit,
}: PrivatePolicyDetailsModalProps) {
  const privatePolicyValidationStatus =
    generalUnit?.privatePolicyValidationStatus;

  const [submittingType, setSubmittingType] =
    useState<PrivatePolicyValidationStatus | null>(null);
  const [isPDFModalOpen, setIsPDFModalOpen] = useState(false);
  const [attachmentError, setAttachmentError] = useState<string | null>(null);

  /* Get a link to download the Declaration Page pdf */
  const attachmentsQuery = useQuery(getAttachmentEphemeralLink, {
    variables: {
      attachmentId: generalUnit?.privatePolicyDeclarationPageAttachmentId, // declarations page URL
    },
    skip: !generalUnit?.privatePolicyDeclarationPageAttachmentId,
    onError: (error) => {
      setAttachmentError(error.message);
    },
  });

  const declarationsPageUrl =
    attachmentsQuery.data?.getAttachmentEphemeralLink?.url;

  /* Intercept requests to close while loading */
  const handleCloseModal = () => {
    if (submittingType) return;
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleCloseModal} maxWidth="sm" fullWidth>
      <DialogTitle sx={{ color: "#152744" }}>
        Private Policy Declarations Page for {generalUnit?.privatePolicyNumber}
      </DialogTitle>
      <DialogContent dividers sx={{ bgcolor: "rgba(245,245,245,1)" }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Stack
              direction="column"
              justifyContent="space-between"
              sx={{ height: "100%" }}
            >
              <PrivatePolicyDeclarationsPageForm
                privatePolicy={{
                  ...generalUnit,
                }}
                label="Private Policy Information"
                properties={[
                  {
                    label: "Validation Status",
                    propertyName: "privatePolicyValidationStatus",
                    renderer: (value) => (
                      <Chip
                        sx={{
                          bgcolor: validationStatusColors[value],
                          color: "white",
                          fontWeight: "bold",
                        }}
                        size="small"
                        label={_.capitalize(validationStatusLabels[value])}
                      />
                    ),
                  },
                  {
                    label: "Policy Source",
                    propertyName: "privatePolicySource",
                  },
                  {
                    label: "Tenant",
                    propertyName: "tenantName",
                  },
                  {
                    label: "Policy Expiration",
                    propertyName: "privatePolicyExpiration",
                    renderer: (value) => {
                      if (!value) return null;
                      return dayjs(value).format("MM/DD/YYYY");
                    },
                  },
                  {
                    label: "Company name",
                    propertyName: "privatePolicyCompanyName",
                  },
                  {
                    label: "Policy Number",
                    propertyName: "privatePolicyNumber",
                  },
                  {
                    label: "Unit Name",
                    propertyName: "unitName",
                  },
                  {
                    label: "Declaration Page",
                    propertyName: "privatePolicyDeclarationPageAttachmentId",
                    renderer: () => (
                      <Tooltip
                        title={
                          attachmentError
                            ? attachmentError
                            : declarationsPageUrl
                              ? "View Declaration Page"
                              : "No Declaration Page"
                        }
                        arrow
                      >
                        <IconButton
                          disabled={!declarationsPageUrl}
                          size="small"
                          onClick={() => {
                            if (declarationsPageUrl) {
                              setIsPDFModalOpen(true);
                            }
                          }}
                        >
                          {attachmentError ? (
                            <ErrorOutline />
                          ) : (
                            <>
                              {declarationsPageUrl ? (
                                <FileOpen />
                              ) : (
                                <FileDownloadOff />
                              )}
                            </>
                          )}
                        </IconButton>
                      </Tooltip>
                    ),
                  },
                ]}
              />
            </Stack>
          </Grid>
        </Grid>
        {isPDFModalOpen && <ExpandedPDFModal open={true} handleClose={() => setIsPDFModalOpen(false)} file={declarationsPageUrl} fileName={"Declaration Page"} />}
      </DialogContent>
    </Dialog>
  );
}
