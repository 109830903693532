import { SafeleaseRoute } from './safelease-route';
import Settings from '../settings/settings';
import { Switch, useRouteMatch } from 'react-router-dom';

export function SettingsRouter({ routerHelper, isAdminRoute }) {
  const { path } = useRouteMatch();

  return (
    <Switch>
      {/* This additional fragment is necessary to prevent error: useQuery.ts:177 Warning: React does not recognize the `computedMatch` prop on a DOM element. If you intentionally want it to appear in the DOM as a custom attribute, spell it as lowercase `computedmatch` instead. If you accidentally passed it from a parent component, remove it from the DOM element.
      See https://github.com/ant-design/ant-motion/issues/185 */}
      <> 
      {isAdminRoute ? (
        <div>
          <SafeleaseRoute path={`${path}/user/:relationshipId`} redirect>
            <Settings routerHelper={routerHelper} tab="userSettings" />
          </SafeleaseRoute>
          <SafeleaseRoute path={`${path}/locations/:relationshipId`} redirect>
            <Settings routerHelper={routerHelper} tab="locationSettings" />
          </SafeleaseRoute>
          <SafeleaseRoute path={`${path}/billing/:relationshipId`} redirect>
            <Settings routerHelper={routerHelper} tab="billingSettings" />
          </SafeleaseRoute>
          <SafeleaseRoute path={`${path}/auto_protect/:relationshipId`} redirect>
            <Settings routerHelper={routerHelper} tab="autoProtectSettings" />
          </SafeleaseRoute>
          <SafeleaseRoute path={`${path}/private_policy/:relationshipId`} redirect>
            <Settings routerHelper={routerHelper} tab="privatePolicySettings" />
          </SafeleaseRoute>
          <SafeleaseRoute path={`${path}/jobs/:relationshipId`} redirect>
            <Settings routerHelper={routerHelper} tab="jobSettings" />
          </SafeleaseRoute>
        </div>
      ) : (
        <div>
          <SafeleaseRoute path={`${path}/user`}>
            <Settings routerHelper={routerHelper} tab="userSettings" />
          </SafeleaseRoute>
          <SafeleaseRoute path={`${path}/locations`} redirect > {/* INSIGHTS TODO: remove the `redirect` (SA-1914) */}
            <Settings routerHelper={routerHelper} tab="locationSettings" />
          </SafeleaseRoute>
          <SafeleaseRoute path={`${path}/billing`} redirect={!routerHelper.getIsInsuranceUser()} >
            <Settings routerHelper={routerHelper} tab="billingSettings" />
          </SafeleaseRoute>
          <SafeleaseRoute path={`${path}/auto_protect`} redirect={!routerHelper.getIsAutoProtectUser()} >
            <Settings routerHelper={routerHelper} tab="autoProtectSettings" />
          </SafeleaseRoute>
          <SafeleaseRoute path={`${path}/notifications`} redirect={!routerHelper.getIsInsuranceUser()} >
            <Settings routerHelper={routerHelper} tab="notificationSettings" />
          </SafeleaseRoute>
          <SafeleaseRoute path={`${path}/contact`} redirect={!routerHelper.getIsInsuranceUser()} >
            <Settings routerHelper={routerHelper} tab="contactSettings"/>
          </SafeleaseRoute>
          <SafeleaseRoute path={`${path}/organization`} redirect={!routerHelper.getIsInsuranceUser()} >
            <Settings routerHelper={routerHelper} tab="organizationSettings"/>
          </SafeleaseRoute>
          <SafeleaseRoute path={`${path}/my-account`} redirect>
            <Settings routerHelper={routerHelper} tab="myAccountSettings"/>
          </SafeleaseRoute>
          <SafeleaseRoute exact path={`${path}`}>
            <Settings routerHelper={routerHelper} />
          </SafeleaseRoute>
        </div>
      )}
      </>
    </Switch>
  );
}
