import { ErrorOutline, ErrorOutlineOutlined } from "@mui/icons-material";
import { Alert, Theme, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { Facility_Reputation as Facility } from "@safelease/service-utilities";

type DisplayType = "icon" | "narrowCard" | "fullCard";

interface SetupValidationDisplayProps {
  facility: Facility;
  displayType: DisplayType;
  fallbackComponent?: React.ReactElement; // Optional component to render if there are no missing fields
}

interface Validation {
  type: keyof Facility; // The name of the property being validated
  isMissing: boolean; // The check to see if the field is missing
  shortMessage: string; // The short error message for narrow width component
  longMessage: string; // The long error message for full width component
}

export const SetupValidationDisplay = ({ facility, displayType = "narrowCard", fallbackComponent = null }: SetupValidationDisplayProps) => {
  // Array of validation rules with type (property name), missing check, short message, and long message
  const validations: Validation[] = [
    {
      type: "logoUrlLarge",
      isMissing: !facility.logoUrlLarge || facility.logoUrlLarge.length === 0,
      shortMessage: "missing Facility Logo",
      longMessage: "Facility is missing a logo. A logo helps customers identify the facility on various platforms.",
    },
    {
      type: "notificationEmail",
      isMissing: !facility.notificationEmail || facility.notificationEmail.length === 0,
      shortMessage: "missing Notification Email",
      longMessage:
        "Notification Email is missing. Notifications of new Private/Diverted Reviews cannot be sent to the facility without it.",
    },
    {
      type: "companyEmail",
      isMissing: !facility.companyEmail || facility.companyEmail.length === 0,
      shortMessage: "missing Company Email",
      longMessage:
        "Facility is missing a Company Email. Communication and updates will not reach the facility without a valid email address.",
    },
    {
      type: "googlePlaceId",
      isMissing: !facility.googlePlaceId || facility.googlePlaceId.length === 0,
      shortMessage: "missing Google Place ID",
      longMessage: "Facility is missing a Google Place ID. Auto-campaigns are disabled until a Google Place ID is added.",
    },
  ];

  // Filter out passing validations
  const missingMessages = validations.filter((validation) => validation.isMissing);

  // If no fields are missing, return fallbackComponent
  if (missingMessages.length === 0) {
    return fallbackComponent;
  }

  // Icon
  if (displayType === "icon") {
    return <ErrorOutline sx={{ color: "rgb(178,34,34) !important", marginRight: 1 }} />;
  }

  // Narrow width, small card
  if (displayType === "narrowCard") {
    return (
      <Alert severity="error" icon={false} sx={{ borderRadius: 1, padding: 1, alignItems: "left" }}>
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          sx={{ color: (theme: Theme) => theme.palette.grey.A100, padding: 0 }}
        >
          {missingMessages.map((validation, index) => (
            <Typography key={index} variant="subtitle2">
              <ErrorOutlineOutlined sx={{ fontSize: 16 }} /> {validation.shortMessage}
            </Typography>
          ))}
        </Stack>
      </Alert>
    );
  }

  // Full width, long card
  return (
    <Alert
      severity="error"
      icon={false}
      sx={{ minWidth: 500, flex: 1, display: "flex", alignItems: "left", fontSize: "16px", lineHeight: "normal" }}
    >
      <Stack direction="column" justifyContent="flex-start" alignItems="flex-start">
        {missingMessages.map((validation, index) => (
          <Typography key={index} variant="subtitle2">
            <ErrorOutlineOutlined sx={{ fontSize: 16 }} /> {validation.longMessage}
          </Typography>
        ))}
      </Stack>
      <Stack direction="row" justifyContent="flex-end" alignItems="flex-end"></Stack>
    </Alert>
  );
};
