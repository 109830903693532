import React from "react";
import { Typography } from "@mui/material";
import GoBackButton from "./GoBackButton";
import { Box } from "@mui/system";
import { styled } from "@mui/material/styles";
import LaptopIcon from "@mui/icons-material/Laptop";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

const FmsConnectionRow = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "16px",
  border: "1px solid #E9E9E9",
  borderRadius: "4px",
  marginTop: "15px",
}));

const StyledText = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "19px",
  color: "#031E30",
}));

const fmsConnectionList = [
  "SiteLink",
  "storeEDGE",
  "Easy Storage Solutions",
  "Web Self Storage",
];

interface FmsConnectionRowContentProps {
  fms: string;
}

const FmsConnectionRowContent: React.FC<FmsConnectionRowContentProps> = ({
  fms,
}) => {
  return (
    <>
      <Box
        // Aligns icon and text vertically center
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <LaptopIcon
          sx={{
            height: "24px",
            width: "24px",
          }}
        />
        <StyledText ml={1}>{fms}</StyledText>
      </Box>
      <ChevronRightIcon
        sx={{
          height: "24px",
          width: "24px",
          color: "#C9C9C9",
        }}
      />
    </>
  );
};

interface ConnectNewFmsViewProps {
  handleGoBackClick: () => void;
}

const ConnectNewFmsView: React.FC<ConnectNewFmsViewProps> = ({
  handleGoBackClick,
}) => {
  return (
    <>
      <GoBackButton onClick={handleGoBackClick} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <StyledText mt={2}>New FMS connection</StyledText>
        {fmsConnectionList.map((fms) => {
          return (
            <FmsConnectionRow key={fms}>
              <FmsConnectionRowContent fms={fms} />
            </FmsConnectionRow>
          );
        })}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <StyledText
            mt={2}
            sx={{
              opacity: "0.7",
            }}
          >
            Don't see your FMS?
          </StyledText>
          <StyledText
            mt={2}
            ml={0.5}
            sx={{
              color: "#031E30",
              fontWeight: 700,
            }}
          >
            Contact us
          </StyledText>
        </Box>
      </Box>
    </>
  );
};

export default ConnectNewFmsView;
