import { useAuth } from "../../auth.js";
import { useEffect } from "react";
import {
  FormControl,
  Select,
  MenuItem,
  TableRow as MuiTableRow,
  TableCell,
  Button,
  Stack,
} from "@mui/material";
import { getLocations, getRelationshipsSelector } from "../../queries";
import { useQuery, QueryResult } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { reportsColumnDefs } from "../../utilities/column-defs";
import TitleHeader from "../../shared/title-header";
import SortingTable from "../../shared/sorting-table";
import { muiSelect } from "../../styles/mui-overrides";
import RouterHelper from "../../utilities/router-helper.js";
import { TableRow } from "../../utilities/table-state";
import TableWrapper from "../../shared/table-wrapper";
import { mixpanelEventHandler } from "../../utilities/reactMixpanelHandler";
import Loader from "../../shared/Loader";
import Error from "../../shared/Error";
import { ghostButtonNavy } from "../../styles/mui-overrides";
import { ArrowForward } from "@mui/icons-material";

const totalsRowRenderer = (totalsRow: TableRow) => {
  if (!totalsRow) return null;
  return (props: { tableTitle: string }) => (
    <MuiTableRow
      sx={{
        backgroundColor: "#1876d2",
        "&:last-child td, &:last-child th": { border: 0 },
      }}
    >
      <TableCell
        component="th"
        scope="row"
        sx={{ color: "white", fontWeight: "bold", padding: "9px" }}
      >
        Totals
      </TableCell>
      {reportsColumnDefs.map((col, i) => {
        if (i === 0) return null;
        return (
          <TableCell
            sx={{ color: "white", fontWeight: "bold", padding: "9px" }}
          >
            {totalsRow.hasOwnProperty(col.key) ? totalsRow[col.key] : ""}
          </TableCell>
        );
      })}
    </MuiTableRow>
  );
};

/**
 * @depricated use ReportsPage.tsx instead
 */
export function LegacyReports(props: { routerHelper: RouterHelper }) {
  const { routerHelper } = props;
  const auth = useAuth();
  const history = useHistory();

  //TODO maybe non manager users shouldn't even have a routerHelper, but this works for now
  const relationshipId =
    routerHelper.getRelationshipId() || auth.user.relationshipId;

  const relationships: QueryResult = useQuery(getRelationshipsSelector, {
    skip: !auth.user.isAdmin,
  });
  const locations: QueryResult = useQuery(getLocations, {
    variables: { relationshipId },
  });

  const navigateToReportDetails = (row: { [index: string]: any }) => {
    return (event: KeyboardEvent | MouseEvent) => {
      history.push(`/report_details/${relationshipId}/${row.id}`);
    };
  };

  useEffect(() => {
    document.body.classList.add("reports");
    mixpanelEventHandler("View Reports");
    return () => {
      document.body.classList.remove("reports");
    };
  }, []);

  if (locations.loading || relationships.loading) return <Loader />;
  if (locations.error || relationships.error) return <Error />;

  const currentMonth = new Date();
  currentMonth.setHours(0, 0, 0, 0);
  currentMonth.setDate(1);

  const totalsRow = locations.data.getLocations.reduce(
    (totals, location) => {
      totals.occupiedUnits =
        totals.occupiedUnits + (location.recentData?.reportOccupiedUnits || 0);
      totals.insured =
        totals.insured + (location.recentData?.reportSafeleaseUnits || 0);
      return totals;
    },
    { occupiedUnits: 0, insured: 0, attachRate: 0 }
  );

  totalsRow.attachRate = totalsRow.insured
    ? totalsRow.insured / totalsRow.occupiedUnits
    : 0;
  totalsRow.attachRate = (totalsRow.attachRate * 100).toFixed(0) + "%";

  function modifyReportsTableRows(): TableRow[] {
    return locations.data.getLocations.map((location) => {
      const locationRow = { ...location };
      locationRow.occupiedUnits = location.recentData?.reportOccupiedUnits;
      locationRow.insured = location.recentData?.reportSafeleaseUnits;
      locationRow.attachRate = location.recentData?.reportAttachRate;
      return locationRow;
    });
  }

  return (
    <div>
      <TitleHeader title="Reports"></TitleHeader>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        {auth.user && auth.user.isAdmin && relationships && (
          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <Select
              value={relationshipId}
              displayEmpty
              onChange={(e) =>
                routerHelper.navigateToRoute("reports", e.target.value, history)
              }
              sx={muiSelect}
            >
              {relationships?.data?.getRelationshipsSelector?.map(
                (relationship) => {
                  return (
                    <MenuItem key={relationship.id} value={relationship.id}>
                      {relationship.name}
                    </MenuItem>
                  );
                }
              )}
            </Select>
          </FormControl>
        )}
      </Stack>
      <TableWrapper title="Reports" columns={reportsColumnDefs}>
        <SortingTable
          title="Reports"
          columns={reportsColumnDefs}
          queryResult={locations}
          queryResultData={locations?.data?.getLocations}
          onRowClick={navigateToReportDetails}
          modifyRows={modifyReportsTableRows}
          totalsRowRenderer={totalsRowRenderer(totalsRow)}
        />
      </TableWrapper>
    </div>
  );
}
