import SafeleaseDrawer from '../shared/safelease-drawer';
import { Formatters } from '../utilities/formatters';

export default function UpcomingJobsDrawer(props: {
  close: () => void;
  isOpen: boolean;
  jobsData: any;
}) {
  const { jobsData, isOpen, close } = props;
  return (
    <SafeleaseDrawer isOpen={isOpen} close={close} title="Upcoming Dates">
      <div className="claim-details__email-setting-drawer--body">
        <div className="tw-flex tw-justify-between tw-mt-4 tw-mb-2 tw-p-4">
          <div className="tw-font-semibold tw-w-1/3 tw-flex tw-justify-start">Date</div>
          <div className="tw-font-semibold tw-w-1/3 tw-flex tw-justify-center">Event Type</div>
          <div className="tw-font-semibold tw-w-1/3 tw-flex tw-justify-end">Facility Address</div>
        </div>
        {jobsData.map((job) => (
          <JobDataRow
            key={JSON.stringify(job)}
            date={job.rawDate}
            label={job.label}
            address={job.address}
          />
        ))}
      </div>
    </SafeleaseDrawer>
  );
}

function JobDataRow(props: { key: string; date: Date; label: string; address: string }) {
  const { date, label, address } = props;
  return (
    <div className="tw-flex tw-flex-1 tw-bg-gray-100 tw-mb-2 tw-w-auto tw-h-12 tw-p-4">
      <div className="tw-w-1/3 tw-flex tw-justify-start tw-items-center">
        {date}
      </div>
      <div className="tw-w-1/3 tw-flex tw-justify-center tw-items-center">{label}</div>
      <div className="tw-w-1/3 tw-flex tw-justify-end tw-items-center">{address}</div>
    </div>
  );
}
