import { useState } from "react";

// UI - libs
import {
  Alert,
  Box,
  Button,
  StepContent,
  Stack,
  StepLabel,
  Typography,
  Tooltip,
} from "@mui/material";
import { grey } from "@mui/material/colors";

// UI - internal
import { SelectPlansStep } from "./SelectPlansStep";
import {
  SafeLeaseStepper,
  SafeLeaseStep,
  SafeLeaseStepIcon,
} from "../../../shared/SafeLeaseStepper";
import { SelectEnforcementDateStep } from "./SelectEnforcementDateStep";
import { primaryColorButton } from "../../../styles/mui-overrides";
import { ReviewStep } from "./ReviewStep";
import { SelectRelationshipStep } from "./SelectRelationshipStep";
import { SelectLocationsStep } from "./SelectLocationsStep";
import { usePlanUpgradeStore } from "../usePlanUpgradeSettingsStore";

// Utils
import dayjs from "dayjs";
import { Formatters } from "../../../utilities/formatters";
import { useSnackbar } from "notistack";
import LoadingButton from "@mui/lab/LoadingButton";
import client from "../../../utilities/apolloClient";
import { getUpgradeProtectionPlanConfigs } from "../../../queries";
import { SelectImpactedSKUsStep } from "./SelectImpactedSKUsStep";
import { Info } from "@mui/icons-material";

interface ScheduleUpgradeFormProps {
  handleClose: () => void;
}

export function ScheduleUpgradeForm({ handleClose }: ScheduleUpgradeFormProps) {
  const { enqueueSnackbar } = useSnackbar();

  const selectedRelationship = usePlanUpgradeStore(
    (state) => state.selectedRelationship
  );
  const selectedLocations = usePlanUpgradeStore(
    (state) => state.selectedLocations
  );
  const selectedEnforcementDate = usePlanUpgradeStore(
    (state) => state.enforcementDate
  );
  const numberOfImpactedUnits = usePlanUpgradeStore(
    (state) => state.numberOfImpactedUnits
  );
  const premiumBuckets = usePlanUpgradeStore((state) => state.premiumBuckets);
  const loading = usePlanUpgradeStore((state) => state.isQueueingPlanUpgrades);
  const disableContinue = usePlanUpgradeStore((state) => state.disableContinue);
  const resetForm = usePlanUpgradeStore((state) => state.resetForm);

  const handleQueuePlanUpgrades = usePlanUpgradeStore(
    (state) => state.handleQueuePlanUpgrades
  );

  const [activeStep, setActiveStep] = useState<number>(0);

  /* REVIEW STATE */
  /* All steps must be reviewed prior to setup */
  const [confirmedSteps, setConfirmedSteps] = useState<{
    [key: string]: boolean;
  }>({
    relationship: false,
    locations: false,
    plannedUpgrades: false,
    enforcementDate: false,
  });

  /* If the user tries to submit prior to reviewing all steps, show an error message */
  const [diplayReviewErrorMessage, setDisplayReviewErrorMessage] =
    useState<boolean>(false);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  /* Handle clicking the queue button */
  const handleQueuePlanUpgrade = async () => {
    if (Object.values(confirmedSteps).includes(false)) {
      setDisplayReviewErrorMessage(true);
    } else {
      setDisplayReviewErrorMessage(false);
      try {
        await handleQueuePlanUpgrades();

        /* Refetch the plan configs to update the UI */
        client.refetchQueries({ include: [getUpgradeProtectionPlanConfigs] });

        enqueueSnackbar("Plan upgrade(s) queued", { variant: "success" });
        handleClose();
        resetForm();
      } catch {
        enqueueSnackbar("Error queueing plan upgrade(s)", { variant: "error" });
      }
    }
  };

  /* Special render conditions for previewing the selected location(s) */
  const selectedLocationsLabel =
    selectedLocations.length === 0 ? (
      "Select Locations"
    ) : selectedLocations.length === 1 ? (
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="body2">Location(s)</Typography>
        <Typography variant="body2" sx={{ color: grey[500] }}>
          {selectedLocations[0].address}
        </Typography>
      </Stack>
    ) : (
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="body2">Locations</Typography>
        <Typography variant="body2" sx={{ color: grey[500] }}>
          {selectedLocations.length} locations
        </Typography>
      </Stack>
    );

  /* Each step in the stepper */
  const steps = [
    {
      label: selectedRelationship ? (
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="body2">Relationship</Typography>
          <Typography variant="body2" sx={{ color: grey[500] }}>
            {selectedRelationship.name}
          </Typography>
        </Stack>
      ) : (
        "Select Relationship"
      ),
      description: <SelectRelationshipStep />,
    },
    {
      label: selectedLocationsLabel,
      description: <SelectLocationsStep />,
    },
    {
      label:
        selectedLocations.length > 0 ? (
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Stack direction="row" alignItems="center">
              <Typography variant="body2">Configure Plans</Typography>
              <Tooltip
                title={
                  <>
                    These are the different combinations of premium & coverage
                    that units will be moved into.
                    <br />
                    NOTE: This does not change the plans available in the FMS.
                  </>
                }
              >
                <Info
                  sx={{
                    height: 20,
                    width: 20,
                    color: grey[500],
                    ml: 1,
                    cursor: "pointer",
                  }}
                />
              </Tooltip>
            </Stack>
            <Typography variant="body2" sx={{ color: grey[500] }}>
              {premiumBuckets
                .map((bucket) => Formatters.penniesToDollars(bucket.newPremium))
                .join(" / ")}
            </Typography>
          </Stack>
        ) : (
          <Stack direction="row" alignItems="center">
            Configure Plans
            <Tooltip
              title={
                <>
                  These are the different combinations of premium & coverage
                  that units will be moved into.
                  <br />
                  NOTE: This does not change the plans available in the FMS.
                </>
              }
            >
              <Info
                sx={{
                  height: 20,
                  width: 20,
                  color: grey[500],
                  ml: 1,
                  cursor: "pointer",
                }}
              />
            </Tooltip>
          </Stack>
        ),
      description: <SelectPlansStep />,
    },
    {
      label: (
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="body2">Select impacted SKUs</Typography>
          <Typography variant="body2" sx={{ color: grey[500] }}>
            {numberOfImpactedUnits > 0 &&
              `${Formatters.formatWithCommas(
                numberOfImpactedUnits
              )} units affected`}
          </Typography>
        </Stack>
      ),
      description: <SelectImpactedSKUsStep />,
    },
    {
      label: selectedEnforcementDate ? (
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack direction="row" alignItems="center">
            <Typography variant="body2">Upgrade Date</Typography>
            <Tooltip title="Units will be moved into the configured premiums/coverage SKUs on this date.">
              <Info
                sx={{
                  height: 20,
                  width: 20,
                  color: grey[500],
                  ml: 1,
                  cursor: "pointer",
                }}
              />
            </Tooltip>
          </Stack>
          <Typography variant="body2" sx={{ color: grey[500] }}>
            {dayjs(selectedEnforcementDate).format("MMM D, YYYY")}
          </Typography>
        </Stack>
      ) : (
        <Stack direction="row" alignItems="center">
          Select Upgrade Date
          <Tooltip title="Units will be moved into the configured premiums/coverage SKUs on this date.">
            <Info
              sx={{
                height: 20,
                width: 20,
                color: grey[500],
                ml: 1,
                cursor: "pointer",
              }}
            />
          </Tooltip>
        </Stack>
      ),
      description: <SelectEnforcementDateStep />,
    },
    {
      label: "Review Impact",
      description: (
        <ReviewStep
          confirmedSteps={confirmedSteps}
          setConfirmedSteps={setConfirmedSteps}
        />
      ),
    },
  ];

  /* Disable the continue button at each step if the value hasn't been properly filled out */
  const shouldDisableContinueButton = (step: number) => {
    if (step === 0) return selectedRelationship == null;
    if (step === 1) return selectedLocations.length === 0;
    if (step === 3) return selectedEnforcementDate === null;
    return false;
  };

  return (
    <Box sx={{ overflowY: "scroll" }}>
      <SafeLeaseStepper activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <SafeLeaseStep key={`step-${index}`}>
            <StepLabel StepIconComponent={SafeLeaseStepIcon}>
              {step.label}
            </StepLabel>
            <StepContent>
              {step.description}
              <Box sx={{ mb: 2 }}>
                {activeStep === 5 && diplayReviewErrorMessage && (
                  <Alert severity="error" sx={{ my: 2 }}>
                    Please review all steps and select checkboxes to continue
                  </Alert>
                )}
                <LoadingButton
                  disabled={
                    shouldDisableContinueButton(index) ||
                    loading ||
                    disableContinue
                  }
                  variant="contained"
                  disableElevation
                  onClick={
                    activeStep === 5 ? handleQueuePlanUpgrade : handleNext
                  }
                  sx={{
                    mt: 1,
                    mr: 1,
                    ...primaryColorButton,
                    fontWeight: "initial",
                  }}
                >
                  {index === steps.length - 1
                    ? "Queue Plan Upgrade"
                    : "Continue"}
                </LoadingButton>
                <Button
                  disabled={index === 0 || loading}
                  onClick={handleBack}
                  sx={{ mt: 1, mr: 1, textTransform: "none" }}
                >
                  Back
                </Button>
              </Box>
            </StepContent>
          </SafeLeaseStep>
        ))}
      </SafeLeaseStepper>
    </Box>
  );
}
