import { BaseAPI } from "./base-api";
class OrganizationAPI extends BaseAPI {
  constructor() {
    super();
  }

  async getOrganizationSettings() {
    const response = await this.axiosInstance.get(`/api/organization-settings`);

    return response;
  }

  async updatePointOfContact(userId: number, entityId: number, entityType: string) {
    const response = await this.axiosInstance.put(`/api/organization-settings/poc`, {
      userId,
      entityId,
      entityType,
    });

    return response;
  }

  async getRelationshipsSelector() {
    const response = await this.axiosInstance.get(`/api/organization-settings/relationships-selector`);

    return response;
  }

  async getOrgSettingsByRelationshipId(relationshipId: number) {
    const response = await this.axiosInstance.get(`/api/organization-settings/relationship/${relationshipId}`);

    return response;
  }
}

export const organizationApi = new OrganizationAPI();
