import { useAuth } from './auth.js';
import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useRouteMatch, useParams } from "react-router-dom";
import { Form, FormElement} from "./forms.js";
import { useQuery } from '@apollo/client';
import { Button } from '@mui/material';
import { me, getUserByToken } from './queries';
import TitleHeader from './shared/title-header';
import jwt from 'jsonwebtoken';
import UserDataService from './services/user.service';
import { mixpanelEventHandler } from './utilities/reactMixpanelHandler.tsx';
import { Image } from './image';
import Loader from './shared/Loader';
import Error from './shared/Error';

function OnboardingScreen(props) {

  return (
    <div className="onboarding-container container-fluid " >
      <div className="row">
        <div className="col left ">
          <Image className="bg-img-1" src="/images/login-bg.svg" alt="SafeLease logo" />
          <Image className="bg-img-2" src="/images/login-bg.svg" alt="SafeLease logo" />
        </div>
        <div className="col right">
        {props.children}
        </div>
      </div>
    </div>
  )
}

function UserEdit() {
  // TODO: we should invest into not clearing certain form fields upon submission,
  // but that will take significant investment into the Form component
  const auth = useAuth();
  
  const onFormAttempt = (input, cb) => {
    UserDataService.edit(input).then(response => {
      cb(response);
    });
  };

  const { loading, error, data, refetch } = useQuery(me);
  if (loading) return <Loader />;
  if (error) return <>{`Error! ${error.message}`}</>;

  const onFormSuccess = async (response) => {
    await auth.signin(response.data); // This has to happen before refetchQueries or there will be an immediate auth error.
    await refetch();
  }

  return (
      <>
        <Form onFormSuccess={onFormSuccess} onFormAttempt={onFormAttempt} submitLabel="Save changes" values={data.me} buttonClassOverride={{ paddingTop: 0, marginTop: 0}}>
          <FormElement name="name" label="Name" />
          <FormElement name="email" label="Email" type="email" />
          <FormElement name="phone" label="Phone Number" type="tel" />
          {data.me.hasPassword && (<FormElement name="currentPassword" label="Current Password" type="password" />)}
          <FormElement name="password" label={data.me.hasPassword ? "New Password" : "Set Password"} type="password" />
          <FormElement name="passwordConfirmation" label={data.me.hasPassword ? "New Password Confirmation" : "Confirm Password"} type="password" />
        </Form>
        {/* <FormMessage type={MessageTypes.success} message="Success!" /> */}
      </>
  );
}


function ResetPasswordEmail(props) {
  const history = useHistory();
  
  const onFormAttempt = (input, cb) => {
    UserDataService.resetPassword(input).then(response => {
      cb(response);
    });
  };

  useEffect(() => {
    document.body.className = 'signin';
    return () => { document.body.className = ''; }
  }, []);

  const onFormSuccess = async (response) => {
    history.push('/messages/reset');
  }

  return (
    <div className="signin-page">
      <OnboardingScreen>
        <Image src="/images/safelease_logo.svg" alt="SafeLease logo" />

        <Form onFormSuccess={onFormSuccess} onFormAttempt={onFormAttempt} submitLabel="Reset password">
          <div className="description">
            An email will be sent with a link to reset your password.
          </div>
          <FormElement name="email" label="Email" />
        </Form>
      </OnboardingScreen>
    </div>

  );
}

function ResetPassword(props) {
  const match = useRouteMatch();
  const history = useHistory();
  
  const onFormAttempt = (input, cb) => {
    UserDataService.savePassword(input).then(response => {
      cb(response);
    });
  };

  useEffect(() => {
    document.body.className = 'signin';
    return () => { document.body.className = ''; }
  }, []);

  const onFormSuccess = async (response) => {
    history.push('/');
  }

  return (
    <div className="signin-page">
      <OnboardingScreen>
        <Image src="/images/safelease_logo.svg" alt="SafeLease logo" />

        <Form onFormSuccess={onFormSuccess} onFormAttempt={onFormAttempt} submitLabel="Reset password" values={{ token: match.params.token }}>
          <FormElement name="password" label="New password" type="password" />
          <FormElement name="passwordConfirmation" label="Password confirmation" type="password" />
        </Form>
      </OnboardingScreen>
    </div>

  );
}


function RequestEmailSignin(props) {
  const auth = useAuth();
  const history = useHistory();
  
  const onFormAttempt = (input, cb) => {
    UserDataService.requestEmailSignin(input).then(response => {
      cb(response);
    });
  };

  useEffect(() => {
    /* Workaround for unwanted Sentry error in prod only, which surrounds case where user was already signed in when navigating to email signin.
      Ideal is to sign user out and back in, which is how regular login works, but this causes gql in-flight query invariant error 18 with email signin. */
    if(auth.user) history.push('/');
    document.body.className = 'signin';
    return () => { document.body.className = ''; }
  }, []);

  const onFormSuccess = async (response) => {
    mixpanelEventHandler('Submit request for sign in email')
    history.push('/messages/requestEmailSigninSent');
  }

  return (
    <div className="signin-page">
      <OnboardingScreen>
        <Image src="/images/safelease_logo.svg" alt="SafeLease logo" />

        <Form onFormSuccess={onFormSuccess} onFormAttempt={onFormAttempt} submitLabel="Email me sign in link">
          <div className="description">
            To sign in without a password, enter your email and click the button below.
          </div>
          <FormElement name="email" label="Email" />
        </Form>
      </OnboardingScreen>
    </div>
  );
}

function EmailSignin(props) {
  const history = useHistory();
  const auth = useAuth();
  const match = useRouteMatch();
  const [isError, setIsError] = React.useState(false); 

  const user = useQuery(me, {skip: !auth.user});

  const failed = function() {
    mixpanelEventHandler('Failed to sign in through email link')
    setIsError(true);
  };

  useEffect(() => {
    if(auth.user && user?.data?.me?.emailVerifiedAt) {
      history.push('/');
      return;
    }

    UserDataService.signinFromEmail({ signinFromEmailToken: match.params.token }).then(async response => {
      if(response.data.error) {
        failed();
      } else {
        await auth.signin(response.data);
        mixpanelEventHandler('Successfully signed in through email link')
        history.push('/');
      }
    }).catch(error => {
      failed();
    });
    document.body.className = 'signin';
    return () => { document.body.className = ''; }
  }, []);

  return (
    <div className="signin-page">
      <OnboardingScreen>
        <Image src="/images/safelease_logo.svg" alt="SafeLease logo" />
        <p>{isError}</p>
        {isError ? <>Invalid or expired link. Please attempt to log in again through the home page.</> : <>Signing in...</>}
      </OnboardingScreen>
    </div>

  );
}

function Unsubscribe(props) {
  const match = useRouteMatch();
  var token = match.params.token;
  const user = useQuery(me, {variables: {token}});

  const { email } = jwt.decode(token);
  
  const [unsubscribed, setUnsubscribed] = React.useState(false);

  useEffect(() => {
    document.body.classList.add('dashboard');
    return () => { document.body.classList.remove('dashboard')}
  }, []);
  
  if (user.loading) return <Loader />;
  if (user.error) return <Error />;

  const unsubscribe = async function() {
    await UserDataService.unsubscribe(token);
    setUnsubscribed(true);
  };

  return (
    <div>
      <TitleHeader title="Unsubscribe" />
      {!unsubscribed && 
       <>
      <div>
        To unsubscribe {email} from all SafeLease emails, click the button below.
      </div>
      <Button variant="contained" onClick={e => unsubscribe()}>Unsubscribe</Button>
       </>
      }

      {unsubscribed &&
       <>
       {email} unsubscribed from all SafeLease emails.
       </>
      }
    </div>
  );
}

function CreateProfile() {
  const history = useHistory();
  const location = useLocation();
  const { token } = useParams();
  console.log(token)
  const auth = useAuth();

  const { data, error, loading } = useQuery(getUserByToken, { variables: { token } });

  const onFormAttemptWithUser = (input, cb) => {
    input.append('token', token);
    UserDataService.acceptInvitation(input).then(response => {
      cb(response);
    });
  };

  const onFormAttemptNewUser = (input, cb) => {
    UserDataService.signup(input).then(response => {
      cb(response);
    });
  };

  const onFormSuccess = async (response) => {
    let { from } = location.state || { from: { pathname: "/" } };
    await auth.signin(response.data);
    history.replace(from);
  }

  useEffect(() => {
    if (auth.user) history.push('/');
  }, [auth]);

  useEffect(() => {
    document.body.className = 'signin';
    return () => { document.body.className = ''; }
  }, []);

  if (loading) return <Loader />;
  if (error || (token && !data.getUserByToken)) {
    window.location = "/";
    return null;
  }

  return (
    <div className="signin-page signup-page">
      <OnboardingScreen>
        <Image
          className="tw-ml-10 tw-mb-10 tw-mt-[-75px]"
          src="/images/safelease_logo.svg"
          alt="SafeLease logo"
        />
          <Form
            onFormSuccess={onFormSuccess}
            onFormAttempt={
              token
              ? onFormAttemptWithUser
              : onFormAttemptNewUser
            }
            submitLabel="Sign up"
            buttonClassOverride={{ paddingTop: 0, marginTop: 0 }}>
          <FormElement
            name="name"
            label={token ? data.getUserByToken.name : 'Name'}
            disabled={!!token}
          />
          <FormElement
            name="email"
            label={token? data.getUserByToken.email : "Email"}
            type="email"
            disabled={!!token}
          />
          <FormElement
            name="phone"
            label="Phone Number"
            type="tel"
          />
          <FormElement
            name="password"
            label="Password"
            type="password"
            autoFocus={!!token}
          />
          <FormElement
            name="passwordConfirmation"
            label="Password Confirmation"
            type="password"
          />
          <FormElement
            name="relationshipName"
            label={
              token
                ? data.getUserByToken.relationshipName
                : "Company Name"
            }
            disabled={!!token}
          />
          <p className="policy-text">
            <small>
              By continuing, you agree to the SafeLease{' '}
              <a
                href="https://info.safelease.com/terms-of-service"
                target="_blank"
                rel="noreferrer"
              >
                Terms of Service
              </a>{' '}
              and{' '}
              <a
                href="https://info.safelease.com/safelease-privacy-policy"
                target="_blank"
                rel="noreferrer"
              >
                Privacy Policy
              </a>
              .
            </small>
          </p>
        </Form>
      </OnboardingScreen>
    </div>
  )
}


export { UserEdit, ResetPasswordEmail, ResetPassword, RequestEmailSignin, EmailSignin, Unsubscribe, CreateProfile, OnboardingScreen }
