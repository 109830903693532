import { Box } from "@mui/material";

// Contains a tab panel for the tabbed components, hides content when tab is not active
export default function TabPanel(props) {
  const { className, children, currentTab, index, ...other } = props;

  return (
    <div
      className={className}
      role="tabpanel"
      hidden={currentTab !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {currentTab === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}
