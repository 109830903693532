import { Box, ListItem, Stack, SxProps, Typography } from "@mui/material";
import { useJobsErrorCenter } from "../useJobsErrorCenter";
import EditableDrawerFormSectionTextField from "./EditableDrawerFormSectionTextField";
import EditableDrawerFormSectionTextFieldDropdown from "./EditableDrawerFormSectionTextFieldDropdown";
import { Control } from "react-hook-form";

interface JobDetailsDrawerListItemProps {
  label: string; // human readable label
  jobErrorKey: string;
  renderer?: (value: any, sx: SxProps) => JSX.Element | string; // conditional render (for chips, bold, etc)
  editable?: boolean;
  isEditingSection?: boolean;
  asDropdown?: boolean; // when editing, display a dropdown of options
  control?: Control;
  options?: { label: string; value: string }[];
}

export default function JobDetailsDrawerListItem({
  label,
  jobErrorKey,
  renderer,
  editable = false,
  isEditingSection = false,
  asDropdown = false,
  options = [],
  control,
}: JobDetailsDrawerListItemProps) {
  const { selectedJobError } = useJobsErrorCenter();

  // Always display black if the section isn't editable or not currently being edited
  // Make the field gray if the section is editable but this field isn't
  // Make the field black if this section is editable and this field is editable
  const displayColor = isEditingSection
    ? editable
      ? "black"
      : "gray"
    : "black";

  return (
    <ListItem
      sx={{
        maxHeight: 'auto',
        p: 2,
        position: 'relative',

        backgroundColor: (theme) => theme.palette.background.paper,
        border: (theme) => `1px solid ${theme.palette.divider}`,
        "&:first-child": {
          borderRadius: "4px 4px 0 0",
        },
        "&:last-child": {
          borderRadius: "0 0 4px 4px",
        },
        "&:not(:last-child)": {
          borderBottom: 0,
        },
        "&:before": {
          display: "none",
        },
      }}
    >
      <Stack direction="row" alignItems="center" sx={{ width: "100%", maxWidth: '100%' }}>
        <Typography
          variant="body1"
          sx={{ color: "gray", mr: 6, minWidth: 125 }}
        >
          {label}
        </Typography>
        <Box sx={{ flexGrow: 1, textAlign: "right", minWidth: 0, flexWrap: 'wrap', wordWrap: 'break-word' }}>
          {/** Base black text display, no special rendering */}
          {((!renderer && !editable) ||
            (editable && !isEditingSection && !renderer)) && (
            <Typography sx={{ wordBreak: "break-word", color: displayColor }}>
              {selectedJobError[jobErrorKey]}
            </Typography>
          )}

          {/** If a renderer callback is present, use it for uneditable fields */}
          {((renderer && !editable) ||
            (renderer && editable && !isEditingSection)) &&
            renderer(selectedJobError[jobErrorKey], { color: displayColor })}

          {/** If the form field is a text field */}
          {editable && isEditingSection && !asDropdown && (
            <EditableDrawerFormSectionTextField
              fieldName={jobErrorKey}
              control={control}
            />
          )}

          {/** If the form field is a dropdown / select, pass the render to be used for MenuItems  */}
          {editable && isEditingSection && asDropdown && (
            <EditableDrawerFormSectionTextFieldDropdown
              fieldName={jobErrorKey}
              control={control}
              options={options}
              menuItemRenderer={renderer}
            />
          )}
        </Box>
      </Stack>
    </ListItem>
  );
}
