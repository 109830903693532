import { useState } from "react";
import { Alert, Box, IconButton, Modal, Tab, Tabs } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import _ from "lodash";
import TabPanel from "./TabPanel";
import LocationAddresses from "../settings/components/LocationAddresses";
import FacilityDetails from "../settings/LocationSettings/FacilityDetails";
import FacilitySetup from "../settings/LocationSettings/FacilitySetup";
import Billing from "../settings/LocationSettings/Billing";
import KeyDates from "../settings/LocationSettings/KeyDates";

const tabs = {
  details: 0,
  setup: 1,
  billing: 2,
  dates: 3,
  addresses: 4,
};

export type LocationSettingsErrors = {
  name?: { msg?: string };
  address?: { msg?: string };
  city?: { msg?: string };
  state?: { msg?: string };
  postalCode?: { msg?: string };
  revenueSharePercent?: { msg?: string };
};

export type LlcErrors = {
  name?: { msg?: string };
  address?: { msg?: string };
  city?: { msg?: string };
  state?: { msg?: string };
  postalCode?: { msg?: string };
};

export default function LocationSettingsModal(props) {
  const { tab, location, onClose, isAdmin } = props;
  const [currentTab, setCurrentTab] = useState(tabs[tab] || tabs.details);
  const [errors, setErrors] = useState<LocationSettingsErrors>({});
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  if (!isAdmin) return null;

  return (
    <Modal
      open={true}
      onClose={() => onClose()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="popup-modal"
    >
      <Box className="modal-box" style={{ width: 700, padding: 2 }}>
        <div className="tw-flex tw-justify-end">
          <IconButton onClick={() => onClose()}>
            <CloseIcon />
          </IconButton>
        </div>
        <div className="tw-flex tw-justify-center tw-pl-10 tw-pr-10">
          <strong>{`${location.fullAddress} (SafeLease ID: ${location.id})`}</strong>
        </div>

        <Box sx={{ borderBottom: 1, borderColor: "divider", marginLeft: 5, marginRight: 5 }}>
          <Tabs value={currentTab} onChange={(event, newTab) => setCurrentTab(newTab)} variant="fullWidth">
            <Tab value={tabs.details} label="Facility Details" />
            <Tab value={tabs.setup} label="Facility Setup" disabled={!location} />
            <Tab value={tabs.billing} label="Billing" disabled={!location} />
            <Tab value={tabs.dates} label="Key Dates" disabled={!location} />
            <Tab value={tabs.addresses} label="Location Addresses" disabled={!location} />
          </Tabs>
        </Box>

        <TabPanel className="tw-pl-5 tw-pr-5" currentTab={currentTab} index={tabs.details}>
          <FacilityDetails
            location={location}
            isAdmin={isAdmin}
            loading={loading}
            setLoading={setLoading}
            setErrorMessage={setErrorMessage}
            onClose={onClose}
          />
        </TabPanel>
        <TabPanel className="tw-pl-5 tw-pr-5" currentTab={currentTab} index={tabs.setup}>
          <FacilitySetup
            location={location}
            isAdmin={isAdmin}
            loading={loading}
            setLoading={setLoading}
            setErrors={setErrors}
            setErrorMessage={setErrorMessage}
            onClose={onClose}
          />
        </TabPanel>
        <TabPanel className="tw-pl-5 tw-pr-5" currentTab={currentTab} index={tabs.billing}>
          <Billing
            loading={loading}
            location={location}
            setLoading={setLoading}
            setErrors={setErrors}
            setErrorMessage={setErrorMessage}
            onClose={onClose}
          />
        </TabPanel>
        <TabPanel className="tw-pl-5 tw-pr-5" currentTab={currentTab} index={tabs.dates}>
          <KeyDates
            location={location}
            loading={loading}
            setLoading={setLoading}
            setErrors={setErrors}
            setErrorMessage={setErrorMessage}
            onClose={onClose}
          />
        </TabPanel>
        <TabPanel className="tw-pl-5 tw-pr-5" currentTab={currentTab} index={tabs.addresses}>
          <LocationAddresses location={location} />
        </TabPanel>
        {errorMessage && (
          <Box textAlign="center" margin={1}>
            <Alert severity="error">{errorMessage}</Alert>
          </Box>
        )}
      </Box>
    </Modal>
  );
}
