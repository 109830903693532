import { FlexRowBox, FlexColumnBox, RowTitleText, RowSubTitleText } from "../MyAccountStyledComponents";
import { styled } from "@mui/system";
import { Switch } from "@mui/material";

const StyledSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase": {
    color: "#031E30",
    "&.Mui-checked": {
      color: "white",
      "& + .MuiSwitch-track": {
        backgroundColor: "#031E30",
        opacity: "1.0",
      },
    },
    "& .MuiSwitch-track": {
      backgroundColor: "#E9E9EA",
      opacity: 1.0,
      height: 24, // Default height if no prop is provided
      borderRadius: 24 / 2, // Ensures full rounded corners
    },
    "& .MuiSwitch-thumb": {
      width: 18, // Adjust thumb size relative to track height
      height: 18,
    },
    "& .MuiSwitch-root": {
      width: 48, // Adjust the total width of the switch based on track height
      height: 50,
    },
  },
}));

const NotificationsSection = () => {
  return (
    <FlexRowBox>
      <FlexColumnBox
        sx={{
          width: "149px",
        }}
      >
        <RowTitleText>Notification alerts</RowTitleText>
        <RowSubTitleText>Receive alerts regarding your account and other important topics</RowSubTitleText>
      </FlexColumnBox>
      <FlexColumnBox
        sx={{
          marginLeft: "80px",
        }}
      >
        <StyledSwitch />
        <StyledSwitch
          sx={{
            marginTop: "20px",
          }}
        />
      </FlexColumnBox>
      <FlexColumnBox
        sx={{
          marginLeft: "12px",
        }}
      >
        <RowTitleText>Get notified via text</RowTitleText>
        <RowSubTitleText>Receive updates and alerts</RowSubTitleText>
        <RowTitleText
          sx={{
            marginTop: "20px",
          }}
        >
          Get notified via email
        </RowTitleText>
        <RowSubTitleText>Receive updates and alerts</RowSubTitleText>
      </FlexColumnBox>
    </FlexRowBox>
  );
};

export default NotificationsSection;
