import { Facility, Portfolio } from "@safelease/service-utilities";
import { Dayjs } from "dayjs";
import { create } from "zustand";

export interface ReputationUser_Employee {
  id: string;
  name: string;
  role: string;
  email: string;
  phone: string;
  src: string;
  rating: number;
  reviews: Array<{
    id: string;
    createdAt: Dayjs;
  }>;
  qrCodes: Array<{
    url: string;
    facility: {
      companyName: string;
    };
    active: boolean;
  }>;
}

interface PerformanceBoardState {
  // Manage active step in setup
  step: number;
  setStep: (step: number) => void;

  // Manage the facilities / people selected
  selectedFacilities: Array<Facility>;
  addFacility: (facility: Facility) => void;
  removeFacility: (facility: Facility) => void;

  // Manage employees at each facility
  selectedUsers: { [facilityId: string]: ReputationUser_Employee };
  setSelectedUsers: (selectedUsers: { [facilityId: string]: ReputationUser_Employee }) => void;
  updateUserSelectionStatus: (userId: string, facilityId: string) => void;

  // List of facilities
  facilities: Array<Facility>;
  setFacilities: (facilities: Array<Facility>) => void;

  // Portfolio overview
  portfolio: Portfolio;
  setPortfolio: (portfolio: Portfolio) => void;

  configDialogOpen: boolean;
  setConfigDialogOpen: (complete: boolean) => void;
}

export const usePerformanceBoardStore = create<PerformanceBoardState>((set, get) => ({
  // Manage active step in setup
  step: 0,
  setStep: (step: number) => set({ step }),

  // Manage the facilities / people selected
  selectedFacilities: [],
  addFacility: (facility: Facility) => set({ selectedFacilities: [...get().selectedFacilities, facility] }),
  removeFacility: (facility: Facility) => set({ selectedFacilities: get().selectedFacilities.filter((f) => f.id !== facility.id) }),

  // Manage employees at each facility
  selectedUsers: {},
  setSelectedUsers: (selectedUsers) => {
    set({ selectedUsers });
  },

  facilities: [],
  setFacilities: (facilities: Array<Facility>) => set({ facilities }),

  portfolio: null,
  setPortfolio: (portfolio) => set({ portfolio }),

  updateUserSelectionStatus(userId: string, facilityId: string) {
    let selectedUsers = get().selectedUsers;
    selectedUsers[facilityId][userId] = !selectedUsers[facilityId][userId];
    set({ selectedUsers });
  },

  configDialogOpen: false,
  setConfigDialogOpen: (open: boolean) => set({ configDialogOpen: open }),
}));

export const usePerformanceBoard = () => {
  const performanceBoardStore = usePerformanceBoardStore((state) => state);

  return {
    ...performanceBoardStore,
    facilities: performanceBoardStore.facilities ?? [],
  };
};
