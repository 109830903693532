import React, { useContext, useEffect, createContext, useState } from "react";
import { mixpanelEventHandler, mixpanelIdentifyHandler } from './utilities/reactMixpanelHandler';
import apolloClient from "./utilities/apolloClient";

import {
  Route,
  Redirect,
} from "react-router-dom";

function ProvideAuth(props) {
  const auth = useProvideAuth(props);
  return (
    <authContext.Provider value={auth}>
      {props.children}
    </authContext.Provider>
  );
}

const authContext = createContext();
function useAuth() {
  return useContext(authContext);
}

function useProvideAuth(props) {
  const { routerHelper } = props;
  const [user, setUser] = useState(null);

  // If the browser has a user persisted, use it.
  if(window.localStorage) {
    var currentUser = window.localStorage.getItem('user');
    if(!user && currentUser) {
      currentUser = JSON.parse(currentUser);
      setUser({ ...currentUser, isAdmin: ['admin', 'superAdmin'].includes(currentUser.role) });
    }
  }

  const signin = async (user) => {
    if (["/","/signin"].includes(window.location)){ // setting RelationshipId to null from the settings route will cause graphql errors due to the billing tab having AccessControl on Relationship
      routerHelper.setRelationshipId(null);
    }
    localStorage.setItem('user', JSON.stringify(user));
    await apolloClient.clearStore() // use of .resetStore() causes an error when switching user permissions, potentially due to in-flight gql queries
    await mixpanelIdentifyHandler();
    mixpanelEventHandler('Login');
    setUser({ ...user, isAdmin: ['admin', 'superAdmin'].includes(user.role) });
  };

  const signout = (cb) => {
    localStorage.removeItem('user');
    routerHelper.setRelationshipId(null);
    apolloClient.clearStore();
    setUser(null);
    if(cb) cb();
  };

  return {
    user,
    signin,
    signout
  };
}

export { useAuth, ProvideAuth };

