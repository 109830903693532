import { Checkbox, Divider, Stack, Theme, Typography, alpha, useTheme } from "@mui/material";
import { ReputationUser_Employee, usePerformanceBoard } from "../usePerformanceBoard";
import { grey } from "@mui/material/colors";
import { SafeLeaseLetterAvatar } from "@safelease/components";

interface EmployeeSelectorProps {
  user: ReputationUser_Employee;
  facilityId: string;
}

/**
 * Manages the selection of an individual employee at a facility
 */
export function EmployeeSelector({ user, facilityId }: EmployeeSelectorProps) {
  const theme = useTheme();

  const { selectedUsers, updateUserSelectionStatus } = usePerformanceBoard();

  const facilityUsers = selectedUsers[facilityId];

  // Flip a single user's status
  const handleUserCheckboxClick = (_event, user) => {
    updateUserSelectionStatus(user.userId, user.facilityId);
  };

  return (
    <Stack
      key={user.id}
      direction="row"
      alignItems="center"
      spacing={1}
      sx={{
        border: "1px solid",
        borderColor: facilityUsers[user.id] ? theme.palette.navy.main : grey[200],
        bgcolor: facilityUsers[user.id] ? alpha(theme.palette.navy.main, 0.025) : "transparent",
        py: 1,
        borderRadius: 1,
      }}
    >
      <Checkbox
        checked={!!facilityUsers[user.id]} // Convert to boolean, otherwise this is recognized as switching between controlled/uncontrolled
        onChange={(event) => handleUserCheckboxClick(event, { userId: user.id, facilityId })}
      />
      <SafeLeaseLetterAvatar name={user.name} />
      <Stack direction="column">
        <Typography variant="body1" sx={{ fontWeight: 500 }}>
          {user.name}
        </Typography>
        <Stack direction="row" spacing={1} divider={<Divider orientation="vertical" flexItem />}>
          <Typography variant="body2" sx={{ color: (theme: Theme) => theme.palette.grey.A100 }}>
            {user.email}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
}
