import { useQuery, useMutation } from "@tanstack/react-query";
import { create } from "zustand";
import { userApi } from "../../../utilities/apiInstances/user-api";
import { useAuth } from "../../../auth";
import { organizationApi } from "../../../utilities/apiInstances/organization-settings-api";
import { useEffect } from "react";
import { TwilioJobPosition } from "../../../types/TwilioTypes";

interface TextFieldState {
  id: string;
  fullName: string;
  jobTitle: string;
  jobPosition?: TwilioJobPosition;
  email: string;
  phone: string;
  role: string;
}

export enum AlertSeverityEnum {
  ERROR = "error",
  WARNING = "warning",
  INFO = "info",
  SUCCESS = "success",
}

export interface MyAccountState {
  textFieldState: TextFieldState;
  setTextFieldState: (newState: Partial<TextFieldState>) => void;

  relationshipSelectorId: string;
  setRelationshipSelectorId: (relationshipSelectorId: string) => void;

  userSelectorId: string;
  setUserSelectorId: (userSelectorId: string) => void;

  alertMessage: {
    message: string;
    severity: AlertSeverityEnum;
  };
  setAlertMessage: (alertMessage: MyAccountState["alertMessage"]) => void;

  selectedFile: File | null;
  setSelectedFile: (selectedFile: File | null) => void;

  profileImageUrl: string | null;
  setProfileImageUrl: (profileImageUrl: string) => void;
}

export const useMyAccountState = create<MyAccountState>((set, get) => ({
  textFieldState: {
    id: "",
    fullName: "",
    jobTitle: "",
    jobPosition: null,
    email: "",
    phone: "",
    role: "",
  },
  setTextFieldState: (newState: Partial<MyAccountState["textFieldState"]>) =>
    set((state) => ({
      textFieldState: {
        ...state.textFieldState,
        ...newState,
      },
    })),

  relationshipSelectorId: "",
  setRelationshipSelectorId: (relationshipSelectorId: string) => set({ relationshipSelectorId }),

  userSelectorId: "",
  setUserSelectorId: (userSelectorId: string) => set({ userSelectorId }),

  alertMessage: null,
  setAlertMessage: (alertMessage) => set({ alertMessage }),

  selectedFile: null,
  setSelectedFile: (selectedFile) => set({ selectedFile }),

  profileImageUrl: null,
  setProfileImageUrl: (profileImageUrl: string) => set({ profileImageUrl }),
}));

export const useMyAccount = () => {
  const auth = useAuth();
  const isAdmin = auth.user.isAdmin;
  const {
    textFieldState,
    relationshipSelectorId,
    userSelectorId,
    alertMessage,
    selectedFile,
    profileImageUrl,
    setTextFieldState,
    setRelationshipSelectorId,
    setUserSelectorId,
    setAlertMessage,
    setSelectedFile,
    setProfileImageUrl,
  } = useMyAccountState();

  // fetches the user's information
  const fetchMeResult = useQuery({
    queryKey: ["fetch-me"],
    queryFn: () => userApi.getMe(),
    enabled: !isAdmin, // execute this query only if the user is not an admin
  });

  // Fetches all the relationships to populate the relationship dropdown for admins/superadmins
  const fetchRelationshipsSelectorResult = useQuery({
    queryKey: ["fetch-relationships-selector"],
    queryFn: () => organizationApi.getRelationshipsSelector(),
    enabled: isAdmin, // execute this query only if the user is an admin
  });

  const fetchUserByIdResult = useQuery({
    queryKey: ["fetch-user-by-id", userSelectorId],
    queryFn: () => userApi.getUserById(+userSelectorId),
    enabled: isAdmin && Boolean(userSelectorId), // Ensure the query only runs if userSelectorId is defined and user is an admin
  });

  // fetches all users for the relationship selected by the admin/superadmin. used to populate the user selector dropdown
  const fetchUsersForRelationshipResult = useQuery({
    queryKey: ["fetch-users-for-relationship", relationshipSelectorId],
    queryFn: () => userApi.getUsers(relationshipSelectorId),
    enabled: isAdmin && Boolean(relationshipSelectorId), // Ensure the query only runs if relationshipSelectorId is defined and user is an admin
    onSuccess: (data) => {
      // If the admin / superadmin is editing another user, set the user selector id as the first user in the relationship users list
      if (relationshipSelectorId !== auth.user.relationshipId) {
        setUserSelectorId(data.edges[0].id);
      } else {
        // if the current admin / superadmin selects another relationship and then switches back to the original relationship, set the user selector id to the current admin's id
        setUserSelectorId(auth.user.id);
      }
    },
  });

  const fetchUserProfileImageResult = useQuery({
    queryKey: ["fetch-user-profile-image", userSelectorId],
    queryFn: () => userApi.getUserProfileImage(userSelectorId),
    enabled: !!userSelectorId,  // Only run query if userSelectorId is truthy (i.e. not an empty string)
    onSuccess: (data) => {
      setProfileImageUrl(data.imageUrl);
    },
    onError: (err) => {
      setProfileImageUrl(null);
    } 
  });

  const userUploadMutation = useMutation((formData: FormData) => userApi.uploadProfileImage(formData), {
    onSuccess: (data) => {
      setAlertMessage({
        message: "Profile image uploaded successfully",
        severity: AlertSeverityEnum.SUCCESS,
      });
      setProfileImageUrl(data.imageUrl);
    },
    onError: () => {
      setAlertMessage({
        message: "Error uploading image.",
        severity: AlertSeverityEnum.ERROR,
      });
    }
  });

  // set the relationship and user selector ids to the current user's relationship and user ids
  useEffect(() => {
    setRelationshipSelectorId(auth.user.relationshipId);
    setUserSelectorId(auth.user.id);
  }, [auth]);

  // set initial values for text fields once available from the APIs
  useEffect(() => {
    if (fetchMeResult.data) {
      setTextFieldState({
        id: fetchMeResult.data?.id ?? "",
        fullName: fetchMeResult.data?.name ?? "",
        jobTitle: fetchMeResult.data?.jobTitle ?? "",
        jobPosition: fetchMeResult.data?.jobPosition ?? "",
        email: fetchMeResult.data?.email ?? "",
        phone: fetchMeResult.data?.phone ?? "",
        role: fetchMeResult.data?.role ?? "",
      });
    } else if (fetchUserByIdResult.data) {
      setTextFieldState({
        id: fetchUserByIdResult.data?.id ?? "",
        fullName: fetchUserByIdResult.data?.name ?? "",
        jobTitle: fetchUserByIdResult.data?.jobTitle ?? "",
        jobPosition: fetchUserByIdResult.data?.jobPosition ?? "",
        email: fetchUserByIdResult.data?.email ?? "",
        phone: fetchUserByIdResult.data?.phone ?? "",
        role: fetchUserByIdResult.data?.role ?? "",
      });
    }
  }, [fetchMeResult.data, fetchUserByIdResult.data]);

  return {
    textFieldState,
    relationshipSelectorId,
    userSelectorId,
    alertMessage,
    selectedFile,
    fetchRelationshipsSelectorResult,
    fetchMeResult,
    fetchUserByIdResult,
    fetchUsersForRelationshipResult,
    fetchUserProfileImageResult,
    userUploadMutation,
    profileImageUrl,
    setTextFieldState,
    setRelationshipSelectorId,
    setUserSelectorId,
    setAlertMessage,
    setSelectedFile,
  };
};
