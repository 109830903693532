import { BarChart } from "@safelease/components";
import { Skeleton, Typography, alpha, useTheme } from "@mui/material";
import * as HighCharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import _ from "lodash";
import { useRef } from "react";
import { PeriodDataPoints } from "../RatingBreakdownGraphs";
import dayjs from "dayjs";
import { sharedToolTip, xAxisConfiguration, yAxisBareConfiguration } from "./graphUtils";
import { amber } from "@mui/material/colors";

interface ReviewSourcesGraphProps {
  periods: Array<PeriodDataPoints>;
  loading?: boolean;
}

export function ReviewSourcesGraph({ periods, loading = false }: ReviewSourcesGraphProps) {
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
  const theme = useTheme();

  if (loading) return <Skeleton variant="rectangular" height={300} />;

  // Datasets to create the bars
  const data = [
    { data: periods.map((period) => period.organic), name: "Organic" },
    { data: periods.map((period) => period.elicited), name: "Elicited" },
    { data: periods.map((period) => period.employee), name: "Employee Generated" },
  ];

  const colors = [alpha(theme.palette.green.main, 0.8), alpha(theme.palette.blue.main, 0.8), alpha(amber[500], 0.8)];

  const configuration = {
    type: "column",
    title: {
      text: null,
    },
    credits: {
      enabled: false,
    },
    chart: {
      marginTop: 40,
    },
    legend: {
      align: "left",
      floating: true,
      enabled: true,
      verticalAlign: "top",
      x: -12,
      y: -15,
      symbolRadius: 4,
      symbolHeight: 14,
      itemStyle: {
        fontSize: "14px",
      },
    },
    xAxis: xAxisConfiguration(periods),
    yAxis: yAxisBareConfiguration(data, theme.palette.grey.A100),

    // Hover tooltip with info about a specific bar
    tooltip: sharedToolTip(periods),

    plotOptions: {
      column: {
        groupPadding: 0.1,
      },
    },
    series: data.map(({ data, name }, index) => ({
      name,
      data,
      type: "column",
      color: colors[index % colors.length],
      marker: {
        fillColor: "#031E30",
        lineWidth: 2,
      },
    })),
  };

  return (
    <HighchartsReact
      id={_.uniqueId("bar-chart-")}
      containerProps={{
        id: _.uniqueId("bar-chart-"),
        style: { height: "100%" },
      }}
      highcharts={HighCharts}
      ref={chartComponentRef}
      options={configuration}
    />
  );
}
