import React, { useState } from "react";

// UI - libs
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  DialogContentText,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import { useSnackbar } from "notistack";

// UI - internal
import { muiRedButton } from "../../../styles/mui-overrides";

// Data - libs
import { useApolloClient } from "@apollo/client";

// Data - internal
import {
  Location,
  LocationAddress,
} from "../../../utilities/generated/gql-types";
import SetupDataService from "../../../services/setup.service";
import { getLocationAddresses } from "../../../queries";

interface DeleteLocationAddressDialogProps {
  location: Location;
  locationAddress: LocationAddress;
  open: boolean;
  onClose: () => void;
}

// Confirmation screen before deleting an address
export default function DeleteLocationAddressDialog({
  location,
  locationAddress,
  open,
  onClose,
}: DeleteLocationAddressDialogProps) {
  const { enqueueSnackbar } = useSnackbar();
  const client = useApolloClient();
  const [loading, setLoading] = useState<boolean>(false);
  const [repeatId, setRepeatId] = useState<string>("");

  const handleDeleteLocationAddress = async () => {
    if (repeatId === locationAddress.id) {
      setLoading(true);
      const response = await SetupDataService.deleteLocationAddress({
        locationAddressId: locationAddress.id,
        relationshipId: location.relationshipId,
      });
      client.refetchQueries({ include: [getLocationAddresses] });

      if (!response.data?.errors) {
        enqueueSnackbar("Location address deleted.", { variant: "success" });
      } else {
        enqueueSnackbar(
          "Error deleting location address: \n" +
            Object.values(response.data.errors)
              .map(({ msg }) => msg)
              .join("\n"),
          { variant: "error" }
        );
      }

      setLoading(false);
    }
    onClose();
  };

  const handleClose = () => {
    setRepeatId("");
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Delete Address</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent className="tw-space-y-2">
        <DialogContentText>
          Delete address&nbsp;
          <b>
            {locationAddress.address}, {locationAddress.city}{" "}
            {locationAddress.state}
          </b>
          ?
          <br />
          <br />
          To confirm you want to delete this address, type "
          <b>{locationAddress.id}</b>" in the field below.
        </DialogContentText>
        <TextField
          label="Enter ID"
          value={repeatId}
          onChange={(e) => setRepeatId(e.target.value)}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <LoadingButton
          onClick={handleDeleteLocationAddress}
          sx={muiRedButton()}
          disabled={repeatId !== locationAddress.id}
          loading={loading}
        >
          Delete
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
