import { ColumnDef, isColumnHidden } from '../utilities/column-defs';
import { TableHead, TableRow, TableCell, Button } from '@mui/material';
import Sorter from './sorter';
import { muiTooltip, muiTableCell } from '../styles/mui-overrides';
import Tooltip from '@mui/material/Tooltip';
import { useTableStore } from '../utilities/table-state'
import { useAuth } from '../auth.js';
import { Image } from '../image';

function SortingTableHeadVirtualized(props: { 
    columns: ColumnDef[],
    tableTitle: string,
    cellPadding?: string,
}) { 
  const { columns, tableTitle, cellPadding } = props;
  const auth = useAuth()
  const { activeColumnKeys, getSortDirectionByKey } = useTableStore(tableTitle, columns)(
    (state) => ({ 
      getSortDirectionByKey: state.getSortDirectionByKey,
      activeColumnKeys: state.activeColumnKeys 
    })
  )

  return (
    <TableRow className="widget-table-row" style={{ height: 60 }}>
      {columns.filter(column => 
          !isColumnHidden( column, auth.user.isAdmin, activeColumnKeys))
          .map(column => {
        const sortDirection = getSortDirectionByKey(column.key)
        return (
          <TableCell key={column.key}
            sx={{
              ...muiTableCell({
                cellPadding: cellPadding ? `${cellPadding}px` : '9px',
                sortDirection,
              })
            }}>
              <div style={{ display: 'flex', alignItems: 'center', ...column.styles }}>
                {column.title}
                {column.tooltip && (
                  <Tooltip placement="bottom" title={column.tooltip}>
                    <Button sx={muiTooltip}>
                      <span className="badge">
                        <Image src="/images/tooltip-icon.svg" alt="warning" />
                      </span>
                    </Button>
                  </Tooltip>
                )}
                  <Sorter columnKey={column.key} tableTitle={tableTitle} />
                </div>
          </TableCell>
        )
      })}
    </TableRow>
  );
}

export default SortingTableHeadVirtualized;
