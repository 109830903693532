import dayjs, { Dayjs } from "dayjs";
import { create } from "zustand";
import {
  defaultEnabledFmsPrivatePolicyComplianceConfigs,
  defaultDisabledFmsPrivatePolicyComplianceConfigs,
  defaultEnabledInternalPrivatePolicyComplianceConfigs,
  defaultDisabledInternalPrivatePolicyComplianceConfigs
} from "./privatePolicySettingsUtilities";

interface AdminBillingState {

  // whether the config modal should be open
  configMenuOpen: boolean;
  setConfigMenuOpen: (open: boolean) => void;

  // controls whether or not we should enable internal enforcement of private policy compliance
  internalEnforcementEnabled: boolean;
  setInternalEnforcementEnabled: (enabled: boolean) => void;

  // the date that we should begin enforcing the selected compliance rules for private policies submitted interanally.(privatepolicy.com)
  selectedIntEnforcementDate: Dayjs;
  setSelectedIntEnforcementDate: (date: Dayjs) => void;

  // controls whether or not we should enable FMS enforcement of private policy compliance
  fmsEnforcementEnabled: boolean;
  setFmsEnforcementEnabled: (enabled: boolean) => void;

  // the date that we should begin enforcing the selected compliance rules for private policies found on the FMS.
  selectedFmsEnforcementDate: Dayjs;
  setSelectedFmsEnforcementDate: (date: Dayjs) => void;

  // reset this store everytime a new relationship is selected
  reset: () => void;

  // This is the object that we will send to the backend to update the compliance rules for private policies
  getComplianceUpdateObject: (advanced: boolean) => {
    intEnforcementDate: Dayjs | null;
    fmsEnforcementDate: Dayjs | null;
    intCompliantDeclaration: boolean;
    fmsValidPolicyNumber: boolean;
    fmsExpirationDate: boolean;
    firstChargeNextBillingCycle: boolean;
  };

  //The following are state is only used whenever a select few users are accessing this settings page

  // this is for targetting a specific location for the custom configurations
  selectedLocationId: string | null;
  setSelectedLocationId: (locationId: string | null) => void;

  // all of the following are specific toggles for choosing which compliance rules to enforce
  fmsValidPolicyNumber: boolean;
  setFmsValidPolicyNumber: (enabled: boolean) => void;

  fmsExpirationDate: boolean;
  setFmsExpirationDate: (enabled: boolean) => void;

  intPoliciesOnly: boolean;
  setIntPoliciesOnly: (enabled: boolean) => void;

  intCompliantDeclaration: boolean;
  setIntCompliantDeclaration: (enabled: boolean) => void;

  firstChargeNextBillingCycle: boolean;
  setFirstChargeNextBillingCycle: (enabled: boolean) => void;

  // end of specific toggles
}

export const usePrivatePolicySettingsStore = create<AdminBillingState>()((set, get) => ({

  configMenuOpen: false,
  setConfigMenuOpen: (open) => set({ configMenuOpen: open }),

  internalEnforcementEnabled: false,
  setInternalEnforcementEnabled: (enabled) => set({ internalEnforcementEnabled: enabled }),

  selectedIntEnforcementDate: dayjs(),
  setSelectedIntEnforcementDate: (date) => set({ selectedIntEnforcementDate: date }),

  fmsEnforcementEnabled: false,
  setFmsEnforcementEnabled: (enabled) => set({ fmsEnforcementEnabled: enabled }),

  selectedFmsEnforcementDate: dayjs(),
  setSelectedFmsEnforcementDate: (date) => set({ selectedFmsEnforcementDate: date }),

  firstChargeNextBillingCycle: false,
  setFirstChargeNextBillingCycle: (enabled) => set({ firstChargeNextBillingCycle: enabled }),

  reset: () => {
    set({
      configMenuOpen: false,
      internalEnforcementEnabled: false,
      selectedIntEnforcementDate: dayjs(),
      fmsEnforcementEnabled: false,
      selectedFmsEnforcementDate: dayjs(),
      selectedLocationId: "",
      fmsValidPolicyNumber: true,
      fmsExpirationDate: true,
      intCompliantDeclaration: true,
      firstChargeNextBillingCycle: false,
    });
  },

  getComplianceUpdateObject: (advanced) => {
    const { internalEnforcementEnabled, selectedIntEnforcementDate, fmsEnforcementEnabled, selectedFmsEnforcementDate, firstChargeNextBillingCycle } = get();
    let internalComplianceConfigs = internalEnforcementEnabled ? defaultEnabledInternalPrivatePolicyComplianceConfigs : defaultDisabledInternalPrivatePolicyComplianceConfigs;
    let fmsComplianceConfigs = fmsEnforcementEnabled ? defaultEnabledFmsPrivatePolicyComplianceConfigs : defaultDisabledFmsPrivatePolicyComplianceConfigs;

    if (advanced) { // if we are using the advanced settings, we need to use the toggles for the specific compliance rules
      if (internalEnforcementEnabled) {
        internalComplianceConfigs = {
          intCompliantDeclaration: get().intCompliantDeclaration,
          intPoliciesOnly: get().intPoliciesOnly,
        };
      }
      if (fmsEnforcementEnabled) {
        fmsComplianceConfigs = {
          fmsValidPolicyNumber: get().fmsValidPolicyNumber,
          fmsExpirationDate: get().fmsExpirationDate,
        };
      }
    }

    return {
      intEnforcementDate: internalEnforcementEnabled ? selectedIntEnforcementDate : null,
      fmsEnforcementDate: fmsEnforcementEnabled ? selectedFmsEnforcementDate : null,
      ...internalComplianceConfigs,
      ...fmsComplianceConfigs,
      firstChargeNextBillingCycle,
    };
  },

  selectedLocationId: "",
  setSelectedLocationId: (locationId) => set({ selectedLocationId: locationId }),

  fmsValidPolicyNumber: true,
  setFmsValidPolicyNumber: (enabled) => set({ fmsValidPolicyNumber: enabled }),

  fmsExpirationDate: true,
  setFmsExpirationDate: (enabled) => set({ fmsExpirationDate: enabled }),

  intPoliciesOnly: true,
  setIntPoliciesOnly: (enabled) => set({ intPoliciesOnly: enabled }),

  intCompliantDeclaration: true,
  setIntCompliantDeclaration: (enabled) => set({ intCompliantDeclaration: enabled }),

  firstChargeNextBillingCycle: false,
  setFirstChargeNextBillingCycle: (enabled) => set({ firstChargeNextBillingCycle: enabled }),

  
}));
