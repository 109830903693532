import { useState } from 'react'
import DatePicker from '../components/lib/SafeLeaseDatePicker';
import { TextField, Button, IconButton, Checkbox, Select, MenuItem } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Switch from '@mui/material/Switch'
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import EditIcon from '@mui/icons-material/Edit'
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn'
import { 
  muiBooleanIcon,
  muiRedIconButton,
  muiGreenIconButton,
  muiFaintIconButton } from '../styles/mui-overrides'
import { TableRow } from '../utilities/table-state'
import { QueryResult } from '@apollo/client'
import { useAuth } from '../auth.js'
import { RenderOptions } from '../utilities/column-defs'
import { useAtom } from 'jotai'

function linkRenderer(text: string) {
  return (
    <a className="link-renderer">{text}</a>
  )
}

function booleanRenderer(value: boolean) {
  return (
    <>
      {value === true &&
        <CheckCircleIcon sx={muiBooleanIcon(true)}/>
      }
      {value === false &&
        <DoNotDisturbOnIcon sx={muiBooleanIcon(false)}/>
      }
    </>
  )
}

function BooleanSwitch(
  value: boolean,
  tableInfo,
  renderOptions: {
    callback: (newValue: boolean, tableInfo) => Promise<any>
  }
) {
  const [loading, setLoading] = useState(false);

  const onSwitch = async (e) => {
    setLoading(true)
    e.stopPropagation();
    await renderOptions.callback(e.target.checked, tableInfo);
    setLoading(false);
  }
  
  return (
    <>
      {loading ? (
        <CircularProgress />
      ) : (
        <Switch
          checked={value}
          onChange={onSwitch}
          inputProps={{ 'aria-label': 'controlled' }}
          color={value ? 'warning' : 'success'}
        />
      )}
    </>
  );
}

function confirmClaimCheckbox(
    checked: boolean, 
    tableInfo: {row: any, queryResult: any}, 
    renderOptions: {
      callback: ({
          claimId, 
          locationId, 
          confirmed, 
          matchedLocation, 
          matchAccuracy, 
      }) => Promise<any>,
      disabled?: boolean
    }
) {

  const { row, queryResult } = tableInfo;
  return (
    <Checkbox checked={checked} 
        disabled={renderOptions.disabled}
        onChange={(e) => {
          renderOptions.callback({
            claimId: row.id, 
            locationId: row.locationId, 
            confirmed: e.target.checked,
            matchedLocation: row.matchedLocation,
            matchAccuracy: row.matchAccuracy,
          }).then(() => {
            queryResult.refetch()
          })
        }} />
  )
}

const editableField = (
    value: string,
    tableInfo: {row: TableRow, queryResult: QueryResult}, 
    renderOptions: {
      callback: (newValue, tableInfo) => Promise<any>,
      isLink?: boolean,
      nonEditingDisplayParser?: 
          (value: string, tableInfo: {row: TableRow, queryResult: QueryResult}) => JSX.Element,
      editingDisplayParser?: 
          (value: string, tableInfo: {row: TableRow, queryResult: QueryResult}) => string,
      validators?: {
        validate: (value: string) => boolean,
        errorMessage: string
      }[],
      nonAdminsCannotEdit: boolean
    }
) => {
  const auth = useAuth()
  const [isEditing, setIsEditing] = useState(false)
  const [pendingValue, setPendingValue] = useState(
      renderOptions.editingDisplayParser ? 
          renderOptions.editingDisplayParser(value, tableInfo) : value)
  const isValid = (pendingValue: string): boolean => {
    console.log({pendingValue})
    if (renderOptions.validators) {
      return renderOptions.validators.every(validator => validator.validate(pendingValue))
    }
    return true
  }
  const validationErrors = (pendingValue: string): string[] => {
    return renderOptions.validators?.filter(validator => 
        !validator.validate(pendingValue)).map(validator => validator.errorMessage) || []
  }

  if (renderOptions.nonAdminsCannotEdit && !auth.user.isAdmin) {
    return renderOptions.nonEditingDisplayParser ?
        renderOptions.nonEditingDisplayParser(value, tableInfo) : 
        value
  }

  return (
    <>
      <div className='tw-flex tw-items-center'>
        {!isEditing && 
          <>
            <div>
              {renderOptions.nonEditingDisplayParser ? 
                  renderOptions.nonEditingDisplayParser(value, tableInfo) :
                  value
              }
            </div>
            <IconButton onClick={(e) => {
                e.stopPropagation() 
                setIsEditing(true)}}>
              <EditIcon sx={muiFaintIconButton}/>
            </IconButton>
          </>
        }
        {isEditing &&
          <>
            <textarea
              autoFocus
              defaultValue={value}
              onClick={(e) => e.stopPropagation()}
              value={pendingValue}
              onChange={(e) => setPendingValue(e.target.value)}
            />
            <IconButton disabled={!isValid(pendingValue)}
                onClick={async (e) => {
                  e.stopPropagation() 
                  await renderOptions.callback(pendingValue, tableInfo)
                  setIsEditing(false)}}>
              <CheckCircleIcon sx={muiGreenIconButton({isActive: isValid(pendingValue)})} />
            </IconButton>
            <IconButton onClick={(e) => {
                e.stopPropagation() 
                setIsEditing(false)}}>
              <CloseIcon sx={muiRedIconButton} /> 
            </IconButton>
          </>
        }
      </div>
      {isEditing && !isValid(pendingValue) && validationErrors(pendingValue).length > 0 &&
        <div className="tw-text-red-500">
          {validationErrors(pendingValue).map(e => <span>{e}</span>)}
        </div>
      }
    </>
  )
}

const editableFieldWithDropdown = <T, >(
    value: string,
    tableInfo: {row: TableRow, queryResult: QueryResult}, 
    renderOptions: RenderOptions
) => {
  const [isEditing, setIsEditing] = useState(false);
  const auth = useAuth()

  if (renderOptions.nonAdminsCannotEdit && !auth.user.isAdmin) return value

  return (
    <div className='tw-flex tw-items-center'>
      <Select value={value} labelId="selected-account-label" 
          disabled={!isEditing}
          defaultValue={value}
          onClick={e => e.stopPropagation()}
          sx={{width: '128px', height: '20px', fontSize: '12px'}}
          onChange={async (e) => {
            await renderOptions.callback(e.target.value, tableInfo)
            setIsEditing(false);
          }}>
        {renderOptions.selectOptions(tableInfo.row)?.map(selectOption => {
          return (
            <MenuItem key={selectOption.value} value={selectOption.value} sx={{fontSize: '12px'}}>
              {selectOption.label}
            </MenuItem>
          );
        })}
      </Select>
      {!isEditing && 
        <IconButton onClick={(e) => {
            e.stopPropagation(); 
            setIsEditing(true)}}>
          <EditIcon sx={muiFaintIconButton}/>
        </IconButton>
      }
      {isEditing && 
        <IconButton onClick={(e) => {
            e.stopPropagation() 
            setIsEditing(false)}}>
          <CloseIcon sx={muiRedIconButton} /> 
        </IconButton>
      }
    </div>
  )
}

const editableMonthField = (
    value: string | Date,
    tableInfo: {row: TableRow, queryResult: QueryResult}, 
    renderOptions: {
      callback: (newValue, tableInfo) => Promise<any>,
      selectOptions: any
    }
) => {
  return <EditableMonthComponent value={value} 
      callback={renderOptions.callback} 
      tableInfo={tableInfo}/>
}

const EditableMonthComponent = (props: {
    value: Date | string,
    callback: (newValue, tableInfo) => Promise<any>,
    tableInfo: {row: TableRow, queryResult: QueryResult}
}) => {
  const auth = useAuth()
  return (
    <div className='tw-flex tw-items-center'>
      {auth.user.isAdmin &&
        <DatePicker
          views={['month', 'year']}
          onChange={async (newValue) => {
            await props.callback(newValue, props.tableInfo)
          }}
          value={props.value}
          slotProps={{
            textField: {
              size: "small",
              sx: {
                width: '12rem',
                backgroundColor: 'white'
              }
            }
          }}
        />
      }
      {!auth.user.isAdmin &&
        <>{props.value}</>
      }
    </div>
  )
}

export { 
  linkRenderer,
  confirmClaimCheckbox,
  booleanRenderer,
  editableFieldWithDropdown,
  editableField,
  editableMonthField,
  BooleanSwitch
};
