import { Control, Controller } from "react-hook-form";
import { useJobsErrorCenter } from "../useJobsErrorCenter";
import { MenuItem, Select, SxProps } from "@mui/material";

interface EditableDrawerFormSectionTextFieldDropdownProps {
  fieldName: string;
  control: Control;
  options: { value: string; label: string }[];
  menuItemRenderer?: (value: any, sx?: SxProps) => JSX.Element | string;
}

export default function EditableDrawerFormSectionTextFieldDropdown({
  fieldName,
  control,
  options,
  menuItemRenderer,
}: EditableDrawerFormSectionTextFieldDropdownProps) {
  const { submittingForm } = useJobsErrorCenter();

  return (
    <Controller
      name={fieldName}
      control={control}
      disabled={submittingForm}
      render={({ field }) => (
        <Select size="small" {...field}>
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {menuItemRenderer(option.label)}
            </MenuItem>
          ))}
        </Select>
      )}
    />
  );
}
