import { Box, Checkbox, FormControlLabel, Stack, TextField, Tooltip, Typography } from "@mui/material";
import DatePicker from "../../../../../components/lib/SafeLeaseDatePicker";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { usePrivatePolicySettingsStore } from "../../usePrivatePolicySettingsStore/usePrivatePolicySettingsStore";
import dayjs from "dayjs";


type InternalEnforcementOptionsProps = {};

function InternalEnforcementOptions({ }: InternalEnforcementOptionsProps) {
  const intPoliciesOnly = usePrivatePolicySettingsStore((state) => state.intPoliciesOnly);
  const setIntPoliciesOnly = usePrivatePolicySettingsStore((state) => state.setIntPoliciesOnly);
  const intCompliantDeclaration = usePrivatePolicySettingsStore((state) => state.intCompliantDeclaration);
  const setIntCompliantDeclaration = usePrivatePolicySettingsStore((state) => state.setIntCompliantDeclaration);
  const internalEnforcementEnabled = usePrivatePolicySettingsStore((state) => state.internalEnforcementEnabled);
  const selectedIntEnforcementDate = usePrivatePolicySettingsStore((state) => state.selectedIntEnforcementDate);
  const setSelectedIntEnforcementDate = usePrivatePolicySettingsStore((state) => state.setSelectedIntEnforcementDate);

  return (
    <>
      <FormControlLabel
        label="Only accept policies that were submitted to SafeLease"
        control={<Checkbox checked={intPoliciesOnly} onChange={(e) => setIntPoliciesOnly(e.target.checked)} disabled={!internalEnforcementEnabled} />}
      />
      <FormControlLabel
        label="Only accept reviewed and compliant private policy declarations page"
        control={<Checkbox checked={intCompliantDeclaration} onChange={(e) => setIntCompliantDeclaration(e.target.checked)} disabled={!internalEnforcementEnabled} />}
      />
      <Stack alignItems="center" spacing={1} direction="row">
        <Typography variant="h6">Internal Enforcement Date</Typography>
        <Tooltip placement="right" title="This is the date that SafeLease will begin enforcing private policy compliance that is set for the private policies that are submitted directly through SafeLease.">
          <InfoOutlinedIcon color="disabled" />
        </Tooltip>
      </Stack>
      <Box>
        <DatePicker
          label="Date"
          disabled={!internalEnforcementEnabled}
          value={selectedIntEnforcementDate}
          onChange={(newDate: Date) => setSelectedIntEnforcementDate(dayjs(newDate))}
        />
      </Box>
    </>)
    ;
}

export default InternalEnforcementOptions;
