import { Typography, useTheme } from "@mui/material";
import { Box, Stack } from "@mui/system";
import { SafeLeaseCard } from "@safelease/components";
import { useState } from "react";

//  Not used for anything yet, but can be once we have needs for portfolio level admin tools
const PortfolioAdminTools = () => {
  const [collapsed, setCollapsed] = useState(true);
  const theme = useTheme();

  const handleCollapseClick = () => {
    setCollapsed((prev) => !prev);
  };

  const getSectionTitleToggle = () => {
    const buttonBlue = "rgb(40 121 251)";
    return (
      <Stack direction="row" justifyContent="flex-start" onClick={handleCollapseClick}>
        <Typography sx={{ mb: 1, mt: 2, fontWeight: "bold", color: buttonBlue }}>Portfolio Admin Tools</Typography>
      </Stack>
    );
  };

  if (collapsed) return <Box>{getSectionTitleToggle()}</Box>;

  return (
    <Box>
      {getSectionTitleToggle()}
      <SafeLeaseCard
        sx={{
          p: 2,
          width: "100%",
          cursor: "pointer",
          "&:hover": {
            border: "2px solid",
            borderColor: theme.palette.primary.light,
            boxShadow: "0 0 20px rgba(0,0,0,0.10)",
          },
        }}
      >
        <Typography sx={{ variant: "caption" }}>visible to admins only</Typography>
        <Typography>Portfolio level settings needed for SafeLease employees to access can be added here.</Typography>
      </SafeLeaseCard>
    </Box>
  );
};

export default PortfolioAdminTools;
