import React, { useState } from "react";

// UI - libs
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  IconButton,
  Grid,
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
  MenuItem,
  DialogActions,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import { useSnackbar } from "notistack";

// Data
import { useApolloClient } from "@apollo/client";
import {
  Location,
  LocationAddress,
} from "../../../utilities/generated/gql-types";
import { stateAbbr } from "../../../utilities/field-enums";
import SetupDataService from "../../../services/setup.service";
import { getLocationAddresses } from "../../../queries";

interface UpdateLocationAddressDialogProps {
  location: Location;
  locationAddress: LocationAddress;
  open: boolean;
  onClose: () => void;
}

export default function UpdateLocationAddressDialog({
  location,
  locationAddress,
  open,
  onClose,
}: UpdateLocationAddressDialogProps) {
  const { enqueueSnackbar } = useSnackbar();

  const client = useApolloClient();

  const [loading, setLoading] = useState<boolean>(false);
  const [address, setAddress] = useState(locationAddress.address);
  const [city, setCity] = useState(locationAddress.city);
  const [state, setState] = useState(locationAddress.state);
  const [errors, setErrors] = useState<{
    address?: { msg?: string };
    city?: { msg?: string };
    state?: { msg?: string };
    postalCode?: { msg?: string };
  }>({});

  const handleUpdateLocationAddress = async () => {
    setLoading(true);
    setErrors({});

    const response = await SetupDataService.updateLocationAddress({
      id: locationAddress.id,
      relationshipId: location.relationshipId,
      address,
      city,
      state,
    });

    await client.refetchQueries({ include: [getLocationAddresses] });

    if (!response.data?.errors) {
      enqueueSnackbar("Location address updated.", { variant: "success" });
    } else {
      setErrors(response.data.errors);
      enqueueSnackbar(
        "Error updating location address: \n" +
          Object.values(response.data.errors)
            .map(({ msg }) => msg)
            .join("\n"),
        { variant: "error" }
      );
    }

    setLoading(false);

    if (Object.keys(errors).length === 0) {
      onClose();
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Add Location Address</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <DialogContentText>
          Update location address for{" "}
          <b>
            {locationAddress.address}, {locationAddress.city}{" "}
            {locationAddress.state}
          </b>
        </DialogContentText>
        <TextField
          className="tw-w-full"
          label="Address"
          value={address}
          onChange={(e) => setAddress(e.target.value)}
          fullWidth
          margin="normal"
          sx={{ marginBottom: 1, width: "100%" }}
          helperText={errors.address?.msg}
        />
        <Grid className="split-input-fields" container spacing={2}>
          <Grid item xs={9} className="input-grid">
            <TextField
              className="tw-w-full"
              name="city"
              label={"City"}
              type="city"
              value={city}
              onChange={(e) => setCity(e.target.value)}
              sx={{ marginBottom: 1, width: "100%" }}
              helperText={errors.city?.msg}
            />
          </Grid>
          <Grid item xs={3} className="input-grid">
            <FormControl fullWidth>
              <InputLabel id="state-selector-label">State</InputLabel>
              <Select
                autoWidth
                id="state-selector"
                labelId="state-selector-label"
                label="State"
                name="state"
                value={state}
                onChange={(e) => setState(e.target.value)}
                MenuProps={{
                  sx: {
                    maxHeight: 400,
                  },
                }}
              >
                {stateAbbr.map((state) => (
                  <MenuItem value={state} key={`state-${state}`}>
                    {state}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{errors.state?.msg}</FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          variant="contained"
          onClick={handleUpdateLocationAddress}
          sx={{ display: "inline-block", float: "right", mt: 4 }}
          className="modal-login-button"
          disabled={!address || !city || !state}
          loading={loading}
        >
          Update Location Address
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
