import { RelationshipUser } from "../../../types/OrganizationSettingsTypes";

export const buildMenuItems = (users: RelationshipUser[]) => {
  const menuItems = users.map((user) => {
    return {
      value: user.id,
      label: user.name,
    };
  });
  return menuItems;
};

export const findUserById = (users: RelationshipUser[], id: number) => {
  return users.find((user: RelationshipUser) => user.id === id);
};

export const getUserProfileImageUrls = (users: RelationshipUser[]) => {
  return users.map((users) => users.profileImageUrl);
};
