import { Checkbox, FormHelperText, ListItemText, MenuItem, Select, SelectProps } from "@mui/material";

interface ModelWithId {
  id: string;
}

type TDropdownOption<T> = T & ModelWithId & { [key: string]: any };

interface CheckboxSelectionProps<T> extends SelectProps {
  options: TDropdownOption<T>[];
  value?: TDropdownOption<T>[];
  handleSelect?: (option: T | T[]) => void;
  disableOptions?: boolean;
  checkOptions?: boolean;
  helperText?: string;
  renderText?: (selected: TDropdownOption<T>) => string;
}

export default function CheckboxSelection<T>({
  options,
  disableOptions=false,
  checkOptions=false,
  helperText,
  renderText,
  ...rest
}: CheckboxSelectionProps<T>) {  
  
  return (
    <>
    <Select
    multiple
    defaultValue={[]}
    {...rest}
    >
      {options.map((option) => (
        <MenuItem
        key={option.id}
        value={option as any} // ignore ts error, we can set objects as values if we want
        disabled={disableOptions}
        >
          <Checkbox checked={!!rest.value?.find((opt: TDropdownOption<T>) => opt.id === option.id) || checkOptions} />
          <ListItemText primary={renderText(option)} />
        </MenuItem>
      ))}
    </Select>
    {helperText && <FormHelperText sx={{ ml: '14px', color: (theme) => theme.palette.error.main }}>{helperText}</FormHelperText>}
  </>
  )
}
