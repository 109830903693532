import { createContext, Context } from 'react'
import { useQuery, QueryResult } from '@apollo/client';
import { getAllNotifications } from '../queries';
import { NotificationType } from './field-enums'
import { Notification, Note } from '../utilities/generated/gql-types'
import { Claim } from '../utilities/generated/gql-types'

export interface NotificationsContextType {
  allNotifications: Notification[],
  allNotificationsQuery: QueryResult<{getAllNotifications: Notification[]}>,
  NotificationsHelper: typeof NotificationsHelper
}

const NotificationsContext: Context<NotificationsContextType> = createContext(null)

const NotificationsHelper = {
  hasInvoiceNotification: (allNotifications: Notification[]): number => {
    const invoiceNotifications = allNotifications?.filter((notification: Notification) => {
      return notification.notificationType === NotificationType.Invoice
    })
    if (invoiceNotifications?.length > 0) return invoiceNotifications.length
    return 0
  },
  hasClaimNoteNotification: (allNotifications: Notification[]): boolean => {
    const claimNotifications = allNotifications?.filter((notification: Notification) => {
      return notification?.notificationType === NotificationType.ClaimNote
    })
    if (claimNotifications?.length > 0) return true
    return false
  },
  noteHasNotification: (note: Note, allNotifications: Notification[]) => {
    if (!note) return false
    return !!allNotifications.find(notification => {
      return notification.noteId == note.id
    })
  },
  mostRecentNotification: (allNotifications: Notification[]): Notification => {
    const mostRecentNotification = JSON.parse(JSON.stringify( allNotifications))
        .filter((notification: Notification) => notification.notificationType !== NotificationType.Invoice)
        .sort((notif1, notif2) => {
          const notif1Date = new Date(notif1.createdAt)
          const notif2Date = new Date(notif2.createdAt)
          return notif1Date > notif2Date ? -1 : 1
        })[0]
    return mostRecentNotification
  },
  claimHasNotification: (
      claim: Claim, 
      allNotifications: Notification[]): boolean => {
    const allClaimNotifications = allNotifications?.filter((notification: Notification) => {
      return notification?.notificationType === NotificationType.ClaimNote
    })
    /*
    const claimNotesWithNotification = claimNotes?.filter(note => {
      const noteHasNotification = claimNotifications.filter(notification => {
        return notification.noteId == note.id &&
            claim.id == note.claimId
      })
      return noteHasNotification?.length > 0
    })*/
    const claimNotificationsForGivenClaim = allNotifications?.filter(
        (notification: Notification) => {
          return notification.claimId == claim.id
        })
    return claimNotificationsForGivenClaim?.length > 0
  }
}

const NotificationsProvider = (props: {children: JSX.Element[]}) => {

  const allNotificationsQuery: QueryResult<{getAllNotifications: Notification[]}> =
      useQuery(getAllNotifications)

  if(!allNotificationsQuery.data) return null;

  const { children } = props
  return (
    <NotificationsContext.Provider value={
        {
          allNotifications: allNotificationsQuery?.data?.getAllNotifications,
          allNotificationsQuery,
          NotificationsHelper
        }}>
      {children}
    </NotificationsContext.Provider>
  )
}

export { 
  NotificationsProvider,
  NotificationsContext,
  NotificationsHelper,
  NotificationsContextType
}
