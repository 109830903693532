import { Box, Typography, useTheme } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { createEditor, Descendant } from "slate";
import { Slate, Editable, withReact } from "slate-react";
import { useReputation } from "../../../../useReputation";
import { withHtml } from "./withHtml";
import { SlateElement } from "./SlateElement";
import { SlateLeaf } from "./SlateLeaf";

export type TypedDescendant = Descendant & {
  type: string;
  children: Array<{
    text: string;
  }>;
};

interface CampaignStepBodyEditorProps {
  onChange?: ((value: Descendant[]) => void) | undefined;
  value: Descendant[];
  setValue?: (value: Descendant[]) => void;
  readOnly?: boolean;
}

/**
 * Campaign step body editor
 * Highly pared down version of https://www.slatejs.org/examples/paste-html
 * Converts text input into SendGrid-ready HTML
 * Also accepts HTML input and displays as editable text
 *
 * SlateJS is a very complicated library and is worth deep diving into if you're going to be working with it
 * https://docs.slatejs.org/
 * TL;DR: It's a framework for building rich text editors, very customizable
 */
export function CampaignStepBodyEditor({ value, setValue, readOnly = false }: CampaignStepBodyEditorProps) {
  const renderElement = useCallback((props) => <SlateElement {...props} />, []);
  const renderLeaf = useCallback((props) => <SlateLeaf {...props} />, []);
  const editor = useMemo(() => withHtml(withReact(createEditor())), []);
  const theme = useTheme();
  const { statuses } = useReputation();
  const [focused, setFocused] = useState<boolean>(false);

  const disabled = statuses.campaignConfigurationForm;

  return (
    <Box>
      {!readOnly && (
        <Typography component="label" sx={{ mb: 1 }}>
          Body
        </Typography>
      )}
      <Slate editor={editor} initialValue={value} onChange={setValue}>
        <Editable
          readOnly={readOnly || disabled}
          tabIndex={0} // Make the editor tab-into-able
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          style={{
            color: disabled ? "rgba(0, 0, 0, 0.38)" : "black",
            backgroundColor: readOnly ? "transparent" : "white",
            border: readOnly ? "1px solid transparent" : focused ? "1px solid #2779FB" : "1px solid #EBEFF7",
            padding: readOnly ? 0 : theme.spacing(1),
            borderRadius: theme.shape.borderRadius,
            outline: "none",
            transition: "border-color 0.15s ease-in-out",
          }}
        />
      </Slate>
    </Box>
  );
}
