import { Close } from "@mui/icons-material";
import {
  Box,
  Dialog,
  DialogProps,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Stack,
  TextField,
  Theme,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers-pro";
import { SafeLeaseButton } from "@safelease/components";

interface NewQuickCampaignDialogProps extends DialogProps {
  handleClose: () => void;
}

export function NewQuickCampaignDialog({
  open,
  handleClose,
}: NewQuickCampaignDialogProps) {
  return (
    <Dialog open={open} onClose={handleClose} PaperProps={{ sx: { p: 4 } }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Box>
              <Typography variant="h6">Launch a Quick Campaign</Typography>
              <Typography
                variant="body2"
                sx={{ color: (theme: Theme) => theme.palette.grey.A100 }}
              >
                Send out an email to your tenants to solicit them for feedback
                or a review.
              </Typography>
            </Box>
            <IconButton size="small" onClick={handleClose}>
              <Close />
            </IconButton>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2" fontWeight="700" sx={{ mb: 1 }}>
            Facilities
          </Typography>
          <Select value="800 Brazos St" fullWidth>
            <MenuItem value="800 Brazos St">800 Brazos St</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2" fontWeight="700" sx={{ mb: 1 }}>
            Tenants
          </Typography>
          <Select value="173 selected" fullWidth>
            <MenuItem value="173 selected">173 selected</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={12}>
          <Divider sx={{ mt: 4 }} />
        </Grid>
        <Grid item xs={12}>
          <Stack direction="column" spacing={2}>
            <Typography variant="body2" fontWeight="700" sx={{ mb: 1 }}>
              Step 1: Customize Email
            </Typography>
            <TextField
              multiline
              rows={2}
              defaultValue="Hi [First name], please leave a review for us on Google. It will only take a few minutes and will help us improve our services."
            />
            <Stack direction="row" spacing={2}>
              <TextField
                size="small"
                placeholder="Enter email to send test"
                sx={{ flex: 1 }}
              />
              <SafeLeaseButton size="small" color="navy" variant="outlined">
                Send Test
              </SafeLeaseButton>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Divider sx={{ mt: 4 }} />
        </Grid>
        <Grid item xs={12}>
          <Stack direction="column" spacing={2}>
            <Typography variant="body2" fontWeight="700" sx={{ mb: 1 }}>
              Step 2: Set Send Date
            </Typography>
            <DatePicker />
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="flex-end"
            alignItems="center"
          >
            <SafeLeaseButton
              size="small"
              color="navy"
              variant="outlined"
              onClick={handleClose}
            >
              Cancel
            </SafeLeaseButton>{" "}
            <SafeLeaseButton size="small" color="blue" variant="contained">
              Start Campaign
            </SafeLeaseButton>
          </Stack>
        </Grid>
      </Grid>
    </Dialog>
  );
}
