/*  UI - libs */
import {
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from "@mui/material";
import { Download, Star } from "@mui/icons-material";
import { amber, blue, grey } from "@mui/material/colors";
import { useSnackbar } from "notistack";

/* Data */
import { AdminToolNavProps } from "./AdminToolNavCard";
import { useHistory } from "react-router-dom";
import { useAdminToolsStore } from "./useAdminToolsStore";


/* -------------------------------------------------------------------------- */
/* AdminToolNavListItem                                                       */
/*                                                                            */  
/* List item for vertical display of admin tools                              */  
/* -------------------------------------------------------------------------- */

export default function AdminToolNavListItem({
  label,
  path,
  icon,
  onClick,
  id,
  downloadable,
}: AdminToolNavProps) {

  /* Hooks */
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { favorites, toggleFavorite } = useAdminToolsStore();

  const isFavorite = favorites.findIndex((favorite) => favorite === id) > -1;

  /* Can't remove "event" as reports need it */
  const handleClick = (event) => {
    if (path) {
      history.push(path);
    } else {
      onClick(event);
    }
  };

  /* Toggle the favorite status of a list item */
  const handleFavorite = (event: React.SyntheticEvent) => {
    event.stopPropagation();
    if (isFavorite) {
      enqueueSnackbar(`Removed ${label} from favorites`, {
        variant: "success",
      });
    } else {
      enqueueSnackbar(`Added ${label} to favorites`, { variant: "success" });
    }
    toggleFavorite(id);
  };

  return (
    <ListItem
      sx={{
        border: "1px solid #EBEFF7",
        boxShadow: `0 0 20px rgba(0, 0, 0, 0.05)`,
        p: 2,
        bgcolor: "white",
        my: 1,
        cursor: "pointer",
        height: 74,
        "&:hover": { bgcolor: blue[100] },
      }}
      onClick={handleClick}
    >
      <ListItemIcon sx={{ color: blue[400] }}>{icon}</ListItemIcon>
      <ListItemText
        primary={label}
        primaryTypographyProps={{
          sx: { fontWeight: "bold", color: "#152744" },
        }}
      />
      <ListItemIcon>
        {downloadable && (
          <Tooltip title="File download">
            <Download sx={{ color: grey[300] }} />
          </Tooltip>
        )}
      </ListItemIcon>
      <ListItemIcon>
        <IconButton onClick={handleFavorite}>
          <Tooltip title={`${isFavorite ? "Remove from" : "Add to"} favorites`}>
            <Star
              sx={{
                float: "right",
                color: isFavorite ? amber[500] : grey[300],
              }}
            />
          </Tooltip>
        </IconButton>
      </ListItemIcon>
    </ListItem>
  );
}
