import { TextField } from "@mui/material";
import { useJobsErrorCenter } from "../useJobsErrorCenter";
import { Control, Controller } from "react-hook-form";

interface EditableDrawerFormSectionTextFieldProps {
  fieldName: string;
  control: Control;
}

export default function EditableDrawerFormSectionTextField({
  fieldName,
  control,
}: EditableDrawerFormSectionTextFieldProps) {
  const { submittingForm } = useJobsErrorCenter();

  return (
    <Controller
      name={fieldName}
      control={control}
      disabled={submittingForm}
      render={({ field: { value, ...rest } }) => (
        <TextField
          fullWidth
          multiline
          size="small"
          value={value ? value : ""}
          {...rest}
        />
      )}
    />
  );
}
