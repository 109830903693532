import { SxProps } from "@mui/material";
import TableWrapperLegacy from "./table-wrapper-legacy";
import TableWrapperVirtualized from "./table-wrapper-virtualized";

export interface TableWrapperProps {
  columns: any;
  title: any;
  children: any;
  isSubTable?: boolean;
  preTable?: JSX.Element;
  cellPadding?: string;
  sx?: SxProps;
  tableHeight?: number;
  size?: { width: number | undefined, height: number | undefined }
  virtualized?: boolean;
}

// Generator to capture tables that currently are not compatible with virtualization
// e.g. we haven't set the parent of TableWrapper to flex column
const TableWrapper = ({ virtualized=false, ...props}: TableWrapperProps) => {
  return virtualized ?  <TableWrapperVirtualized {...props} /> : <TableWrapperLegacy {...props} />
}

export default TableWrapper;
