import { CampaignOutlined, KeyboardArrowRight, Lens } from "@mui/icons-material";
import { Stack, Theme, Typography } from "@mui/material";

interface CampaignCounterProps {
  count?: number;
  label?: string;
}

export function CampaignCounter({ count = 0, label = "Active Campaigns" }: CampaignCounterProps) {
  return (
    <Stack direction="row" spacing={1} alignItems="center" sx={{ color: (theme: Theme) => theme.palette.grey.A100 }}>
      <Lens
        sx={{
          height: 10,
          width: 10,
          color: (theme: Theme) => theme.palette.blue.main,
        }}
      />
      <CampaignOutlined />
      <Typography variant="subtitle2" sx={{ color: (theme: Theme) => theme.palette.grey.A100 }}>
        <Typography component="span" variant="subtitle2" sx={{ color: (theme: Theme) => theme.palette.navy.main, fontWeight: 700 }}>
          {count}
        </Typography>{" "}
        {label}
      </Typography>
      <KeyboardArrowRight sx={{ height: 18, width: "auto" }} />
    </Stack>
  );
}
