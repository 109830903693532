import http from "./http-common";
import { editableAttachmentMetadata } from '../utilities/field-enums'
import axios from "axios";

class AttachmentDataService {
  uploadFromFrontend(formdata: FormData) {
    console.log('attachment data service hit', {formdata: formdata.get('attachment')})
    console.log('🔥 1st file:', formdata.get('attachment'))
    console.log('payload', formdata.get('payload'))
    console.log({formdata})
    return http.post("/documents/upload", formdata)
  }

  delete(payload: {id: number | string}) {
    return http.post("documents/delete", payload)
  }

  directUploadUrl() {
    return http.get("/documents/direct_upload_url");
  }

  async directUpload(file) {
    const response = await http.get("/documents/direct_upload_url");
    const directUploadUrl = response.data.directUploadUrl;
    const filename = response.data.filename;
    
    var formData = new FormData();
    formData.append('file', file, 'file');

    const s3AxiosInstance = axios.create();
    const uploaded = await s3AxiosInstance.put(directUploadUrl, formData, {
      headers: {
        "Content-Type": file.type,
      },
    });
    console.log(uploaded);

    return filename;
  }

  async editDocumentName(payload: {attachmentId: number | string, newName: string}) {
    return http.post("/documents/edit_document_name", payload)
  }

  async editMetadata(payload: 
      { [key in editableAttachmentMetadata]?: any } & 
      {attachmentId: number | string}
  ) {
    return http.post("/documents/edit_document_metadata", payload)
  }
  
  async getUrl(payload: {documentId: number | string}) {
    return http.post("/documents/get_url", payload)
  }

  async getUrlRequiringOtp(payload: {documentId: number | string}) {
    return http.post("/documents/get_url_requiring_otp", payload)
  }
}

export default new AttachmentDataService()
