// Libs
import { create } from "zustand";

// Data
import { GeneralUnit } from "../../utilities/generated/gql-types";

interface GeneralUnitsHistoryState {
  selectedGeneralUnit: null | GeneralUnit;
  setSelectedGeneralUnit: (generalUnit: null | GeneralUnit) => void;

  drawerOpen: boolean;
  setDrawerOpen: (drawerOpen: boolean) => void;
}

export const useGeneralUnitsHistory = create<GeneralUnitsHistoryState>()(
  (set, get) => ({
    selectedGeneralUnit: null,
    setSelectedGeneralUnit: (generalUnit: null | GeneralUnit) =>
      set({ selectedGeneralUnit: generalUnit }),

    drawerOpen: false,
    setDrawerOpen: (drawerOpen: boolean) => set({ drawerOpen }),
  })
);
