// UI - Libs
import { Alert, Box, Grid, Skeleton, Stack, Theme, Tooltip, Typography } from "@mui/material";
import { SafeLeaseCard } from "@safelease/components";

// UI - Local
import { FacilityOverviewHeader } from "./FacilityOverviewHeader";
import { CampaignCard } from "../../CampaignCard";
import { ReviewsGrid } from "./ReviewsGrid";
import { RatingsDisplay } from "../../../../components/RatingsDisplay";
import { RatingBreakdownGraphs } from "../../../../components/RatingBreakdownGraphs";
import dayjs from "dayjs";
import { useReputation } from "../../../../useReputation";
import { ReviewReplyDrawer } from "../../../ReviewReplyDrawer";
import { PrivateReview_Reputation as PrivateReview } from "@safelease/service-utilities";
import { InfoOutlined } from "@mui/icons-material";
interface FacilityOverviewProps {}

// Display the reputation overview of a facility
// Essentially the same UI as the portfolio-level overview, but for a single facility
export function FacilityOverview({}: FacilityOverviewProps) {
  const { facilityMetrics, statuses, portfolio, setReviewReplyDrawerOpen, selectedFacilityId, facility, pendingReviews, metrics } =
    useReputation();

  if (!portfolio || !statuses || statuses.any === "loading") return <Skeleton variant="rectangular" height={400} sx={{ width: "100%" }} />;

  if (statuses.facilityMetrics === "error") return <Alert severity="error">Failed to load facility. Please refresh the page.</Alert>;

  const reviews = facilityMetrics.reviews;

  // Get all the pending reviews at this facility
  const pendingReplyPublicReviews = pendingReviews?.filter((review) => review.facilityId === selectedFacilityId) ?? [];

  // Get all the pending private reviews at this facility
  const pendingReplyPrivateReviews = portfolio.facilities
    .filter((facility) => facility.id === selectedFacilityId)
    .flatMap((facility) =>
      facility.privateReviews.filter((sub: PrivateReview) => {
        // include the feedback submission if there's no response yet
        // or if the response is within the last 24 hours, so they can edit if they make a mistake when clicking 'responded'
        return (
          sub.responseSnippet === null ||
          sub.responseSnippet === "" ||
          (sub.responseSnippet && sub.responseDate && dayjs(sub.responseDate).isAfter(dayjs().subtract(1, "day")))
        );
      }),
    );

  // Count the total number of reviews/feedback that are pending a reply
  const reviewsPendingReplyCount =
    pendingReplyPublicReviews.length + pendingReplyPrivateReviews.filter((review) => !review.responseSnippet).length;

  const facilityName = [facility.address, facility.city, facility.state, facility.postalCode].filter((part) => !!part).join(", ");

  // ########################################################################################
  // Static Live Campagin Display
  // ########################################################################################
  const sortedCampaigns = facilityMetrics.campaigns.sort((a, b) => {
    const order = ["Move In", "Anniversary", "Evergreen", "Move Out"];

    const indexA = order.indexOf(a.name);
    const indexB = order.indexOf(b.name);
    if (indexA !== -1 && indexB !== -1) {
      return indexA - indexB;
    } else if (indexA !== -1) {
      return -1;
    } else if (indexB !== -1) {
      return 1;
    } else {
      return a.name.localeCompare(b.name);
    }
  });
  return (
    <>
      <Grid container spacing={2} sx={{ maxWidth: 1680 }}>
        <Grid item xs={12}>
          <FacilityOverviewHeader
            logo={facility.logoUrlLarge}
            facilityName={facility.companyName}
            facilityAddress={facilityName}
            activeCampaignCount={1}
            reviewsPendingReplyCount={reviewsPendingReplyCount}
            handleReviewReplyDrawerOpen={() => setReviewReplyDrawerOpen(true)}
          />
        </Grid>
        <Grid item xs={12}>
          <RatingsDisplay
            averageRating={facilityMetrics.averageRating}
            previousRating={facilityMetrics.averageRating - facilityMetrics.averageRatingWeeklyChange}
            ratings={facilityMetrics.reviewCounts}
          />
        </Grid>
        <Grid item xs={12}>
          <RatingBreakdownGraphs
            periods={metrics.facilities.filter((metricFacility) => metricFacility.id === selectedFacilityId)[0]?.historicalReviewData}
            loading={statuses.facilityMetrics === "loading"}
            liveDate={facility.liveDate}
          />
        </Grid>
        <Grid item xs={12}>
          <Stack direction="row" alignItems="center" sx={{ mb: 1, mt: 2 }} spacing={1}>
            <Typography variant="body1" fontWeight="bold">
              Live Campaigns
            </Typography>
            <Tooltip
              title="Campaigns are connected to parts of a tenant's lifecycle.
            Each campaign sends messages with distinct language to reflect its type.
            Tenants are only enrolled in one campaign at a time, and the campaign changes as they move through their lifecycle.
            "
            >
              <InfoOutlined fontSize="small" sx={{ color: (theme: Theme) => theme.palette.grey.A100 }} />
            </Tooltip>
          </Stack>
          <Grid container spacing={2}>
            {sortedCampaigns
              .filter((campaign) => campaign.campaignType === "recurring")
              .map((campaign) => (
                <Grid item xs={6} md={6} lg={4} xl={3} key={campaign.id}>
                  <CampaignCard campaign={campaign} />
                </Grid>
              ))}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ mb: 1, mt: 2 }}>
            <Typography variant="body1" fontWeight="bold">
              Public Reviews
            </Typography>
          </Stack>
          <SafeLeaseCard sx={{ pb: 2 }}>
            <Box sx={{ height: 360 }}>
              <ReviewsGrid reviews={reviews} />
            </Box>
          </SafeLeaseCard>
        </Grid>
      </Grid>
      <ReviewReplyDrawer publicReviews={pendingReplyPublicReviews} privateReviews={pendingReplyPrivateReviews} />
    </>
  );
}
