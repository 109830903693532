function JobStatus(props: {status: number}) {
  const { status } = props
  return (
    <>
      {status === 0 &&
        <div className="status-pill status-pill__pending">
          {status} - Pending
        </div>
      }
      {status === 1 &&
        <div className="status-pill status-pill__paid">
          {status} - Completed
        </div>
      }
      {status === 2 &&
        <div className="status-pill status-pill__denied">
          {status} - Failed
        </div>
      }
      {status === 3 &&
        <div className="status-pill status-pill__in-progress">
          {status} - In Progress
        </div>
      }
    </>
  )
}

export function jobStatusRenderer(status: number) {
  return <JobStatus status={status} />
}