import { ChevronLeft } from "@mui/icons-material";
import { Box } from "@mui/material";
import { Link } from 'react-router-dom';
import { Image } from '../image';

export function SafeleaseNavLogo({ setDrawerOpen }) {
  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', paddingTop: 1, paddingRight: 1 }}>
        <Box
          className='chevron-left'
          sx={{
            opacity: 0, // Initially hidden (transparent)
            visibility: 'hidden', // Hidden from layout initially
            transition: 'opacity .5s ease, visibility 0s ease .5s', // Fade out over 5 seconds
          }}
        >
          <ChevronLeft onClick={() => setDrawerOpen(false)} sx={{ color: 'grey' }} />
        </Box>
      </Box>
      <Link to='/' style={{ marginBottom: 20 }}>
        <Image
          style={{ marginLeft: 24, marginRight: 24 }}
          alt='SafeLease logo'
          src='/images/logos/horizontal/safelease_logohorizontal_rgb_navyblueonoffwhite.svg'
        />
      </Link>
    </>
  );
}
