import { useState } from "react";
import { useAuth } from "../../auth";
import { getInvoices } from "../../queries";
import { useQuery } from "@apollo/client";
import Loader from "../../shared/Loader";
import BillingDataService from '../../services/billing.service';
import JobsDataService from '../../services/jobs.service';
import client from "../../utilities/apolloClient";
import FileSaver from 'file-saver';
import { AxiosResponse } from "axios";
import { OtpRequired } from "../../admin";
import DatePicker from "../../components/lib/SafeLeaseDatePicker";
import { Box, Button, Checkbox, CircularProgress, Divider, FormControlLabel, Link, Tooltip, Typography } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { Formatters } from "../../utilities/formatters";
import { muiTooltip, redCTAButton } from '../../styles/mui-overrides';
import { Image } from "../../image";
import SafeleaseDataGrid from "../../shared/safelease-data-grid";
import _ from 'lodash';
import { Invoice } from "../../utilities/generated/gql-types";
import { invoicesTableColumnDefs } from "./InvoicesTableColumnDefs";
import useWindowSize from "../../utilities/useWindowSize";
import { useAdminBillingStore } from "./useAdminBillingStore";
import dayjs from "dayjs";
import { GridRowParams } from "@mui/x-data-grid-premium";
import InvoiceDetailsDrawer from "./InvoiceDetailsDrawer/InvoiceDetailsDrawer";
import CustomToolbar from "../../shared/custom-toolbar";

const AdminBilling = () => {
  const lastMonth = (() => {
    const date = new Date();
    date.setMonth(date.getMonth() - 1);
    date.setHours(0, 0, 0, 0);
    date.setDate(1);
    return date;
  })();
  const [month, setMonth] = useState<Date>(lastMonth);
  const [loading, setLoading] = useState<boolean>(false);
  const [autoConfirmFailuresLoading, setAutoConfirmFailuresLoading] = useState<boolean>(false);
  const [runAutoConfirmLoading, setRunAutoConfirmLoading] = useState<boolean>(false);
  const [adHocChecked, setAdHocChecked] = useState<boolean>(false);
  const auth = useAuth();
  const isSuperAdmin = auth.user.role === 'superAdmin';
  const audio = new Audio('https://safelease-static.s3.us-east-2.amazonaws.com/cha-ching.mp3');

  const size = useWindowSize();
  const {
    visibleColumns,
    updateVisibleColumns,
    selectedInvoiceIds,
    updateSelectedInvoiceIds,
    filterModel,
    updateFilterModel,
    setSelectedInvoiceForDrawer
  } = useAdminBillingStore();

  const invoicesQuery = useQuery(getInvoices, { variables: { servicePeriod: adHocChecked ? null : month, adHocInvoices: adHocChecked } });

  if (invoicesQuery.error) return <>Error getting invoices</>;
  if (invoicesQuery.loading) return <Loader />;

  // TODO: This doesn't stop querying once it begins.
  const fetchQueuedInvoices = async () => {
    do {
      await new Promise((r) => setTimeout(r, 10000));
      await client.refetchQueries({ include: [getInvoices] });
    } while (!!invoicesQuery?.data?.getInvoices.find(invoice => invoice.status === 'queued'));
  };
  if (!!invoicesQuery?.data?.getInvoices.find(invoice => invoice.status === 'queued')) fetchQueuedInvoices();

  const handleGetPaid = async () => {
    setLoading(true);
    try {
      const invoiceIds = selectedInvoiceIds.map(id => id.toString()); // not necessarily needed but to ensure that the type is string[]. DataGridPremium returns string[] by default but just incase that ever changes in the future.
      const response = await BillingDataService.runDwollaBilling(invoiceIds);
      console.log(response);
      await audio.play();
      client.refetchQueries({ include: [getInvoices] });
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const bordereauReportV2 = async (e) => {
    e.preventDefault();

    const response = await BillingDataService.bordereauReportV2(month);
    const csvData = new Blob([response.data.output], { type: 'text/csv;charset=utf-8;' });
    FileSaver.saveAs(csvData, `Bordereau Reporting CSV V2 (${month.toISOString().split('T')[0]}).csv`);
  };

  const ytdSafeLeaseRevenueReport = async (e) => {
    e.preventDefault();

    const response = await BillingDataService.ytdSafeLeaseRevenueReport(month);
    const csvData = new Blob([response.data.output], { type: 'text/csv;charset=utf-8;' });
    FileSaver.saveAs(csvData, `YTD SafeLease Revenue Report Through ${dayjs(month).format("MMM")} ${month.getFullYear()}.csv`);
  };

  async function autoConfirmFailures(e) {
    e.preventDefault();
    setAutoConfirmFailuresLoading(true);

    const response = await BillingDataService.autoConfirmFailures();
    let jobResponse: AxiosResponse<any>;
    while (true) {
      jobResponse = await JobsDataService.status(response.data.jobId);
      if (jobResponse.data.status !== 'pending') {
        break;
      }
      await new Promise((r) => setTimeout(r, 5000));
    }
    const csvData = new Blob([jobResponse.data.output], { type: 'text/csv;charset=utf-8;' });
    const date = new Date().toISOString().split('T')[0];
    FileSaver.saveAs(csvData, `Auto Confirm Failures (${date}).csv`);
    setAutoConfirmFailuresLoading(false);
  }

  const runAutoConfirmClicked = async () => {
    setRunAutoConfirmLoading(true);
    const invoiceIds = invoicesQuery?.data?.getInvoices?.filter(invoice => invoice.confirmed === false).map(invoice => invoice.id);
    for (const chunk of _.chunk<number>(invoiceIds, 10)) {
      await BillingDataService.runAutoConfirm(chunk);
    }
    setRunAutoConfirmLoading(false);
    invoicesQuery.refetch();
  };

  return (
    <OtpRequired>
      <div>
        <div className="tw-flex tw-flex-row tw-justify-between">
          <div className="tw-flex tw-flex-col tw-justify-between tw-mb-4">
            <DatePicker
              value={month}
              onChange={(newDate: Date) => {
                setMonth(newDate);
              }}
              label="Service Month"
              views={['year', 'month']}
              disabled={adHocChecked}
            />
            <Link onClick={bordereauReportV2} sx={{ cursor: 'pointer' }}>Bordereau Reporting CSV</Link>
            {autoConfirmFailuresLoading ? (
              <div style={{ display: "flex", width: "200px", justifyContent: "center" }}>
                <CircularProgress size="1rem" />
              </div>
            ) : (
              <Link onClick={autoConfirmFailures} sx={{ cursor: 'pointer' }}>
                Invoice Auto Confirm Failures
              </Link>
            )}
            <Link onClick={ytdSafeLeaseRevenueReport} sx={{ cursor: 'pointer' }}>YTD SafeLease Revenue Report</Link>
            <LoadingButton
              sx={{ height: "30px" }}
              onClick={runAutoConfirmClicked}
              loading={runAutoConfirmLoading}
              disableElevation
              variant="contained">
              Run auto confirm
            </LoadingButton>
            <div className="tw-flex tw-flex-row tw-justify-center">
              <FormControlLabel
                label={<Typography>Ad-Hoc Invoices Only</Typography>}
                control={
                  <Checkbox
                    onChange={(e) => setAdHocChecked(e.target.checked)}
                    checked={adHocChecked}
                  />
                }
              />
            </div>
            {isSuperAdmin && (
              <Button disabled={loading} onClick={handleGetPaid} sx={{ ...redCTAButton(), width: '100%', margin: 0, }}>
                Get Paid
              </Button>
            )}
          </div>

          <div className="tw-flex tw-mb-4 widget widget__long">
            <div className="totals" style={{ marginBottom: 0 }}>
              <div className="title">Summary Statistics</div>
              <div className="body">
                <div className="total">
                  <div className="number">
                    {Formatters.penniesToDollars(
                      _.sumBy(invoicesQuery.data?.getInvoices, (invoice: Invoice) => invoice.amount)
                    )}
                  </div>
                  <div className="label">
                    Total Monthy Due
                    <Tooltip
                      placement="bottom"
                      title={
                        'This is the sum of all paid and unpaid invoice amounts for the chosen service period.'
                      }
                    >
                      <Button sx={muiTooltip}>
                        <span className="badge">
                          <Image src="/images/tooltip-icon.svg" alt="warning" />
                        </span>
                      </Button>
                    </Tooltip>
                  </div>
                </div>
                <div className="total">
                  <div className="number">
                    {Formatters.penniesToDollars(
                      _.sumBy(invoicesQuery.data?.getInvoices, (invoice: Invoice) => invoice.balance)
                    )}
                  </div>
                  <div className="label">
                    Remaining Monthy Due
                    <Tooltip
                      placement="bottom"
                      title={
                        'This is the sum of all invoice balances for the chosen service period.'
                      }
                    >
                      <Button sx={muiTooltip}>
                        <span className="badge">
                          <Image src="/images/tooltip-icon.svg" alt="warning" />
                        </span>
                      </Button>
                    </Tooltip>
                  </div>
                </div>
                <div className="total">
                  <div className="number">{invoicesQuery.data?.getInvoices?.length}</div>
                  <div className="label">
                    Total Invoices
                    <Tooltip
                      placement="bottom"
                      title={'This is the count of all invoices for the chosen service period.'}
                    >
                      <Button sx={muiTooltip}>
                        <span className="badge">
                          <Image src="/images/tooltip-icon.svg" alt="warning" />
                        </span>
                      </Button>
                    </Tooltip>
                  </div>
                </div>
                <div className="total">
                  <div className="number">
                    {invoicesQuery.data?.getInvoices?.reduce((total, invoice) => {
                      if (invoice.confirmed) return total + 1;
                      else return total;
                    }, 0)}
                  </div>
                  <div className="label">
                    Confirmed Invoices
                    <Tooltip
                      placement="bottom"
                      title={
                        'This is the count of all confirmed invoices for the chosen service period.'
                      }
                    >
                      <Button sx={muiTooltip}>
                        <span className="badge">
                          <Image src="/images/tooltip-icon.svg" alt="warning" />
                        </span>
                      </Button>
                    </Tooltip>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="tw-ml-2 tw-mr-2 tw-mb-4">
          <Divider />
        </div>
        <Box sx={{ minHeight: 300, height: size.height - (isSuperAdmin ? 400 : 350), backgroundColor: "white", borderRadius: "5px" }}>
          <SafeleaseDataGrid
            autosizeOnMount
            checkboxSelection
            disableRowSelectionOnClick
            columns={invoicesTableColumnDefs}
            rows={invoicesQuery.data?.getInvoices.map((inv) => ({
              ...inv,
              invoiceLink: `/admin/invoices/${inv.relationshipId}?${new URLSearchParams({ billingEntityId: inv.billingEntityId?.toString(), invoiceId: inv.id?.toString() })}`,
            }))}
            columnVisibilityModel={visibleColumns}
            onColumnVisibilityModelChange={(newModel) =>
              updateVisibleColumns(newModel)
            }
            onRowClick={
              (params: GridRowParams<Invoice>) => {
                setSelectedInvoiceForDrawer(params.row);
              }
            }
            rowSelectionModel={selectedInvoiceIds}
            onRowSelectionModelChange={(newModel) =>
              updateSelectedInvoiceIds(newModel)
            }
            filterModel={filterModel}
            onFilterModelChange={(newModel) =>
              updateFilterModel(newModel)
            }
            slots={{
              toolbar: () => <CustomToolbar fileNamePrefix="SafeLease_Billing_Table_Export" />,
            }}
          />
        </Box>
        <InvoiceDetailsDrawer invoicesQuery={invoicesQuery} />
      </div>
    </OtpRequired>
  );
};

export default AdminBilling;
