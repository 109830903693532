import { useState } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import * as NavIcons from './icons';

type NavigationAccordionProps = {
  children: React.ReactNode;
  title: string;
  showEnabled: boolean;
  icon: NavIcons.NavIcons;
};

export function NavigationAccordion({
  children,
  title,
  showEnabled,
  icon,
}: NavigationAccordionProps) {
  const [expanded, setExpanded] = useState(true);
  const [isHovered, setIsHovered] = useState(false);
  const safeleasePrimaryColor = '#3781FB';
  const safeleasePrimaryColorHover = '#3781FB0A';
  const accordionStyles = {
    boxShadow: 'none',
    '& .MuiAccordionSummary-root': {
      mx: 0.6,
      '&:hover': {
        bgcolor: safeleasePrimaryColorHover,
      },
      borderRadius: '8px',
      '& .Mui-expanded': {
        minHeight: '36px !important',
        maxHeight: '36px !important',
      },
    },
  };

  return (
    <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)} sx={accordionStyles}>
      <AccordionSummary
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        expandIcon={
          <ExpandMore sx={{ color: isHovered || showEnabled ? safeleasePrimaryColor : '#8A94A6' }} />
        }
        sx={{
          '& .MuiAccordionSummary-content': {
            display: 'flex',
            alignItems: 'center',
          },
          minHeight: '36px !important',
          maxHeight: '36px !important',
          bgcolor: showEnabled ? safeleasePrimaryColorHover : 'transparent',
        }}
      >
        <ListItemIcon
          sx={{
            color: isHovered || showEnabled ? safeleasePrimaryColor : '#667085',
            minWidth: 44,
          }}
        >
          {NavIcons[icon]()}
        </ListItemIcon>
        <ListItemText
          primary={title}
          primaryTypographyProps={{
            fontSize: 12,
            fontFamily: 'Open Sans',
            color: isHovered || showEnabled ? safeleasePrimaryColor : '#434A60',
          }}
        />
      </AccordionSummary>
      <AccordionDetails sx={{ display: 'flex', flexDirection: 'column', ml: '3rem', p: 0 }}>
        {children}
      </AccordionDetails>
    </Accordion>
  );
}
