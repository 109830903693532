import {
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
} from "@mui/material";
import { useJobsErrorCenter } from "./useJobsErrorCenter";
import { useAuth } from "../../auth";
import { inverseNavyButton, primaryColorButton } from "../../styles/mui-overrides";

interface JobsErrorCenterMetricCardProps {
  value: number | string;
  label: string;
  showButton?: boolean;
}

export default function JobsErrorCenterMetricCard({
  value,
  label,
  showButton,
}: JobsErrorCenterMetricCardProps) {
  const auth = useAuth();
  const { filterForUser, shouldUseFilterModel } = useJobsErrorCenter();
  const isFilteringForUser = shouldUseFilterModel && auth.user?.name;

  return (
    <Card
      elevation={0}
      sx={{ border: `1px solid #EBEFF7`, height: "100%" }}
    >
      <CardContent>
        <Typography color="textSecondary">
          {label}
        </Typography>
        <Typography variant="h4" component="h2" sx={{ fontWeight: 600 }}>
          {value}
        </Typography>
      </CardContent>
      {showButton && (
        <CardActions sx={{ justifyContent: "flex-end" }}>
          <Button
            onClick={() => filterForUser(auth.user.name)}
            disableElevation
            variant="contained"
            sx={{
              ...(isFilteringForUser ? inverseNavyButton : primaryColorButton)
            }}
            size="small"
          >
            {isFilteringForUser ? "Remove filter" : "View"}
          </Button>
        </CardActions>
      )}
    </Card>
  );
}
