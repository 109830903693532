/* React */
import { useEffect } from "react";

/* Hooks */
import { useReportsStore } from "./useReportsStore";

/* Data */
import { QueryResult, useQuery } from "@apollo/client";
import {
  getGeneralUnitsPrivatePolicyData,
  getLocations,
  getPrivatePoliciesForLocations,
} from "../queries";
import { Location } from "../utilities/generated/gql-types";
import { useHistory } from "react-router-dom";
import Loader from "../shared/Loader"
/* Many reports use a variety of queries,
 * so this component is used to fetch all the data
 * needed for reports.
 *
 * The objective of this component's existence is to keep the
 * report component code relatively clean and render-logic focuseds
 */
export default function ReportsQueries({ routerHelper }) {
  const relationshipId = useReportsStore((state) => {
    if (state.relationshipId) return state.relationshipId;

    return routerHelper.getRelationshipId();
  });

  const history = useHistory();

  const setRelationshipId = useReportsStore((state) => state.setRelationshipId);

  useEffect(() => {
    setRelationshipId(routerHelper.getRelationshipId())
  }, [history.location.pathname])
  


  /* Managing locations available to the user that the report can be filtered on */
  const locationIds = useReportsStore((state) => state.locationIds);
  const setLocationIds = useReportsStore((state) => state.setLocationIds);
  const setLocations = useReportsStore((state) => state.setLocations);
  const setLoading = useReportsStore((state) => state.setLoading);

  /* Managing which locations the user wants to include in the report */
  const setSelectedLocationsInReport = useReportsStore(
    (state) => state.setSelectedLocationsInReport
  );

  /* Set the privat  policies from the locations the user has access to */
  const updatePrivatePolicies = useReportsStore(
    (state) => state.updatePrivatePolicies
  );

  /* Set the units from the locations the user has access to */
  const updateUnits = useReportsStore((state) => state.updateUnits);

  /* Fetch all locations for a relationship */
  const locationsQuery: QueryResult<{ getLocations: Location[] }> = useQuery(
    getLocations,
    {
      variables: {
        relationshipId,
      },
      onCompleted: (data) => {
        setLocations(data.getLocations);
        setLocationIds(
          data.getLocations?.map((location) => Number(location.id))
        );
        setSelectedLocationsInReport(
          data.getLocations?.map((location) => Number(location.id))
        );
      },
      onError: (error) => {
        console.error("Error fetching locations:", error);
        setLoading(false);
      },
      fetchPolicy: 'network-only',
      skip: !relationshipId,
    }
  );

  // Fetch private policies for all locations that this user is allowed to view
  const privatePoliciesQuery = useQuery(getPrivatePoliciesForLocations, {
    variables: { locationIds: locationIds },
    onCompleted: (data) => {
      updatePrivatePolicies(data.getPrivatePoliciesForLocations);
    },
    skip: locationIds.length === 0,
    fetchPolicy: 'network-only',
  });

  const getGeneralUnitsPrivatePolicyDataQuery = useQuery(
    getGeneralUnitsPrivatePolicyData,
    {
      variables: {
        relationshipId,
      },
      skip: locationIds.length === 0,
      onCompleted: (data) => {
        updateUnits(data.getGeneralUnitsPrivatePolicyData);
      },
      fetchPolicy: 'network-only',
    }
  );

  /* Are any queries loading */
  const isAnyLoading =
    locationsQuery.loading ||
    privatePoliciesQuery.loading ||
    getGeneralUnitsPrivatePolicyDataQuery.loading;

  /* Put loading state in shared reports context */
  useEffect(() => {
    setLoading(isAnyLoading);
  }, [isAnyLoading]);

  if (locationsQuery.loading) return <Loader />;

  return null;
}
