import { Box, Grid, List, Typography } from '@mui/material';
import AdminToolNavCard, { AdminToolNavProps } from './AdminToolNavCard';
import { useAdminToolsStore } from './useAdminToolsStore';
import AdminToolNavListItem from './AdminToolNavListItem';
interface AdminToolsSectionProps {
  label: string;
  tools: AdminToolNavProps[];
}

/* -------------------------------------------------------------------------- */
/* AdminToolsSection                                                          */
/*                                                                            */
/* List of admin tools                                                        */
/* -------------------------------------------------------------------------- */

export default function AdminToolsSection({ label, tools }: AdminToolsSectionProps) {
  const { mode } = useAdminToolsStore();

  /* If the mode is in "list" mode, or we're in "split" mode and not looking at the list of favorites, display list items */
  if (mode === 'list' || (mode === 'split' && label !== 'Favorites')) {
    return (
      <Box>
        <Typography variant="h6" sx={{ fontWeight: "bold", mb: 1 }}>
          {label}
        </Typography>
        <List>
          <Grid container spacing={2}>
            {tools.map((tool) => {
              if (!tool) return null;
              return (
                <Grid key={tool.label} item xs={12} sm={6} lg={4} xl={3}>
                  <AdminToolNavListItem {...tool} />
                </Grid>
              );
            })}
          </Grid>
        </List>
      </Box>
    );
  }

  /* Otherwise, display cards */
  return (
    <Box sx={{ borderBottom: '1px solid #EBEFF7', mb: 2, pb: 4 }}>
      <Typography variant='h6' sx={{ fontWeight: 'bold', mb: 1 }}>
        {label}
      </Typography>
      <Grid container direction='row' spacing={2}>
        {tools.map(tool => {
          if (!tool) return null;
          return (
            <Grid key={tool.label} item xs={4} sm={3} lg={3} xl={2}>
              <AdminToolNavCard {...tool} />
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
}
